import { HeaderUrlLink, UrlLink } from "booking_app/constants/header-url-link";
import { HeaderUrlList } from "booking_app/services/header-url-list";
import { GlobalStateService } from "booking_app/services/global-state.service";

export class MenuLinksController {
  static $inject = [
    "$rootScope",
    "$location",
    "$window",
    "$modal",
    "$translate",
    "HeaderUrlList",
    "GlobalStateService",
  ];

  linkOption: UrlLink;
  useSequenceIndex: boolean;

  private activeHeader: HeaderUrlLink;

  constructor(
    private $rootScope: any,
    private $location: any,
    private $window: any,
    private $modal: any,
    private $translate: any,
    private headerUrlList: HeaderUrlList,
    private globalStateService: GlobalStateService,
  ) {}

  $onInit(): void {
    this.activeHeader = this.headerUrlList.getActiveHeader();
  }

  triggerOnClick(e: JQueryEventObject): void {
    e.preventDefault();
    if (this.$rootScope.isLoading) {
      return;
    }
    const url = new URL(this.redirectUrl());

    if (this.linkOption.openModal) {
      this.$modal.open({
        animation: true,
        templateUrl: "/html/modals/two_option_modal",
        controller: "TwoOptionCustomModal",
        windowClass: "dine-redirect-modal",
        backdropClass: "dine-modal-backdrop",
        size: "sm",
        resolve: {
          modalContent: () => {
            return {
              modalHeader: this.$translate.instant("Time to dine!"),
              modalBody: this.$translate.instant("We are now directing you to our local dining partner."),
              modalYes: this.$translate.instant("Continue"),
              modalNo: this.$translate.instant("Cancel"),
            };
          },
        },
      }).result.then( (proceed: boolean): void => {
        if (proceed) {
          this.redirect(url);
        }
      });
    } else {
      // only closing the mobile menu when link doesn't open modal (directly redirect)
      this.$rootScope.globalState.showNavMenu = false;
      this.redirect(url, e.metaKey || e.ctrlKey);
    }
  }

  redirect(url: URL, forceOpenInNewTab = false): void {
    if (this.linkOption.openInNewTab || forceOpenInNewTab) {
      this.$window.open(this.redirectUrl(), "_blank");
    } else if (this.redirectLink(url.origin) && this.compareCurrentHeader()) {
      this.globalStateService.changeTravelType(this.linkOption.travelType);
      this.$location.url(url.pathname);
    } else {
      this.$window.location.href = this.redirectUrl();
    }
  }

  isActive(): boolean {
    return this.linkOption.active;
  }

  urlRedirect(): string {
    return this.linkOption.siteUrl ? this.linkOption.siteUrl : "#";
  }

  private redirectUrl(): string {
    const url: string = new URL(this.linkOption.siteUrl).origin;
    if (this.linkOption.redirect &&
      this.$rootScope.isUserLoggedIn() &&
      !this.redirectLink(url)) {
      return `${url}/whitelabel/fab/login?redirect=${this.linkOption.siteUrl}`;
    } else {
      return this.linkOption.siteUrl;
    }
  }

  private redirectLink(originPath: string): boolean {
    // Use to check if it is on the same tenant using the header URL
    return originPath === this.$window.location.origin;
  }

  private compareCurrentHeader(): boolean {
    const isComplimentaryNights: boolean = this.$rootScope.globalState.productType === "complimentary-nights";
    if (isComplimentaryNights) {
      return false;
    } else {
      return !this.excludeLinks();
    }
  }

  private excludeLinks(): boolean {
    return this.linkOption.siteUrl.includes("complimentary_nights") ||
           this.linkOption.siteUrl.includes("logout");
  }
}
