import { Bindings } from "booking_app/types";
import { TravelTypeSwitcherController } from "./travel-type-switcher.controller";

export class TravelTypeSwitcher {
  bindings: any = {
    order: Bindings.ONE_WAY,
  };

  controller: any = TravelTypeSwitcherController;
  template: string = `
     <ng-switch on="$ctrl.order">
       <div ng-switch-when="flight-hotel-car" class="switch-container">
         ${this.flightTab()}
         ${this.hotelTab()}
         ${this.carTab()}
       </div>
       <div ng-switch-default class="switch-container">
         ${this.hotelTab()}
         ${this.flightTab()}
         ${this.carTab()}
       </div>
     </ng-switch>
 `;

  private hotelTab(): string {
    return `
      <div class="hotels-container"
        ng-if="$ctrl.hasTravelType('hotels')"
        tabindex="0"
        ng-class="{ 'active': $ctrl.globalStateService.isTravelType('hotels')}"
        ng-click="$ctrl.globalStateService.changeTravelTypeAndUrl('hotels')"
        ng-keypress="$event.key == 'Enter' && $ctrl.globalStateService.changeTravelTypeAndUrl('hotels')">
        <div class="icon">
        </div>
        <div>
          {{ "Hotels" | translate }}
        </div>
      </div>`;
  }

  private flightTab(): string {
    return `
      <div class="flights-container"
        ng-if="$ctrl.hasTravelType('flights')"
        ng-class="{ 'active': $ctrl.globalStateService.isTravelType('flights')}"
        ng-click="$ctrl.globalStateService.changeTravelTypeAndUrl('flights')"
        tabindex="0"
        id="skip-to-content"
        ng-keypress="$event.key == 'Enter' && $ctrl.globalStateService.changeTravelTypeAndUrl('flights')">
        <div class="icon">
        </div>
        <div>
          {{ "Flights" | translate }}
        </div>
      </div>`;
  }

  private carTab(): string {
    return `
      <div class="cars-container"
        ng-if="$ctrl.hasTravelType('cars')"
        ng-class="{ 'active': $ctrl.globalStateService.isTravelType('cars')}"
        ng-click="$ctrl.globalStateService.changeTravelTypeAndUrl('cars')"
        tabindex="0"
        ng-keypress="$event.key == 'Enter' && $ctrl.globalStateService.changeTravelTypeAndUrl('cars')">
        <div class="icon">
        </div>
        <div>
          {{ "Cars" | translate }}
        </div>
      </div>`;
  }
}

angular.module("BookingApp").component("travelTypeSwitcher", new TravelTypeSwitcher());
