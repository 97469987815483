require('../values/kaligo-search')
require('../components/flights/search-form/flights-search-form.service')
require('../components/flights/search-form/flights-search-form.state')
require('./points-cash-share.service')

angular.module('BookingApp')
.service 'SearchUrlService', [

  "$rootScope", 'AppTraffic', 'KaligoSearch', 'AppSettings',
  'CarsState', 'FlightsSearchFormService', "GlobalStateService",
  '$routeParams', '$window', 'PointsCashShareService', 'AppConfig'
  ($rootScope, AppTraffic, KaligoSearch,
  AppSettings, CarsState, FlightsSearchFormService,
  GlobalStateService, $routeParams, $window,
  PointsCashShareService, AppConfig) ->

    hideEmptyParam = (key, value) ->
      if not value?
        return ''
      else
        qstr = '&' + key + '=' + value
        return qstr

    @createBackToSearchUrl = (scope) ->
      '/results/' + $rootScope.destinationId +
      '?destination=' + $rootScope.destination +
      commonPOIParams() +
      "&" + commonSearchUrlParams()

    formatDate = (date) ->
      moment(date, 'MM/DD/YYYY').format("YYYY-MM-DD")

    @createSearchUrl = () ->
      destination = KaligoSearch.form.destination if KaligoSearch.form
      routeParams = $rootScope.routeParams

      if destination && destination.value && destination.value.destination_id
        destId = destination.value.destination_id
        destLabel = destination.label
        destType = destination.value.type
        destLat = destination.value.lat
        destLng = destination.value.lng
        destHotelId = destination.value.hotel_id
      else
        destId = routeParams.destinationId
        destLabel = routeParams.destination
        destType = routeParams.destinationType
        destLat = routeParams.destinationLat
        destLng = routeParams.destinationLng
        destHotelId = routeParams.selectedHotelId

      return '/results/' + destId +
      '?destination=' + destLabel +
      '&destination_type=' + destType +
      '&lat=' + destLat +
      '&lng=' + destLng +
      "&" + commonSearchUrlParams()

    @generateCarsParams = (params) ->
      params.returnLocation = params.pickupLocation if params.sameAsPickup
      # NOTE: combine `18/09/2016` and 10:00 together as `2016-09-18T10:00:00`
      carsParams = {
        pickup_location: params.pickupLocation.id
        pickup_location_name: params.pickupLocation.value
        return_location: params.returnLocation.id
        return_location_name: params.returnLocation.value
        location_type: params.locationType
        pickup_time: "#{formatDate(params.checkin)}T#{params.pickupTime}:00"
        return_time: "#{formatDate(params.checkout)}T#{params.returnTime}:00"
      }

      # TODO: Remove this & carsParams variable once AppConfig.enable_country_of_residence is not used anymore
      if AppConfig.enable_country_of_residence
        driverCountryCode = params.driverCountry?.id || params.driverCountry
        Object.assign(carsParams, { driver_country: driverCountryCode, age: params.age })
      carsParams

    @createFlightDetailsUrl = () ->
      '/flights/summary' +
      '?booking_key=' + $routeParams.booking_key +
      '&partnerId=' + $routeParams.partnerId

    @createCarsSearchUrl = (params) ->
      '/cars/results/' +
      carsCommonParams(params)

    @createCarsDetailsUrl = (bookingKey, params) ->
      '/cars/details/' + bookingKey +
      carsCommonParams(params)

    @createCarsCheckoutUrl = (bookingKey, params) ->
      '/cars/checkout/' + bookingKey +
      carsCommonParams(params)

    carsCommonParams = (params) ->
      carsParams = carsPickupLocationParams(params) +
                   carsReturnLocationParams(params) +
                   "&pickup_time=#{params.pickup_time}" +
                   "&return_time=#{params.return_time}" +
                   "&#{commonProductParams()}" +
                   "&location_type=#{params.location_type}"

      # TODO: Remove this & carsParams variable AppConfig.enable_country_of_residence is not used anymore
      if AppConfig.enable_country_of_residence
        driverCountryCode = params.driver_country?.value || params.driver_country
        carsParams += "&driver_country=#{driverCountryCode}" +
        "&age=#{params.age}"
      carsParams

    carsPickupLocationParams = (params) ->
      pickupParams = "?pickup_location=#{params.pickup_location}"
      if params.pickup_location_name
        pickupParams += "&pickup_location_name=#{params.pickup_location_name}"
      pickupParams

    carsReturnLocationParams = (params) ->
      returnParams = "&return_location=#{params.return_location}"
      if params.return_location_name
        returnParams += "&return_location_name=#{params.return_location_name}"
      returnParams

    @createHotelDetailsUrl = (hotel) ->
      if hotel.violates_price_policy
        ""
      else
        '/hotels/detail/' + hotel.id +
        '?destination=' + $rootScope.destination +
        '&destinationId=' + $rootScope.destinationId +
        commonPOIParams() +
        '&' + commonSearchUrlParams()

    @createCheckoutUrl = (bookingKey) ->
      '/hotels/checkout/' + $rootScope.hotelId +
      '?destination=' + $rootScope.destination +
      '&destinationId=' + $rootScope.destinationId +
      '&bookingKey=' + bookingKey +
      commonPOIParams() +
      '&' + commonSearchUrlParams()

    @createBackToHotelDetailsUrl = () ->
      '/hotels/detail/' + $rootScope.hotelId +
      '?destination=' + $rootScope.destination +
      '&destinationId=' + $rootScope.destinationId +
      commonPOIParams() +
      '&' + commonSearchUrlParams()

    urlWithProductType = () ->
      if AppSettings.useProductType
        '&productType=' + ($rootScope.globalState.productType)
      else
        ''
    commonPOIParams = () ->
      hideEmptyParam('destination_type', $rootScope.destinationType) +
      hideEmptyParam('lat', $rootScope.destinationLat) +
      hideEmptyParam('lng', $rootScope.destinationLng)

    commonProductParams = () ->
      'country=' + $rootScope.selectedCountrySite.code +
      '&landingPage=' + ($rootScope.landingPage.url || "") +
      urlWithProductType() +
      '&currency=' + $rootScope.selectedCurrency.code +
      '&partnerId=' + $rootScope.pointsPartner.id +
      commonPointCashParams() +
      commonPointsAccountParams()

    commonPointCashParams = () ->
      return '' if !$rootScope.landingPage.hasProductType("redeem") &&
        !AppSettings.pointsCashSliderSettings.alwaysShowPointsSlider
      return '&pointsCashShare=' + PointsCashShareService.pointsCashShare.value

    commonPointsAccountParams = () ->
      if $rootScope.pointsAccountId
        "&pointsAccountId=#{$rootScope.pointsAccountId}"
      else
        ""

    commonSearchUrlParams = () ->
      'checkInDate=' + $rootScope.checkInDate +
      '&checkOutDate=' + $rootScope.checkOutDate +
      hideEmptyParam('guests', $rootScope.guests) +
      hideEmptyParam('trafficSource', AppTraffic.source) +
      hideEmptyParam('trafficClickId', AppTraffic.clickId) +
      hideEmptyParam('suppliers', $rootScope.routeParams.suppliers) +
      '&' + commonProductParams() +
      hotelSearchUrlParams()

    hotelSearchUrlParams = () ->
      destination = KaligoSearch.form.destination if KaligoSearch.form
      routeParams = $rootScope.routeParams

      if destination && destination.value && destination.value.destination_id
        destHotelId = destination.value.hotel_id
      else
        destHotelId = routeParams.selectedHotelId
      return '' if !destHotelId
      return '&selectedHotelId=' + destHotelId

    redirectToSearch = () ->
      $window.location.href = buildSearchUrl()

    buildSearchUrl = () =>
      if GlobalStateService.isTravelType("cars")
        params = @generateCarsParams(CarsState)
        @createCarsSearchUrl(params)
      else if GlobalStateService.isTravelType("hotels")
        @createBackToSearchUrl($rootScope)
      else if GlobalStateService.isTravelType("flights")
        FlightsSearchFormService.generateResultsUrl()

    changeProductType = (productType) ->
      $rootScope.isLoading = true
      $rootScope.globalState.productType = productType

    @switchProductTypeBasedOnPoints = () ->
      travelType = GlobalStateService.travelType
      pointsCashShareValue = PointsCashShareService.pointsCashShare.value
      pointsValue = $rootScope.pointsPartner
        .pointsPaymentTiersBy(travelType)[pointsCashShareValue]
      productType = $routeParams.productType ||
        $rootScope.globalState.productType
    return
]
