import { Bindings } from "booking_app/types";
import { InputSelectController } from "./input-select.controller";

export class InputSelectComponent {
  bindings: any = {
    valueFormatter: Bindings.ONE_WAY,
    onUpdate: Bindings.EXPRESSION,
    selectedValue: Bindings.ONE_WAY,
    options: Bindings.ONE_WAY,
    placeholder: Bindings.ONE_WAY,
    formName: Bindings.ONE_WAY,
    searchEnabled: Bindings.ONE_WAY,
    onRefresh: Bindings.EXPRESSION,
    refreshDelay: Bindings.ONE_WAY,
    choicesAriaLabel: Bindings.ONE_WAY,
    choicesGroupAriaLabel: Bindings.ONE_WAY,
    triggerUpdateOnInit: Bindings.ONE_WAY,
  };
  controller: any = InputSelectController;
  template: string = `
    <ui-select
      ng-change="$ctrl.onValueChange()"
      ng-model="$ctrl.currentValue"
      required=""
      theme="select2"
      search-enabled="{{ $ctrl.searchEnabled || false }}"
      name="{{ $ctrl.formName }}"
      tabindex="-1"
      search-aria-label="{{ $ctrl.displayOption($select.selected) }}"
      choices-aria-label="{{ $ctrl.choicesAriaLabel }}"
      choices-group-aria-label="{{ $ctrl.choicesGroupAriaLabel }}"
    >
      <ui-select-match placeholder="{{ $ctrl.displayPlaceholder() }}">
        <div class="selected-value"
          ng-class="$ctrl.inputClass($select.selected)"
          ng-bind="$ctrl.displayOption($select.selected)"
          aria-label="{{ $ctrl.choicesAriaLabel }} {{ $ctrl.displayOption($select.selected) }}"></div>
      </ui-select-match>
      <ui-select-choices
        refresh="$ctrl.refresh($select.search)"
        refresh-delay="$ctrl.refreshDelay"
        repeat="item in ($ctrl.options | filter: $select.search)">
        <div class="input-options" ng-bind-html="$ctrl.displayOption(item)"
        ng-class="$ctrl.inputClass(item)"></div>
      </ui-select-choices>
    </ui-select>
  `;
}

angular.module("BookingApp").component("inputSelect", new InputSelectComponent());
