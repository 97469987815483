import { Bindings } from "booking_app/types";
import { MobileDropdownWrapperController } from "./mobile-dropdown.controller";

export class MobileDropdownWrapperComponent {

  bindings: any = {
    name: Bindings.ONE_WAY,
    elementId: Bindings.ONE_WAY,
    elementClass: Bindings.ONE_WAY,
    ngModel: Bindings.ONE_WAY,
    required: Bindings.ONE_WAY,
    options: Bindings.ONE_WAY,
    optionLabel: Bindings.ONE_WAY,
    defaultLabel: Bindings.ONE_WAY,
    optionId: Bindings.ONE_WAY,
    onChange: Bindings.EXPRESSION,
  };
  transclude: boolean = true;
  require: any = {
    ngModelController: "ngModel",
  };

  controller: any = MobileDropdownWrapperController;
  template: string = `
    <ng-container>
      <div ng-if="$ctrl.userAgentChecker.isMobileOrTablet()" class="mobile-native-select2 {{ $ctrl.elementClass }}">
        <select
          id="{{ $ctrl.elementId }}"
          ng-model="$ctrl.selectedElementId"
          ng-required="$ctrl.required"
          name="{{ $ctrl.name }}"
          ng-change="$ctrl.ngModelChange()" >
          <option hidden="" value="" ng-bind="$ctrl.defaultLabel"></option>
          <option
            ng-repeat="option in $ctrl.options"
            ng-value="option[$ctrl.optionId]"
            ng-bind="option[$ctrl.optionLabel]"></option>
        </select>
      </div>
      <ng-transclude ng-if="!$ctrl.userAgentChecker.isMobileOrTablet()"></ng-transclude>
    </ng-container>
  `;
}

angular.module("BookingApp").component("mobileDropdownWrapper", new MobileDropdownWrapperComponent());
