const commonDependencies = [
  'ngRoute',
  'ngTouch',
  'ipCookie',
  'ui.select',
  'ngDropdowns',
  'ngSanitize',
  'ngResource',
  'pascalprecht.translate',
  'angulartics',
  'angulartics.google.analytics',
  'ui.bootstrap',
  'lumx.tooltip',
  'rzModule',
  'ngMessages',
  'slickCarousel',
  'vcRecaptcha',
]

angular.module("BookingApp", commonDependencies.concat(wlAngularDeps || []));
