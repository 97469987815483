declare var moment: any;

angular.module("BookingApp")
  .filter("getNumberOfDays", () => {
    return (before: string, after: string): number => {
      const format = (/\-/.test(before)) ? "YYYY-MM-DD" : "MM/DD/YYYY";
      const delta = moment.utc(after, format) - moment.utc(before, format);

      return delta / 1000 / 60 / 60 / 24;
    };
  })
  .filter("getNumberOfCarDays", () => {
    return (before: string, after: string): number => {
      const format = "YYYY-MM-DDTHH:mm";
      const delta = moment.utc(after, format) - moment.utc(before, format);

      return Math.ceil(delta / 1000 / 60 / 60 / 24);
    };
  })
  .filter("convertToDate", () => {
    return (text: string): number => {
      return Date.parse(text.substring(0, 10));
    };
  })
  .filter("convertToLocalisedTime", () => {
    const convertToLocalisedTimeFilter = (date: string, format?: string): string => {
      const dateFormat: string = format || "lll";
      return moment.utc(date).format(dateFormat);
    };
    convertToLocalisedTimeFilter.$stateful = true;
    return convertToLocalisedTimeFilter;
  })
  .filter("normalizeQuickSearchMiles", () => {
    return (miles: number, partner?: any): number => {
      if (partner && partner.category === "gift_card") {
        return Math.round(miles / 750) * 5;
      } else {
        return miles;
      }
    };
  })
  .filter("dateOrTime", () => {
    const dateOrTimeFilter = (dateTime: any, type: string, format?: string): string => {
      const dateFormat = format || "ll";
      const timeFormat = format || "hh:mm A";

      const selectedFormat = (type === "date") ? dateFormat : timeFormat;

      return moment(dateTime).format(selectedFormat);
    };
    dateOrTimeFilter.$stateful = true;
    return dateOrTimeFilter;
  })
  .filter("roundDownNumber", () => {
    return (value: number): number => {
      return Math.floor(value);
    };
  })
  .filter("filterAvailableHotels", () => {
    return (hotels: any[], _searchCompleted: boolean, _showUnavailableHotels: boolean): any[] => {
      return hotels.filter(hotel => hotel.available);
    };
  })
  .filter("filterVoucherHotels", () => {
    return (hotels: any[], _searchCompleted: boolean, _showUnavailableHotels: boolean): any[] => {
      return hotels.filter(hotel => hotel.voucher_type_id);
    };
  })
  .filter("filterVisibleHotels", () => {
    return (hotels: any[], _searchCompleted: boolean, _showUnavailableHotels: boolean): any[] => {
      const minStarRating: number = 3;
      const maxUnavailableRatio: number = 0.28;
      const minUnavailableCount: number = 5;
      const shouldAddUnavailableHotel = (hotel: any, unavailableCount: number, totalCount: number): boolean => {
        if (parseFloat(hotel.rating) < minStarRating) { return false; }
        if (unavailableCount < minUnavailableCount) { return true; }
        if ((unavailableCount + 1) / totalCount < maxUnavailableRatio) { return true; }
      };

      const results: any[] = [];
      let unavailableHotelsCount: number = 0;

      angular.forEach(hotels, (hotel: any): void => {
        if (hotel.price) {
          results.push(hotel);
        } else if (
          _searchCompleted &&
          _showUnavailableHotels &&
          shouldAddUnavailableHotel(hotel, unavailableHotelsCount, results.length)
        ) {
          results.push(hotel);
          unavailableHotelsCount += 1;
        }
      });

      return results;
    };
  });
