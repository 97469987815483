import { ProductType } from "booking_app/types/product-type";
import { AppSettings } from "booking_app/values/app-settings";
import { PageDiscoveryService } from "booking_app/services/page-discovery.service";

export class ProductTypeSwitchController {

  static $inject = ["$rootScope", "$translate", "AppSettings", "$route", "AppConfig", "PageDiscoveryService"];

  // local state
  productTypes: ProductType[];

  constructor(
    public $rootScope: any,
    private $translate: any,
    private appSettings: AppSettings,
    private $route: any,
    private appConfig: any,
    public pageDiscoveryService: PageDiscoveryService,
  ) {
    this.productTypes = appSettings.supportedProductTypes ;
  }

  setProductType(productType: ProductType): void {
    this.$rootScope.globalState.productType = productType;
    this.$rootScope.landingPage.setUrl(this.appSettings.tenant, productType);
    this.$route.reload();
  }

  getProductType(): ProductType {
    return this.$rootScope.globalState.productType;
  }

  isProductTypeSwitchEnabled(): boolean {
    return !this.appConfig?.feature_flags?.disable_header_product_type_switcher;
  }

  // Currently this component will return a common translation value according to the product type.
  // But in future if the WL wish to use different translations , we need to refactor this method in such a way,
  // That the component can recieve translaton keys from the WL and use that here instead.
  translateProductType(productType: string): string {
    switch (productType) {
      case ProductType.EARN: {
        return "txt.product_type.earn";
      }
      case ProductType.REDEEM: {
        return "txt.product_type.redeem";
      }
      case ProductType.CASHVOUCHER: {
        return "txt.product_type.cash_voucher";
      }
      case ProductType.VOUCHER: {
        return "txt.product_type.voucher";
      }
      default: {
        return "";
      }
    }
  }
}
