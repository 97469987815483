require('../services/utils')
require('../services/api-data.service')

angular.module("BookingApp")

.factory "KaligoUser", [

  "$q", "$rootScope", "$location", "UtilsService", "ApiDataService"
  ($q, $rootScope, $location, UtilsService, ApiDataService) ->

    KaligoUser = () ->
      self = this

      @register = (recaptchaResponse) ->
        requestData = {
          email: self.email
          first_name: self.first_name
          last_name: self.last_name
          send_marketing: self.send_marketing || false
          lp: $rootScope.landingPage.url
          referral_code: UtilsService.getReferralCode($location),
          recaptcha_response: recaptchaResponse
        }

        return ApiDataService.post(
          $rootScope.globalState.signupEndpoint,
          requestData,
          {'X-Requested-With': 'XMLHttpRequest'},
          false
        )
        .then (data) ->
          data
        , (error) ->
          $q.reject(error)

      @update = (profile) ->
        return ApiDataService.post(
          "user/update",
          { user:
            first_name: profile.first_name
            last_name: profile.last_name
            address1: profile.address1
            city: profile.city
            zip_code: profile.zip_code
            country: profile.country
            state: profile.state
            telephone: profile.telephone
            day: profile.day
            month: profile.month
            year: profile.year
            company: profile.company
            company_details: profile.company_details
            title: profile.title
            send_marketing: profile.send_marketing || false
          },
          {},
          true
        )

      @forgetPassword = (email, recaptchaResponse) ->
        return ApiDataService.post(
          $rootScope.globalState.forgetPassEndpoint,
          {
            user: { email },
            commit: 'Reset password',
            recaptcha_response: recaptchaResponse
          },
          {'X-Requested-With': 'XMLHttpRequest'},
          false
        )
        .then (data) ->
          data
        , (error) ->
          $q.reject(error)

      @updatePassword = (password) ->
        return ApiDataService.post(
          "user/update_password",
          { user:
            current_password: password.current_password
            password: password.new_password
            password_confirmation: password.new_password_confirmation },
          {},
          true
        )

      @format = (user) ->
        if !user || user is "null"
          return { loggedIn: false, user: null }
        if user.birthday
          [user.year, user.month, user.day] = user.birthday.split('-').map (value) -> parseInt(value)
        return { loggedIn: true, user: user }

      return self

    return (KaligoUser)
]
