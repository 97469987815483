import { AppInitializer } from './app-initializer';

const appInitalizer = new AppInitializer();

appInitalizer.initializeApp()
  .then(() => {
    angular.element(document).ready(() => {
      angular.bootstrap(document.documentElement, ['BookingApp'])
    })
  });
