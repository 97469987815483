import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { ModalManager } from "booking_app/components/common/modal-manager";

export class GalleryModal {
  static $inject = [
    "$scope",
    "$rootScope",
    "images",
    "defaultImage",
    "currentGalleryIndex",
    "ModalManager",
  ];

  constructor(
    private $scope: any,
    private $rootScope: any,
    public images: string[],
    public defaultImage: any,
    public currentGalleryIndex: number,
    private modalManager: ModalManager,
  ) {
    this.$scope.close = () => {
      this.modalManager.close();
    };

    this.$scope.yes = () => {
      this.modalManager.close();
    };

    this.$scope.images = images;
    this.$scope.defaultImage = defaultImage;
    this.$scope.currentGalleryIndex = currentGalleryIndex;
    this.modalWidthListener();
  }

  private modalWidthListener() {
    this.$scope.galleryModal = this.$rootScope.$watch("globalState.browserWidth", () => {
      if (this.$rootScope.globalState.browserWidth <= ViewportSizes.XS_MAX) {
        this.$scope.close();
        this.$scope.galleryModal();
      }
    });
  }
}

angular.module("BookingApp").controller("GalleryModal", GalleryModal);
