import { FlightsCheckoutData } from "booking_app/components/flights/checkout/checkout-data";
import { FlightSearchParams, FlightCabin, FlightType, FlightItem } from "booking_app/types";
import { SecondaryPassengerFormData } from "booking_app/components/flights/checkout/secondary-passenger-detail-form";
import { CommonPassengerDetailFormData } from "booking_app/components/flights/checkout/common-passenger-detail-form";
import { FlightsItinerary } from "booking_app/models";
import { PaymentDetailFormData } from "booking_app/components/common";
import { PopulateCheckoutService } from "booking_app/services/populate-checkout.service";
import { PhoneDetailsFormObject } from "booking_app/components/flights/checkout/phone-details-form-object";

export class FlightsCheckoutState {
  static $inject = ["PopulateCheckoutService", "$rootScope"];

  data: FlightsCheckoutData;
  params: FlightSearchParams;
  itinerary: FlightsItinerary;
  flightItem: FlightItem;

  constructor(
    private populateCheckoutService: PopulateCheckoutService,
    private $rootScope: any,
  ) { }

  resetValues(params: FlightSearchParams, itinerary: FlightsItinerary, revalidating: boolean) {
    this.params = params;
    this.itinerary = itinerary;
    this.flightItem = FlightItem.buildFromItinerary(itinerary);
    if (revalidating) {
      this.buildFormData();
    }
  }

  buildEmptyCommonData(): CommonPassengerDetailFormData {
    return {
      title: null,
      firstName: null,
      lastName: null,
      rememberMe: false,
      dateOfBirth: {
        day: null,
        month: null,
        year: null,
      },
      passportNationality: {
        id: null,
        code: null,
        text: null,
      },
      passportNumber: null,
      passportExpiryDate: {
        day: null,
        month: null,
        year: null,
      },
    };
  }

  private buildFormData() {
    let commonFormData: CommonPassengerDetailFormData;
    let phoneDetailsFormObject: PhoneDetailsFormObject;

    if (this.$rootScope.userDetails.loggedIn) {
      commonFormData = this.buildPopulatedCommonData();
      phoneDetailsFormObject = this.populateCheckoutService.buildFlightDetailsForm();
    } else {
      commonFormData = this.buildEmptyCommonData();
      phoneDetailsFormObject = { countryCode: null, number: null };
    }
    const emailValue: string = this.populateCheckoutService.getEmail();

    this.data = {
      primaryPassengerData: {
        commonData: commonFormData,
        phoneDetails: phoneDetailsFormObject,
        email: emailValue,
      },
      secondaryPassengerData: this.buildSecondaryPassengerData(),
      paymentData: this.buildPaymentData(),
    };
  }

  private buildSecondaryPassengerData(): SecondaryPassengerFormData[] {
    const adultPassengers: SecondaryPassengerFormData[] = Array(this.params.adultCount - 1).fill({
      commonData: this.buildEmptyCommonData(),
      type: "adult",
    });

    const childPassengers: SecondaryPassengerFormData[] = Array(this.params.childCount).fill({
      commonData: this.buildEmptyCommonData(),
      type: "child",
    });

    const infantPassengers: SecondaryPassengerFormData[] = Array(this.params.infantCount).fill({
      commonData: this.buildEmptyCommonData(),
      type: "infant",
    });

    return adultPassengers.concat(childPassengers).concat(infantPassengers);
  }

  private buildPopulatedCommonData(): CommonPassengerDetailFormData {
    let commonData = this.populateCheckoutService.populateFields(this.buildEmptyCommonData());

    if (this.itinerary && this.itinerary.passport_required) {
      commonData = this.populateCheckoutService.populatePassportDetails(commonData);
    }

    return commonData;
  }

  private buildPaymentData(): PaymentDetailFormData {
    return {
      creditCardData: {
        cardNumber: null,
        cardholderName: null,
        expiryDate: {
          month: null,
          year: null,
        },
        securityCode: null,
      },
      addressData: {
        address: null,
        postalCode: null,
        city: null,
        country: null,
        state: null,
      },
    };
  }
}

angular.module("BookingApp").service("FlightsCheckoutState", FlightsCheckoutState);
