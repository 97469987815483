import { UserActionInput, UserActionType } from "booking_app/types/user-action";
import { ApiDataService } from "booking_app/services/api-data.service";
import { convertJSONToQueryString } from "booking_app/services/route-params-utils";

export class UserActionService {
  static $inject = [
    "$rootScope",
    "ApiDataService",
  ];

  constructor(
    private $rootScope: any,
    private apiDataService: ApiDataService,
  ) { }

  public postUserAction(userActionType: UserActionType, customDataId: string): ng.IPromise<{}> {
    const userAction: UserActionInput = {
      action_type: userActionType,
      custom_data_id: customDataId,
    };

    return this.apiDataService.post("user_actions", userAction);
  }

  public getUserAction(userActionType: UserActionType, limit?: number): ng.IPromise<{}> {
    const params = { action_type: userActionType, limit };
    const url: string = `user_actions?${convertJSONToQueryString(params)}`;

    return this.apiDataService.get(url);
  }

}

angular.module("BookingApp").service("UserActionService", UserActionService);
