import { HotelDescriptionController } from "./hotel-description.controller";
import { Bindings } from "booking_app/types";

export class HotelDescriptionComponent {
  bindings: any = {
    description: Bindings.ONE_WAY,
  };
  controller: any = HotelDescriptionController;
  template: string = `
    <div class="hotel-description">
      <div class="headline-container">
        <h3 class="title color-headline" translate="txt.hotel_overview"></h3>
        <div class="chevron-container">
          <a class="fa" ng-click="$ctrl.toggleDescription()"
            ng-class="$ctrl.showFullDescription() ? 'fa-chevron-up' : 'fa-chevron-down'"
            aria-label="{{ $ctrl.showFullDescription() ? 'Collapse hotel overview' : 'Expand hotel overview' }}"
            role="button"
            href="#">
          </a>
        </div>
      </div>
      <div ng-if="$ctrl.isDescriptionLoading()" class="hotel-description-loading"
        ng-repeat="x in [].constructor(4) track by $index">
        <div class="first-loading-description"> </div>
        <div class="second-loading-description"> </div>
        <div class="second-loading-description" ng-if= "$index % 2 == 1"> </div>
        <div class="third-loading-description"> </div>
      </div>
      <div ng-if="!$ctrl.isDescriptionLoading()" class="hotelDescription clear" ng-bind-html="$ctrl.description"
        ng-class="{ 'limit-description': !$ctrl.showFullDescription() }">
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("hotelDescription", new HotelDescriptionComponent());
