import { Bindings } from "booking_app/types";
import { GroupedRoomListItemController } from "./grouped-room-list-item.controller";

export class GroupedRoomListItemComponent {
  bindings: any = {
    room: Bindings.ONE_WAY,
    policies: Bindings.ONE_WAY,
    pointsPartner: Bindings.ONE_WAY,
    redirectToHotelCheckoutPage: Bindings.EXPRESSION,
  };
  controller: any = GroupedRoomListItemController;
  templateUrl: any = "/html/hotels/grouped_room_list_item";
}

angular.module("BookingApp").component("groupedRoomListItem", new GroupedRoomListItemComponent());
