
// Directive Skip-to
// skip-to-offset -> header offset
// skip-to-smooth -> if true use smooth scrolling
import { UserAgentChecker } from "booking_app/utils/user-agent-checker";

declare var angular: any;

angular.module("BookingApp")
  .directive("skipTo", [
    "UserAgentChecker",
    "$rootScope",
    "$timeout",
    (
      userAgentChecker: UserAgentChecker,
      $rootScope: any,
      $timeout: any,
    ) => {
      return {
        restrict: "A",
        link: (scope: any, element: any, attr: any) => {
          let debounceTimeout: any;
          let hashValue: string;
          let targetAnchor: any;

          const updateAnchorElement = () => {
            // fixing Safari target focussing on enter
            if (targetAnchor) {
              scrollBehavior();
              targetAnchor.setAttribute("tabindex", "-1");
              targetAnchor.addEventListener("keydown", blurFocusOutListener);
              if (!attr.skipToSmooth) {
                targetAnchor.focus();
              }
            }
          };

          const blurFocusOutListener = () => {
            targetAnchor.removeAttribute("tabIndex");
            // Made sure to destroy listener
            targetAnchor.removeEventListener("keydown", blurFocusOutListener);
          };

          const fixOffsetScroll = () => {
            // fixing the hidden content under sticky header
            const offset = parseInt(attr.skipToOffset, 10);
            if ( (offset && offset > 0) && !attr.skipToSmooth) {
              window.scroll(0, window.scrollY - offset);
            }
          };

          const prepareFocus = () => {
            // Repeatedly cancels the timeout event while still scrolling to
            // Prevent scroll stop because `focus()` stops the scrolling which
            // Can result to halting before reaching the area desired
            $timeout.cancel(debounceTimeout);

            // Created a debounce Timeout to catch when the scroll has ended
            debounceTimeout = $timeout(() => {
              targetAnchor.focus({ preventScroll: true });

              // Remove Scroll Debounced event
              window.removeEventListener("scroll", prepareFocus);
            }, 100);
          };

          const scrollBehavior = () => {
            const coordinates = targetAnchor.getBoundingClientRect();
            const offset = parseInt(attr.skipToOffset, 10);
            const scrollCoordinate = coordinates.top + window.scrollY - offset;
            // Creates an event listener that waits scroll to finish before focusing
            window.addEventListener("scroll", prepareFocus);
            if (attr.skipToOffset && attr.skipToSmooth) {
              if (userAgentChecker.isIE()) {
                window.scrollTo(0, scrollCoordinate);
              } else {
                window.scroll({ top: scrollCoordinate, behavior: "smooth" });
              }
            } else {
              // Original Skip-to Scroll into view logic
              targetAnchor.scrollIntoView(true);
            }
          };

          element[0].addEventListener("click", (ev) => {
            hashValue = attr.href;
            targetAnchor = document.getElementById(hashValue.substr(1));

            ev.preventDefault();
            ev.target.blur();
            updateAnchorElement();
            fixOffsetScroll();
          });
        },
      };
    },
  ]);
