import { Bindings } from "booking_app/types";
import { HotelRemarksController } from "./hotel-remarks.controller";

export class HotelRemarksComponent {
  bindings: any = {
    heading: Bindings.ONE_WAY,
    content: Bindings.ONE_WAY,
  };

  controller: any = HotelRemarksController;
  template: string = `
    <div class="instruction" ng-show="$ctrl.content">
      <p>
        <strong class="capitalize">{{ $ctrl.heading | translate }}</strong>
        <br>
        <div ng-bind-html="$ctrl.content"></div>
      </p>
    </div>
  `;
}

angular.module("BookingApp").component("hotelRemarks", new HotelRemarksComponent());
