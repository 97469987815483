import { HotelBookingDetailController } from "booking_app/components/bookings/hotel-booking-detail.controller";

export class HotelBookingDetailComponent {
  public controller: any;
  public controllerAs: string;
  public templateUrl: string;

  constructor() {
    this.controller = HotelBookingDetailController;
    this.templateUrl = '/html/hotels/success_page';
  }
}

angular.module("BookingApp").component('hotelBookingDetailComponent', new HotelBookingDetailComponent())
