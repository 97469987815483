// TODO: We should implement this site wide to avoid opening of multiple Modals
type ModalStatus = "open" | "close";
declare var Rollbar: any;

export class ModalManager {
  static $inject = [
    "$modal",
  ];

  public modalStatus: ModalStatus;
  public priorityModalStatus: ModalStatus;
  public currentModalInstance: any;

  constructor(
    private $modal: any,
  ) {
  }

  close(): void {
    this.modalStatus = "close";
    this.priorityModalStatus = "close";

    if (!!this.currentModalInstance) {
      this.currentModalInstance.dismiss("close");
    }
    this.currentModalInstance = null;
  }

  open(config: any, priorityModal: boolean = false): void {
    if (!config) {
      Rollbar.warning("Error: no modal config passed");
      return;
    }

    if (priorityModal) {
      this.close();
      this.priorityModalStatus = "open";
    }

    if (this.isModalOpen()) {
      this.close();
    }

    this.modalStatus = "open";
    this.currentModalInstance = this.$modal.open(config);
  }

  isPriorityModalOpen(): boolean {
    return this.priorityModalStatus === "open";
  }

  private isModalOpen(): boolean {
    return !!this.currentModalInstance && this.modalStatus === "open";
  }
}

angular.module("BookingApp").service("ModalManager", ModalManager);
