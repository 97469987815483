import { Bindings } from "booking_app/types";
import { PaginatedDropdownController } from "./paginated-dropdown.controller";

export class PaginatedDropdownComponent {

  bindings: any = {
    ngModel: Bindings.ONE_WAY,
    items: Bindings.ONE_WAY,
    name: Bindings.ONE_WAY,
    elementId: Bindings.ONE_WAY,
    elementClass: Bindings.ONE_WAY,
    material: Bindings.ONE_WAY,
    formControl: Bindings.ONE_WAY,
    label: Bindings.ONE_WAY,
    onUpdate: Bindings.EXPRESSION,
    formatValue: Bindings.EXPRESSION,
  };

  require: any = {
    ngModelController: "ngModel",
  };

  controller: any = PaginatedDropdownController;
  templateUrl: string = "html/components/paginated_dropdown";
}

angular.module("BookingApp").component("paginatedDropdown", new PaginatedDropdownComponent());
