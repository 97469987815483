import { UrlUtils } from "booking_app/utils";
import { CurrentPage, RoomInfoItem } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";
import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { HotelDetailsService } from "booking_app/services/hotels/hotel-details.service";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { PointsCashPaymentHandlingService } from "booking_app/services/points-cash-payment-handling.service";

declare var moment: any;

export class GroupedRoomListItemController {
  static $inject = [
    "$rootScope",
    "$scope",
    "$translate",
    "UrlUtils",
    "AppSettings",
    "HotelDetailsService",
    "AppConfig",
    "GlobalStateService",
    "PointsCashPaymentHandlingService",
  ];

  // bindings
  private room: RoomInfoItem;
  private policies: any;
  private pointsPartner: any;
  private redirectToHotelCheckoutPage: () => void;

  private adminDataOn: boolean;
  private nightOfStay: number;

  constructor(
    private $rootScope: any, // Being used inside (didn't want to pass everything inside)
    private $scope: any,
    private $translate: any,
    private urlUtils: UrlUtils,
    private appSettings: AppSettings,
    private hotelDetailsService: HotelDetailsService,
    private appConfig: any,
    private globalStateService: GlobalStateService,
    private pointsCashPaymentHandlingService: PointsCashPaymentHandlingService,
  ) {
    this.nightOfStay = this.$rootScope.duration;
    this.$scope.showGroupedActions = this.appSettings.hotelDetailsTemplateConfig.showGroupedActions;
    this.$scope.showCurrencyIcon = this.appSettings.hotelDetailsTemplateConfig.showCurrencyIcon;
    this.$scope.earnOnRedeem = this.appSettings.hotelDetailsTemplateConfig.earnOnRedeem;
    this.$scope.showPerNightText = this.appSettings.showPerNightText;
    this.$scope.showCashBeforePoints = this.appSettings.showCashBeforePoints;
    this.$scope.globalStateService = this.globalStateService;
  }

  breakfastLogoUrl(): string {
    if (this.room.roomDescriptionDetail !== "hotel_detail_room_only") {
      return this.urlUtils.imageUrl(this.appSettings.hotelDetailsTemplateConfig.breakfastIconUrl);
    } else {
      return this.urlUtils.imageUrl(this.appSettings.hotelDetailsTemplateConfig.noBreakfastIconUrl);
    }
  }

  goToHotelCheckoutPage(): void {
    this.redirectToHotelCheckoutPage();
  }

  shouldShowFreeCancellation(): boolean {
    return this.hotelDetailsService.shouldShowFreeCancellation();
  }

  hasCancellationText(): boolean {
    return !!this.roomPolicy().freeCancellationText && this.shouldShowFreeCancellation();
  }

  cancellationText(): string {
    if (this.appConfig.cancellation_fee_percentage) {
      return this.$translate.instant(this.appSettings.cancellationFeeText.details);
    } else {
      return this.$translate.instant("Free cancellation");
    }
  }

  // to check if loaded
  hasPolicy(): boolean {
    return !!this.roomPolicy() && Object.prototype.hasOwnProperty.call(this.roomPolicy(), "refundable");
  }

  toggleAdminData(e): void {
    this.adminDataOn = !this.adminDataOn;
    e.stopPropagation();
  }

  formatCancellationDate(policy: any, dateFormat: string = "ddd, D MMM"): string {
    const date = policy.freeCancellationText || policy.free_cancellation_before;
    const momentDate = moment(date);
    let dateString: string;

    if (momentDate.isValid()) {
      dateString = momentDate.format(dateFormat);
    } else {
      dateString = date;
    }

    return this.$translate.instant("txt.before_cancellation_date", { date: dateString });
  }

  isMobile(): boolean {
    return this.$rootScope.globalState.browserWidth < ViewportSizes.XS_MAX;
  }

  roomItemHiddenHeadingText(): string {
    const roomName: string = this.room.roomDescriptionShort;
    const roomOption: string = this.$translate.instant(this.room.roomDescriptionDetail);
    let heading: string = `${roomName}, ${roomOption}`;

    if (!this.hasPolicy()) {
      return heading;
    }

    if (this.hasCancellationText()) {
      const cancellationDate: string = this.formatCancellationDate(
        this.policies[this.room.key],
        "MMMM Do YYYY",
      );
      heading = heading + `, with ${this.cancellationText()} ${cancellationDate}`;
    } else {
      heading = heading + ", non refundable";
    }

    return heading;
  }

  zeroFullCashPayment(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(
      this.room.cash_payment,
      this.room.points_payment,
    );
  }

  zeroFullCashPaymentPerNight(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(
      this.room.cash_payment_per_night,
      this.room.points_payment_per_night,
    );
  }

  private roomPolicy(): any {
    return this.policies[this.room.key];
  }
}
