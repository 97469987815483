import { UrlUtils } from "booking_app/utils";
import { ModalManager} from "booking_app/components/common/modal-manager";
import { HotelImageService } from "booking_app/services/hotel-image.service";

export class GalleryModalButtonController {
  static $inject = [
    "HotelImageService",
    "UrlUtils",
    "ModalManager",
  ];

  // Bindings
  images: string[];
  defaultImage: any;
  currentGalleryIndex: number;
  hotelId: string;

  constructor(
    private hotelImageService: HotelImageService,
    private urlUtils: UrlUtils,
    private modalManager: ModalManager,
  ) {
  }

  openGalleryModal($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.openModalInstance();
  }

  photosIcon(): string {
    return this.urlUtils.imageUrl(`/icons/photos-icon.png`);
  }

  private openModalInstance(): void {
    this.modalManager.open({
      animation: true,
      templateUrl: "/html/modals/gallery_modal",
      controller: "GalleryModal",
      size: "gallery-lg",
      windowClass: "center-modal",
      backdropClass: "gallery-backdrop",
      resolve: {
        images: () => this.highResImage(),
        defaultImage: () => this.defaultImage,
        currentGalleryIndex: () => this.currentGalleryIndex,
      },
    });
  }

  private highResImage(): string[] {
    const highResImage: string[] = this.hotelImageService.getHotelHighResImagesSet(this.hotelId);
    highResImage.forEach((part, index, images) => {
      images[index] = `${images[index]}?fit=cover&h=1350&w=1800`;
    });
    return highResImage;
  }
}
