import { CardTypeService } from "booking_app/services/card-type.service";
import { AppSettings } from "booking_app/values/app-settings";

angular.module("BookingApp")
  .directive("validateCreditCard", [
    "$rootScope",
    "CardTypeService",
    "AppSettings",
    (
      $rootScope: any,
      cardTypeService: CardTypeService,
      appSettings: AppSettings,
    ) => {
      return {
        require: "ngModel",
        link: (scope: any, elem: any, attrs: any, ctrl: any) => {
          let ccNumber = "";

          const validate = (ccNumberInput: string): void => {
            let cardType: any;
            let isSupported: boolean;
            let isValid: boolean;
            let supportCheck: any;
            ccNumberInput = ccNumberInput === "" ? ctrl.$viewValue : ccNumberInput;
            if (!!ccNumberInput) {
              ctrl.$setDirty();
            }

            cardType = cardTypeService.getCardType(ccNumberInput, false);
            if (scope.bookingDetails) {
              scope.bookingDetails.payment.card.type = cardType || "";
            }
            isValid = !!cardType;
            isSupported = true;
            // if card is valid, check if it is supported by the selected currency
            if (isValid) {
              supportCheck = appSettings.supportedCards[cardType];
              // if supportCheck is null, we assume that it support all currency
              if (supportCheck && supportCheck.indexOf($rootScope.selectedCurrency.code) < 0) {
                isSupported = false;
              }

              // for amex card, display error message when it is not supported
              if (scope.creditcard) {
                if (cardType === "amex") {
                  scope.creditcard.warnAmex = !(isValid && isSupported);
                }
              }
            }
            // if card is valid and currency is supported, we call the backend validation
            if (isValid && isSupported) {
              if (typeof scope.ccValidation === "function") {
                scope.ccValidation(ccNumberInput);
              }
              if (scope.$ctrl && typeof scope.$ctrl.ccValidation === "function") {
                scope.$ctrl.ccValidation(ccNumberInput);
              }
            }
            ctrl.$setValidity("creditCardType", isValid && isSupported);
          };

          ctrl.$parsers.unshift((ccNumberInput: string): string => {
            ccNumber = ccNumberInput;
            validate(ccNumber);
            return ccNumber;
          });

          scope.$watch("selectedCurrency", ((): void => {
            if (angular.isDefined(ctrl.$viewValue)) {
              ctrl.$setViewValue(ctrl.$viewValue);
            }
            validate(ccNumber);
          }), true);
        },
      };
    },
  ]);
