import { MapboxService } from "../mapbox";
import { TrackerService } from "../tracker";
import { HotelsSearchResult } from "booking_app/models";
import { ApiDataService } from "../api-data.service";
import { AppSettings } from "booking_app/values/app-settings";
import { UserActionService } from "booking_app/services/user-action.service";
import { UserActionType } from "booking_app/types/user-action";

declare var moment: any;

export class HotelsSearchService {

  static $inject = [
    "$location",
    "$rootScope",
    "$window",
    "$q",
    "SearchUrlService",
    "SearchFiltersService",
    "TrackerService",
    "SearchSortingService",
    "BusinessFiltersService",
    "KaligoSearch",
    "KaligoConfig",
    "AppSettings",
    "UtilsService",
    "KaligoConstants",
    "MapboxService",
    "ApiDataService",
    "UserActionService",
  ];

  searchForm: any;
  guestsInfo: any;

  constructor(
    public $location,
    public $rootScope,
    public $window,
    private $q: any,
    public SearchUrlService,
    public SearchFiltersService,
    public trackerService: TrackerService,
    public SearchSortingService,
    public BusinessFiltersService,
    public KaligoSearch,
    public KaligoConfig,
    private appSettings: AppSettings,
    public UtilsService,
    public KaligoConstants,
    public mapboxService: MapboxService,
    private apiDataService: ApiDataService,
    private userActionService: UserActionService,
  ) {}

  public init() {
    this.variableSetups();
  }

  public perform() {
    if (this.preventSearch()) { return; }
    this.trackUserDestinationSearch();
    this.$rootScope.destination = (this.KaligoSearch.form.destination?.label || this.$rootScope.destination);
    this.$rootScope.checkInDate = this.KaligoSearch.form.checkin;
    this.$rootScope.checkOutDate = this.KaligoSearch.form.checkout;
    this.$rootScope.pointsPartner = this.KaligoSearch.form.pointsPartner;

    const childrenAges = this.UtilsService.getChildrenAges(
      this.KaligoSearch.form.children,
      this.KaligoSearch.form.childrenAges,
    );
    this.$rootScope.guests = this.UtilsService.formatToGuests(
      this.KaligoSearch.form.rooms,
      this.KaligoSearch.form.adults,
      childrenAges,
    );

    const searchUrl = this.SearchUrlService.createSearchUrl();

    if (this.isSearchParamsNotChanged(searchUrl)) { return this.closeSearchForm(); }

    this.trackerService.hotelsSearch(this.KaligoSearch.form);
    this.resetSearchResultState();

    this.$rootScope.isLoading = true;
    this.$rootScope.globalState.isNewSearch = this.isInSearchResultPage();

    if (this.appSettings.reloadOnSearch) {
      this.$window.location.href = searchUrl;
    } else {
      this.$location.url(searchUrl);
    }

    return this.closeSearchForm();
  }

  public hotelsLocationSearch(name: string): Promise<HotelsSearchResult[]> {
    return this.$q((resolve, reject) => {
      if (!name || name === "") {
        reject("Empty name");
      } else {
        this.apiDataService.get(`destinations?name=${name}`)
          .then((res: HotelsSearchResult[]) => {
            resolve(res);
          })
          .catch(error => reject(error));
      }
    });
  }

  private isInSearchResultPage(): boolean {
    return this.$location.path().indexOf("/results") !== -1;
  }

  // TODO: this.KalgioSearch.form.form is very confusing
  // https://github.com/Kaligo/kaligo-web/pull/4583#discussion_r229570956
  private isFormValid(): boolean {
    this.KaligoSearch.form.form.submitted = true;
    return (
      this.KaligoSearch.form.form?.$valid &&
      (this.KaligoSearch.form.destination?.value || this.$rootScope.destinationId)
    );
  }

  private isSearchParamsNotChanged(searchUrl): boolean {
    return searchUrl === decodeURIComponent(this.$window.location.pathname + this.$window.location.search);
  }

  private preventSearch(): boolean {
    return !this.isFormValid();
  }

  private closeSearchForm(): void {
    if (this.KaligoSearch.close) {
      this.KaligoSearch.close();
    }
  }

  private variableSetups() {

    const checkInDate = this.$rootScope.checkInDate ||
      moment().add(this.appSettings.startCheckInDate, "d").format("MM/DD/YYYY");

    const minBookingDays = this.$rootScope.landingPage.minBookingDays;

    const checkOutDate = this.$rootScope.checkOutDate ||
      moment(checkInDate, "MM/DD/YYYY").add(minBookingDays, "d").format("MM/DD/YYYY");

    this.KaligoSearch.form.setCheckin(checkInDate);
    this.KaligoSearch.form.setCheckout(checkOutDate);

    this.$rootScope.globalState.isSelectingPartner = false;
    this.$rootScope.globalState.isCancellationPolicyError = false;
    this.KaligoSearch.form.pointsPartner = angular.copy(this.$rootScope.pointsPartner);
    this.guestsInfo = this.UtilsService.parseGuestsParams(this.$rootScope.guests);
    this.KaligoSearch.form.rooms = this.guestsInfo.roomCount || this.KaligoConstants.DEFAULT.roomCount;
    this.KaligoSearch.form.adults = this.guestsInfo.adultCount || this.KaligoConstants.DEFAULT.adultCount;
    this.KaligoSearch.form.children = this.guestsInfo.childrenCount || this.KaligoConstants.DEFAULT.childrenCount;

    if (this.guestsInfo.childrenAges && (this.guestsInfo.childrenAges.length > 0)) {
      // reduce the guestsInfo.childrenAges into [0, 0 ,0, 0] array format
      this.KaligoSearch.form.childrenAges = this.guestsInfo.childrenAges.reduce((arr, val, index) => {
        arr[index] = val;
        return arr;
      }, this.KaligoConstants.DEFAULT.childrenAges);
    } else {
      this.KaligoSearch.form.childrenAges = this.KaligoConstants.DEFAULT.childrenAges;
    }

    this.KaligoSearch.form.destination = null; // need to reset for better experience
  }

  private resetSearchResultState(): void {
    this.mapboxService.reset();
    this.SearchFiltersService.reset();
    this.SearchSortingService.reset();
    this.BusinessFiltersService.reset();
  }

  private trackUserDestinationSearch(): void {
    if (this.$rootScope.searchState.destination && this.appSettings.enableUserActionTracking) {
      const id: string = this.$rootScope.searchState.destination.value.hotel_id ||
        this.$rootScope.searchState.destination.value.destination_id;
      this.userActionService.postUserAction(UserActionType.DESTINATION, id);
    }
  }
}

angular.module("BookingApp").service("HotelsSearchService", HotelsSearchService);
