import { ModalManager } from "booking_app/components/common/modal-manager";
import { AppSettings } from "booking_app/values/app-settings";

export class ManageBookingModal {
  static $inject = [
    "ModalManager",
    "AppSettings",
    "$window",
    "$scope",
  ];

  constructor(
    private modalManager: ModalManager,
    private appSettings: AppSettings,
    private $window: any,
    private $scope: any,
  ) {
    this.$scope.close = () => {
      this.modalManager.close();
    };

    this.$scope.yes = () => {
      this.$window.location.reload();
    };

    this.$scope.closeRefresh = () => {
      this.$window.location.reload();
    };

    this.$scope.tenant = this.appSettings.tenant.toLowerCase();
  }
}

angular.module("BookingApp").controller("ManageBookingModal", ManageBookingModal);
