import "booking_app/values/app-settings";
import "../services/currencies.service";

angular.module("BookingApp")
  .filter("codeSymbolFmt", [
    "AppSettings", "CurrenciesService",
    (AppSettings, CurrenciesService) => (
      (currencyCode: string) => CurrenciesService.
          findByCode(currencyCode)[AppSettings.defaultCurrencyDisplay]
    ),
  ]);
