declare var moment: any;
import { AppSettings } from "booking_app/values/app-settings";

export class CancelBookingConfirmModal {
  static $inject = [
    "AppSettings",
    "modalContent",
    "ManageBooking",
    "$rootScope",
    "$scope",
    "$modalInstance",
    "$translate",
  ];

  constructor(
    private appSettings: AppSettings,
    private modalContent: any,
    private manageBooking: any,
    private $rootScope: any,
    private $scope: any,
    private $modalInstance: any,
    private $translate: any,
  ) {
    this.$scope.modalHeader = modalContent.modalHeader;
    this.$scope.modalBody = modalContent.modalBody;
    this.$scope.modalYes = modalContent.modalYes;
    this.$scope.modalNo = modalContent.modalNo;
    this.$scope.booking = modalContent.booking;
    this.$scope.token = modalContent.token;
    this.$scope.cancelling = false;
    this.$scope.deferred = modalContent.deferred;

    this.$scope.close = () => {
      this.$modalInstance.dismiss("close");
    };

    this.$scope.yes = () => {
      this.$scope.modalBody = this.$translate.instant("Cancelling...");
      this.$scope.cancelling = true;
      this.manageBooking.processCancelBooking(
        this.$scope.booking.id,
        this.$scope.token,
        this.$scope.deferred,
        this.$modalInstance,
      );
    };

    this.$scope.no = () => {
      this.$modalInstance.close(false);
      if ($scope.deferred) {
        this.$scope.deferred.resolve("abort");
      }
    };

    this.$scope.displayCancellation = () => {
      return $scope.booking && $scope.booking.status !== "pending" && $scope.booking.status !== "verification_required";
    };

    this.$scope.nonCancellable = () => {
      return $scope.booking.payment_type === "free_night_voucher" ||
        ($scope.booking.paid_in_points > 0 && !$scope.appSettings.showCancelPolicyOnRedeem);
    };

    this.$scope.nonRefundable = () => {
      return $scope.booking.cancellation_policy.refundable === "no";
    };

    this.$scope.nonRefundableFrom = () => {
      if ($scope.booking.cancellation_policy.non_refundable_from) {
        return moment.utc($scope.booking.cancellation_policy.non_refundable_from).format("lll");
      }
    };

    this.$scope.displayPenaltyInPercentage = () => {
      return $scope.booking.paid_in_points > 0;
    };
  }
}

angular.module("BookingApp").controller("CancelBookingConfirmModal", CancelBookingConfirmModal);
