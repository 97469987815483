require('./cookie.service')
require('./locales_factory')
require('./utils')
require('../values/app-settings')
require('./e2e/e2e-user-preference.service')

angular.module("BookingApp")

.service "DefaultLocale", [

  "$location", "$rootScope", "$translate", '$q', "CookieService", "LocalesFactory", "UtilsService", "AppSettings", "E2eUserPreferenceService"
  ($location, $rootScope, $translate, $q, CookieService, LocalesFactory, UtilsService, AppSettings, E2eUserPreferenceService) ->

    self = this
    self.localesFactory = null

    restrictedLocaleCodes = AppSettings.restrictedLocales

    _translateDefer = $q.defer()
    _translateInit = false

    @defer = (cb) ->
      if _translateInit
        cb()
      else
        _translateDefer.promise.then(cb)

    @init = (languages) ->
      self.localesFactory = LocalesFactory.init(languages)
      $rootScope.supportedLocales = self.localesFactory.all()
      $rootScope.enabledLocales = UtilsService.filterArrayAttr(
        $rootScope.supportedLocales,
        ((locale.code for locale in $rootScope.supportedLocales when locale.code not in restrictedLocaleCodes)),
        'code'
      )

      # This event is emitted when a language translation is completed when using an async loader.
      # Because we're using an async loader, sometimes it may take up to a few seconds to load the translation
      # causing some race condition with TranslationService.
      $rootScope.$on '$translateChangeSuccess', ->
        _translateInit = true
        _translateDefer.resolve(true)
        loadedLanguage = $translate.use()
        CookieService.setLocale(loadedLanguage)
        $rootScope.selectedLocale = angular.copy(self.localesFactory.findByCode(loadedLanguage) || self.localesFactory.findByCode('en'))

      $rootScope.globalState.changeCurrency = (currencyCode) ->
        if currencyCode && currencyCode?
          $rootScope.selectedCurrency = $rootScope.currenciesService.findByCode(currencyCode)
        #  use the ? syntax so that it will check of undefined or null
        #  if not, the uglification will simply check for !== 0 which will not work!
        if $rootScope.selectedCurrency?
          $rootScope.convert_rate = $rootScope.selectedCurrency.rate
          CookieService.setCurrency($rootScope.selectedCurrency.code)

      $rootScope.globalState.changeLocale = (langCode) ->
        if langCode
          $rootScope.selectedLocale = locale for locale in $rootScope.supportedLocales when locale.lang_code == langCode
        return unless $rootScope.selectedLocale

        $translate.use($rootScope.selectedLocale.lang_code)
        if AppSettings.e2eWhitelabel && $rootScope.isUserLoggedIn()
          E2eUserPreferenceService.updatePreferences({ locale: $rootScope.selectedLocale.code })

      urlParams = $location.search()
      langCode = urlParams.lang
      langCode = "zh-TW" if urlParams.lang == "zh-HK" || urlParams.lang == "zh-tw"

      localeToUse = (
        langCode ||
        CookieService.getLocale() ||
        CookieService.getGeoLocale() ||
        'en'
      )

      localeToUse = 'en' if !self.localesFactory.findByCode(localeToUse)

      $rootScope.globalState.changeLocale(localeToUse)

      return

    return this
]
