import { Bindings } from "booking_app/types";

import { MapDirectionPopupController } from "./map-direction-popup.controller";

export class MapDirectionPopupComponent {
  bindings: any = {
    pickUpState: Bindings.ONE_WAY,
    address: Bindings.ONE_WAY,
  };

  controller: any = MapDirectionPopupController;
  template: string = `
    <div class="map-box-popup-container">
      <div class="header-title" translate="{{ $ctrl.headerTranslation() }}"></div>
      <div class="address-header" ng-bind="$ctrl.locationValue()"></div>
      <div class="address" ng-if="$ctrl.shouldShowAddressLine()" ng-bind="'{{ $ctrl.address }}'"></div>
    </div>
  `;
}

angular.module("BookingApp").component("mapDirectionPopup", new MapDirectionPopupComponent());
