import { HotelItem } from "booking_app/types/hotel-item";

interface CategoryScores {
  business: number;
  family: number;
  couple: number;
  solo: number;
}

interface CategoryFilters {
  business: false;
  family: false;
  couple: false;
  solo: false;
}

type Scores = CategoryScores;
type Filters = CategoryFilters;

export class BusinessFiltersService {

  categoryFilters: CategoryFilters;

  private readonly minimumScores: Scores = {
    business: 80,
    family: 80,
    couple: 80,
    solo: 80,
  };

  private readonly defaultCategoryFilters: CategoryFilters = {
    business: false,
    family: false,
    couple: false,
    solo: false,
  };

  constructor() {
    this.categoryFilters = angular.copy(this.defaultCategoryFilters);
  }

  public filterByPopularCategory(hotels: HotelItem[]): HotelItem[] {
    if (!this.hasEnabledCategoryFilters()) {
      return hotels;
    }

    return hotels.filter((hotel) => {
      const scores = hotel.trustyou && hotel.trustyou.score;
      return scores && !this.isScoreTooLow(scores, this.categoryFilters);
    });
  }

  public reset(): void {
    angular.copy(this.defaultCategoryFilters, this.categoryFilters);
  }

  private hasEnabledCategoryFilters(): boolean {
    return Object.values(this.categoryFilters).some(filter => filter);
  }

  private isScoreTooLow(scores: Scores, filters: Filters): boolean {
    for (const filter in filters) {
      if (!this.isFilterEnabled(filter)) {
        continue;
      }

      if (!scores[filter] || scores[filter] < this.minimumScores[filter]) {
        return true;
      }
    }

    return false;
  }

  private isFilterEnabled(filter: string): boolean {
    return this.categoryFilters[filter];
  }
}

angular.module("BookingApp").service("BusinessFiltersService", BusinessFiltersService);
