angular.module("BookingApp")
  .run([
    '$templateCache',
    "$translate",
    function ($templateCache) {
      // Override existing drop down select item template
      $templateCache.put('ngDropdowns/templates/dropdownSelectItem.html',
        `<li class="col-xs-6">
        <a href="" class="dropdown-item" ng-if="::!dropdownSelectItem.divider"'
          ng-href="{{::dropdownSelectItem.href}}"' ng-click="selectItem()">
          <div ng-if="::dropdownSelectItem['flag_code']">
            <div class="flag flag-{{::dropdownSelectItem['flag_code']}} dropdown-flag-item"></div>
            <div class='dropdown-item-name'>{{::dropdownSelectItem[dropdownItemLabel]}}</div>
          </div>
          <div class="row dropdown-currency-item" ng-if="::dropdownSelectItem['symbol']">
            <div class="dropdown-currency-symbol col-xs-3" ng-bind="::dropdownSelectItem['symbol']"></div>
            <div class="dropdown-currency-code col-xs-9" ng-bind="::dropdownSelectItem['code']"></div>
          </div>
        </a>
        <span ng-if="::dropdownSelectItem.divider">{{::dropdownSelectItem[dropdownItemLabel]}}</span>
      </li>`)

      // Override existing dropdown select text template
      $templateCache.put('ngDropdowns/templates/dropdownSelect.html',
        `<div class="wrap-dd-select" role="link">
        <div ng-if="::dropdownModel['flag_code']"
          class="flag flag-{{dropdownModel['flag_code'] | lowercase}} dropdown-flag">
        </div>
        <span class="dropdown-selected">{{dropdownModel[labelField]}}</span>
        <ul class="dropdown">
          <li ng-repeat="item in ::dropdownSelect"
           class="dropdown-item"
           dropdown-select-item="item"
           dropdown-item-label="labelField">
          </li>
        </ul>
      </div>`
      )

      // New template for the ui-select extended component
      $templateCache.put("bootstrap/select.destination.tpl.html",
        `<div class="ui-select-container ui-select-bootstrap dropdown"
          ng-class="{open: $select.open}"
          role="combobox"
          aria-expanded="{{ $select.open ? 'true' : 'false' }}"
          aria-haspopup="listbox"
          ng-focus="$select.activate()"
          tabindex=0>
          <div class="ui-select-match"></div>
          <input type="search" autocomplete="off"
                aria-label="Search for"
                aria-owns="ui-select-choices-{{ $select.generatedId }}"
                class="form-control ui-select-search ui-select-destination-search"
                ng-class="{ 'ui-select-search-hidden' : !$select.searchEnabled, 'ui-select-invisible': !$select.open }"
                placeholder="{{$select.placeholder}}"
                ng-model="$select.search"
                ng-focus="$select.activate()" >
          <span ng-show="$select.open && $select.refreshing  && $select.spinnerEnabled"
                class="ui-select-refreshing {{$select.spinnerClass}}"></span>
          <div class="ui-select-choices"></div>
          <div class="ui-select-no-choice"></div>
        </div>`,
      );

      $templateCache.put("bootstrap/choices.tpl.html",
        `
        <ul class="ui-select-choices ui-select-choices-content ui-select-dropdown dropdown-menu"
            ng-show="$select.open && $select.items.length > 0">
          <li class="ui-select-choices-group"
              id="ui-select-choices-{{ $select.generatedId }}"
              role="listbox">
            <div class="divider" ng-show="$index > 0" ng-if="$select.isGrouped"></div>
            <div ng-if="$select.isGrouped"
                 class="ui-select-choices-group-label dropdown-header {{ $group.name }}"
                 ng-bind="$group.name | translate"></div>
            <div ng-attr-id="ui-select-choices-row-{{ $select.generatedId }}-{{$index}}"
                 id="ui-select-choices-row-{{ $select.generatedId }}-{{$index}}"
                 class="ui-select-choices-row"
                 ng-class="{active: $select.isActive(this), disabled: $select.isDisabled(this)}"
                 aria-selected="{{$select.isActive(this) ? 'true' : 'false'}}"
                 role="option">
              <span class="ui-select-choices-row-inner"></span>
            </div>
          </li>
        </ul>
        `,
      );
    },
  ]);
