import { Bindings } from "booking_app/types";
import { DateRangeFieldController } from "./date-range-field.controller";

export class DateRangeFieldComponent {
  bindings: any = {
    startDate: Bindings.ONE_WAY,
    endDate: Bindings.ONE_WAY,
    minDaysToBookInAdvance: Bindings.ONE_WAY,
    minBookingDays: Bindings.ONE_WAY,
    onUpdate: Bindings.EXPRESSION,
    locale: Bindings.ONE_WAY,
    dateFormat: Bindings.ONE_WAY,
    numberOfMonths: Bindings.ONE_WAY,
  };
  controller: any = DateRangeFieldController;
  template: string = `
    <div class="date-range-field">
      <div class="transclude-container" ng-transclude></div>
      <div
        class="date-range-field-modal" ng-class="$ctrl.selectionState + '' | lowercase"
        ng-if="$ctrl.modalOpen">
        <date-picker
          min-date="$ctrl.minDatepickerDate()"
          start-date="$ctrl.startDate"
          end-date="$ctrl.endDate"
          on-select-start-date="$ctrl.updateStartDate(value)"
          on-select-end-date="$ctrl.updateEndDate(value)"
          selection-state="$ctrl.selectionState"
          locale="$ctrl.locale"
          number-of-months="$ctrl.numberOfMonths"
        ></date-picker>
      </div>
    </div>
  `;
  transclude: boolean = true;
}

angular.module("BookingApp").component("dateRangeField", new DateRangeFieldComponent());
