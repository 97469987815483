import { PaymentMethod } from "booking_app/types/payment-method";
import { SavedCard } from "booking_app/models/saved-card";
import { PaymentMethodService } from "booking_app/services/payment-method.service";
import { PayAnyoneService } from "booking_app/services/payments/pay-anyone.service";
import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { AppSettings } from "booking_app/values/app-settings";
import { StripePaymentIntentService } from "booking_app/services/stripe-payment-intent.service";

export class PaymentMethodController {
  static $inject = [
    "$rootScope",
    "PaymentMethodService",
    "AppSettings",
    "PayAnyoneService",
    "StripePaymentIntentService",
  ];

  // For removeCreditCard callback binding, type declaration
  // has been intentionally left out to avoid
  // expression binding variables becoming undefined during runtime
  removeCreditCard;
  addNewCardText;

  constructor(
    private $rootScope: any,
    private paymentMethodService: PaymentMethodService,
    private appSettings: AppSettings,
    private payAnyoneService: PayAnyoneService,
    private stripePaymentIntentService: StripePaymentIntentService,
  ) {
  }

  public $onInit(): void {
    this.paymentMethodService.activePaymentTab = this.appSettings.defaultPaymentMethod;
    this.addNewCardText = this.appSettings.checkoutSettings.addNewCardText;
  }

  public isPaymentType(type: PaymentMethod): boolean {
    return this.paymentMethodService.activePaymentTab === type;
  }

  public setActiveTab(paymentMethod: PaymentMethod): void {
    if (paymentMethod !== PaymentMethod.ADD_CARDS) {
      this.stripePaymentIntentService.resetPaymentIntentFields();
    }
    this.paymentMethodService.setActiveTab(paymentMethod);
  }

  public isMobile(): boolean {
    return this.appSettings.mobileFirst || this.$rootScope.globalState.browserWidth < ViewportSizes.XS_MAX;
  }

  public showSavedCards(): boolean {
    return this.$rootScope.isUserLoggedIn() &&
      this.paymentMethodService.hasSavedCards();
  }

}
