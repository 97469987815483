declare var angular: any;

import { GlobalStateService } from "booking_app/services/global-state.service";
import { TravelType } from "booking_app/types/travel-type";

export interface Banner {
  slot: string;
  image_url: string;
  target_url: string;
  target_url_text: string;
  user_segment: string;
  travel_type: TravelType;
  text_content: string;
  text_title: string;
  deep_link_url: string;
  deep_link_text: string;
  banner_type: "normal" | "deep_link";
  group_name: string;
  search_params: any;
  active_date: string;
}

export enum BannerType {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}

export class BannersService {

  static $inject = [
    "$rootScope",
    "GlobalStateService",
  ];

  constructor(
    private $rootScope: any,
    private globalStateService: GlobalStateService,
  ) { }

  find(slot: string): Banner {
    if (this.$rootScope.isUserLoggedIn()) {
      const segments = this.$rootScope.userDetails.user.segments || {};

      return this.findBannerForSegment(slot, segments.banner) || this.findDefaultBanner(slot);
    } else {
      return this.findDefaultBanner(slot);
    }
  }

  findBanners(groupName: string): Banner[] {
    let banners: Banner[];
    if (this.$rootScope.isUserLoggedIn()) {
      const segments = this.$rootScope.userDetails.user.segments || {};
      banners = this.findCarouselBannersForSegment(segments.banner, groupName)
        || this.findDefaultCarouselBanners(groupName);
    } else {
      banners = this.findDefaultCarouselBanners(groupName);
    }
    return this.getMostRecentBanners(banners);
  }

  private findDefaultCarouselBanners(groupName: string): Banner[] {
    const isEmpty = (segment: string) => segment === null || segment === "";
    let defaultBanner: Banner[] = this.$rootScope.banners.filter((banner: Banner) => {
      return isEmpty(banner.user_segment) &&
      banner.travel_type === this.globalStateService.travelType &&
      banner.group_name === groupName;
    });

    if (defaultBanner.length === 0) {
      defaultBanner = this.$rootScope.banners.filter((banner: Banner) => {
        return isEmpty(banner.user_segment) && banner.group_name === groupName;
      });
    }

    return defaultBanner;
  }

  private findCarouselBannersForSegment(
    segment: string,
    groupName: string,
  ): Banner[] {
    let segmentBanners: Banner[] = this.$rootScope.banners.filter((banner: Banner) => {
      return banner.user_segment === segment &&
        banner.travel_type === this.globalStateService.travelType &&
        banner.group_name === groupName;
    });

    if (segmentBanners.length === 0) {
      segmentBanners = this.$rootScope.banners.filter((banner: Banner) => {
        return banner.user_segment === segment &&
          banner.travel_type === null && banner.group_name === groupName;
      });
    }
    return segmentBanners;
  }

  private findBannerForSegment(slot: string, segment: string): Banner { 
    let segmentBanners: Banner[] = this.$rootScope.banners.filter((banner: Banner) => {
      return banner.slot === slot && banner.user_segment === segment &&
        banner.travel_type === this.globalStateService.travelType;
    });

    if (!segmentBanners) {
      segmentBanners = this.$rootScope.banners.filter((banner: Banner) => {
        return banner.slot === slot && banner.user_segment === segment &&
          banner.travel_type === null;
      });
    }

    return segmentBanners.length > 0 ? this.getMostRecentBanners(segmentBanners)[0] : null;
  }

  private findDefaultBanner(slot: string): Banner {
    const isEmpty = (segment: string) => segment === null || segment === "";
    const current_date = new Date();
    let defaultBanner: Banner = this.$rootScope.banners.find((banner: Banner) => {
      return banner.slot === slot && isEmpty(banner.user_segment) &&
      banner.travel_type === this.globalStateService.travelType &&
      new Date(banner.active_date) <= current_date;
    });

    if (!defaultBanner) {
      defaultBanner = this.$rootScope.banners.find((banner: Banner) => {
        return banner.slot === slot && isEmpty(banner.user_segment);
      });
    }

    return defaultBanner;
  }

  private  getMostRecentBanners(banners: any[]): any[] {
    const current_date = new Date();
    const activeBanners = banners.filter(banner => new Date(banner.active_date) <= current_date);
    return [activeBanners.reduce((prev, curr) => (new Date(curr.active_date) > new Date(prev.active_date) ? curr : prev))];
  }

}

angular.module("BookingApp").service("BannersService", BannersService);
