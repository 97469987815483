import { Booking } from "../../models";

declare var angular: any;

export class BookingSuccessTrackingComponent {

  public bindings: any;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      booking: "<",
    };
    this.templateUrl = "/html/components/booking_success_tracking";
  }
}

angular.module("BookingApp").component('bookingSuccessTrackingComponent', new BookingSuccessTrackingComponent());
