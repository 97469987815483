import { IDeferred } from "angular";

export class ApiDataService {

  static $inject = ['$http', '$q', '$timeout'];

  processing: boolean;

  constructor(
    private $http,
    private $q,
    private $timeout,
  ) { 
    this.processing = false;
  }

  call(url: string, options: {[key: string]: any} = {}) {
    return this.$http({
      method: 'GET',
      url: url,
      cache: options.cache || false
    }).then((res) => {
      return res.data || options.defaultData;
    }, (error) => {
      this.$q.reject(error.data || options.defaultData);
    });
  }

  get(path: string, apiPrefix: boolean = true): ng.IPromise<{}> {
    let defer = this.$q.defer();
    
    const fullPath = apiPrefix ? "/api/" + path : path;
    
    this.$http.get(fullPath, {
      timeout: defer.promise
    }).then((res) => {
      if (!res.data || res.data.error) {
        this.returnError(res.data, defer);
      } else {
        defer.resolve(res.data);
      }
    }, (res) => {
      this.returnUnexpectedError(res.data, res.status, defer);
    });
    
    return defer.promise;
  }

  getJSON(path: string, apiPrefix: boolean = true): ng.IPromise<{}> {
    let defer = this.$q.defer();

    const fullPath = apiPrefix ? "/api/" + path : path;

    this.$http.get(fullPath, {
      headers: { "accept": "application/json" }
    }).then((res) => {
      if (!res.data || res.data.error) {
        this.returnError(res.data, defer);
      } else {
        defer.resolve(res.data);
      }
    }, (res) => {
      this.returnUnexpectedError(res.data, res.status, defer);
    });

    return defer.promise;
  }

  jsonP(url: string, options: {[key: string]: any} = {}): ng.IPromise<{}> {
    let defer = this.$q.defer();

    this.$http.jsonp(url, options).then((res) => {
      if (!res.data || res.data.error) {
        this.returnError(res.data, defer);
      } else {
        defer.resolve(res.data);
      }
    }, (res) => {
      this.returnUnexpectedError(res.data, res.status, defer);
    });

    return defer.promise;
  }

  post(path: string, data: any = {}, headers: {[key: string]: any} = {}, apiPrefix: boolean = true): ng.IPromise<{}> {
    let defer = this.$q.defer();

    if (this.processing === true) {
      this.$timeout(() => {
        this.returnUnexpectedError({
          msg: "Process still running",
        }, 200, defer);
      }, 1);
      return defer.promise;
    }

    const fullPath = apiPrefix ? "/api/" + path : path;

    this.$http.post(fullPath, data, {
      headers
    }).then((res) => {
      this.processing = false;
      if (res.data.error) {
        this.returnError(res.data, defer);
      } else {
        defer.resolve(res.data);
      }
    }, (res) => {
      this.processing = false;
      this.returnUnexpectedError(res.data, res.status, defer);
    });

    this.processing = true;

    return defer.promise;
  }

  put(path: string, data: any = {}, apiPrefix: boolean = true): ng.IPromise<{}> {
    let defer = this.$q.defer();

    const fullPath = apiPrefix ? "/api/" + path : path;

    this.$http.put(fullPath, data).then((res) => {
      if (!res.data || res.data.error) {
        this.returnError(res.data, defer);
      } else {
        defer.resolve(res.data);
      }
    }, (res) => {
        this.returnUnexpectedError(res.data, res.status, defer);
    });

    return defer.promise;
  }

  delete(path: string, apiPrefix: boolean = true): ng.IPromise<{}> {
    let defer = this.$q.defer();

    const fullPath = apiPrefix ? "/api/" + path : path;
    
    if (this.processing === true) {
      this.$timeout(() => {
        this.returnUnexpectedError({
          msg: "Process still running",
        }, 200, defer);
      }, 1);
      return defer.promise;
    }
    
    this.$http.delete(fullPath).then((res) => {
      this.processing = false;
      if ((!res.data && res.data !== "") || res.data.error) {
        this.returnError(res.data, defer);
      } else {
        defer.resolve(res.data);
      }
    }, (res) => {
      this.processing = false;
      this.returnUnexpectedError(res.data, res.status, defer);
    });

    this.processing = true;

    return defer.promise;
  }

  private returnError(res, defer: ng.IDeferred<any>) {
    defer.reject(res);
  }

  private returnUnexpectedError(res, status: number, defer: ng.IDeferred<any>) {
    const errorRes = this.returnUnexpectedErrorMsg(res, status);
    defer.reject(errorRes);
  }

  private returnUnexpectedErrorMsg(res, status: number) {
    if (status === 500 || status === 501 || status === 502) {
      return { 'errors': ["Unknown error"]};
    }

    if (status === 429) {
      return { errors: ["Rate limit exceeded. Please try again later"] };
    }
      
    if (typeof res === "undefined" || res === null) {
      return { errors: [''] };
    }
      
    if (typeof res === 'object') {
      if (res.error && typeof res.error === 'string') {
          return { 'errors': [res.error] };
        } else if (res.errors) {
          return res;
        } else {
          return { 'errors': [res.toString()] };
        }
      } else {
        return { 'errors': [res] };
      }
  }

}

angular.module('BookingApp').service('ApiDataService', ApiDataService);
