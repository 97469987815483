export class SortService {
  public string(list: any[], key: string): any[] {
    return list.sort((a: any, b: any): number => {
      const aValue: string = a[key] ? a[key].toLowerCase() : "";
      const bValue: string = b[key] ? b[key].toLowerCase() : "";
      if (aValue > bValue) {
        return 1;
      } else if (aValue < bValue) {
        return -1;
      }
      return 0;
    });
  }

  public int(list: any[], key: string, isDescending: boolean = false): any[] {
    return list.sort((a: any, b: any): number => {
      const aValue: number = a[key] ? parseInt(a[key], 10) : 0;
      const bValue: number = b[key] ? parseInt(b[key], 10) : 0;
      let compared: number = 0;
      if (aValue > bValue) {
        compared = 1;
      } else if (aValue < bValue) {
        compared = -1;
      } else {
        return compared;
      }
      return isDescending ? -compared : compared;
    });
  }

  // order = "asc" | "desc"
  public func(list: any[], fun: (any) => number, order: string = "asc"): any[] {
    return list.sort((a: any, b: any): number => {
      const aValue: number = fun(a);
      const bValue: number = fun(b);
      let compared: number = 0;
      if (aValue > bValue) {
        compared = -1;
      } else if (aValue < bValue) {
        compared = 1;
      } else {
        return compared;
      }
      return order === "asc" ? -compared : compared;
    });
  }
}

angular.module("BookingApp").service("SortService", SortService);
