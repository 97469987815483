import { HeaderUrlList } from "booking_app/services/header-url-list";
import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { CurrentPage } from "booking_app/types";
import { PaymentMethodService } from "booking_app/services/payment-method.service";

export class StickyHeaderController {
  static $inject = [
    "$timeout",
    "$rootScope",
    "HeaderUrlList",
    "GlobalStateService",
    "PaymentMethodService",
  ];

  private hideFlag: boolean;
  private navbar: any; // Cant use Element Type here as we need to modify styles
  private customFlagElement: any;
  private currentScrollValue: any;
  private eventDebouncerValue: any;
  private fixedElement: boolean; // Status marker if the element is either affixed to the top or not
  private debounceTimeout: any;

  constructor(
    private $timeout: any,
    private $rootScope: any,
    private headerUrlList: HeaderUrlList,
    private globalStateService: GlobalStateService,
    private paymentMethodService: PaymentMethodService,
  ) {
  }

  $onInit() {
    this.hideFlag = false;
    this.currentScrollValue = 0;
    this.navbar = document.getElementById("sticky");
    this.customFlagElement = document.getElementById("header-static-pages");
    this.setupStickyListener();
    this.fixedElement = true;
  }

  triggerHoverBehaviour(): void {
    this.headerUrlList.updateActiveLink();
  }

  private setupStickyListener(): void {
    window.onscroll = () => {
      const scrollY = window.pageYOffset;
      this.triggerScrollBehaviour(scrollY);
    };
  }

  private triggerScrollBehaviour(scrollY): void {
    const delta = this.currentScrollValue - scrollY;
    if (scrollY === 0) {
      this.scrollOnTopBehaviour();
    } else if (delta < 0 && scrollY > this.navbar.offsetHeight) {
      this.scrollDownBehaviour();
    } else if (delta > 0 && !this.fixedElement) {
      this.scrollUpBehaviour(scrollY);
    }
    this.setCurrentScroll(scrollY);
  }

  private setCurrentScroll(scrollValue: number): void {
    this.currentScrollValue = scrollValue;
  }

  private updateHideFlag(flag: boolean): void {
    this.hideFlag = flag;
  }

  private customElementAction(action: boolean, element: any): void {
    if (window.pageYOffset <= 70) {
      element.classList.remove("hide-transition");
    }

    if (action) {
      if (this.hideFlag) {
        if (this.eventDebouncerValue - window.pageYOffset >= 30) {
          element.classList.remove("hide-transition");
        }
      } else {
        this.eventDebouncerValue = window.pageYOffset;
        this.$timeout.cancel(this.debounceTimeout);
        this.debounceTimeout = this.$timeout(() => {
          this.updateHideFlag(action);
        }, 100);
      }
    } else {
      this.updateHideFlag(action);
    }
  }

  private scrollOnTopBehaviour(): void {
    // Scroll on top of screen
    this.navbar.classList.remove("scroll-up");
    this.addScrollOnTopClass();
    this.navbar.style.position = "relative";
    this.customElementAction(false, this.customFlagElement);
    this.customFlagElement.classList.remove("hide-transition");
    this.fixedElement = true;
  }

  private scrollDownBehaviour(): void {
    // Scroll going down behaviour
    this.navbar.classList.add("scroll-up");
    this.removeScrollOnTopClass();
    this.customFlagElement.classList.add("hide-transition");
    this.updateHideFlag(false);
    this.fixedElement = false;
  }

  private scrollUpBehaviour(scrollY: number): void {
    // Scrolling up behaviour
    this.navbar.classList.remove("scroll-up");
    this.navbar.style.position = "fixed";
    this.customElementAction(true, this.customFlagElement);
  }

  private addScrollOnTopClass(): void {
    this.navbar.classList.add("scroll-on-top");
    this.customFlagElement.classList.add("scroll-on-top");
  }

  private removeScrollOnTopClass(): void {
    this.navbar.classList.remove("scroll-on-top");
    this.customFlagElement.classList.remove("scroll-on-top");
  }

  private isMobile(): boolean {
    return this.$rootScope.globalState.browserWidth <= ViewportSizes.XS_MAX;
  }

  private showSegmentedBanner(): boolean {
    if (!this.$rootScope.isUserLoggedIn()) {
      return false;
    }
    const headerValue: string = this.$rootScope.userDetails.user.segments.header;
    return (this.globalStateService.currentPage === CurrentPage.CHECKOUT &&
      !this.paymentMethodService.showPayAnyone &&
      (headerValue === "16" ||  headerValue === "17"));
  }
}
