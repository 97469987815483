import { UrlUtils } from "booking_app/utils";

angular.module("BookingApp")
  .directive("cdnPath", [
    "UrlUtils",
    (
      urlUtils: UrlUtils,
    ) => {
      return {
        restrict: "A",
        link: (scope: any, element: any, attr: any) => {
          element.attr("src", urlUtils.imageUrl(attr.cdnPath));
          return;
        },
      };
    },
  ]);
