export class TranslationUtils {
  static $inject = ["$rootScope", "$translate"];

  private translationKeyPrefix: string;
  private suffixKeysToTry: string[] = [".one", ".other", ""];

  constructor(private $rootScope: any, private $translate: any) {}

  // This handles issues with phraseApp's API for pluralisation.
  // Ex: key is `txt.night`. If a `txt.night.one` exists (English/German/etc), use that translation key
  // If that doesn't exist (`txt.night.one` for Korean/Japanese), then use the `txt.night.other` key.
  // If that also doesn't exist, then just use the original key.
  // Ex: `txt.day_one` doesnt have pluralisation, so just use the original key.
  getSingularTranslation(translationKeyPrefix: string): any {
    this.translationKeyPrefix = translationKeyPrefix;

    if (this.hasTranslation()) {
      return this.translatedText();
    } else {
      // Display English translation when all possible translations are missing.
      // We can modify this in the future so that if a tenant has a different default
      // locale, then default to that locale
      return this.$translate.instant(translationKeyPrefix, "en");
    }
  }

  humanizeTranslation(translation: string): string {
    const pattern: any = (/\.|\_/g);
    const translatedText = this.$translate.instant("cars.charge." + translation);
    if (translatedText.match(pattern)) {
      return translation.replace(pattern, " ");
    }
    return translatedText;
  }

  private hasTranslation(): boolean {
    return typeof this.suffixWithTranslation() !== undefined;
  }

  private suffixWithTranslation(): string {
    return this.suffixKeysToTry.find((suffix) => this.translationExistsFor(suffix));
  }

  private translationExistsFor(suffix: string): boolean {
    return typeof this.translate(`${this.translationKeyPrefix}${suffix}`) !== "undefined";
  }

  private translatedText(): string {
    return this.translate(`${this.translationKeyPrefix}${this.suffixWithTranslation()}`);
  }

  private translate(key: string): string {
    return this.$translate.getTranslationTable(this.$rootScope.selectedLocale.lang_code)[key];
  }
}

angular.module("BookingApp").service("TranslationUtils", TranslationUtils);
