// TODO: migrate hotel result filter existing controller logics to this controller
// or a new controller something like HotelResultFilterController which can be
// used across all the whitelabels
import { BusinessFiltersService } from "booking_app/services/business-filters.service";
import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { AppSettings } from "booking_app/values/app-settings";

export class HotelResultFilterMobileController {
  static $inject = [
    "$rootScope",
    "$scope",
    "AppSettings",
    "BusinessFiltersService",
    "SearchFiltersService",
  ];

  // local state
  modalOpen: boolean = false;
  maxModalWidth: ViewportSizes;

  constructor(
    private $rootScope: any,
    private $scope: any,
    private appSettings: AppSettings,
    private businessFiltersService: BusinessFiltersService,
    private searchFiltersService: any,
  ) {
    this.$scope.filterIconSource = this.appSettings.mobileResultPopupConfig.filterIconSource;
    this.maxModalWidth = ViewportSizes.SM_MAX;
  }

  openModal() {
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
  }

  triggerKeyupBusinessFilter(field: string, $event: any): void {
    if ($event.keyCode === 13 || $event.keyCode === 32) {
      $event.preventDefault();
      this.businessFiltersService.categoryFilters[field] = !this.businessFiltersService.categoryFilters[field];
      this.searchFiltersService.toggleQuickFilter();
    }
  }

  triggerKeyupStarRatingFilter(rating: number, $event: any): void {
    if ($event.keyCode === 13 || $event.keyCode === 32) {
      $event.preventDefault();
      this.searchFiltersService.v2StarRatings[`star${rating}`] = !this.searchFiltersService.v2StarRatings[`star${rating}`];
      this.searchFiltersService.toggleRating(rating);
    }
  }
}
