import { MapboxService } from "booking_app/services/mapbox";
import { HotelItem } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";
import { ViewportSizes } from "booking_app/types/viewport-sizes";

export class MapOpenerService {
  static $inject = [
    "MapboxService",
    "AppSettings",
    "$rootScope",
    "$timeout",
  ];

  public modalOpen: boolean;

  constructor(
    private mapboxService: MapboxService,
    private appSettings: AppSettings,
    private $rootScope: any,
    private $timeout: any,
  ) { }

  openMap(hotel?: HotelItem, callbackButtonElem?: HTMLElement): void {
    if (callbackButtonElem) {
      this.mapboxService.setCallbackButtonElement(callbackButtonElem);
    }
    const browserWidth = this.$rootScope.globalState.browserWidth;
    const minimumBrowserWidth = ViewportSizes.SM_MAX;
    if (browserWidth <= minimumBrowserWidth) {
      this.openMobileMap(hotel);
    } else {
      this.openWebMap(hotel);
    }
  }

  closeMap(): void {
    this.modalOpen = false;
  }

  private openMobileMap(hotel?: HotelItem): void {
    this.modalOpen = true;
    this.$timeout(() => this.loadMobileMap(hotel), 1);
  }

  private openWebMap(hotel: HotelItem): void {
    this.modalOpen = true;
    this.mapboxService.focusOnHotel(hotel);
  }

  private loadMobileMap(hotel?: HotelItem): void {
    this.mapboxService.toggleMobileViewMode();
    if (hotel) {
      this.mapboxService.openPopup([hotel.longitude, hotel.latitude], hotel.id, 18);
    }
  }
}

angular.module("BookingApp").service("MapOpenerService", MapOpenerService);
