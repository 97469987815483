import { StripeCardToken, StripePaymentSource } from "booking_app/types";
declare var Stripe: any;

export class StripePaymentService {
  static $inject = ["$q"];

  constructor(
    private $q: any,
  ) { }

  public getToken(
    cardNumber: string,
    cardCvv: string,
    expiryMonth: number,
    expiryYear: number,
    name: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    country: string,
  ): any {
    const deferred = this.$q.defer();
    const cardToken: StripeCardToken = {
      number: cardNumber,
      cvc: cardCvv,
      exp_month: expiryMonth,
      exp_year: expiryYear,
      name,
      address_line1: address,
      address_city: city,
      address_state: state,
      address_zip: zip,
      address_country: country,
    };

    Stripe.card.createToken(
      cardToken,
      (status, response) => this.responseHandler(deferred, response),
    );

    return deferred.promise;
  }

  public createSource(token: string, usage: string): ng.IPromise<{}> {
    const deferred = this.$q.defer();
    const stripeSource: StripePaymentSource = { type: "card", usage, token };

    Stripe.source.create(
      stripeSource,
      (status, response) => this.responseHandler(deferred, response),
    );

    return deferred.promise;
  }

  private responseHandler(deferred: any, response: any): void {
    if (response.error) {
      deferred.reject(response.error.message);
    } else {
      deferred.resolve(response.id);
    }
  }
}

angular.module("BookingApp").service("StripePaymentService", StripePaymentService);
