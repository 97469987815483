import { UrlLink } from "booking_app/constants/header-url-link";
import { CurrentPage } from "booking_app/types";
import { PageDiscoveryService } from "booking_app/services/page-discovery.service";

export class DropdownLinksController {
  static $inject = ["PageDiscoveryService"];
  linkOption: UrlLink;

  constructor(
    private pageDiscoveryService: PageDiscoveryService,
  ) {}

  toggleDropdown(): void {
    this.linkOption.active = !this.linkOption.active;
  }

  removeActiveClass(): void {
    this.linkOption.active = false;
  }

  menuToggleDropdown(e: JQueryEventObject): void {
    e.stopPropagation();
    this.linkOption.active = false;
  }

  isActiveTravelLink(): boolean {
    const currentPage: CurrentPage = this.pageDiscoveryService.currentPage();
    const activePages: CurrentPage[] = this.linkOption.activePages || [];
    return this.linkOption.active || activePages.includes(currentPage);
  }
}
