import { CurrentPage, TravelType } from "booking_app/types";
import { TravelTypeService } from "booking_app/services/travel-type.service";

declare var angular: any;

export class GlobalStateService {
  static $inject = ["TravelTypeService"];

  private TravelType: TravelType;
  private CurrentPage: CurrentPage;

  constructor(
    private travelTypeService: TravelTypeService,
  ) {}

  set travelType(travelType: TravelType) {
    this.TravelType = travelType;
  }

  get travelType(): TravelType {
    return this.TravelType;
  }

  set currentPage(currentPage: CurrentPage) {
    this.CurrentPage = currentPage;
  }

  get currentPage(): CurrentPage {
    return this.CurrentPage;
  }

  public initState(): void {
    this.CurrentPage = CurrentPage.HOME;
    this.TravelType = this.travelTypeService.initTravelType();
  }

  public isTravelType(type: TravelType): boolean {
    return this.TravelType === type;
  }

  public changeTravelType(type: TravelType): void {
    this.TravelType = type;
  }

  public changeTravelTypeAndUrl(type: TravelType): void {
    this.changeTravelType(type);
    this.travelTypeService.changeTravelTypeUrl(type);
  }

  public isDetailsPage(): boolean {
    const detailPages = [
      CurrentPage.CAR_DETAIL,
      CurrentPage.FLIGHT_DETAIL,
      CurrentPage.HOTEL_DETAIL,
    ];
    return detailPages.includes(this.currentPage);
  }
}

angular.module("BookingApp").service("GlobalStateService", GlobalStateService);
