declare var Rollbar: any;

export class AppInitializer {

  private reservedUrlKeywords: string[];

  // injected variables
  private injector: any;
  private $q: any;
  private $http: any;
  private app: any;

  constructor() {
    this.reservedUrlKeywords = [
      'html', 'company', 'hotels', 'search', 'users', 'purchase', 'results',
      'press', 'terms', 'purchases', 'account', 'context.html', 'admin',
      'flights', 'cars', 'bookings',
    ];

    this.injector = angular.injector(['ng'])
    this.$q = this.injector.get('$q')
    this.$http = this.injector.get('$http')
    this.app = angular.module("BookingApp")
  }

  private setConstants() {
    const kaligoConfig = (window as any).KaligoConfig;
    // assign configs & predefined value to angular constants
    this.app
      .constant("ReservedUrlKeywords", this.reservedUrlKeywords)
      .constant("KaligoConfig", kaligoConfig);
  }

  initializeApp(): Promise<any> {
    (<any>window).geoLocale = $.cookie('geolocale')
    if (!(<any>window).KaligoConfig) { (<any>window).KaligoConfig = {} }

    this.$http.defaults.headers.common["APP-VERSION"] = (<any>window).KaligoConfig["APP_VERSION"]
    this.$http.defaults.headers.common["Cache-Control"] = 'no-cache, no-store, must-revalidate'

    this.clearCookies();

    if ((<any>window).KaligoConfig.KARMATEST) {
      return this.$q((resolve) => resolve(this.setConstants()));
    } else {
      return this.fetchData()
        .then(
          () => {
            this.setConstants();
            return true;
          },
          (err) => {
            Rollbar.warning("Angular bootstrap failed", JSON.stringify(err));
          }
        )
    }
  }

  // clear cookies from /results path
  private clearCookies() {
    $.removeCookie("currency", { path: '/results' })
    $.removeCookie("showInterstitial", { path: '/results' })
    $.removeCookie("partner", { path: '/results' })
    $.removeCookie("landingPage", { path: '/results' })
    $.removeCookie("countrySite", { path: '/results' })
  }

  // The logic is following:
  // * if we are on the main page, we determine landing page by looking for /{lp}
  // * if we are on another page, we look for landing page by looking for
  // /...?landingPage={lp}
  private detectLandingPageUrl(): string {
    const potentialLandingPageUrl = this.extractLandingPage().toLowerCase();
    return this.possibleDlpUrl(potentialLandingPageUrl) ? potentialLandingPageUrl : this.findLandingPageParam();
  }

  private extractLandingPage(): string {
    if ((<any>window).KaligoConfig['DLP']) { return (<any>window).KaligoConfig['DLP'] }
    if (location.pathname != '/') { return location.pathname.split("/")[1] || "" }
    return "";
  }

  private findLandingPageParam(): string {
    if ((<any>window).KaligoConfig['DLP']) { return (<any>window).KaligoConfig['DLP'] }
    const queryParams = location.search.split("?")[1];
    if (queryParams) {
      for (let part of queryParams.split("&")) {
        if (part.split("=")[0] == "landingPage") {
          return part.split("=")[1].toLowerCase()
        }
      }
    }
    return ""
  }

  private possibleDlpUrl(url: string) {
    return (url != "" && this.reservedUrlKeywords.indexOf(url) == -1)
  }

  private fetchGenericData() {
    const landingPageUrl = this.detectLandingPageUrl();

    const deferred = this.$q.defer()
    this.fetchHttpConfig(deferred, `/api/bootstrap?url=${landingPageUrl}`, "AppConfig", 0);
    return deferred.promise;
  }

  private fetchUserData() {
    const deferred = this.$q.defer()
    this.fetchHttpConfig(deferred, "/api/user", "AppUser", 0);
    return deferred.promise
  }

  private fetchHttpConfig(deferred, url, constantName, retries) {
    this.$http.get(url)
      .then(
        (response) => {
          this.app.constant(constantName, response.data)
          deferred.resolve()
        },
        (errorResponse) => {
          if (retries <= 3) {
            this.fetchHttpConfig(deferred, url, constantName, retries + 1)
          } else {
            this.app.constant(constantName, null);
            Rollbar.warning("Angular fetchUser bootstrap failed", JSON.stringify(errorResponse));
            deferred.reject();
          }
        }
      )
  }

  private fetchData(): Promise<any> {
    return this.$q.all([this.fetchGenericData(), this.fetchUserData()]);
  }
}
