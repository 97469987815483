require('../services/hotel-image.service')
require('../services/api-data.service')
require('../factories/bookings-factory')
require('../services/manage-booking')
require('../values/app-settings')
require('../services/currencies.service')

bookingItem = ($modal, $translate, HotelImageService, ApiDataService,
BookingsFactory, ManageBooking, AppSettings, ModalManager,
SimpleModalService, CurrenciesService) ->

  restrict: 'E'

  scope: { booking: "=" }

  template: '<ng-include src="getTemplateUrl()"></ng-include>'

  link:
    pre: (scope, elem, attr) ->
      scope.getTemplateUrl = () ->
        "/html/account/#{scope.booking.travel_type}_booking_item"

      scope.initializeImage = ->
        HotelImageService.initHotelImageData(
          [
            {
              id: scope.booking.hotel_id,
              cloudflare_image_url: scope.booking.cloudflare_image_url
            }
          ]
        )

      scope.paidWithPoints = paidWithPoints = scope.booking.paid_in_points > 0
      freeNightVoucher = scope.booking.payment_type == "free_night_voucher"
      scope.initializeImage()
      scope.defaultBackgroundImageStyle = HotelImageService.getDefaultHotelBgImageStyle()
      scope.backgroundImageStyle = HotelImageService.getThumbImageStyle(scope.booking.hotel_id, 1)
      scope.cancelStatus = ManageBooking.defaultStatus
      scope.cancelRequestStatus = ManageBooking.defaultStatus
      pointsCredits = scope.booking.points_credits
      if pointsCredits && pointsCredits.length > 0
        scope.partnerPointLabel = pointsCredits[0].points_partner.currency_short
        scope.partnerLogoUrl = pointsCredits[0].points_partner.logo_url

      scope.getTenant = ->
        return AppSettings.tenant.toLowerCase()

      scope.displayCancelation = ->
        scope.booking &&
        scope.booking.status != 'pending' &&
        scope.booking.status != 'verification_required'

      scope.headlineCssClass = ->
        booking = scope.booking
        status = scope.booking.status
        return 'text-success' if status == 'active' && !booking.cancelled
        return 'text-careful' if status == 'pending'
        return 'red-test' if booking.cancelled

      scope.cancellationFee = ->
        return scope.booking.cancellation_fee_percentage

      scope.cancellationFeeText = AppSettings.cancellationFeeText.manageBookings

      scope.alwaysShowCancellationFee = scope.cancellationFee() > 0 && AppSettings.alwaysShowCancellationFee

      scope.cancelledDate = ->
        if scope.booking.cancellation_date
          moment.utc(scope.booking.cancellation_date).local().format("ll")
        else
          ""

      scope.nonRefundableFrom = ->
        if scope.booking.cancellation_policy.non_refundable_from
          moment.utc(scope.booking.cancellation_policy.non_refundable_from).format("lll")
        else
          ""

      scope.nonRefundable = ->
        scope.booking.cancellation_policy.refundable == 'no'

      scope.nonCancellable = ->
        freeNightVoucher ||
          scope.booking.travel_type == 'cars' ||
          (paidWithPoints && !AppSettings.showCancelPolicyOnRedeem)

      scope.showCancelBooking = ->
        (
          scope.booking.status == "active" &&
          scope.booking.product_type != "complimentary_nights" &&
          !scope.nonCancellable() &&
          !scope.booking.cancelled &&
          moment(scope.booking.checkOutDate, "YYYY-MM-DD") > moment().startOf('day')
        )

      scope.nonRefundableText = AppSettings.carsBookingPage.nonRefundableText

      scope.showCancelBookingRequest = ->
        (
          scope.booking.status == "active" &&
          !scope.booking.cancelled
        )

      scope.displayTaxIncluded = -> scope.booking.cost > 0 && !freeNightVoucher

      scope.isExpanded = false
      scope.expand = -> scope.isExpanded = !scope.isExpanded

      scope.emailConfirmationSent = () ->
        SimpleModalService.open("resend-confirmation-success-modal")

      scope.emailConfirmationNotSent = () ->
        SimpleModalService.open("resend-confirmation-failed-modal")

      scope.flightsCompleted = () ->
        departureDate = new Date(scope.booking.departure_date)
        currentDate = new Date()
        return currentDate.getTime() > departureDate.getTime()


      scope.resendConfEmail = () ->
        BookingsFactory.sendConfirmationEmail {id: scope.booking.id},
        (confirmationRes) ->
          scope.emailConfirmationSent()
        , (errors) ->
          scope.emailConfirmationNotSent()

      scope.cancelBooking = () ->
        ManageBooking.showConfirmCancelBookingDialog(scope.booking)
        .then((status) ->
          return if !status || status == 'abort'
          scope.cancelStatus = status
          scope.booking.cancelled = true
          scope.booking.status_description = 'Cancelled'
        , (status) ->
          scope.cancelStatus = status
        , (status) ->
          scope.cancelStatus = status
        )

      scope.cancelBookingRequest = () ->
        ManageBooking.showConfirmCancelBookingRequestDialog(scope.booking)
        .then((status) ->
          return if !status || status == 'abort'
          scope.cancelRequestStatus = status
        , (status) ->
          scope.cancelRequestStatus = ManageBooking.defaultStatus
        , (status) ->
          scope.cancelRequestStatus = status
        )

      scope.formatToShortDate = (date) -> moment(date, "YYYY-MM-DD").format("ll")
      scope.formatToLocalShortDate = (date) -> moment.utc(date).local().format("ll")
      scope.formatToFullDate = (date) -> moment(date).utc().format("lll")
      scope.formatToFullDateWithDay = (date) -> moment(date).format("LL (ddd)")
      scope.formatToLocalFullDateWithDay = (date) -> moment.utc(date).local().format("LL (ddd)")
      scope.formatDate = (date, format) -> moment(date, "YYYY-MM-DD" ).format(format)

      isActive = () -> scope.cancelStatus != 'cancelled' && scope.cancelStatus != 'error'
      scope.displayManualCancellation = () ->
        return (
          AppSettings.turnOnManualCancellation &&
          paidWithPoints &&
          isActive()
        )

      scope.displayCancelButton = () ->
        (
          !scope.displayManualCancellation() && (
            !paidWithPoints || (paidWithPoints && AppSettings.allowCancelRedemptionBooking)
          )
        )

      scope.displayPenaltyInPercentage = () -> (paidWithPoints)

      scope.currencyDecimals = CurrenciesService.findByCode(scope.booking.currency).decimalPlace

      return

bookingItem
  .$inject = ["$modal", "$translate", "HotelImageService", "ApiDataService",
  "BookingsFactory", "ManageBooking", "AppSettings", "ModalManager",
  "SimpleModalService", "CurrenciesService"]

angular.module("BookingApp")
.directive "bookingItem", bookingItem
