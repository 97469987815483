import { Bindings } from "booking_app/types";
import { MapViewController } from "./map-view.controller";

export class MapViewComponent {
  // General component variables
  private bindings: any;
  private template: string;
  private controller: any;

  constructor() {
    this.bindings = {
      lat: Bindings.ONE_WAY,
      lng: Bindings.ONE_WAY,
    };

    this.controller = MapViewController;
    this.template = `
      <div id="hotel-detail-map" >
        <div id="mapbox-search-map"></div>
      </div>
    `;
  }
}

angular.module("BookingApp").component("mapView", new MapViewComponent());
