declare var angular: any;

const EVENT_UPDATE_TEMPLATE = "dlpq-update-template"
const EVENT_UPDATE_LOCALE = "dlpq-update-locale"
const EVENT_UPDATE_CURRENCY = "dlpq-update-currency"

class EventTriggers {

  public updateTemplate(template: any) {
    window.dispatchEvent(new CustomEvent(EVENT_UPDATE_TEMPLATE, {detail: template}))
  }

  public updateLocale(locale: any) {
    window.dispatchEvent(new CustomEvent(EVENT_UPDATE_LOCALE, {detail: locale}))
  }

  public updateCurrency(currency: any) {
    window.dispatchEvent(new CustomEvent(EVENT_UPDATE_CURRENCY, {detail: currency}))
  }

}

angular.module("BookingApp").service('EventTriggers', EventTriggers)
