angular.module("BookingApp")

.factory "BookingsService", [

  '$q', '$rootScope', 'CookieService', 'ApiDataService', 'AppTraffic', 'PaymentMethodService'
  ($q, $rootScope, CookieService, ApiDataService, AppTraffic, PaymentMethodService) ->

    BookingsClass = (booking_key) ->
      hotelCPDeferred = null
      @get_cancellation_policy = ->
        hotelCPDeferred = $q.defer()
        checkin = moment.utc($rootScope.checkInDate, "MM/DD/YYYY").format("YYYY-MM-DD")

        ApiDataService.get("hotels/cancellation_policy/" + booking_key + "?checkin=" + checkin)
        .then (data) ->
          hotelCPDeferred.resolve(data or {})
        , (error) ->
          hotelCPDeferred.reject(error or {})

        hotelCPDeferred.promise

      @cancelCancellationPolicy = (reason) ->
        return if !hotelCPDeferred
        hotelCPDeferred.resolve(reason)

      formatDate = (usFormatDate) ->
        moment(usFormatDate, "MM/DD/YYYY").format("YYYY-MM-DD")

      @create_booking = (title, firstName, lastName, email, city, rememberMe,
                        state, houseNumber, address, postalCode, country, cardFirstName,
                        cardLastName, card, cvv, expirationMonth, expirationYear, bookingKey, roomDescription,
                        memberNo, memberFirstName, memberLastName, pointsPartnerId, currency, userEmail, userFirstName,
                        send_marketing, guestSpecialRequests, vouchers, phoneNumber, paymentChannel, hotelName,
                        paymentNonce, paymentNonceType, streetNumber,
                        paymentTypeCode, redemptionPassword, payWithPointsTier, referrer,
                        payWithPointsDebugInfo, otp, familyMilesSelected,
                        couponCode, productType, newCardSave,
                        adyenEncryptedData, browserInfo, paymentMethod, customer,
                        adyenPaymentType, adyenToken) ->

        escapedGuestSpecialRequests = guestSpecialRequests.replace(/&/g,'and').trim()

        bookingParams = JSON.stringify(
          {
            user: {
              first_name:                userFirstName || firstName,
              email:                     userEmail || email,
              send_marketing:            send_marketing || false,
              redemption_password:       redemptionPassword,
            },
            lp:                          $rootScope.landingPage.url,
            guest: {
              salutation:                title,
              first_name:                firstName,
              last_name:                 lastName,
              room_remarks:              escapedGuestSpecialRequests,
              phone:                     phoneNumber,
            },
            payment_channel:             paymentChannel,
            payment: angular.merge({
              house_number:              houseNumber,
              address:                   address,
              city:                      city,
              state:                     state,
              country:                   country,
              postal_code:               postalCode,
              first_name:                cardFirstName,
              last_name:                 cardLastName,
              payment_type_code:         paymentTypeCode
            }, PaymentMethodService.filterPaymentData({
                card_number:               card,
                cvv:                       cvv,
                expiration_month:          expirationMonth,
                expiration_year:           expirationYear,
              }),
              adyenEncryptedData,
              browser_info:              browserInfo,
              payment_method:            paymentMethod,
              customer:                  customer,
            ),
            membership: {
              number:                    memberNo,
              first_name:                memberFirstName,
              last_name:                 memberLastName
            },
            hotel_name:           hotelName,
            hotel_id:             $rootScope.hotelId,
            destination_id:       $rootScope.destinationId,
            guests:               $rootScope.guests,
            checkin:              formatDate($rootScope.checkInDate),
            checkout:             formatDate($rootScope.checkOutDate),
            currency:             currency,
            room_description:     roomDescription,
            partner_id:           pointsPartnerId,
            voucher_ids:          (voucher.uid for voucher in vouchers),
            payment_nonce:        paymentNonce,
            payment_nonce_type:   paymentNonceType,
            booking_key:          bookingKey,
            pay_with_points_tier: payWithPointsTier,
            referrer:             referrer,
            traffic_source:       (AppTraffic.source || CookieService.fetchCookie('SOURCE')),
            traffic_click_id:     (AppTraffic.clickId || CookieService.fetchCookie('cj')),
            debug_info:           payWithPointsDebugInfo,
            otp:                  otp,
            use_family_miles:     familyMilesSelected,
            coupon_code:          couponCode,
            product_type:         productType,
            remember_me:          rememberMe,
            new_card_save:        newCardSave,
            points_account_id:    $rootScope.pointsAccountId,
            adyen_payment_type:     adyenPaymentType,
            adyen_card_token:       adyenToken
          }
        )

        ApiDataService.post("bookings", bookingParams)
        .then (data) ->
          data or {}
        , (error) ->
          $q.reject(error or {})

      @getBooking = (accessToken, bookingReference) ->
        ApiDataService.get("bookings/#{bookingReference}?access_token=#{accessToken}")
        .then (data) ->
          data or {}
        , (error) ->
          $q.reject(error or {})

      return
    BookingsClass
]
