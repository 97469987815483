import { CurrenciesService } from "booking_app/services/currencies.service";

export class ComplimentaryPriceBoxController {
  static $inject = [
    "CurrenciesService",
    "$filter",
    "$rootScope",
  ];

  // Bindings
  complimentaryNights: any;
  singleRoomPerNightWithTax: number;
  priceWithTax: number;
  duration: number;
  buttonText: string;
  ourRateText: boolean;
  priceBoxAction: () => void;

  constructor(
    private currenciesService: CurrenciesService,
    private $filter: any,
    private $rootScope: any,
  ) { }

  diamondLogo(): string {
    return `/visa/icons/diamond.png`;
  }

  convertedTotalDiscountedPriceWithTax(): string {
    return this.formatCurrency(this.currenciesService.convertFromUsd(this.priceWithTax));
  }

  convertedOriginalPricePerRoomPerNightWithTax(): string {
    return this.formatCurrency(this.currenciesService.convertFromUsd(this.singleRoomPerNightWithTax));
  }

  private formatCurrency(value: number): string {
    return this.$filter("localeNumberFmt")(value, 0);
  }
}
