import { Bindings } from "booking_app/types";
import { NavigationBarController } from "./navigation-bar.controller";

export class NavigationBarComponent {
  bindings: any = {
    navBarText: Bindings.ONE_WAY,
    navAction: Bindings.EXPRESSION,
  };
  controller: any = NavigationBarController;
  template: string = `
    <header ng-class="{ 'center': $ctrl.isHomePage() && !$ctrl.userLoggedin(), 'show-nav-menu': $ctrl.displayPointsBalance() } ">
      <a class="header-text" ng-click="$ctrl.navAction()">
        <i class="fa fa-chevron-left" ng-if="!$ctrl.isHomePage()"></i>
        <span ng-bind-html="$ctrl.translatedNavText()"></span>
      </a>
      <div class="header-right-area">
        <a class="nav-toggle" ng-click="$ctrl.toggleSideNav()">
          <div class="display-currency-locale">
            <em class="fa fa-ellipsis-v"></em>
          </div>
        </a>
      </div>
    </header>
  `;
}

angular.module("BookingApp").component("navigationBar", new NavigationBarComponent());
