import { Booking } from "booking_app/models";
import { BookingDetailUtils } from "booking_app/utils";
import { TimeUtils } from "booking_app/utils";
import { NumberUtils } from "booking_app/utils";
import { CurrentPage } from "booking_app/types";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { RedirectService } from "booking_app/services/redirect.service";
import { AppSettings } from "booking_app/values/app-settings";
import { CarCoverage } from "booking_app/types/car-coverage";

export class CarBookingDetailController {
  static $inject = [
    "$rootScope",
    "$filter",
    "BookingDetailUtils",
    "GlobalStateService",
    "RedirectService",
    "AppSettings",
  ];

  constructor(
    private $rootScope: any,
    private $filter: any,
    private bookingDetailUtils: BookingDetailUtils,
    private globalStateService: GlobalStateService,
    private redirectService: RedirectService,
    private appSettings: AppSettings,
  ) {
    this.globalStateService.currentPage = CurrentPage.CONFIRMATION;
  }

  public $onInit(): void {
    this.redirectService.unauthorizedConfirmationPage();
    this.bookingDetailUtils.init();
    this.setupLocaleListener();
  }

  public getBooking(): Booking {
    return this.bookingDetailUtils.getBooking();
  }

  public getPointsPartner(): any {
    return this.bookingDetailUtils.getPointsPartner();
  }

  public paidInCashOnly(): boolean {
    return this.bookingDetailUtils.paidInCashOnly();
  }

  public paidAnyCash(): boolean {
    return this.bookingDetailUtils.paidAnyCash();
  }

  public paidAnyPoints(): boolean {
    return this.bookingDetailUtils.paidAnyPoints();
  }

  public activeBooking(): boolean {
    return this.bookingDetailUtils.activeBooking();
  }

  public getBookingGuestName(): string {
    return this.bookingDetailUtils.getBookingGuestName();
  }

  public carCoverages(): CarCoverage[] {
    if (this.getBooking()) {
      return this.getBooking().priced_coverages;
    } else {
      return [];
    }
  }

  public pdfDownloadLink(): string {
    return this.bookingDetailUtils.pdfDownloadLink();
  }

  public verificationTimeLeft(): string {
    return this.bookingDetailUtils.verificationTimeLeft();
  }

  // Car specific methods

  public getCarCategoryTranslationKey(): string {
    return this.getBooking() ? `cars.category.${this.getBooking().category}` : "";
  }

  public formatDateAndTimeBy(datetime: string, separator: string): string {
    return TimeUtils.formatLocalizedUTCDate(datetime) + separator + TimeUtils.formatLocalizedUTCHourMinute(datetime);
  }

  public formatShortDateAndTimeBy(datetime: string, separator: string): string {
    return TimeUtils.formatShortLocalizedUTCDate(datetime) + separator +
           TimeUtils.formatLocalizedUTCHourMinute(datetime);
  }

  public formatPointsBalance(points: number): string {
    return NumberUtils.formatPointsValue(points).toLocaleString(this.bookingDetailUtils.getSelectedLocale().code);
  }

  formatLocaleNumber(value: number, decimalPlace: number) {
    return this.$filter("localeNumberFmt")(value, decimalPlace);
  }

  hasPointsDiscount(): boolean {
    return this.getBooking().points_discount > 0;
  }

  pointsBeforeDiscount(): string {
    return this.formatLocaleNumber(this.getBooking().paid_in_points + this.getBooking().points_discount, 0);
  }

  hasCashDiscount(): boolean {
    return this.getBooking().cash_discount > 0;
  }

  cashBeforeDiscount(): string {
    return `${this.getBooking().converted_amount + this.getBooking().cash_discount}`;
  }

  private setupLocaleListener(): void {
    this.$rootScope.$watch("selectedLocale", () => {
      this.bookingDetailUtils.init();
    });
  }
}
