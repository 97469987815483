
angular.module("BookingApp")
.filter("parseHtmlInInput", () => {
  return (str: string) => {
    const element: HTMLElement = document.createElement("span");
    element.innerHTML = str;
    str =  element.innerText || element.textContent;
    return str;
  };
});
