declare var angular: any;

angular.module("BookingApp").directive("selectScrollDetector", [
  () => (
    {
      restrict: "A",
      link: (scope, element, attrs) => {
        const raw = element[0];
        element.bind("scroll", () => {
          // minus 100 is required so it will not feel like it is being loaded behind
          if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight - 100) {
            scope.$apply(attrs.selectScrollDetector);
          }
        });
      },
    }
  ),
]);
