import { DatePickerSelectionState } from "../date-picker";
import { DateAdapterUtils } from "booking_app/utils";

declare var moment: any;

interface DateUpdateValue {
  startDate: string;
  endDate: string;
}

export class DateRangeFieldMobileController {
  static $inject = ["$element", "$scope"];

  // bindings
  startDate: string;
  endDate: string;
  minDaysToBookInAdvance: number = 0;
  minBookingDays: number = 0;
  onUpdate: (obj: { value: DateUpdateValue }) => void;
  locale: string;
  dateFormat: string;
  startLabel: string;
  endLabel: string;
  noEndDate: boolean; // for scenarious where there is only start date (e.g flights one way)

  // local state
  modalOpen: boolean = false;
  selectionState: DatePickerSelectionState;

  constructor(private $element: any, private $scope: any) {

  }

  updateStartDate(value: string) {
    this.onUpdate({
      value: {
        startDate: value,
        endDate: !this.noEndDate ? this.newEndDateValue(value) : "",
      },
    });
    this.selectionState = "END";
    if (this.noEndDate) {
      this.closeModal();
    }
    this.$element.find(".field-input#start").blur();
    this.$scope.$apply();
  }

  updateEndDate(value: string) {
    this.onUpdate({
      value: {
        startDate: this.startDate,
        endDate: value,
      },
    });
    this.selectionState = "START";
    this.closeModal();
    this.$element.find(".field-input#end").blur();
    this.$scope.$apply();
  }

  beginDateSelection(value: string) {
    if (value === "start") {
      this.selectionState = "START";
    } else {
      this.selectionState = "END";
    }
    this.modalOpen = true;
    this.$element.find(".field-input").blur();
  }

  closeModal() {
    this.modalOpen = false;
  }

  formattedStartDate(): string {
    return this.stringToMoment(this.startDate).format(this.dateFormat || "ll");
  }

  formattedEndDate(): string {
    return this.stringToMoment(this.endDate).format(this.dateFormat || "ll");
  }

  numberOfNights(): number {
    return this.stringToMoment(this.endDate).diff(this.stringToMoment(this.startDate), "days");
  }

  startInfoFieldClass(): { [key: string]: boolean } {
    return {
      "info-field": true,
      "is-selecting": this.selectionState === "START",
    };
  }

  endInfoFieldClass(): { [key: string]: boolean } {
    return {
      "info-field": true,
      "is-selecting": this.selectionState === "END",
    };
  }

  minDatepickerDate(): string {
    if (this.selectionState === "START") {
      return moment().add(this.minDaysToBookInAdvance, "d").format(DateAdapterUtils.V2_DATE_FORMAT);
    } else if (this.selectionState === "END") {
      return this.stringToMoment(this.startDate)
        .add(this.minBookingDays, "d")
        .format(DateAdapterUtils.V2_DATE_FORMAT);
    }
  }

  private newEndDateValue(newStartValue: string): string {
    if (this.startDateGreaterThanEndDate(newStartValue)) {
      return this.momentToString(this.addMinBookingDays(newStartValue));
    } else {
      return this.endDate;
    }
  }

  private startDateGreaterThanEndDate(newStartDate: string): boolean {
    return this.stringToMoment(newStartDate) > this.stringToMoment(this.endDate);
  }

  private stringToMoment(date: string): any {
    return moment(date, DateAdapterUtils.V2_DATE_FORMAT);
  }

  private momentToString(date: string): string {
    return moment(date).format(DateAdapterUtils.V2_DATE_FORMAT);
  }

  private addMinBookingDays(date: string): string {
    return this.stringToMoment(date).add(this.minBookingDays, "d");
  }

}
