export class TravelVoucherController {
  static $inject = [
    "AppConfig",
  ];

  constructor(
    private appConfig: any,
  ) {}

  public isTravelVoucherAvailable(): boolean {
    return !!this.appConfig.landing_page_coupon;
  }

  public travelVoucherStatement(): string {
    const coupon: { bonus_program: { name: string }, code: string }
      = this.appConfig.landing_page_coupon;
    return `${coupon.bonus_program.name} ${coupon.code}`;
  }

}
