import { Currency } from "booking_app/types/currency";

export class CurrenciesService {
  static $inject = ["$rootScope"];

  private readonly defaultCurrency = "USD";

  private currencies: Currency[] = [];

  constructor(
    private $rootScope: any,
  ) { }

  public init(currencies: any[]): void {
    this.currencies = currencies.map((currency) => {
      return {
        code: currency.text,
        text: `${currency.symbol} ${currency.text}`,
        rate: currency.rate,
        symbol: currency.symbol,
        preferredGateway: currency.web_preferred_payment_gateway,
        decimalPlace: this.decimalPlace(currency.text),
      };
    });
  }

  public all(): Currency[] {
    return this.currencies;
  }

  public default(): Currency {
    return this.findByCode(this.defaultCurrency);
  }

  public findByCode(currencyCode: string): Currency {
    return this.currencies.find(currency => currency.code === currencyCode);
  }

  public convertFromUsd(amount: number): number {
    return amount * this.$rootScope.convert_rate;
  }

  public adjustDecimals(amount: number, decimalPlace: number): string {
    const re = RegExp(`^-?\\d+(?:\.\\d{0,${decimalPlace || -1}})?`);
    return amount.toString().match(re)[0];
  }

  public adjustZeroDecimal(amount: number, decimalPlace: number): number {
    if (decimalPlace === 0) {
      return Math.ceil(amount);
    }

    return amount;
  }

  private decimalPlace(currencyCode: string): number {
    switch (currencyCode) {
      case "IDR":
      case "JPY":
      case "KRW":
        return 0;
      default:
        return 2;
    }
  }
}

angular.module("BookingApp").service("CurrenciesService", CurrenciesService);
