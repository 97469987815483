import { ApiDataService } from "booking_app/services/api-data.service";
import { AppSettings } from "booking_app/values/app-settings";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { ContactUsConfig, CurrentPage, FormUser } from "booking_app/types";

export class ContactUsCtrl {
  static $inject = [
    "$window",
    "ApiDataService",
    "AppSettings",
    "GlobalStateService",
  ];

  public formUser: FormUser;
  public contactForm: any;

  private contactUsConfig: ContactUsConfig;
  private success: boolean;

  constructor(
    private $window: any,
    private apiDataService: ApiDataService,
    private appSettings: AppSettings,
    private globalStateService: GlobalStateService,
  ) {
    this.globalStateService.currentPage = CurrentPage.CONTACT_US;

    this.success = false;
    this.contactUsConfig = this.appSettings.contactUsConfig;
    this.resetFormUser();
  }

  public reset(): void {
    this.resetFormUser();
    this.contactForm.$setPristine();
    this.contactForm.$setUntouched();
  }

  public formSubmit(valid: boolean): void {
    this.contactForm.errors = null;
    this.contactForm.question.$setDirty();
    this.contactForm.details.$setDirty();
    this.contactForm.username.$setDirty();
    this.contactForm.email.$setDirty();
    if (this.contactForm.$invalid || this.contactForm.processing) {
      return;
    }

    this.contactForm.processing = true;

    this.apiDataService.post("tickets", this.formUser).then(
      (res) => {
        this.contactForm.processing = false;
        this.success = true;
        this.reset();
      }, (res) => {
        this.contactForm.processing = false;
        this.contactForm.errors = res.errors;
      },
    ).then(() => {
      this.$window.scrollTo(0, 0);
    });
  }

  public showSuccessMessage(): boolean {
    return this.success;
  }

  public getHeadingText(): string {
    return this.contactUsConfig.contactUsHeadingText;
  }

  public displayEmailText(): boolean {
    return this.contactUsConfig.contactUsEmailText;
  }

  public getParagraphText(): string {
    return this.contactUsConfig.contactUsParagraphText;
  }

  public getTenant(): string {
    return this.appSettings.tenant.toLowerCase();
  }

  private resetFormUser(): void {
    this.formUser = {
      question: "",
      details: "",
      name: "",
      email: "",
    };
  }
}

angular.module("BookingApp").controller("ContactUsCtrl", ContactUsCtrl);
