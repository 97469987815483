const ORD_MULTIPLIER = 10000000000000;

class FloodlightService {
  static $inject = ["$rootScope"];

  // injectors
  $rootScope: any;

  constructor($rootScope) {
    this.$rootScope = $rootScope;
  }

  init() {
    const a = `${Math.random() * ORD_MULTIPLIER}`;

    if (this.$rootScope.selectedCountrySite.code === "GB" && !this.$rootScope.landingPage.url) {
      $("#floodlight_container").html(
        '<iframe src="https://2365300.fls.doubleclick.net/activityi;src=2365300;type=amexk0;cat=kalig0;ord=' +
        a +
        '?" width="1" height="1" frameborder="0" style="display:none"></iframe>'
      );
    }
  }
}

angular.module("BookingApp").service("FloodlightService", FloodlightService);
