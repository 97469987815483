import { HotelItem, MarketRate } from "booking_app/types";
import { DisplayUtils } from "booking_app/utils";
import { AppSettings } from "booking_app/values/app-settings";
import { CurrenciesService } from "booking_app/services/currencies.service";
import { PointsCashShareService } from "booking_app/services/points-cash-share.service";

export class HotelListItemCompRatesController {
  static $inject = [
    "$rootScope",
    "$filter",
    "$window",
    "DisplayUtils",
    "AppSettings",
    "WhitelabelPartner",
    "CurrenciesService",
    "PointsCashShareService",
  ];
  // bindings
  hotel: HotelItem;

  constructor(
    private $rootScope: any,
    private $filter: any,
    private $window: any,
    private displayUtils: DisplayUtils,
    private appSettings: AppSettings,
    private whitelabelPartner: any,
    private currenciesService: CurrenciesService,
    private pointsCashShareService: PointsCashShareService,
  ) { }

  showMarketRates(): boolean {
    // Turkish Airlines wanted us to disable market rates ¯\_(ツ)_ /¯
    const partnerIsTurkish = this.$rootScope.pointsPartner && (this.$rootScope.pointsPartner.id === 73);

    return !this.whitelabelPartner.hideCompareRatesUI &&
      this.appSettings.showMarketRates &&
      this.hotel.marketRates &&
      !partnerIsTurkish &&
      this.applicableRatesAvailable();
  }

  formatLocaleShortenNumber(value: number): string {
    return this.$filter("localeShortenNumber")(this.computedPrice(value));
  }

  redirectToExternalSupplier($event, marketRate: MarketRate): void {
    if (this.appSettings.redirectMarketRates) {
      $event.stopPropagation();
      let redirectUrl = `/redirect?lang=${this.$rootScope.selectedLocale.lang_code}`;
      const bookingUrl = `${marketRate.booking_url}&splash=false`;
      redirectUrl += "&redirect=" + encodeURIComponent(bookingUrl);
      redirectUrl += "&partner=" + marketRate.supplier;
      redirectUrl += "&price=" + this.$filter("localeNumberFmt")(marketRate.rate);
      redirectUrl += "&currency=" + this.$filter("localeNumberFmt")(this.$rootScope.selectedCurrency.code);
      this.$window.open(redirectUrl);
    }
  }

  computedPrice(amount: number): number {
    if (this.$rootScope.globalState.showTotalNights) {
      amount = amount * this.$rootScope.duration;
    }

    return amount;
  }

  hasComparableRate(amount): boolean {
    if (this.appSettings.showBetterRatesOnly) {
      return Math.ceil(this.maxCashAmount(this.hotel.max_cash_payment)) <= Math.ceil(this.computedPrice(amount));
    } else {
      return true;
    }
  }

  private maxCashAmount(amount: number): number {
    const sliderOptionsCeil = this.pointsCashShareService.pointsCashShare.sliderOptions.ceil;
    let convertedAmount = this.currenciesService.convertFromUsd(amount);
    if (this.$rootScope.pointsPartner.hotelsPointsPaymentTiers) {
      convertedAmount = convertedAmount *
        this.$rootScope.pointsPartner.hotelsPointsPaymentTiers[sliderOptionsCeil];
    }
    return convertedAmount;
  }

  private applicableRatesAvailable(): boolean {
    const filteredRates = this.hotel.marketRates.filter(m => this.hasComparableRate(m.singleRate));
    if (filteredRates.length > 0) {
      return true;
    }
    return false;
  }
}
