# TODO: Remove this once AppSettings.useNewHotelListItem is not used anymore
require('../services/search-url')
require('../services/trustyou.service')
require('../services/mapbox')
require('../services/hotel-image.service')
require('../services/whitelabel-partner')
require('../services/tracker')
require('../services/points-cash-payment-handling.service')
require('../values/app-settings')

angular.module("BookingApp")
.directive "hotelDetail", [

  '$rootScope', '$filter', '$location', '$timeout', '$analytics', '$modal', '$window'
  'SearchUrlService', 'TrustyouService', 'HotelImageService'
  'WhitelabelPartner', 'AppSettings', 'TrackerService',
  'MapOpenerService', 'PointsCashPaymentHandlingService'

  ($rootScope, $filter, $location, $timeout, $analytics, $modal, $window,
  SearchUrlService, TrustyouService, HotelImageService,
  WhitelabelPartner, AppSettings, TrackerService, MapOpenerService,
  PointsCashPaymentHandlingService) ->

    restrict: "E"
    scope: ->
      hotel: 'hotel'
    templateUrl: () ->
      return "/html/landing/kaligo2/result_list_item"   if WhitelabelPartner.hideCompareRatesUI || $rootScope.landingPage.redeemHotel()
      return "/html/landing/kaligo2/result_list_item2"
    link: (scope) ->
      partnerId = $rootScope.pointsPartner.id

      scope.showPerNightText = AppSettings.showPerNightText
      scope.showEarnOnBurn = AppSettings.payWithPoints.earnOnBurn
      scope.tenant = AppSettings.tenant

      scope.hotel.activeTab = ""
      scope.hotel.isLoading = false

      scope.hotel.locationURL = SearchUrlService.createHotelDetailsUrl(scope.hotel)
      scope.hotel.backgroundImageStyle = HotelImageService.getDefaultThumbImageStyle(scope.hotel.id)
      scope.hotel.defaultBackgroundImageStyle = HotelImageService.getDefaultHotelBgImageStyle()

      $rootScope.$on 'pointsCashSliderUpdate', ((event, data) ->
        scope.hotel.locationURL = SearchUrlService.createHotelDetailsUrl(scope.hotel)
      ), true

      $rootScope.$watch 'pointsPartner', ((newValue, oldValue) ->
        return if !$rootScope.selectedCountrySite || !scope.hotel
        scope.hotel.locationURL = SearchUrlService.createHotelDetailsUrl(scope.hotel)
      ), true

      scope.hotel.nightOfStay = $filter('getNumberOfDays')($rootScope.checkInDate, $rootScope.checkOutDate)

      if scope.hotel.trustyou.score
        scope.hotel.trustyou.ui = TrustyouService.getScoreUIDetails(scope.hotel.trustyou.score.kaligo_overall.toFixed(1))
        scope.hotel.trustyouClass = if scope.hotel.trustyou.ui.color == 'positive' then "pos" else "neu"

      scope.redirectToHotelDetailsPage = (e) ->
        # ignore if the event triggered from "<a>" tag.
        # <a> will use the href attribute to change url
        TrackerService.hotelClick(
          scope.hotel,
          {
            guests: $rootScope.guests
            currencyCode: $rootScope.routeParams.currency
            checkInDate: $rootScope.checkInDate,
            checkOutDate: $rootScope.checkOutDate
          }
        )
        return if e.originalEvent.target.nodeName is "A"
        return unless scope.hotel.available
        $location.url(scope.hotel.locationURL)
        return

      scope.goToHotelDetailPage = (e) ->
        $location.url(scope.hotel.locationURL)
        e.preventDefault()
        return

      scope.showInMap = (e, hotel) ->
        MapOpenerService.openMap(hotel)
        e.stopPropagation()

      scope.isSelectedHotel = (hotel) ->
        return hotel.id && hotel.id == $rootScope.routeParams.selectedHotelId

      scope.zeroFullCashPayment = () ->
        return PointsCashPaymentHandlingService.zeroFullCashPayment(
          scope.hotel.cash_payment,
          scope.hotel.points_payment
        )

      getTYReviews = (hotelId) ->
        scope.hotel.isLoading = true
        TrustyouService.getHotelReview(hotelId).then (result) ->
          if result["error"]
            scope.hotelDetail.trustyou["reviewsError"] = true
          else
            scope.hotel.trustyou["reviews"] = result
            scope.hotel.trustyou["reviews"]["meta"]["minify"] = true
          scope.hotel.isLoading = false
        return

      get_hotel_review = ->
        if !scope.hotel.trustyou.reviews
          getTYReviews(scope.hotel.id)
        return if $rootScope.globalState.activeModal
        $rootScope.globalState.activeModal = true
        modalInstance = $modal.open({
          animation: true
          templateUrl: '/html/modals/trustyou_review_modal'
          controller: 'TrustyouReviewModal'
          size: "lg"
          windowClass: 'trustyou-modal'
          resolve: {
            hotel: () ->
              return scope.hotel
          }
        })

        modalInstance.result.then(
          (selection) ->
            $rootScope.globalState.activeModal = false
          , () ->
            $rootScope.globalState.activeModal = false
        )

        return

      scope.redirect = (marketRate, e) ->
        e.stopPropagation()
        redirectUrl = "/redirect?lang=" + $rootScope.selectedLocale.lang_code
        bookingUrl = marketRate.booking_url + "&splash=false"
        redirectUrl += "&redirect=" + encodeURIComponent(bookingUrl)
        redirectUrl += "&partner=" + marketRate.supplier
        redirectUrl += "&price=" + $filter("numberFmt")(marketRate.rate, $rootScope.selectedLocale)
        redirectUrl += "&currency=" + $rootScope.selectedCurrency.code
        $window.open(redirectUrl)
        return

      return
]
