import { Bindings } from "booking_app/types";
import { MastheadBannerController } from "./masthead-banner.controller";

export class MastheadBannerComponent {
  bindings: any = {
    travelType: Bindings.ONE_WAY,
    productType: Bindings.ONE_WAY,
    localeCode: Bindings.ONE_WAY,
    hasDescription: Bindings.ONE_WAY,
    hasCustomHeaderText: Bindings.ONE_WAY,
  };

  controller: any = MastheadBannerController;
  template: string = `
    <div ng-style="{'background-image': $ctrl.imageDesktopUrl(true)}"
      class="masthead-image-bg hidden-xs {{ $ctrl.travelType }}-background"
      ng-if="!$ctrl.isMobile()">
      <img ng-src="{{ $ctrl.imageDesktopUrl() }}" alt="banner">
      <masthead-description
        ng-if="$ctrl.hasDescription"
        travel-type="$ctrl.travelType"
        product-type="$ctrl.productType"
        has-custom-header-text="$ctrl.hasCustomHeaderText"
        whitelabel="$ctrl.currentWhiteLabel()">
      </masthead-description>
    </div>
    <div ng-style="{'background-image': $ctrl.imageMobileUrl(true)}"
      class="masthead-image-bg visible-xs {{ $ctrl.travelType }}-background" ng-if="$ctrl.isMobile()">
      <img ng-src="{{ $ctrl.imageMobileUrl() }}" alt="banner">
      <masthead-description
        ng-if="$ctrl.hasDescription"
        travel-type="$ctrl.travelType"
        product-type="$ctrl.productType"
        has-custom-header-text="$ctrl.hasCustomHeaderText"
        whitelabel="$ctrl.currentWhiteLabel()">
      </masthead-description>
    </div>
  `;
}

angular.module("BookingApp").component("mastheadBanner", new MastheadBannerComponent());
