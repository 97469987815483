import { HotelItem } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";
import { MapOpenerService } from "booking_app/services/hotels/map-opener.service";
import { ViewportSizes } from "booking_app/types/viewport-sizes";

export class HotelListItemMapButtonController {
  static $inject = ["AppSettings", "$rootScope", "MapOpenerService"];

  // bindings
  hotel: HotelItem;
  mapText: string;

  constructor(
    private appSettings: AppSettings,
    private $rootScope: any,
    private mapOpenerService: MapOpenerService,
  ) { }

  showInMap($event): void {
    $event.preventDefault();
    this.$rootScope.routeParams.hotelId = this.hotel.id;
    if (document.getElementsByTagName("html")[0].classList.contains("is-keyboard-user")) {
      this.mapOpenerService.openMap(this.hotel, $event.currentTarget as HTMLElement);
    } else {
      this.mapOpenerService.openMap(this.hotel);
    }
  }

  showMapText(): string {
    return this.mapText || "Map";
  }
}
