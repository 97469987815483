import { HotelImageService } from "booking_app/services/hotel-image.service";
import { HotelItem } from "booking_app/types";
import { AngularUtils } from "booking_app/utils";

export class HotelListItemImageController {
  static $inject = ["HotelImageService"];

  // bindings
  hotel: HotelItem;

  backgroundImageStyle: object;
  defaultBackgroundImageStyle: object;

  constructor(private hotelImageService: HotelImageService) { }

  $onChanges(changesObj: any) {
    if (AngularUtils.hasBindingChanged(changesObj.hotel)) {
      this.backgroundImageStyle = this.hotelImageService.getDefaultThumbImageStyle(this.hotel.id);
      this.defaultBackgroundImageStyle = this.hotelImageService.getDefaultHotelBgImageStyle();
    }
  }
}
