declare var moment: any;

export class SearchUtilsService {
  public searchDatesValid(checkInDate: string, checkOutDate: string): boolean {
    const dateFormat = "MM/DD/YYYY";
    const inDate = moment(checkInDate, dateFormat);
    const outDate = moment(checkOutDate, dateFormat);

    if (inDate.format() === "Invalid date" || outDate.format() === "Invalid date") {
      return false;
    }

    const today = moment().startOf("day");

    return inDate.diff(today, "days") >= 0 && outDate.diff(inDate, "days") > 0;
  }

  public isSelectedTimeValid(start: string, end: string): boolean {
    const startTime = moment(start);
    const endTime = moment(end);

    if (startTime.format() === "Invalid date" || endTime.format() === "Invalid date") {
      return false;
    }

    const today = moment().startOf("day");

    return startTime.diff(today, "days") >= 0 && endTime.diff(startTime, "hours") > 4;
  }
}

angular.module("BookingApp").service("SearchUtilsService", SearchUtilsService);
