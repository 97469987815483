import { RoomGuestFieldMobileController } from "./room-guest-field-mobile.controller";
import { Bindings } from "booking_app/types";

export class RoomGuestFieldMobileComponent {
  bindings: any = {
    roomCount: Bindings.ONE_WAY,
    adultCount: Bindings.ONE_WAY,
    childCount: Bindings.ONE_WAY,
    childrenAges: Bindings.ONE_WAY,
    isShowingTotalGuest: Bindings.ONE_WAY,
    onUpdateRoomCount: Bindings.EXPRESSION,
    onUpdateAdultCount: Bindings.EXPRESSION,
    onUpdateChildCount: Bindings.EXPRESSION,
    onUpdateChildrenAges: Bindings.EXPRESSION,
  };
  controller: any = RoomGuestFieldMobileController;
  transclude: any = {
    iconContent: "?iconContent",
  };
  template: string = `
    <div class="room-guest-field-mobile" click-outside="$ctrl.closeModal()">

      <div class="room-guest-field-mobile-input" ng-click="$ctrl.beginSelection()"
        aria-label="{{ $ctrl.summaryTextAriaLabel() }}" role="button">
        <button aria-label="Open guest input" class="open-guest-input"
          ng-transclude="iconContent" ng-click="$ctrl.beginSelection($event)" type="button"></button>
        <input
          class="field-input"
          type="text"
          readonly
          ng-value="$ctrl.summaryText() | parseHtmlInInput"
          ng-keypress="$event.key == 'Enter' && $ctrl.beginSelection()"
          ng-if="!$ctrl.labelOnlySummaryText()"
        >
        <span class="field-input"
          ng-bind-html="$ctrl.summaryText()"
          ng-if="$ctrl.labelOnlySummaryText()">
        </span>
      </div>

      <form-popup-mobile ng-if="$ctrl.modalOpen">
        <title-content>
          <button
            type="button"
            class="fa fa-close close-tick"
            ng-click="$ctrl.closeModal()"
            aria-label="Close">
          </button>
          <div class="summary-text">
            <span ng-bind-html="$ctrl.summaryText()"></span>
           </div>
        </title-content>

        <body-content modal-accessibility-lock="form-popup-mobile">
          <div class="counters-container">
            <room-guest-counter
              room-count="$ctrl.roomCount"
              adult-count="$ctrl.adultCount"
              child-count="$ctrl.childCount"
              children-ages="$ctrl.childrenAges"
              on-update-room-count="$ctrl.updateRoomCount(count)"
              on-update-adult-count="$ctrl.updateAdultCount(count)"
              on-update-child-count="$ctrl.updateChildCount(count)"
              on-update-children-ages="$ctrl.updateChildrenAges(childrenAges)"
            ></room-guest-counter>
            <button
              type="button"
              class="btn-action"
              ng-click="$ctrl.closeModal()">
              <span> {{ "button.done" | translate }} </span>
            </button>
          </div>
        </body-content>
      </form-popup-mobile>

    </div>
  `;
}

angular.module("BookingApp").component("roomGuestFieldMobile", new RoomGuestFieldMobileComponent());
