import { Bindings } from "booking_app/types";
import { GalleryModalButtonController } from "./gallery-modal-button.controller";

export class GalleryModalButtonComponent {
  bindings: any = {
    defaultImage: Bindings.ONE_WAY,
    currentGalleryIndex: Bindings.ONE_WAY,
    hotelId: Bindings.ONE_WAY,
  };
  controller: any = GalleryModalButtonController;
  template: string = `
    <button class="hotel-detail-room-button-text"
            ng-click="$ctrl.openGalleryModal($event)"
            aria-label="Open hotel photo gallery">
      <span translate="txt.see_all_photos"> </span>
      <img ng-src="{{ $ctrl.photosIcon() }}" alt="">
    </button>
  `;
}

angular.module("BookingApp").component("galleryModalButton", new GalleryModalButtonComponent());
