import './bonus'

export const BonusCollectionFactory: any = function (BonusFactory) {
  class BonusCollection {
    public collection;

    constructor (rawBonuses) {
      this.collection = rawBonuses.map(rawBonus => BonusFactory(rawBonus));
    }

    public active () {
      return this.collection.filter(bonus => bonus.isActive())
    }

  }

  return (rawBonuses) => new BonusCollection(rawBonuses)
}

BonusCollectionFactory.$inject = ["BonusFactory"]

angular.module('BookingApp').factory('BonusCollectionFactory', BonusCollectionFactory)
