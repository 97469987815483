require('../assets/javascripts/angular-setup');
require('../assets/javascripts/polyfills.ts');

if (typeof loadWlDeps === 'function') { loadWlDeps(); }

require('../assets/javascripts/booking_app/angular-init');
require('../assets/javascripts/booking_app/bootstrap');
require('../assets/javascripts/booking_app/templates-override')
require('../assets/javascripts/booking_app/modals');
require('../assets/javascripts/booking_app/controllers/error-page-controller');
require('../assets/javascripts/booking_app/controllers/hotels-checkout-controller');
require('../assets/javascripts/booking_app/controllers/hotels-detail-controller');
require('../assets/javascripts/booking_app/controllers/login-controller');
require('../assets/javascripts/booking_app/controllers/login-popup-controller');
require('../assets/javascripts/booking_app/controllers/main-controller');
require('../assets/javascripts/booking_app/controllers/navigation-bar-controller');
require('../assets/javascripts/booking_app/controllers/result-controller');
// TODO: Remember to move this somewhere else that makes more sense after.
// The concept of importing services manually will be gone in Angular because of the decorator concept.
// We probably just need a file that imports all services related to hotels
require("../assets/javascripts/booking_app/services/banners.service");
require("../assets/javascripts/booking_app/services/quick-search.service");
require("../assets/javascripts/booking_app/services/populate-checkout.service");
require("../assets/javascripts/booking_app/services/search-filters");
require("../assets/javascripts/booking_app/services/tracker");
require("../assets/javascripts/booking_app/services/search-sorting.service");
require("../assets/javascripts/booking_app/services/mapbox");
require("../assets/javascripts/booking_app/services/business-filters.service");
require('../assets/javascripts/booking_app/services/hotels');
require('../assets/javascripts/booking_app/services/google-analytics');
require('../assets/javascripts/booking_app/services/exclusive-benefits-service');
require('../assets/javascripts/booking_app/services/cloudfront-image.service');
require('../assets/javascripts/booking_app/services/checkout-validation');
require('../assets/javascripts/booking_app/services/header-url-list');
require('../assets/javascripts/booking_app/services/simple-modal.service');
require('../assets/javascripts/booking_app/services/user-action.service');
require('../assets/javascripts/booking_app/services/alternate-reward-scheme.service');

require('../assets/javascripts/booking_app/services/header-menu-list');
require('../assets/javascripts/booking_app/services/payment-styling-settings');
// This override will occur based on the order of instantiation
// of the modules. The last module created
// (which is the header-menu-list inside the WL specific)
// will have precedence this service module .

require("../assets/javascripts/booking_app/services/redirect.service");
require("../assets/javascripts/booking_app/services/adobe-analytics.service");
require("../assets/javascripts/booking_app/services/page-discovery.service");

require('../assets/javascripts/booking_app/controllers/search-controller');
require('../assets/javascripts/booking_app/controllers/press-controller');
require('../assets/javascripts/booking_app/controllers/page-controller');
require('../assets/javascripts/booking_app/controllers/quick-search-controller');
require('../assets/javascripts/booking_app/controllers/welcome-box-controller');
require('../assets/javascripts/booking_app/controllers/voucher-controller');
require('../assets/javascripts/booking_app/controllers/sign-up-controller');
require('../assets/javascripts/booking_app/controllers/sign-up-popup-controller');
require('../assets/javascripts/booking_app/controllers/forget-password-controller');
require('../assets/javascripts/booking_app/controllers/forget-password-popup-controller');
require('../assets/javascripts/booking_app/controllers/kaligo-select-controller');
require('../assets/javascripts/booking_app/controllers/video-controller');
require('../assets/javascripts/booking_app/controllers/edit-search-controller');
require('../assets/javascripts/booking_app/controllers/acc-nav-controller');
require('../assets/javascripts/booking_app/controllers/acc-profile-controller');
require('../assets/javascripts/booking_app/controllers/acc-manage-bookings-controller');
require('../assets/javascripts/booking_app/controllers/contact-us-form-controller');
require('../assets/javascripts/booking_app/controllers/hotels/search-bar-controller');
require('../assets/javascripts/booking_app/controllers/cancel-booking-controller');

if (typeof loadAppDeps === 'function') { loadAppDeps(); }

require('../assets/javascripts/booking_app/components/bookings');
require('../assets/javascripts/booking_app/components/common');
require('../assets/javascripts/booking_app/components/hotels');
require('../assets/javascripts/booking_app/components/flights/checkout/checkout.state');
require('../assets/javascripts/booking_app/components/shared/travel-breakdown')
