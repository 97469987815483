import { ApiDataService } from "booking_app/services/api-data.service";
import { AppSettings } from "booking_app/values/app-settings";
import { BookingErrorsMapper } from "booking_app/services/booking-errors-mapper.service";
import { CountryService } from "booking_app/services/country.service";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { InputValidationService } from "booking_app/services/input-validation.service";
import {
  BookingDetails,
  Card,
  CountrySelectItem,
  Membership,
  PaymentChannel,
  PaymentMethod,
  ProductType,
  TravelType,
} from "booking_app/types";
import { PayWithPointsCashService } from "../pay-with-points-cash.service";
import { SortService } from "../sort.service";
import { PaymentMethodService } from "../payment-method.service";
import { SavedCard } from "booking_app/models";

export class CheckoutFormService {
  static $inject = [
    "$rootScope", "$timeout",
    "AppSettings", "GlobalStateService", "KaligoConstants",
    "CountryService", "SortService", "BookingErrorsMapper",
    "ApiDataService", "InputValidationService",
    "PayWithPointsCashService", "PaymentMethodService",
  ];

  externalPaymentChannels: string[];
  chinesePaymentChannels: string[];
  countriesList: CountrySelectItem[];
  phoneCountriesList: CountrySelectItem[];

  bookingDetails: BookingDetails;
  newMembership: Membership;

  public checkoutFormScope: any;

  private initialCountryCode: string;

  constructor(
    private $rootScope: any,
    private $timeout: any,
    private appSettings: AppSettings,
    private globalStateService: GlobalStateService,
    private kaligoConstants: any,
    private countryService: CountryService,
    private sortService: SortService,
    private bookingErrorsMapper: BookingErrorsMapper,
    private apiDataService: ApiDataService,
    private inputValidationService: InputValidationService,
    private payWithPointsCashService: PayWithPointsCashService,
    private paymentMethodService: PaymentMethodService,
  ) {
    this.externalPaymentChannels = [
      PaymentChannel.ADYEN_UNIONPAY,
      PaymentChannel.ADYEN_DINERS,
      PaymentChannel.ADYEN_JCB,
      PaymentChannel.ADYEN_ALIPAY,
      PaymentChannel.PAY_ANYONE,
    ];
    this.chinesePaymentChannels = [
      PaymentChannel.ADYEN_ALIPAY,
      PaymentChannel.ADYEN_UNIONPAY,
    ];
    this.initialCountryCode =
      this.appSettings.checkoutSettings.initialCountryOption;

    this.populateCountryList();
  }

  init(scope: any): void {
    this.initScopeVariables(scope);
    this.setupScopeVariables(scope);

    scope.onlyShowVisaLogo = (): boolean => {
      return this.appSettings.onlyShowVisaCardLogo;
    };

    scope.acceptAlipay = (): boolean => {
      return this.$rootScope.selectedCurrency.code === "CNY" && this.nonCreditCardPartner();
    };

    scope.displayVisa = (): boolean => {
      return true;
    };

    scope.displayMastercard = (): boolean => {
      return this.$rootScope.pointsPartner.partner !== "DBS Bank Singapore";
    };

    scope.displayDiscover = (): boolean => {
      return this.appSettings.displayDiscoverLogo;
    };

    scope.acceptJcb = (): boolean => {
      return this.$rootScope.selectedCurrency.code === "JPY" && this.nonCreditCardPartner();
    };

    scope.displayJcb = (): boolean => {
      return this.appSettings.displayJcbLogo || scope.acceptJcb();
    };

    scope.acceptUnionpay = (): boolean => {
      return this.$rootScope.selectedCurrency.code === "CNY" && this.nonCreditCardPartner();
    };

    scope.displayUnionpay = (): boolean => {
      return scope.acceptUnionpay();
    };

    scope.acceptDinersViaAdyen = (): boolean => {
      return this.appSettings.displayDinersOptions;
    };

    scope.displayDiners = (): boolean => {
      return this.appSettings.displayDinersLogo || scope.acceptDinersViaAdyen();
    };

    scope.acceptNoCCPayment = (): boolean => {
      return (
        scope.acceptAlipay() ||
        scope.acceptUnionpay() ||
        scope.acceptDinersViaAdyen() ||
        scope.acceptJcb()
      ) && !this.appSettings.onlyShowVisaCardLogo;
    };

    scope.displayAlipayInternational = (): boolean => {
      return scope.acceptAlipay() && !this.$rootScope.chineseUser();
    };

    scope.displayAlipayChina = (): boolean => {
      return scope.acceptAlipay() && this.$rootScope.chineseUser();
    };

    scope.isExternalPaymentProvider = (): boolean => {
      return this.isExternalPaymentProvider(scope.bookingDetails.paymentChannel);
    };

    scope.isCCPayment = (): boolean => {
      return !scope.isExternalPaymentProvider();
    };

    scope.isAlipayPayment = (): boolean => {
      return scope.acceptAlipay() && scope.bookingDetails.paymentChannel === PaymentChannel.ADYEN_ALIPAY;
    };

    scope.isUnionPayPayment = (): boolean => {
      return scope.acceptUnionpay() && scope.bookingDetails.paymentChannel === PaymentChannel.ADYEN_UNIONPAY;
    };

    scope.isJcbPayment = (): boolean => {
      return scope.acceptJcb() && scope.bookingDetails.paymentChannel === PaymentChannel.ADYEN_JCB;
    };

    scope.isDinersPayment = (): boolean => {
      return scope.acceptDinersViaAdyen() && scope.bookingDetails.paymentChannel === PaymentChannel.ADYEN_DINERS;
    };

    scope.isAdyenCardPayment = (): boolean => {
      return scope.isUnionPayPayment() || scope.isJcbPayment() || scope.isDinersPayment();
    };

    scope.fetchStates = (term: string): void => {
      if (!scope.countryCode) { return; }
      this.$timeout(() => {
        this.apiDataService.get(`countries/${scope.countryCode}/states?name=${term}`)
        .then((res) => {
          scope.statesList = res;
        });
      }, scope.stateDebounceTimer);
    };

    scope.validatePhoneNumber = (): void => {
      let value = scope.bookingDetails.user.phoneNumber;
      if (!value) { return; }
      value = value.replace(/ /g, "");
      scope.checkOutForm.phoneNumber.$setValidity("pattern",
                                                  !!this.inputValidationService.validatePhone(value));
    };

    scope.validatePhone = ((): any => {
      return {
        test: (value: string): boolean => {
          const strippedValue = value.replace(/ /g, "");
          return this.inputValidationService.validatePhone(strippedValue);
        },
      };
    })();

    scope.validateFlightNumber = ((): any => {
      return {
        test: (value: string): boolean => {
          return this.inputValidationService.validateFlightNumber(value);
        },
      };
    })();

    scope.filterSavedCards = () => {
      this.filterSavedCards();
    };

    scope.getSelectedCreditCard = (res: SavedCard[]) => {
      // original implementation when app does not have multiple payment methods
      if (res.length > 0 && !this.appSettings.hasMultiplePaymentMethods) {
        this.paymentMethodService.setActiveTab(PaymentMethod.SAVED_CARDS);
        return res[0];
      }
      // populate selectedCreditCard from PaymentMethodService
      return null;
    }

    this.setupListeners(scope);
    this.checkoutFormScope = scope;
  }

  updateCheckoutError(scope: any): void {
    if (!scope.checkoutState?.errorKey) {
      scope.formErrors =  null;
      return;
    }
    scope.formErrors = this.bookingErrorsMapper.map(scope.checkoutState.errorKey);
  }

  isExternalPaymentProvider(paymentChannel: PaymentChannel): boolean {
    return this.externalPaymentChannels.indexOf(paymentChannel) > -1;
  }

  private initScopeVariables(scope: any): void {
    this.bookingDetails = {
      guest: {},
      user: {},
      browserInfo: {
        acceptHeader: "",
        colorDepth: null,
        javaEnabled: false,
        language: "",
        screenHeight: 0,
        screenWidth: 0,
        timeZoneOffset: 0,
        userAgent: "",
      },
      payment: {
        card: {
          number: "",
          firstName: "",
          lastName: "",
          expirationMonth: "",
          expirationYear: "",
          cvv: "",
        },
      },
      paymentChannel: this.$rootScope.paymentMode,
      newCardSave: false,
      selectedMembership: {
        member_first_name: "",
        member_last_name: "",
        member_no: "",
      },
      otp: "",
    };
    this.newMembership = {
      member_first_name: "",
      member_last_name: "",
      member_no: "",
    };
  }

  private setupScopeVariables(scope: any): void {
    scope.bookingDetails = this.bookingDetails;
    scope.newMembership = this.newMembership;
    scope.memberships = undefined;
    scope.formErrors = null;
    scope.customCheckoutButtonImage = this.appSettings.customCheckoutButtonImage;
    scope.poweredByAscenda = this.appSettings.poweredByAscenda;
    scope.customPaymentLogo = this.appSettings.customPaymentLogo;
    scope.creditCardExpirationYears = this.kaligoConstants.creditCardExpirationYears;
    scope.isStateRequired = false;
    scope.statesList = [];
    scope.countriesList = this.countriesList;
    scope.phoneCountriesList = this.phoneCountriesList;
    scope.stateDebounceTimer = 200;
  }

  private setupListeners(scope: any): void {
    scope.$watch("selectedCurrency.code", (): void => {
      if (this.paymentChannelDoesNotMatchCurrency(scope)) {
        this.resetPaymentChannel(scope);
      }
      this.filterSavedCards();
      switch (this.globalStateService.travelType) {
        case TravelType.HOTELS:
          this.$rootScope.selectedCurrency.acceptAmex = this.appSettings.supportedCards[Card.AMEX]
                                                        .indexOf(this.$rootScope.selectedCurrency.code) > -1;
          if (this.$rootScope.landingPage.hasProductType(ProductType.REDEEM)) {
            scope.hotelPrice = this.payWithPointsCashService.calculatePointsCashToPay(scope.hotelPrice);
          }
          break;
        case TravelType.CARS:
          this.$rootScope.selectedCurrency.acceptAmex = this.appSettings.supportedCards[Card.AMEX]
                                                        .indexOf(this.$rootScope.selectedCurrency.code) > -1;
          break;
      }
    });

    // This part is the hotel implementation, which does the opposite thing as cars checkout form, i am confused
    scope.$watch("bookingDetails.guest.country", (newCountry, oldCountry): void => {
      if (newCountry) {
        scope.countryCode = newCountry.code;
        if (scope.bookingDetails.guest.state) {
          scope.bookingDetails.guest.state = null;
        }
      }
      this.checkStateRequired(scope);
    });

    scope.$watch("checkoutState.errorKey", (): void => {
      this.updateCheckoutError(scope);
    });
  }

  private nonCreditCardPartner(): boolean {
    return this.$rootScope.pointsPartner && this.$rootScope.pointsPartner.category !== PaymentChannel.CREDIT_CARD;
  }

  private isChinesePayment(scope: any): boolean {
    return this.chinesePaymentChannels.indexOf(scope.bookingDetails.paymentChannel) > -1;
  }

  private isJcbPayment(scope: any): boolean {
    return scope.bookingDetails.paymentChannel === PaymentChannel.ADYEN_JCB;
  }

  private paymentChannelDoesNotMatchCurrency(scope: any): boolean {
    return (this.isChinesePayment(scope) && this.$rootScope.selectedCurrency.code !== "CNY") ||
           (this.isJcbPayment(scope) && this.$rootScope.selectedCurrency.code !== "JPY");
  }

  private resetPaymentChannel(scope: any): void {
    scope.bookingDetails.paymentChannel = PaymentChannel.CREDIT_CARD;
  }

  private checkStateRequired(scope: any): void {
    scope.isStateRequired =
      scope.bookingDetails.guest.country &&
      this.countryService.isStateRequired(scope.bookingDetails.guest.country.code);
  }

  private populateCountryList(): void {
    const countriesList: CountrySelectItem[] = [];
    const phoneCountriesList: CountrySelectItem[] = [];
    let id: string;
    let countryItem: CountrySelectItem;
    let phoneCountryItem: CountrySelectItem;

    let initialCountryItem: CountrySelectItem; // Temporary Holders
    let initialPhoneCountryItem: CountrySelectItem; // Temporary Holders

    this.countryService.getCountries().forEach( country => {
      id = `(+${country.phoneCode})`;
      countryItem = { id: country.code, code: country.code, text: country.name };
      phoneCountryItem = { id, text: `${country.name} ${id}` };

      if (this.initialCountryCode === country.code) {
        initialCountryItem = countryItem;
        initialPhoneCountryItem = phoneCountryItem;
      } else {
        countriesList.push(countryItem);
        phoneCountriesList.push(phoneCountryItem);
      }
    });

    this.countriesList = this.sortService.string(countriesList, "text");
    this.phoneCountriesList = this.sortService.string(phoneCountriesList, "text");

    if (this.initialCountryCode) {
      this.countriesList.unshift(initialCountryItem);
      this.phoneCountriesList.unshift(initialPhoneCountryItem);
    }
  }

  private filterSavedCards(): void {
    const stripeCards = this.paymentMethodService.savedCards.filter((card) => card.payment_channel === "stripe");
    let filteredCards = stripeCards;

    if (this.checkoutFormScope.acceptJcb()) {
      const jcbCards = this.paymentMethodService.adyenSavedCards.filter((card) => card.card_type === Card.JCB);
      filteredCards = filteredCards.concat(jcbCards);
    }
    if (this.checkoutFormScope.acceptUnionpay()) {
      const cupCards = this.paymentMethodService.adyenSavedCards.filter((card) => card.card_type === Card.UNIONPAY);
      filteredCards = filteredCards.concat(cupCards);
    }
    if (this.checkoutFormScope.acceptDinersViaAdyen()) {
      const dinersCards = this.paymentMethodService.adyenSavedCards.filter((card) => card.card_type === Card.DINERS);
      filteredCards = filteredCards.concat(dinersCards);
    }

    this.checkoutFormScope.storedCreditCards = filteredCards;
    this.paymentMethodService.savedCards = filteredCards;
    this.checkoutFormScope.updateSelectedCreditCard(filteredCards);
  }
}

angular.module("BookingApp").service("CheckoutFormService", CheckoutFormService);
