import { CreditCardFormData } from "./credit-card-form";
import { Currency } from "booking_app/types";
import { AngularUtils } from "booking_app/utils";
import { AppSettings } from "booking_app/values/app-settings";

export class CreditCardDetailController {
  static $inject = ["AppSettings", "$rootScope"];

  // injectors
  appSettings: any;
  $rootScope: any;

  // bindings
  data: CreditCardFormData;
  onUpdate: (obj: { data: CreditCardFormData }) => void;
  parentForm: any;
  formName: string;
  submitted: boolean;

  internalData: CreditCardFormData;

  constructor(appSettings: AppSettings, $rootScope: any) {
    this.appSettings = appSettings;
    this.$rootScope = $rootScope;
  }

  $onChanges(changesObj): void {
    if (AngularUtils.hasBindingChanged(changesObj.data)) {
      this.internalData = angular.copy(this.data);
    }
  }

  onUpdateInternalData(data) {
    this.internalData = data;
    this.onUpdateData();
  }

  onUpdateData() {
    this.onUpdate({ data: this.internalData });
  }

  currency(): Currency {
    return this.$rootScope.selectedCurrency;
  }

  cardSupportedCurrencies(): any {
    return this.appSettings.supportedCards;
  }

  pointsPartner(): any {
    return this.$rootScope.pointsPartner;
  }
}
