declare var moment: any;

import Pikaday from "pikaday/pikaday";
import { AngularUtils, DateAdapterUtils } from "booking_app/utils";
import { DatePickerSelectionState } from "./date-picker-selection-state";
import { AppSettings } from "booking_app/values/app-settings";
import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { KeyboardKeyType } from "booking_app/types";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { TravelType } from "booking_app/types";

export class DatePickerController {
  static $inject = [
    "$element",
    "$rootScope",
    "$timeout",
    "$translate",
    "AppSettings",
    "GlobalStateService",
  ];

  // bindings
  minDate: string;
  startDate: string;
  endDate: string;
  dateFormat: string;
  onSelectStartDate: (obj: { value: string }) => void;
  onSelectEndDate: (obj: { value: string }) => void;
  selectionState: DatePickerSelectionState;
  locale: string;
  destroyTimeout: number = 400; // in miliseconds, match with leave animation speed
  numberOfMonths: number;

  private datepicker: any;
  private keyboardEnabled: boolean;
  private updatedDate: string;
  private keyDownListenerCallBack: () => {};

  constructor(
    private $element: any,
    private $rootScope: any,
    private $timeout: any,
    private $translate: any,
    private appSettings: AppSettings,
    private globalStateService: GlobalStateService,
  ) {
  }

  $onInit() {
    this.initializeDatePicker();
    this.updateDates();
    this.initializeKeyboardEventListener();
  }

  $onDestroy() {
    this.$timeout(() => this.destroyDatePicker(), this.destroyTimeout || 400);
  }

  $onChanges(changesObj) {
    if (this.datepicker && (
      AngularUtils.hasBindingChanged(changesObj.selectionState) ||
      AngularUtils.hasBindingChanged(changesObj.startDate) ||
      AngularUtils.hasBindingChanged(changesObj.endDate)
    )) {
      this.updateDates();
    }
    if (this.datepicker && AngularUtils.hasBindingChanged(changesObj.minDate)) {
      this.updateMinDate();
    }
    if (this.datepicker && this.locale && AngularUtils.hasBindingChanged(changesObj.locale)) {
      this.destroyDatePicker();
      this.initializeDatePicker();
      this.updateDates();
    }
  }

  ariaDateCheckInCheckOut(): string {
    const dateFormat = this.dateFormat || "ll";
    const startDate = this.stringToMoment(this.startDate).format(dateFormat);
    const endDate = this.stringToMoment(this.endDate).format(dateFormat);

    switch (this.globalStateService.travelType) {
      case (TravelType.FLIGHTS):
        return `Depart ${startDate} Return ${endDate} datepicker button`;
      case (TravelType.CARS):
        return `Pick-up ${startDate} Drop-off ${endDate} datepicker button`;
      default:
        return `Check-in ${startDate} Check-out ${endDate} datepicker button`;
    }
  }

  private stringToMoment(date: string): any {
    return moment(date, DateAdapterUtils.V2_DATE_FORMAT);
  }

  private destroyDatePicker(): void {
    this.datepicker.destroy();
    this.keyboardEnabled = false;
    this.removeKeyDownListener(this.keyDownListenerCallBack);
  }

  private initializeDatePicker() {
    this.datepicker = new Pikaday({
      field: this.$element.find("input")[0],
      container: this.$element.find(".datepicker-container")[0],
      defaultDate: this.defaultDate(),
      setDefaultDate: true,
      minDate: this.toDateObject(this.minDate),
      maxDate: this.setMaxDate(this.appSettings.maximumAvailableBookingMonths),
      yearRange: 1,
      format: "ll",
      bound: false,
      showMonthAfterYear: moment.localeData()._showMonthAfterYear || false,
      yearSuffix: moment.localeData()._yearSuffix || "",
      numberOfMonths: this.calenderNumberOfMonths(),
      onSelect: (date) => {
        this.onSelect(date);
      },
      onDraw: () => {
        this.$timeout(this.initFocusEventListener(), 1);
      },
      firstDay: moment.localeData()._firstDay || 1,
      ...this.getI18nSettings(),
      enableSelectionDaysInNextAndPreviousMonths: this.appSettings.canSelectDaysOfPreviousAndNextMonth,
    });
  }

  private onSelect(date: string): void {
    this.updatedDate = date;
    this.setMaxBookingDays(this.appSettings.maxBookingDays);
    const onSelectDateCallBack: () => {} = this.onSelectDate.bind(this);
    this.$timeout(onSelectDateCallBack, 100);
  }

  private setMaxBookingDays(maxBookingDays: number): void {
    if (maxBookingDays && this.selectionState === "START") {
      this.datepicker.setMaxDate(moment(this.updatedDate).add(this.appSettings.maxBookingDays, "d").toDate());
    }
  }

  private onSelectDate(): void {
    if (!this.keyboardEnabled) {
      if (this.selectionState === "START") {
        this.onSelectStartDate({ value: this.toFormattedDate() });
        this.selectionState = "END";
      } else if (this.selectionState === "END") {
        this.onSelectEndDate({ value: this.toFormattedDate() });
        this.selectionState = "START";
        this.removeKeyDownListener(this.keyDownListenerCallBack);
      } else if (this.selectionState === "DATE") {
        this.onSelectStartDate({ value: this.toFormattedDate() });
        this.removeKeyDownListener(this.keyDownListenerCallBack);
      }
    } else {
      this.keyboardEnabled = false;
    }
  }

  private initializeKeyboardEventListener(): void {
    this.keyDownListenerCallBack = this.keyDownListener.bind(this);
    document.addEventListener("keydown",
    this.keyDownListenerCallBack);
  }

  private keyDownListener(event: KeyboardEvent): void {
    event.preventDefault();
    switch (event.code) {
      case KeyboardKeyType.ARROWUP:
      case KeyboardKeyType.ARROWDOWN:
      case KeyboardKeyType.ARROWLEFT:
      case KeyboardKeyType.ARROWRIGHT:
        this.keyboardEnabled = true;
        break;
      case KeyboardKeyType.ENTER:
        this.keyboardEnabled = false;
        this.onSelectDate();
        break;
      case KeyboardKeyType.TAB:
        this.keyboardEnabled = false;
        this.removeKeyDownListener(this.keyDownListenerCallBack);
        break;
    }
  }

  private removeKeyDownListener(keyPressEvent: () => {}): void {
    document.removeEventListener("keydown", keyPressEvent);
  }

  private getI18nSettings(): any {
    return {
      i18n: {
        months: this.getMonthNames(),
        weekdays: moment.localeData()._weekdays,
        weekdaysShort: moment.localeData()._weekdaysMin,
      },
      isRTL: this.isRTL(),
    };
  }

  private getMonthNames(): string[] {
    const localesWithShortMonthFormat = ["zh-CN", "zh-TW", "tw"];
    if (localesWithShortMonthFormat.includes(this.$rootScope.selectedLocale.lang_code)) {
      return moment.localeData().monthsShort();
    } else {
      return moment.localeData().months();
    }
  }

  private isRTL(): boolean {
    return this.$rootScope.globalState.displayAlignment === "rtl";
  }

  private calenderNumberOfMonths(): number {
    if (this.numberOfMonths) {
      return this.numberOfMonths;
    }

    const MAX_VIEWPORT = ViewportSizes.SM_MAX + 1;

    if (this.$rootScope.globalState.browserWidth >= MAX_VIEWPORT) {
      return 2;
    } else {
      return 1;
    }
  }

  private updateDates() {
    this.datepicker.setStartRange(this.toDateObject(this.startDate));
    this.datepicker.setEndRange(this.toDateObject(this.endDate));
    this.datepicker.hide();
    this.datepicker.show();
  }

  private updateMinDate() {
    this.datepicker.setMinDate(this.toDateObject(this.minDate));
    this.datepicker.hide();
    this.datepicker.show();
  }

  private defaultDate(): string {
    if (this.selectionState === "START") {
      return this.toDateObject(this.startDate);
    } else if (this.selectionState === "END") {
      return this.toDateObject(this.endDate);
    }
  }

  private toDateObject(dateString) {
    return moment(dateString, DateAdapterUtils.V2_DATE_FORMAT).toDate();
  }

  private setMaxDate(noOfMonths) {
    return moment(new Date()).add(noOfMonths, "M").toDate();
  }

  private toFormattedDate() {
    return moment(this.updatedDate).format(DateAdapterUtils.V2_DATE_FORMAT);
  }

  private initFocusEventListener(): void {
    const pikaTitle = this.$element.find(".pika-title");
    if (pikaTitle) {
      pikaTitle.removeAttr("aria-live");
    }
    this.setupPikaChangeMonthButtons();
    this.setupPikaHeader();
    this.setupPikaButtons();
  }

  private setupPikaChangeMonthButtons(): void {
    const pikaPrev = this.$element.find(".pika-prev")[0];
    const pikaNext = this.$element.find(".pika-next")[0];
    pikaPrev.setAttribute("role", "none");
    pikaNext.setAttribute("role", "none");
    pikaPrev.setAttribute("aria-label", this.$translate.instant("Previous Month"));
    pikaNext.setAttribute("aria-label", this.$translate.instant("Next Month"));
  }

  private setupPikaHeader(): void {
    // setup table headers
    const pikaHeaders = this.$element.find(".pika-table abbr");
    pikaHeaders.each((_, el) => {
      el.setAttribute("aria-hidden", "true");
    });
  }

  private setupPikaButtons(): void {
    // setup pikaday aria d
    const pikaButtons = this.$element.find(".pika-button.pika-day");
    pikaButtons.each((_, el) => {
      const pikaData = el.dataset;
      const date = new Date(pikaData.pikaYear, pikaData.pikaMonth, pikaData.pikaDay);
      let ariaDateString: string = `${ moment(date).format("dddd, ll") }`;

      if (el.parentElement.classList.contains("is-disabled")) {
        ariaDateString = `${ariaDateString}, date disabled`;
      }

      $(el).attr("aria-label", ariaDateString);
    });
  }
}
