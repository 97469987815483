import { ApiDataService  } from './api-data.service';

export class VouchersService {

  static $inject = ['$q', 'ApiDataService'];
  
  constructor(
    private $q,
    private apiDataService: ApiDataService,
  ) { }

  validate(
    landingPageUrl: string,
    id: number|string,
    price: number|string,
    partnerId: number|string,
    voucherType: number|string = ''
  ): any {
    let voucherEndpointUrl = `landing_pages/${landingPageUrl}/vouchers/${id}?price=${price}&partnerId=${partnerId}&voucher_type_id=${voucherType}`;

    return this.apiDataService
             .get(voucherEndpointUrl)
             .then((data) => data, (error) => this.$q.reject(error));
  }

}

angular.module('BookingApp').service('VouchersService', VouchersService);
