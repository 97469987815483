require('./points-partner')

angular.module('BookingApp')

.factory 'PointsPartnersCollection', [

  "PointsPartner"
  (PointsPartner) ->

    defaultPartner = 123

    (partnersList, defaultId) ->

      collection = (PointsPartner(rawPointsPartner) for rawPointsPartner in partnersList)
      visible = (partner for partner in collection when partner.visible)

      findById = (id) ->
        for partner in collection
          return angular.copy(partner) if partner.id == id
        null

      determinePartner = ->
        for id in arguments
          partner = findById(id)
          return partner if partner
        defaultPartner

      defaultPartner = findById(defaultId)

      {
        collection: collection
        visible: visible
        all: collection
        findById: findById
        determinePartner: determinePartner
      }
]
