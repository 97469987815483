import { AppSettings } from "booking_app/values/app-settings";

export class WhitelabelTranslateService {
  static $inject = ["$translate", "AppSettings"];

  constructor(
    private $translate: any,
    private appSettings: AppSettings,
  ) { }

  public translate(translateString: string, translateValues?: any): string {
    let translationKey: string = `wl.${this.appSettings.tenant.toLowerCase()}.${translateString}`;
    let translatedText: string = this.$translate.instant(
      `${translationKey}`,
      translateValues,
    );

    if (translatedText === translationKey && this.appSettings.wlTranslationPath) {
      translationKey = `${this.appSettings.wlTranslationPath}.${translateString}`;
      translatedText = this.$translate.instant(
        `${translationKey}`,
        translateValues,
      );
    }

    if (translatedText === translationKey) {
      translatedText = this.$translate.instant(
        translateString,
        translateValues,
      );
    }

    return translatedText;
  }
}

angular.module("BookingApp").service("WhitelabelTranslateService", WhitelabelTranslateService);
