type CardType = "visa" | "mastercard" | "amex" | "diners" | "discover" | "aura" | "elo" | "hipercard" | "jcb";

interface CardTypePattern {
  readonly pattern: RegExp;
  readonly eagerPattern: RegExp;
  readonly card: CardType;
}

export class CardTypeService {

  cardTypePatterns: CardTypePattern[] = [
    // EXAMPLE
    // 4111111111111111
    // 4012888888881881
    // regex grabbed from here: http://www.regular-expressions.info/creditcard.html
    {
      pattern: /^4\d{15}?$/,
      eagerPattern: /^4/,
      card: "visa",
    },
    // EXAMPLE
    // 5555555555554444
    // 5105105105105100
    // regex grabbed from here: http://www.regular-expressions.info/creditcard.html
    {
      pattern: /^(5[1-5]\d{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)\d{12})$/,
      eagerPattern: /^(5[1-5]\d{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)\d{12})/,
      card: "mastercard",
    },
    // EXAMPLE
    // 378282246310005
    // 371449635398431
    // regex grabbed from here: http://www.regular-expressions.info/creditcard.html
    {
      pattern: /^3[47]\d{13}$/,
      eagerPattern: /^3[47]/,
      card: "amex",
    },
    // EXAMPLE
    // 30569309025904
    // 38520000023237
    // regex grabbed from here: http://www.regular-expressions.info/creditcard.html
    {
      pattern: /^3(?:0[0-5]|[68][0-9])\d{11}$/,
      eagerPattern: /^(36|38|30[0-5])/,
      card: "diners",
    },
    // EXAMPLE
    // 6011111111111117
    // regex grabbed from here: http://stackoverflow.com/a/13500918/613896
    {
      pattern: /^6(?:011\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})$/,
      eagerPattern: /^(6011|65|64[4-9]|622)/,
      card: "discover",
    },
    // EXAMPLE
    // 5078601870000127985
    // 5078601800003247449
    {
      pattern: /^(5078\d{15})$/,
      eagerPattern: /^(5078)/,
      card: "aura",
    },
    // EXAMPLE
    // 6362970000457013
    {
      pattern: /^(6362\d{12})$/,
      eagerPattern: /^(6362)/,
      card: "elo",
    },
    // EXAMPLE
    // 6062825624254001
    {
      pattern: /^(6062\d{12})$/,
      eagerPattern: /^(6062)/,
      card: "hipercard",
    },
    // EXAMPLE
    // 3088000000000009
    // regex grabbed from here: https://stackoverflow.com/questions/35565991/reg-ex-for-jcb-credit-card-validation
    {
      pattern: /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))\d{12})$/,
      eagerPattern: /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9])))/,
      card: "jcb",
    },
  ];

  public getCardType(ccNumber: string, eager: boolean): CardType {
    const cardType = this.cardTypePatterns.find((cardTypePattern) => {
      return cardTypePattern[this.getProperty(eager)].test(ccNumber);
    });
    return (cardType) ? cardType.card : undefined;
  }

  private getProperty(eager: boolean): string {
    if (eager) {
      return "eagerPattern";
    } else {
      return "pattern";
    }
  }
}

angular.module("BookingApp").service("CardTypeService", CardTypeService);
