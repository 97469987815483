declare var angular: any

import { ApiDataService } from './api-data.service';

class MembershipsService {

  static $inject = [
    'ApiDataService',
  ];

  constructor(
    private apiDataService: ApiDataService,
  ) { }

  getMembershipDetails(partner_id) {
    return this.apiDataService.get(`partners/${partner_id}/memberships`);
  }

  selectMembership(membershipsList, newMembership, transactionMembership) {
    for (var membership of membershipsList) {
      if(transactionMembership.member_no == membership.member_no) return membership;
    }
    newMembership.member_no = transactionMembership.member_no;
    newMembership.member_first_name = transactionMembership.member_first_name;
    newMembership.member_last_name = transactionMembership.member_last_name;
    return newMembership;
  }

}

angular.module("BookingApp").service('MembershipsService', MembershipsService)
