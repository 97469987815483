accordion = ($timeout, $translate) ->
  link:
    post: (scope, elem, attr) ->

      scope.showPanel = false

      calculateChildrenHeight = (element) ->
        totalHeight = 0
        children = element.children
        angular.forEach(children, (child) ->
          childStyle = window.getComputedStyle(child)
          childHeight = child.offsetHeight
          marginTop = parseFloat(childStyle.marginTop)
          marginBottom = parseFloat(childStyle.marginBottom)
          totalHeight += childHeight + marginTop + marginBottom
        )
        totalHeight

      initializeContentHeight = ->
        scope.contentElement = elem[0].getElementsByClassName('content')[0]
        # includes padding 15px by default
        includePadding = if attr.includePadding? and
          attr.includePadding.toLowerCase() == 'false'
            false
          else
            true
        paddingValue = if includePadding then 15 else 0

        if scope.contentElement
          scope.contentHeight = (
            calculateChildrenHeight(scope.contentElement) + paddingValue
          )
          setContentHeight()

      # Wait for the view to be fully initialized
      $timeout(initializeContentHeight, 100)

      scope.togglePanel = () ->
        scope.showPanel = !scope.showPanel
        setContentHeight()

      setContentHeight = () ->
        if scope.showPanel
          scope.contentElement.style.height = "#{scope.contentHeight}px"
        else
          scope.contentElement.style.height = "0"

      scope.formatTerm = (term) ->
        translatedString = $translate.instant(term)
        if translatedString == term
          return term.replace(/_/g, " ")
        else
          return translatedString

      scope.$on('accordionDataUpdated', () ->
        $timeout(initializeContentHeight, 0)
      )

accordion
  .$inject = ['$timeout', '$translate']

angular.module("BookingApp")
.directive "accordion", accordion
