import { RoomInfo } from "booking_app/models/room-info";
import { CurrenciesService } from "booking_app/services/currencies.service";
import { AppSettings } from "booking_app/values/app-settings";

export class MobileRoomPricesButtonController {
  static $inject = [
    "$rootScope",
    "CurrenciesService",
    "AppSettings",
  ];

  // bindings
  productType: "earn" | "redeem";
  searchCompleted: boolean;
  rooms: RoomInfo[];
  scrollTo: () => void;
  loyaltyPointLabel: string;

  constructor(
    public $rootScope: any,
    private currenciesService: CurrenciesService,
    private appSettings: AppSettings,
  ) { }

  hasRoomInfo(): boolean {
    return this.rooms && this.rooms.length > 0;
  }

  firstRoom() {
    if (this.hasRoomInfo()) {
      return this.rooms[0];
    }
  }

  isEarn() {
    return this.productType === "earn";
  }

  isRedeem() {
    return this.productType === "redeem";
  }

  currencyRate() {
    return this.$rootScope.selectedCurrency.rate;
  }

  showTotalNights() {
    return this.$rootScope.globalState.showTotalNights;
  }

  roomEarnRate() {
    return this.rooms[0].score + this.rooms[0].bonuses;
  }

  loyaltyLabel() {
    return this.loyaltyPointLabel ? this.loyaltyPointLabel : "Miles";
  }

  convertedOriginalPricePerRoomPerNightWithTax(): number {
    return this.currenciesService.convertFromUsd(this.firstRoom().original_price_per_room_per_night);
  }

  getHeaderHeight(): number {
    return this.appSettings.getStickyHeaderHeight();
  }

}
