import { StripeIntentPaymentFormController } from "./stripe-intent-payment-form.controller";
import { Bindings } from "booking_app/types";

export class StripeIntentPaymentFormComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      bookingDetails: Bindings.ONE_WAY,
      checkOutForm: Bindings.ONE_WAY,
    };
    this.controller = StripeIntentPaymentFormController;
    this.templateUrl = "/html/components/stripe_intent_payment_form";
  }
}

angular.module("BookingApp").component("stripeIntentPaymentForm", new StripeIntentPaymentFormComponent());
