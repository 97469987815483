import { TranslationUtils } from "booking_app/utils/translation-utils";

angular.module("BookingApp")
  .directive("humanizeTranslation", [
    "TranslationUtils",
    "$rootScope", (
      translationUtils: TranslationUtils,
      $rootScope: any,
    ) => {
      return {
        restrict: "A",
        link: (scope: any, element: any, attr: any) => {
          scope.$watch(
            () =>  $rootScope.selectedLocale,
            () => {
              const translatedValue = translationUtils.humanizeTranslation(attr.humanizeTranslation);
              element.html(translatedValue);
            });

          return;
        },
      };
    },
  ]);
