CarsState = {
  init: (params, searchState) ->

    if searchState.pickupLocation && searchState.pickupLocation.value
      @pickupLocation = angular.copy(searchState.pickupLocation)
    else
      @pickupLocation = {
        id: angular.copy(params.pickup_location)
      }

    if searchState.returnLocation && searchState.returnLocation.value
      @returnLocation = angular.copy(searchState.returnLocation)
    else
      @returnLocation = {
        id: angular.copy(params.return_location)
      }

    @sameAsPickup = @pickupLocation.id == @returnLocation.id
    @pickupDateTime = angular.copy(params.pickup_time)
    @returnDateTime = angular.copy(params.return_time)
    @pickupMoment = moment(params.pickup_time)
    @returnMoment = moment(params.return_time)
    @locationType = params.location_type
    @driverCountry = angular.copy(params.driver_country)
    @age = angular.copy(params.age)

    @duration = @returnMoment.diff(@pickupMoment, 'days')

    @checkin = @pickupMoment.format('MM/DD/YYYY')
    @checkout = @returnMoment.format('MM/DD/YYYY')
    @pickupTime = @pickupMoment.format("HH:mm")
    @returnTime = @returnMoment.format("HH:mm")

    if params.booking_key
      @bookingKey = params.booking_key

    # Support adyen & alipay redirect to checkout page
    if params.transactionId
      @transactionId = params.transactionId

    return

  isEqual: (searchState) ->
    return (
      (@pickupLocation && @pickupLocation.id == searchState.pickupLocation.id) &&
      (@returnLocation && @returnLocation.id == searchState.returnLocation.id) &&
      (@checkin && @checkin == searchState.checkin) &&
      (@checkout && @checkout == searchState.checkout) &&
      (@pickupTime && @pickupTime == searchState.pickupTime) &&
      (@returnTime && @returnTime == searchState.returnTime) &&
      (@driverCountry && @driverCountry == searchState.driverCountry) &&
      (@age && @age == searchState.age)
    )

  clear: () ->
    for own prop of @
      unless typeof @[prop] is 'function'
        delete @[prop]
    return

}

angular.module('BookingApp')
.value 'CarsState', CarsState
