import { HeaderUrlLink } from "booking_app/constants/header-url-link";

export class HeaderMenuList {
  public headerList: HeaderUrlLink[];
  constructor(
  ) {
    this.setupHeaderList();
  }

  setupHeaderList(): void {
    this.headerList = [];
  }
}
angular.module("BookingApp").service("HeaderMenuList", HeaderMenuList);
