declare var moment: any;

export class TimeUtils {

  static MIN_MINUTES_IN_DAY = 0
  static MAX_MINUTES_IN_DAY = 1439

  static numberToHours(value: number): string {
    return TimeUtils.formatHourMinuteTime(moment().startOf('day').minute(value));
  }

  static timeToNumber(time: string): number {
    let hours = parseInt(moment(time).format("HH"))
    let minutes = parseInt(moment(time).format("mm"))

    return hours * 60 + minutes;
  }

  static formatHourMinuteTime(time: string, format: string = "h:mm A"): string {
    return moment(time).format(format);
  }

  static formatDateAndTime(time, format: string = "ddd, D MMM, YYYY - h:mm A"): string {
    return moment(time).format(format);
  }

  static formatLocalizedUTCDate(time: string): string {
    return moment.utc(time).format('LL');
  }

  static formatShortLocalizedUTCDate(time: string): string {
    return moment.utc(time).format("ll");
  }

  static formatLocalizedUTCHourMinute(time: string): string {
    return moment.utc(time).format('LT');
  }

  static getArrivalDayOffset(arrivalTime: string, departureTime: string): number {
    return moment(arrivalTime).startOf('day').diff(moment(departureTime).startOf('day'), 'days')
  }

  static formatDuration(duration) {
    return (duration.days() > 0 ? `${duration.days()}d ` : "") +
      (duration.hours() > 0 ? `${duration.hours()}h ` : "") +
      (duration.minutes() > 0 ? `${duration.minutes()}m` : "")
  }
}
