import { Bindings } from "booking_app/types";
import { MenuLinksController } from "./menu-links.controller";

export class MenuLinksComponent {
  controller: any = MenuLinksController;
  bindings: any = {
    linkOption: Bindings.ONE_WAY,
    useSequenceIndex: Bindings.ONE_WAY,
  };
  template: string = `
    <div class="link-menu {{ $ctrl.linkOption.klass }}"
      ng-class="{ 'active-link-menu': $ctrl.isActive() }">
      <a class="nav"
        tabindex="{{ $ctrl.useSequenceIndex ? 0 : $ctrl.linkOption.tabIndex }}"
        role="button"
        ng-class="{ active: $ctrl.isActive() }"
        ng-click="$ctrl.triggerOnClick($event)"
        ng-href="{{ $ctrl.urlRedirect() }}">
        <i class="{{ $ctrl.linkOption.icon }}"></i>
        <span translate="{{ $ctrl.linkOption.title }}"> </span>
      </a>
    </div>
  `;
}

angular.module("BookingApp").component("menuLinks", new MenuLinksComponent());
