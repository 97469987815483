import { Bindings } from "booking_app/types";
import { RoomImageController } from "./room-image.controller";

export class RoomImageComponent {
  bindings: any = {
    room: Bindings.ONE_WAY,
    defaultImage: Bindings.ONE_WAY,
  };
  controller: any = RoomImageController;

  template: string = `
    <div class="default-image hotel-image"
      ng-style="{ 'background-image': 'url({{ $ctrl.defaultImage }})' }"
      ng-if="!$ctrl.hasRoomImages()">
    </div>
    <room-details-modal-button room="$ctrl.room" button-aria-label="'View more room photos'">
      <modal-button-content>
        <div class="hotel-image"
          ng-style="{ background: 'url({{ $ctrl.displayRoomImage() }}) center center / cover no-repeat' }"
          ng-if="$ctrl.hasRoomImages()">
          <div class="image-counter">
            1 / {{ $ctrl.room.images.length }}
          </div>
        </div>
        <div class="broken-image hotel-image"
          ng-style="{ background: 'url({{ $ctrl.defaultImage }}) center center / cover no-repeat' }">
        </div>
        <div class="room-details" translate='View more'>
        </div>
      </modal-button-content>
    </room-details-modal-button>
  `;
}

angular.module("BookingApp").component("roomImage", new RoomImageComponent());
