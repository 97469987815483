export class PointsPartnerMapperService {
  public pointsPartnerList = {};
  constructor() {
    this.setupPointsPartnerList();
  }

  setupPointsPartnerList(): void {
    this.pointsPartnerList = {};
  }
}

angular.module("BookingApp").service("PointsPartnerMapperService", PointsPartnerMapperService);
