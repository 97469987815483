import { ModalSettings } from "booking_app/types/modal-settings";

export const modalSettings: ModalSettings  = {
  "resend-confirmation-success-modal": {
    content: {
      modalHeader: "Confirmation email sent",
      resolveButtonText: "OK",
    },
  },
  "resend-confirmation-failed-modal": {
    content: {
      modalHeader: "Send Failed. Please try again",
      resolveButtonText: "Ok",
    },
  },
  "cancel-booking-success-modal": {
    backdrop: "static",
    content: {
      modalHeader: "wl.booking_cancelled_header",
      modalBody: "wl.booking_cancelled_message",
      resolveButtonText: "Ok",
    },
  },
  "cancel-booking-failed-modal": {
    backdrop: "static",
    content: {
      modalHeader: "bp.admin.notifications.error",
      resolveButtonText: "Ok",
    },
  },
  "cancel-booking-modal": {
    backdrop: "static",
    size: "sm",
    content: {
      modalBody: "my_bookings.cancel_booking.are_you_sure",
      resolveButtonText: "Yes",
      rejectButtonText: "No",
    },
  },
  "cancelling-booking-modal": {
    backdrop: "static",
    size: "sm",
    content: {
      modalBody: "Cancelling...",
    },
  },
  "gulf-residency-modal": {
    size: "sm",
    backdrop: "static",
    keyboard: false,
    content: {
      modalHeader: "search.gulf_resident.header",
      modalBody: "search.gulf_resident.body",
      resolveButtonText: "Yes",
      rejectButtonText: "No",
    },
  },
  "cancel-booking-request-modal": {
    content: {
      modalHeader: "my_bookings.cancel_booking_request.title",
      modalBody: "my_bookings.cancel_booking_request.are_you_sure",
      resolveButtonText: "Ok",
      rejectButtonText: "Cancel",
    },
  },
  "cancel-booking-request-success-modal": {
    size: "sm",
    content: {
      modalHeader: "Done!",
      modalBody: "my_bookings.cancel_booking_request.success",
      resolveButtonText: "OK",
    },
  },
  "cancel-booking-request-failed-modal": {
    size: "sm",
    content: {
      modalHeader: "Done!",
      modalBody: "Your cancellation request has not been sent.<br> Please try again.",
      resolveButtonText: "OK",
    },
  },
  "hotel-room-unavailable-modal": {
    backdrop: "static",
    keyboard: false,
    content: {
      modalBody: "cancellation_policy_error_message",
      resolveButtonText: "cancellation_policy_error_button",
    },
  },
  "flights-expiry-back-to-results-page-modal": {
    backdrop: "static",
    keyboard: false,
    content: {
      modalBody: "flights.itinerary_unavailable",
      resolveButtonText: "flights.back_to_search_results",
    },
  },
  "flights-expiry-back-to-landing-page-modal": {
    backdrop: "static",
    keyboard: false,
    content: {
      modalBody: "flights.itinerary_unavailable",
      resolveButtonText: "flights.back_to_main_page",
    },
  },
  "session-expiry-modal": {
    backdrop: "static",
    content: {
      modalHeader: "session_expire.modal_header",
      modalBody: "session_expire.modal_body",
      resolveButtonText: "Login",
    },
  },
  "user-ineligibility-modal": {
    backdrop: "static",
    content: {
      modalBody: "user_ineligibility.modal_body",
      resolveButtonText: "OK",
    },
  },
  "impersonated-confirmation-modal": {
    content: {
      modalIconCDNPath: "/common/icons/warning-icon.svg",
      modalHeader: "Warning",
      modalBody: "You are about to make an impersonated redemption as:",
      resolveButtonText: "Confirm",
      rejectButtonText: "Cancel",
    },
  },
  "impersonated-read-only-modal": {
    content: {
      modalIconCDNPath: "/common/icons/warning-icon.svg",
      modalHeader: "Warning",
      modalBody: "checkout.errors.impersonation_read_only_access",
      resolveButtonText: "Ok",
    },
  },
};
