import { StateSelectController } from './state-select.controller';
import { Bindings } from 'booking_app/types';

export class StateSelectComponent {
  bindings: any;
  controller: any;
  template: string;

  constructor() {
    this.bindings = {
      country: Bindings.ONE_WAY,
      value: Bindings.ONE_WAY,
      formName: Bindings.ONE_WAY,
      onUpdate: Bindings.EXPRESSION,
    };
    this.controller = StateSelectController;
    this.template = `
    <input-select
      value-formatter="$ctrl.displayOptions"
      selected-value="$ctrl.internalValue"
      placeholder="'State'"
      on-update="$ctrl.onUpdateInternalValue(value)"
      form-name="$ctrl.formName"
      options="$ctrl.options"
      search-enabled="true"
      on-refresh="$ctrl.fetchStatesList(searchText)"
      refresh-delay="200"
    ></input-select>
    `;
  }
}

angular.module("BookingApp").component("stateSelectComponent", new StateSelectComponent());
