angular.module("BookingApp")

.factory "UsersService", [

  "$q", "ApiDataService"
  ($q, ApiDataService) ->

    userDetailsDeferred = $q.defer()
    userDetails = undefined

    (resetUser) ->
      if resetUser
        userDetails = undefined
        userDetailsDeferred = $q.defer()

      formatUserDetails = (data) ->
        if !data || data == 'null'
          return { loggedIn: false, user: null }
        if data.birthday
          [data.year, data.month, data.day] = data.birthday.split('-').map (value) -> parseInt(value)
        return { loggedIn: true, user: data }

      if userDetails == undefined
        ApiDataService.get("user")
        .then (data) ->
          userDetailsDeferred.resolve(formatUserDetails(data))
      else
        userDetailsDeferred.resolve(userDetails)

      userDetailsDeferred.promise
]
