import { AppSettings } from "booking_app/values/app-settings";
import "booking_app/filters/parse-html-in-input";

export class RoomGuestFieldMobileController {

  static $inject = [
    "$translate",
    "$element",
    "AppSettings",
  ];

  // bindings
  roomCount: number;
  adultCount: number;
  childCount: number;
  childrenAges: number[];
  isShowingTotalGuest: boolean;
  onUpdateRoomCount: (obj: { count: number }) => void;
  onUpdateAdultCount: (obj: { count: number }) => void;
  onUpdateChildCount: (obj: { count: number }) => void;
  onUpdateChildrenAges: (obj: { childrenAges: number[] }) => void;

  // local state
  modalOpen: boolean = false;

  constructor(
    private $translate: any,
    private $element: any,
    private appSettings: AppSettings,
  ) { }

  summaryText(): string {
    let res = "";

    if (this.roomCount > 0) {
      res += `${this.roomCaption()} <span class="separator"> | </span> `;
    }

    if (this.isShowingTotalGuest) {
      res += `${this.totalGuestsCaption()}`;
    } else {
      res += `${this.adultCaption()}`;

      if (this.childCount > 0) {
        res += `, ${this.childCaption()}`;
      }
    }

    return res;
  }

  summaryTextAriaLabel(): string {
    return this.summaryText().replace("|", ",") + " selected dropdown menu";
  }

  beginSelection($event?: any) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.modalOpen = true;
    this.$element.find(".field-input").blur();
  }

  closeModal() {
    this.modalOpen = false;
  }

  updateRoomCount(count: number) {
    this.onUpdateRoomCount({ count });
  }

  updateAdultCount(count: number) {
    this.onUpdateAdultCount({ count });
  }

  updateChildCount(count: number) {
    this.onUpdateChildCount({ count });
  }

  updateChildrenAges(childrenAges: number[]) {
    this.onUpdateChildrenAges({
      childrenAges,
    });
  }

  labelOnlySummaryText(): boolean {
    return this.appSettings.roomGuestParameters.labelOnlySummaryText;
  }

  private adultCaption(): string {
    if (Number.isInteger(this.childCount)) {
      return this.showLabelAsAdult();
    } else {
      return this.showLabelAsGuest();
    }
  }

  private showLabelAsAdult(): string {
    if (this.adultCount === 1) {
      return this.$translate.instant("count.adult_one");
    } else {
      return this.$translate.instant("count.adult_other", { count: this.adultCount });
    }
  }

  private showLabelAsGuest(): string {
    if (this.adultCount === 1) {
      return this.$translate.instant("Single Guest Count");
    } else {
      return this.$translate.instant("Multiple Guest Count", { count: this.adultCount });
    }
  }

  private childCaption(): string {
    if (this.childCount === 1) {
      return this.$translate.instant("count.children_one");
    } else if (this.childCount > 1) {
      return this.$translate.instant("count.children_other", { count: this.childCount });
    }
  }

  private totalGuestsCaption(): string {
    const totalGuests = this.adultCount + this.childCount;

    return totalGuests === 1 ?
           this.$translate.instant("count.guest_per_room.one") :
           this.$translate.instant("count.guest_per_room.other", { count: totalGuests });
  }

  private roomCaption(): string {
    if (this.roomCount === 1) {
      return this.$translate.instant("Single Room Count");
    } else if (this.roomCount > 1) {
      return this.$translate.instant("Multiple Room Count", { count: this.roomCount });
    }
  }
}
