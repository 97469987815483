declare var angular : any;
declare var $ : any;

class MainRouting {

  static $inject = ["$routeProvider", "$injector", "KaligoConfig", "AppUser", "AppConfig"]

  private $routeProvider : any;
  private $injector : any;
  private KaligoConfig : any;
  private AppUser: any;
  private AppConfig: any;

  constructor(
    $routeProvider,
    $injector,
    KaligoConfig,
    AppUser,
    AppConfig) {
    this.$routeProvider = $routeProvider;
    this.$injector = $injector;
    this.KaligoConfig = KaligoConfig;
    this.AppUser = AppUser;
    this.AppConfig = AppConfig;

    this.setupRoutes();
  }

  private setupRoutes = function() {
    if (this.KaligoConfig.ignoreAngularRoute) return;

    if (this.KaligoConfig.APPLICATION_TYPE != 'whitelabel'){
      this.$routeProvider.when("/:partner", {
        controller: "SearchCtrl",
        templateUrl: function(params){ return '/html/hotels/search/' + params.partner },
        resolve: {
          resolved: this.$injector.get('CheckLandingPageExists'),
          parseUrl: this.$injector.get('ParseTrafficParams')
        }
      })
    }

    this.$routeProvider.when("/", {
      controller: "SearchCtrl",
      templateUrl: this.searchTemplate(),
      resolve: {
        parseUrl: this.$injector.get("ParseTrafficParams"),
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      }
    }).otherwise( {
      resolve: {
        resolved: this.$injector.get('CatchAll404Route')
      }
    })

    this.$routeProvider.when("/account/profile", {
      controller: "AccProfileCtrl",
      templateUrl: "/html/account/profile_new"
    }).when("/account/membership", {
      controller: "AccMembershipCtrl",
      templateUrl: "html/account/membership",
      resolve: {
        MembershipsPrefetch: this.$injector.get("FetchMemberships")
      }
    }).when("/account/booking", {
      controller: "AccManageBookingsCtrl",
      templateUrl: "html/account/manage_bookings",
      resolve: {
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    }).when("/account/referral", {
      controller: "AccReferralCtrl",
      templateUrl: "html/account/referral",
      resolve: {
        ReferralPrefetch: this.$injector.get("FetchReferrals")
      }
    })

    // basic default page, should be open to public
    this.$routeProvider.when("/company/about_us", {
      controller: "PageCtrl",
      templateUrl: "/html/company/about_us2"
    }).when("/company/contact_us", {
      controller: "PageCtrl",
      templateUrl: "/html/company/contact_us"
    }).when("/company/faqs", {
      controller: "PageCtrl",
      templateUrl: "/html/company/faqs2"
    }).when("/press/releases/:slug?", {
      controller: "PressCtrl",
      templateUrl: "/html/press/releases2"
    }).when("/press/media_kit", {
      controller: "PageCtrl",
      templateUrl: "/html/press/media_kit"
    }).when("/terms/privacy", {
      controller: "PageCtrl",
      templateUrl: "/html/terms/privacy_policy2"
    }).when("/terms/terms_condition", {
      controller: "PageCtrl",
      templateUrl: "/html/terms/terms_condition2"
    }).when("/purchases/:id", {
      controller: "PageCtrl"
    }).when("/users/password/:id", {
      controller: "PageCtrl"
    }).when("/users/sign_up", {
      controller: "PageCtrl"
    }).when("/commbank-video", {
      controller: "PageCtrl"
    })

    if (this.AppConfig.travel_types.indexOf("hotels") !== -1) {
      this.initHotelRoutes();
    }
    if (this.AppConfig.travel_types.indexOf("cars") !== -1) {
      this.initCarRoutes();
    }
    if (this.AppConfig.travel_types.indexOf("flights") !== -1) {
      this.initFlightRoutes();
    }
  }

  private searchTemplate = function() {
    if (this.KaligoConfig.APPLICATION_TYPE == 'whitelabel') {
       return '/html/whitelabel/' + this.KaligoConfig.WHITELABEL_PARTNER  + "/search"
     } else {
       return "/html/landing/kaligo2/search"
     }
  }

  private initHotelRoutes = function(): void {
    this.$routeProvider.when("/results/:destinationId", {
      controller: "ResultsCtrl",
      templateUrl: "/html/hotels/result",
      resolve: {
        HotelIndexPrefetch: this.$injector.get("FetchHotelDetails"),
        DestinationInfo: this.$injector.get("FetchDestinationInfo"),
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    }).when("/hotels/detail/:hotelId", {
      controller: "HotelsDetailCtrl",
      templateUrl: "/html/hotels/hotel_details",
      resolve: {
        HotelsDetailPrefetch: this.$injector.get("FetchSingleHotelDetail"),
        DestinationInfo: this.$injector.get("FetchDestinationInfo"),
        PayAnyoneRefresh: this.$injector.get("PayAnyoneRefresh"),
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    }).when("/hotels/checkout/:hotelId", {
      controller: "HotelsCheckoutCtrl",
      templateUrl: "/html/hotels/checkout",
      resolve: {
        HotelsCheckoutPrefetch: this.$injector.get("FetchSingleHotelDetail"),
        DestinationInfo: this.$injector.get("FetchDestinationInfo"),
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    }).when("/bookings/:bookingReference", {
      template: "<hotel-booking-detail-component></hotel-booking-detail-component>",
      resolve: {
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    });
  };

  private initCarRoutes = function(): void {
    this.$routeProvider.when("/cars/results", {
      controller: "CarsResultsCtrl",
      templateUrl: "html/cars/search_results",
      resolve: {
        resolved: this.$injector.get("ParseCarsRouteParams"),
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    }).when("/cars/details/:booking_key", {
      controller: "CarsDetailsCtrl",
      templateUrl: "/html/cars/product_details",
      resolve: {
        resolved: this.$injector.get("ParseCarsRouteParams"),
        PayAnyoneRefresh: this.$injector.get("PayAnyoneRefresh"),
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    }).when("/cars/checkout/:booking_key", {
      controller: "CarsCheckoutCtrl",
      templateUrl: "/html/cars/checkout",
      resolve: {
        resolved: this.$injector.get("ParseCarsRouteParams"),
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    }).when("/cars/bookings/:bookingReference", {
      template: "<car-booking-detail-component></car-booking-detail-component>",
      resolve: {
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    });
  };

  private initFlightRoutes = function(): void {
    this.$routeProvider.when("/flights/results", {
      template: "<flights-search-results-component></flights-search-results-component>",
      resolve: {
        resolved: this.$injector.get("ParseFlightsRouteParams"),
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    }).when("/flights/summary", {
      template: "<flights-summary-component> </flights-summary-component>",
      resolve: {
        resolved: this.$injector.get("ParseFlightsRouteParams"),
        PayAnyoneRefresh: this.$injector.get("PayAnyoneRefresh"),
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    }).when("/flights/checkout", {
      template: "<flights-checkout-component></flights-checkout-component>",
      resolve: {
        resolved: this.$injector.get("ParseFlightsRouteParams"),
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    }).when("/flights/bookings/:bookingReference", {
      template: "<flight-booking-detail-component></flight-booking-detail-component>",
      resolve: {
        RedirectSchemeUrl: this.$injector.get("RedirectSchemeUrl"),
      },
    });
  };

}

angular.module('BookingApp').config(MainRouting)
