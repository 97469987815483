angular.module('BookingApp')

.service "FormatService", ->

  @number = (num, locale, decimalPlace = 0) ->
    num = Number(num)
    return "" if typeof num != "number" || isNaN(num) || !locale
    if decimalPlace > 0
      num = num.toFixed(decimalPlace)
    else
      num = Math.ceil(num)
    parts = num.toString().split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, locale.numSeparator)
    if !decimalPlace || decimalPlace <= 0
      # display rounded value less than 1
      return if parts[0] == "0" && parts.length > 1 then "1" else parts[0]
    return parts.join(locale.comma)

  @summarize = (number, locale) ->
    return this.number(number, locale, 0) if number < 10000
    if number < 1000000
      # 999,999 to 10,000
      thousands = Math.ceil(number / 1000)
      return "#{thousands}K"
    else
      # >= 1,000,000
      millions = Math.floor(number / 1000000)
      number -= millions * 1000000
      tenthousands = Math.ceil(number / 10000)
      if tenthousands == 0
        return "#{millions}M"
      else
        return "#{millions}#{locale.comma}#{tenthousands}M"

  @adjustDecimals = (num, locale, decimalPlace = 0) ->
    num = Number(num)
    return "" if typeof num != "number" || isNaN(num) || !locale
    if decimalPlace > 0
      num = (Math.floor(num * (10 ** decimalPlace)) / (10 ** decimalPlace)).toFixed(decimalPlace)
    else
      num = Math.ceil(num)
    parts = num.toString().split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, locale.numSeparator)
    if !decimalPlace || decimalPlace <= 0
      # display rounded value less than 1
      return if parts[0] == "0" && parts.length > 1 then "1" else parts[0]
    return parts.join(locale.comma)

  return
