require('../services/points-partner-mapper.service')

angular.module('BookingApp')

.controller 'NavBarCtrl', [

  '$scope', '$rootScope', '$http', '$window',
  '$location', '$modal', '$timeout', '$translate',
  'CookieService', 'DropdownService', 'KaligoConfig',
  'LoginService', 'GlobalStateService', 'AppSettings',
  'PointsPartnerMapperService'

  ($scope, $rootScope, $http, $window,
  $location, $modal, $timeout, $translate,
  CookieService, DropdownService, KaligoConfig,
  LoginService, GlobalStateService, AppSettings,
  PointsPartnerMapperService) ->

    $scope.globalStateService = GlobalStateService

    $scope.isShowCountrySiteAndCurrencyPopUp = false
    $scope.activeTab = 'currency'

    $scope.tenant = AppSettings.tenant.toLowerCase()

    $rootScope.lockBody = ""

    if !$scope.globalState
      $scope.globalState = $rootScope.globalState

    $scope.globalState.displayMemberships = (
      !(KaligoConfig.WHITELABEL_PARTNER)
    )
    $scope.globalState.displayBonuses = (
      !(KaligoConfig.WHITELABEL_PARTNER)
    )
    $scope.globalState.displayReferrals = (
      !(KaligoConfig.WHITELABEL_PARTNER)
    )

    # Specify iconBarsColor on main search page based on logo color
    # Dark logo => black bars
    # Light logo => white bars
    $rootScope.iconBarsColor = () ->
      if $rootScope.navBarClass && $rootScope.navBarClass != "landing"
        { 'color': if $rootScope.landingPage.dlp_logo_type == "red" then "black" else "white" }

    # For Mobile
    $scope.showUserSettingsPopUp = () ->
      if $scope.isUserSettingsPopUp is true
        $scope.isUserSettingsPopUp = false
        $rootScope.overFlowStyle =
          overflow: "inherit"
          height: "auto"
      else
        screenHeight = document.getElementsByTagName("body")[0].clientHeight
        $scope.isUserSettingsPopUp = true
        $rootScope.overFlowStyle =
          overflow: "hidden"
          height: screenHeight - 52 + "px"

    $loginForm = $("#loginDropDown")

    DropdownService.register $loginForm
    $("#loginDropDown").on "click", (e) ->
      e.stopPropagation()
      DropdownService.toggleActive($loginForm)

    $("#signInForm").on "click", (e) ->
      e.stopPropagation()

    $scope.$watch('globalState.showNavMenu', (newValue, oldValue) ->
      $timeout( ->
        $rootScope.lockBody = if newValue is true then "lock-body" else ""
      , 10)
    )

    # For Visa, as there is no log in/log out, only checking if known user
    $scope.isKnownUser = () ->
      return $.cookie().known

    # For Mobile

    $scope.toggleSideNav = () ->
      return if $rootScope.isLoading
      $scope.globalState.showNavMenu = !$scope.globalState.showNavMenu

    $scope.delayedToggleNav = () ->
      return if $rootScope.isLoading

      $scope.globalState.displayNav = true
      $timeout( ->
        $scope.globalState.showNavMenu = !$scope.globalState.showNavMenu
        10
      )


    $scope.goToPage = (event, page) ->
      return if $rootScope.isLoading
      $scope.globalState.showNavMenu = false
      $location.url(page)
      GlobalStateService.initState()

    $scope.goToAccountPage = (e, page) ->
      return if $rootScope.isLoading
      if !$scope.userDetails.loggedIn
        $scope.globalState.openLogin()
        return

      $rootScope.activePanel = page
      $scope.globalState.showNavMenu = false
      return if !e.target

      profilePage = "/account/#{page}"

      if e.target.target == "" && !KaligoConfig.ignoreAngularRoute
        $location.url(profilePage)
      else
        $window.location.href = profilePage

    $scope.goToAdminPage = (e, page) ->
      return if $rootScope.isLoading
      if !$scope.userDetails.loggedIn
        $scope.globalState.openLogin()
        return

      $rootScope.activePanel = page
      $scope.globalState.showNavMenu = false
      return if !e.target

      if e.target.target == "" && !KaligoConfig.ignoreAngularRoute
        $location.url("/admin/users")
      else
        $window.location.href = "/admin/users"

    $scope.signOut = () ->
      return if $rootScope.isLoading
      $rootScope.isLoading = true
      LoginService.logout()

    $scope.isDefaultPage = () ->
      return $location.path() == '/'

    $scope.jalLoginRedirect = () ->
      window.location.href = '/whitelabel/jal/login'

    $scope.redirectTo = (page) ->
      window.location.href = page


    $scope.showVisaSubmenu = (event, menuClass) ->
      if $scope.globalState.visaMenuShowClass != menuClass
        $scope.globalState.visaMenuShowClass = menuClass
      else
        $scope.globalState.visaMenuShowClass = null

    #hacked for signup tracking of initial page: used for telling backend the id
    $rootScope.signUpLandingPageID = CookieService.getLandingPage() || 0

    $scope.getPointsBalance = () ->
      if $rootScope.userDetails.selectedPointsBalance > 0
        $rootScope.userDetails.selectedPointsBalance.toLocaleString()
      else
        $translate.instant('No')

    $scope.pointsPartnerMapper = (pointsPartnerName) ->
      PointsPartnerMapperService.pointsPartnerList[pointsPartnerName]
]
