import { CurrenciesService } from "booking_app/services/currencies.service";
import { AppSettings } from "booking_app/values/app-settings";
import { BonusProgramType, HotelItem } from "booking_app/types";
import { Hotel } from "booking_app/models";
import "../services/format-service";
import { GlobalStateService } from "./global-state.service";
import { PointsCashShareService } from "./points-cash-share.service";
import { PayWithPointsCashService } from "./pay-with-points-cash.service";

export class PayWithPointsCashCompNights {
  static $inject = [
    "$rootScope",
    "$translate",
    "AppSettings",
    "CurrenciesService",
    "FormatService",
    "GlobalStateService",
    "PayWithPointsCashService",
    "PointsCashShareService",
  ];

  private pointsPartner: any;
  private pointsCashShare: any;

  constructor(
    private $rootScope: any,
    private $translate: any,
    private appSettings: AppSettings,
    private currenciesService: CurrenciesService,
    private formatService: any,
    private globalStateService: GlobalStateService,
    private payWithPointsCashService: PayWithPointsCashService,
    private pointsCashShareService: PointsCashShareService,
  ) {
    this.pointsPartner = this.$rootScope.pointsPartner;
    this.pointsCashShare = this.pointsCashShareService.pointsCashShare;
  }

  public calculatePointsCashToPay(item: HotelItem): any {
    if (!item) {
      return;
    }

    const duration: number = this.$rootScope.duration * this.$rootScope.roomCount;
    if (item.max_points_payment && item.max_points_payment > 0) {
      item.points_payment = this.pointsToPay(
        item.base_points_payment,
        item.max_points_payment,
        item.bonus_program_type,
        item.complimentary_points_discount);
      item.points_payment_per_night = Math.ceil(item.points_payment / duration);
      item.original_points_payment = this.pointsToPay(item.base_points_payment);
      item.original_points_payment_per_night = Math.ceil(item.original_points_payment / duration);
      item.minPoint = this.payWithPointsCashService.minPoint(item.max_points_payment);
    }

    if (item.max_cash_payment && item.max_cash_payment > 0) {
      let calculatedCash: number;
      let calculatedBaseCash: number;
      if (this.payWithPointsCashService.useMinPoints(item)) {
        calculatedCash = this.payWithPointsCashService.cashToPayAfterMinPointsValue(item.max_cash_payment);
        calculatedBaseCash = this.payWithPointsCashService.cashToPayAfterMinPointsValue(item.base_cash_payment);
      } else {
        calculatedCash = this.cashToPay(
          item.base_cash_payment,
          item.max_cash_payment,
          item.bonus_program_type,
          item.complimentary_discount);
        calculatedBaseCash = this.cashToPay(item.base_cash_payment);
      }

      if (calculatedCash > 0) {
        item.cash_payment = this.payWithPointsCashService.formatCurrency(calculatedCash);
        item.original_cash_payment = this.payWithPointsCashService.formatCurrency(calculatedBaseCash);
        item.cash_payment_per_night = this.payWithPointsCashService.formatCurrency(item.cash_payment / duration);
        item.original_cash_payment_per_night = this.payWithPointsCashService.formatCurrency(item.original_cash_payment / duration);
      } else {
        item.original_cash_payment = 0;
        item.original_cash_payment_per_night = 0;
        item.cash_payment = 0;
        item.cash_payment_per_night = 0;
      }

      if (item.complimentary_discount) {
        item.converted_complimentary_discount = Math.floor(
          this.currenciesService.convertFromUsd(item.complimentary_discount),
        );
      }
    }

    return item;
  }

  public getPriceBeforeDiscount(item: HotelItem): string {
    const originalPointsPayment: number = this.pointsToPay(
      item.base_points_payment - item.complimentary_points_discount
    );
    let originalCashPayment: number;
    let priceAfterDiscount: string = "";

    if (this.payWithPointsCashService.useMinPoints(item)) {
      originalCashPayment = this.payWithPointsCashService.cashToPayAfterMinPointsValue(item.base_cash_payment);
    } else {
      originalCashPayment = this.cashToPay(item.base_cash_payment - item.complimentary_discount);
    }

    if (item && originalPointsPayment > 0) {
      const pointsPayment: number = this.formatService.number(originalPointsPayment, this.$rootScope.selectedLocale, 0);
      priceAfterDiscount = `${pointsPayment} ${this.$translate.instant(this.pointsPartner.shortCurrency)}`;
    }

    if (item && originalCashPayment > 0) {
      if (originalPointsPayment > 0) {
        priceAfterDiscount = `${priceAfterDiscount} +`;
      }
      const cashPayment: number = this.formatService.number(originalCashPayment, this.$rootScope.selectedLocale, 2);
      priceAfterDiscount = `${priceAfterDiscount} ${this.$rootScope.selectedCurrency.code} ${cashPayment}`;
    }

    return priceAfterDiscount;
  }

  private pointsToPay(basePoints: number, maxPoints: number = basePoints, bonusProgramType: string = "", compNightsPointsDiscount: number = 0): number {
    const travelType: string = this.globalStateService.travelType;
    if (!this.pointsPartner || !this.pointsPartner.pointsPaymentTiersBy(travelType)) {
      return this.payWithPointsCashService.toNearest(maxPoints);
    }
    if (!this.pointsPartner.minPoints) {
      return this.payWithPointsCashService.toNearest(
        this.calculatedPoints(basePoints, maxPoints, bonusProgramType, compNightsPointsDiscount),
      );
    }
    return this.payWithPointsCashService.toNearest(
      Math.max(
        this.calculatedPoints(basePoints, maxPoints, bonusProgramType, compNightsPointsDiscount),
        this.pointsPartner.minPoints,
      ),
    );
  }

  private cashToPay(baseCash: number, maxCash: number = baseCash, bonusProgramType: string = "", compNightsCashDiscount: number = 0): number {
    const travelType: string = this.globalStateService.travelType;
    if (this.pointsPartner && this.pointsPartner.cashPaymentTiersBy(travelType) && this.pointsCashShare) {
      maxCash = this.calculatedCash(baseCash, maxCash, bonusProgramType, compNightsCashDiscount);
    }
    return this.payWithPointsCashService.toTwoDecimalPlace(
      this.currenciesService.convertFromUsd(maxCash),
    );
  }

  private calculatedPoints(basePoints: number, maxPoints: number = basePoints, bonusProgramType: string = "", compNightsPointsDiscount: number = 0): number {
    const travelType: string = this.globalStateService.travelType;
    let calculatedPointsValue: number;
    switch (bonusProgramType) {
      case BonusProgramType.FIXED_ANY:
      case BonusProgramType.FIXED_SINGLE:
        calculatedPointsValue = ((basePoints - compNightsPointsDiscount) *
          this.pointsPartner.pointsPaymentTiersBy(travelType)[this.pointsCashShare.value]) -
          ((basePoints - compNightsPointsDiscount) - maxPoints);
        break;
      default:
        calculatedPointsValue = maxPoints *
          this.pointsPartner.pointsPaymentTiersBy(travelType)[this.pointsCashShare.value];
    }
    return calculatedPointsValue;
  }

  private calculatedCash(baseCash: number, maxCash: number = baseCash, bonusProgramType: string = "", compNightsCashDiscount: number = 0): number {
    const travelType: string = this.globalStateService.travelType;
    let calculatedCashValue: number;
    switch (bonusProgramType) {
      case BonusProgramType.FIXED_ANY:
      case BonusProgramType.FIXED_SINGLE:
        calculatedCashValue = ((baseCash - compNightsCashDiscount) *
          this.pointsPartner.cashPaymentTiersBy(travelType)[this.pointsCashShare.value]) -
          Math.max(baseCash - compNightsCashDiscount - maxCash, 0.0);
        break;
      default:
        calculatedCashValue = maxCash *
          this.pointsPartner.cashPaymentTiersBy(travelType)[this.pointsCashShare.value];
    }
    return calculatedCashValue;
  }

}

angular.module("BookingApp").service("PayWithPointsCashCompNights", PayWithPointsCashCompNights);
