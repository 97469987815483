import { MapboxService } from "booking_app/services/mapbox";

export class MapViewController {
  static $inject = [
    "MapboxService",
    "$timeout",
  ];
  // bindings
  private lng: string;
  private lat: string;

  constructor(
    private mapboxService: MapboxService,
    private $timeout: any,
  ) { }

  $onInit(): void {
    this.setupMap();
  }

  private setupMap(): void {
    this.$timeout(() => {
      this.mapboxService.resetAll();
      this.mapboxService.setupMapView("mapbox-search-map");
      this.mapboxService.setupSingleHotelMap([parseFloat(this.lng), parseFloat(this.lat)]);
    });
  }
}
