import { AppSettings } from "booking_app/values/app-settings";

export class CardErrorModal {
  static $inject = ["$scope", "$modalInstance", "AppSettings"];

  constructor(
    private $scope: any,
    private $modalInstance: any,
    private appSettings: AppSettings,
  ) {
    this.$scope.close = () => {
      this.$modalInstance.dismiss("close");
    };

    this.$scope.yes = () => {
      this.$modalInstance.close("yes");
    };

    this.$scope.tenant = this.appSettings.tenant.toLowerCase();

    this.populateKeys();
  }

  private populateKeys() {
    if (this.$modalInstance.titleKey) {
      this.$scope.title = this.$modalInstance.titleKey;
    } else {
      this.$scope.title = "Card error";
    }

    if (this.$modalInstance.errorMessageKey) {
      this.$scope.errorMessage = this.$modalInstance.errorMessageKey;
    } else {
      this.$scope.errorMessage = "The credentials could not be validated.";
    }
  }
}

angular.module("BookingApp").controller("CardErrorModal", CardErrorModal);
