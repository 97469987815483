import { FlightFeatures, FlightsItinerary, FlightsSegment, flightsSegmentId } from "../models";
import { TimeUtils } from "booking_app/utils";

declare var moment: any;

/*
  A flight item is an entity that the we store in the frontend to represent an itinerary or a part of it.
  This is used in the flights search results page, to represent user selections on different stages of selection.
  We would then use this object to get the correct information back from the FlightsItinerary object.
*/

export class FlightItem {

  static buildFromItinerary(itinerary: FlightsItinerary, segmentType = null) {
    let segmentsToUse = [];
    if (segmentType != null) {
      segmentsToUse = itinerary.segments.filter(segment => segment.segment_type === segmentType);
    } else {
      segmentsToUse = itinerary.segments;
    }
    return new FlightItem(
      segmentsToUse, itinerary.max_points_payment, itinerary.base_points_payment,
      itinerary.max_cash_payment, itinerary.base_cash_payment,
      itinerary.points_non_fixed_discount, itinerary.points_fixed_discount,
      itinerary.cash_non_fixed_discount, itinerary.cash_fixed_discount,
      itinerary.bonus_program_type, null,
      itinerary.rate, itinerary.points, itinerary.cash_non_fixed_discounts_by_tier,
      itinerary.points_non_fixed_discounts_by_tier,
      itinerary.bonus_programs,
      itinerary.bonus_tiers,
      itinerary.source,
      itinerary.features,
      itinerary.flight_fare_id,
    );
  }

  segments: FlightsSegment[];
  departureTimeInMinutes: number;
  arrivalTimeInMinutes: number;
  maxPriceInPoints: number;
  basePriceInPoints: number;
  maxPriceInCash: number;
  basePriceInCash: number;
  totalEarnPoints: number;
  pointsNonFixedDiscount: number;
  pointsFixedDiscount: number;
  cashNonFixedDiscount: number;
  cashFixedDiscount: number;
  bonusProgramType: string;
  id: string;
  rate: number;
  duration: number;
  cashNonFixedDiscountsByTier: number[];
  pointsNonFixedDiscountsByTier: number[];
  bonusPrograms: number[];
  bonusTiers: object[];
  source: string;
  features: FlightFeatures;
  flightFareId?: string;

  constructor(
    segments: FlightsSegment[],
    maxPriceInPoints: number,
    basePriceInPoints: number,
    maxPriceInCash: number,
    basePriceInCash: number,
    pointsNonFixedDiscount: number,
    pointsFixedDiscount: number,
    cashNonFixedDiscount: number,
    cashFixedDiscount: number,
    bonusProgramType: string,
    duration: number,
    rate: number, // admin view only
    totalEarnPoints: number,
    cashNonFixedDiscountsByTier: number[],
    pointsNonFixedDiscountsByTier: number[],
    bonusPrograms: number[],
    bonusTiers: object[],
    source: string,
    features: FlightFeatures,
    flightFareId?: string,
  ) {
    this.segments = segments;
    this.maxPriceInPoints = maxPriceInPoints;
    this.basePriceInPoints = basePriceInPoints;
    this.maxPriceInCash = maxPriceInCash;
    this.basePriceInCash = basePriceInCash;
    this.pointsNonFixedDiscount = pointsNonFixedDiscount;
    this.pointsFixedDiscount = pointsFixedDiscount;
    this.cashNonFixedDiscount = cashNonFixedDiscount;
    this.cashFixedDiscount = cashFixedDiscount;
    this.bonusProgramType = bonusProgramType;
    this.totalEarnPoints = totalEarnPoints;
    this.id = this.buildIdFromSegments(segments);
    this.departureTimeInMinutes = TimeUtils.timeToNumber(this.segments[0].departure_time);
    this.arrivalTimeInMinutes = TimeUtils.timeToNumber(this.segments[this.segments.length - 1].arrival_time);
    this.duration = this.getDurationAsMinutes(this.segments);
    this.rate = rate;
    this.cashNonFixedDiscountsByTier = cashNonFixedDiscountsByTier;
    this.pointsNonFixedDiscountsByTier = pointsNonFixedDiscountsByTier;
    this.bonusPrograms = bonusPrograms;
    this.bonusTiers =  bonusTiers;
    this.source = source;
    this.features = features;
    this.flightFareId = flightFareId;
  }

  private buildIdFromSegments(segments: FlightsSegment[]): string {
    return segments.reduce((identifier, segment) => {
      return `${identifier}${flightsSegmentId(segment)}`;
    }, "");
  }

  private getDurationAsMinutes(segments: FlightsSegment[]): number {
    let travelDuration = 0;
    let stopOverDuration = 0;

    for (let i = 0; i < segments.length; i++) {
      if (i < segments.length - 1) {
        const arrival = moment(segments[i].arrival_time);
        const departure = moment(segments[i + 1].departure_time);
        stopOverDuration += moment.utc(departure).diff(arrival);
      }

      travelDuration += Number(segments[i].duration);
    }

    const duration = moment.duration().add(travelDuration, "minutes").add(stopOverDuration, "milliseconds");
    return duration.asMinutes();
  }
}
