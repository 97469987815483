import { Bindings } from "booking_app/types";
import { RoomDetailsModalButtonController } from "./room-details-modal-button.controller";

export class RoomDetailsModalButtonComponent {
  bindings: any = {
    room: Bindings.ONE_WAY,
    buttonAriaLabel: Bindings.ONE_WAY,
  };
  transclude: any = {
    modalButtonContent: "modalButtonContent",
  };
  controller: any = RoomDetailsModalButtonController;
  templateUrl: string = "/html/components/room_details_modal_button";
}

angular.module("BookingApp").component("roomDetailsModalButton", new RoomDetailsModalButtonComponent());
