import { CurrentPage } from "booking_app/types";

export class PageDiscoveryService {

  static $inject = ["$location", "$rootScope"];

  constructor(
    private $location: any,
    private $rootScope: any,
  ) {}

  public listenRouteChange(currentPageCallback: (currentPage: CurrentPage) => void): void {
    this.$rootScope.$on("$routeChangeSuccess", (event, current, previous) => {
      currentPageCallback(this.currentPage());
    });
  }

  public isOnCurrentPage(pageName: CurrentPage): boolean {
    return this.currentPage() === pageName;
  }

  public isOnLandingPage(): boolean {
    return this.currentPage().indexOf("home") >= 0;
  }

  public currentPage(): CurrentPage {
    const path = this.$location.path();

    if (path === "/") {
      return CurrentPage.HOME;
    }
    else if (path === "/hotels") {
      return CurrentPage.HOTEL_HOME;
    }
    else if (path === "/cars") {
      return CurrentPage.CAR_HOME;
    }
    else if (path === "/flights") {
      return CurrentPage.FLIGHT_HOME;
    }
    else if (path === "/complimentary_nights") {
      return CurrentPage.COMPLIMENTARY_NIGHTS;
    }
    else if (path === "/account/booking") {
      return CurrentPage.MANAGE_BOOKING;
    }
    else if (path.includes("contact_us")) {
      return CurrentPage.CONTACT_US;
    }
    else if (path.includes("faq")) {
      return CurrentPage.FAQ;
    }
    else if (["conditions", "/terms/terms_condition"].some(pathItem => path.includes(pathItem))) {
      return CurrentPage.TERMS_CONDITION;
    }
    else if (["privacy-policy", "/terms/privacy"].some(pathItem => path.includes(pathItem))) {
      return CurrentPage.PRIVACY_POLICY;
    }
    // Only for Bounty
    else if (path.includes("privacy-collection")) {
      return CurrentPage.PRIVACY_COLLECTION;
    }
    // Cars
    else if (path === "/cars/results") {
      return CurrentPage.CAR_RESULT;
    }
    else if (path.includes("/cars/details")) {
      return CurrentPage.CAR_DETAIL;
    }
    else if (path.includes("/cars/checkout")) {
      return CurrentPage.CAR_CHECKOUT;
    }
    else if (path.includes("cars/bookings")) {
      return CurrentPage.CAR_SUCCESS;
    }
    // Flights
    else if (path === "/flights/results") {
      return CurrentPage.FLIGHT_RESULT;
    }
    else if (path === "/flights/summary") {
      return CurrentPage.FLIGHT_DETAIL;
    }
    else if (path === "/flights/checkout") {
      return CurrentPage.FLIGHT_CHECKOUT;
    }
    else if (path.includes("/flights/bookings")) {
      return CurrentPage.FLIGHT_SUCCESS;
    }
    // Hotels
    else if (path.includes("/results/")) {
      return CurrentPage.SEARCH_RESULT;
    }
    else if (path.includes("/hotels/detail")) {
      return CurrentPage.HOTEL_DETAIL;
    }
    else if (path.includes("/hotels/checkout")) {
      return CurrentPage.CHECKOUT;
    }
    else if (path.includes("/bookings/")) {
      return CurrentPage.CONFIRMATION;
    }
    else {
      return CurrentPage.HOME;
    }
  }

}

angular.module("BookingApp").service("PageDiscoveryService", PageDiscoveryService);
