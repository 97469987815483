declare var angular : any

class CommonTrackerService {

  static $inject = ["$analytics", "$rootScope", "FacebookPixelService"];


  constructor(
    private $analytics: any,
    private $rootScope: any,
    private FacebookPixelService: any,
    ) {}

  public hotelsSearch(searchForm) {
    // GA
    this.$analytics.eventTrack('searched_hotel', {
      category: 'destination',
      label: searchForm.destination?.label || this.$rootScope.destination,
    })

    this.$analytics.eventTrack('searched_hotel', {
      category: 'dayBeforeStay',
      label: searchForm.gaDaysBefore
    })
    this.$analytics.eventTrack('searched_hotel', {
      category: 'stay_period',
      label: searchForm.duration
    })

    // FB
    this.FacebookPixelService.eventTrack("Search", {
      content_category: "destination",
      search_string: searchForm.destination?.label || this.$rootScope.destination,
    })
    this.FacebookPixelService.eventTrack("SearchDayBefore", {
      content_category: "dayBeforeStay",
      search_string: searchForm.gaDaysBefore
    })
    this.FacebookPixelService.eventTrack("SearchStayPeriod", {
      content_category: "stay_period",
      search_string: searchForm.duration
    })
  }

  public hotelCheckoutInitiation(hotel) {
    this.FacebookPixelService.eventTrack('AddToCart', {})
  }

  public hotelDetailsImpression(hotel) {
    this.FacebookPixelService.eventTrack('ViewContent', {})
  }

  public cityTileImpression(_cityTile, _index) { null }
  public cityTileClick(_cityTile, _index) { null }
  public hotelsImpression(hotel) { null }
  public hotelClick(hotel) { null }
  public hotelBookingConfirmation(room, hotel, params) {
    // Facebook Pixel
    this.FacebookPixelService.eventTrack('Purchase', {
      value: room.bookingCost,
      currency: params.currencyCode,
    });
  }
  public filteringEvent(data) { null }
  public sortingEvent(data) { null }

}

angular.module("BookingApp").service("CommonTrackerService", CommonTrackerService)
