import { AppSettings } from "booking_app/values/app-settings";

export interface ModalParameters {
  readonly header: string;
  readonly body: string;
  readonly labelYes: string;
  readonly labelNo?: string;
}

export class CCValidationModals {
  static $inject = [
    "$rootScope",
    "$filter",
    "$modal",
    "AppSettings",
  ];

  private errorMessages = {
    INVALID_BIN_FOR_PARTNER: "wl.invalid_bin_for_partner",
    INVALID_BIN_FOR_PARTNER_MATCHING: "wl.invalid_bin_for_partner_matching",
    BONUS_VALIDATION: "hotels_checkout.bonus_validation.modal_body",
    INVALID_BIN_FOR_PARTNER_AIRLINE: "hotels_checkout.cc_validation.invalid_bin_for_airline",
    VALUE_CHANGED: "hotels_checkout.bonus_validation.value_changed",
  };

  constructor(
    private $rootScope: any,
    private $filter: any,
    private $modal: any,
    private appSettings: AppSettings,
  ) {}

  public displayInvalidUobBinForDLP(): void {
    this.displayOneOptionModal({
      header: this.$filter("translate")("Payment card validation"),
      body: this.$filter("translate")("Please use a valid UOB card for payment."),
      labelYes: this.$filter("translate")("Continue"),
    });
  }

  public displayBonusValidation(valueChanged: boolean): void {
    const key: string =  (valueChanged) ? "VALUE_CHANGED" : "BONUS_VALIDATION";
    this.displayOneOptionModal({
      header: this.$filter("translate")("hotels_checkout.bonus_validation.modal_header"),
      body: this.$filter("translate")(this.errorMessages[key],
        { currency: this.$rootScope.pointsPartner.longCurrency }),
      labelYes: this.$filter("translate")("Ok"),
    });
  }

  public displayPaymentCardValidation(invalidCard: string): void {
    this.displayOneOptionModal({
      header: this.$filter("translate")("Payment card validation"),
      body: this.$filter("translate")(`Please use a valid ${invalidCard} card for payment.`),
      labelYes: this.$filter("translate")("Continue"),
    });
  }

  public displayInvalidCreditCardValidation(status: string): void {
    this.displayOneOptionModal({
      header: this.$filter("translate")("Invalid credit card validation"),
      body: this.$filter("translate")(this.errorMessages[status],
        { programName: this.$rootScope.pointsPartner.programName }),
      labelYes: this.$filter("translate")("Ok"),
    });
  }

  public displayChangePartner(response: any, displayChangePartnerCallback?: (selection: string) => void) {
    let modalHeader: string;
    let modalBody: string;
    let modalYes: string;
    let modalNo: string;

    const newPartner = this.$rootScope.pointsPartners.findById(response.matching_partner);
    if (newPartner.category === "credit_card") {
      modalHeader = this.$filter("translate")("Change Points Partner");
      modalBody = this.$filter("translate")(this.errorMessages[response.status + "_MATCHING"], {
        programName: this.normalizeCobrandProgramName(this.$rootScope.pointsPartner),
        newProgramName: this.normalizeCobrandProgramName(newPartner),
        currency: newPartner.longCurrency,
      });
      modalYes = this.$filter("translate")("Proceed & earn", {
        currency: newPartner.longCurrency,
      });
      modalNo = this.$filter("translate")("Go back & change payment card");
    } else if (newPartner.category === "airline") {
      modalHeader = this.$filter("translate")("Points adjustment");
      modalBody = this.$filter("translate")(this.errorMessages.INVALID_BIN_FOR_PARTNER_AIRLINE);
      modalYes = this.$filter("translate")("Proceed");
      modalNo = this.$filter("translate")("Go back & change payment card");
    }
    this.displayChangePartnerModal(
      {
        header: modalHeader,
        body: modalBody,
        labelYes: modalYes,
        labelNo: modalNo,
      },
      newPartner,
      displayChangePartnerCallback,
    );
  }

  public openCardErrorModal(): void {
    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: "/html/modals/card_error_modal",
      controller: "CardErrorModal",
      windowClass: "card-error-modal",
      size: "md",
    });
    modalInstance.titleKey = "wl.visa.non-visa_card";
    const currentAppName = this.appSettings.tenant.toLowerCase();
    modalInstance.errorMessageKey = `wl.${currentAppName}.use_valid_${currentAppName}_card`;
  }

  public openExceedLimitedBinUsageModal(): void {
    const modalInstance = this.$modal.open({
      animation: true,
      templateUrl: "/html/modals/card_error_modal",
      controller: "CardErrorModal",
      windowClass: "card-error-modal",
      size: "md",
    });
    const currentAppName = this.appSettings.tenant.toLowerCase();
    modalInstance.errorMessageKey = `wl.${currentAppName}.exceed_limited_bin_usage`;
  }

  private displayOneOptionModal(modalParams: ModalParameters): void {
    const modalInstance = this.openModalInstance(modalParams, "One");

    modalInstance.result.then(() => {
      return this.$rootScope.globalState.activeModal = false;
    }, () => {
      return this.$rootScope.globalState.activeModal = false;
    });
  }

  private displayChangePartnerModal(
    modalParams: ModalParameters,
    newPartner: any,
    displayChangePartnerCallback?: (selectionInput: string) => void | undefined,
  ): void {
    const modalInstance = this.openModalInstance(modalParams, "Two");

    modalInstance.result.then((selection) => {
      this.$rootScope.globalState.activeModal = false;
      if (selection === "yes") {
        this.$rootScope.pointsPartner = newPartner;
      }
      if (typeof displayChangePartnerCallback === "function") {
        displayChangePartnerCallback(selection);
      }
    }, () => {
      this.$rootScope.globalState.activeModal = false;
    });
  }

  private openModalInstance(modalParams: ModalParameters, template: string): any {
    if (this.$rootScope.globalState.activeModal) {
      return;
    }
    this.$rootScope.globalState.activeModal = true;

    return this.$modal.open({
      animation: true,
      templateUrl: `/html/modals/${template.toLowerCase()}_option_modal`,
      controller: `${template}OptionModal`,
      size: "sm",
      backdrop: "static",
      windowClass: "option-modal",
      resolve: {
        modalHeader: () => {
          return modalParams.header;
        },
        modalBody: () => {
          return modalParams.body;
        },
        modalYes: () => {
          return modalParams.labelYes;
        },
        modalNo: () => {
          return modalParams.labelNo;
        },
      },
    });
  }

  private normalizeCobrandProgramName(pointsPartner: any): string {
    switch (pointsPartner.partner) {
      case "Virgin Atlantic Bank of America":
        return "Virgin Atlantic World Elite MasterCard Credit Card";
      case "Avianca Cobrand":
        return "Avianca LifeMiles Credit Card";
      default:
        return pointsPartner.programName;
    }
  }
}

angular.module("BookingApp").service("CCValidationModals", CCValidationModals);
