import { AdobeAnalyticsData, AdobeAnalyticsPageDetails, CurrentPage, Tenant } from "booking_app/types";
import { PageDiscoveryService } from "./page-discovery.service";
import { AppSettings } from "booking_app/values/app-settings";
import { UserAgentChecker } from "booking_app/utils/user-agent-checker";

export class AdobeAnalyticsService {

  static $inject = [
    "$rootScope",
    "$window",
    "PageDiscoveryService",
    "AppSettings",
    "UserAgentChecker",
  ];

  public adobeAnalyticsData: AdobeAnalyticsData;

  constructor(
    protected $rootScope: any,
    protected $window: any,
    protected pageDiscoveryService: PageDiscoveryService,
    protected appSettings: AppSettings,
    protected userAgentChecker: UserAgentChecker,
  ) {}

  public initiateAnalyticsOnRouteChange(): void {
    this.pageDiscoveryService.listenRouteChange(this.trackAnalytics.bind(this));
  }

  protected trackAnalytics(currentPage: CurrentPage, pageData?: any): void {
    const adobeAnalyticsPageDetailsObject: AdobeAnalyticsPageDetails = this.adobeAnalyticsData[currentPage];
    this.$window._satellite.cookie.set("sat_track", "true");
    this.$window._satellite.pageBottom();
    this.setPageDetails(adobeAnalyticsPageDetailsObject);
  }

  protected setPageDetails(adobeAnalyticsPageDetailsObject: AdobeAnalyticsPageDetails): void {
    this.$window.digitalData = {
      page: {
        pageName: adobeAnalyticsPageDetailsObject ? adobeAnalyticsPageDetailsObject.pageName : "",
        pageURL: window.location.href,
        server: window.location.origin,
      },
    };

    if (this.$window._satellite) {
      this.$window._satellite.track("page-view");
    }
  }

}

angular.module("BookingApp").service("AdobeAnalyticsService", AdobeAnalyticsService);
