import { AppSettings } from "booking_app/values/app-settings";
import { CheckoutValidationService } from "booking_app/services/checkout-validation/checkout-validation.service";
import { GlobalStateService } from "./global-state.service";
import { PointsCashShareService } from "./points-cash-share.service";

export class PointsAdjustmentService {

  static $inject = [
    "AppSettings",
    "CheckoutValidationService",
    "PointsCashShareService",
    "GlobalStateService",
    "$rootScope",
  ];

  public pointsNeeded: number;
  public minPoint: number;

  constructor(
    private appSettings: AppSettings,
    private checkoutValidationService: CheckoutValidationService,
    private pointsCashShareService: PointsCashShareService,
    private globalStateService: GlobalStateService,
    private $rootScope: any,
  ) {}

  showInsufficientPointBalance(pointsNeeded: number, minPoint: number = 0): boolean {
    this.pointsNeeded = pointsNeeded;
    this.minPoint = minPoint;

    if (this.showPointsModalChecker()) {
      this.checkoutValidationService.insufficientPointsChecker();
      return true;
    } else {
      return false;
    }
  }

  adjustMiles(): void {
    const travelType: string = this.globalStateService.travelType;

    const maxPaymentTier =
      this.$rootScope.pointsPartner.pointsPaymentTiersBy(travelType).length - 1;

    while (this.$rootScope.currentPointsBalance() < this.pointsNeeded) {
      if (this.pointsCashShareService.pointsCashShare.value === maxPaymentTier) {
        break;
      } else {
        this.pointsCashShareService.pointsCashShare.value += 1;
        this.$rootScope.$broadcast("pointsCashSliderUpdate");
      }
    }
  }

  hasEnoughPointsToCoverMinimum(): boolean {
    return this.$rootScope.currentPointsBalance() > this.minPoint;
  }

  private hasNotEnoughPoints(): boolean {
    // Checks for the current points
    return this.$rootScope.currentPointsBalance() < this.pointsNeeded;
  }

  private showPointsModalChecker(): boolean {
    return this.appSettings.checkoutSettings.insufficientPointsModalCheck && this.hasNotEnoughPoints();
  }
}

angular.module("BookingApp").service("PointsAdjustmentService", PointsAdjustmentService);
