declare var angular : any
declare var Rollbar: any

import { AppSettings } from "booking_app/values/app-settings";
import { ApiDataService } from "booking_app/services/api-data.service";

export class LoginService {

  static $inject = ["$q", "$http", "$window", "AppSettings", "ApiDataService"]

  private $q: any
  private $http: any
  private $window: any
  private appSettings: AppSettings;
  private apiDataService: ApiDataService;

  constructor($q, $http, $window, appSettings, apiDataService) {
    this.$q = $q
    this.$http = $http
    this.$window = $window
    this.appSettings = appSettings;
    this.apiDataService = apiDataService;
  }

  public call(endpoint: string, email: string, password: string, rememberMe: boolean, recaptchaResponse: string) {
    const params = { user: { email, password }, recaptcha_response: recaptchaResponse };
    if (rememberMe) { params['user']['remember_me'] = '1' }

    return this.apiDataService.post(endpoint, params, {}, false);
  }

  // Used for non-e2e integrated whitelabels where we simply
  // call devise's logout method to delete the user's session
  public logout() {
    return this.$http({ method: "DELETE", url: this.appSettings.logoutUrl })
      .then(
        (response) => this.$window.location.reload(),
        (error) => {
          Rollbar.info("Error on sign out: " + JSON.stringify(error.data));
          this.$window.location.reload();
        },
      )
  }

  // Used for e2e integrated whitelabels where we send
  // the user to GuardHouse's (GH) logout URL to purge his
  // GH session
  public redirectLogout() {
    this.$window.location.href = `${this.appSettings.logoutUrl}?reason=GH2030`;
  }
}

angular.module("BookingApp").service("LoginService", LoginService)
