angular.module("BookingApp")
.directive "scrollIf", [
  "$window"
  ($window) ->
    ($scope, element, attrs) ->
      $ = $window.$

      $scope.$watch attrs.scrollIf, (value) ->
        return unless value

        wrapper = $(element).parent()

        $(window).scrollTop(wrapper.offset().top - 150)
]
