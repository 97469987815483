import "../services/login";
import { LoginService } from "../services/login";

export class LoginCtrl {

  static $inject = [
    "$scope",
    "$rootScope",
    "$window",
    "$modal",
    "$timeout",
    "LoginService",
    "vcRecaptchaService",
    "AppConfig",
  ];

  public recaptchaWidgetId: number;

  constructor(
    private $scope: any,
    private $rootScope: any,
    private $window: any,
    private $modal: any,
    private $timeout: any,
    private loginService: LoginService,
    private vcRecaptchaService: any,
    private appConfig: any,
  ) {
    if (this.$scope.globalState.showRegisterForm) { $timeout(this.displayRegisterModal, 300) }
    if (this.$scope.globalState.showForgetPasswordForm) { $timeout(this.displayRegisterModal , 300) }
  }

  public loginUser() {
    // Validate Form
    if (this.$scope.signInForm.$invalid) {
      if (!this.isChinaUser() &&
          !!this.$scope.signInForm.$error.recaptcha &&
          Object.keys(this.$scope.signInForm.$error).length === 1) {
        this.vcRecaptchaService.execute(this.recaptchaWidgetId);
      }
      return;
    }

    if (this.$rootScope.globalState.processing) { return; }
    this.$rootScope.globalState.processing = true;
    this.$scope.loginFormErrors = null;
    const recaptchaResponse = this.isChinaUser() ? "" : this.vcRecaptchaService.getResponse(this.recaptchaWidgetId);
    this.loginService.call(
      this.$rootScope.globalState.loginEndpoint,
      this.$scope.userEmail,
      this.$scope.userPassword,
      this.$scope.userRememberMe,
      recaptchaResponse,
    ).then(
      (_result) => {
        this.$window.location.reload();
        this.$scope.showLoginForm = false;
      },
      (errors) => {
        this.$rootScope.globalState.processing = false;
        this.$scope.loginFormErrors = errors.errors.join(",");
        this.vcRecaptchaService.reload(this.recaptchaWidgetId);
      }
    );
  }

  public displayRegisterModal() {
    if (this.$rootScope.globalState.activeModal) { return; }
    this.$scope.globalState.showRegisterForm = true;
    this.$rootScope.globalState.activeModal = true;
    let modalInstance = this.$modal.open({
      animation: true,
      templateUrl: "/html/modals/register_modal2",
      controller: "SignUpPopupCtrl",
      controllerAs: "popup",
      windowClass: "register-modal",
      size: "sm"
    });
    modalInstance.result.then(
      (_selection) => {
        this.$scope.globalState.showRegisterForm = false;
        this.$rootScope.globalState.activeModal = false;
      },
      () => {
        this.$scope.globalState.showRegisterForm = false;
        this.$rootScope.globalState.activeModal = false;
      }
    );
  }

  public displayForgetPasswordModal () {
    if (this.$rootScope.globalState.activeModal) { return; }
    this.$scope.globalState.showForgetPasswordForm = true;
    this.$rootScope.globalState.activeModal = true;
    let modalInstance = this.$modal.open({
      animation: true,
      templateUrl: "/html/modals/forget_password_modal2",
      controller: "ForgetPasswordPopupCtrl",
      controllerAs: "popup",
      windowClass: "forget-password-modal",
      size: "sm"
    });
    modalInstance.result.then(
      (_selection) => {
        this.$scope.globalState.showForgetPasswordForm = false;
        this.$rootScope.globalState.activeModal = false;
      },
      () => {
        this.$scope.globalState.showForgetPasswordForm = false;
        this.$rootScope.globalState.activeModal = false;
      }
    );
  }

  public isChinaUser(): boolean {
    return this.appConfig.country_code === "CN";
  }
}

angular.module("BookingApp").controller("LoginCtrl", LoginCtrl);
