import { Bindings } from "booking_app/types";
import { DropdownLinksController } from "./dropdown-links.controller";

export class DropdownLinksComponent {
  controller: any = DropdownLinksController;
  bindings: any = {
    linkOption: Bindings.ONE_WAY,
  };
  template: string = `
    <a class="travel-link"
      tabindex="{{ $ctrl.linkOption.tabIndex }}"
      ng-class="{'active': $ctrl.isActiveTravelLink()}"
      ng-click="$ctrl.toggleDropdown()"
      click-outside="$ctrl.removeActiveClass()"
      role="button"
      href="#">
      <span translate="{{ $ctrl.linkOption.title }}"></span>
      <div class="static-links"
        ng-class="{'active': $ctrl.linkOption.active}"
        ng-click="$ctrl.menuToggleDropdown($event)">
        <menu-links
          ng-repeat="option in $ctrl.linkOption.links"
          link-option="option">
        </menu-links>
      </div>
      <span class="arrow" ng-if="$ctrl.linkOption.links"></span>
    </a>
  `;
}

angular.module("BookingApp").component("dropdownLinks", new DropdownLinksComponent());
