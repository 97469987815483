import { DatePickerSelectionState } from "../date-picker";
import { DateAdapterUtils } from "booking_app/utils";
import { AppSettings } from "booking_app/values/app-settings";
import { KeyboardKeyType } from "booking_app/types";

declare var moment: any;

interface DateUpdateValue {
  startDate: string;
  endDate: string;
}

export class DateRangeSingleFieldController {
  static $inject = ["$element",
    "$scope",
    "$rootScope",
    "$translate",
    "AppSettings"];

  // bindings
  startDate: string;
  endDate: string;
  minDaysToBookInAdvance: number = 0;
  minBookingDays: number = 0;
  onUpdate: (obj: { value: DateUpdateValue }) => void;
  locale: string;
  dateFormat: string;
  numberOfMonths: number;
  enableSelectionOfDates: boolean = false;

  // local state
  modalOpen: boolean = false;
  selectionState: DatePickerSelectionState;
  ariaDateString: string;
  keyboardEnabled: boolean = false;

  constructor(
    private $element: any,
    private $scope: any,
    private $rootScope: any,
    private $translate: any,
    private appSettings: AppSettings,
  ) {

  }

  updateStartDate(value: string) {
    this.onUpdate({
      value: {
        startDate: value,
        endDate: this.newEndDateValue(value),
      },
    });
    this.beginEndDateSelection();
    this.$scope.$apply();
  }

  updateEndDate(value: string) {
    this.onUpdate({
      value: {
        startDate: this.startDate,
        endDate: value,
      },
    });
    this.closeModal();
    this.$scope.$apply();
    this.blurField();
    this.ariaDateString = `${this.$translate.instant("label.stay_period")} ${this.formattedDate()}`;
  }

  beginStartDateSelection() {
    this.selectionState = "START";
    this.openModal();
  }

  beginEndDateSelection() {
    this.selectionState = "END";
  }

  focusInputField(): void {
    const inputField = this.findInputField();
    inputField.focus();
  }

  cancelSelection() {
    if (!this.$rootScope.isKeyboardUser) {
      this.closeModal();
    }
  }

  blurField() {
    return this.$element.find(".field-input")[0].blur();
  }

  formattedDate(): string {
    const dateFormat = this.dateFormat || "ll";
    const startDate = this.stringToMoment(this.startDate).format(dateFormat);
    const endDate = this.stringToMoment(this.endDate).format(dateFormat);
    return `${startDate} - ${endDate}`;
  }

  minDatepickerDate(): string {
    if (this.selectionState === "START") {
      return moment().add(this.minDaysToBookInAdvance, "d").format(DateAdapterUtils.V2_DATE_FORMAT);
    } else if (this.selectionState === "END") {
      let minBookingDays = this.minBookingDays;
      if (this.enableSelectionOfDates) {
        minBookingDays = 1;
      }

      return this.stringToMoment(this.startDate)
        .add(minBookingDays, "d")
        .format(DateAdapterUtils.V2_DATE_FORMAT);
    }
  }

  keypressEvent(event: any): void {
    // This if block is to support the scenario when user finish selecting date,
    // Then if they want to change their selections, they can press Enter/Space to reopen the datepicker modal
    // without navigating to the next/previous element and navigating back to open the datepicker modal
    if ((event.key === KeyboardKeyType.ENTER || event.key === KeyboardKeyType.SPACE)
        && !this.modalOpen) {
      event.preventDefault();
      event.stopPropagation();
      this.beginStartDateSelection();
      return;
    }

    const isTabPressed: boolean = event.key === KeyboardKeyType.TAB;
    const isArrowPressedWhenModalClose: boolean =
      (event.key === KeyboardKeyType.ARROWUP || event.key === KeyboardKeyType.ARROWDOWN)
      && !this.modalOpen;
    if (!isTabPressed && !isArrowPressedWhenModalClose) { return; }

    event.preventDefault();
    const parentElement: HTMLElement = this.$element.closest(".search-form-group")[0];
    this.blurField();
    let focusElement: any;
    if (event.shiftKey || event.key === KeyboardKeyType.ARROWUP) {
      const prevElement = parentElement.previousElementSibling;
      focusElement = prevElement.getElementsByClassName("ui-select-search")[0];
      // move to previous element
    } else {
      const nextElement = parentElement.nextElementSibling;
      focusElement = nextElement;
      // move to next element
    }

    if (focusElement) {
      event.preventDefault();
      event.stopPropagation();
      this.closeModal();
      $(focusElement).focus();
    }
  }

  clickedOutsideDatepicker(): void {
    this.closeModal();
  }

  private findInputField(): any {
    return this.$element.find(".field-input")[0];
  }

  private newEndDateValue(newStartValue: string): string {
    if (this.startDateGreaterThanEndDate(newStartValue) || this.appSettings.maxBookingDays) {
      return this.momentToString(this.addMinBookingDays(newStartValue));
    } else {
      return this.endDate;
    }
  }

  private startDateGreaterThanEndDate(newStartDate: string): boolean {
    return this.stringToMoment(newStartDate) > this.stringToMoment(this.endDate);
  }

  private stringToMoment(date: string): any {
    return moment(date, DateAdapterUtils.V2_DATE_FORMAT);
  }

  private momentToString(date: string): string {
    return moment(date).format(DateAdapterUtils.V2_DATE_FORMAT);
  }

  private addMinBookingDays(date: string): string {
    return this.stringToMoment(date).add(this.minBookingDays, "d");
  }

  private openModal() {
    this.modalOpen = true;
  }

  private closeModal() {
    this.modalOpen = false;
  }
}
