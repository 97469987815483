import { FlightType, FlightCabin } from "../../../types";
import { FlightAirportSearchResult } from "../../../models";
import { AppSettings } from "booking_app/values/app-settings";

declare var moment: any;

export class FlightsSearchFormState {
  static $inject = ["AppSettings"];

  fromAirport: FlightAirportSearchResult;
  toAirport: FlightAirportSearchResult;
  flightType: FlightType;
  adultCount: number;
  childCount: number;
  infantCount: number;
  cabin: FlightCabin;
  departureDate: string;
  returnDate: string;
  departuresOnly: boolean;

  static MIN_ADULT_COUNT = 1;
  static MAX_ADULT_COUNT = 9;
  static MIN_CHILD_COUNT = 0;
  static MAX_CHILD_COUNT = 9;
  static MIN_INFANT_COUNT = 0;
  static MAX_INFANT_COUNT = 9;

  constructor(
    private appSettings: AppSettings
  ) {
    this.initializeValues();
  }

  private initializeValues(): void {
    this.flightType = FlightType.RETURN;
    this.adultCount = 1;
    this.childCount = 0;
    this.infantCount = 0;
    this.departureDate = moment().add(this.appSettings.startCheckInDate, 'd').format('YYYY-MM-DD');
    this.returnDate = moment().add(this.appSettings.startCheckOutDate, 'd').format('YYYY-MM-DD');
    this.cabin = FlightCabin.ECONOMY;
    this.departuresOnly = this.appSettings.departuresOnly;
  }
}

angular.module("BookingApp").service("FlightsSearchFormState", FlightsSearchFormState)
