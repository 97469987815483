import { AppSettings } from "booking_app/values/app-settings";

export class CloudfrontImageService {
  static $inject = ["AppSettings"];

    constructor(
      private appSettings: AppSettings,
    ) { }

    processImage(params: { [key: string]: string }, imageUrl: string): string {
      return `${this.appSettings.cdnProcessingURL}/cdn-cgi/image/${this.processParams(params)}/${imageUrl}`;
    }

    processVisaImage(url: string, width: number): string {
      return `${url}?imwidth=${width}`;
    }

    private processParams(params: { [key: string]: string }): string {
      return Object.keys(params).map((k, v) => (`${k}=${params[k]}`) ).join(",");
    }
}

angular.module("BookingApp").service("CloudfrontImageService", CloudfrontImageService);
