require('../values/city-tile-settings')
require('../services/utils')
require('../services/tracker')
require('../filters/filter')
require('../components/flights/search-form/flights-search-form.state')
require('../components/flights/search-form/flights-search-form.service')

angular.module('BookingApp')
.controller 'QuickSearchCtrl', [

  '$scope', '$rootScope', '$location', '$window', '$httpParamSerializer',
  'CityTileSettings', 'AppSettings',
  'UtilsService', 'TrackerService', 'FlightsSearchFormState',
  'FlightsSearchFormService', 'SearchUrlService', "GlobalStateService",
  "CityTilesService"


  ($scope, $rootScope, $location, $window, $httpParamSerializer,
    CityTileSettings, AppSettings,
    UtilsService, TrackerService, FlightsSearchFormState,
    FlightsSearchFormService, SearchUrlService, GlobalStateService,
    CityTilesService) ->

    destinations = {
      'Phuket':       {
        'id': '4XcC'
        'name': 'Phuket, Thailand'
        'type': 'city'
        'lat': 7.8804479
        'lng': 98.3922504
      },
      'London':       {
        'id': 'jC3Y'
        'name': 'London, ENGLAND, United Kingdom'
        'type': 'city'
        'lat': 51.5082616708
        'lng': -0.128059387207
      },
      'Singapore':    {
        'id': 'RsBU'
        'name': 'Singapore, Singapore'
        'type': 'city'
        'lat': 1.2800945
        'lng': 103.8509491
      },
      'Dubai':        {
        'id': 'SfLK'
        'name': 'Dubai, United Arab Emirates'
        'type': 'city'
        'lat': 25.271139
        'lng': 55.307485
      },
      'Rome':         {
        'id': 'A6Dz'
        'name': 'Rome, Italy'
        'type': 'city'
        'lat': 41.895466
        'lng': 12.482324
      },
      'Los Angeles':  {
        'id': 'RsLO'
        'name': 'Los Angeles, CA, United States'
        'type': 'city'
        'lat': 34.052187
        'lng': -118.243425
      }
    }

    buildDestination = (id, name, lat, lng) ->
      return {
        'id': id
        'name': name
        'lat': lat
        'lng': lng
        'type': 'city'
      }

    initializeSearch = (destination, nights = 3) ->
      checkInDaysLater = AppSettings.destinationTileCheckinDaysLater
      checkInDate = moment().add(checkInDaysLater, 'd').format('MM/DD/YYYY')
      checkInDaysLater += parseInt(nights)
      checkOutDate = moment().add(checkInDaysLater, 'd').format('MM/DD/YYYY')
      param = {
        destination: destination.name
        destination_type: destination.type
        lat: destination.lat
        lng: destination.lng
        checkInDate: checkInDate
        checkOutDate: checkOutDate
        landingPage: $rootScope.landingPage.url
        guests: UtilsService.formatToGuests(1, 2)
        currency: $rootScope.selectedCurrency.code
      }

      urlQuery = $httpParamSerializer(param)
      url = "/results/#{destination.id}?#{urlQuery}"
      navigateToSearchPage(url)

    initializeFlightSearch = (fromAirport, toAirport) ->
      formState = FlightsSearchFormState
      formState.fromAirport = { value: fromAirport }
      formState.toAirport = { value: toAirport }

      url = FlightsSearchFormService.generateResultsUrl()
      navigateToSearchPage( url )

    initializeCarSearch =
      (pickupLocId, returnLocId, pickupLocName, returnLocName) ->
        if (pickupLocId)
          pickupLocation = {}
          pickupLocation.id = pickupLocId
          pickupLocation.value = pickupLocName if pickupLocName
          $rootScope.searchState.pickupLocation = pickupLocation
        if (returnLocId)
          returnLocation = {}
          returnLocation.id = returnLocId
          returnLocation.value = returnLocName if returnLocName
          $rootScope.searchState.returnLocation = returnLocation

        urlParams = SearchUrlService.generateCarsParams($rootScope.searchState)
        url = SearchUrlService.createCarsSearchUrl(urlParams)
        navigateToSearchPage( url )

    navigateToSearchPage = (url) ->
      if $rootScope.landingPage.quickSearchInNewTab
        $window.open(url, '_blank')
      else if AppSettings.reloadOnQuickSearch
        # this forces page reload for wls that can switch product types at landing page
        # similar to how it's done in HotelsSearchService.perform()
        $window.location.href = url
      else
        $location.url(url)
      return

    DEFAULT_NO_OF_NIGHTS = 3
    $scope.searchCity = (index, cityTile) ->
      TrackerService.cityTileClick(cityTile, index+1)
      initializeSearch(cityTile.search_params, DEFAULT_NO_OF_NIGHTS)

    $scope.runCustomSearch = (id, name, nights = 3, lat = 0, lng = 0) ->
      destinationId = id
      destinationName = name
      destination = buildDestination(destinationId, destinationName, lat, lng)
      initializeSearch(destination, nights)

    $scope.runCustomFlightSearch = (fromAirport, toAirport) ->
      initializeFlightSearch(fromAirport, toAirport)

    $scope.runCustomCarSearch =
      (pickupLocId, returnLocId, pickupLocName, returnLocName) ->
        initializeCarSearch(
          pickupLocId,
          returnLocId,
          pickupLocName,
          returnLocName
        )

    # DEPRECATED: For old design
    $scope.runQuickSearch = (quickSearchCountry) ->
      destination = destinations[quickSearchCountry]
      return if !destination
      initializeSearch(destination)

    $scope.settings = CityTileSettings

    $scope.globalStateService = GlobalStateService

    $scope.initCityTiles = () ->
      $scope.cityTiles = []
      $rootScope.landingPage.loading = true

      CityTilesService.fetchCityTile().then (res) ->
        $rootScope.landingPage.loading = false
        $scope.cityTiles = res

    $scope.tenant = AppSettings.tenant.toLowerCase()

    $scope.searchState = $rootScope.searchState

    if !AppSettings.newLandingPageTiles
      $scope.initCityTiles()

]
