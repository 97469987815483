import { ViewportSizes } from "booking_app/types/viewport-sizes";
// TODO: implement viewport sizes to force close the dropdown popup when it reaches a certain viewport
interface DropdownOption {
  display: string;
  value: string;
}

export class DropdownFieldPopupController {
  static $inject = ["$translate", "$rootScope"];

  // bindings
  options: any;
  currentValue: any;
  onUpdate: (obj: { value: any }) => void;
  valueFormatter: (selected: string) => string;

  modalOpen: boolean = false;
  formattedOptions: DropdownOption[];
  watchWidthEvent: any;

  constructor(
    private $translate: any,
    private $rootScope: any,
  ) {  }

  $onInit(): void {
    this.formattedOptions = this.rebuildOptions();
  }

  public beginSelection(): void {
    this.modalOpen = true;
    this.setupModalWidthListener();
  }

  public closeModal(): void {
    this.modalOpen = false;
    if (this.watchWidthEvent) {
      this.watchWidthEvent();
    }
  }

  public updateModelValue(dropdownValue: any): void {
    this.onUpdate({ value: dropdownValue });
    this.modalOpen = false;
  }

  public displayOption(selected: any): string {
    if (this.valueFormatter) {
      return this.$translate.instant(this.valueFormatter(selected));
    } else {
      return this.$translate.instant(selected);
    }
  }

  public selectedValue(): string {
    const selectedOption = this.formattedOptions.find(obj => {
      return obj.value === this.currentValue;
    });
    return this.displayOption(selectedOption.display);
  }

  private rebuildOptions(): DropdownOption[] {
    return this.options.map((option) => {
      if (typeof option === "string") {
        return { display: option, value: option};
      } else {
        return option;
      }
    });
  }

  private setupModalWidthListener(): void {
    this.watchWidthEvent = this.$rootScope.$watch("globalState.browserWidth", () => {
      if (this.$rootScope.globalState.browserWidth >= ViewportSizes.XS_MAX + 1) {
        this.closeModal();
      }
    });
  }
}
