declare var angular : any;

class PartnerTypeIconController {
  static $inject = ['$rootScope'];

  private partnerType: any;

  constructor($rootScope) {
    this.partnerType = $rootScope.pointsPartner.category
  }

  public iconClass() {
    if (this.partnerType == 'airline') { return 'fa-plane' }
    if (this.partnerType == 'credit_card') { return 'fa-credit-card' }
    return 'fa-car';
  }
};

export class PartnerTypeIconComponent {

  public controller : any;
  public controllerAs : any;
  public template : any;

  constructor() {
    this.controller = PartnerTypeIconController;
    this.template = "<i class='fa {{ $ctrl.iconClass() }}'></i>"
  }

}

angular.module('BookingApp').component('partnerTypeIcon', new PartnerTypeIconComponent())
