import { HotelListItemDistanceController } from "./distance.controller";
import { Bindings } from "booking_app/types";

export class HotelListItemDistanceComponent {
  bindings: any = {
    destination: Bindings.ONE_WAY,
    destinationType: Bindings.ONE_WAY,
    distance: Bindings.ONE_WAY,
    distanceLabel: Bindings.ONE_WAY,
  };
  controller: any = HotelListItemDistanceController;
  template: string = `
    <div class="hotel-distance" ng-if="$ctrl.showHotelDistance()">
      <em class="fa fa-road"></em>
      <span
        translate="{{ $ctrl.hotelDistanceLabel() }}"
        translate-values="{distance: '{{ $ctrl.hotelDistance() }}', poi: '{{ $ctrl.poiName() }}'}"
      ></span>
    </div>
  `;
}

angular.module("BookingApp").component("hotelListItemDistance", new HotelListItemDistanceComponent());
