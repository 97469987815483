import { SessionTimerConfig } from "booking_app/types";
import { ApiDataService } from "./api-data.service";
import { LoginService } from "./login";

class SessionTimerService {

  static $inject = ["$modal", "LoginService", "ApiDataService"];

  private timer: ReturnType<typeof setTimeout> = null;

  constructor(private $modal: any, private loginService: LoginService, private apiDataService: ApiDataService) {}

  public startTimer(config: SessionTimerConfig): void {
    clearTimeout(this.timer);
    const countDownStartingPointInSeconds: number = (config.sessionTime - config.countdownTime ) / 1000;
    this.timer = setTimeout(() => {
      this.pollUserSessionTimer().then((response: number) => {
        if (response <= countDownStartingPointInSeconds) {
          const modalInstance = this.$modal.open({
            animation: true,
            templateUrl: "/html/modals/session_timer_modal",
            controller: "SessionTimerModal",
            windowClass: "session-timer-modal",
            size: "md",
            controllerAs: "ctrl",
            resolve: {
              countdownTime: () => {
                return config.countdownTime;
              },
            },
          });
          modalInstance.result.then(
            () => {
              this.resetUserSession();
              this.startTimer(config);
            },
            () => {
              clearTimeout(this.timer);
              this.loginService.logout();
            },
          );
        } else {
          this.startTimer(config);
        }
      });
    }, config.pollingInterval);
  }

  private pollUserSessionTimer(): ng.IPromise<{}> {
    return this.apiDataService.get("poll_session_timeout", false);
  }

  private resetUserSession(): ng.IPromise<{}> {
    return this.apiDataService.post("reset_last_request_time", {}, {}, false);
  }

}

angular.module("BookingApp").service("SessionTimerService", SessionTimerService);
