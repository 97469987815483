export enum CurrentPage {
  HOME                  = "home",
  HOTEL_HOME            = "hotel-home",
  CAR_HOME              = "car-home",
  FLIGHT_HOME           = "flight-home",
  COMPLIMENTARY_NIGHTS  = "complimentary-nights",
  SEARCH_RESULT         = "search-result",
  HOTEL_DETAIL          = "hotel-detail",
  CHECKOUT              = "checkout",
  CONFIRMATION          = "confirmation",
  CAR_RESULT            = "car-result",
  CAR_DETAIL            = "car-detail",
  CAR_CHECKOUT          = "car-checkout",
  CAR_SUCCESS           = "car-success",
  FLIGHT_RESULT         = "flight-result",
  FLIGHT_DETAIL         = "flight-detail",
  FLIGHT_CHECKOUT       = "flight-checkout",
  FLIGHT_SUCCESS        = "flight-success",
  MANAGE_BOOKING        = "manage-booking",
  CONTACT_US            = "contact-us",
  FAQ                   = "faq",
  TERMS_CONDITION       = "terms-condition",
  PRIVACY_POLICY        = "privacy-policy",
  PRIVACY_COLLECTION    = "privacy-collection",
  STATIC_CONTENT        = "static-content",
}
