declare var moment: any;

import { Booking, CancellationPolicyClause } from "booking_app/models";
import { BookingDetailUtils } from "booking_app/utils";
import { AppSettings } from "booking_app/values/app-settings";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { CurrentPage } from "booking_app/types";
import { RedirectService } from "booking_app/services/redirect.service";

export class HotelBookingDetailController {
  static $inject = [
    "BookingDetailUtils",
    "AppSettings",
    "GlobalStateService",
    "RedirectService",
    "$rootScope",
    "$translate",
    "$filter",
    "$window",
  ];

  cancellationFeeText: string;

  // variables moved over from $scope
  private displayablePolicyClauses: CancellationPolicyClause[];

  constructor(
    private bookingDetailUtils: BookingDetailUtils,
    private appSettings: AppSettings,
    private globalStateService: GlobalStateService,
    private redirectService: RedirectService,
    private $rootScope: any,
    private $translate: any,
    private $filter: any,
    private $window: any,
  ) {
    this.globalStateService.currentPage = CurrentPage.CONFIRMATION;
    this.cancellationFeeText = this.appSettings.cancellationFeeText.success;
  }

  public $onInit(): void {
    this.redirectService.unauthorizedConfirmationPage();
    this.bookingDetailUtils.init();
  }

  public getBooking(): Booking {
    return this.bookingDetailUtils.getBooking();
  }

  public getPointsPartner(): any {
    return this.bookingDetailUtils.getPointsPartner();
  }

  public paidAnyCash(): boolean {
    return this.bookingDetailUtils.paidAnyCash();
  }

  public paidAnyPoints(): boolean {
    return this.bookingDetailUtils.paidAnyPoints();
  }

  public paidInCashOnly(): boolean {
    return this.bookingDetailUtils.paidInCashOnly();
  }

  public paidInPointsOnly(): boolean {
    return this.bookingDetailUtils.paidInPointsOnly();
  }

  public activeBooking(): boolean {
    return this.bookingDetailUtils.activeBooking();
  }

  public verificationRequiredBooking(): boolean {
    return this.bookingDetailUtils.verificationRequiredBooking();
  }

  public getBookingGuestName(): string {
    return this.bookingDetailUtils.getBookingGuestName();
  }

  public pdfDownloadLink(): string {
    return this.bookingDetailUtils.pdfDownloadLink();
  }

  public accessAndCancelText(): boolean {
    return this.appSettings.displayPurchaseAccessAndCancelText;
  }
  // Hotel specific code

  public verificationTimeLeft(): string {
    return this.bookingDetailUtils.verificationTimeLeft();
  }

  public navigateToLandingPage(): void {
    // Below product type is the parameter we recieve from backend.
    // Not to be confused with the format complementary-nights which we use it front-end.
    const productType: string = this.getBooking().product_type;
    const url: string = productType === "complimentary_nights" ? productType : "/";
    this.$window.location.href = url;
  }

  public getTenant(): string {
    return this.appSettings.tenant.toLowerCase();
  }

  public alwaysShowCancellationFee(): boolean {
    return this.getBooking() && this.getBooking().cancellation_fee_percentage > 0 && this.appSettings.alwaysShowCancellationFee;
  }

  formattedCheckinDate(format: string = "LL"): string {
    if (!this.getBooking()) {
      return "";
    }
    return moment(this.getBooking().checkInDate, "YYYY-MM-DD").format(format);
  }

  formattedCheckoutDate(format: string = "LL"): string {
    if (!this.getBooking()) {
      return "";
    }
    return moment(this.getBooking().checkOutDate, "YYYY-MM-DD").format(format);
  }

  formattedChildrenInfo(): string {
    if (!this.getBooking()) {
      return "";
    }

    const { children_count_info, children_ages } = this.getBooking();
    return `${children_count_info} (${children_ages.join(", ")})`;
  }

  roomsSummary(): string {
    if (!this.getBooking()) {
      return "";
    }

    const { numOfRooms } = this.getBooking();
    return this.caption("room", numOfRooms);
  }

  guestsSummary(): string {
    if (!this.getBooking()) {
      return "";
    }

    const { numOfGuests, children_count_info } = this.getBooking();
    return [
      this.caption("adult", numOfGuests),
      this.caption("children", children_count_info),
    ].filter(item => item).join(", ");
  }

  roomsGuestsSummary(): string {
    if (!this.getBooking()) {
      return "";
    }

    return [
      this.roomsSummary(),
      this.guestsSummary(),
    ].filter(item => item).join(", ");
  }

  formatLocaleNumber(value: number, decimalPlace: number) {
    return this.$filter("localeNumberFmt")(value, decimalPlace);
  }

  // Filter out policies that have penalty 100%
  getDisplayablePolicyClauses(): CancellationPolicyClause[] {
    if (this.displayablePolicyClauses) { return this.displayablePolicyClauses; }
    const penalties = this.getBooking()?.cancellation_policy?.penalties;
    this.displayablePolicyClauses = penalties?.length > 0 ? penalties.filter(p => p.percentage < 100) : [];
  }

  hasPointsDiscount(): boolean {
    return this.getBooking().points_discount > 0;
  }

  pointsBeforeDiscount(): string {
    return this.formatLocaleNumber(this.getBooking().paid_in_points + this.getBooking().points_discount, 0);
  }

  hasCashDiscount(): boolean {
    return this.getBooking().cash_discount > 0;
  }

  cashBeforeDiscount(): string {
    return `${this.getBooking().converted_amount + this.getBooking().cash_discount}`;
  }

  private caption(type: "room" | "adult" | "children", count: number) {
    if (count === 1) {
      if (this.hasNoSingularTranslations()) {
        return this.$translate.instant(`count.${type}_one`);
      }
      return this.$translate.instant(`count.${type}.one`);
    } else if (count > 1) {
      return this.$translate.instant(`count.${type}.other`, { count });
    } else {
      return null;
    }
  }

  private hasNoSingularTranslations(): boolean {
    const languageCodesWithNoSingularForms = ["ko", "ja", "zh-CN"];
    return languageCodesWithNoSingularForms.includes(this.$rootScope.selectedLocale.lang_code);
  }
}
