import { PaymentDetailFormData } from "./payment-detail-form-data";
import { CreditCardFormData } from "./credit-card";
import { AddressFormData } from "./address-form";
import { AngularUtils } from "booking_app/utils";
import { CheckoutFormService } from "booking_app/services/checkout";
import { PaymentMethodService } from "booking_app/services/payment-method.service";

export class PaymentDetailFormController {
  static $inject = ["$rootScope", "$scope", "CheckoutFormService", "PaymentMethodService"];

  // bindings
  data: PaymentDetailFormData;
  onUpdate: (obj: { data: PaymentDetailFormData }) => void;
  formName: string;
  parentForm: any;
  submitted: boolean;

  internalData: PaymentDetailFormData;

  constructor(
    private $rootScope: any,
    private $scope: any,
    private checkoutFormService: CheckoutFormService,
    private paymentMethodService: PaymentMethodService,
  ) { }

  $onInit() {
    this.checkoutFormService.init(this.$scope);
    this.$scope.updateSelectedCreditCard = (cards) => {
      this.paymentMethodService.selectedCreditCard = this.$scope.getSelectedCreditCard(cards);
    };
    this.setupAdyenFormUpdateListener();
    this.setupPaymentChannelListener();
    this.setupCurrencyChangeListener();
    this.$scope.filterSavedCards();
  }

  $onChanges(changesObj): void {
    if (AngularUtils.hasBindingChanged(changesObj.data)) {
      this.internalData = angular.copy(this.data);
    }
  }

  onUpdateCreditCardData(data: CreditCardFormData) {
    this.internalData.creditCardData = data;
    this.onUpdateInternalData();
  }

  onUpdateAddressData(data: AddressFormData) {
    this.internalData.addressData = data;
    this.onUpdateInternalData();
  }

  private setupAdyenFormUpdateListener() {
    this.$scope.$on("adyen-form-update", (_event, stateData, name) => {
      if (name) {
        this.internalData.creditCardData.cardholderName = name;
      }
      if (stateData) {
        this.internalData.browserInfo = stateData.browserInfo;
        this.internalData.creditCardData.encryptedCardNumber = stateData.paymentMethod.encryptedCardNumber;
        this.internalData.creditCardData.encryptedExpiryMonth = stateData.paymentMethod.encryptedExpiryMonth;
        this.internalData.creditCardData.encryptedExpiryYear = stateData.paymentMethod.encryptedExpiryYear;
        this.internalData.creditCardData.encryptedSecurityCode = stateData.paymentMethod.encryptedSecurityCode;
      }
      this.onUpdateInternalData();
    });
  }

  private setupPaymentChannelListener() {
    this.$scope.$watch("bookingDetails.paymentChannel", (newPaymentChannel) => {
      this.internalData.creditCardData.paymentChannel = newPaymentChannel;
      this.onUpdateInternalData();
    });
  }

  private setupCurrencyChangeListener() {
    this.$rootScope.$watch("selectedCurrency.code", () => {
      this.$scope.filterSavedCards();
    });
  }

  private onUpdateInternalData() {
    this.onUpdate({ data: this.internalData });
  }
}
