import { AppSettings } from "booking_app/values/app-settings";
import { ViewportSizes } from "booking_app/types/viewport-sizes";

export class ScrollService {
  static $inject = [
    "$rootScope",
    "$timeout",
    "AppSettings",
  ];

  processingScroll: boolean;

  constructor(
    private $rootScope: any,
    private $timeout: any,
    private appSettings: AppSettings,
  ) {
  }

  visitedHotel(delay: number = 100): void {
    const hotelId: string = this.$rootScope.routeParams.hotelId;
    this.$timeout(() => {
      const hotelElement: HTMLElement =
        document.getElementById(`hotel-id-${hotelId}`);
      if (hotelElement) {
        const elementRect = hotelElement.getBoundingClientRect();
        const absoluteElementPosition = elementRect.top +
          window.pageYOffset - this.getOffSetValue();
        window.scrollTo(0, absoluteElementPosition);
        this.processingScroll = false;
        const focusableElements = [].filter.call(hotelElement.getElementsByTagName("a"), el => el.className.indexOf("show-on-map") <= 0);
        if (focusableElements.length > 0) {
          focusableElements[0].focus();
        }
        this.setKeyboardUser();
      } else {
        this.processingScroll = false;
      }
    }, delay);
  }

  private getOffSetValue(): number {
    if (this.$rootScope.globalState.browserWidth < ViewportSizes.SM_MAX) {
      const offSet: { desktop: number, mobile?: number} =
        this.appSettings.resultsPageScrollOffset;
      return offSet.mobile || offSet.desktop;
    } else {
      return this.appSettings.resultsPageScrollOffset.desktop;
    }
  }

  private setKeyboardUser(): void {
    const htmlElement = document.getElementsByTagName("html")[0];
    if (!htmlElement.classList.contains("is-keyboard-user")) {
      htmlElement.classList.add("is-keyboard-user");
      if (!this.$rootScope.isKeyboardUser) {
        this.$rootScope.isKeyboardUser = true;
      }
    }
  }
}

angular.module("BookingApp").service("ScrollService", ScrollService);
