import { CreditCardDetailController } from "./credit-card-detail.controller";
import { Bindings } from "booking_app/types";

export class CreditCardDetailComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      data: Bindings.ONE_WAY,
      onUpdate: Bindings.EXPRESSION,
      parentForm: Bindings.ONE_WAY,
      formName: Bindings.ONE_WAY,
      submitted: Bindings.ONE_WAY,
    };
    this.controller = CreditCardDetailController;
    this.templateUrl = '/html/components/payment_detail_form_credit_card_detail';
  }
}

angular.module("BookingApp").component("creditCardDetailComponent", new CreditCardDetailComponent());
