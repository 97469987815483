export class MapDirectionPopupController {
  static $inject = ["$rootScope"];

  private pickUpState: string;
  private address: string;

  constructor(private $rootScope: any) {
  }

  headerTranslation(): string {
    if (this.pickUpState === "destination") {
      return "txt.car_pickup_location";
    } else {
      return "txt.your_location";
    }
  }

  locationValue(): string {
    if (this.pickUpState === "pickup") {
      return this.$rootScope.searchState.pickupLocation.value;
    } else {
      return "";
    }
  }

  shouldShowAddressLine(): boolean {
    return this.pickUpState !== "pickup";
  }
}
