import { Bindings } from "booking_app/types";
import { CheckoutValidationController } from "./checkout-validation.controller";

export class CheckoutValidationComponent {
  bindings: any = {
    selectedItem: Bindings.ONE_WAY,
    redirectAction: Bindings.EXPRESSION,
    buttonLabel: Bindings.ONE_WAY,
    notAllowedToBook: Bindings.ONE_WAY,
    isPointChecking: Bindings.ONE_WAY,
  };
  controller: any = CheckoutValidationController;
  template: string = `
    <button
      class="btn-action uppercase checkout-button"
      ng-click="$ctrl.goToCheckoutPage()"
      ng-disabled="$ctrl.notAllowedToBook"
      translate="{{$ctrl.buttonLabel}}">
    </button>
  `;

}

angular.module("BookingApp").component("checkoutValidation", new CheckoutValidationComponent());
