import { GoogleAnalyticsService } from "booking_app/services/google-analytics";

declare var angular: any;

export class GoogleAnalyticsController {
  static $inject = ["GoogleAnalyticsService"];

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
  }

  public $onInit(): void {
    if (!this.googleAnalyticsService) {
      return;
    }
    this.googleAnalyticsService.commonGa();
  }
}
