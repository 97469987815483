import { GlobalStateService } from "booking_app/services/global-state.service";
import { AppSettings } from "booking_app/values/app-settings";

export class TravelBreakdownController {

  static $inject = ["GlobalStateService", "AppSettings"];

  // bindings
  roomCount: number = 0;
  duration: number = 0;

  constructor(
    private globalStateService: GlobalStateService,
    private appSettings: AppSettings,
  ) {}
}
