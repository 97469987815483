import { Bindings } from "booking_app/types";
import { FormPopupMobileController } from "./form-popup-mobile.controller";

export class FormPopupMobileComponent {
  bindings: any = {
    maxModalWidth: Bindings.ONE_WAY,
    closeModal: Bindings.EXPRESSION,
  };

  transclude: any = {
    titleContent: "?titleContent",
    bodyContent: "?bodyContent",
  };
  controller: any = FormPopupMobileController;
  template: string = `
    <div class="title-content" ng-transclude="titleContent" id="mobile-popup-header"></div>
    <div class="body-content" ng-transclude="bodyContent"></div>
  `;
}

angular.module("BookingApp").component("formPopupMobile", new FormPopupMobileComponent());
