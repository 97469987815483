import { DatePickerSelectionState } from "../date-picker";
import { DateAdapterUtils } from "booking_app/utils";
import { AppSettings } from "booking_app/values/app-settings";

declare var moment: any;

interface DateUpdateValue {
  startDate: string;
  endDate: string;
}

export class DateRangeSingleFieldMobileController {
  static $inject = ["$element", "$scope", "AppSettings", "$timeout"];

  // bindings
  startDate: string;
  endDate: string;
  minDaysToBookInAdvance: number = 0;
  minBookingDays: number = 0;
  onUpdate: (obj: { value: DateUpdateValue }) => void;
  locale: string;
  dateFormat: string;
  enableSelectionOfDates: boolean = false;

  // local state
  modalOpen: boolean = false;
  selectionState: DatePickerSelectionState;

  constructor(
    private $element: any,
    private $scope: any,
    private appSettings: AppSettings,
    private $timeout: any,
  ) {

  }

  updateStartDate(value: string) {
    this.onUpdate({
      value: {
        startDate: value,
        endDate: this.newEndDateValue(value),
      },
    });
    this.selectionState = "END";
    this.$scope.$apply();
  }

  updateEndDate(value: string) {
    this.onUpdate({
      value: {
        startDate: this.startDate,
        endDate: value,
      },
    });
    this.selectionState = "START";
    this.$scope.$apply();
    this.closeModal();
  }

  beginDateSelection() {
    this.modalOpen = true;
    this.selectionState = "START";
    this.$element.find(".field-input").blur();
  }

  closeModal() {
    this.modalOpen = false;
  }

  formattedDate(): string {
    const dateFormat = this.dateFormat || "ll";
    const startDate = this.stringToMoment(this.startDate).format(dateFormat);
    const endDate = this.stringToMoment(this.endDate).format(dateFormat);
    return `${startDate} - ${endDate}`;
  }

  formattedStartDate(): string {
    return this.stringToMoment(this.startDate).format(this.dateFormat || "D MMM YYYY");
  }

  formattedEndDate(): string {
    return this.stringToMoment(this.endDate).format(this.dateFormat || "D MMM YYYY");
  }

  numberOfNights(): number {
    return this.stringToMoment(this.endDate).diff(this.stringToMoment(this.startDate), "days");
  }

  startInfoFieldClass(): object {
    return {
      "info-field": true,
      "is-selecting": this.selectionState === "START",
    };
  }

  endInfoFieldClass(): object {
    return {
      "info-field": true,
      "is-selecting": this.selectionState === "END",
    };
  }

  minDatepickerDate(): string {
    if (this.selectionState === "START") {
      return moment().add(this.minDaysToBookInAdvance, "d").format(DateAdapterUtils.V2_DATE_FORMAT);
    } else if (this.selectionState === "END") {
      let minBookingDays = this.minBookingDays;
      if (this.enableSelectionOfDates) {
        minBookingDays = 1;
      }

      return this.stringToMoment(this.startDate)
        .add(minBookingDays, "d")
        .format(DateAdapterUtils.V2_DATE_FORMAT);
    }
  }

  keyPressEvent(event): void {
    const isTabPressed = event.code === "Tab" || event.keyCode === 9;
    if (!isTabPressed) { return; }
    const parentElement = this.$element.closest("form-popup-mobile");
    this.$timeout(() => {
      $(this.$element[0].getElementsByClassName("btn-action")[0]).focus();
    });
  }

  private newEndDateValue(newStartValue: string): string {
    if (this.startDateGreaterThanEndDate(newStartValue) || this.appSettings.maxBookingDays) {
      return this.momentToString(this.addMinBookingDays(newStartValue));
    } else {
      return this.endDate;
    }
  }

  private startDateGreaterThanEndDate(newStartDate: string): boolean {
    return this.stringToMoment(newStartDate) > this.stringToMoment(this.endDate);
  }

  private stringToMoment(date: string): any {
    return moment(date, DateAdapterUtils.V2_DATE_FORMAT);
  }

  private momentToString(date: string): string {
    return moment(date).format(DateAdapterUtils.V2_DATE_FORMAT);
  }

  private addMinBookingDays(date: string): string {
    return this.stringToMoment(date).add(this.minBookingDays, "d");
  }
}
