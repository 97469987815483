import { AddressFormData } from "./address-form-data";
import { CountrySelectOption } from "booking_app/components/common/country-select";
import { StateSelectOption } from "booking_app/components/common/state-select";
import { AngularUtils } from "booking_app/utils";
import { CountryService } from "booking_app/services/country.service";
import { AppSettings } from "booking_app/values/app-settings";
import { Country } from "booking_app/constants/countries";
import { CheckoutOptions } from "booking_app/services/checkout-options";
import { PaginatedDropdownType } from "booking_app/types/paginated-dropdown-type";

export class AddressFormController {

  static $inject = [
    "AppSettings",
    "CountryService",
    "CheckoutOptions",
    "$rootScope",
    "$scope",
  ];

  // bindings
  data: AddressFormData;
  onUpdate: (obj: { data: AddressFormData }) => void;
  parentForm: any;
  formName: string;
  submitted: boolean;
  isAdyenCard: boolean;

  internalData: AddressFormData;
  statesList: StateSelectOption[];
  isStateRequired: boolean;

  constructor(
    private appSettings: AppSettings,
    private countryService: CountryService,
    private checkoutOptions: CheckoutOptions,
    private $rootScope: any,
    private $scope: any,
  ) { }

  $onInit() {
    this.statesList = [];
    this.isStateRequired = false;
    this.initializeCountrySelection();
    this.$scope.countryOptions = this.checkoutOptions.countryOptions();
  }

  $onChanges(changesObj): void {
    if (AngularUtils.hasBindingChanged(changesObj.data)) {
      this.internalData = angular.copy(this.data);
    }
  }

  onUpdateCountry(value: CountrySelectOption) {
    this.internalData.country = value;
    this.internalData.state = null;

    if (this.internalData.country) {
      this.isStateRequired = this.countryService.isStateRequired(this.internalData.country.code);
    }
    this.onUpdateData();
  }

  onUpdateState(value: StateSelectOption) {
    this.internalData.state = value;
    this.onUpdateData();
  }

  onUpdateData() {
    this.onUpdate({ data: this.internalData });
  }

  updateStatesList(options: StateSelectOption[]) {
    this.statesList = options;
  }

  formatCountryBillingValues(paginatedDropdownValues: PaginatedDropdownType): CountrySelectOption {
    const countrySelectValue: CountrySelectOption = {
      id: "",
      code: "",
      text: "",
    };
    if (paginatedDropdownValues.value) {
      countrySelectValue.id = paginatedDropdownValues.value;
      countrySelectValue.code = paginatedDropdownValues.value;
      countrySelectValue.text = paginatedDropdownValues.text;
    }

    return countrySelectValue;
  }

  private initializeCountrySelection(): void {
    if (this.appSettings.defaultCountryInCheckout.hasDefaultCountry) {
      const defaultCountry: Country = this.countryService.getDefaultCountry();
      this.internalData.country = {
        id: defaultCountry.code,
        code: defaultCountry.code,
        text: defaultCountry.name,
      };
    }
  }
}
