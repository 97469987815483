import { Bindings } from "booking_app/types";
import { GalleryController } from "./gallery.controller";

export class GalleryComponent {
  bindings: any = {
    images: Bindings.ONE_WAY,
    defaultBackgroundImage: Bindings.ONE_WAY,
    infinite: Bindings.ONE_WAY,
    autoplaySpeed: Bindings.ONE_WAY,
    autoplay: Bindings.ONE_WAY,
    accessibility: Bindings.ONE_WAY,
    arrows: Bindings.ONE_WAY,
    pauseOnHover: Bindings.ONE_WAY,
    dots: Bindings.ONE_WAY,
    currentGalleryIndex: Bindings.ONE_WAY,
    imagePosition: Bindings.ONE_WAY,
    arrowAriaLabel: Bindings.ONE_WAY,
  };
  controller: any = GalleryController;
  template: string = `
    <slick
      settings="$ctrl.slickConfiguration"
      class="hotel-detail-image-carousel"
    >
      <div class="slide" ng-if="$ctrl.images.length > 0" ng-repeat="image in $ctrl.images track by $index">
        <img ng-class="$ctrl.imagePositionClass()" data-lazy="{{ image }}" alt="">
        <div class="inset-shadow"></div>
      </div>
    </slick>
    <gallery-modal-button default-image="$ctrl.defaultBackgroundImage"
      current-gallery-index="$ctrl.currentGalleryIndex"
      hotel-id="$ctrl.$rootScope.hotelId">
    </gallery-modal-button>
    <div class="image-counter" ng-if="$ctrl.currentSlideNumber()">
      <span ng-bind="$ctrl.currentSlideNumber()"></span>
      <span>/</span>
      <span ng-bind="$ctrl.imageCount()"></span>
    </div>
  `;
}

angular.module("BookingApp").component("gallery", new GalleryComponent());
