export class HotelRegionSearchController {
  static $inject = ["$translate", "$rootScope", "CityCountryBuilderService"];

  // bindings
  label: string;
  value: any;
  list: any[];
  isCity: any;
  message: string;
  showError: any;

  public searchStatusMsg: string;
  public internalValue: string;
  private showSearchedLocations: boolean = true;

  constructor(
    private $translate: any,
    private $rootScope: any,
  ) {}

  $onChanges(changes: any) {
    if (changes?.value?.currentValue) {
      this.internalValue = changes.value.currentValue.name;
    }
  }

  onSelectCallback(location: any, isCity: any): void {
    this.internalValue = location.name;
    if (isCity) {
      this.$rootScope.searchState.updateDestination(location);
    } else {
      this.$rootScope.searchState.country = location;
      this.$rootScope.searchState.updateCities(location);
    }

    // when a dropdown option is selected, hide searchedLocations
    this.showSearchedLocations = false;
  }

  hasNoLocation(list: any[], query: string = "", msg: string = ""): boolean {
    query = query.toLowerCase().trim();
    if (query && query !== "") {
      const output = list.filter((loc) =>
        loc.name.toLowerCase().includes(query),
      );
      if (output.length === 0) {
        this.searchStatusMsg = this.$translate.instant(msg);
        return true;
      }
    }
    return false;
  }
}
