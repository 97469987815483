import { UserAgentChecker } from "booking_app/utils/user-agent-checker";

export class MobileDropdownWrapperController {

  static $inject = ["UserAgentChecker"];

  private selectedElementId: any;
  private ngModelController: any;

  // binding
  private name: string;
  private elementId: string;
  private elementClass: string;
  private ngModel: any;
  private required: boolean;
  private options: any[];
  private optionId: string;
  private optionLabel: string;
  private defaultLabel: string;
  private onChange;

  constructor(
    private userAgentChecker: UserAgentChecker,
  ) {}

  $onInit(): void {
    this.required = !!this.required ? this.required : false;
    this.optionLabel = !!this.optionLabel ? this.optionLabel : "text";
    this.optionId = !!this.optionId ? this.optionId : "id";
    this.defaultLabel = !!this.defaultLabel ? this.defaultLabel : "";
    this.selectedElementId = this.ngModel ? this.ngModel[this.optionId] : "";
  }

  public ngModelChange(): void {
    const selected = this.options.find( item => item.id === this.selectedElementId );
    this.ngModel = selected;
    this.ngModelController.$setViewValue(this.ngModel);
    if (typeof this.onChange === "function") {
      this.onChange();
    }
  }
}
