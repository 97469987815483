import { BannerController } from "./banner.controller";
import { Bindings } from "booking_app/types";

export class BannerComponent {

  bindings: {[key: string]: Bindings} = {
    slot: Bindings.ONE_WAY,
    type: Bindings.ONE_WAY,
  };

  controller: any = BannerController;

  template: string = `
    <ng-switch on="$ctrl.banner.banner_type">
      <div ng-switch-when="normal">
        ${this.normalBanner()}
      </div>
      <div ng-switch-when="deep_link">
        <div ng-if="$ctrl.hasTextcontent()">
          ${this.deepLinkBanner()}
        </div>
        <!-- This is only for acceptance criteria -->
        <div ng-if="!$ctrl.hasTextcontent()">
          ${this.normalBanner()}
        </div>
      </div>
    </ng-switch>
  `;

  private normalBanner(): string {
    return `
      <div class="banner-wrap" ng-if="!$ctrl.isMobile() && $ctrl.hasDesktopBanner()">
        <a ng-href="{{$ctrl.banner.target_url}}" target="{{$ctrl.bannerTargetWindow()}}">
          <img ng-src="{{$ctrl.banner.image_url}}">
        </a>
      </div>
      <div class="banner-wrap" ng-if="$ctrl.isMobile() && $ctrl.hasMobileBanner()">
        <a ng-href="{{$ctrl.banner.target_url}}" target="{{$ctrl.bannerTargetWindow()}}">
          <img ng-src="{{$ctrl.banner.image_url}}">
        </a>
      </div>
    `;
  }

  private deepLinkBanner(): string {
    return `
      <div class="deep-link-container">
        <div class="image-container">
          ${this.normalBanner()}
        </div>
        <div class="deep-link-content">
          <div class="text-content">
            <div class="title-text" ng-bind="$ctrl.banner.text_title">
            </div>
            <div class="body-text" ng-bind="$ctrl.banner.text_content">
            </div>
            <div class="url-link" ng-if="$ctrl.hasTargetUrlLink()">
              <a ng-href="{{$ctrl.banner.target_url}}" target="{{$ctrl.bannerTargetWindow()}}">
                <span ng-bind="$ctrl.banner.target_url_text"></span>
                <i class="fa fa-chevron-right"></i>
              </a>
            </div>
          </div>
          <div class="button-content" ng-if="$ctrl.hasDeepLink()">
            <a class="btn btn-primary" ng-click="$ctrl.deepLinkClick()">
              <span ng-bind="$ctrl.banner.deep_link_text"></span>
            </a>
          </div>
        </div>
      </div>
    `;
  }

}

angular.module("BookingApp").component("bannerComponent", new BannerComponent());
