import { DateSelectOption, DateSelectService } from "booking_app/components/flights/checkout";
import { isFutureDate } from "booking_app/components/flights/checkout/helpers";
import { CreditCardFormData } from "./credit-card-form-data";
import { AngularUtils } from "booking_app/utils";
import { CCValidationService } from "booking_app/services/ccvalidation/ccvalidation.service";
import { AppSettings } from "booking_app/values/app-settings";

export class CreditCardFormController {
  static $inject = [
    "DateSelectService",
    "CCValidationService",
    "AppSettings",
  ];

  // bindings
  parentForm: any;
  formName: string;
  data: CreditCardFormData;
  onUpdate: (obj: { data: CreditCardFormData }) => void;
  submitted: boolean;

  internalData: CreditCardFormData;
  stripePaymentIntentsEnabled: boolean;

  constructor(
    private dateSelectService: DateSelectService,
    private ccValidationService: CCValidationService,
    private appSettings: AppSettings,
  ) {
    this.dateSelectService = dateSelectService;
  }

  $onInit() {
    this.stripePaymentIntentsEnabled = this.appSettings.stripePaymentIntentsEnabled;
  }

  $onChanges(changesObj) {
    if (AngularUtils.hasBindingChanged(changesObj.data)) {
      this.internalData = angular.copy(this.data);
    }
  }

  isMaterial(): boolean {
    return this.appSettings.materialImplementation;
  }

  ccValidation(ccNumberInput: string) {
    this.ccValidationService.validate({
      scope: this,
      ccNumberInput,
      formInput: this.parentForm.cardNumber,
    });
  }

  onUpdateData() {
    this.onUpdate({ data: this.internalData });
  }

  displayDateOptions(option: DateSelectOption): string {
    return option && option.text;
  }

  onUpdateExpiryDate(value: DateSelectOption, key: string): void {
    this.internalData.expiryDate[key] = value;
    this.onUpdateData();
  }

  monthOptions(): DateSelectOption[] {
    return this.dateSelectService.monthOptions;
  }

  yearOptions(): DateSelectOption[] {
    return this.dateSelectService.futureYearOptions;
  }

  validExpiryDate(): boolean {
    return isFutureDate(this.internalData.expiryDate);
  }

  showExpiryDateRequiredError(): boolean {
    return this.submitted && (
      this.parentForm.expiryDateMonth.$error.required ||
      this.parentForm.expiryDateYear.$error.required
    );
  }

  showExpiryDateInvalidError(): boolean {
    return this.submitted && (
      this.parentForm.expiryDateMonth.$dirty ||
      this.parentForm.expiryDateYear.$dirty
    );
  }
}
