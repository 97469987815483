require('../services/vouchers.service')
require('../services/booking-errors-mapper.service')

angular.module("BookingApp")
.controller "VoucherCtrl", [

  "$scope", "$rootScope", "$filter", "VouchersService", "BookingErrorsMapper"
  ($scope, $rootScope, $filter, VouchersService, BookingErrorsMapper) ->

    $scope.voucherString = ""
    $scope.voucherFormErrors = null
    $scope.voucher = null

    $scope.enterVoucherPlaceholder = $filter("translate")("Enter voucher code")
    $scope.$watch "selectedLocale", (newValue, oldValue) ->
      $scope.enterVoucherPlaceholder = $filter("translate")("Enter voucher code")

    $scope.isCheckingVoucher = false

    $scope.getLeftToPay = ->
      return $scope.leftToPay() if $scope.hotelPrices
      0 if $scope.car # temporarily 0; the proper function will be added together with cash voucher

    $scope.getVoucherTypeId = ->
      return $scope.hotelPrices.voucher_type_id if $scope.hotelPrices
      $scope.car.voucher_type_id if $scope.car

    $scope.validateVoucher = ->
      return if $scope.isCheckingVoucher || !$scope.voucherString

      $scope.voucherFormErrors = null

      if $scope.vouchers.length == 1 && !$scope.landingPage.hasProductType("voucher")
        $scope.voucherFormErrors = "Only one voucher can be applied."
        return

      if $scope.vouchers.length > 0
        for voucher in $scope.vouchers
          if voucher.uid == $scope.voucherString
            $scope.voucherFormErrors = "This voucher code has already been added to the booking. \
                                        Please add a different code."
            return

      $scope.isCheckingVoucher = true

      VouchersService.validate(
        $rootScope.landingPage.url,
        $scope.voucherString,
        $scope.getLeftToPay(),
        $rootScope.pointsPartner.id,
        $scope.getVoucherTypeId()
      ).then (voucher) ->
        $scope.isCheckingVoucher = false
        $scope.voucher = voucher
        $scope.vouchers.push voucher
        $scope.voucherString = ""
        $scope.voucherFormErrors = null

        if voucher.points
          $scope.partnerScore.base = voucher.points

      , (errorResponse) ->
        $scope.isCheckingVoucher = false
        $scope.voucherFormErrors = BookingErrorsMapper.map(errorResponse.errors[0])

]
