interface PointsCashAriaLabelInput {
  points?: number;
  cash?: number;
  roundCashTo?: number;
  includeRoomsAndNights?: boolean;
}

angular.module("BookingApp")
  .filter("pointsCashAriaLabel", [
    "$filter", "$rootScope",
    ($filter, $rootScope) => {
      return (input: PointsCashAriaLabelInput): string => {
        const pointsValue: number = input.points;
        const cashValue: number = input.cash;
        const roundCashTo: number = input.roundCashTo || 0;
        const includeRoomsAndNights: boolean = input.includeRoomsAndNights || false;
        const ariaLabels: string[] = [];

        if (pointsValue > 0) {
          ariaLabels.push($filter("localeNumberFmt")(pointsValue, 0));
        }
        ariaLabels.push($rootScope.pointsPartner.shortCurrency);
        if (pointsValue > 0 && cashValue > 0) {
          ariaLabels.push("and");
        }
        if (cashValue > 0) {
          ariaLabels.push($filter("localeNumberFmt")(cashValue, roundCashTo));
        }
        ariaLabels.push($filter("codeSymbolFmt")($rootScope.selectedCurrency.code));

        if (includeRoomsAndNights) {
          if ($rootScope.globalState.showTotalNights) {
            ariaLabels.push("for");
            ariaLabels.push($rootScope.roomCount);
            ariaLabels.push($rootScope.roomCount > 1 ? "rooms" : "room");
            ariaLabels.push("and");
            ariaLabels.push($rootScope.duration);
            ariaLabels.push($rootScope.duration > 1 ? "nights" : "night");
          } else {
            ariaLabels.push("per room per night");
          }
        }

        return ariaLabels.join(" ");
      };
    }]);
