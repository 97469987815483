declare var angular : any;

class AuthAccess {

  static $inject = ['$location', '$rootScope']

  private $location : any
  private $rootScope : any
  private securePaths : any

  constructor($location, $rootScope) {
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.securePaths = /\/account\/*/
  }

  public check(route) {
    if (!route || !route.$$route) { return }
    var routePath = route.$$route.originalPath
    if (!this.$rootScope.userDetails.loggedIn && routePath.match(this.securePaths)) {
      this.$location.url("/")
    }
  }

}

angular.module("BookingApp").service('AuthAccess', AuthAccess)
