import { SavedCard } from "booking_app/models/saved-card";
import { PaymentMethodService } from "booking_app/services/payment-method.service";
import { PaymentMethod } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";

export class SavedCardsController {
  static $inject = [
    "$translate",
    "PaymentMethodService",
    "AppSettings",
  ];

  // bindings
  removeCreditCard;

  private selectedCardId: string;

  constructor(
    private $translate: any,
    private paymentMethodService: PaymentMethodService,
    private appSettings: AppSettings,
  ) {
  }

  public $onInit(): void {
    this.initializeSavedCards();
  }

  public selectCard(card: SavedCard): void {
    this.paymentMethodService.selectedCreditCard = card;
    this.selectedCardId = card.id;
  }

  public isSelected(cardId: string): boolean {
    return cardId === this.selectedCardId;
  }

  public getCardIconPath(cardType: string): string {
    return `/checkout/${cardType.toLowerCase()}.png`;
  }

  public removeCardConfirmation(currentCard: SavedCard): void {
    let isCurrentCardAlreadyRemoveInProgress;
    isCurrentCardAlreadyRemoveInProgress =
      this.paymentMethodService.savedCards
        .filter((card) => card.id === currentCard.id && card.removeInProgress)[0];

    /**
     * If the current card is already in progress and if the user press again
     * hide remove in progress button.
     */
    if (isCurrentCardAlreadyRemoveInProgress) {
      currentCard.removeInProgress = false;
    } else {
      this.initializeSavedCards();
      currentCard.removeInProgress = true;
    }
  }

  public removeCardClass(currentCard: SavedCard): { [key: string]: boolean } {
    const removeCard = {
      "remove-in-progress": false,
    };

    if (currentCard.removeInProgress) {
      removeCard["remove-in-progress"] = true;
    }

    return removeCard;
  }

  public removeCard(currentCard: SavedCard): void {
    this.removeCreditCard()(currentCard.token, currentCard.payment_channel);
    this.paymentMethodService.savedCards
      = this.paymentMethodService.savedCards.filter((card) => card.id !== currentCard.id);
    if (!this.paymentMethodService.hasSavedCards()) {
      this.paymentMethodService.activePaymentTab = this.appSettings.defaultPaymentMethod;
    }
  }

  public getCardContent(card: SavedCard): string {
    const translateLabel = this.isSelected(card.id) ?
                           "checkout.label.selected_card_type_ending_with" :
                           "checkout.label.unselected_card_type_ending_with";
    return this.$translate.instant(translateLabel, {type: card.card_type, digits: card.last_4});
  }

  public setCardFocus(event) {
    angular.element(event.target.parentNode).addClass("card-focused");
  }

  public setCardBlur(event) {
    angular.element(event.target.parentNode).removeClass("card-focused");
  }

  private initializeSavedCards(): void {
    // there will be no default cards if there are no saved cards
    if (this.paymentMethodService.hasSavedCards()) {
      const defaultCard = this.paymentMethodService.savedCards.filter((card) => card.default)[0] ??
        this.paymentMethodService.savedCards[0];
      this.paymentMethodService.selectedCreditCard = defaultCard;
      this.selectedCardId = defaultCard.id;
    }
  }

}
