import { CountrySelectItem, UiSelectChoices } from "booking_app/types";
import { CountryService } from "booking_app/services/country.service";
import { SortService } from "booking_app/services/sort.service";
import { AppSettings } from "booking_app/values/app-settings";

export class CheckoutOptions {
  static $inject = [
    "KaligoConstants",
    "CountryService",
    "SortService",
    "AppSettings",
    "$rootScope",
  ];

  private phoneCountriesList: UiSelectChoices[];
  private countriesList: UiSelectChoices[];

  constructor(
    private kaligoConstants: any,
    private countryService: CountryService,
    private sortService: SortService,
    private appSettings: AppSettings,
    private $rootScope: any,
  ) { }

  public creditCardExpirationYears(): UiSelectChoices[] {
    const yearsOptions: UiSelectChoices[] = [];
    this.kaligoConstants.creditCardExpirationYears.map((year) => {
      yearsOptions.push({ text: year, value: year});
    });

    return yearsOptions;
  }

  public creditCardExpirationMonths(): UiSelectChoices[] {
    const monthsOptions: UiSelectChoices[] = [];
    moment.monthsShort().map((month, index) => {
      monthsOptions.push({ text: month, value: index + 1});
    });

    return monthsOptions;
  }

  public guestTitleOptions(): UiSelectChoices[] {
    return [
      { value: "Mr", text: "salution_mr" },
      { value: "Ms", text: "salution_ms" },
      { value: "Mrs", text: "salution_mrs" },
    ];
  }

  public countryOptions(): UiSelectChoices[] {
    const options = this.countryService.getCountries().map(item => ({
      value: item.code,
      text: item.name,
    }));

    return this.sortService.string(options, "text");
  }

  public populateCountryList(): void {
    const countriesList: UiSelectChoices[] = [];
    const phoneCountriesList: UiSelectChoices[] = [];
    let countryItem: UiSelectChoices;
    let phoneCountryItem: UiSelectChoices;

    let initialCountryItem: UiSelectChoices; // Temporary Holders
    let initialPhoneCountryItem: UiSelectChoices; // Temporary Holders
    const initialCountryCode = this.appSettings.checkoutSettings.initialCountryOption;
    this.countryService.getCountries().forEach( country => {
      const id: string = `(+${country.phoneCode})`;

      countryItem = { value: country.code, text: country.name };
      phoneCountryItem = { value: id, text: `${country.name} ${id}` };

      if (initialCountryCode === country.code) {
        initialCountryItem = countryItem;
        initialPhoneCountryItem = phoneCountryItem;
      } else {
        countriesList.push(countryItem);
        phoneCountriesList.push(phoneCountryItem);
      }
    });

    this.countriesList = this.sortService.string(countriesList, "text");
    this.phoneCountriesList = this.sortService.string(phoneCountriesList, "text");

    if (initialCountryCode) {
      this.countriesList.unshift(initialCountryItem);
      this.phoneCountriesList.unshift(initialPhoneCountryItem);
    }
  }
}

angular.module("BookingApp").service("CheckoutOptions", CheckoutOptions);
