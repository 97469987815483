import { DateRangeSingleFieldController } from "./date-range-single-field.controller";
import { Bindings } from "booking_app/types";

export class DateRangeSingleFieldComponent {
  bindings: any = {
    startDate: Bindings.ONE_WAY,
    endDate: Bindings.ONE_WAY,
    minDaysToBookInAdvance: Bindings.ONE_WAY,
    minBookingDays: Bindings.ONE_WAY,
    onUpdate: Bindings.EXPRESSION,
    locale: Bindings.ONE_WAY,
    dateFormat: Bindings.ONE_WAY,
    numberOfMonths: Bindings.ONE_WAY,
    enableSelectionOfDates: Bindings.ONE_WAY,
  };
  controller: any = DateRangeSingleFieldController;
  transclude: any = {
    iconContent: "?iconContent",
  };
  template: string = `
    <div class="date-range-single-field" tabindex="-1" click-outside="$ctrl.clickedOutsideDatepicker()">
      <div id="aria-message-container" aria-live="assertive" role="alert">
        {{ $ctrl.ariaDateString }}
      </div>
      <div ng-class="{ 'date-range-single-field-input': true, 'is-focused': $ctrl.isFocused }">
        <span id="span-datepicker"
          ng-click="$ctrl.focusInputField()"
          ng-transclude="iconContent">
        </span>
        <input
          aria-labelledby="span-datepicker"
          class="field-input"
          type="text"
          readonly
          title="Hit Enter or Space to open date picker"
          ng-value="$ctrl.formattedDate()"
          ng-focus="$ctrl.beginStartDateSelection()"
          ng-click="$ctrl.beginStartDateSelection()"
          ng-blur="$ctrl.cancelSelection()"
          ng-keydown="$ctrl.keypressEvent($event)"
          aria-describedby="aria-message-container"
        >
      </div>

      <div class="date-range-single-field-modal" ng-if="$ctrl.modalOpen">
        <date-picker
          min-date="$ctrl.minDatepickerDate()"
          start-date="$ctrl.startDate"
          end-date="$ctrl.endDate"
          on-select-start-date="$ctrl.updateStartDate(value)"
          on-select-end-date="$ctrl.updateEndDate(value)"
          selection-state="$ctrl.selectionState"
          locale="$ctrl.locale"
          number-of-months="$ctrl.numberOfMonths"
        ></date-picker>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("dateRangeSingleField", new DateRangeSingleFieldComponent());
