import { ProductTypeSwitchController } from "./product-type-switch.controller";

export class ProductTypeSwitchComponent {
  controller: any = ProductTypeSwitchController;
  template: string = `
    <div class="switch-container" ng-if="$ctrl.pageDiscoveryService.isOnLandingPage() && $ctrl.isProductTypeSwitchEnabled()">
      <span ng-class="{ 'form-input-radio': true }" ng-repeat="(key, value) in $ctrl.productTypes">
        <input
          id="{{ 'product-type-' + value }}"
          type="radio"
          name="product-type"
          ng-model="$ctrl.$rootScope.globalState.productType"
          ng-change="$ctrl.setProductType($ctrl.productTypes[key])"
          value="{{value}}"
        >
        <label class="input-label" for="{{ 'product-type-' + value }}"
        translate="{{ $ctrl.translateProductType(value) }}"></label>
      </span>
    </div>
  `;
}

angular.module("BookingApp").component("productTypeSwitch", new ProductTypeSwitchComponent());
