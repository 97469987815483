import { AppSettings } from "booking_app/values/app-settings";
import { ViewportSizes } from "booking_app/types/viewport-sizes";

export class RoomGuestSelectMobileController {
  static $inject = ["$translate", "$rootScope", "$timeout", "AppSettings"];

  // bindings
  roomCount: number; // optional
  adultCount: number;
  childCount: number; // optional
  childrenAges: number[];
  onUpdateRoomCount: (obj: { count: number }) => void;
  onUpdateAdultCount: (obj: { count: number }) => void;
  onUpdateChildCount: (obj: { count: number }) => void;
  onUpdateChildrenAges: (obj: { childrenAges: number[] }) => void;
  closeModal: () => void;

  // local state
  minRoomCount: number = 1;
  minAdultCount: number = 1;
  minChildCount: number = 0;
  maxRoomCount: number = 4;
  maxAdultCount: number = 4;
  maxChildCount: number = 4;
  childrenAgeList: number[];

  constructor(
    private $translate: any,
    private $rootScope: any,
    private $timeout: any,
    private appSettings: AppSettings,
  ) { }

  $onInit() {
    this.childrenAgeList = this.appSettings.childrenAgeList;
    this.$timeout(this.addButtonEvents.bind(this));
  }

  updateRoomCount(value: number) {
    this.onUpdateRoomCount({ count: value });
  }

  updateAdultCount(value: number) {
    this.onUpdateAdultCount({ count: value });
  }

  updateChildCount(value: number) {
    this.onUpdateChildCount({ count: value });
  }

  numOfChildren(): number[] {
    if (this.childCount === undefined) {
      return [];
    }
    return new Array(this.childCount)
      .fill("")
      .map((_, index) => index);
  }

  updateChildrenAges(value: number, index: number) {
    const childrenAges = [...this.childrenAges];
    childrenAges[index] = value;
    this.onUpdateChildrenAges({ childrenAges });
  }

  formatChildValue(value: any) {
    return value;
  }

  shouldRenderRoomCounter(): boolean {
    return Number.isInteger(this.roomCount);
  }

  shouldRenderChildrenCounter(): boolean {
    if (!Number.isInteger(this.childCount)) {
      return false;
    }
    if (this.shouldRenderRoomCounter()) {
      return this.roomCount === 1;
    } else {
      return true;
    }
  }

  showMaxGuestsMessage(): boolean {
    return this.appSettings.maximumGuestsMessage.showMessage &&
           this.adultCount >= this.appSettings.maximumGuestsMessage.maximumGuests;
  }

  adultLabel(): string {
    if (Number.isInteger(this.childCount)) {
      return this.showLabelAsAdult();
    } else {
      return this.showLabelAsGuest();
    }
  }

  showLabelAsAdult(): string {
    if (this.labelOnlyRoomCounter()) {
      return this.$translate.instant("txt.adults");
    } else if (this.adultCount === 1) {
      return this.$translate.instant("count.adult_one");
    } else {
      return this.$translate.instant("count.adult_other", { count: this.adultCount });
    }
  }

  showLabelAsGuest(): string {
    if (this.labelOnlyRoomCounter()) {
      return this.$translate.instant("txt.adults");
    } else if (this.adultCount === 1) {
      return this.$translate.instant("Single Guest Count");
    } else {
      return this.$translate.instant("Multiple Guest Count", { count: this.adultCount });
    }
  }

  childLabel(): string {
    if (this.labelOnlyRoomCounter()) {
      return this.$translate.instant("Children");
    } else if (this.childCount === 1) {
      return this.$translate.instant("count.children_one");
    } else {
      return this.$translate.instant("count.children_other", { count: this.childCount });
    }
  }

  roomLabel(): string {
    if (this.labelOnlyRoomCounter()) {
      return this.$translate.instant("Rooms");
    } else if (this.roomCount === 1) {
      // count.room.one - phrase app doesnt register this pluralization key for other languages
      return this.$translate.instant("count.room_one");
    } else {
      return this.$translate.instant("count.room.other", { count: this.roomCount });
    }
  }

  showCloseButton(): boolean {
    return this.$rootScope.globalState.browserWidth > ViewportSizes.XS_MAX &&
      this.appSettings.roomGuestParameters.hasRoomGuestCloser;
  }

  private addButtonEvents(): void {
    const applyButton = document.getElementById("room-guest-counter-apply");
    if (applyButton) {
      applyButton.addEventListener("blur", () => this.closeModal());
    }
  }

  private labelOnlyRoomCounter(): boolean {
    return this.appSettings.roomGuestParameters.labelOnlyRoomCounter;
  }
}
