import { PayWithPointsCashService } from "booking_app/services/pay-with-points-cash.service";
import { CurrenciesService } from "booking_app/services/currencies.service";
import { TaxesAndFees } from "booking_app/types";

export class HotelPricePopulateService {
  static $inject = [
    "$rootScope",
    "$filter",
    "PayWithPointsCashService",
    "CurrenciesService",
  ];

  constructor(
    private $rootScope: any,
    private $filter: any,
    private payWithPointsCashService: PayWithPointsCashService,
    private currenciesService: CurrenciesService,
  ) { }

  populatePrice(hotelPrice: any, scope: any): any {
    this.updateFeeBreakdown(hotelPrice);
    hotelPrice.excludedTaxesAndFeesTotalInCurrency = hotelPrice.excluded_taxes_and_fees_total_in_currency;
    hotelPrice.excludedTaxesAndFeesCurrency = hotelPrice.excluded_taxes_and_fees_currency;
    hotelPrice.excludedTaxesAndFeesTotal = hotelPrice.excluded_taxes_and_fees_total;

    hotelPrice.totalPrice = hotelPrice.chargeableRate;
    hotelPrice.taxes = this.calculateTaxCharges(hotelPrice);
    hotelPrice.hotelFees = this.calculateHotelFees(hotelPrice);
    hotelPrice.kaligoServiceFee = hotelPrice.roomAdditionalInfo.displayFields.kaligo_service_fee;
    hotelPrice.totalPriceWithoutTax = hotelPrice.totalPrice - hotelPrice.taxes.taxAndRecoveryCharges;
    hotelPrice.totalPriceWithoutFeeAndTax = hotelPrice.totalPriceWithoutTax - hotelPrice.kaligoServiceFee;

    if (this.$rootScope.landingPage.complimentaryOrRedeem()) {
      hotelPrice = this.payWithPointsCashService.calculatePointsCashToPay(hotelPrice);
    }

    hotelPrice.formattedBaseRate = (): string => {
      return this.formatValue(hotelPrice.baseRate);
    };
    hotelPrice.formattedIncludedTaxesAndFees = this.formatIncludedTaxesAndFees(hotelPrice);
    hotelPrice.formattedIncludedTaxesAndFeesTotal = (): string => {
      return this.formatValue(hotelPrice.includedTaxesAndFeesTotal);
    };

    hotelPrice.totalRoomPriceWithoutFeeAndTax = (): string => {
      return this.formatCurrencyValue(hotelPrice.totalPriceWithoutFeeAndTax);
    };
    hotelPrice.averageRoomPrice = (): string => {
      if (this.$rootScope.globalState.taxInclusive) {
        return this.formatCurrencyValue(hotelPrice.totalPrice / scope.totalNights);
      }
      return this.formatCurrencyValue(hotelPrice.totalPriceWithoutTax / scope.totalNights);
    };
    hotelPrice.taxAndRecoveryCharges = (): string => {
      return this.formatCurrencyValue(hotelPrice.taxes.taxAndRecoveryCharges);
    };
    hotelPrice.taxAndRecoveryChargesPerNight = (): string => {
      return this.formatCurrencyValue(hotelPrice.taxes.taxAndRecoveryCharges / scope.totalNights);
    };
    hotelPrice.salesTax = (): string => {
      return this.formatCurrencyValue(hotelPrice.taxes.salesTax);
    };
    hotelPrice.hotelOccupancyTax = (): string => {
      return this.formatCurrencyValue(hotelPrice.taxes.hotelOccupancyTax);
    };
    hotelPrice.propertyFee = (): string => {
      return this.formatCurrencyValue(hotelPrice.taxes.propertyFee);
    };
    hotelPrice.formattedKaligoServiceFee = (): string => {
      return this.formatCurrencyValue(hotelPrice.kaligoServiceFee);
    };
    hotelPrice.formattedHotelFees = (): string => {
      return this.formatCurrencyValue(hotelPrice.hotelFees);
    };
    hotelPrice.formattedSubtotal = (): string => {
      return this.formatCurrencyValue(hotelPrice.totalPrice);
    };
    hotelPrice.formattedTotalVoucher = (): string => {
      const totalVoucher = scope.sumOfVouchers();
      let formattedValue = this.formatCurrencyValue(totalVoucher);
      if (totalVoucher > 0) {
        formattedValue = "-" + formattedValue;
      }
      return formattedValue;
    };
    hotelPrice.grandTotal = (): string => {
      return this.adjustCurrencyValue(scope.leftToPay());
    };
    hotelPrice.formattedOriginalPrice = (): string => {
      return this.formatCurrencyValue(hotelPrice.original_price);
    };
    hotelPrice.formattedComplimentaryDiscount = (): string => {
      return this.adjustCurrencyValue(hotelPrice.complimentary_discount);
    };
    // VISA complimentary nights average room rate
    hotelPrice.complimentaryAverageRoomPrice = (): number => {
      return (hotelPrice.original_price - hotelPrice.taxes.taxAndRecoveryCharges) / scope.totalNights;
    };
    hotelPrice.formattedComplimentaryAverageRoomPrice = (): string => {
      return this.formatCurrencyValue(hotelPrice.complimentaryAverageRoomPrice());
    };
    hotelPrice.formattedTotalRoomRate = (): string => {
      return this.formatCurrencyValue(hotelPrice.complimentaryAverageRoomPrice() * scope.totalNights);
    };
    hotelPrice.unformattedGrandTotal = (): number => {
      return Number(this.currenciesService.convertFromUsd(scope.leftToPay())
        .toFixed(this.$rootScope.selectedCurrency.decimalPlace));
    };
    // FAB complimentary-nights price breakdown
    hotelPrice.complimentaryNightTaxExclusivePrice = (): string => {
      return this.formatCurrencyValue(hotelPrice.tax_exclusive_price);
    };
    hotelPrice.complimentaryNightTaxes = (): string => {
      return this.formatCurrencyValue(hotelPrice.base_cash_payment - hotelPrice.tax_exclusive_price);
    };
    hotelPrice.complimentaryNightDiscount = (): string => {
      return this.formatCurrencyValue(hotelPrice.complimentary_discount);
    };

    // For Bounty discount and original price
    hotelPrice.originalGrandTotal = (): string => {
      let originalPrice: number = Number(this.currenciesService.convertFromUsd(scope.leftToPay()));
      if (hotelPrice.discount) {
        originalPrice = Math.ceil( originalPrice / (1 - hotelPrice.discount / 100));
      }
      return this.formatValue(this.currenciesService.adjustDecimals(originalPrice, 2));
    };

    return hotelPrice;
  }

  public updateFeeBreakdown(hotelPrice: any): void {
    if (!hotelPrice) {
      return;
    }

    const hasFeeBreakdown = !!(
      typeof hotelPrice.base_rate === "number"
        && typeof hotelPrice.included_taxes_and_fees_total === "number"
        && typeof hotelPrice.excluded_taxes_and_fees_currency === "string"
        && typeof hotelPrice.excluded_taxes_and_fees_total === "number"
        && Array.isArray(hotelPrice.included_taxes_and_fees)
        && typeof hotelPrice.excluded_taxes_and_fees_total_in_currency === "number"
    )

    if (!hasFeeBreakdown) {
      return;
    }

    if (this.$rootScope.landingPage.complimentaryOrRedeem()) {
      // This part should be removed as we introduce stripe processing fee on BE
      hotelPrice = this.payWithPointsCashService.calculateAdjustedFeeBreakdown(hotelPrice);
    } else {
      this.calculateFeeBreakdown(hotelPrice);
    }
    hotelPrice.formattedIncludedTaxesAndFees = this.formatIncludedTaxesAndFees(hotelPrice);
  }

  private formatIncludedTaxesAndFees(hotelPrice: any): TaxesAndFees {
    if (!hotelPrice?.includedTaxesAndFees) {
      return [];
    }
    return hotelPrice.includedTaxesAndFees?.map((item) => ({
      id: item.id,
      amount: this.formatValue(item.amount),
    }));
  }

  private calculateFeeBreakdown(hotelPrice): any {
    const originalTotalCharge = this.formatCurrencyValueAsNumber(
      hotelPrice.base_rate + hotelPrice.included_taxes_and_fees_total,
    );
    hotelPrice.includedTaxesAndFees = hotelPrice.included_taxes_and_fees.map((taxAndFeeItem) => (
      {id: taxAndFeeItem.id, amount: this.formatCurrencyValueAsNumber(taxAndFeeItem.amount)}
    ));
    hotelPrice.includedTaxesAndFeesTotal = this.formatCurrencyValueAsNumber(hotelPrice.included_taxes_and_fees_total);
    hotelPrice.baseRate = originalTotalCharge - hotelPrice.includedTaxesAndFeesTotal; // tackles rounding
  }

  private calculateTaxCharges(hotelPrice: any): any {
    const surcharges = hotelPrice.roomAdditionalInfo.displayFields.surcharges;
    const taxes = {
      taxAndRecoveryCharges: 0,
      salesTax: 0,
      hotelOccupancyTax: 0,
      propertyFee: 0,
    };

    surcharges.forEach(surcharge => {
      switch (surcharge.type) {
        case "TaxAndServiceFee":
        case "ExtraPersonFee":
          taxes.taxAndRecoveryCharges += surcharge.amount;
          break;
        case "SalesTax":
          taxes.salesTax += surcharge.amount;
          break;
        case "PropertyFee":
          taxes.propertyFee += surcharge.amount;
          break;
        case "HotelOccupancyTax":
          taxes.hotelOccupancyTax += surcharge.amount;
          break;
      }
    });
    return taxes;
  }

  private calculateHotelFees(hotelPrice: any): number {
    const hotelFees = hotelPrice.roomAdditionalInfo.displayFields.hotel_fees;
    let fees = 0;
    hotelFees.forEach(hotelFee => {
      fees += hotelFee.amount;
    });
    return fees;
  }

  private formatCurrencyValueAsNumber(value: number): number {
    const converted = this.currenciesService.convertFromUsd(value);
    if (this.$rootScope.selectedCurrency.decimalPlace === 0) {
      return Math.ceil(converted);
    }

    return Number(this.currenciesService.adjustDecimals(converted, 2));
  }

  private formatCurrencyValue(value: number): string {
    return this.formatValue(this.currenciesService.convertFromUsd(value));
  }

  private adjustCurrencyValue(value: number): string {
    const converted = this.currenciesService.convertFromUsd(value);
    const formatted = this.$rootScope.selectedCurrency.decimalPlace === 0
      ? Math.ceil(converted)
      : this.currenciesService.adjustDecimals(converted, 2);

    return this.formatValue(formatted);
  }

  private formatValue(value): string {
    if (this.$rootScope.selectedCurrency.decimalPlace === 0) {
      return this.$filter("numberFmt")(Math.ceil(value), this.$rootScope.selectedLocale, 0);
    }
    return this.$filter("numberFmt")(value, this.$rootScope.selectedLocale, 2);
  }
}

angular.module("BookingApp").service("HotelPricePopulateService", HotelPricePopulateService);
