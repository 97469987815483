export interface LoginModalConfig {
  loginModalWindowClass: LoginModalWindowClassOption;
  loginModalSize: LoginModalSizeOption;
}

export enum LoginModalWindowClassOption {
  DEFAULT_CLASS = "login-modal",
  VELOCITY_CLASS = "velocity-login-modal",
  HAINAN_CLASS = "hainan-login-modal",
  LOGIN_MODAL_VALIDATION_CLASS = "login-modal-validation",
}

export enum LoginModalSizeOption {
  DEFAULT_SIZE = "sm",
  MEDIUM_SIZE = "md",
}
