declare var moment: any;

export class DateAdapterUtils {
  static V1_DATE_FORMAT = "MM/DD/YYYY";
  static V2_DATE_FORMAT = "YYYY-MM-DD";
  static FORMAT_WITH_DAY = "ddd, MMM D";
  static V2_DATETIME_FORMAT = "YYYY-MM-DDThh:mm";

  // v1Date is MM/DD/YYYY
  static toV2(v1Date: string): string {
    return moment(v1Date, DateAdapterUtils.V1_DATE_FORMAT).format(DateAdapterUtils.V2_DATE_FORMAT);
  }

  // v2Date is YYYY-MM-DD
  static toV1(v2Date: string): string {
    return moment(v2Date, DateAdapterUtils.V2_DATE_FORMAT).format(DateAdapterUtils.V1_DATE_FORMAT);
  }

}
