import { CCValidationService } from "booking_app/services/ccvalidation/ccvalidation.service";
import { PaymentMethodService } from "booking_app/services/payment-method.service";
import { ApiDataService } from "booking_app/services//api-data.service";
import { loadStripe, PaymentIntentResult, Stripe } from "@stripe/stripe-js";

import {
  PaymentMethod,
  StripeCancelPaymentIntentResponse,
  StripeIntentIINResponse,
  StripePaymentIntentStatus,
} from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";
import { CardBrandDisplayName } from "booking_app/types/card-brand-display-name";

declare var Rollbar: any;

export enum StripeIntentFields {
  CardNumber = "cardNumber",
  CardExpiry = "cardExpiry",
  CardExpiryPastDate = "cardExpiryPastDate",
  CardCvc = "cardCvc",
  CardBrandVerification = "cardBrandVerification",
}

export class StripePaymentIntentService {
  static $inject = [
    "$rootScope",
    "KaligoConfig",
    "$timeout",
    "ApiDataService",
    "AppSettings",
    "CCValidationService",
    "PaymentMethodService",
    "PaymentStylingSettings",
  ];

  cardNumberElement: any;
  cardExpiryElement: any;
  cardCvcElement: any;
  currentCardBrand: string;
  formScope: any;
  errorFlags: { [key in StripeIntentFields]: boolean };
  stripeIntentForm: { [key in StripeIntentFields]: boolean };
  stripe: Stripe;

  constructor(
    private $rootScope: any,
    private kaligoConfig: any,
    private $timeout: any,
    private apiDataService: ApiDataService,
    private appSettings: AppSettings,
    private ccValidationService: CCValidationService,
    private paymentMethodService: PaymentMethodService,
    private paymentStylingSettings: any,
  ) {
    this.resetPaymentIntentFields();
    this.initStripe();
  }

  // Form & Validation Related Methods

  public mountStripeIntentElements(): void {
    if (typeof this.stripe === "undefined" || !this.stripe) {
      Rollbar.warning("Error on mounting Stripe Intent Elements: stripe instance not initialized");
    }

    const elements = this.stripe.elements();

    if (typeof elements === "undefined" || !elements) {
      Rollbar.warning("Error on mounting Stripe Intent Elements: stripe elements not initialized");
    }

    if (typeof elements.create !== "function") {
      Rollbar.warning("Error on mounting Stripe Intent Elements: stripe elements create is not a function");
    }

    const elementStyles = this.paymentStylingSettings.paymentSettings.stripe;
    this.cardNumberElement = elements.create("cardNumber", { style: elementStyles });
    this.cardExpiryElement = elements.create("cardExpiry", { style: elementStyles });
    this.cardCvcElement = elements.create("cardCvc", { style: elementStyles });

    this.cardNumberElement.on("loaderror", (e) => {
      Rollbar.warning(`Error on mounting Stripe Intent Elements: card number element fails to mount ${JSON.stringify(e.error)}`);
    });
    this.cardExpiryElement.on("loaderror", (e) => {
      Rollbar.warning(`Error on mounting Stripe Intent Elements: card expiry element fails to mount ${JSON.stringify(e.error)}`);
    });
    this.cardCvcElement.on("loaderror", (e) => {
      Rollbar.warning(`Error on mounting Stripe Intent Elements: card cvc element fails to mount ${JSON.stringify(e.error)}`);
    });

    // Since we conditionally load the template,
    // we need to make sure template is available in the DOM before we mount.
    this.waitForElement("card-number", () => {
      this.cardNumberElement.mount("#card-number");
    });
    this.waitForElement("card-expiry", () => {
      this.cardExpiryElement.mount("#card-expiry");
    });
    this.waitForElement("card-cvc", () => {
      this.cardCvcElement.mount("#card-cvc");
    });
  }

  public setupOnChangeListeners(scope: any): void {
    this.formScope = scope;
    this.cardNumberElement.on("change", this.handleOnChange.bind(this));
    this.cardExpiryElement.on("change", this.handleOnChange.bind(this));
    this.cardCvcElement.on("change", this.handleOnChange.bind(this));
    if (this.appSettings.materialImplementation) {
      this.cardNumberElement.on("focus", this.formElementFocus.bind(this));
      this.cardExpiryElement.on("focus", this.formElementFocus.bind(this));
      this.cardCvcElement.on("focus", this.formElementFocus.bind(this));
      this.cardNumberElement.on("blur", this.formElementBlur.bind(this));
      this.cardExpiryElement.on("blur", this.formElementBlur.bind(this));
      this.cardCvcElement.on("blur", this.formElementBlur.bind(this));
    }
    this.formScope.$on("currencyChanged", () => {
      this.checkAndUpdateCardBrandValid(this.currentCardBrand);
    });
  }

  public validateStripeIntentForm(): boolean {
    if (this.isUsingSavedCard() || this.isPayAnyone()) {
      return true;
    }

    if (!this.stripeIntentForm.cardExpiry) {
      this.stripeIntentForm.cardExpiryPastDate = true;
    }

    return (
      this.stripeIntentForm.cardNumber &&
      this.stripeIntentForm.cardExpiry &&
      this.stripeIntentForm.cardCvc &&
      this.stripeIntentForm.cardBrandVerification
    );
  }

  public cardBrandDisplayName(): string {
    if (!this.currentCardBrand) {
      return;
    }

    return CardBrandDisplayName[this.currentCardBrand] ||
          this.currentCardBrand.charAt(0).toUpperCase() + this.currentCardBrand.slice(1);
  }

  public isUsingSavedCard(): boolean {
    return (
      this.appSettings.storeCreditCard &&
      this.paymentMethodService.activePaymentTab === PaymentMethod.SAVED_CARDS &&
      !!this.paymentMethodService.selectedCreditCard
    );
  }

  // Endpoint related methods

  public confirmIntentProcess(
    clientSecret: string,
    successCallback: (response) => any,
    failCallback: (error) => any,
    bookingTransactionId?: string,
  ): Promise<any> {
    return this.confirmPayment(clientSecret).then((response) => {
      // If response is an error object, throw straight away
      if (response.error) {
        Rollbar.warning(`Stripe failed to confirm payment intent ${JSON.stringify(response)}`);
        throw response.error.message;
      }

      if (this.isConfirmPaymentSucceeded(response)) {
        if (this.isUsingSavedCard()) {
          return successCallback(response);
        }

        const paymentMethodId = (typeof response.paymentIntent.payment_method === "string") ?
                                response.paymentIntent.payment_method :
                                response.paymentIntent.payment_method.id;

        return this.getIntentIin(paymentMethodId).then((iinResponse) => {
          const { iin, fingerprint } = iinResponse;
          return this.ccValidationService.validateIntentIIN(
            iin,
            {
              fingerprint,
              bookingTransactionId,
            },
          ).then((valid) => {
            if (valid) {
              return successCallback(response);
            } else {
              throw new Error("IIN validation failed");
            }
          });
        });
      } else {
        throw new Error("Stripe intent status not succeeded or requires_capture");
      }
    }).catch((error) => {
      return failCallback(error);
    });
  }

  public cancelPaymentIntent(paymentIntentId: string): Promise<string> {
    return (this.apiDataService.post(`payment_intents/${paymentIntentId}/cancel`)
      .then((res: StripeCancelPaymentIntentResponse) => {
        if (res.success) {
          return `Stripe intent ${paymentIntentId} cancelled successfully.`;
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        return `Stripe intent ${paymentIntentId} not cancelled!`;
      })
    ) as Promise<string>;
  }

  public resetPaymentIntentFields(): void {
    this.stripeIntentForm = {
      cardNumber: false,
      cardExpiry: false,
      cardExpiryPastDate: false,
      cardCvc: false,
      cardBrandVerification: false,
    };
    this.errorFlags = {
      cardNumber: false,
      cardExpiry: false,
      cardExpiryPastDate: false,
      cardCvc: false,
      cardBrandVerification: false,
    };
  }

  private async initStripe(): Promise<void> {
    this.stripe = await loadStripe(
      this.kaligoConfig.isProduction ?
      this.appSettings.stripePublishableKey.production :
      this.appSettings.stripePublishableKey.test
    );
  }

  private confirmPayment(secret: string): Promise<PaymentIntentResult> {
    if (this.isUsingSavedCard()) {
      return this.stripe.confirmCardPayment(
        secret,
        { payment_method: this.paymentMethodService.selectedCreditCard.token },
      );
    }

    if (!this.cardNumberElement) {
      return new Promise((_, reject) => {
        reject("Stripe intent card number element not found");
      });
    }

    return this.stripe.confirmCardPayment(
      secret,
      { payment_method: { card: this.cardNumberElement } },
    );
  }

  private isConfirmPaymentSucceeded(response: PaymentIntentResult): boolean {
    if (response.paymentIntent) {
      return response.paymentIntent.status === StripePaymentIntentStatus.SUCCEEDED ||
             response.paymentIntent.status === StripePaymentIntentStatus.REQUIRES_CAPTURE;
    }
    return false;
  }

  private getIntentIin(paymentMethodId: string): Promise<StripeIntentIINResponse> {
    return (this.apiDataService.get(`payment_methods/${paymentMethodId}`)) as Promise<StripeIntentIINResponse>;
  }

  // Private Methods for OnChange Handlers

  private handleOnChange(event) {
    if (event.elementType === StripeIntentFields.CardNumber) {
      this.currentCardBrand = event.brand;
      this.checkAndUpdateCardBrandValid(event.brand);
    }

    this.updateStripeIntentForm(event.elementType, event.complete && !event.error);

    if (event.error || event.empty) {
      this.toggleFieldValidity(event.elementType, false, event.error?.code);
    } else if (event.complete) {
      this.toggleFieldValidity(event.elementType, true);
    }
  }

  private checkAndUpdateCardBrandValid(brand: string): void {
    if (this.isCardSupported(brand)) {
      this.stripeIntentForm.cardBrandVerification = true;
      this.errorFlags.cardBrandVerification = false;
    } else {
      this.stripeIntentForm.cardBrandVerification = false;
      this.errorFlags.cardBrandVerification = true;
    }
  }

  private isCardSupported(brand: string): boolean {
    const supportCheck: string[] = this.appSettings.supportedCards[brand];

     // if supportCheck is null, we assume that it support all currency
    if (!supportCheck) {
      return true;
    }

    return supportCheck.indexOf(this.$rootScope.selectedCurrency.code) >= 0;
  }

  private updateStripeIntentForm(elementType: StripeIntentFields, value: boolean) {
    this.stripeIntentForm[elementType] = value;
  }

  private toggleFieldValidity(field: StripeIntentFields, isValid: boolean, errorCode?: string) {
    switch (field) {
      case StripeIntentFields.CardNumber: {
        this.errorFlags.cardNumber = !isValid;
        break;
      }
      case StripeIntentFields.CardExpiry: {
        if (errorCode === "invalid_expiry_year_past") {
          this.errorFlags.cardExpiryPastDate = true;
          this.errorFlags.cardExpiry = false;
          this.stripeIntentForm.cardExpiryPastDate = false;
        } else {
          this.errorFlags.cardExpiry = !isValid;
          this.errorFlags.cardExpiryPastDate = false;
          this.stripeIntentForm.cardExpiryPastDate = true;
        }
        break;
      }
      case StripeIntentFields.CardCvc: {
        this.errorFlags.cardCvc = !isValid;
        break;
      }
    }
    this.formScope.$apply();
  }

  private formElementFocus(event): void {
    // ID's must be always be based on elementType ea. #card-number, #card-cvc...
    const dashedId = event.elementType.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
    const element = document.getElementById(dashedId);
    this.updateStripeFocusElement(element, true);
  }

  private formElementBlur(event): void {
    const dashedId = event.elementType.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
    const element = document.getElementById(dashedId);
    switch (event.elementType) {
      case StripeIntentFields.CardNumber: {
        if (this.cardNumberElement._empty) {
          this.updateStripeFocusElement(element, false);
          this.errorFlags.cardNumber = true;
        } else {
          element.parentElement.classList.remove("input-focused");
        }
        break;
      }
      case StripeIntentFields.CardExpiry: {
        if (this.cardExpiryElement._empty) {
          this.updateStripeFocusElement(element, false);
          this.errorFlags.cardExpiry = true;
        } else {
          element.parentElement.classList.remove("input-focused");
        }
        break;
      }
      case StripeIntentFields.CardCvc: {
        if (this.cardCvcElement._empty) {
          this.updateStripeFocusElement(element, false);
          this.errorFlags.cardCvc = true;
        } else {
          element.parentElement.classList.remove("input-focused");
        }
        break;
      }
    }
    this.formScope.$apply();
  }

  private updateStripeFocusElement(element: any, isFocused: boolean = true): void {
    if (isFocused) {
      element.classList.add("stripe-input-focused");
      element.parentElement.classList.add("input-focused");
    } else {
      element.classList.remove("stripe-input-focused");
      element.parentElement.classList.remove("input-focused");
    }
  }

  private isPayAnyone(): boolean {
    return this.paymentMethodService.activePaymentTab === PaymentMethod.PAY_ANYONE;
  }

  private waitForElement(elementId: string, callBack: () => void): void {
    this.$timeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        callBack();
      } else {
        this.waitForElement(elementId, callBack);
      }
    }, 500);
  }
}

angular.module("BookingApp").service("StripePaymentIntentService", StripePaymentIntentService);
