export interface InputSelectBindings {
  valueFormatter?: (selected: any) => string;
  onUpdate?: (obj: { value: any }) => void;
  selectedValue?: any;
  options?: any[];
  placeholder?: string;
  formName?: string;
  searchEnabled?: boolean;
  onRefresh?: (obj: { searchText: string }) => void;
  refreshDelay?: number;
  choicesAriaLabel?: string;
  choicesGroupAriaLabel?: string;
  triggerUpdateOnInit?: boolean;
}

export class InputSelectController implements InputSelectBindings {
  static $inject = ["$translate"];

  // bindings
  valueFormatter: (selected: any) => string;
  onUpdate: (obj: { value: any }) => void;
  selectedValue: any;
  options: any[];
  placeholder: string;
  formName: string;
  searchEnabled: boolean;
  onRefresh: (obj: { searchText: string }) => void;
  refreshDelay: number;
  choicesAriaLabel: string;
  choicesGroupAriaLabel: string;
  triggerUpdateOnInit: boolean = false;

  private currentValue: any;

  constructor(private $translate: any) { }

  $onInit() {
    this.currentValue = angular.copy(this.selectedValue);
    if (this.selectedValue && this.triggerUpdateOnInit) {
      this.onUpdate({ value: this.currentValue });
    }
  }

  $onChanges(changes: any) {
    if (changes.selectedValue) {
      this.currentValue = angular.copy(this.selectedValue);
    }
  }

  onValueChange() {
    this.onUpdate({ value: this.currentValue });
  }

  displayOption(selected: string): string {
    const option: string = selected ?? "";

    return this.$translate.instant(this.valueFormatter(option));
  }

  displayPlaceholder() {
    return this.$translate.instant(this.placeholder);
  }

  inputClass(selected: string | number): string {
    // We need to add number here because some input select causes issue here
    // because some input select values are pure `number`
    const option: string | number = selected ?? "";
    if (typeof(option) === "number") {
      return "";
    } else {
      return this.valueFormatter(option).split(" ")[0];
    }
  }

  refresh(searchText: string) {
    this.onRefresh({ searchText });
  }
}
