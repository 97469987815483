import { HotelsSearchFormController } from "./search-form.controller";
import { Bindings } from "booking_app/types";

export class HotelsSearchFormComponent {
  bindings: any = {
    // Added because changi-rewards-redeem DLP old design taking up more space than expected
    hidePartnerSelector: Bindings.ONE_WAY,
    dateIcon: Bindings.ONE_WAY,
  };
  controller: any = HotelsSearchFormController;
  templateUrl: string = "/html/hotels/search_form";
}

angular.module("BookingApp").component("hotelsSearchForm", new HotelsSearchFormComponent());
