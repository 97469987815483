import { ApiDataService } from "./api-data.service";
import { CityTile } from "booking_app/models";

export class CityTilesService {
  static $inject = [
    "ApiDataService",
    "$rootScope",
  ];

  private cityTiles: CityTile[];

  constructor(
    private apiDataService: ApiDataService,
    private $rootScope: any,
  ) { }

  // Add type
  fetchCityTile(): ng.IPromise<{}> {
    const url = this.$rootScope.landingPage.url;
    return this.apiDataService.get(`city_tiles?url=${url}`);
  }
}

angular.module("BookingApp").service("CityTilesService", CityTilesService);
