import { Banner, BannersService, BannerType } from "booking_app/services/banners.service";
import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { AppSettings } from "booking_app/values/app-settings";
import { QuickSearchService } from "booking_app/services/quick-search.service";
import { TravelType } from "booking_app/types";
import { GlobalStateService } from "booking_app/services/global-state.service";

export class BannerController {

  static $inject = [
    "$rootScope",
    "BannersService",
    "AppSettings",
    "GlobalStateService",
    "QuickSearchService",
  ];

  // bindings
  slot: string;
  type: BannerType;

  banner: Banner;

  constructor(
    private $rootScope: any,
    private bannersService: BannersService,
    private appSettings: AppSettings,
    private globalStateService: GlobalStateService,
    private quickSearchService: QuickSearchService,
  ) {}

  public $onInit(): void {
    this.banner = this.bannersService.find(this.slot);
  }

  public hasDesktopBanner(): boolean {
    if (this.isDesktopBanner()) {
      this.banner = this.bannersService.find(this.slot);
      return !!this.banner;
    }
  }

  public hasMobileBanner(): boolean {
    if (this.isMobileBanner()) {
      this.banner = this.bannersService.find(this.slot);
      return !!this.banner;
    }
  }

  public bannerTargetWindow(): string {
    return this.appSettings.bannerTargetWindow;
  }

  public isMobile(): boolean {
    return this.appSettings.mobileFirst || this.$rootScope.globalState.browserWidth <= ViewportSizes.XS_MAX;
  }

  public hasDeepLink(): boolean {
    return (!!this.banner.deep_link_url && !!this.banner.deep_link_text);
  }

  public hasTargetUrlLink(): boolean {
    return (!!this.banner.target_url && !!this.banner.target_url_text);
  }

  public hasTextcontent(): boolean {
    return (!!this.banner.text_title && !!this.banner.text_content);
  }

  public deepLinkClick(): void {
    const searchParams = this.banner.search_params;
    switch (this.globalStateService.travelType) {
      case TravelType.HOTELS:
        this.quickSearchService.runCustomHotelSearch(
          {
            id: searchParams.id,
            name: searchParams.name,
            nights: this.appSettings.destinationTileCheckoutDaysAfter,
            lat: searchParams.lat,
            lng: searchParams.lng,
          },
        );
        break;
      case TravelType.CARS:
        this.quickSearchService.runCustomCarSearch(
          {
            pickupId: searchParams.id,
            pickupName: searchParams.value,
          },
        );
        break;
      case TravelType.FLIGHTS:
        const locationIds = searchParams.id.toString().split("|");
        this.quickSearchService.runCustomFlightSearch({
          fromAirport: locationIds[0],
          toAirport: locationIds[1],
        });
        break;
    }
  }

  private isMobileBanner(): boolean {
    return this.type === BannerType.MOBILE;
  }

  private isDesktopBanner(): boolean {
    return this.type === BannerType.DESKTOP;
  }

}
