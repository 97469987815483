declare var angular: any;

const EMPTY_STAR = 0;
const FILLED_STAR = 1;
const MAX_STARS_LIMIT = 5;

class StarRatingController {
  static $inject = ["$translate"];

  public stars: number;
  public maxStars: number;

  constructor(private $translate: any) {}

  public getStarsCollection(): Array<string> {
    let starsCount;
    if (!this.stars || Number.isNaN(this.stars)) {
      starsCount = 0;
    } else {
      starsCount = Math.min(Math.floor(this.stars), MAX_STARS_LIMIT);
    }

    const emptyStarsCount = (this.maxStars || MAX_STARS_LIMIT) - starsCount;

    const stars = Array(starsCount).fill(FILLED_STAR);
    const emptyStars = Array(emptyStarsCount).fill(EMPTY_STAR);

    return stars.concat(emptyStars);
  }

  public starClass(star: number) {
    return {
      fa: true,
      'fa-star': true,
      empty: star === EMPTY_STAR,
    };
  }

  public getAriaLabel(): string {
    return this.stars > 1 ?
      this.$translate.instant("count.stars_other", { count: this.stars }) :
      this.$translate.instant("count.stars_one");
  }
}

export class StarRatingComponent {
  public bindings: any;
  public controller: any;
  public template: string;

  constructor() {
    this.bindings = {
      stars: '<',
      maxStars: '<',
    }
    this.controller = StarRatingController;
    this.template = [
      `<span aria-label="{{ $ctrl.getAriaLabel() }}" role="text">`,
        `<i ng-class="$ctrl.starClass(star)"
            ng-repeat="star in $ctrl.getStarsCollection() track by $index"
            aria-hidden="true"></i>`,
      `</span>`,
    ].join('');
  }
}

angular.module("BookingApp").component("starRating", new StarRatingComponent());
