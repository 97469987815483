angular.module('BookingApp')
.factory 'BonusFactory', [

  "$rootScope", "FormatService"
  ($rootScope, FormatService) ->

    (rawBonus) ->
      angular.extend angular.copy(rawBonus),

        maxThreshold: ->
          thresholds = (tier.threshold for tier in @bonus_tiers)
          if thresholds.length > 0 then Math.max.apply(null, thresholds) else 0

        maxReachedThreshold: ->
          thresholds = (tier.threshold for tier in @bonus_tiers when tier.threshold_in_usd <= @spent_so_far)
          if thresholds.length > 0 then Math.max.apply(null, thresholds) else 0

        partnerCurrencyName: -> @bonus_program.points_partners[0].currency_short

        currencySymbol: -> @bonus_program.currency_symbol

        earnedPoints: ->
          points = (tier.bonus_amount for tier in @bonus_tiers when tier.threshold_in_usd <= @spent_so_far)
          if points.length > 0 then Math.max.apply null, (points) else 0

        endDate: ->
          @bonus_program.end_time

        partnersIds: -> (parseInt(partner.id) for partner in @bonus_program.points_partners)

        applicableForPartner: (partnerId) ->
          parseInt(partnerId) in @partnersIds()

        isMultiThreshold: -> @bonus_tiers.length > 1 && @bonus_program.restrict_to == 'multiple_bookings'

        isSingleBooking: -> @bonus_program.restrict_to == 'single_booking'

        isActive: -> @status == 'active'

        completenessInfo: ->
          currRate = @bonus_program.currency_rate
          spentSoFar = FormatService.number(
            @spent_so_far * currRate,
            $rootScope.selectedLocale,
            2
          )

          if @isMultiThreshold() && @maxReachedThreshold() > 0
            "#{@status} (#{@currencySymbol()}#{spentSoFar} spent so far," +
            " #{@earnedPoints()} #{@partnerCurrencyName()} earned)"
          else if @isMultiThreshold()
            "#{@status} (#{@currencySymbol()}#{spentSoFar} spent so far)"
          else
            @status
]
