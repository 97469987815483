import { AppSettings } from "booking_app/values/app-settings";
import { ProductType } from "booking_app/types";
import { RoomInfoItem } from "booking_app/types";

export class HotelDetailsService {
  static $inject = [
    "$rootScope",
    "$timeout",
    "AppSettings",
    "UtilsService",
  ];

  public rooms: any[];
  public availRooms: RoomInfoItem[];
  public roomListing: any;

  private timeoutEvent: any;
  constructor(
    private $rootScope: any,
    private $timeout: any,
    private appSettings: AppSettings,
    private utilsService: any,
  ) { }

  initRooms(hotelPriceRooms: RoomInfoItem[]): void {
    this.updateAvailableRooms(hotelPriceRooms);
    this.availRooms = this.rooms;
    this.setupRooms();
    this.updateRoomsDescription();
  }

  shouldShowFreeCancellation(): boolean {
    if (this.$rootScope.globalState.productType === ProductType.REDEEM) {
      return this.appSettings.showCancelPolicyOnRedeem;
    } else if (this.$rootScope.globalState.productType === ProductType.EARN) {
      return this.appSettings.showCancelPolicyOnEarn;
    } else {
      return false;
    }
  }

  private updateRoomsDescription(): void {
    const childrenCount = this.utilsService.getchildrenCount(this.$rootScope.guests);
    if (!childrenCount) {
      return;
    }

    for (const room of this.availRooms) {
      if (room.roomDescriptionDetail === "hotel_detail_breakfast_included") {
        room.roomDescriptionDetail = `${room.roomDescriptionDetail}_adult_only`;
      }
    }
  }

  private removeDuplicates(rooms): RoomInfoItem[] {
    const filteredRooms: RoomInfoItem[] = [];
    rooms.forEach(room => {
      const currentRoom = filteredRooms.find(filteredRoom => (
        filteredRoom.roomDescriptionDetail === room.roomDescriptionDetail &&
        (filteredRoom.free_cancellation === room.free_cancellation ||
          !this.shouldShowFreeCancellation())
      ));
      if (currentRoom) {
        if (currentRoom.max_cash_payment < room.max_cash_payment) {
          room = currentRoom;
        }
        return;
      }
      filteredRooms.push(room);
    });

    return filteredRooms;
  }

  private updateAvailableRooms(hotelPriceRooms): void {
    this.rooms = hotelPriceRooms;
    this.rooms = this.rooms.filter(
      (room) => !this.$rootScope.bookingKeysBlackList.includes(room.key),
    );

    this.rooms.forEach((room, i) => {
      return room.visible = i < this.rooms.length;
    });
  }

  private setupRooms(): void {
    if (this.timeoutEvent) {
      this.$timeout.cancel(this.timeoutEvent);
    }

    this.timeoutEvent = this.$timeout(() => {
      this.groupRooms();
      this.removeDuplicateOnRooms();
      this.timeoutEvent = undefined; // destroy timeout variable
    }, 300);
  }

  private groupRooms(): void {
    this.roomListing = this.availRooms.reduce((roomListing, room) => {
      const arrKey = room.roomNormalizedDescription || room.roomDescription || room.type;
      roomListing[arrKey] = roomListing[arrKey] || { id: arrKey, rooms: []};
      roomListing[arrKey].rooms.push(room);
      return roomListing;
    }, {});
  }

  private removeDuplicateOnRooms(): void {
    for (const room in this.roomListing) {
      if (this.roomListing[room].rooms.length > 1) {
        this.roomListing[room].rooms = this.removeDuplicates(this.roomListing[room].rooms);
      }
    }
  }
}

angular.module("BookingApp").service("HotelDetailsService", HotelDetailsService);
