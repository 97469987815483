declare var angular: any;

// NOTE: This should be converted to attribute directive of img once we move to angular 2+
angular.module("BookingApp")
  .directive("imageSrcCheck", [
    () => {
      return {
        restrict: "A",
        link: (scope, element, attr) => {
          element.on("error", () => {
            element.attr("class", "hidden-image");
            // changed this to hidden image which just make the image opacity: 0
            // as to not break block images
          });
        },
      };
    },
  ]);
