require('../factories/search-form')
require('../values/kaligo-search')
require('../services/utils')

SearchCtrl = (
  $scope, $rootScope, SearchForm, KaligoSearch,
  UtilsService, AppSettings, HotelsSearchService,
  $routeParams, GlobalStateService
) ->
  GlobalStateService.currentPage = "home"
  $scope.globalStateService = GlobalStateService

  KaligoSearch.form = new SearchForm()

  # We can't bring this out yet. Cars depends on the same variable as hotels,
  # checkInDate and checkOutDate. Ideally we initialize this service only when
  # hotels search form is initialized
  HotelsSearchService.init()
  $rootScope.searchState = KaligoSearch.form

  if Object.keys(AppSettings.landingPageUrl).length
    $rootScope.globalState.productType =
      if $routeParams.product
        switch $routeParams.product
          when 'earn' then 'earn'
          when 'redeem' then 'redeem'
      else  $rootScope.globalState.productType

    $rootScope.landingPage.url = AppSettings.landingPageUrl[$rootScope.globalState.productType]

  $scope.$watch (()->
    return KaligoSearch.form.rooms
  ), (newValue, oldValue) ->
    if newValue > 1
      KaligoSearch.form.resetChildren()
  return


SearchCtrl
  .$inject = [
    '$scope', '$rootScope', 'SearchForm', 'KaligoSearch',
    'UtilsService', 'AppSettings', 'HotelsSearchService',
    '$routeParams', "GlobalStateService"
  ]

angular.module('BookingApp')
  .controller 'SearchCtrl', SearchCtrl
