import { HotelItem } from "booking_app/types";
import { AngularUtils, DisplayUtils } from "booking_app/utils";
import { TrackerService } from "booking_app/services/tracker";
import { MapboxService } from "booking_app/services/mapbox";
import { MapOpenerService } from "booking_app/services/hotels/map-opener.service";
import { TrustyouService } from "booking_app/services/trustyou.service";
import { AppSettings } from "booking_app/values/app-settings";
import { PointsCashPaymentHandlingService } from "booking_app/services/points-cash-payment-handling.service";

export class HotelListItemController {
  static $inject = [
    "$rootScope",
    "$scope",
    "$location",
    "DisplayUtils",
    "TrackerService",
    "AppSettings",
    "SearchUrlService",
    "WhitelabelPartner",
    "MapOpenerService",
    "PointsCashPaymentHandlingService",
  ];

  // bindings
  hotel: HotelItem;
  showAdminData: boolean;
  onToggleAdminData: () => void;

  constructor(
    private $rootScope: any,
    private $scope: any,
    private $location: any,
    private displayUtils: DisplayUtils,
    private trackerService: TrackerService,
    private appSettings: AppSettings,
    private searchUrlService: any,
    private whitelabelPartner: any,
    private mapOpenerService: MapOpenerService,
    private pointsCashPaymentHandlingService: PointsCashPaymentHandlingService,
  ) {
    this.$scope.showVatInfo = this.appSettings.showVatInfo;
  }

  trackRedirect() {
    if (this.hotel.available) {
      this.trackerService.hotelClick(
        this.hotel,
        {
          guests: this.$rootScope.guests,
          currencyCode: this.$rootScope.routeParams.currency,
          checkInDate: this.$rootScope.checkInDate,
          checkOutDate: this.$rootScope.checkOutDate,
        },
      );
    }
  }

  hotelDetailsUrl(): string {
    if (this.hotel.available) {
      return this.searchUrlService.createHotelDetailsUrl(this.hotel);
    } else {
      return "#";
    }
  }

  // earn specific
  earnAmount(): number {
    return this.hotel.score + this.hotel.bonus;
  }

  // earn specific
  earnAmountClass(): string {
    return `prefix${this.$rootScope.pointsPartner.partner}`;
  }

  // earn specific
  pointsPartnerIcon(): string {
    return this.$rootScope.globalState.pointsPartnerIcon;
  }

  // earn specific
  showAppName(): boolean {
    return !this.whitelabelPartner.hideCompareRatesUI;
  }

  showPerNightText(): boolean {
    return this.appSettings.showPerNightText;
  }

  showEarnOnBurn(): boolean {
    return this.appSettings.payWithPoints.earnOnBurn;
  }

  showTotalNights(): boolean {
    return this.$rootScope.globalState.showTotalNights;
  }

  toggleAdminData($event) {
    $event.preventDefault();
    this.onToggleAdminData();
  }

  isSelectedHotel(): boolean {
    return this.hotel.id === this.$rootScope.routeParams.selectedHotelId;
  }

  redirectToHotelDetailsPage(): void {
    this.trackRedirect();
    this.$location.url(this.hotelDetailsUrl());
  }

  showInMap(e, hotel?: HotelItem): void {
    this.mapOpenerService.openMap(hotel);
    e.stopPropagation();
  }

  zeroFullCashPayment(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(
      this.hotel.cash_payment,
      this.hotel.points_payment,
    );
  }
}
