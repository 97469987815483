import { CookieService } from "booking_app/services/cookie.service";
import { CancellationPolicyClause } from "../../models";
import { CancellationPolicyService } from "../../services/cancellation-policy.service";

declare var angular: any;
declare var moment: any;

class CancellationPolicyController {
  static $inject = [
    "CancellationPolicyService",
    "CookieService",
    "FormatService",
  ];

  // bindings
  public static: boolean;
  public responsive: boolean;
  public profile: boolean;
  public displayPercentage: boolean;
  public purchase: boolean;
  public amount: number;
  public convertRate: number;
  public leftToPay: number;
  public clause: CancellationPolicyClause;
  public currency: string;
  public locale: any;

  // internal variables
  private internalClause: CancellationPolicyClause;
  private translateValues: any;

  constructor(
    private cancellationPolicyService: CancellationPolicyService,
    private cookieService: CookieService,
    private formatService: any,
  ) { }

  public $onChanges(changesObj): void {
    this.updateValues();
  }

  private updateValues(): void {
    // In purchase success, it defaults to zh-TW for some reason w/o this code.
    moment.locale(this.cookieService.getLocale());

    if (this.displayPercentage) {
      this.updateClauseForDisplayPercentage();
    } else if (this.purchase) {
      this.updateClauseForPurchase();
    } else {
      this.internalClause = angular.copy(this.clause);
    }

    this.cancellationPolicyService.transformClause(this.internalClause);

    if (this.responsive && this.leftToPay && this.currency) {
      this.updateTranslateValuesForResponsive();
    } else if (this.static) {
      this.updateTranslateValuesForStatic();
    }
  }

  private updateClauseForDisplayPercentage(): void {
    this.internalClause = {
      from: this.clause.from,
      to: this.clause.to,
      amount: `${this.clause.percentage.toFixed(2)}%`,
    };
  }

  private updateClauseForPurchase(): void {
    let amount: string;

    const num: number = this.amount * this.clause.percentage / 100;
    const formattedNum: string = this.formatService.number(num, this.locale, 2);
    amount = `${this.currency} ${formattedNum}`;

    this.internalClause = {
      from: this.clause.from,
      to: this.clause.to,
      amount,
    };
  }

  private updateTranslateValuesForResponsive() {
    const amount: number = (this.internalClause.percentage / 100.0 * this.leftToPay) * this.convertRate;
    const formattedAmount: string = this.formatService.number(amount, this.locale, 2);
    this.translateValues = {
      amount: `<strong>${this.currency}${formattedAmount}</strong>`,
      start_date: `<strong>${this.formatDate(this.internalClause.from)}</strong>`,
      end_date: `<strong>${this.formatDate(this.internalClause.to)}</strong>`,
    };
  }

  private updateTranslateValuesForStatic() {
    this.translateValues = {
      amount: `<strong>${this.internalClause.amount}</strong>`,
      start_date: `<strong>${this.formatDate(this.internalClause.from)}</strong>`,
      end_date: `<strong>${this.formatDate(this.internalClause.to)}</strong>`,
    }
  }

  private formatDate(date) {
    return date.format("lll");
  }
}

export class CancellationPolicyComponent {
  public bindings: any;
  public template: string;
  public controller: any;

  constructor() {
    this.bindings = {
      static: "<",
      responsive: "<",
      profile: "<",
      displayPercentage: "<",
      purchase: "<",
      amount: "<",
      convertRate: "<",
      leftToPay: "<",
      clause: "<",
      currency: "<",
      locale: "<",
    };
    this.template = [
      `<div ng-if="$ctrl.translateValues">`,
      `<div `,
      `translate="{{ $ctrl.internalClause.text }}" `,
      `translate-values="$ctrl.translateValues"`,
      `></div>`,
      `</div>`,
    ].join('');
    this.controller = CancellationPolicyController;
  }
}

// rename this to 'cancellationPolicy' after the directive is completely removed
angular.module('BookingApp').component('cancellationPolicyComponent', new CancellationPolicyComponent())
