export class SessionTimerModal {

  static $inject = ['$scope', '$modalInstance', 'countdownTime']

  private $scope : any;
  private $modalInstance : any;
  public timeInSeconds : any;
  public timer : any;

  constructor($scope, $modalInstance, countdownTime) {
  	this.$scope = $scope
  	this.$modalInstance = $modalInstance
  	this.timeInSeconds = countdownTime / 1000

  	this.counting();
  }

  counting(): void {
    this.timer = setTimeout(() => {
      this.timeInSeconds = this.timeInSeconds - 1;
      this.$scope.$apply();
      if (this.timeInSeconds > 0) {
      this.counting();
      }
      else{
        this.close();
      }
    }, 1000);
  }

  ok(): void {
    clearTimeout(this.timer);
    this.$modalInstance.close('ok');
  };

  close(): void {
    this.$modalInstance.dismiss('close');
  };

}

angular.module('BookingApp').controller('SessionTimerModal', SessionTimerModal)
