export class LoginNotificationModal {

  private $scope : any;
  private $rootScope : any;
  private $modalInstance : any;

  static $inject = ['$scope', '$rootScope', '$modalInstance', 'modalYes', 'otpChannel', 'otpRecipient']

  constructor($scope, $rootScope, $modalInstance, modalYes, otpChannel, otpRecipient) {
    this.$scope = $scope
    this.$rootScope = $rootScope
    this.$modalInstance = $modalInstance

    this.$scope.otpChannel = otpChannel
    this.$scope.otpRecipient = otpRecipient
    this.$scope.modalYes = modalYes
    this.$rootScope.isLoading = false;

    this.$scope.close = () => {
      this.$modalInstance.dismiss('close')
    }

    this.$scope.yes = () => {
      this.$modalInstance.close('yes')
    }
  }
}

angular.module('BookingApp').controller('LoginNotificationModal', LoginNotificationModal)
