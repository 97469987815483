import { DateAdapterUtils, UrlUtils } from "booking_app/utils";
import { HotelsSearchService } from "booking_app/services/hotels";
import { CityCountryBuilderService } from "booking_app/services/city-country-builder.service";
import { AppSettings } from "booking_app/values/app-settings";
import { TravelType } from "booking_app/types";

declare var moment: any;

export class HotelsSearchFormController {

  static $inject = [
    "KaligoSearch",
    "$rootScope",
    "$translate",
    "HotelsSearchService",
    "AppSettings",
    "UrlUtils",
    "CityCountryBuilderService",
    "AppConfig",
  ];

  roomsOptions = [1, 2, 3, 4];
  adultsOptions = [1, 2, 3, 4];
  childrenOptions = [0, 1, 2, 3, 4];
  showChildrenForm: boolean = false;

  // bindings
  hidePartnerSelector: boolean = false;

  searchState: any;

  constructor(
    private KaligoSearch: any,
    private $rootScope: any,
    private $translate: any,
    private hotelsSearchService: HotelsSearchService,
    private appSettings: AppSettings,
    private urlUtils: UrlUtils,
    private cityCountryBuilderService: CityCountryBuilderService,
    private appConfig: any,
  ) {
    this.searchState = this.$rootScope.searchState;
  }

  searchHotels() {
    this.updateCheckOutDate(this.validatedCheckOutDate());
    this.hotelsSearchService.perform();
    setTimeout(() => {
      if (!!this.searchState.form) {
        this.searchState.form.submitted = false;
      }
    }, 100);
  }

  isLoading(): boolean {
    return this.$rootScope.isLoading || this.$rootScope.globalState.isNewSearch;
  }

  toggleChildrenForm(): void {
    this.showChildrenForm = !this.showChildrenForm;
  }

  closeChildrenForm(): void {
    this.showChildrenForm = false;
  }

  isChildrenSearchEnabled() {
    return this.appSettings.enableChildrenSearch;
  }

  updateDates(value: { startDate: string, endDate: string }) {
    this.updateCheckInDate(value.startDate);
    this.updateCheckOutDate(value.endDate);
  }

  minDaysToBookInAdvance(): number {
    return this.appConfig.min_days_to_book_in_advance[TravelType.HOTELS];
  }

  minBookingDays(): number {
    return this.$rootScope.landingPage.minBookingDays;
  }

  enableSelectionOfDates(): boolean {
    return this.appSettings.enableSelectionOfDates;
  }

  selectedLocale(): string {
    return this.$rootScope.selectedLocale.code;
  }

  updateRoomCount(count: number) {
    this.searchState.rooms = count;
  }

  updateAdultCount(count: number) {
    this.searchState.adults = count;
  }

  updateChildCount(count: number) {
    this.searchState.children = count;
  }

  updateChildrenAges(childrenAges: number[]) {
    this.searchState.childrenAges = childrenAges;
  }

  dateIconUrl(): string {
    const currentAppFolder = this.appSettings.tenant.toLowerCase();
    return this.urlUtils.imageUrl(`/${currentAppFolder}/icons/${this.appSettings.dateIcon}`);
  }

  numberOfDays(): number {
    return moment(this.searchState.checkout).diff(this.searchState.checkin, "days");
  }

  showHotelSearchError(): boolean {
    return !this.searchState.destination &&
      !this.$rootScope.destination &&
      this.searchState.form.submitted;
  }

  get countryList(): any[] {
    return this.cityCountryBuilderService.countryList;
  }

  // Intended as an adapter until all date format is changed to YYYY-MM-DD
  private checkInDate(): string {
    return DateAdapterUtils.toV2(this.searchState.checkin);
  }

  private checkOutDate(): string {
    return this.validatedCheckOutDate();
  }

  private validatedCheckOutDate(): string {
    const checkOutDate = moment(this.searchState.checkout);
    const minCheckOutDate = moment(this.searchState.checkin).add(this.minBookingDays(), "d");
    if (checkOutDate < minCheckOutDate) {
      return minCheckOutDate.format(DateAdapterUtils.V2_DATE_FORMAT);
    }
    return checkOutDate.format(DateAdapterUtils.V2_DATE_FORMAT);
  }

  private updateCheckInDate(date: string) {
    this.KaligoSearch.form.setCheckin(DateAdapterUtils.toV1(date));
  }

  private updateCheckOutDate(date: string) {
    this.KaligoSearch.form.setCheckout(DateAdapterUtils.toV1(date));
  }
}
