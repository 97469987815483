export class TwoOptionCustomModal {

  static $inject = ["$scope", "$modalInstance", "modalContent"];

  constructor(
    private $scope: any,
    private $modalInstance: any,
    public modalContent: any,
  ) {
    this.$scope.close = () => this.$modalInstance.dismiss("close");
    this.$scope.yes = () => this.$modalInstance.close(true);
    this.$scope.no = () => this.$modalInstance.close(false);

    this.$scope.modalHeader = modalContent.modalHeader;
    this.$scope.modalBody = modalContent.modalBody;
    this.$scope.modalYes = modalContent.modalYes;
    this.$scope.modalNo = modalContent.modalNo;
    this.$scope.modalYesUIClasses = modalContent.modalYesUIClasses;
  }
}

angular.module("BookingApp").controller("TwoOptionCustomModal", TwoOptionCustomModal);
