import { Modal, ProductType, TravelType } from "booking_app/types";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { AppSettings } from "booking_app/values/app-settings";
import { SimpleModalService } from "../simple-modal.service";

export class CheckoutValidationService {

  static $inject = [
    "$rootScope",
    "ModalManager",
    "$q",
    "$window",
    "SearchUrlService",
    "CarsState",
    "GlobalStateService",
    "AppSettings",
    "SimpleModalService",
  ];

  constructor(
    private $rootScope: any,
    private modalManager: any,
    private $q: any,
    private $window: any,
    private SearchUrlService: any,
    private CarsState: any,
    private globalStateService: GlobalStateService,
    private appSettings: AppSettings,
    private simpleModalService: SimpleModalService,
  ) {}

  public checkIfValidToCheckout(selectedItem: any, isPointChecking: boolean = false): Promise<void> {
    return this.$q((resolve, reject) => {
      if (this.requireLogin()) {
        reject(this.openLoginModal(selectedItem));
      } else if ( isPointChecking && this.isInsufficientPoints(selectedItem.minPoint) ) {
        reject(this.openMilesModal());
      } else if (this.isUserIneligible()) {
        reject(this.openUserIneligibilityCheckModal());
      }
      resolve();
    });
  }

  public insufficientPointsChecker(): void {
    this.modalManager.open({
      animation: true,
      templateUrl: "/html/modals/insufficient_point_check_modal",
      windowClass: "insufficient-points-modal-validation",
      controller: "InsufficientPointsModal",
      controllerAs: "$ctrl",
      backdrop: true,
      backdropClass: "validation-backdrop",
    });
  }

  public openLoginModal(selectedItem: any): void {
    this.$rootScope.globalState.openLogin(false, this.checkoutUrl(selectedItem));
  }

  public openMilesModal(): void {
    if (this.$rootScope.globalState.activeModal) {
      return;
    }
    this.$rootScope.globalState.activeModal = true;
    this.modalManager.open({
      animation: true,
      templateUrl: "/html/modals/miles_check_modal",
      windowClass: "login-modal-validation",
      size: "sm",
      controller: "LoginPopupCtrl",
      controllerAs: "popup",
      backdrop: true,
      backdropClass: "validation-backdrop",
      resolve: {
        redirectUrl: () => "",
      },
    });
  }

  public isInsufficientPoints(minPoint: number): boolean {
    const scopeUser = this.$rootScope.userDetails.user;
    return (this.$rootScope.landingPage.hasProductType(ProductType.REDEEM) &&
      scopeUser && scopeUser.family_miles < minPoint);
  }

  public requireLogin(): boolean {
    return this.$rootScope.globalState.requireLoginOnCheckout && !this.$rootScope.isUserLoggedIn();
  }

  public scrollToInvalidField(): void {
    const invalidFields = angular.element("[name='checkOutForm']")
                              .find("select.ng-invalid, input.ng-invalid, div.ng-invalid");
    const fieldOffset = this.appSettings.checkoutFormScrollOffset;
    if (invalidFields.length > 0) {
      const firstField = invalidFields.first();
      firstField.focus();
      this.$window.scrollTo(0, firstField.offset().top - fieldOffset);
    }
  }

  public isUserIneligible(): boolean {
    return this.appSettings.userIneligibilityCheck &&
      this.$rootScope.userDetails.user.account_status === "blocked";
  }

  public openUserIneligibilityCheckModal(): void {
    this.simpleModalService.open(Modal.USER_INELIGIBILITY_MODAL);
  }

  public impersonatedModalCheck(): boolean {
    return this.$rootScope.showE2EImpersonatedBanner && this.$window.KaligoConfig["isProduction"];
  }

  public impersonatedUserIsReadOnly(): boolean {
    const scopeUser = this.$rootScope.userDetails.user;

    if (!scopeUser) {
      return false;
    }

    return scopeUser.read_only;
  }

  private checkoutUrl(selectedItem: any): string {
    let url: string = "";
    switch (this.globalStateService.travelType) {
      case TravelType.HOTELS:
        url =  this.SearchUrlService.createCheckoutUrl(selectedItem.key);
        break;
      case TravelType.CARS:
        const params = this.SearchUrlService.generateCarsParams(this.CarsState);
        url =  this.SearchUrlService.createCarsCheckoutUrl(this.CarsState.bookingKey, params);
        break;
      case TravelType.FLIGHTS:
        url = selectedItem;
        break;
    }

    return encodeURIComponent(url);
  }
}

angular.module("BookingApp").service("CheckoutValidationService", CheckoutValidationService);
