import { Bindings } from "booking_app/types";
import { CarouselBannerController } from "./carousel-banner.controller";

export class CarouselBannerComponent {
  bindings: any = {
    groupName: Bindings.ONE_WAY,
    type: Bindings.ONE_WAY,
  };

  controller: any = CarouselBannerController;
  template: string = `
    <slick
      settings="$ctrl.slickConfiguration"
      class="carousel-banner">
      <div class="slide" ng-repeat="banner in $ctrl.carouselBanners track by $index">
        <banner-component slot="banner.slot" type="$ctrl.type" > </banner-component>
      </div>
    </slick>
  `;
}

angular.module("BookingApp").component("carouselBannerComponent", new CarouselBannerComponent());
