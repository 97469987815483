import { ApiDataService } from "booking_app/services/api-data.service";
import { E2eUserPreferences } from "booking_app/types/e2e-user-preferences";

export class E2eUserPreferenceService {
  static $inject = [
    "ApiDataService",
  ];

  constructor(
    private apiDataService: ApiDataService,
  ) {
  }

  updatePreferences(preferences: E2eUserPreferences): void {
    this.apiDataService.post("e2e/update_preferences", preferences);
  }

}

angular.module("BookingApp").service("E2eUserPreferenceService", E2eUserPreferenceService);
