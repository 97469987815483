import { Bindings } from "booking_app/types";
import { LandingPageInfoController } from "./landing-page-info.controller";

export class LandingPageInfoComponent {
  bindings: any = {
    travelType: Bindings.ONE_WAY,
    folderName: Bindings.ONE_WAY,
    hasCustomEarnRedeemIcon: Bindings.ONE_WAY,
    hasCustomSearchIcon: Bindings.ONE_WAY,
    productType: Bindings.ONE_WAY,
    iconFormat: Bindings.ONE_WAY,
  };

  controller: any = LandingPageInfoController;
  template: string = `
    <div class="max-size">
      <landing-page-info-item
        header="$ctrl.searchHeader()"
        description="$ctrl.searchDescription()"
        icon="$ctrl.searchIcon"
        folder-name="$ctrl.folderName"
        icon-format="$ctrl.iconFormat"
      ></landing-page-info-item>
      <landing-page-info-item
        header="$ctrl.earnRedeemHeader()"
        description="$ctrl.earnRedeemDescription()"
        icon="$ctrl.earnRedeemIcon"
        folder-name="$ctrl.folderName"
        icon-format="$ctrl.iconFormat"
      ></landing-page-info-item>
      <landing-page-info-item
        header="$ctrl.enjoyHeader()"
        description="$ctrl.enjoyDescription()"
        icon="$ctrl.enjoyIcon()"
        folder-name="$ctrl.folderName"
        icon-format="$ctrl.iconFormat"
      ></landing-page-info-item>
    </div>
  `;
}

angular.module("BookingApp").component("landingPageInfo", new LandingPageInfoComponent());
