import { GlobalStateService } from "booking_app/services/global-state.service";
import { TravelType } from "booking_app/types";

export class TravelTypeSwitcherController {
  static $inject = [
    "GlobalStateService",
    "AppConfig",
  ];

  order: string;

  constructor(
    private globalStateService: GlobalStateService,
    private appConfig: any,
  ) { }

  public $onInit(): void {
    this.order = this.order || "hotel-flight-car";
  }

  public hasTravelType(travelType: TravelType): boolean {
    return this.appConfig.travel_types.includes(travelType);
  }
}
