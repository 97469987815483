import { CardVerificationController } from "./card-verification.controller";
import { Bindings } from "booking_app/types";

export class CardVerification {
  bindings: any = {
    data: Bindings.ONE_WAY,
  };

  controller: any = CardVerificationController;
  template: string = `
    <div class="card-verification-form">
      <span class="verify-text" ng-bind="$ctrl.verifyText()">
      </span>
      <form name="$ctrl.formData" ng-submit="$ctrl.verifyCreditCard()">
        <input type="text" id="creditCardBin"
          ng-model="$ctrl.formData.creditCardBin"
          placeholder="{{ $ctrl.ccPlaceHolder() }}">
        <button type="submit" class="btn">
          <div ng-show="!$ctrl.isLoading" translate="txt.submit">
          </div>
          <button-loader is-loading="$ctrl.isLoading">
          </button-loader>
        </button>
      </form>
    </div>
  `;
}

angular.module("BookingApp").component("cardVerification", new CardVerification());
