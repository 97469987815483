export interface StripeIntentIINResponse {
  iin: string;
  fingerprint: string;
}

export interface StripeCancelPaymentIntentResponse {
  success: boolean;
}

export interface StripePaymentIntentLastPaymentError {
  charge: string;
  code: string;
  decline_code: string;
  doc_url: string;
  message: string;
  param: string;
  payment_method: StripePaymentMethod;
  payment_method_type: string;
  type: string;
}

export enum StripePaymentIntentStatus {
  REQUIRES_PAYMENT_METHOD = "requires_payment_method",
  REQUIRES_CONFIRMATION = "requires_confirmation",
  REQUIRES_ACTION = "requires_action",
  PROCESSING = "processing",
  REQUIRES_CAPTURE = "requires_capture",
  CANCELED = "canceled",
  SUCCEEDED = "succeeded",
}

export interface StripePaymentMethod {
  id: string;
  object: string;
  billing_details: {
    address: {
      city: string,
      country: string,
      line1: string,
      line2: string,
      postal_code: string,
      state: string,
    },
    email: string,
    name: string,
    phone: string,
  };
  card: {
    brand: string,
    checks: {
      address_line1_check: string,
      address_postal_code_check: string,
      cvc_check: string,
    },
    country: string,
    exp_month: number,
    exp_year: number,
    fingerprint: string,
    funding: "credit" | "debit" | "prepaid" | "unknown",
    generated_from: {
      charge: string;
      payment_method_details: any;
      setup_attempt: string;
    },
    last4: string,
    networks: {
      available: string[];
      preferred: string;
    },
    three_d_secure_usage: {
      supported: boolean,
    },
    wallet: any,
  };
  created: number;
  customer: string;
  livemode: boolean;
  metadata: any;
  redaction: any;
  type: string;
}
