import { UrlUtils } from "booking_app/utils";
import { UserAgentChecker } from "booking_app/utils/user-agent-checker";
import { PaymentMethodService } from "booking_app/services/payment-method.service";
import { Browser } from "booking_app/types/browser";
import { AppSettings } from "booking_app/values/app-settings";

declare var Rollbar: any;

type ChannelType = "PAO" | "MBS" | "TP" | "iPhone" | "Android";

export enum Status {
  IDLE = "idle",
  STARTED = "started",
}

export class PayAnyoneService {
  static $inject = [
    "$rootScope",
    "UrlUtils",
    "$window",
    "UserAgentChecker",
    "PaymentMethodService",
    "AppConfig",
    "AppSettings",
    "KaligoConfig",
  ];

  // class variables
  status: Status = Status.IDLE;
  qrCode: string = "";

  constructor(
    private $rootScope: any,
    private urlUtils: UrlUtils,
    private $window: any,
    private userAgentChecker: UserAgentChecker,
    private paymentMethodService: PaymentMethodService,
    private appConfig: any,
    private appSettings: AppSettings,
    private kaligoConfig: any,
  ) {
  }

  public start(response, bookingTransactionId): boolean {
    const payAnyoneMobile = this.userAgentChecker.isMobile();

    // only process when status is idle
    if (this.status !== Status.IDLE) {
      return payAnyoneMobile;
    }

    this.status = Status.STARTED;
    this.qrCode = response.params.qr_string;

    if (payAnyoneMobile) {
      if (!this.kaligoConfig.isProduction) {
        const payment_type = this.showMbs() ? "OCBC Digital" : "PayAnyone";
        alert(`Please switch network & then press Close to open ${payment_type} App`);
      }
      this.$window.location = this.mobileDeeplink(response, bookingTransactionId);
    } else {
      this.paymentMethodService.showPayAnyone = true;
    }
    return payAnyoneMobile;
  }

  public getImagePath(): string {
    const whiteLabel = this.appSettings.tenant.toLowerCase();
    return this.urlUtils.imageUrl(`/${whiteLabel}`);
  }

  public isPaoApp(): boolean {
    return this.isAppSource("PAO");
  }

  public isMbsApp(): boolean {
    return this.isAppSource("MBS");
  }

  public showMbs(): boolean {
    return this.isAppSource("MBS") || this.isAppSource("TP")
      || (!!this.appConfig?.feature_flags?.oldham_digital && !this.isPaoApp());
  }

  public resetPayAnyone(): void {
    this.status = Status.IDLE;
    this.paymentMethodService.showPayAnyone = false;
  }

  public payment_by(): string {
    return this.showMbs() ? "wl.ocbc_digital_payment_by" : "wl.pay_anyone_payment_by";
  }

  public scan_qr(): string {
    return this.showMbs() ? "wl.ocbc_digital_scan_qr" : "wl.pay_anyone_scan_qr";
  }

  private isAppSource(channel: ChannelType): boolean {
    const { user } = this.$rootScope.userDetails;
    return user && user.sign_in_origin && user.sign_in_origin === channel;
  }

  // Need to vary the deeplink based on iOS or Android, and also
  // based on the browser
  private mobileDeeplink(response, bookingTransactionId): string {
    const params = [];
    let scheme = this.showMbs() ? "ocbcmb://readQR?version=3" : "ocbcpao://readQR?version=2";

    params.push(
      scheme,
      `qrString=${this.qrCode}`,
      `transactionID=${bookingTransactionId}`,
      `appID=${this.getAppId(response.params.app_id)}`,
      `returnURI=${this.redirectUrl(response.params.redirect_url)}`,
    );

    if (!this.userAgentChecker.isIOS()) {
      params.push(
        "intentAction=android.intent.action.VIEW",
        `shouldOpenInExternalBrowser=${this.openInExternalBrowser()}`,
      );
    }

    Rollbar.info(`Browser: ${this.userAgentChecker.browser()} => Deeplink: ${params.join("&")}`);

    return params.join("&");
  }

  private openInExternalBrowser(): boolean {
    switch (this.userAgentChecker.browser()) {
      case (Browser.IOS_SAFARI):
      case (Browser.IOS_FIREFOX):
      case (Browser.IOS_CHROME):
        return !this.isPaoApp();
      case (Browser.ANDROID_CHROME):
      case (Browser.ANDROID_FIREFOX):
      case (Browser.ANDROID_OPERA):
      case (Browser.ANDROID_SAMSUNG_INTERNET):
      case (Browser.MOBILE_PUFFIN):
        return false;
      default:
        return true;
    }
  }

  private getAppId(appId: string): string {
    switch (this.userAgentChecker.browser()) {
      case (Browser.IOS_SAFARI):
      case (Browser.IOS_FIREFOX):
      case (Browser.IOS_CHROME):
        return appId;
      case (Browser.ANDROID_CHROME):
        return "com.android.chrome";
      case (Browser.ANDROID_FIREFOX):
        return "org.mozilla.firefox";
      case (Browser.ANDROID_OPERA):
        return "com.opera.mini.native";
      case (Browser.ANDROID_SAMSUNG_INTERNET):
        return "com.sec.android.app.sbrowser";
      case (Browser.MOBILE_PUFFIN):
        return "com.cloudmosa.puffinFree";
      default:
        return "";
    }
  }

  private redirectUrl(url: string): string {
    if (this.isPaoApp()) {
      return url;
    }

    switch (this.userAgentChecker.browser()) {
      case (Browser.IOS_FIREFOX):
        return url.replace("https", "firefox");
      case (Browser.IOS_CHROME):
        return url.replace("https", "googlechrome");
      default:
        return url;
    }
  }
}

angular.module("BookingApp").service("PayAnyoneService", PayAnyoneService);
