import { UrlUtils } from "booking_app/utils";

export class HotelImageService {
  static $inject = ["UrlUtils"];

  private defaultImageIndexes: {string?: number};
  private numberOfImages: {string?: number};
  private imageSources: {string?: string};
  private DEFAULT_IMAGES: string[];

  constructor(
    private urlUtils: UrlUtils,
  ) {
    this.defaultImageIndexes = {};
    this.numberOfImages = {};
    this.imageSources = {};
    this.DEFAULT_IMAGES = [
      "door.jpg",
      "pillow.jpg",
      "towels.jpg",
      "bell.jpg",
      "hotel-room.jpg",
      "hotel_desk.jpg",
      "bed_lamp.jpg",
      "housekeeping.jpg",
    ];
  }

  public initHotelImageData(data: any[]): void {
    data.forEach( (hotel: any): void => {
      this.defaultImageIndexes[hotel.id] = hotel.default_image_index;
      this.numberOfImages[hotel.id] = hotel.number_of_images;
      this.imageSources[hotel.id] = hotel.cloudflare_image_url;
    });
  }

  // Regarding Image styles
  public getThumbImageStyle(hotelId: string, index: number): object {
    return { "background-image": `url(${this.getHotelImageUrl(hotelId, index, "h=250&w=250")})` };
  }

  public getDefaultThumbImageStyle(hotelId: string): object {
    return this.getThumbImageStyle(hotelId, this.defaultImageIndexes[hotelId]);
  }

  public getDefaultHotelBgImageStyle(): object {
    const imageIndex: number = Math.floor(Math.random() * this.DEFAULT_IMAGES.length);
    const missingBackground: string = this.urlUtils.imageUrl(`/hotels_missing_images/${this.DEFAULT_IMAGES[imageIndex]}`);

    return {
      "background-image": `url(${missingBackground})`,
      "background-size": "cover",
    };
  }

  // Regarding Images
  public getHotelMediumSizeImagesSet(hotelId): string[] {
    return this.getHotelImagesSet(hotelId, "h=500&w=500");
  }

  public getHotelHighResImagesSet(hotelId: string): string[] {
    return this.getHotelImagesSet(hotelId);
  }

  public getDefaultHotelBgImage(): string {
    const imageIndex: number = Math.floor(Math.random() * this.DEFAULT_IMAGES.length);
    return this.urlUtils.imageUrl(`/hotels_missing_images/${this.DEFAULT_IMAGES[imageIndex]}`);
  }

  // Helper functions
  private getHotelImageUrl(hotelId: string, index: number, size: string = ""): string {
    const suffix: string = size === "" ? "" : `?fit=cover&${size}`;
    return `${this.imageSources[hotelId]}/${hotelId}/i${index}.jpg${suffix}`;
  }

  private getHotelImagesSet(hotelId: string, size = ""): string[] {
    const images: string[] = [];
    for (let i = 0; i < this.numberOfImages[hotelId]; i++) {
      images.push(this.getHotelImageUrl(hotelId, i + 1, size));
    }
    return images.length ? images : [this.getDefaultHotelBgImage()];
  }
}

angular.module("BookingApp").service("HotelImageService", HotelImageService);
