declare var angular: any;
angular.module("BookingApp")
  .directive("uiSelectDestinationSearch", [
    "uiSelectDirective",
    (
      uiSelectDirective: any,
    ) => {
      return angular.extend({}, uiSelectDirective[0], {
        templateUrl: "bootstrap/select.destination.tpl.html",
      });
    },
  ])
  .directive("ariaHideOnBlur", [
    "uiSelectDirective",
    (
      uiSelectDirective: any,
    ) => {
      return {
        restrict: "A",
        link: ($scope, $element) => {
          // this is overwritten the original tabindex value to allow NVDA focusing
          $element.attr("tabindex", "-1");
          $scope.$watch("$select.open", (isOpen) => {
            $scope.$select.focusser.attr("aria-hidden", !isOpen);
          });
        },
      };
    },
  ])
  .directive("ariaHideOnEmpty", [
    "uiSelectMatchDirective",
    (
      uiSelectMatchDirective: any,
    ) => {
      return {
        restrict: "A",
        link: ($scope, $element) => {
          // because the `div.ui-select-match` is not existed on the DOM, it generated by the directive
          const uiSelectMatch = $element.find(".ui-select-match").prevObject[0];
          $scope.$watch("$select.open", (isOpen) => {
            const hasValue = typeof $scope.$select.selected !== "undefined" && $scope.$select.selected ;
            const isHidden = !hasValue || isOpen;
            uiSelectMatch.setAttribute("aria-hidden", isHidden.toString());
          });
        },
      };
    },
  ])
  .directive("closeOnBlur", [
    "uiSelectDirective",
    (
      uiSelectDirective: any,
    ) => {
      return {
        restrict: "A",
        link: ($scope, $element) => {
          const searchInput = $element.querySelectorAll("input.ui-select-search");
          if (searchInput.length !== 1) {
            throw Error("Element not found");
          }
          searchInput.on("blur", (skipFocusser) => {
            $scope.$select.close(skipFocusser);
          });
        },
      };
    },
  ]);
