declare var angular: any;

angular.module("BookingApp").directive("convertToNumber", () => (
  {
    require: 'ngModel',
    link: (scope, element, attrs, ngModel) => {
      ngModel.$parsers.push(val => parseInt(val, 10));
      return ngModel.$formatters.push(val => '' + val);
    }
  }
));
