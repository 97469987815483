angular.module("BookingApp")
  .directive("validateMembershipNo", [
    "$timeout",
    ($timeout) => {
      return {
        restrict: "A",
        require: "ngModel",
        link: (scope, element, attrs, controller) => {
          let debounceTimeout: any;

          scope.$watch("membership.member_no", () => {
            $timeout.cancel(debounceTimeout);
            debounceTimeout = $timeout(() => {
              scope.validateMembershipNo();
            }, 100);
          });
        },
      };
    },
  ]);
