import { FlightsSinglePriceSearchResult } from "../flights-single-price-search-result";

export function isBookingKeyExpired(res: FlightsSinglePriceSearchResult): boolean {
  return res && res.itineraries && res.itineraries.length === 0;
}

export function defaultSinglePriceSearchResult(): FlightsSinglePriceSearchResult {
  return {
    completed: true,
    itineraries: [],
    search: null,
    error: null,
  }
}
