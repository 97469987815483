import { AdyenFormController } from "./adyen-form.controller";
import { Bindings } from "booking_app/types";

export class AdyenFormComponent {
  bindings: { [key: string]: Bindings } = {
    brand: Bindings.ONE_WAY,
  };
  controller: any = AdyenFormController;
  template: string = `
    <div id="adyen-custom-card-container">
      <div class="adyen-row">
        <label class="adyen-field-container card-number-field">
          <span>Card number</span>
          <div class="adyen-field" ng-class="{ 'invalid': $ctrl.isCardNumberInvalid, 'on-focus': $ctrl.isCardNumberOnFocus }">
            <span data-cse="encryptedCardNumber"></span>
          </div>
          <div ng-show="$ctrl.isCardNumberInvalid" class="dirtyMsg"
            translate-values="{ field: '{{ 'Card number' | translate | lowercase }}' }"
            translate="accounts.error.required.field"
          >
          </div>
        </label>
        <label class="adyen-field-container">
          <span>Name on Card</span>
          <input type="text" name="uCardName" class="adyen-field"
            ng-class="{ 'invalid': !$ctrl.nameOnCard && $ctrl.isFormSubmitted }"
            ng-model="$ctrl.nameOnCard"
            ng-model-options='{ debounce: 400 }'
            ng-change="$ctrl.handleNameChange()">
          <div ng-show="!$ctrl.nameOnCard && $ctrl.isFormSubmitted" class="dirtyMsg"
            translate-values="{ field: '{{ 'Name on Card' | translate | lowercase }}' }"
            translate="accounts.error.required.field"
          >
          </div>
        </label>
      </div>
      <div class="adyen-row security-field">
        <label class="adyen-field-container expiry-date-field">
          <span>Expiry date</span>
          <div class="adyen-field" ng-class="{ 'invalid': $ctrl.isExpiryDateInvalid, 'on-focus': $ctrl.isExpiryDateOnFocus }">
            <span data-cse="encryptedExpiryDate"></span>
          </div>
          <div ng-show="$ctrl.isExpiryDateInvalid" class="dirtyMsg"
            translate-values="{ field: '{{ 'Expiry date' | translate | lowercase }}' }"
            translate="accounts.error.required.field"
          >
          </div>
        </label>
        <label class="adyen-field-container cvv-field">
          <span>CVV/CVC</span>
          <div class="adyen-field" ng-class="{ 'invalid': $ctrl.isCvvInvalid, 'on-focus': $ctrl.isCvvOnFocus  }">
            <span data-cse="encryptedSecurityCode"></span>
          </div>
          <div ng-show="$ctrl.isCvvInvalid" class="dirtyMsg"
            translate-values="{ field: '{{ 'CVV/CVC' | translate }}' }"
            translate="accounts.error.required.field"
          >
          </div>
        </label>
        <div class="col-sm-3 col-xs-6 security-code">
          <img class="adyen-security-img inline-element" cdn-path= "/icons/icn-ccv.svg" />
        </div>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("adyenForm", new AdyenFormComponent());
