import { AppSettings } from "booking_app/values/app-settings";
import { FlightsSearchFormService, FlightsSearchFormState } from "booking_app/components/flights/search-form/.";

export class QuickSearchService {
  static $inject = [
    "$httpParamSerializer",
    "$location",
    "$rootScope",
    "$window",
    "AppSettings",
    "FlightsSearchFormService",
    "FlightsSearchFormState",
    "SearchUrlService",
    "UtilsService",
  ];

  constructor(
    private $httpParamSerializer: any,
    private $location: any,
    private $rootScope: any,
    private $window: any,
    private appSettings: AppSettings,
    private flightsSearchFormService: FlightsSearchFormService,
    private flightsSearchFormState: FlightsSearchFormState,
    private searchUrlService: any,
    private utilsService: any,
  ) { }

  public runCustomHotelSearch(
    location: {
      id: string,
      name: string,
      nights: number,
      lat: number,
      lng: number,
    },
  ): void {
    const destination = {
      id: location.id,
      name: location.name,
      lat: location.lat,
      lng: location.lng,
      type: "city",
    };

    this.initializeHotelSearch(destination, location.nights);
  }

  public runCustomCarSearch(
    location: {
      pickupId: string,
      pickupName: string,
      returnId?: string,
      returnName?: string,
    },
  ): void {
    this.initializeCarSearch({
      pickupId: location.pickupId,
      pickupName: location.pickupName,
      returnId: location.returnId,
      returnName: location.returnName,
    });
  }

  public runCustomFlightSearch(location: { fromAirport: string, toAirport: string}): void {
    this.initializeFlightSearch(
      {
        fromAirport: location.fromAirport,
        toAirport: location.toAirport,
      },
    );
  }

  private initializeFlightSearch(
    location: { fromAirport: string, toAirport: string },
  ): void {
    this.flightsSearchFormState.fromAirport = { value: location.fromAirport };
    this.flightsSearchFormState.toAirport = { value: location.toAirport };

    const url = this.flightsSearchFormService.generateResultsUrl();
    this.navigateToSearchPage(url);
  }

  private initializeCarSearch(location:
    {
      pickupId: string,
      pickupName: string,
      returnId?: string,
      returnName?: string,
    },
  ): void {
    if (location.pickupId) {
      const pickupLocation: any = {};
      pickupLocation.id = location.pickupId;
      if (location.pickupName) {
        pickupLocation.value = location.pickupName;
      }
      this.$rootScope.searchState.pickupLocation = pickupLocation;
      this.$rootScope.searchState.returnLocation = pickupLocation;
    }

    if (location.returnId) {
      const returnLocation: any = {};
      returnLocation.id = location.returnId;
      if (location.returnName) {
        returnLocation.value = location.returnName;
      }
      this.$rootScope.searchState.returnLocation = returnLocation;
    }

    const urlParams: string = this.searchUrlService.generateCarsParams(this.$rootScope.searchState);
    const url: string = this.searchUrlService.createCarsSearchUrl(urlParams);
    this.navigateToSearchPage(url);
  }

  private initializeHotelSearch(destination: any, nights: number = 3) {
    const checkInDaysLater: number = this.appSettings.destinationTileCheckinDaysLater;
    const checkInDateVal: string = moment().add(checkInDaysLater, "d").format("MM/DD/YYYY");
    const checkOutDateVal: string = moment().add(checkInDaysLater + nights, "d").format("MM/DD/YYYY");
    const params = {
      destination: destination.name,
      destination_type: destination.type,
      lat: destination.lat,
      lng: destination.lng,
      checkInDate: checkInDateVal,
      checkOutDate: checkOutDateVal,
      landingPage: this.$rootScope.landingPage.url,
      guests: this.utilsService.formatToGuests(1, 2),
      currency: this.$rootScope.selectedCurrency.code,
    };

    const urlQuery = this.$httpParamSerializer(params);
    const url = `/results/${destination.id}?${urlQuery}`;
    this.navigateToSearchPage(url);
  }

  private navigateToSearchPage(url: string): void {
    if (this.$rootScope.landingPage.quickSearchInNewTab) {
      this.$window.open(url, "_blank");
    } else if (this.appSettings.reloadOnQuickSearch) {
      this.$window.location.href = url;
    } else {
      this.$location.url(url);
    }
  }
}

angular.module("BookingApp").service("QuickSearchService", QuickSearchService);
