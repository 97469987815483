import { ModalManager } from "booking_app/components/common/modal-manager";
import { LoginService } from "booking_app/services/login";

export class IdleModal {
  static $inject = [
    "ModalManager",
    "hermesInstance",
    "$interval",
    "LoginService",
  ];

  remainingSeconds: number;
  pollingInterval: any;
  constructor(
    private modalManager: ModalManager,
    private hermesInstance: any,
    private $interval: any,
    private loginService: LoginService,
  ) {
    this.pollingInterval = this.$interval(() => {
      this.getRemainingSeconds();
    }, 1000);
  }

  $onInit(): void {
    this.getRemainingSeconds();
  }

  onCloseButtonClicked(): void {
    this.hermesInstance.onIdleDialogClosed();
    this.closeModal();
  }

  private getRemainingSeconds(): void {
    this.remainingSeconds = this.hermesInstance.getIdleDialogRemainingSeconds();
    if (this.remainingSeconds <= 0) {
      this.destroyInterval();
      this.loginService.redirectLogout();
    }
  }

  private closeModal(): void {
    this.destroyInterval();
    this.modalManager.close();
  }

  private destroyInterval(): void {
    this.$interval.cancel(this.pollingInterval);
  }
}

angular.module("BookingApp").controller("IdleModal", IdleModal);
