require('../services/booking-errors-mapper.service')

angular.module('BookingApp')
.controller 'ErrorPageController', [

  "$scope", "$location", "BookingErrorsMapper"
  ($scope, $location, BookingErrorsMapper) ->

    error = $location.search()["error_message"]
    $scope.errorMsg = BookingErrorsMapper.map(error)
]
