declare var angular: any

class MapboxStaticService {

  static $inject = ['$compile', '$rootScope', '$timeout', '$q', 'AppSettings']

  // Injected variables
  private $compile : any
  private $rootScope : any
  private $timeout : any
  private $q : any
  private AppSettings : any

  constructor($compile, $rootScope, $timeout, $q, AppSettings) {
    this.$compile = $compile
    this.$rootScope = $rootScope
    this.$timeout = $timeout
    this.$q = $q
    this.AppSettings = AppSettings
  }

  private isValidCoordinate(lat, lng): boolean {
    return parseFloat(lat) != 0 && parseFloat(lat) != NaN && parseFloat(lng) != 0 && parseFloat(lng) != NaN;
  }

  private createMiniMapUrl(lat, lng) {
    const baseURL = `https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/${lng},${lat},9/240x132`;
    const queryString = `?access_token=${this.AppSettings.mapboxAPIKey}&logo=false&attribution=false`
    return `${baseURL}${queryString}`;
  }

  public minimapUrl() {
    if (!(this.$rootScope.destinationLat || this.$rootScope.destinationLng || this.$rootScope.destination)) return
    let deferred = this.$q.defer()
    this.$timeout(() => {
      if (this.isValidCoordinate(this.$rootScope.destinationLat, this.$rootScope.destinationLng)) {
        let url = this.createMiniMapUrl(this.$rootScope.destinationLat, this.$rootScope.destinationLng)
        deferred.resolve(url)
      }
    }, 0)
    return deferred.promise
  }

}

angular.module("BookingApp").service('MapboxStaticService', MapboxStaticService)
