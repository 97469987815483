import { ApiDataService } from './api-data.service';
import '../factories/bonus-collection';

class BonusesService {

  static $inject = [
    'ApiDataService',
    'BonusCollectionFactory',
  ];

  constructor(
    private apiDataService: ApiDataService,
    private bonusCollectionFactory: any,
  ) { }

  fetch() {
    return this.apiDataService.get('bonuses').then(
      (data) => this.bonusCollectionFactory(data)
    )
  }
}

angular.module('BookingApp').service('BonusesService', BonusesService)
