export class HotelListItemDistanceController {
  static $inject = ["$filter"];

  // bindings
  destination: string;
  destinationType: string;
  distance: number;
  distanceLabel: string;

  constructor(private $filter: any) { }

  showHotelDistance(): boolean {
    return !!this.distance;
  }

  hotelDistance(): string {
    return this.$filter("localeNumberFmt")(this.distance, 1);
  }

  hotelDistanceLabel(): string {
    return this.distanceLabel || "POI_DISTANCE_AWAY_FROM";
  }

  poiName(): string {
    if (this.destinationType === "city") {
      return this.$filter("translate")("city center");
    } else if (this.destinationType === "airport") {
      return this.$filter("translate")("Airport");
    } else {
      return (this.destination || "").split(",")[0];
    }
  }
}
