export enum Card {
  AMEX = "amex",
  AURA = "aura",
  DINERS = "diners",
  DISCOVER = "discover",
  ELO = "elo",
  HIPERCARD = "hipercard",
  JCB = "jcb",
  MASTERCARD = "mastercard",
  VISA = "visa",
  UNIONPAY = "cup",
}
