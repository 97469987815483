declare var moment: any;

import { Booking } from "booking_app/models";
import { FlightsSegment } from "booking_app/models";
import { BookingDetailUtils } from "booking_app/utils";
import { CurrentPage, FlightCabin } from "booking_app/types";
import { TimeUtils } from "booking_app/utils";
import { RedirectService } from "booking_app/services/redirect.service";
import { AppSettings } from "booking_app/values/app-settings";
import { GlobalStateService } from "booking_app/services/global-state.service";

export class FlightBookingDetailController {

  static $inject = [
    "BookingDetailUtils",
    "RedirectService",
    "AppSettings",
    "GlobalStateService",
    "$rootScope",
    "$translate",
  ];

  cancellationFeeText: string;
  useTravelfusionConfirmationPage: boolean;

  constructor(
    private bookingDetailUtils: BookingDetailUtils,
    private redirectService: RedirectService,
    private appSettings: AppSettings,
    private globalStateService: GlobalStateService,
    private $rootScope: any,
    private $translate: any,
  ) {
    this.cancellationFeeText = this.appSettings.cancellationFeeText.success;
    this.useTravelfusionConfirmationPage = this.appSettings.useTravelfusionConfirmationPage;
  }

  public $onInit(): void {
    this.redirectService.unauthorizedConfirmationPage();
    this.bookingDetailUtils.init();
    this.globalStateService.currentPage = CurrentPage.CONFIRMATION;
  }

  public getBooking(): Booking {
    return this.bookingDetailUtils.getBooking();
  }

  public bookingStatus(): string {
    return this.getBooking().booking_status;
  }

  public departureDate(): string {
    const departureTime = moment(this.originFlights()[0].departure_time).utc();
    return this.dateFormatWithDate(departureTime, this.appSettings.customFlightsDateTimeLabel);
  }

  public returnDate(): string {
    if (!this.isOneWay()) {
      const departureTime = moment(this.returnFlights()[0].departure_time).utc();
      return this.dateFormatWithDate(departureTime, this.appSettings.customFlightsDateTimeLabel);
    }
  }

  public departureFlightText(): string {
    if (this.getBooking()) {
      return `${this.getBooking().from_airport_name} (${this.getBooking().from_airport})`;
    }
  }

  public returnFlightText(): string {
    if (this.getBooking()) {
      return `${this.getBooking().to_airport_name} (${this.getBooking().to_airport})`;
    }
  }

  public originArrivalDate(): string {
    if (this.getBooking()) {
      const flight = this.originFlights().slice(-1)[0];
      return this.buildArrivalDate(this.originFlights()[0].departure_time, flight);
    }
  }

  public departureArrivalDate(): string {
    if (this.getBooking() && !this.isOneWay()) {
      const flight = this.returnFlights().slice(-1)[0];
      return this.buildArrivalDate(this.returnFlights()[0].departure_time, flight);
    }
  }

  public originFlights(): FlightsSegment[] {
    if (this.getBooking()) {
      return this.getBooking().origin_flights;
    }
  }

  public returnFlights(): FlightsSegment[] {
    if (this.getBooking()) {
      return this.getBooking().return_flights;
    }
  }

  public flightCabinText(): string {
    if (this.getBooking()) {
      return FlightCabin.toStringTranslation(this.getBooking().cabin);
    }
  }

  public shortCurrency(): string {
    return this.$rootScope.pointsPartner.shortCurrency;
  }

  public isOneWay(): boolean {
    return this.returnFlights().length === 0;
  }

  public displayArrivalDate(segments: FlightsSegment[]): boolean {
    if (segments && segments.length !== 0) {
      const arrival = moment(segments[segments.length - 1].arrival_time).utc();
      const departure = moment(segments[0].departure_time).utc();
      return this.getOffsetDay(arrival, departure) > 0;
    }
  }

  public getTenant(): string {
    return this.appSettings.tenant.toLowerCase();
  }

  public emailNote(): string {
    return this.$translate.instant(`wl.${this.getTenant()}.travel_booking_email_note`);
  }

  private getOffsetDay(arrival, departure): number {
    return TimeUtils.getArrivalDayOffset(arrival, departure);
  }

  private buildArrivalDate(departure, flight): string {
    const arrivalDate = moment(flight.arrival_time).utc();
    const departureDate = moment(departure).utc();

    if (arrivalDate.format("MM-DD") > departureDate.format("MM-DD")) {
      return this.dateFormatWithDate(arrivalDate, "D MMM");
    }
  }

  private dateFormatWithDate(date, dateFormat): string {
    return moment(date).utc().format(dateFormat);
  }
}
