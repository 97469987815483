import { CheckoutValidationService } from "booking_app/services/checkout-validation";

export class CheckoutValidationController {

  static $inject = [
    "$rootScope",
    "$modal",
    "CheckoutValidationService",
  ];
  // Bindings
  private selectedItem: any;
  private redirectAction: () => void;
  private buttonLabel: string;
  private notAllowedToBook: boolean;

  // JAL required to check the point balance before checkout
  private isPointChecking: boolean;

  constructor(private $rootScope: any,
              private $modal: any,
              private checkoutValidationService: CheckoutValidationService,
  ) { }

  goToCheckoutPage(): void {
    const promiseValidToCheckout = this.checkoutValidationService.checkIfValidToCheckout(
        this.selectedItem,
        this.isPointChecking,
    );
    promiseValidToCheckout.then(() => {
      this.redirectAction();
    },
    () => {
      return; // error
    });
  }

}
