import { HotelListItemImageController } from "./image.controller";
import { Bindings } from "booking_app/types";

export class HotelListItemImageComponent {
  bindings: any = {
    hotel: Bindings.ONE_WAY,
  };
  controller: any = HotelListItemImageController;
  template: string = `
    <div class="hotel-image-container" ng-style="::$ctrl.defaultBackgroundImageStyle">
      <div class="hotel-image" ng-style="::$ctrl.backgroundImageStyle"></div>
    </div>
  `;
}

angular.module("BookingApp").component("hotelListItemImage", new HotelListItemImageComponent());
