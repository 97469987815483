import { CreditCardFormData } from "booking_app/components/common/payment-detail-form/credit-card";
import { CardDetails } from "booking_app/types/booking-details";
import { ModalManager} from "booking_app/components/common/modal-manager";
import { PaymentChannel } from "booking_app/types";

export interface AdyenCheckoutParams {
  clientKey: string;
  locale: string;
  environment: string;
  onAdditionalDetails?: (state, component) => void;
}

export class AdyenFormService {
  static $inject = [
    "$rootScope",
    "KaligoConfig",
    "ModalManager",
  ];

  constructor(
    private $rootScope: any,
    private kaligoConfig: any,
    private modalManager: ModalManager,
  ) { }

  public adyenCheckoutParams(): AdyenCheckoutParams {
    return {
      clientKey: this.kaligoConfig.isProduction ? "live_7VD74KOZT5HWZA6IEOL374C25ICCMUWG" : "test_3D3KFRSNB5DNZAABGKSL5RFOCQ7ASEZK",
      locale: "en_US",
      environment: this.kaligoConfig.isProduction ? "live" : "test",
    };
  }

  public adyenEncryptedData(cardData: CardDetails | CreditCardFormData): any {
    return {
      encrypted_card_number: cardData.encryptedCardNumber,
      encrypted_expiry_month: cardData.encryptedExpiryMonth,
      encrypted_expiry_year: cardData.encryptedExpiryYear,
      encrypted_security_code: cardData.encryptedSecurityCode,
    };
  }

  public validAdyenForm(paymentChannel: PaymentChannel, cardData: CardDetails | CreditCardFormData): boolean {
    if (this.adyenFormNotRequired(paymentChannel)) {
      return true;
    }
    const name: string = (cardData as CardDetails).firstName ?? (cardData as CreditCardFormData).cardholderName;

    this.$rootScope.$broadcast("form-submitted", cardData);
    return (
      !!name &&
      !!cardData.encryptedCardNumber &&
      !!cardData.encryptedExpiryMonth &&
      !!cardData.encryptedExpiryYear &&
      !!cardData.encryptedSecurityCode
    );
  }

  public handle3dsAction(action: any): void {
    this.modalManager.open({
      animation: true,
      templateUrl: "/html/modals/adyen_challenge_modal",
      windowClass: "center-modal",
      controller: "AdyenChallengeModal",
      controllerAs: "$ctrl",
      backdrop: "static",
      size: "modal-lg",
      backdropClass: "adyen-challenge-backdrop",
      resolve: {
        action: () => action,
      },
    });
  }

  private adyenFormNotRequired(paymentChannel: PaymentChannel): boolean {
    return !paymentChannel.startsWith("adyen") || paymentChannel === PaymentChannel.ADYEN_ALIPAY;
  }
}

angular.module("BookingApp").service("AdyenFormService", AdyenFormService);
