import { Browser } from "booking_app/types/browser";
import Bowser from "bowser";

const match = createMatch(navigator.userAgent);

function createMatch(userAgent: string): (regex: RegExp) => boolean {
  return (regex: RegExp): boolean => regex.test(userAgent);
}

export class UserAgentChecker {
  public isMobileOrTablet(): boolean {
    return this.isMobile() || this.isTablet();
  }

  public isMobile(): boolean {
    return this.currentBrowser().is("mobile");
  }

  public isTablet(): boolean {
    return this.currentBrowser().is("tablet");
  }

  public isIE(): boolean {
    return this.currentBrowser().is("Internet Explorer");
  }

  public isAndroid(): boolean {
    return this.currentBrowser().is("Android");
  }

  public isIOS(): boolean {
    return this.currentBrowser().is("iOS");
  }

  public isIE11(): boolean {
    return this.currentBrowser().satisfies({ "internet explorer": "~11" });
  }

  public isHuaweiBrowser(): boolean {
    const huaweiBrowser = /HuaweiBrowser/i;
    return match(huaweiBrowser);
  }

  public getPlatFormType(): "mobile" | "tablet" | "desktop" {
    return this.currentBrowser().getPlatformType();
  }

  public getOS(): string {
    return this.currentBrowser().getOSName();
  }

  public browser(): Browser {
    const browser = this.currentBrowser();
    if (browser.is("iOS") && browser.is("safari")) {
      return Browser.IOS_SAFARI;
    } else if (browser.is("iOS") && browser.is("firefox")) {
      return Browser.IOS_FIREFOX;
    } else if (browser.is("chrome") && browser.is("iOS")) {
      return Browser.IOS_CHROME;
    } else if (browser.is("chrome") && browser.is("android") && !this.isHuaweiBrowser()) {
      return Browser.ANDROID_CHROME;
    } else if (browser.is("firefox") && browser.is("android")) {
      return Browser.ANDROID_FIREFOX;
    } else if (browser.is("opera") && browser.is("android")) {
      return Browser.ANDROID_OPERA;
    } else if (browser.is("Samsung Internet for Android") && browser.is("android")) {
      return Browser.ANDROID_SAMSUNG_INTERNET;
    } else if (browser.is("mobile") && browser.is("puffin")) {
      return Browser.MOBILE_PUFFIN;
    } else if (this.isIE11()) {
      return Browser.IE11;
    }
  }

  private currentBrowser(): any {
    return Bowser.getParser(navigator.userAgent);
  }
}

angular.module("BookingApp").service("UserAgentChecker", UserAgentChecker);
