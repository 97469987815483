import { AppSettings } from "booking_app/values/app-settings";

export class RedirectService {
  static $inject = [
    "$rootScope",
    "$location",
    "AppSettings",
  ];

  constructor(
    private $rootScope: any,
    private $location: any,
    private appSettings: AppSettings,
  ) {}

  public unauthorizedConfirmationPage(): void {
    if (this.notAllowedConfirmationPage()) {
      return this.$location.url("/");
    }
  }

  private notAllowedConfirmationPage(): boolean {
    return this.appSettings.requireLoginOnConfirmationPage &&
      !this.$rootScope.isUserLoggedIn();
  }
}

angular.module("BookingApp").service("RedirectService", RedirectService);
