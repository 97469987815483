export class TwoOptionModal {

  static $inject = ["$scope", "$modalInstance", "modalHeader", "modalBody", "modalYes", "modalNo"];

  constructor(
    private $scope: any,
    private $modalInstance: any,
    public modalHeader: string,
    public modalBody: string,
    public modalYes: string,
    public modalNo: string,
  ) {
    this.$scope.close = () => this.$modalInstance.dismiss("close");
    this.$scope.yes = () => this.$modalInstance.close("yes");
    this.$scope.no = () => this.$modalInstance.close("no");

    this.$scope.modalHeader = modalHeader;
    this.$scope.modalBody = modalBody;
    this.$scope.modalYes = modalYes;
    this.$scope.modalNo = modalNo;
  }
}

angular.module("BookingApp").controller("TwoOptionModal", TwoOptionModal);
