import { Booking } from "../../models";
import { GoogleAnalyticsService } from "booking_app/services/google-analytics";

declare var angular: any;

class GAEcommerceTrackerCtrl {
  static $inject = [
    "GoogleAnalyticsService",
  ];

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private booking: Booking,
  ) {
  }

  public $onChanges(changes: any): void {
    if (changes.booking &&
      changes.booking.currentValue &&
      !angular.equals(changes.booking.currentValue, changes.booking.previousValue)) {
      this.trackGAEcommerce();
    }
  }

  public trackGAEcommerce(): void {
    if (!this.googleAnalyticsService) {
      return;
    }

    this.googleAnalyticsService.commonGa();

    this.googleAnalyticsService.setGa([
      {
        name: "require",
        value: "ecommerce",
      },
      {
        name: "ecommerce:addTransaction",
        value: {
          id: this.booking.zumata_booking_id,
          affiliation: "Kaligo",
          revenue: this.booking.cost,
          shipping: "0",
          tax: this.booking.tax_amount_usd,
        },
      },
      {
        name: "ecommerce:addItem",
        value: {
          id: this.booking.zumata_booking_id,
          name: this.booking.hotel_name,
          sku: this.booking.hotel_id,
          price: this.booking.cost,
          quantity: "1",
        },
      },
      {
        name: "ecommerce:send",
      },
    ]);
  }
}

export class GAEcommerceTrackerComponent {

  public bindings: any;
  public controller: any;
  public template: string;

  constructor() {
    this.bindings = {
      booking: '<',
    };
    this.controller = GAEcommerceTrackerCtrl;
    this.template = '';
  }
}

angular.module("BookingApp").component('gaEcommerceTrackerComponent', new GAEcommerceTrackerComponent())
