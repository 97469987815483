import { DateRangeFieldController } from "../date-range-field.controller";
import { DatePickerSelectionState } from "../../date-picker";
import { KeyboardKeyType } from "booking_app/types";

export class DateFieldController {
  static $inject = ["$element", "$rootScope"];

  dateRangeField: DateRangeFieldController;
  selectionState: DatePickerSelectionState;

  private isFocused: boolean = false;

  constructor(
    private $element: any,
    private $rootScope: any,
  ) {

  }

  focusInput() {
    this.$element.find("input")[0].focus();
  }

  formattedDate(): string {
    if (this.selectionState === "START") {
      return this.dateRangeField.formattedStartDate();
    } else if (this.selectionState === "END") {
      return this.dateRangeField.formattedEndDate();
    }
  }

  beginDateSelection() {
    this.isFocused = true;
    if (this.selectionState === "START") {
      this.dateRangeField.beginStartDateSelection();
    } else if (this.selectionState === "END") {
      this.dateRangeField.beginEndDateSelection();
    }
  }

  cancelSelection() {
    if (!this.$rootScope.isKeyboardUser) {
      this.isFocused = false;
      this.dateRangeField.cancelSelection();
    }
  }

  inputClass(): object {
    return {
      "date-range-field-start": this.selectionState === "START",
      "date-range-field-end": this.selectionState === "END",
    };
  }

  keypressEvent(event: any): void {
    // This if block is to support the scenario when user finish selecting date,
    // Then if they want to change their selections, they can press Enter/Space to reopen the datepicker modal
    // without navigating to the next/previous element and navigating back to open the datepicker modal
    this.isFocused = false;
    if ((event.key === KeyboardKeyType.ENTER || event.key === KeyboardKeyType.SPACE)
        && !this.dateRangeField.modalOpen) {
      event.preventDefault();
      event.stopPropagation();
      this.beginDateSelection();
      return;
    }
    const isTabPressed: boolean = event.key === KeyboardKeyType.TAB;
    const isArrowPressedWhenModalClose: boolean =
      (event.key === KeyboardKeyType.ARROWUP || event.key === KeyboardKeyType.ARROWDOWN)
      && !this.dateRangeField.modalOpen;

    if (!isTabPressed && !isArrowPressedWhenModalClose) { return; }

    event.preventDefault();
    const siblingElement = this.$element.closest("date-field").next()[0];
    let parentElement: HTMLElement = this.$element.closest(".search-form-group")[0];
    this.dateRangeField.blurStartDateField();
    this.dateRangeField.blurEndDateField();
    let focusElement: any;
    if (event.shiftKey || event.key === KeyboardKeyType.ARROWUP) {
      if (!parentElement.previousElementSibling) {
        parentElement = this.$element.closest("date-range-field")[0];
      }
      const prevElement = parentElement.previousElementSibling;
      focusElement = prevElement.getElementsByClassName("ui-select-search")[0];
      // move to previous element
    } else {
      const nextInputElement = siblingElement ? siblingElement.getElementsByClassName("select2-choice")[0] : null;
      const nextElement = parentElement.nextElementSibling;
      focusElement = nextInputElement || nextElement;
      // move to next element
    }

    if (focusElement) {
      event.preventDefault();
      event.stopPropagation();
      this.dateRangeField.closeModal();
      $(focusElement).focus();
    }
  }
}
