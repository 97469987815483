SearchForm = ($rootScope, $timeout, $translate,
  $analytics, KaligoConfig, CookieService,
  AppSettings, CityCountryBuilderService) ->

  () ->

    # SearchForm represents a state of the hotels search form.
    # We need it because user might change date in search edit form
    # and later close modal without submitting the form.
    # When form is submitted, these values are copied directly to $rootScope.

    # Initialize default value
    @destination = null
    @checkin = null
    @checkout = null
    @rooms = 1
    @adults = 2
    @children = 0
    @childrenAges = [0,0,0,0]
    @pointsPartner = null
    @showPartnerSelector = false

    @ageList = AppSettings.childrenAgeList

    calculateDuration = () =>
      if @checkOutDateObject
        @duration = @checkOutDateObject.diff(@checkInDateObject, 'days')
      @gaDaysBefore = @checkInDateObject.diff(moment(), 'days')
      return

    @setCheckin = (date) =>
      @checkin = date if date
      @checkInDateObject = moment(@checkin, 'MM/DD/YYYY').startOf('day')
      @displayCheckInDateNum = @checkInDateObject.toDate()
      @displayCheckInDate = @checkInDateObject.format("LL")
      @displayCheckInDateShort = @checkInDateObject.format("ll")
      @displayCheckInDateSimple = @checkInDateObject.format("DD/MM/YYYY")
      @displayCheckInDateWithDay = @checkInDateObject.format("ddd MMM, D")
      calculateDuration()
      return

    @setCheckout = (date) =>
      @checkout = date if date
      @checkOutDateObject = moment(@checkout, 'MM/DD/YYYY').startOf('day')
      @displayCheckOutDateNum = @checkOutDateObject.toDate()
      @displayCheckOutDate = @checkOutDateObject.format("LL")
      @displayCheckOutDateShort = @checkOutDateObject.format("ll")
      @displayCheckOutDateSimple = @checkOutDateObject.format("DD/MM/YYYY")
      @displayCheckOutDateWithDay = @checkOutDateObject.format("ddd MMM, D")
      calculateDuration()
      return

    @allowPartnerSwitching = () ->
      return $rootScope.landingPage.canChangePartner && !$rootScope.landingPage.hasProductType("voucher")

    @startSelectPartner = =>
      return if !@allowPartnerSwitching()
      $timeout( =>
        @isSelectingPartner = true
        @showPartnerSelector = true
        $rootScope.lockBody = "lock-body"
      )
      return

    @finishSelectPartner = (partnerId) =>
      if partnerId
        @pointsPartner = $rootScope.pointsPartners.findById(partnerId)
        CookieService.setPartner(partnerId)
      @isSelectingPartner = false
      @showPartnerSelector = false
      $rootScope.lockBody = ""
      return

    @resetChildren = () ->
      @children = 0
      @childrenAges = [0,0,0,0]

    captureEvent = (e) ->
      e.preventDefault()
      e.stopPropagation()

    MAX_ROOM = 4
    MAX_ADULT_PER_ROOM = 4
    MAX_CHILDREN_PER_ROOM = 4

    @reduceAdults = (e) =>
      @adults-- if @adults > 1
      captureEvent(e)

    @increaseAdults = (e) ->
      @adults++ if @adults < MAX_ADULT_PER_ROOM
      captureEvent(e)

    @reduceRooms = (e) ->
      @rooms-- if @rooms > 1
      captureEvent(e)

    @increaseRooms = (e) ->
      @rooms++ if @rooms < MAX_ROOM
      captureEvent(e)

    @reduceChildren = (e) ->
      @children-- if @children > 0
      captureEvent(e)

    @increaseChildren = (e) ->
      @children++ if @children < MAX_CHILDREN_PER_ROOM
      captureEvent(e)

    @numOfChildren = (numOfChildren) ->
      return [] if numOfChildren < 1
      return [1..numOfChildren]

    @updateDestination = (selectedCity) ->
      $timeout( =>
        @destination =
          label: selectedCity.term
          value:
            destination_id: selectedCity.id
            lat: selectedCity.lat
            lng: selectedCity.lng
            type: "city"
      , 10)

    @updateCities = (selectedCountry) ->
      @city = selectedCountry.cities[0]
      @updateDestination(@city)

    if AppSettings.limitedCountryCityOptions
      cityList = CityCountryBuilderService.cityList

      if cityList && $rootScope.destinationId
        @city = cityList.find (city) ->
          city.id == $rootScope.destinationId
        @country = @city.country

      if @city
        @updateDestination(@city)

    return # END

SearchForm
  .$inject = ['$rootScope', '$timeout', '$translate', '$analytics',
    'KaligoConfig', 'CookieService', 'AppSettings', 'CityCountryBuilderService']

angular.module('BookingApp').factory 'SearchForm', SearchForm
