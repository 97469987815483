import { HeaderUrlList } from "booking_app/services/header-url-list";
import { HeaderUrlLink } from "booking_app/constants/header-url-link";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { TravelType } from "booking_app/types";
import { TravelTypeService } from "booking_app/services/travel-type.service";
import { AppSettings } from "booking_app/values/app-settings";
export class SiteNavigationController {
  static $inject = [
    "HeaderUrlList",
    "GlobalStateService",
    "TravelTypeService",
    "AppSettings",
    "$scope",
    "$rootScope",
  ];

  constructor(
    private headerUrlList: HeaderUrlList,
    private globalStateService: GlobalStateService,
    private travelTypeService: TravelTypeService,
    private appSettings: AppSettings,
    private $scope: any,
    private $rootScope: any,
  ) {
    this.headerUrlList.setupUrlLink();
  }

  $onInit(): void {
    this.setupBackButtonListener();
    this.$scope.tenant = this.appSettings.tenant.toLowerCase();
  }

  headerList(): HeaderUrlLink[] {
    return this.headerUrlList.headerList;
  }

  customSelect(link): void {
    this.headerUrlList.updateActiveLink(link);
  }

  localeHeaderItem(): HeaderUrlLink {
    return this.headerUrlList.headerList.filter( item => item.contentType === "locale")[0];
  }

  currencyHeaderItem(): HeaderUrlLink {
    return this.headerUrlList.headerList.filter( item => item.contentType === "currency")[0];
  }

  currentHeaderItem(title: string): HeaderUrlLink {
    return this.headerUrlList.headerList.filter( item => item.title === title)[0];
  }

  removeActiveClass(content: string): void {
    const headerList = this.headerUrlList.headerList.filter( item => item.contentType === content)[0];
    if (headerList) {
      headerList.active = false;
    }
  }

  changeLocale(localeCode: string, event): void {
    this.removeActiveClass("locale");
    this.$rootScope.globalState.changeLocale(localeCode);
    event.stopPropagation();
    event.preventDefault();
  }

  changeCurrency(currencyCode: string, event: any): void {
    event.stopPropagation();
    event.preventDefault();

    this.removeActiveClass("currency");
    this.$rootScope.globalState.changeCurrency(currencyCode);
  }

  isUserAccountBlocked(): boolean {
    return this.$rootScope.userDetails.user.account_status === 'blocked';
  }

  private setupBackButtonListener(): void {
    this.$scope.$on("$routeChangeStart", (_, next, current) => {
      let travelType: TravelType = next.params.travelType;
      if (travelType && this.isSearchController(next)) {
        // cant use travelType constant here because its a TravelType:Type and
        // this complimentary nights is special case for this scenario
        if (next.params.travelType !== "complimentary_nights") {
          this.updateTravelType(travelType);
        }
      } else if (!travelType && this.isSearchController(next)) {
        this.updateTravelType(TravelType.HOTELS);
      } else if (!travelType &&
        (this.isSearchController(current) || this.isPageCtrl(current))
      ) {
        travelType = this.travelTypeService.getTravelTypeFromPath(next.originalPath);
        this.globalStateService.changeTravelType(travelType);
      }
    });
  }

  private isSearchController(route): boolean {
    return route.controller === "SearchCtrl";
  }

  private isPageCtrl(path: any): boolean {
    return path.originalPath.includes("company") || path.controller === "PageCtrl";
  }

  private updateTravelType(travelType: TravelType): void {
    if (!this.globalStateService.isTravelType(travelType)) {
      this.globalStateService.changeTravelTypeAndUrl(travelType);
    }
  }
}
