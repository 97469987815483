import { AngularUtils } from "booking_app/utils";
import { HotelsSearchService } from "booking_app/services/hotels";
import { AppSettings } from "booking_app/values/app-settings";
import { HotelsSearchResult } from "booking_app/models/hotels-location-search-result";
import { UserActionService } from "booking_app/services/user-action.service";
import { UserAction, UserActionType } from "booking_app/types/user-action";

const DEBOUNCE_TIMEOUT = 0;

interface LocationSearchObject {
  label: string;
  value: {
    destination_id: string;
    type: string;
    lat: number;
    lng: number;
    hotel_id?: string;
  };
  type?: string;
}

export class HotelLocationSearchController {
  static $inject = [
    "$translate",
    "$timeout",
    "$rootScope",
    "$analytics",
    "HotelsSearchService",
    "AppSettings",
    "UserActionService",
  ];

  // bindings
  label: string;
  value: any;
  showError: any;

  public searchStatusMsg: string;
  public internalValue: string;
  private fetchTimeout: any;
  private locations: LocationSearchObject[];
  private searchedLocations: LocationSearchObject[];
  private showSearchedLocations: boolean = true;

  constructor(
    private $translate: any,
    private $timeout: any,
    private $rootScope: any,
    private $analytics: any,
    private hotelsSearchService: HotelsSearchService,
    private appSettings: AppSettings,
    private userActionService: UserActionService,
  ) {
    this.initializeSearch();
    if (this.appSettings.enableUserActionTracking) {
      this.initializeSearchedLocations();
    }
  }

  $onChanges(changes: any) {
    if (changes?.value?.currentValue) {
      this.internalValue = changes.value.currentValue.label;
    }
  }

  onSelectCallback(location: LocationSearchObject): void {
    this.internalValue = location.label;
    this.$rootScope.searchState.destination = location;

    // when a dropdown option is selected, hide searchedLocations
    this.showSearchedLocations = false;
  }

  fetchHotelsLocations(query: string = "") {

    if (this.fetchTimeout) {
      this.$timeout.cancel(this.fetchTimeout);
      this.fetchTimeout = null;
    }
    query = query.toLowerCase().trim();
    this.handleAnalytics(query);
    this.fetchTimeout = this.$timeout(() => {
      this.handleSearchInProgress();
      this.hotelsSearchService.hotelsLocationSearch(query).then((resultsArr: HotelsSearchResult[]) => {
        if (unescape(encodeURIComponent(query)).length > 1) {
          if (resultsArr.length > 0) {
            this.handleSearchHasLocations(resultsArr);
          } else {
            this.handleSearchHasNoLocations();
          }
        } else {
          this.handleSearchHasNoLocations();
        }
      }).catch(() => {
        this.initializeSearch();
        this.showSearchedLocations = true;
      });
    }, DEBOUNCE_TIMEOUT);
  }

  hasNoLocation(): boolean {
    return this.locations.length === 0;
  }

  private initializeSearch(): void {
    if (this.$rootScope.searchState.destination) {
      this.internalValue = this.$rootScope.searchState.destination.label;
    } else if (this.$rootScope.destination) {
      this.internalValue = this.$rootScope.destination;
    }

    this.searchStatusMsg = this.$translate.instant("e.g. city or region");
    this.locations = [];

    if (this.appSettings.enableUserActionTracking) {
      this.handleUserSearchedLocations();
    }
  }

  private initializeSearchedLocations(): void {
    const recentDestinationSearchLimit: number = 3;
    this.userActionService.getUserAction(UserActionType.DESTINATION, recentDestinationSearchLimit)
      .then((resultsArr: UserAction[]) => {
      if (resultsArr.length > 0) {

        // v1 dropdown uses ui-select. however, ui-select-choices does not support custom headers.
        // workaround: we use ui-select-choices group-by feature to show the "previously searched" group header
        this.searchedLocations = resultsArr.map( (result) => ({
          ...this.mapLocation(result.custom_data),
          ...{type: "recent_searches"},
        }));
        this.locations = this.searchedLocations;
      }
    });
  }

  private handleUserSearchedLocations(): void {
    if (this.searchedLocations && this.showSearchedLocations) {
      this.locations = this.searchedLocations;
    }
  }

  private handleSearchInProgress(): void {
    this.searchStatusMsg = this.$translate.instant("Searching ...");
  }

  private handleSearchHasNoLocations(): void {
    this.searchStatusMsg = this.$translate.instant("No matching city or region");
    this.locations = [];
  }

  private handleSearchHasLocations(rawLocationsArray: HotelsSearchResult[]): void {
    rawLocationsArray = this.appSettings.blacklistedDestinations.length > 0 ?
      this.handleBlackListedDestinations(rawLocationsArray) : rawLocationsArray;
    this.locations = rawLocationsArray.map( (result: HotelsSearchResult) => ({
      ...this.mapLocation(result),
      ...{type: this.labelHotelOrDestination(result.type)},
    }));
    if (this.locations.length === 0) {
      this.handleSearchHasNoLocations();
    }
  }

  private mapLocation(result: HotelsSearchResult) {
    return {
      label: result.term,
      value: {
        destination_id: result.value,
        type: result.type,
        lat: result.lat,
        lng: result.lng,
        hotel_id: result.hotel_id,
      },
    };
  }

  private handleBlackListedDestinations(rawLocationsArray: HotelsSearchResult[]): HotelsSearchResult[] {
    const filteredDestinations: HotelsSearchResult[] = rawLocationsArray.filter(
      location => !this.appSettings.blacklistedDestinations.includes(location.value),
    );
    return filteredDestinations;
  }

  private handleAnalytics(query: string): void {
    if (query.length > 2) {
      this.$analytics.eventTrack("autosuggest_Destination", {
        category: "destination",
        label: query,
      });
    }
  }

  private labelHotelOrDestination(type: string): string {
    return type === "hotel" ? "Hotel" : "Destination";
  }
}
