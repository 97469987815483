import { HotelListItemController } from "./hotel-list-item.controller";
import { Bindings } from "booking_app/types";

export class HotelListItemComponent {
  bindings: any = {
    hotel: Bindings.ONE_WAY,
    showAdminData: Bindings.ONE_WAY,
    onToggleAdminData: Bindings.EXPRESSION,
  };
  controller: any = HotelListItemController;
  templateUrl: string = "/html/hotels/results_list_item";
}

angular.module("BookingApp").component("hotelListItem", new HotelListItemComponent());
