require('../services/bonuses-registration')
require('../services/users')
require('../services/facebook-pixel')
require('../services/utils')

angular.module('BookingApp')
.controller 'WelcomeBoxCtrl', [

  '$scope', '$rootScope', '$window', '$location', '$timeout', '$filter', '$modal',
  'BonusesRegistrationService', 'UsersService', 'FacebookPixelService', 'UtilsService'

  ($scope, $rootScope, $window, $location, $timeout, $filter, $modal,
  BonusesRegistrationService, UsersService, FacebookPixelService, UtilsService) ->

    displaysMarketingCheckbox = -> $('#bonus-marketing').length > 0

    autoCheckMarketingCheckbox = ->
      pagesWithAutoCheck = []
      $rootScope.landingPage.url in pagesWithAutoCheck

    # sendMarketing is set to true for landing pages which do not show checkbox
    # and for specified landing pages which show checkbox
    # in the latter case it is possible to uncheck it, in the former - not
    $scope.registerBonus = {
      firstName: null
      email: null
      sendMarketing: !displaysMarketingCheckbox() || autoCheckMarketingCheckbox()
      form: null
      loading: false
    }

    referralCode = UtilsService.getReferralCode($location)

    $scope.goToSignup = () ->
      $window.location.href = "/users/sign_up"

    $scope.closeBox = ($event) ->
      if $event
        $event.stopPropagation()
        $event.preventDefault()
      $rootScope.showWelcomeBox = false
      return

    $scope.isLoading = false

    $scope.submitBonus = (closeForm = false) ->
      $scope.registerBonusForm.submitted = true

      return if $scope.isLoading
      return if !$scope.registerBonusForm.$valid || $scope.registerBonusForm.validSubmission

      $scope.registerBonusForm.validSubmission = true
      $scope.isLoading = true

      registerBonusCall = BonusesRegistrationService.registerBonus(
        $rootScope.landingPage.url,
        $scope.registerBonus.firstName,
        $scope.registerBonus.email,
        $scope.registerBonus.sendMarketing,
        referralCode
      )

      registerBonusCall.then ((resolveResult) ->
        $scope.isLoading = false
        $scope.registerBonus.success = true
        $scope.registerBonus.registeredBonuses = resolveResult
        $rootScope.showEmilesPixel = true
        $scope.closeBox() if closeForm

        UsersService(true).then (result) ->
          FacebookPixelService.eventTrack('Lead', {
            content_category: "LandingPage",
            content_name: $rootScope.landingPage.url
          })
          $rootScope.userDetails = result #login user

      ), ((rejectResult) ->
        $scope.isLoading = false
        $scope.registerBonus.success = false
        $scope.registerBonus.error = true
      )

    showRegisterSuccessModal = () ->
      modalInstance = $modal.open({
        animation: true
        templateUrl: '/html/modals/one_option_modal'
        controller: 'OneOptionModal'
        size: "sm"
        backdrop: 'static'
        resolve: {
          modalHeader: () ->
            return ""
          modalBody: () ->
            return $filter('translate')("Congratulations! You've successfully signed up")
          modalYes: () ->
            return $filter('translate')("Ok")
        }
      })

      modalInstance.result.then(
        () ->
          $rootScope.showWelcomeBox = false
          $scope.registerBonus.success = true
        , () ->
          $rootScope.showWelcomeBox = false
          $scope.registerBonus.success = true
      )

    $scope.registerForBonus = () ->
      $scope.registerBonus.form.submitted = true

      return if $scope.registerBonus.form.$invalid
      return if $scope.registerBonus.loading

      $scope.registerBonus.loading = true

      registerBonusCall = BonusesRegistrationService.registerBonus(
        $rootScope.landingPage.url,
        $scope.registerBonus.firstName,
        $scope.registerBonus.email,
        $scope.registerBonus.sendMarketing,
        referralCode
      )

      registerBonusCall.then ((resolveResult) ->
        $scope.registerBonus.loading = false
        $scope.registerBonus.registeredBonuses = resolveResult

        # DLP present
        if $rootScope.landingPage.url != "" && $scope.registerBonus.registeredBonuses.length == 0
          $scope.registerBonus.success = false
          $scope.registerBonus.error = true
        else
          # just show the success message and auto close it after 3 seconds
          $scope.registerBonus.success = true
          $timeout ()->
            $rootScope.showWelcomeBox = false
          , 3000

        # Always perform no matter what
        FacebookPixelService.eventTrack('Lead', {
          content_category: "LandingPage",
          content_name: $rootScope.landingPage.url
        })

        UsersService(true).then (result) ->
          $rootScope.userDetails = result #login user

      ), ((rejectResult) ->
        $scope.registerBonus.loading = false
        $scope.registerBonus.success = false
        $scope.registerBonus.error = true
      )
]
