import { BonusSignUpWidgetController } from "./bonus-sign-up-widget.controller";
import { Bindings } from "booking_app/types";

export class BonusSignUpWidgetComponent {
  controller: any = BonusSignUpWidgetController;
  templateUrl: string = "/html/components/bonus_sign_up_widget";
  bindings: any = {
    registerAction: Bindings.EXPRESSION,
    userDetails: Bindings.ONE_WAY,
    successMessageForNotLoggedUser: Bindings.ONE_WAY,
    headerTitle: Bindings.ONE_WAY,
    hasLoggedUser: Bindings.ONE_WAY,
    closeBox: Bindings.EXPRESSION,
    enableWelcomeBox: Bindings.EXPRESSION,
  };
}

angular.module("BookingApp").component("bonusSignUpWidget", new BonusSignUpWidgetComponent());
