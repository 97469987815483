import { AddressFormController } from "./address-form.controller";
import { Bindings } from "booking_app/types";

export class AddressFormComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      data: Bindings.ONE_WAY,
      onUpdate: Bindings.EXPRESSION,
      parentForm: Bindings.ONE_WAY,
      formName: Bindings.ONE_WAY,
      submitted: Bindings.ONE_WAY,
      isAdyenCard: Bindings.ONE_WAY,
    };
    this.controller = AddressFormController;
    this.templateUrl = '/html/components/payment_detail_form_address_form';
  }
}

angular.module("BookingApp").component("addressFormComponent", new AddressFormComponent());
