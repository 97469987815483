require('../../services/utils')
require('../../services/search-url')
require('../../services/global-state.service')
require('../../values/app-settings')

HotelsSearchBarCtrl = ($scope, $rootScope, $location, $modal,
UtilsService, SearchUrlService, GlobalStateService, AppSettings) ->

  $scope.globalStateService = GlobalStateService

  formatDate = () ->
    return UtilsService.formatSelectedDates(
      $scope
      $rootScope.checkInDate
      $rootScope.checkOutDate
    )

  $scope.inResultPage = (GlobalStateService.currentPage == "search-result")

  closeEditModal = () -> $rootScope.globalState.editModalOpen = false

  $scope.editSearch = ->
    return if $rootScope.globalState.editModalOpen
    $rootScope.showWelcomeBox = false
    $rootScope.globalState.isNewSearch = false
    $rootScope.globalState.editModalOpen = true
    modalInstance = $modal.open({
      animation: true
      backdrop: 'static'
      backdropClass: 'hotels-search-backdrop'
      templateUrl: '/html/modals/hotels_search'
      size: "sm"
      controller: 'EditSearchCtrl'
      windowClass: 'edit-search-popup'
    })
    modalInstance.result.then(closeEditModal, closeEditModal)
    return

  $scope.childrenCount = UtilsService.getchildrenCount($rootScope.guests)

  $scope.redirectToSearchResultsPage = () ->
    $location.url(SearchUrlService.createBackToSearchUrl($scope))

  $scope.$watch "selectedLocale", (newValue, oldValue) ->
    return formatDate()

  $scope.guestCount = $scope.adultCount + $scope.childrenCount

  $scope.getTenant = ->
    return AppSettings.tenant.toLowerCase()

  return

HotelsSearchBarCtrl
  .$inject = [
    '$scope', '$rootScope', '$location', '$modal',
    'UtilsService', 'SearchUrlService', "GlobalStateService", "AppSettings"
  ]

angular.module('BookingApp')
  .controller 'HotelsSearchBarCtrl', HotelsSearchBarCtrl
