import { TravelType } from "../../../types/travel-type";
import { ProductType } from "booking_app/types";

export class LandingPageInfoController {
  // Bindings
  travelType: TravelType;
  folderName: string;
  hasCustomEarnRedeemIcon: boolean;
  hasCustomSearchIcon: boolean;

  earnRedeemIcon: string;
  searchIcon: string;
  productType: ProductType;

  public $onInit(): void {
    if (this.hasCustomEarnRedeemIcon) {
      this.earnRedeemIcon = `${this.travelType}-earn-redeem`;
    } else {
      this.earnRedeemIcon = "earn-redeem";
    }

    if (this.hasCustomSearchIcon) {
      this.searchIcon = `${this.travelType}-search`;
    } else {
      this.searchIcon = "search";
    }
  }

  public searchHeader(): string {
    const travelType = this.travelType.charAt(0).toUpperCase() + this.travelType.slice(1);
    return `Search ${travelType}`;
  }

  public searchDescription(): string {
    return `wl.${this.folderName}.search_description_${this.travelType}`;
  }

  public earnRedeemHeader(): string {
    if (this.travelType === TravelType.HOTELS && this.productType === ProductType.REDEEM ) {
      return `wl.${this.folderName}.redeem_miles_${this.travelType}`;
    }
    return `wl.${this.folderName}.earn_redeem_miles_${this.travelType}`;
  }

  public earnRedeemDescription(): string {
    return `wl.${this.folderName}.earn_redeem_miles_description_${this.travelType}`;
  }

  public enjoyHeader(): string {
    return `wl.${this.folderName}.enjoy_your_trip_${this.travelType}`;
  }

  public enjoyDescription(): string {
    return `wl.${this.folderName}.enjoy_your_trip_description_${this.travelType}`;
  }

  public enjoyIcon(): string {
    return `${this.travelType}-enjoy`;
  }
}
