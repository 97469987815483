import { Bindings } from "booking_app/types";
import { HotelRegionSearchController } from "./hotel-region-search.controller";
import "booking_app/directives/ui-select-destination-search";

export class HotelRegionSearchComponent {
  bindings: any = {
    label: Bindings.ONE_WAY,
    value: Bindings.ONE_WAY,
    list: Bindings.ONE_WAY,
    isCity: Bindings.ONE_WAY,
    message: Bindings.ONE_WAY,
    showError: Bindings.EXPRESSION,
  };
  controller: any = HotelRegionSearchController;

  template: string = `
    <ui-select-destination-search
      close-on-blur
      aria-hide-on-blur
      ng-model="$ctrl.internalValue"  
      theme="bootstrap"
      ng-class="{'invalid': $ctrl.showError()}"
      ng-disabled="!$ctrl.list.length"
      on-select="$ctrl.onSelectCallback($item, $ctrl.isCity, $model)">
      <ui-select-match
        aria-hide-on-empty
        placeholder="{{ $ctrl.label | translate }}"
        aria-label="{{$ctrl.internalValue}}"
        role="text">{{$ctrl.internalValue}}
      </ui-select-match>
      <ui-select-choices repeat="country in $ctrl.list | filter: { name: $select.search }">
        <span>
          {{country.name}}
        </span>
      </ui-select-choices>
      <ul
        class="ui-select-no-choice dropdown-menu"
        ng-show="$ctrl.hasNoLocation($ctrl.list, $select.search, $ctrl.message)"
        aria-hidden="{{ $select.open ? 'false' : 'true' }}">
        <li>
          {{ $ctrl.searchStatusMsg }}
        </li>
      </ul>
    </ui-select-destination-search>
    <span class="input-icon" ng-click="$event.preventDefault();"></span>
    <div class="dirtyMsg tooltips" ng-show="$ctrl.showError()"  aria-live="assertive">
      <span role="alert">{{ "wl.please_fill_in_a_destination" | translate }}</span>
    </div>
  `;
}

angular.module("BookingApp").component("hotelRegionSearch", new HotelRegionSearchComponent());
