import { ApiDataService } from "./api-data.service";
import { AppSettings } from "booking_app/values/app-settings";

interface UserData {
  uid: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  rewards_program?: string;
}

export class AlternateRewardSchemeService {

  static $inject = [
    "$rootScope",
    "AppSettings",
    "ApiDataService",
    "$window",
    "$q",
  ];

  constructor(
    private $rootScope: any,
    private appSettings: AppSettings,
    private apiDataService: ApiDataService,
    private $window: any,
    private $q: any,
  ) { }

  public perform(): ng.IPromise<void> {
    const defer = this.$q.defer();
    if (!this.checkAlternateScheme()) {
      defer.resolve();
      return defer.promise;
    }

    this.apiDataService.get(this.appSettings.alternateSchemeSettings.userCustomDataEndpoint, false)
      .then((data: UserData) => {
        if (data.rewards_program === this.appSettings.alternateSchemeSettings.redirectScheme) {
          this.$window.location.href = this.appSettings.alternateSchemeSettings.redirectSchemeUrl;
          defer.reject();
        } else {
          defer.resolve();
        }
      })
      .catch((_error) => {
        defer.resolve();
      });
    return defer.promise;
  }

  private checkAlternateScheme(): boolean {
    return this.$rootScope.isUserLoggedIn() && this.appSettings.alternateSchemeSettings.checkAlternateScheme;
  }
}

angular.module("BookingApp").service("AlternateRewardSchemeService", AlternateRewardSchemeService);
