import { DateTimeFieldMobileController } from "./date-time-field-mobile.controller";
import { Bindings } from "booking_app/types";

export class DateTimeFieldMobileComponent {
  bindings: any = {
    date: Bindings.ONE_WAY,
    onUpdateDate: Bindings.EXPRESSION,
    minDate: Bindings.ONE_WAY,
    time: Bindings.ONE_WAY,
    onUpdateTime: Bindings.EXPRESSION,
    dateFormat: Bindings.ONE_WAY,
    dateLabelKey: Bindings.ONE_WAY,
    timeLabelKey: Bindings.ONE_WAY,
    locale: Bindings.ONE_WAY,
    showTimeOnly: Bindings.ONE_WAY,
  };
  controller: any = DateTimeFieldMobileController;
  transclude: any = {
    iconContent: "?iconContent",
  };
  template: string = `
    <div class="date-time-field-mobile">

      <div class="date-time-field-mobile-input">
      <span ng-click="$ctrl.beginSelection()" ng-transclude="iconContent"></span>
        <input
          class="field-input"
          type="text"
          readonly
          ng-value="$ctrl.dateTimeSummary()"
          ng-focus="$ctrl.beginSelection()"
        >
      </div>

      <form-popup-mobile ng-if="$ctrl.modalOpen">
        <title-content>
          <button
            type="button"
            class="fa fa-close close-tick"
            ng-click="$ctrl.closeModal()">
          </button>
          <div ng-class="$ctrl.dateFieldClass()">
            <div class="info-field-label">{{ $ctrl.dateLabelKey | translate }}</div>
            <div class="info-field-box">{{ $ctrl.formattedPopupDate() }}</div>
          </div>
          <div ng-class="$ctrl.timeFieldClass()">
            <div class="info-field-label">{{ $ctrl.timeLabelKey | translate }}</div>
            <select
              class="info-field-box"
              ng-model="$ctrl.internalTime"
              ng-change="$ctrl.updateTime()"
              ng-focus="$ctrl.onTimeSelectionBegin()"
            >
              <option
                ng-repeat="option in $ctrl.timesList"
                ng-value="option.value"
                ng-bind="option.display">
              </option>
            </select>
          </div>
        </title-content>

        <body-content>
          <div class="datepicker-container">
            <date-picker
              min-date="$ctrl.minDate"
              start-date="$ctrl.date"
              on-select-start-date="$ctrl.updateDate(value)"
              selection-state="'DATE'"
              locale="$ctrl.locale"
            ></date-picker>
            <button
              type="button"
              class="btn-action"
              ng-click="$ctrl.closeModal()">
              {{ "button.done" | translate }}
            </button>
          </div>
        </body-content>
      </form-popup-mobile>
    </div>
  `;
}

angular.module("BookingApp").component("dateTimeFieldMobile", new DateTimeFieldMobileComponent());
