import { CountrySelectItem } from "booking_app/types";
import { CheckoutFormService } from "booking_app/services/checkout/checkout-form.service";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { TravelType } from "booking_app/types";

export class PaginatedDropdownController {
  static $inject = ["CheckoutFormService", "GlobalStateService", "$timeout", "$element"];

  private currentElement: number;

  private ngModelController: any;
  private ngModel: any; // ngModel value is the the selected item in the list
  private items: CountrySelectItem[]; // ngModel should be of type CountrySelectItem {id, code, text}
  private name: string;
  private elementId: string;
  private elementClass: string;
  private material: boolean;
  private formControl: any;
  private label: string = "Country code";
  private onUpdate: (obj: { value: any }) => void;
  private formatValue: (obj) => any;

  constructor(
    private checkoutFormService: CheckoutFormService,
    private globalStateService: GlobalStateService,
    private $timeout: any,
    private $element: any,
  ) { }

  $onInit(): void {
    if (this.ngModel && this.ngModel.id !== null) {
      this.toggleMaterialContainerClass();
    }

    // Transform ngModel format --> for case where there is a pre-defined item selected already, it will highlight selected item directly
    if (this.ngModel && this.ngModel.id) {

      // Update ngModel with the new format
      this.ngModel = this.items.find((item) => item.text === this.ngModel.text);

      // Update the view value
      if (this.ngModelController) {
        this.ngModelController.$setViewValue(this.ngModel);
        this.ngModelController.$render();
      }

      // Call ngModelChange to ensure any side effects are triggered
      this.ngModelChange();
    }
  }

  public setActiveIndex($select): void {
    this.$timeout(() => {
      if (this.ngModel === undefined || $select.selected === undefined) {
        $select.activeIndex = -1000; // can be anything < 0, tried with -1 and -1000, turns out -1000 works better
      }
    }, 15);

  }

  public resetElementCount($select): void {
    this.setActiveIndex($select);
    if (this.material) {
      const inputSearchField = this.$element.find(".ui-select-search")[0];
      inputSearchField.addEventListener("focus", (e) => {
        const target = e.target;
        target.value = "";
        target.dispatchEvent(new Event("change"));
      });
      if (this.ngModel) { this.toggleMaterialContainerClass(1); }
    }
  }

  public ngModelChange(): void {
    this.ngModelController.$setViewValue(this.ngModel);

    const formattedValue: {} = this.formatValue({ value: this.ngModel });
    if (formattedValue) {
      this.ngModel = formattedValue;
    }

    if (this.onUpdate) {
      this.onUpdate({ value: this.ngModel });
    }

    if (this.material) {
      this.toggleMaterialContainerClass(100);
    }
  }

  public checkoutForm(): any {
    switch (this.globalStateService.travelType) {
      case TravelType.HOTELS:
        return this.checkoutFormService.checkoutFormScope.checkOutForm;
      case TravelType.FLIGHTS:
        return this.formControl || {};
      case TravelType.CARS:
        return this.formControl || {};
    }
  }

  public hasValue(): boolean {
    return this.ngModel && (this.ngModel.value || this.ngModel.id !== null);
  }

  public selectIsOpen(): boolean {
    return this.materialContainer().getElementsByClassName("open").length > 0;
  }

  private toggleMaterialContainerClass(timeout: number = 300): void {
    this.$timeout(() => {
      if (this.hasValue()) {
        this.materialContainer()?.classList.add("md-input-has-value");
      }
    }, timeout);
  }

  private materialContainer(): any {
    return document.getElementById(`material-${ this.elementId }-container`);
  }
}
