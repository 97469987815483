import { PhoneCountryCodeSelectOption } from "./phone-country-code-select-option";
import { AngularUtils } from "booking_app/utils";
import { CountryService } from "booking_app/services/country.service";
import { SortService } from "booking_app/services/sort.service";

export class PhoneCountryCodeSelectController {
  static $inject = ["CountryService", "SortService"];

  // bindings
  value: PhoneCountryCodeSelectOption;
  formName: string;
  onUpdate: (obj: { value: PhoneCountryCodeSelectOption }) => void;

  internalValue: PhoneCountryCodeSelectOption;
  options: PhoneCountryCodeSelectOption[];

  constructor(
    private countryService: CountryService,
    private sortService: SortService,
  ) {
    this.options = [];
  }

  $onInit() {
    this.options = this.initOptions();
  }

  $onChanges(changesObj): void {
    if (AngularUtils.hasBindingChanged(changesObj.value)) {
      if (this.value.id === null) {
        this.internalValue = null;
      } else {
        this.internalValue = angular.copy(this.value);
      }
    }
  }

  displayOptions(option: PhoneCountryCodeSelectOption) {
    return option && option.text;
  }

  onUpdateInternalValue(value: PhoneCountryCodeSelectOption) {
    this.internalValue = angular.copy(value);
    this.onUpdate({ value: this.internalValue });
  }

  private initOptions(): PhoneCountryCodeSelectOption[] {
    const options = this.countryService.getCountries().map(item => ({
      id: `(+${item.phoneCode})`,
      text: `${item.name} (+${item.phoneCode})`,
    }));

    return this.sortService.string(options, "text");
  }
}
