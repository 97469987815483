import { PaymentMethodController } from "./payment-method.controller";
import { Bindings } from "booking_app/types/bindings";

export class PaymentMethodComponent {
  bindings: any = {
    removeCreditCard: Bindings.EXPRESSION,
  };
  controller: any = PaymentMethodController;
  templateUrl: string = "/html/components/payment_method";
}

angular.module("BookingApp").component("paymentMethod", new PaymentMethodComponent());
