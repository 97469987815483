require('../services/country.service')
require('../services/sort.service')

angular.module('BookingApp')
.controller 'AccProfileCtrl', [

  "$scope", "$rootScope", "$window", "$location", "$timeout", "$translate"
  "CountryService", "KaligoUser", 'SortService'

  ($scope, $rootScope, $window, $location, $timeout, $translate
  CountryService, KaligoUser, SortService) ->

    $scope.pageState =
      isEditMode: false
      isEditPasswordMode: false
      isUpdateSuccess: null
      userProfile: {}
      userPassword: {}
      formErrorMessages: defaultFormErrorMessage

    UserAPI = new KaligoUser()
    defaultFormErrorMessage = $translate.instant("There was an error updating your profile. Please try again.")

    $scope.constants = {
      days: [1..31]
      months: [
          { id: 1, name: 'January' },
          { id: 2, name: 'February' },
          { id: 3, name: 'March' },
          { id: 4, name: 'April' },
          { id: 5, name: 'May' },
          { id: 6, name: 'June' },
          { id: 7, name: 'July' },
          { id: 8, name: 'August' },
          { id: 9, name: 'September' },
          { id: 10, name: 'October' },
          { id: 11, name: 'November' },
          { id: 12, name: 'December' }
        ]
      years: [(new Date().getFullYear() - 16)..(new Date().getFullYear() - 100)]
      titles: [
        "Mr",
        "Ms",
        "Mrs"
      ]
    }

    updateUserState = (countryCode, stateCode) ->
      if countryCode? && stateCode?
        CountryService.getState(countryCode, stateCode)
          .then (state) ->
            $scope.userState = state
            $scope.selectedState = state
          , (error) ->
            $scope.userState = null
      else
        $scope.userState = null

    $scope.userCountry = CountryService.getCountry($rootScope.userDetails.user.country)

    updateUserState($rootScope.userDetails.user.country, $rootScope.userDetails.user.state)

    $scope.isStateRequired = false
    checkStateRequired = () ->
      selectedCountry = $scope.pageState.userProfile.country
      if $scope.pageState.isEditMode
        $scope.isStateRequired =
          CountryService.isStateRequired(selectedCountry)
      else
        $scope.isStateRequired =
          CountryService.isStateRequired($rootScope.userDetails.user.country)

    $scope.$watch "pageState.userProfile.country", (newCountry, old) ->
      if newCountry? && $scope.pageState.userProfile.state?
        $scope.pageState.userProfile.state = null
      checkStateRequired()

    toggleEdit = () ->
      $scope.pageState.isEditMode = !$scope.pageState.isEditMode
      if $scope.pageState.isEditMode
        $scope.pageState.userProfile = angular.copy($rootScope.userDetails.user)
        $scope.pageState.userProfile.country = $scope.userCountry.code if $scope.userCountry
        $scope.selectedCountry = $scope.userCountry if $scope.userCountry

    alertMessageResetAsync = () ->
      $timeout () ->
        $scope.pageState.isUpdateSuccess = null
        $scope.pageState.formErrorMessages = defaultFormErrorMessage
      , 5000

    updateProfile = () ->
      UserAPI.update($scope.pageState.userProfile)
        .then (res) ->
          $rootScope.userDetails = UserAPI.format(res)
          $scope.pageState.isUpdateSuccess = true
          $scope.pageState.isEditMode = false
          alertMessageResetAsync()
          $scope.userCountry = CountryService.getCountry($rootScope.userDetails.user.country)
          updateUserState($rootScope.userDetails.user.country, $rootScope.userDetails.user.state)
        , (res) ->
          $scope.pageState.isUpdateSuccess = false
          alertMessageResetAsync()

    $scope.editProfile = ()->
      toggleEdit()

    $scope.submitProfile = () ->
      if $scope.pageState.userProfile.state
        $scope.pageState.userProfile.state =
          $scope.pageState.userProfile.state.code
      updateProfile()

    $scope.onUpdateCountry = (country) ->
      $scope.pageState.userProfile.country = country.code
      $scope.selectedCountry = country
      $scope.selectedState = {}

    $scope.stateFormatter = (state) ->
      if state
        return state.name

    $scope.onUpdateState = (state) ->
      $scope.userState = state
      $scope.pageState.userProfile.state = state
      $scope.selectedState = state

    $scope.changePassword = () ->
      $scope.pageState.isEditPasswordMode = !$scope.pageState.isEditPasswordMode
      $scope.pageState.userPassword =
        current_password: ""
        new_password: ""
        new_password_confirmation: ""
      toggleEdit() if $scope.pageState.isEditMode

    $scope.cancelChangePassword = () ->
      $scope.pageState.isEditPasswordMode = false

    $scope.submitMarketingPreferences = () ->
      $scope.pageState.userProfile = angular.copy($rootScope.userDetails.user)
      UserAPI.update($scope.pageState.userProfile)
        .then (res) ->
          $rootScope.userDetails = UserAPI.format(res)
          $scope.pageState.isUpdateSuccess = true
          alertMessageResetAsync()
        , (res) ->
          $scope.pageState.isUpdateSuccess = false
          alertMessageResetAsync()

    $scope.submitPassword = () ->
      # check if the new password and the confirm password match first
      newPassword = $scope.pageState.userPassword.new_password
      newPasswordConfirmation = $scope.pageState.userPassword.new_password_confirmation

      isStrongPassword = /^(?=.*[A-Z])(?=.*[\W_])(?=.*[0-9])(?=.*[a-z]).{8,}$/.test(newPassword)
      $scope.changePasswordForm.new_password.$setValidity("weakPassword", isStrongPassword)

      isMatch = newPassword == newPasswordConfirmation
      $scope.changePasswordForm.new_password_confirmation.$setValidity("mismatch", isMatch)

      # return if there is any error in the form (this include mismatch check)
      return if $scope.changePasswordForm.$invalid

      UserAPI.updatePassword($scope.pageState.userPassword)
        .then (res) ->
            $scope.pageState.isEditPasswordMode = false
            $scope.pageState.isUpdateSuccess = true
            alertMessageResetAsync()
          , (err) ->
            $scope.pageState.isUpdateSuccess = false
            $scope.pageState.formErrorMessages = $translate.instant(err.errors.join(", "))
            alertMessageResetAsync()
      return

    checkStateRequired()
]
