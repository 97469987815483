import { Bindings } from "booking_app/types";
import { MastheadDescriptionController } from "./masthead-description.controller";

export class MastheadDescriptionComponent {
  bindings: any = {
    travelType: Bindings.ONE_WAY,
    whitelabel: Bindings.ONE_WAY,
    productType: Bindings.ONE_WAY,
    hasCustomHeaderText: Bindings.ONE_WAY,
  };

  controller: any = MastheadDescriptionController;
  template: string = `
    <div class="overlay-bg overlay-{{$ctrl.travelType}}"></div>
    <div class="max-size {{ $ctrl.travelType }}">
     <h1 class="header-text" translate="{{ $ctrl.header() }}"></h1>
     <div class="description-text" translate="{{ $ctrl.description() }}"></div>
    </div>
  `;
}

angular.module("BookingApp").component("mastheadDescription", new MastheadDescriptionComponent());
