import { Modal, ModalOptions } from "booking_app/types";
import { modalSettings } from "booking_app/constants/modal-settings";

export class SimpleModalService {

  static $inject = ["$modal"];

  private modalInstance: any;

  constructor(private $modal: any) {}

  open(modalId: Modal): ng.IPromise<{}> {
    // Ensure only 1 modal is open at the same time
    this.modalInstance?.dismiss();
    this.modalInstance = this.$modal.open(this.loadModalInput(modalId));
    return this.modalInstance.result;
  }

  private loadModalInput(modalId: Modal): any {
    const modalOptions: ModalOptions = modalSettings[modalId];

    const modalInput: any = {
      animation: true,
      controller: "SimpleModalController as $ctrl",
      windowClass: modalOptions.windowClass ? modalOptions.windowClass : modalId,
      backdrop: modalOptions.backdrop ? modalOptions.backdrop : true,
      backdropClass: modalOptions.backdropClass ? modalOptions.backdropClass : "",
      size: modalOptions.size ? modalOptions.size : "",
      keyboard: modalOptions.keyboard ? modalOptions.keyboard : true,
      template: `
        <div class="modal-header" modal-accessibility-lock="">
          <div class="error-icon" ng-if="$ctrl.modalIconCDNPath">
            <img cdn-path="{{ $ctrl.modalIconCDNPath }}">
          </div>
          <div class="modal-title" ng-if="$ctrl.modalHeader" translate="{{ $ctrl.modalHeader }}"></div>
        </div>
        <div class="modal-closer" ng-if="$ctrl.showModalClose" aria-label="Close">
          <a ng-click="$ctrl.rejectModal()">
            <span class="close-text" translate="close"></span>
            <span class="x-text">✕</span>
          </a>
        </div>
        <div class="modal-body" ng-if="$ctrl.modalBody">
          <div ng-bind-html="$ctrl.modalBody | translate"></div>
          <div class="name" ng-bind-html="$ctrl.impersonatedModalBody | translate" ng-if="$ctrl.showImpersonatedModal"></div>
        </div>
        <div class="modal-footer" modal-accessibility-lock="">
          <button class="btn btn-action"
                  ng-click="$ctrl.resolveModal()"
                  translate="{{ $ctrl.resolveButtonText }}"
                  ng-if="$ctrl.resolveButtonText"></button>
          <button class="btn btn-cancel"
                  ng-click="$ctrl.rejectModal()"
                  translate="{{ $ctrl.rejectButtonText }}"
                  ng-if="$ctrl.rejectButtonText"></button>
        </div>
      `,
      resolve: {
        modalIconCDNPath: (): string => modalOptions.content.modalIconCDNPath,
        modalHeader: (): string => modalOptions.content.modalHeader,
        showModalClose: (): boolean => modalOptions.content.showModalClose,
        modalBody: (): string => modalOptions.content.modalBody,
        rejectButtonText: (): string => modalOptions.content.rejectButtonText,
        resolveButtonText: (): string => modalOptions.content.resolveButtonText,
        modalId: (): string => modalId,
      },
    };
    modalInput.windowClass = "simple-modal " + modalInput.windowClass;

    return modalInput;
  }
}

angular.module("BookingApp").service("SimpleModalService", SimpleModalService);
