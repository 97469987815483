export enum PaymentChannel {
  ADYEN = "adyen",
  ADYEN_ALIPAY = "adyen_alipay",
  ADYEN_DINERS = "adyen_diners",
  ADYEN_JCB = "adyen_jcb",
  ADYEN_UNIONPAY = "adyen_unionpay",
  ALIPAY = "alipay",
  CHECKOUT_COM = "checkout_com",
  CREDIT = "credit",
  CREDIT_CARD = "credit_card",
  FREE_NIGHT_VOUCHER = "free_night_voucher",
  BRAINTREE = "braintree",
  PAY_ANYONE = "pay_anyone",
  STRIPE = "stripe",
  STRIPE_PAYMENT_INTENTS = "stripe_payment_intents",
}
