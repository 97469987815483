require('../values/app-settings')

angular.module('BookingApp')

.controller 'AccountNavCtrl', [

  "$scope", "$location", "$rootScope", "AppSettings"
  ($scope, $location, $rootScope, AppSettings) ->

    $scope.activePath = (path) ->
      if $location.path() == path then "active" else ""

]
