import { Bindings } from "booking_app/types";
import { HotelResultMapMobileController } from "./result-map-mobile.controller";

export class HotelResultMapMobileComponent {
  controller: any = HotelResultMapMobileController;
  template: string = `
    <div class="hotel-result-map-mobile">
      <button class="hotel-result-map-mobile-button" ng-click="$ctrl.openModal()">
        <span ng-if="mapIconSource">
          <img ng-src="{{ $ctrl.$rootScope.const.cdnImageUrl }}/{{ mapIconSource }}" alt="">
        </span>
        <div class="map-label">{{ "Map" | translate }}</div>
      </button>
      <form-popup-mobile
        ng-if="$ctrl.mapOpenerService.modalOpen"
        max-modal-width="$ctrl.maxModalWidth"
        close-modal="$ctrl.closeModal()">
        <title-content>
          <div class="map-title">
            <span>{{ "hotel_result_map_mobile.title" | translate }}</span>
          </div>
          <button class="fa fa-close close-tick" ng-click="$ctrl.closeModal()"
          aria-label="Close"
          modal-accessibility-lock="title-content"></button>
        </title-content>
        <body-content>
          <div class="max-size" id="resultMapContainer">
            <div id="mapbox-search-map-mobile"></div>
          </div>
        </body-content>
      </form-popup-mobile>
    </div>
  `;
}

angular.module("BookingApp").component("hotelResultMapMobile", new HotelResultMapMobileComponent());
