import { CountryService } from "booking_app/services/country.service";
import { Country } from "booking_app/constants/countries";
import { DateFormObject } from "booking_app/components/flights/checkout/date-form-object";
import { PhoneDetailsFormObject } from "booking_app/components/flights/checkout/phone-details-form-object";
import { TravelType } from "booking_app/types";
import { GlobalStateService } from "booking_app/services/global-state.service";

declare var moment: any;

interface PopulatedFields {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  passportNationality: any;
  dateOfBirth: DateFormObject;
  street: string;
  city: string;
  postalCode: string;
  rememberMe: boolean;
}

export class PopulateCheckoutService {
  static $inject = [
    "$rootScope",
    "CountryService",
    "GlobalStateService",
  ];

  user: any;
  countries: Country[];

  constructor(
    private $rootScope: any,
    private countryService: CountryService,
    private globalStateService: GlobalStateService,
  ) {
    this.user = this.$rootScope.userDetails.user;
    this.countries = this.countryService.getCountries();
  }

  populateFields(field): PopulatedFields {
    if (this.user) {
      field.title = this.rebuildTitle(this.user.title);
      field.firstName = this.user.first_name;
      field.lastName = this.user.last_name;
      field.email = this.user.email;

      if (this.user.telephone) {
        field.phoneNumber = this.buildPhoneNumber(this.user.telephone);
        field.phoneCode = this.buildPhoneCode(this.user.telephone);
      }

      switch (this.globalStateService.travelType) {
        case TravelType.FLIGHTS:
          field = this.buildFormDataForFlights(field);
          break;
        case TravelType.HOTELS:
          field = this.buildFormDataForHotels(field);
          break;
      }
    }
    return field;
  }

  buildFlightDetailsForm(): PhoneDetailsFormObject {
    if (this.user.telephone) {
      const telephone = this.buildPhoneNumber(this.user.telephone);
      const code = this.buildPhoneCode(this.user.telephone);
      return { countryCode: code, number: +telephone };
    }
  }

  getEmail(): string {
    if (this.user) {
      return this.user.email;
    }
  }

  populatePassportDetails(field): PopulatedFields {
    const passportNumber = this.user.passport_number;

    if (passportNumber) {
      field.passportNumber = passportNumber;
    }

    if (this.user.passport_expiry) {
      const expiryDate = moment(this.user.passport_expiry, "YYYY-MM-DD");
      const expiryDay = expiryDate.date();
      const expiryYear = expiryDate.year();
      const expiryMonth = expiryDate.month() + 1;
      if (!!expiryDay && !!expiryYear && !!expiryMonth) {
        field.passportExpiryDate.day = { value: expiryDay, text: this.padStart(expiryDay) };
        field.passportExpiryDate.year = { value: expiryYear, text: this.padStart(expiryYear) };
        field.passportExpiryDate.month = { value: expiryMonth, text: this.padStart(expiryMonth) };
      }
    }

    return field;
  }

  private buildFormDataForHotels(field): PopulatedFields {
    field.street = this.user.address1;
    field.city = this.user.city;
    field.postalCode = this.user.zip_code;
    return field;
  }

  private buildPhoneNumber(telephone): string {
    return telephone.split(" ")[1];
  }

  private buildPhoneCode(telephone): { id: string, text: string } {
    const countryCode = telephone.split(" ")[0].replace(/[^\w\s]/gi, "");
    const countryValue = this.countries.find(o => o.phoneCode === parseInt(countryCode, 0));
    if (countryValue) {
      return {
        text: `${countryValue.name} (+${countryCode})`,
        id: `(+${countryValue.phoneCode})`,
      };
    } else {
      return { text: null, id: null };
    }
  }

  private buildFormDataForFlights(field): PopulatedFields {
    const day = this.user.day;
    const year = this.user.year;
    const month = this.user.month;

    if (!!day && !!year && !!month) {
      field.dateOfBirth.day = { value: day, text: this.padStart(day) };
      field.dateOfBirth.year = { value: year, text: this.padStart(year) };
      field.dateOfBirth.month = { value: month, text: this.padStart(month) };
    }

    const countryValue = this.countries.find(o => o.code === this.user.country);
    if (!!countryValue) {
      const countryText = { text: countryValue.name };
      field.passportNationality = { ...countryValue, ...countryText };
    }

    return field;
  }

  private padStart(value): string {
    return String(value).padStart(2, "0");
  }

  private rebuildTitle(title): string {
    if (!!title) {
      return (title[0].toUpperCase() + title.slice(1)).replace(".", "");
    }
  }
}

angular.module("BookingApp").service("PopulateCheckoutService", PopulateCheckoutService);
