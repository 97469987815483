export enum FlightCabin {
  ECONOMY = "Y",
  PREMIUM_ECONOMY = "S",
  BUSINESS = "C",
  FIRST_CLASS = "F",
}
/*
* For some flights, Mystifly will return a cabin type J which is the same as C (BUSINESS).
* In LW we only search for these 4 types (Y, S, C, F), therefore, we don't need to add J here.
*/

export namespace FlightCabin {
  export function toStringTranslation(flightCabinkey: string): string {
    return `flight_cabin.${flightCabinkey}`
  }
}
