import AdyenCheckout from "@adyen/adyen-web";
import { CbObjOnBinLookup } from "@adyen/adyen-web/dist/types/components/internal/SecuredFields/lib/types";
import "@adyen/adyen-web/dist/adyen.css";
import { AdyenFormService } from "booking_app/services/adyen-form.service";

enum AdyenField {
  CardNumber = "encryptedCardNumber",
  ExpiryDate = "encryptedExpiryDate",
  SecurityCode = "encryptedSecurityCode",
}

export class AdyenFormController {
  static $inject = [
    "$scope",
    "AdyenFormService",
  ];

  // binding
  brand: string;

  nameOnCard: string;
  isCardNumberInvalid: boolean;
  isExpiryDateInvalid: boolean;
  isCvvInvalid: boolean;
  isFormSubmitted: boolean;
  isCardNumberOnFocus: boolean;
  isExpiryDateOnFocus: boolean;
  isCvvOnFocus: boolean;

  constructor(
    private $scope: any,
    private adyenFormService: AdyenFormService,
  ) {
    this.isCardNumberInvalid = false;
    this.isExpiryDateInvalid = false;
    this.isCvvInvalid = false;
    this.isFormSubmitted = false;
    this.isCardNumberOnFocus = false;
    this.isExpiryDateOnFocus = false;
    this.isCvvOnFocus = false;
  }

  $onInit() {
    this.renderAdyenCheckoutForm(this.brand);
    this.$scope.$on("form-submitted", (_event, encryptedData) => {
      this.isFormSubmitted = true;
      this.isValidAdyenForm(encryptedData);
    });
  }

  isValidAdyenForm(encryptedData): boolean {
    if (!encryptedData.encryptedCardNumber) {
      this.isCardNumberInvalid = true;
    }
    if (!encryptedData.encryptedExpiryMonth || !encryptedData.encryptedExpiryYear) {
      this.isExpiryDateInvalid = true;
    }
    if (!encryptedData.encryptedSecurityCode) {
      this.isCvvInvalid = true;
    }

    return (
      !!this.nameOnCard &&
      !!encryptedData.encryptedCardNumber &&
      !!encryptedData.encryptedExpiryMonth &&
      !!encryptedData.encryptedExpiryYear &&
      !!encryptedData.encryptedSecurityCode
    );
  }

  renderAdyenCheckoutForm(selectedBrand: string) {
    AdyenCheckout(this.adyenFormService.adyenCheckoutParams()).then((checkout) => {
      checkout.create("securedfields", {
        // Optional configuration
        type: "card",
        brands: [selectedBrand],
        styles: {
          placeholder: {
            color: "#999999",
          },
        },
        // Only for Web Components before 4.0.0.
        // For Web Components 4.0.0 and above, configure aria-label attributes in translation files
        ariaLabels: {
          lang: "en-GB",
          encryptedCardNumber: {
            label: "Credit or debit card number field",
          },
        },
        // Events
        onChange: this.handleOnChange.bind(this),
        onValid: () => { },
        onLoad: () => { },
        onConfigSuccess: () => { },
        onFieldValid: this.onFieldValid.bind(this),
        onBrand: () => { },
        onError: () => { },
        onFocus: this.onFocus.bind(this),
        onBinValue: (bin) => { },
        onBinLookup: (callbackObj: CbObjOnBinLookup) => { },
      }).mount("#adyen-custom-card-container");
    });
  }

  handleNameChange() {
    this.$scope.$emit("adyen-form-update", null, this.nameOnCard);
  }

  private handleOnChange(state, _component) {
    this.$scope.$emit("adyen-form-update", state.data);

    if (state.errors.encryptedCardNumber && !state.errors.encryptedCardNumber.isValid) {
      this.toggleFieldError(AdyenField.CardNumber, false);
    }
    if (state.errors.encryptedExpiryDate && !state.errors.encryptedExpiryDate.isValid) {
      this.toggleFieldError(AdyenField.ExpiryDate, false);
    }
    if (state.errors.encryptedSecurityCode && !state.errors.encryptedSecurityCode.isValid) {
      this.toggleFieldError(AdyenField.SecurityCode, false);
    }
  }

  private onFieldValid(field): void {
    this.toggleFieldError(field.fieldType, field.valid);
  }

  private toggleFieldError(field: AdyenField, isValid: boolean) {
    switch (field) {
      case AdyenField.CardNumber: {
        this.isCardNumberInvalid = !isValid;
        break;
      }
      case AdyenField.ExpiryDate: {
        this.isExpiryDateInvalid = !isValid;
        break;
      }
      case AdyenField.SecurityCode: {
        this.isCvvInvalid = !isValid;
        break;
      }
    }
    this.$scope.$apply();
  }

  private onFocus(field): void {
    switch (field.fieldType) {
      case AdyenField.CardNumber: {
        this.isCardNumberOnFocus = field.focus;
        break;
      }
      case AdyenField.ExpiryDate: {
        this.isExpiryDateOnFocus = field.focus;
        break;
      }
      case AdyenField.SecurityCode: {
        this.isCvvOnFocus = field.focus;
        break;
      }
    }
    this.$scope.$apply();
  }
}
