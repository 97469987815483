import { UrlUtils } from "booking_app/utils";
import { AppSettings } from "booking_app/values/app-settings";
import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { Tenant } from "booking_app/types";

export class MastheadBannerController {
  static $inject = [
    "UrlUtils",
    "AppSettings",
    "$rootScope",
  ];

  // Bindings
  travelType: string;
  productType: string;
  localeCode: string;

  constructor(
    private urlUtils: UrlUtils,
    private appSettings: AppSettings,
    private $rootScope: any,
  ) {}

  public $onInit(): void {
    this.localeCode = this.localeCode || "en";
  }

  public imageMobileUrl(returnAsBackground: boolean = false): string {
    const imgUrl: string = this.urlUtils.imageUrl(`${this.bannerPath()}-mobile.jpg`);
    return returnAsBackground ? `url(${imgUrl})` : imgUrl;
  }

  public imageDesktopUrl(returnAsBackground: boolean = false): string {
    const imgUrl: string = this.urlUtils.imageUrl(`${this.bannerPath()}.jpg`);
    return returnAsBackground ? `url(${imgUrl})` : imgUrl;
  }

  public currentWhiteLabel(): string {
    return this.appSettings.tenant.toLowerCase();
  }

  private bannerPath(): string {
    const imageName: string = this.imageName();

    return `/${this.currentWhiteLabel()}/banners/${this.travelType}/${this.productType}/${imageName}`;
  }

  private isMobile(): boolean {
    return this.appSettings.mobileFirst || this.$rootScope.globalState.browserWidth <= ViewportSizes.XS_MAX;
  }

  private imageName(): string {
    const startTime: number = this.appSettings.mastheadBannerSettings.startTime;
    const endTime: number = this.appSettings.mastheadBannerSettings.endTime;
    const currentTime: number = Date.now();
    const customName: string = this.appSettings.mastheadBannerSettings.landing_page_banner[this.travelType];
    const imageName = (() => {
      if (customName) {
        if (!!startTime && !!endTime) {
          if (currentTime >= startTime && currentTime < endTime) {
            return `${this.localeCode}-${customName}`;
          } else {
            return this.localeCode;
          }
        } else {
          return `${this.localeCode}-${customName}`;
        }
      } else {
        return this.localeCode;
      }
    })();

    return imageName;
  }

}
