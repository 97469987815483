import "../services/facebook-pixel";
import { FacebookPixelService } from "../services/facebook-pixel";
import "../factories/user-factory";

declare var angular : any;
export class SignUpCtrl {

  static $inject = [
    "$scope",
    "$rootScope",
    "$window",
    "$timeout",
    "$filter",
    "FacebookPixelService",
    "KaligoUser",
    "vcRecaptchaService",
    "AppConfig",
  ];

  public registerState: any;
  public recaptchaWidgetId: number;

  private isClicked: boolean;

  constructor(
    private $scope: any,
    private $rootScope: any,
    private $window: any,
    private $timeout: any,
    private $filter: any,
    private facebookPixelService: FacebookPixelService,
    private KaligoUser: any,
    private vcRecaptchaService: any,
    private appConfig: any,
  ) {
    this.isClicked = false;
    this.registerState = { error: null, success: "", newUser: new this.KaligoUser() };
  }

  public signUpClick() {
    if (this.isClicked) { return; }
    this.isClicked = true;
    this.trackLead();
  }

  public doSignup() {
    if (this.registerState.form.$invalid) {
      if (!this.isChinaUser() &&
          !!this.registerState.form.$error.recaptcha &&
          Object.keys(this.registerState.form.$error).length === 1) {
        this.vcRecaptchaService.execute(this.recaptchaWidgetId);
      }
      return;
    }
    if (this.$scope.globalState.processing === true) { return; }

    this.startProcessing();

    const recaptchaResponse = this.isChinaUser() ? "" : this.vcRecaptchaService.getResponse(this.recaptchaWidgetId);
    const defer = this.registerState.newUser.register(recaptchaResponse);
    defer.then(
      (data) => { data.id ? this.showSuccess() : this.showError(data) },
      (reason) => {
        this.showError(reason);
        this.vcRecaptchaService.reload(this.recaptchaWidgetId);
      },
    );
  }

  public isChinaUser(): boolean {
    return this.appConfig.country_code === "CN";
  }

  private trackLead() {
    this.facebookPixelService.eventTrack("Lead", {
      content_category: "LandingPage",
      content_name: this.$rootScope.landingPage.url,
    });
  }

  private startProcessing() {
    this.$scope.globalState.processing = true;
    this.registerState.error = null;
    this.registerState.showPopup = true;
  }

  private showSuccess() {
    this.$scope.globalState.processing = false;
    this.trackLead();
    this.registerState.success = this.$filter("translate")("Congratulations! You've successfully signed up");
    this.$timeout(() => { this.$window.location.reload() }, 2000);
  }

  private showError(error) {
    this.$scope.globalState.processing = false;
    this.registerState.error = error;
  }

}

angular.module("BookingApp").controller("SignUpCtrl", SignUpCtrl);
