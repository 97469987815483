export enum SortType {
  DEAL = "deal",
  PRICE_LOHI = "price-lohi",
  PRICE_HILO = "price-hilo",
  CARS_PRICE_LOHI = "cars-price-lohi",
  CARS_PRICE_HILO = "cars-price-hilo",
  RATING_LOHI = "rating-lohi",
  RATING_HILO = "rating-hilo",
  POINTS_HILO = "point-hilo",
  CARS_POINT_HILO = "cars-point-hilo",
  PAY_POINTS_LOHI = "pay-points-lohi",
  PAY_POINTS_HILO = "pay-points-hilo",
  PAY_POINTS_CASH_LOHI = "pay-points-cash-lohi",
  PAY_POINTS_CASH_HILO = "pay-points-cash-hilo",
  BEST_RATED = "best-rated",
  BUSINESS = "business",
  DISTANCE_LOHI = "distance-lohi",

}

export enum FlightSortType {
  PRICE_LOHI = "price-lohi",
  PRICE_HILO = "price-hilo",
  MILES_LOHI = "miles-lohi",
  MILES_HILO = "miles-hilo",
  STOPS_LOHI = "stops-lohi",
  OUTBOUND_LOHI = "outbound-lohi",
  OUTBOUND_HILO = "outbound-hilo",
  DURATION_LOHI = "duration-lohi",
  DURATION_HILO = "duration-hilo",
}
