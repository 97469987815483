import { ApiDataService } from './api-data.service';

interface TrustyouUIDetails {
  score: number;
  color: "positive" | "neutral" | "negative";
  class: "pos" | "neu" | "neg";
  category: "trustyou_rank_excellent" | "trustyou_rank_vgood" | "trustyou_rank_good" | "trustyou_rank_fair";
}

export class TrustyouService {

  static $inject = [
    '$q',
    'ApiDataService',
  ];

  scoreMapping: TrustyouUIDetails[] = [
    { score: 4.5, color: "positive", class: "pos", category: "trustyou_rank_excellent" },
    { score: 4, color: "positive", class: "pos", category: "trustyou_rank_vgood" },
    { score: 3.3, color: "positive", class: "pos", category: "trustyou_rank_good" },
    { score: 0, color: "neutral", class: "neu", category: "trustyou_rank_fair" },
  ];

  private unresolvedPromises: any;

  constructor(
    private $q: any,
    private apiDataService: ApiDataService,
  ) {
    this.unresolvedPromises = {};
  }

  getHotelReview(hotelId: string) {
    if (this.unresolvedPromises[hotelId]) { return this.unresolvedPromises[hotelId]; }

    const reviewDeferred = this.$q.defer();
    this.apiDataService.get(`hotels/${hotelId}/review`)
    .then(
      (data) => this.resolvePromise(hotelId, reviewDeferred, this.postProcessSingleReview(data)),
      (error) => this.resolvePromise(hotelId, reviewDeferred, error),
    );
    this.unresolvedPromises[hotelId] = reviewDeferred.promise;
    return reviewDeferred.promise;
  }

  resolvePromise(hotelId: string, deferred: any, response: any) {
    deferred.resolve(response);
    delete this.unresolvedPromises[hotelId];
  }

  getScoreUIDetails(score: number): TrustyouUIDetails {
    let res = this.scoreMapping[this.scoreMapping.length - 1];
    this.scoreMapping.some((scoreMap) => {
      if (score >= scoreMap.score) {
        res = scoreMap;
        return true;
      }
    });

    return res;
  }

  postProcessSingleReview(review): any {
    const res = { ...review };
    const score = Number.parseFloat(res.summary.value);
    res.summary.sentiment = this.getScoreUIDetails(score);
    res.summary.value = score;

    return res;
  }
}

angular.module("BookingApp").service("TrustyouService", TrustyouService);
