import "../factories/user-factory";

declare var angular : any;

export class ForgetPasswordCtrl {

  static $inject = [
    "$scope",
    "$filter",
    "KaligoUser",
    "vcRecaptchaService",
    "AppConfig",
  ];

  public recaptchaWidgetId: number;
  private forgetState: any;

  constructor(
    private $scope: any,
    private $filter: any,
    private KaligoUser: any,
    private vcRecaptchaService: any,
    private appConfig: any,
  ) {
    this.forgetState = { error: null, success: "", email: "" };
  }

  public doResetPassword() {
    if (this.forgetState.form.$invalid) {
      if (!this.isChinaUser() &&
          !!this.forgetState.form.$error.recaptcha &&
          Object.keys(this.forgetState.form.$error).length === 1) {
        this.vcRecaptchaService.execute(this.recaptchaWidgetId);
      }
      return;
    }
    if (this.$scope.globalState.processing) { return; }

    this.startProcessing();

    const recaptchaResponse = this.isChinaUser() ? "" : this.vcRecaptchaService.getResponse(this.recaptchaWidgetId);
    const defer = this.KaligoUser().forgetPassword(this.forgetState.email, recaptchaResponse);
    defer.then(
      (data) => { data.errors ? this.showError(data) : this.showSuccess() },
      (reason) => {
        this.showError(reason);
        this.vcRecaptchaService.reload(this.recaptchaWidgetId);
      },
    );
  }

  public showSuccess() {
    this.$scope.globalState.processing = false;
    this.forgetState.success = this.$filter("translate")("forget_password.email_instructions_sent");
  }

  public showError(error) {
    this.$scope.globalState.processing = false;
    this.forgetState.error = error;
  }

  public isChinaUser(): boolean {
    return this.appConfig.country_code === "CN";
  }

  private startProcessing() {
    this.$scope.globalState.processing = true;
    this.forgetState.error = null;
  }
}

angular.module("BookingApp").controller("ForgetPasswordCtrl", ForgetPasswordCtrl);
