export class PointsCashPaymentHandlingService {
  static $inject = [];

  constructor() {}

  zeroFullCashPayment(cash_payment: number, points_payment: number): boolean {
    return (!cash_payment || cash_payment < 0) && points_payment === 0;
  }
}

angular.module("BookingApp").service("PointsCashPaymentHandlingService", PointsCashPaymentHandlingService);
