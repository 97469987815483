import { AppSettings } from "booking_app/values/app-settings";

export class GalleryController {
  static $inject = ["$rootScope", "AppSettings", "$window"];
  // Bindings
  images: string[];
  infinite: boolean;
  autoplaySpeed: boolean;
  autoplay: boolean;
  accessibility: boolean;
  arrows: boolean;
  pauseOnHover: boolean;
  dots: boolean;
  currentGalleryIndex: number;
  imagePosition: "contain" | "cover"; // behaves like object-fit / background-position;
  arrowAriaLabel: string;

  // local state
  private slickConfiguration: { [key: string]: any };

  constructor(
    private $rootScope: any,
    private appSettings: AppSettings,
    private $window: any,
  ) {
  }

  $onInit(): void {
    this.rtlCheck();
    this.setupRtlCheckListener();
  }

  $onChanges(): void {
    this.slickConfiguration = this.buildSlickConfiguration();
    this.rtlCheck();
  }

  imagePositionClass(): { [key: string]: boolean } {
    if (this.imagePosition === "contain") {
      return { contain: true };
    } else {
      return { cover: true };
    }
  }

  rtlCheck(): void {
    const el = angular.element("gallery");
    if (this.$rootScope.globalState.displayAlignment === "rtl") {
      el.attr("dir", "rtl");
    } else {
      el.removeAttr("dir");
    }
  }

  setupRtlCheckListener(): void {
    this.$rootScope.$watch("selectedLocale", () => {
      this.rtlCheck();
    });
  }

  private buildSlickConfiguration(): any {
    return {
      lazyLoad: "ondemand",
      infinite: this.buildValue(this.infinite, true),
      autoplay: this.buildValue(this.autoplay, false),
      draggable: false,
      autoplaySpeed: this.buildValue(this.autoplaySpeed, 3000),
      dots: this.buildValue(this.dots, false),
      pauseOnHover: this.buildValue(this.pauseOnHover, true),
      accessibility: this.buildValue(this.accessibility, false),
      arrows: this.images.length <= 1 ? false : this.buildValue(this.arrows, true),
      prevArrow: `<button type="button" class="slick-prev" aria-label="Previous ${this.arrowAriaLabel} photo">Previous</button>`,
      nextArrow: `<button type="button" class="slick-next" aria-label="Next ${this.arrowAriaLabel} photo">Next</button>`,
      initialSlide: this.currentGalleryIndex || 0,
      mobileFirst: true,
      event: {
        afterChange: (event, slick, currentSlide, nextSlide) => {
          this.currentGalleryIndex = currentSlide;
          this.triggerResizeEvent();
        },
        init: (event, slick) => {
          this.currentGalleryIndex = this.currentGalleryIndex || 0;
          this.triggerResizeEvent();
        },
      },
    };
  }

  private triggerResizeEvent(): void {
    // Need to retriger window resize event to fix issues on safari showing to slides on modal
    const resizeEvent = window.document.createEvent("UIEvents");
    resizeEvent.initEvent("resize", true, false);
    window.dispatchEvent(resizeEvent);
  }

  private imageCount(): number {
    return this.images.length;
  }

  private currentSlideNumber(): number {
    return this.currentGalleryIndex + 1;
  }

  private buildValue(customValue, defaultValue): any {
    return (customValue !== undefined) ?  customValue : defaultValue;
  }
}
