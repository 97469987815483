import * as showdown from "showdown";
import { ApiDataService } from "./api-data.service";
import { PrismicService } from "./prismic.service";
import { AppSettings } from "booking_app/values/app-settings";

export interface PageContentType {
  page: {
    content: string;
  };
}

export class PageService {
  static $inject = [
    "ApiDataService",
    "PrismicService",
    "AppSettings",
    "$q",
  ];

  static readonly LANGUAGE_MAPPING = {
    "zh-CN": "zh",
    "zh-TW": "hk",
    "pt-BR": "pt",
    "tw": "tw",
    "ms": "en",
    "nl": "en",
    "ar": "ar",
    "da": "en",
    "lv": "en",
    "no": "en",
    "fi": "en",
    "kr": "en",
    "my": "en",
    "sv": "en",
    "ru": "en",
  };

  private showdown: any;
  private converter: any;
  private prismicSetting: {
    enabled: boolean,
    faqs?: string,
    terms?: string,
    privacyPolicy?: string,
  };

  constructor(
    private apiDataService: ApiDataService,
    private prismicService: PrismicService,
    private appSettings: AppSettings,
    private $q: any,
  ) {
    this.showdown  = require("showdown");
    this.converter = new showdown.Converter();
    this.prismicSetting = this.appSettings.prismicSettings;
  }

  public getPage(page: string, locale: string): ng.IPromise<{}> {
    const defer = this.$q.defer();
    if (this.prismicSetting.enabled && this.prismicService.getPath()) {
      this.prismicService.initPrismicConnection();
      this.prismicService.getPage(locale).then((res: PageContentType) => {
        defer.resolve(res);
      });
    } else {
      if (PageService.LANGUAGE_MAPPING[locale]) {
        locale = PageService.LANGUAGE_MAPPING[locale];
      }
      const url = this.pageUrl(page, locale);
      this.apiDataService.get(url).then((res: PageContentType) => {
        defer.resolve(res);
      });
    }
    return defer.promise;
  }

  public hasPrismicImplementation(): boolean {
    return (this.prismicSetting.enabled && !!this.prismicService.getPath());
  }

  private pageUrl(page: string, locale: string): string {
    return `pages/${page}?locale=${locale}`;
  }
}

angular.module("BookingApp").service("PageService", PageService);
