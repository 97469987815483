require('./points-cash-share.service')

angular.module("BookingApp")

.factory "BookingTransactionsService", [

  '$q', '$timeout', 'ApiDataService', '$rootScope', 'PointsCashShareService'
  ($q, $timeout, ApiDataService, $rootScope, PointsCashShareService) ->

    bookingInfo = {}

    requestCheckStatus = (transactionId) ->
      defer = $q.defer() # we use $q because of the timeout
      $timeout ->
        ApiDataService.get("transactions/#{transactionId}/status")
        .then (data) ->
          defer.resolve data
        , (error) ->
          defer.resolve error
      , 2000
      defer.promise

    performCheckStatus = (transactionId, defer) ->
      requestCheckStatus(transactionId).then (data) ->
        if data.status == 'in progress'
          performCheckStatus(transactionId, defer)
        else
          defer.resolve(data)
      defer.promise

    updateStatus = (transactionId, status, response) ->
      defer = $q.defer()
      ApiDataService.post("transactions/#{transactionId}/status", { status: status, response: response })
      .then (data) ->
        bookingInfo[transactionId] = data
        defer.resolve data
      , (error) ->
        defer.reject error

    requestBookingInfo = (defer, transactionId) ->
      ApiDataService.get("transactions/#{transactionId}")
      .then (data) ->
        bookingInfo[transactionId] = data
        defer.resolve data
      , (error) ->
        defer.reject error

    fetchBookingInfo = (transactionId) ->
      defer = $q.defer()
      if bookingInfo[transactionId]
        defer.resolve bookingInfo[transactionId]
      else
        requestBookingInfo(defer, transactionId)
      defer.promise

    loadCheckoutData = (scope, checkoutData) ->
      if checkoutData.travel_type == "hotels"
        scope.bookingDetails.guest.title = checkoutData.salutation
        scope.bookingDetails.guest.firstName = checkoutData.firstName
        scope.bookingDetails.guest.lastName = checkoutData.lastName
        scope.bookingDetails.guest.specialRequests = checkoutData.guest_special_requests || ''
        scope.bookingDetails.user.email = checkoutData.user_email
        scope.bookingDetails.user.firstName = checkoutData.user_first_name
        scope.bookingDetails.user.phoneNumber = checkoutData.phone_number.split(' ')[1]
      else # cars here
        scope.bookingDetails.driver.firstName = checkoutData.first_name
        scope.bookingDetails.driver.lastName = checkoutData.last_name
        scope.bookingDetails.driver.email = checkoutData.user_email
        scope.bookingDetails.driver.flight_number = checkoutData.flight_number
        scope.bookingDetails.driver.phoneNumber = checkoutData.phone_number.split(' ')[1]
        scope.bookingDetails.driver.country_residence = checkoutData.country_residence
        scope.bookingDetails.driver.age = checkoutData.age

      scope.bookingDetails.guest.city = checkoutData.city
      scope.bookingDetails.guest.street = checkoutData.street
      scope.bookingDetails.guest.postalCode = checkoutData.postalCode
      scope.bookingDetails.guest.state = checkoutData.state
      PointsCashShareService.pointsCashShare.value = checkoutData.pay_with_points_tier if PointsCashShareService.pointsCashShare
      scope.bookingDetails.paymentChannel = checkoutData.payment_channel || "credit_card"
      scope.bookingDetails.guest.country = {id: checkoutData.country, code: checkoutData.country, text: checkoutData.countryName}
      scope.bookingDetails.useFamilyMiles = checkoutData.use_family_miles

    ServiceBuilder = (transactionId) ->
      {
        checkStatus: () -> performCheckStatus(transactionId, $q.defer())
        fetchInfo: () -> fetchBookingInfo(transactionId)
        updateStatus: (status, response) -> updateStatus(transactionId, status, response)
        loadCheckoutData: loadCheckoutData
      }

    return ServiceBuilder

]
