/* global angular */
'use strict'; // jshint ignore:line


angular.module('lumx.tooltip', [])
    .controller('LxTooltipController', ['$scope', '$timeout', function($scope, $timeout) {
        var self = this,
            tooltip,
            tooltipContent,
            tooltipPosition,
            tooltipColor,
            tooltipLabel,
            tooltipBackground,
            tooltipTrigger;

        this.init = function(element, attrs) {
            tooltipTrigger = element;

            tooltipContent = attrs.lxTooltip;
            tooltipPosition = angular.isDefined(attrs.tooltipPosition) ? attrs.tooltipPosition : 'top';
            tooltipColor = angular.isDefined(attrs.tooltipColor) ? attrs.tooltipColor : 'black';

            tooltip = angular.element('<div/>', {
                class: 'tooltip tooltip--' + tooltipPosition + ' tooltip--' + tooltipColor
            });

            tooltipBackground = angular.element('<div/>', {
                class: 'tooltip__background'
            });

            tooltipLabel = angular.element('<span/>', {
                class: 'tooltip__label',
                text: tooltipContent
            });

            tooltipTrigger
                .bind('mouseenter', function() {
                    self.showTooltip();
                });

            tooltipTrigger
                .bind('mouseleave', function() {
                    self.hideTooltip();
                });
        };

        this.showTooltip = function() {
            var width = tooltipTrigger.outerWidth(),
                height = tooltipTrigger.outerHeight(),
                top = tooltipTrigger.offset().top,
                left = tooltipTrigger.offset().left;

            tooltip
                .append(tooltipBackground)
                .append(tooltipLabel)
                .appendTo('body');

            if (tooltipPosition === 'top') {
                tooltip.css({
                    left: left - (tooltip.outerWidth() / 2) + (width / 2),
                    top: top - tooltip.outerHeight()
                });
            } else if (tooltipPosition === 'bottom') {
                tooltip.css({
                    left: left - (tooltip.outerWidth() / 2) + (width / 2),
                    top: top + height
                });
            } else if (tooltipPosition === 'left') {
                tooltip.css({
                    left: left - tooltip.outerWidth(),
                    top: top + (height / 2) - (tooltip.outerHeight() / 2)
                });
            } else if (tooltipPosition === 'right') {
                tooltip.css({
                    left: left + width,
                    top: top + (height / 2) - (tooltip.outerHeight() / 2)
                });
            }

            tooltip.addClass('tooltip--is-active');
        };

        this.update = function(content) {
            tooltipContent = content;
            tooltipLabel.text(tooltipContent);
        };

        this.hideTooltip = function() {
            if (angular.isDefined(tooltip)) {
                tooltip.removeClass('tooltip--is-active');

                $timeout(function() {
                    tooltip.remove();
                }, 200);
            }
        };

        this.isDisplayed = function() {
            return angular.isDefined(tooltip) && tooltip.hasClass('tooltip--is-active');
        };

        $scope.$on('$destroy', function(scope) {
            if (angular.isDefined(tooltip)) {
                tooltip.remove();
            }
        });
    }])
    .directive('lxTooltip', function() {
        return {
            restrict: 'A',
            controller: 'LxTooltipController',
            link: function(scope, element, attrs, ctrl) {
                attrs.$observe('lxTooltip', function() {
                    if (attrs.lxTooltip) {
                        if (ctrl.isDisplayed()) {
                            ctrl.update(attrs.lxTooltip);
                        } else {
                            ctrl.init(element, attrs);
                        }
                    } else {
                        ctrl.hideTooltip();
                    }
                });
            }
        };
    });
