import { Bindings } from "booking_app/types";
import { TaxAndFeesAccordionController } from "./tax-and-fees-accordion.controller";

export class TaxAndFeesAccordion {
  bindings: any = {
    hotelPrice: Bindings.ONE_WAY,
    currencyCode: Bindings.ONE_WAY,
  };
  controller: any = TaxAndFeesAccordionController;
  template: string = `
    <accordion ng-if="$ctrl.renderAccordion()" class="accordion-element" include-padding="false">
      <div class="header" ng-click="togglePanel()" ng-class="{active: showPanel}" tabindex="0">
        <div class="price-list">
          <div class="label pull-left">
            <span translate="checkout.tax_breakdown.label.fee_breakdown"></span>
            <em class="fa fa-angle-down"></em>
          </div>
          <div class="value pull-left text-right cash-value">
            <span class="currency">{{ $ctrl.currencyCode }}</span>
            <span>{{ $ctrl.hotelPrice.formattedIncludedTaxesAndFeesTotal() }}</span>
          </div>
        </div>
      </div>

      <div class="content" ng-class="{active: showPanel}">
        <div class="price-list" ng-repeat="taxAndFeeItem in $ctrl.hotelPrice.formattedIncludedTaxesAndFees">
          <div class="label pull-left">
            <span translate="checkout.tax_breakdown.label.{{ taxAndFeeItem.id }}"/>
          </div>
          <div class="value pull-left text-right cash-value">
            <span class="currency">{{ $ctrl.currencyCode }}</span>
            <span>{{ taxAndFeeItem.amount }}</span>
          </div>
        </div>
      </div>
    </accordion>
    <div ng-if="!$ctrl.renderAccordion()">
      <div class="price-list">
        <div class="label pull-left">
          <span translate="checkout.tax_breakdown.label.{{ $ctrl.hotelPrice.formattedIncludedTaxesAndFees[0].id }}">
          </span>
        </div>
        <div class="value pull-left text-right cash-value">
          <span class="currency">{{ $ctrl.currencyCode }}</span>
          <span >{{ $ctrl.hotelPrice.formattedIncludedTaxesAndFees[0].amount }}</span>
        </div>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("taxAndFeesAccordion", new TaxAndFeesAccordion());
