import { CheckoutLoadingModalController } from './checkout-loading-modal.controller';
import { Bindings } from 'booking_app/types';

export class CheckoutLoadingModalComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      message: Bindings.ONE_WAY,
    };
    this.controller = CheckoutLoadingModalController;
    this.templateUrl = "/html/components/flights_payment_loader";
  }
}

angular.module("BookingApp").component("checkoutLoadingModalComponent", new CheckoutLoadingModalComponent());
