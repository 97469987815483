angular.module("BookingApp")
.directive "destinationSelect2", [
  '$rootScope', '$timeout', '$translate', 'KaligoSearch', 'AppSettings'
  ($rootScope, $timeout, $translate, KaligoSearch, AppSettings) ->
    link: (scope, element, attrs) ->
      element.on 'select2-selecting', (e) ->
        KaligoSearch.form.destination =
          label: e.object.label
          value: e.object.value
        $timeout ->
          $('input').blur()
        , 10
        return

      $rootScope.$watch 'selectedLocale', (newValue) ->
        return if KaligoSearch.form.destination || AppSettings.destinationFieldPlaceHolder == ""
        chosenField = element.parent().find(".select2-chosen")
        chosenField.html($translate.instant(AppSettings.destinationFieldPlaceHolder))
]
