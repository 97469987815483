import { Bindings } from "booking_app/types";
import { GroupedRoomController } from "./grouped-room.controller";

export class GroupedRoomComponent {
  bindings: any = {
    policies: Bindings.ONE_WAY,
    pointsPartner: Bindings.ONE_WAY,
    redirectToHotelCheckoutPage: Bindings.EXPRESSION,
    showAllRooms: Bindings.ONE_WAY,
    isPointChecking: Bindings.ONE_WAY,
  };
  transclude: boolean = true;
  controller: any = GroupedRoomController;
  template: string = `
    <div
      modal-accessibility-lock
      class="max-size" ng-repeat="(key, val) in $ctrl.roomListing()"
      ng-if="$index <= $ctrl.currentLimit">
      <div class="header-container">
        <div class="header-title" ng-bind="val.rooms[0].roomDescriptionShort"></div>
        <div class="discount" ng-if="val.rooms[0].discount"> {{ val.rooms[0].discount + '% off' }} </div>
      </div>
      <div class="grouped-room">
        <div class="image-container">
          <room-image room="val.rooms[0]" default-image="$ctrl.roomDefaultImage()"></room-image>
        </div>
        <div class="room-information-container">
          <div class="rooms"
            ng-repeat="room in val.rooms track by room.key"
            ng-show="room.visible">
            <grouped-room-list-item
              room="room"
              policies="$ctrl.policies"
              points-partner="$ctrl.pointsPartner"
              redirect-to-hotel-checkout-page="$ctrl.goToHotelCheckoutPage(room)">
            </grouped-room-list-item>
          </div>
        </div>
      </div>
    </div>

    <div ng-transclude></div>

    <div
      class="max-size" ng-repeat="(key, val) in $ctrl.roomListing()"
      ng-if="$index > $ctrl.currentLimit && $ctrl.showAllRooms">
      <div class="header-container">
        <div class="header-title" ng-bind="val.rooms[0].roomDescriptionShort"></div>
        <div class="discount" ng-if="val.rooms[0].discount"> {{ val.rooms[0].discount + '% off' }} </div>
      </div>
      <div class="grouped-room">
        <div class="image-container">
          <room-image room="val.rooms[0]" default-image="$ctrl.roomDefaultImage()"></room-image>
        </div>
        <div class="room-information-container">
          <div class="rooms"
            ng-repeat="room in val.rooms track by room.key"
            ng-show="room.visible">
            <grouped-room-list-item
              room="room"
              policies="$ctrl.policies"
              points-partner="$ctrl.pointsPartner"
              redirect-to-hotel-checkout-page="$ctrl.goToHotelCheckoutPage(room)">
            </grouped-room-list-item>
          </div>
        </div>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("groupedRoom", new GroupedRoomComponent());
