import { Bindings } from "booking_app/types";
import { DateRangeFieldMobileController } from "./date-range-field-mobile.controller";

export class DateRangeFieldMobileComponent {
  bindings: any = {
    startDate: Bindings.ONE_WAY,
    endDate: Bindings.ONE_WAY,
    minDaysToBookInAdvance: Bindings.ONE_WAY,
    minBookingDays: Bindings.ONE_WAY,
    onUpdate: Bindings.EXPRESSION,
    locale: Bindings.ONE_WAY,
    dateFormat: Bindings.ONE_WAY,
    numberOfMonths: Bindings.ONE_WAY,
    startLabel: Bindings.ONE_WAY,
    endLabel: Bindings.ONE_WAY,
    noEndDate: Bindings.ONE_WAY,
  };
  transclude: any = {
    iconContent: "?iconContent",
  };
  controller: any = DateRangeFieldMobileController;
  template: string = `
    <div class="date-range-field-mobile" click-outside="$ctrl.closeModal()">

      <div class="date-range-field-mobile-input" ng-if="!$ctrl.noEndDate">
        <input
          class="field-input"
          id="start"
          type="text"
          readonly
          ng-value="$ctrl.formattedStartDate()"
          ng-click="$ctrl.beginDateSelection('start')"
        >
        <span ng-transclude="iconContent"></span>
        <input
          class="field-input"
          id="end"
          type="text"
          readonly
          ng-value="$ctrl.formattedEndDate()"
          ng-click="$ctrl.beginDateSelection('end')"
        >
      </div>

      <div class="date-range-field-mobile-input" id="start-only" ng-if="$ctrl.noEndDate">
        <input
          class="field-input"
          id="start"
          type="text"
          readonly
          ng-value="$ctrl.formattedStartDate()"
          ng-click="$ctrl.beginDateSelection('start')"
        >
      </div>

      <form-popup-mobile ng-if="$ctrl.modalOpen">
        <title-content>
          <button
            type="button"
            class="fa fa-close close-tick"
            ng-click="$ctrl.closeModal()"
            aria-label="Close">
          </button>
          <div class="date-summary">
            <div ng-class="$ctrl.startInfoFieldClass()">
              <div class="info-field-label">{{ $ctrl.startLabel | translate }}</div>
              <div class="info-field-box">{{ $ctrl.formattedStartDate() }}</div>
            </div>
            <div class="number-of-nights" ng-if="!$ctrl.noEndDate">
              <span>{{ $ctrl.numberOfNights() }}</span>
              <i class="fa fa-moon-o"></i>
            </div>
            <div ng-class="$ctrl.endInfoFieldClass()" ng-if="!$ctrl.noEndDate">
              <div class="info-field-label">{{ $ctrl.endLabel | translate }}</div>
              <div class="info-field-box">{{ $ctrl.formattedEndDate() }}</div>
            </div>
          </div>
        </title-content>

        <body-content>
          <div class="datepicker-container">
            <date-picker
              min-date="$ctrl.minDatepickerDate()"
              start-date="$ctrl.startDate"
              end-date="$ctrl.endDate"
              on-select-start-date="$ctrl.updateStartDate(value)"
              on-select-end-date="$ctrl.updateEndDate(value)"
              selection-state="$ctrl.selectionState"
              locale="$ctrl.locale"
            ></date-picker>
            <button
              type="button"
              class="btn-action"
              ng-click="$ctrl.closeModal()">
              <span> {{ "button.done" | translate }} </span>
            </button>
          </div>
        </body-content>
      </form-popup-mobile>

    </div>
  `;
}

angular.module("BookingApp").component("dateRangeFieldMobile", new DateRangeFieldMobileComponent());
