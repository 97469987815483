import { ApiDataService } from './api-data.service';
import { convertJSONToQueryString } from 'booking_app/services/route-params-utils';

export class ApiPollingService {

  static MAX_RETRIES = 8;

  static $inject = [
    '$q',
    '$timeout',
    'ApiDataService',
  ];

  constructor(
    private $q,
    private $timeout,
    private apiDataService: ApiDataService,
  ) { }

  poll(baseUrl: string, params: object): any {
    const url = `${baseUrl}?${convertJSONToQueryString(params)}`;

    const defer = this.$q.defer();

    this.runQuery(url, defer);

    return defer.promise;
  }

  private runQuery(url: string, promise: any, tries = 0, failures = 0) {
    this.apiDataService.get(url)
      .then(
        (data) => { this.handleSuccess(url, promise, tries, data); }, // success
        (data) => { this.handleFailure(url, promise, tries, failures, data); } // failure
      )
  }

  private handleSuccess(url: string, promise: any, tries: number, data: any) : void {
    if (data.completed) {
      promise.resolve(data);
    } else {
      promise.notify(data);
      this.$timeout(() => this.runQuery(url, promise, tries + 1), this.pollingInterval(tries));
    }
  }

  private handleFailure(url: string, promise: any, tries: number, failures: number, error: object) : void {
    if (this.shouldRetry(failures)) {
      this.$timeout(() => this.runQuery(url, promise, tries, failures + 1), this.pollingInterval(tries));
    } else {
      promise.reject(error);
    }
  }

  private shouldRetry(failures: number) : boolean {
    return failures <= ApiPollingService.MAX_RETRIES;
  }

  private pollingInterval(tries: number) : number {
    if (tries > 3) {
      return 3000;
    } else {
      return 1000;
    }
  }

}

angular.module("BookingApp").service("ApiPollingService", ApiPollingService);
