export enum Tenant {
  CHINAEASTERN = "Chinaeastern",
  FINNAIR = "Finnair Plus",
  HAINAN = "Hainan",
  VELOCITY = "Velocity",
  ACME = "Acme",
  ELAL = "Matmid Club",
  FAB = "Fab",
  JAL = "Jal",
  OLDHAM = "Oldham",
  UOB = "Uob",
  VISA = "Visa",
  VMA = "Vma",
  ASCENDAGO = "Ascendago",
  KALIGO = "Kaligo.com",
  CTBC = "Ctbc",
  BSA = "Bsa",
  BOM = "Bom",
  STG = "Stg",
  MYBANK = "Mybank",
  ANZBIZ = "AnzBiz",
  ANZREWARDS = "AnzRewards",
  HYUNDAI = "Hyundai",
  BNZ = "Bnz",
}

export type RCTenant = string;
