import * as prismic from "@prismicio/client";
import * as showdown from "showdown";

import { PrismicPageSection, PrismicType } from "booking_app/types/prismic.type";
import { CurrentPage } from "booking_app/types/current-page";
import { PageDiscoveryService } from "./page-discovery.service";
import { AppSettings } from "booking_app/values/app-settings";
import { PageContentType } from "booking_app/services/page.service";

export class PrismicService {
  static $inject = ["PageDiscoveryService", "AppSettings", "$q"];
  static readonly LANGUAGE_MAPPING = {
    "zh-TW": "zh-tw",
    "tw": "zh-tw",
    "ko": "ko-kr",
    "ja": "ja-jp",
    "zh-CN": "zh-cn",
    "id": "id",
    "vi": "vi",
  };

  private client: any;
  private endpoint: any;
  private showdown: any;
  private converter: any;
  constructor(
    private pageDiscoveryService: PageDiscoveryService,
    private appSettings: AppSettings,
    private $q: any,
  ) {
    this.showdown  = require("showdown");
    this.converter = new this.showdown.Converter();
  }

  initPrismicConnection(): void {
    this.endpoint = prismic.getRepositoryEndpoint("ascendaloyalty");
    this.client = prismic.createClient(this.endpoint, { fetch });
  }

  getPage(locale: string): ng.IPromise<PageContentType> {
    const defer = this.$q.defer();
    if (locale === "en") {
      locale = "en-us";
    } else {
      locale = PrismicService.LANGUAGE_MAPPING[locale];
    }
    this.client.getByUID("pagecontent", this.getPath(), { lang: locale }).then((res: PrismicType) => {
      defer.resolve(this.processDocument(res));
    });
    return defer.promise;
  }

  getPath(): string {
    const prismicSetting = this.appSettings.prismicSettings;
    switch (this.pageDiscoveryService.currentPage()) {
      case CurrentPage.FAQ:
        return prismicSetting.faqs;
      case CurrentPage.TERMS_CONDITION:
        return prismicSetting.terms;
      case CurrentPage.PRIVACY_POLICY:
        return prismicSetting.privacyPolicy;
    }
  }

  private processDocument(document: PrismicType): PageContentType {
    const page: string[] = [];
    page.push(this.converter.makeHtml(document.data.title[0].text));
    page.push(this.buildPageSections(document.data.pagesections));
    return { page: { content: page.join("") } };
  }

  private buildPageSections(pageSections: PrismicPageSection[]): string {
    const pageContent: string[] = [];
    pageSections.forEach((section: any) => {
      if (section.title1.length > 0) {
        pageContent.push(this.converter.makeHtml(section.title1[0].text));
      }
      pageContent.push(this.converter.makeHtml(section.content[0].text));
    });

    return pageContent.join("");
  }
}

angular.module("BookingApp").service("PrismicService", PrismicService);
