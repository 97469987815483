import '../services/format-service'

declare var angular : any;

export class DisplayUtils {

  static $inject = ['$rootScope', '$translate', 'FormatService']

  private $rootScope: any;
  private $translate: any;
  private formatService: any;

  constructor($rootScope, $translate, formatService) {
    this.$rootScope = $rootScope;
    this.$translate = $translate;
    this.formatService = formatService
  }

  formatNumber(value: number): string {
    return this.formatService.number(value, this.$rootScope.selectedLocale, 0)
  }

  partnerShortCurrency(): string {
    return this.$translate.instant(this.$rootScope.pointsPartner.shortCurrency)
  }
}

angular.module('BookingApp').service('DisplayUtils', DisplayUtils)
