import { AppSettings } from "booking_app/values/app-settings";

declare var angular : any;

export class LoginPopupCtrl {
  static $inject = [
    "$scope",
    "$rootScope",
    "ModalManager",
    "redirectUrl",
    "AppSettings",
  ];

  constructor(
    private $scope: any,
    private $rootScope: any,
    private ModalManager: any,
    private redirectUrl: string,
    private appSettings: AppSettings,
  ) {

    this.$rootScope.isLoading = false;

    this.$scope.params = (): string => {
      return `redirect=${ this.redirectUrl }`;
    };

    this.$scope.tenant = this.appSettings.tenant.toLowerCase();

    this.$rootScope.$on('login-successful', () => {
      this.ModalManager.close();
    });
  }

  public cancel() {
    this.ModalManager.close();
  }
}

angular.module('BookingApp').controller('LoginPopupCtrl', LoginPopupCtrl)
