import { HeaderUrlLink, UrlLink } from "booking_app/constants/header-url-link";
import { CurrentPage } from "booking_app/types";
import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { PageDiscoveryService } from "./page-discovery.service";

export class HeaderUrlList {
  static $inject = [
    "HeaderMenuList",
    "$rootScope",
    "PageDiscoveryService",
  ];

  public headerList: HeaderUrlLink[] = [];

  private currentHeaderTitle: string;

  constructor(
    private headerMenuList: any,
    private $rootScope: any,
    protected pageDiscoveryService: PageDiscoveryService,
  ) {
  }

  updateActiveLink(contentTitle: string = this.currentHeaderTitle): void {
    this.removeActiveHeader();
    const headerUrlLink: HeaderUrlLink = this.headerList.find((obj => obj.title === contentTitle));
    if (headerUrlLink) {
      headerUrlLink.active = true;
    }
  }

  setupUrlLink(): void {
    this.buildHeaderList();
    this.filterRestrictedLinks();

    // Setup tab index here
    // so we will not bother adding new lines for each on header-menu-list.ts
    this.addTabIndex();

    const currentPage: CurrentPage = this.pageDiscoveryService.currentPage();
    this.setActiveLink(currentPage);
    this.pageDiscoveryService.listenRouteChange(this.onRouteChange.bind(this));
  }

  getActiveHeader(): HeaderUrlLink {
    return this.headerList.find(obj => obj.active);
  }

  getCurrentLinks(): UrlLink[] {
    const currentHeader: HeaderUrlLink = this.getActiveHeader();
    if (currentHeader) {
      const currentLinks: UrlLink[] = currentHeader.links;
      return currentLinks;
    } else {
      return [];
    }
  }

  protected onRouteChange(currentPage: CurrentPage): void {
    this.resetLinksStates();
    this.setActiveLink(currentPage);
  }

  private buildHeaderList(): void {
    this.headerList = this.headerMenuList.headerList;
  }

  private filterRestrictedLinks(): void {
    if (!this.$rootScope.isUserLoggedIn()) {
      this.headerList = this.headerList.filter(e => !e.requireLoggedIn);
    }
  }

  private addTabIndex(): void {
    let tabIndexValue: number = 1;
    this.headerList.forEach((siteLink) => {
      tabIndexValue += 1;
      siteLink.tabIndex = tabIndexValue;
      tabIndexValue += 1;
      siteLink.links.forEach((link) => {
        if (this.$rootScope.globalState.browserWidth <= ViewportSizes.SM_MAX ) {
          link.tabIndex = 0;
        } else {
          link.tabIndex = tabIndexValue;
        }
      });
    });
  }

  private setActiveLink(currentPage: CurrentPage): void {
    this.headerList.forEach((headerLink: HeaderUrlLink) => {
      headerLink.links.forEach((link: UrlLink) => {
        const linkActivePages: CurrentPage[] = link.activePages || [];
        if (linkActivePages.includes(currentPage)) {
          if (!headerLink.onClickOnlyActive) {
            headerLink.active = true;
          }
          link.active = true;
          this.currentHeaderTitle = headerLink.title;
        }
      });
    });
  }

  private resetLinksStates(): void {
    this.headerList.forEach((headerLink: HeaderUrlLink) => {
      headerLink.links.forEach((link: UrlLink) => {
        link.active = false;
      });
      headerLink.active = false;
    });
  }

  private removeActiveHeader(): void {
    const activeHeader: HeaderUrlLink = this.getActiveHeader();

    if (activeHeader) {
      activeHeader.active = false;
    }
  }

}

angular.module("BookingApp").service("HeaderUrlList", HeaderUrlList);
