import "booking_app/directives/accordion.js";

export class TaxAndFeesAccordionController {
  static $inject = ["$scope"];

  hotelPrice: any;
  currencyCode: string;

  constructor(
    private $scope: any,
  ) {
    this.$scope = $scope;
  }

  public renderAccordion(): boolean {
    return this.hotelPrice.formattedIncludedTaxesAndFees.length > 1;
  }

  $onChanges(changes: any): void {
    if (changes.hotelPrice) {
      this.$scope.$broadcast("accordionDataUpdated");
    }
  }
}
