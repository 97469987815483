import { AppSettings } from "booking_app/values/app-settings";
import { MapboxDirectionService } from "booking_app/services/mapbox-direction";

export class MapDirectionController {

  static $inject = [
    "AppSettings",
    "$rootScope",
    "MapboxDirectionService",
  ];

  // bindings
  private originLat: string;
  private originLng: string;
  private destinationLat: string;
  private destinationLng: string;
  private distance: number;
  private originAddress: string;
  private destinationAddress: string;

  private mapViewApiBaseUrl: string;
  private mapApiQueryString: string;
  private origin: number[];
  private destination: number[];
  private isModal: boolean;

  constructor(
    private appSettings: AppSettings,
    private $rootScope: any,
    private mapboxDirectionService: MapboxDirectionService,
  ) { }

  openMap($event, callbackButtonElem?: HTMLElement): void {
    if (!this.isModal && callbackButtonElem) {
      this.mapboxDirectionService.setCallbackButtonElement(callbackButtonElem);
    }
    $event.stopPropagation();
    $event.preventDefault();
    this.setupMapDirectionCoordinates();
    this.mapboxDirectionService.setViewMode(this.isModal ? "modal" : "");
    this.mapboxDirectionService.toggleViewMode();
  }

  closePopupMobile(): void {
    this.mapboxDirectionService.removeMap();
  }

  hasCoordinates(): boolean {
    return !!this.originLat && !!this.originLng;
  }

  private setupMapDirectionCoordinates(): void {
    this.mapboxDirectionService.origin = [parseFloat(this.originLng), parseFloat(this.originLat)];
    this.mapboxDirectionService.originAddress = this.$rootScope.searchState.pickupLocationAddress;
    this.mapboxDirectionService.destination = [parseFloat(this.destinationLng), parseFloat(this.destinationLat)];
    this.mapboxDirectionService.destinationAddress = this.destinationAddress;
  }

}
