import { HeaderUrlList } from "booking_app/services/header-url-list";
import { UrlLink } from "booking_app/constants/header-url-link";

export class SiteLinksController {
  static $inject = [
    "HeaderUrlList",
  ];

  linkOption: UrlLink;
  clickBehavior: boolean;

  constructor(
    private headerUrlList: HeaderUrlList,
  ) {}

  updateActiveLink(): void {
    this.headerUrlList.updateActiveLink(this.linkOption.title);
  }
}
