import { PaymentSettings } from "booking_app/types/payment-settings";

export class PaymentStylingSettings {
  public paymentSettings: PaymentSettings;
  constructor(
  ) {
    this.setupPaymentStyling();
  }

  setupPaymentStyling(): void {
    // Default base style
    this.paymentSettings = {
      stripe: {
        base: {
          color: "#282828",
          fontSize: "14.4px",
        },
        invalid: {
          color: "#282828",
        },
      },
    };
  }
}
angular.module("BookingApp").service("PaymentStylingSettings", PaymentStylingSettings);
