import { HotelListItemMapButtonController } from "./map-button.controller";
import { Bindings } from "booking_app/types";

export class HotelListItemMapButtonComponent {
  bindings: any = {
    hotel: Bindings.ONE_WAY,
    mapText: Bindings.ONE_WAY,
  };
  controller: any = HotelListItemMapButtonController;
  template: string = `
    <button class="inline-element show-on-map" ng-click="$ctrl.showInMap($event)">
      <em class="fa fa-map-marker"></em>
      <span translate="{{ $ctrl.showMapText() }}"></span>
    </button>
  `;
}

angular.module("BookingApp").component("hotelListItemMapButton", new HotelListItemMapButtonComponent());
