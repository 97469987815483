export class InputValidationService {
  public validateFlightNumber(flightNumber: string): boolean {
    return this.areFlightNumberCharactersValid(flightNumber);
  }

  public validatePhone(phoneNumber: string): boolean {
    return this.isPhoneNumberLengthValid(phoneNumber) && this.arePhoneNumberCharactersValid(phoneNumber);
  }

  private isPhoneNumberLengthValid(phoneNumber: string): boolean {
    return phoneNumber.match(/\d/g) && phoneNumber.match(/\d/g).length >= 5;
  }

  private arePhoneNumberCharactersValid(phoneNumber: string): boolean {
    return /^[\d\+\-]+$/.test(phoneNumber);
  }

  private areFlightNumberCharactersValid(flightNumber: string): boolean {
    return /^[a-zA-Z0-9]{2}[0-9]{1,4}$/.test(flightNumber);
  }
}

angular.module("BookingApp").service("InputValidationService", InputValidationService);
