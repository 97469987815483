declare var angular: any;

export interface FacebookPixelEventTrackParam {
  content_category: string,
  content_name: string,
}

export class FacebookPixelService {
  static $inject = ['$window'];

  private $window: any;
  private isActive: boolean;

  constructor($window) {
    this.$window = $window;
    this.isActive = true;
  }

  public eventTrack(key: string, param: FacebookPixelEventTrackParam): void {
    if (this.$window.fbq && this.isActive) {
      this.$window.fbq('track', key, param);
    }
  }

  public deactivate(): void {
    this.isActive = false;
  }
}

angular.module("BookingApp").service('FacebookPixelService', FacebookPixelService);
