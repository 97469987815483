import { ProductType } from "booking_app/types";
import { TravelType } from "booking_app/types/travel-type";

export class MastheadDescriptionController {
  static $inject = [
    "$rootScope",
  ];

  // Bindings
  travelType: string;
  whitelabel: string;
  productType: string;
  hasCustomHeaderText: boolean = false;

  constructor(
    private $rootScope: any,
  ) {}

  header(): string {
    if (this.hasCustomHeaderText && this.productType === ProductType.REDEEM && this.travelType === TravelType.HOTELS) {
      return `wl.${this.whitelabel}.${this.travelType}_header_redeem`;
    }
    // wl.whitelabelName.hotels_header
    return `wl.${this.whitelabel}.${this.travelType}_header`;
  }

  description(): string {
    // wl.whitelabelName.hotels_description
    return `wl.${this.whitelabel}.${this.travelType}_description`;
  }
}
