import { Bindings } from "booking_app/types";
import { TravelBreakdownController } from "./travel-breakdown.controller";

export class TravelBreakdownComponent {
  bindings: any = {
    duration: Bindings.ONE_WAY,
    roomCount: Bindings.ONE_WAY,
  };
  controller: any = TravelBreakdownController;
  template: string = `
    <div
      class="travel-breakdown-container"
      ng-if='$ctrl.globalStateService.isTravelType("hotels")'
      ng-class="{ 'default-direction': $ctrl.appSettings.isTravelBreakdownFlexDirectionDefault }">
      <span class="travel-breakdown-item" ng-if='$ctrl.duration == 1' translate= "txt.night_one"></span>
      <span
        class="travel-breakdown-item"
        ng-if='$ctrl.duration > 1'
        translate="night.count.other"
        translate-values="{ count: {{$ctrl.duration}} }"></span>
      <span
        class="travel-breakdown-item"
        ng-if='$ctrl.roomCount == 1'
        translate= 'Single Room Count'></span>
      <span
        class="travel-breakdown-item"
        ng-if='$ctrl.roomCount > 1'
        translate= 'Multiple Room Count' translate-values="{ count: '{{ $ctrl.roomCount }}' }"></span>
    </div>
    <div ng-if='$ctrl.globalStateService.isTravelType("cars")'>
      <span
        class="travel-breakdown-item"
        ng-if='$ctrl.duration == 1'
        translate= 'Single Day Count'></span>
      <span
        class="travel-breakdown-item"
        ng-if='$ctrl.duration > 1'
        translate= 'Multiple Days Count' translate-values="{ count: '{{ $ctrl.duration }}' }"></span>
    </div>
  `;
}

angular.module("BookingApp").component("travelBreakdown", new TravelBreakdownComponent());
