import { Bindings } from "booking_app/types";
import { RoomGuestFieldController } from "./room-guest-field.controller";

export class RoomGuestFieldComponent {
  bindings: any = {
    roomCount: Bindings.ONE_WAY,
    adultCount: Bindings.ONE_WAY,
    childCount: Bindings.ONE_WAY,
    childrenAges: Bindings.ONE_WAY,
    isShowingTotalGuest: Bindings.ONE_WAY,
    onUpdateRoomCount: Bindings.EXPRESSION,
    onUpdateAdultCount: Bindings.EXPRESSION,
    onUpdateChildCount: Bindings.EXPRESSION,
    onUpdateChildrenAges: Bindings.EXPRESSION,
  };
  transclude: any = {
    iconContent: "?iconContent",
  };
  controller: any = RoomGuestFieldController;
  template: string = `
    <div class="room-guest-field" click-outside="$ctrl.closeModal()">

      <div class="room-guest-field-input" ng-class="{ 'is-focused': $ctrl.isFocused }"
        ng-click="$ctrl.toggleModal()" tabindex=0 ng-keyup="$event.keyCode == 13 ? $ctrl.toggleModal() : null"
        aria-label="{{ $ctrl.summaryTextAriaLabel() }}" role="button">
        <span ng-transclude="iconContent"></span>
        <input
          class="field-input"
          type="text"
          readonly
          ng-value="$ctrl.summaryText()"
          ng-focus="$ctrl.openModal()"
          ng-if="!$ctrl.labelOnlySummaryText()"
        >
        <span class="field-input"
          ng-bind-html="$ctrl.summaryText()"
          ng-if="$ctrl.labelOnlySummaryText()">
        </span>
      </div>

      <div class="room-guest-field-modal" ng-if="$ctrl.modalOpen">
        <room-guest-counter
          room-count="$ctrl.roomCount"
          adult-count="$ctrl.adultCount"
          child-count="$ctrl.childCount"
          children-ages="$ctrl.childrenAges"
          on-update-room-count="$ctrl.updateRoomCount(count)"
          on-update-adult-count="$ctrl.updateAdultCount(count)"
          on-update-child-count="$ctrl.updateChildCount(count)"
          on-update-children-ages="$ctrl.updateChildrenAges(childrenAges)"
          close-modal="$ctrl.closeModal()"
        ></room-guest-counter>
      </div>

    </div>
  `;
}

angular.module("BookingApp").component("roomGuestField", new RoomGuestFieldComponent());
