import { DropdownFieldPopupController } from "./dropdown-field-popup-mobile.controller";
import { Bindings } from "booking_app/types";

export class DropdownFieldPopupComponent {
  bindings: any = {
    options: Bindings.ONE_WAY,
    currentValue: Bindings.ONE_WAY,
    onUpdate: Bindings.EXPRESSION,
    valueFormatter: Bindings.ONE_WAY,
  };

  controller: any = DropdownFieldPopupController;
  template: string = `
    <div class="selected-value" ng-click="$ctrl.beginSelection()" click-outside="$ctrl.closeModal()"
      ng-keypress="$event.key == 'Enter' && $ctrl.beginSelection()"
      tabindex="0">
      <span class="selected-text" ng-bind="$ctrl.selectedValue()"></span>
      <span class="dropdown-arrow"></span>
    </div>
    <form-popup-mobile ng-if="$ctrl.modalOpen">
      <title-content>
        <button
          type="button"
          class="fa fa-close close-tick"
          ng-click="$ctrl.closeModal()"
          aria-label="Close">
        </button>
      </title-content>
      <body-content>
        <div class="button-selection-container" modal-accessibility-lock="form-popup-mobile">
          <button ng-repeat='option in $ctrl.formattedOptions'
            ng-click="$ctrl.updateModelValue(option.value)"
            ng-bind-html="$ctrl.displayOption(option.display)"
            class="options"
            ng-class="{ 'selected': $ctrl.currentValue == option.value }">
          </button>
        </div>
      </body-content>
    </form-popup-mobile>
  `;
}

angular.module("BookingApp").component("dropdownFieldPopupMobile", new DropdownFieldPopupComponent());
