import { Bindings } from "booking_app/types";

export class ExclusiveCard {
  bindings: any = {
    title: Bindings.ONE_WAY,
    shortDescription: Bindings.ONE_WAY,
    image: Bindings.ONE_WAY,
    goToExclusiveBenefits: Bindings.EXPRESSION,
  };
  template: string = `
    <a class="card" ng-click="$ctrl.goToExclusiveBenefits()">
      <div class="image-container" ng-style= "{ 'background-image': 'url(' + $ctrl.image + ')' }"></div>
      <div class="card-message-container">
        <div class="title" ng-bind-html="$ctrl.title"></div>
        <div class="description-container">
          <div class="context" ng-bind-html="$ctrl.shortDescription"></div>
          <div class="fa fa-chevron-right"></div>
        </div>
      </div>
    </a>
  `;
}

angular.module("BookingApp").component("exclusiveCard", new ExclusiveCard());
