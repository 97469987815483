import { ApiDataService } from "booking_app/services/api-data.service";
import { CCValidationModals } from "./ccvalidation-modals";
import { AppSettings } from "booking_app/values/app-settings";

declare var moment: any;

export class CCValidationService {
  static $inject = [
    "$rootScope",
    "$filter",
    "$modal",
    "ApiDataService",
    "CCValidationModals",
    "AppSettings",
  ];

  private formInput: any;
  private isValidating: boolean = false;
  private scope: any;
  private displayChangePartnerCallback: any;

  private invalidCardStatus = {
    INVALID_CARD_HSBC: "HSBC",
    INVALID_CARD_VISA: "Visa",
  };

  constructor(
    private $rootScope: any,
    private $filter: any,
    private $modal: any,
    private apiDataService: ApiDataService,
    private ccValidationModals: CCValidationModals,
    private appSettings: AppSettings,
  ) { }

  public validateIntentIIN(iin, params = {}): Promise<boolean> {
    if (this.isValidating || !iin) {
      return;
    }

    this.isValidating = true;

    return (this.apiDataService.post("checkout/validate", {
      ...this.defaultParams(iin),
      ...params,
    }).then((response: any) => {
      if (typeof response === "boolean") {
        return response;
      } else if (response.status) {
        this.handleResponseByStatus(response);
        switch (response.status) {
          case "BONUS_VALIDATION":
            return true;
          case "VALIDATION_PASS":
            return true;
          case "INVALID_BIN_FOR_PARTNER":
            return false;
          case "INVALID_UOB_BIN_FOR_DLP":
            return false;
          case "EXCEED_LIMITED_BIN_USAGE":
            return false;
          default:
            return !this.invalidCardStatus[response.status];
        }
      } else {
        return true;
      }
    }).catch(() => {
      return true;
    }).finally(() => {
      this.isValidating = false;
    })) as Promise<boolean>;
  }

  public validate({
    scope,
    ccNumberInput,
    formInput,
    params = {},
    displayChangePartnerCallback,
  }: {
    scope: any;
    ccNumberInput: string;
    formInput: any;
    params?: {};
    displayChangePartnerCallback?: (selection: string) => void;
  }): void {
    const ccNumber = ccNumberInput || scope.bookingDetails.payment.card.number;
    this.formInput = formInput;
    this.scope = scope;
    this.displayChangePartnerCallback = displayChangePartnerCallback;

    if (this.isValidating || !ccNumber) {
      return;
    }

    this.validating(true);

    this.apiDataService.post(
      "checkout/validate",
      { ...this.defaultParams(ccNumber), ...params },
    ).then((response: any) => {
      this.validating(false);
      if (response.status) {
        this.handleResponseByStatus(response);
      }
    }, (error: any) => {
      this.validating(false);
      this.formInput.$setValidity("ccbin", true);
    });
  }

  public defaultParams(ccNumber: string): any {
    return {
      landingPageUrl: this.$rootScope.landingPage.url,
      partnerId: this.$rootScope.pointsPartner.id,
      creditCardBin: ccNumber.substring(0, 9),
      checkin: this.formatDate(this.$rootScope.checkInDate),
      checkout: this.formatDate(this.$rootScope.checkOutDate),
      product_type: this.productType(),
    };
  }

  private validating(isValidating: boolean): void {
    this.isValidating = isValidating;

    if (!this.formInput.$$element) {
      return;
    }
    if (isValidating) {
      this.formInput.$$element.addClass("validating");
    } else {
      this.formInput.$$element.removeClass("validating");
    }
  }

  // contains scope.hotel specific data, but this response will only be returned by checkout_credit_card_validator.rb
  // and not for whitelabel credit card validator
  // TODO: refactor when WLs also implement something similar to checkout_credit_card_validator
  // WLs validators only return the following
  private handleResponseByStatus(response: any): void {
    switch (response.status) {
      case "BONUS_VALIDATION":
        this.formInput?.$setValidity("ccbin", true);
        if (!this.scope?.partnerScore) {
          return;
        }
        if (this.scope.partnerScore.bonus === response.eligible_bonus) {
          return;
        }
        this.scope.partnerScore.bonus = response.eligible_bonus;
        const originalPartnerBonus = this.scope.hotelPrice.bonuses;
        if (!(response.eligible_bonus !== null && originalPartnerBonus !== response.eligible_bonus)) {
          return;
        }

        const valueChanged = response.eligible_bonus > originalPartnerBonus;
        this.ccValidationModals.displayBonusValidation(valueChanged);
        break;
      case "VALIDATION_PASS":
        this.formInput?.$setValidity("ccbin", true);
        if (!this.scope?.hotelPrice) {
          return;
        }
        if (this.scope.hotelPrice.bonuses) {
          this.scope.partnerScore.bonus = this.scope.hotelPrice.bonuses;
        }
        break;
      case "INVALID_BIN_FOR_PARTNER":
        this.formInput?.$setValidity("ccbin", false);
        if (response.matching_partner) {
          this.ccValidationModals.displayChangePartner(response, this.displayChangePartnerCallback);
        } else {
          // message for when if no matching_partner or pointsPartner data found
          this.ccValidationModals.displayInvalidCreditCardValidation(response.status);
        }
        break;
      case "INVALID_UOB_BIN_FOR_DLP":
        this.ccValidationModals.displayInvalidUobBinForDLP();
        break;
      case "EXCEED_LIMITED_BIN_USAGE":
        this.ccValidationModals.openExceedLimitedBinUsageModal();
        break;
      default:
        const invalidCard = this.invalidCardStatus[response.status];
        if (invalidCard) {
          this.formInput?.$setValidity("ccbin", false);
          if (this.appSettings.ccValidationUseCardErrorModal) {
            this.ccValidationModals.openCardErrorModal();
          } else {
            this.ccValidationModals.displayPaymentCardValidation(invalidCard);
          }
        } else {
          this.formInput?.$setValidity("ccbin", true);
        }
    }
  }

  private productType(): string | undefined {
    return this.$rootScope.useProductType() ?
      this.$rootScope.productTypeAdapter(this.$rootScope.globalState.productType) : undefined;
  }

  private formatDate(usFormatDate: string): string {
    return usFormatDate ? moment(usFormatDate, "MM/DD/YYYY").format("YYYY-MM-DD") : undefined;
  }

}

angular.module("BookingApp").service("CCValidationService", CCValidationService);
