import { ApiDataService } from "booking_app/services/api-data.service";
import { UserAgentChecker } from "booking_app/utils/user-agent-checker";

declare var Rollbar: any;

export class PaymentService {

  // static constants
  static readonly STATUS_IDLE: string = "IDLE"
  static readonly STATUS_STARTED: string = "STARTED"

  // class variables
  status: string = PaymentService.STATUS_IDLE
  redirectFrame: any

  static $inject = [
    "$q",
    "$window",
    "ApiDataService",
    "UserAgentChecker",
  ]

  constructor(
    private $q,
    private $window,
    private apiDataService: ApiDataService,
    private userAgentChecker: UserAgentChecker,
  ) { }

  public start(gatewayUrl): any {
    if (this.status !== PaymentService.STATUS_IDLE) {
      Rollbar.info(`Payments: Unable to create 3DS iframe. Status: ${this.status} URL: ${gatewayUrl}`);
      return;
    } //  only process when status is idle
    this.status = PaymentService.STATUS_STARTED
    let deferred = this.$q.defer()
    this.registerEventListener(deferred)
    this.createIframe(gatewayUrl)
    return deferred.promise
  }

  public getStoredPayments() {
    return this.apiDataService.get("payments")
  }

  public removeStoredPayment(nonce: string, payment_channel: string) {
    if (payment_channel === "stripe") {
      return this.apiDataService.delete(`payments/${nonce}`);
    } else if (payment_channel === "adyen") {
      return this.apiDataService.delete(`payments/adyen/${nonce}`);
    }
  }

  private createIframe(gatewayUrl): void {
    let className = "popup payment-redirect"
    let scrolling = "auto"
    if (this.userAgentChecker.isIOS()) {
      className += " ios"
      scrolling = "yes"
    }
    this.redirectFrame = angular.element(`<div class="${className}"><iframe id="iframe-3ds" class="iframe-page" scrolling="${scrolling}" frameborder="0" src="${gatewayUrl}"></iframe></div>`);
    angular.element(document.body).append(this.redirectFrame);
    Rollbar.info(`Payments: Trying to load 3DS iframe ${gatewayUrl}`);
    this.checkIframeLoaded(gatewayUrl);
  }

  private registerEventListener(deferred): void {
    var onMessage: EventListener = (e: MessageEvent) => {
      if (e.origin && e.origin.indexOf(window.location.hostname) === -1) {
        return; // ignore event from different domain
      }
      this.$window.removeEventListener("message", onMessage, true)
      this.$window.removeEventListener("beforeunload", this.preventNavigation)
      this.status = PaymentService.STATUS_IDLE
      if (e.data && e.data == "success") {
        this.redirectFrame.remove()
        deferred.resolve(e.data)
      } else {
        deferred.reject(e.data)
      }
    }
    this.$window.addEventListener("message", onMessage, true) // capture on bubble
    this.$window.addEventListener("beforeunload", this.preventNavigation)
  }

  private preventNavigation(e: BeforeUnloadEvent): void {
    // This is just going to display default browser prompt in the modern browser
    // the message entered here means nothing. it will just use the browser locale and display default text
    e.returnValue = "Please do not navigate during payment process."
  }

  private checkIframeLoaded(gatewayUrl): void {
    const paymentIframe: HTMLElement = document.getElementById("iframe-3ds");
    paymentIframe.onload = () => {
      Rollbar.info(`Payments: Successfully loaded 3DS iframe. ${gatewayUrl}`);
    };
  }
}

angular.module("BookingApp").service('PaymentService', PaymentService)
