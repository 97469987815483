import { SavedCardsController } from "./saved-cards.controller";
import { Bindings } from "booking_app/types/bindings";

export class SavedCardsComponent {
  bindings: any = {
    removeCreditCard: Bindings.EXPRESSION,
  };
  controller: any = SavedCardsController;
  templateUrl: string = "/html/components/saved_cards";
}

angular.module("BookingApp").component("savedCards", new SavedCardsComponent());
