import { ModalManager } from "booking_app/components/common/modal-manager";
import { ApiDataService } from "booking_app/services/api-data.service";
import AdyenCheckout from "@adyen/adyen-web";
import { AdyenCheckoutParams, AdyenFormService } from "booking_app/services/adyen-form.service";

type AdyenPaymentMethodResponse = {
  success: boolean,
};

export class AdyenChallengeModal {

  static $inject = [
    "$rootScope",
    "ApiDataService",
    "ModalManager",
    "AdyenFormService",
    "action",
  ];

  threeDSMethodURL: string;
  encodedData: string;

  constructor(
    private $rootScope: any,
    private apiDataService: ApiDataService,
    private modalManager: ModalManager,
    private adyenFormService: AdyenFormService,
    public action: any,
  ) {
    this.mountAction();
  }

  private mountAction(): void {
    const threeDSConfiguration: object = { challengeWindowSize: "02" };
    const actionParams: AdyenCheckoutParams = this.adyenFormService.adyenCheckoutParams();
    Object.assign(actionParams, { onAdditionalDetails: this.handleOnAdditionalDetails.bind(this) });
    AdyenCheckout(actionParams).then((checkout) => {
      checkout.createFromAction(this.action, threeDSConfiguration).mount("#adyen-3ds-action-container");
    });
  }

  private handleOnAdditionalDetails(state: any, _component: object): void {
    this.submit3dsData(state.data).then((response) => {
      this.$rootScope.$broadcast("adyen-3ds-completed");
      this.modalManager.close();
    });
  }

  private submit3dsData(data: object): Promise<AdyenPaymentMethodResponse> {
    return (this.apiDataService.post("payment_details", {
      data,
      booking_transaction_id: this.$rootScope.bookingTransactionId,
    })) as Promise<AdyenPaymentMethodResponse>;
  }
}

angular.module("BookingApp").controller("AdyenChallengeModal", AdyenChallengeModal);
