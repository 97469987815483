angular.module("BookingApp")
  .directive("translateSingular", [
    "TranslationUtils",
    "$rootScope",
    (TranslationUtils, $rootScope) => {
      return {
        restrict: "A",
        link: (scope, elem, attr) => {
          scope.$watch(
            () =>  $rootScope.selectedLocale,
            () => {
              const translatedValue = TranslationUtils.getSingularTranslation(attr.translateSingular);
              elem.html(translatedValue);
            });

          return;
        },
      };
    },
  ],
);
