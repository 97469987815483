import { AppSettings } from "booking_app/values/app-settings";
import { ProductType } from "booking_app/types";
import { ApiDataService } from "./api-data.service";
import { Hotel } from "booking_app/models";
import { HotelItem, MarketRate } from "booking_app/types";
import "./local_call_cache";
import { CurrenciesService } from "./currencies.service";
import { PayWithPointsCashService } from "./pay-with-points-cash.service";

declare var moment: any;

interface PriceResult {
  tax_inclusive: boolean;
  completed: boolean;
  hotels: Hotel[];
}

export class HotelUtilService {
  static $inject = ["$q", "$rootScope", "$routeParams",
  "LocalCallCacheService", "PayWithPointsCashService", "UtilsService",
  "AppTraffic", "SortService", "CurrenciesService",
  "AppSettings", "ApiDataService"];

  private landingPage: any;
  private pointsPartner: any;

  private hotelPriceDeferred: any;
  private singleRoomPriceDeferred: any;

  constructor(
    private $q: any,
    private $rootScope: any,
    private $routeParams: any,
    private localCallCacheService: any,
    private payWithPointsCashService: PayWithPointsCashService,
    private utilsService: any,
    private appTraffic: any,
    private sortService: any,
    private currenciesService: CurrenciesService,
    private appSettings: AppSettings,
    private apiDataService: ApiDataService,
  ) {
    this.landingPage = this.$rootScope.landingPage;
    this.pointsPartner = this.$rootScope.pointsPartner;
    this.hotelPriceDeferred = null;
    this.singleRoomPriceDeferred = null;
  }

  public buildUrl(path: string, bookingKey = null, couponCode = null): string {
    const url: string = `${path}?destination_id=${this.$rootScope.destinationId}` +
      `&checkin=${this.formatDate(this.$rootScope.checkInDate)}` +
      `&checkout=${this.formatDate(this.$rootScope.checkOutDate)}` +
      `&lang=en_US&currency=${this.$rootScope.selectedCurrency.code}` +
      `&landing_page=${this.getLandingPageUrl()}` +
      `${this.useProductType()}&partner_id=${this.$rootScope.pointsPartner.id}` +
      `&country_code=${this.$rootScope.selectedCountrySite.code}` +
      `${this.getBookingKey(bookingKey)}` +
      `${this.getGuestsValue()}` +
      `${this.getTrafficSource()}` +
      `${this.getSuppliers()}` +
      `${this.getCouponCode(couponCode)}`;
    return url;
  }

  public getDetails(): ng.IPromise<{}> {
    const url: string =  `hotels?destination_id=${this.$rootScope.destinationId}` +
      `&destination_type=${this.$rootScope.destinationType}${this.getLatOrLong("lat")}` +
      `${this.getLatOrLong("lng")}`;
    return this.apiDataService.get(url);
  }

  public getSingleDetail(id: string): ng.IPromise<{}> {
    return this.apiDataService.get(`hotels/${id}`);
  }

  public getPrices(): ng.IPromise<{}> {
    const cacheKey: string = `${this.$rootScope.destinationId}-${this.$rootScope.checkInDate}-` +
      `${this.$rootScope.checkOutDate}-${this.$rootScope.guests}-${this.$rootScope.selectedCurrency.code}-` +
      `${this.landingPage.id}-${this.pointsPartner.id}`;

    return this.localCallCacheService.cacheCall("prices", cacheKey, 300 , (deferred) => {
      let results = {};
      this.apiDataService.get(this.buildUrl("hotels/prices"))
        .then((data: PriceResult) => {
          if (!!data) {
            this.setTaxInclusiveState(data.tax_inclusive);
            results = {
              completed: data.completed,
              hotelPrices: data.hotels,
            };
          } else {
            results = {};
          }
          deferred.resolve(results);
        }, (data) => {
          deferred.reject(data || {});
        });
    }, (data) => {
      return data.completed;
    });
  }

  public getSinglePrice(hotelId: any): ng.IPromise<{}> {
    this.hotelPriceDeferred = this.$q.defer();
    this.apiDataService.get(this.buildUrl(`hotels/${hotelId}/price`))
      .then((data: PriceResult) => {
        this.setTaxInclusiveState(data.tax_inclusive);
        this.hotelPriceDeferred.resolve(data);
      }, (data) => {
        this.hotelPriceDeferred.resolve(data);
      });
    return this.hotelPriceDeferred.promise;
  }

  public cancelGetSinglePrice(reason: string): void {
    if (this.hotelPriceDeferred) {
      this.hotelPriceDeferred.resolve(reason);
    }
  }

  public getSingleRoomPrice(hotelId: string, bookingKey: string, couponCode = ""): ng.IPromise<{}> {
    this.singleRoomPriceDeferred = this.$q.defer();
    this.apiDataService.get(this.buildUrl(`hotels/${hotelId}/price`, bookingKey, couponCode))
      .then((data: PriceResult) => {
        this.setTaxInclusiveState(data.tax_inclusive);
        this.singleRoomPriceDeferred.resolve(data);
      }, (data) => {
        this.singleRoomPriceDeferred.resolve(data);
      });
    return this.singleRoomPriceDeferred.promise;
  }

  public cancelGetSingleRoomPrice(reason: string): void {
    if (this.singleRoomPriceDeferred) {
      this.singleRoomPriceDeferred.resolve(reason);
    }
  }

  public getSingleHotelReview(reviewId: string): ng.IPromise<{}> {
    const hotelSingleReviewDeferred: any = this.$q.defer();
    this.apiDataService.get(this.buildUrl(`reviews/${reviewId}`))
      .then((data: PriceResult) => {
        this.setTaxInclusiveState(data.tax_inclusive);
        hotelSingleReviewDeferred.resolve(data);
      }, (data) => {
        hotelSingleReviewDeferred.resolve(data);
      });
    return hotelSingleReviewDeferred.promise;
  }

  public getMarketSingleRate(marketRates: MarketRate[] = []): MarketRate[] {
    marketRates.forEach((marketRate) => {
      let rate: number;
      if (this.$rootScope.globalState.taxInclusive) {
        rate = marketRate.rate;
      } else {
        rate =  marketRate.rate_without_tax;
      }
      // Divide the market rate by roomCount
      const roomCount: number = this.$rootScope.roomCount;
      const nights: number = this.$rootScope.duration;
      marketRate.singleRate = rate / roomCount / nights;
    });
    return this.sortMarketRates(this.filterMarketRates(marketRates));
  }

  // filter out expedia compare rates except entertainer
  public filterMarketRates(marketRates: MarketRate[]): MarketRate[] {
    if (!marketRates) {
      return [];
    }

    if (this.appSettings.displayNonBookableMarketRates) {
      return marketRates;
    }

    return marketRates.filter((rate) => rate.booking_url);
  }

  // filter out expedia compare rates except entertainer
  public sortMarketRates(marketRates: MarketRate[]): MarketRate[] {
    this.sortService.int(marketRates, "singleRate", false);
    return marketRates;
  }

  // Filter out expedia compare rates except entertainer
  public updatePricePerNight(hotel: HotelItem) {
    if (!hotel) {
      return;
    }

    if (!hotel.price) {
      hotel.pricePerNight = 0;
      hotel.pricePerRoomPerNight = 0;
      return;
    }

    let pricePerNight: number = (hotel.price / this.$rootScope.duration);
    pricePerNight = this.currenciesService.convertFromUsd(pricePerNight);

    let pricePerRoomPerNight = pricePerNight / this.$rootScope.roomCount;
    if (this.$rootScope.selectedCurrency.decimalPlace === 0) {
      pricePerNight = Math.ceil(pricePerNight);
      pricePerRoomPerNight = Math.ceil(pricePerRoomPerNight);
    }

    hotel.pricePerNight = pricePerNight;
    hotel.pricePerRoomPerNight = pricePerRoomPerNight;
  }

  public collatePricesToDetails(hotelPrices, hotelDetails: HotelItem[]): any {
    if (!hotelPrices || (hotelPrices && hotelPrices.length === 0)) {
      return hotelDetails;
    }

    const numberOfNights: number = this.utilsService.numOfDays(
      this.$rootScope.checkInDate, this.$rootScope.checkOutDate);
    const roomCount: number = this.$rootScope.roomCount;

    for (let hotelDetail of hotelDetails) {
      let matched: boolean = false;
      hotelDetail.poor_lat_lon_info = (hotelDetail.latitude === 0 || hotelDetail.longitude === 0);
      hotelDetail.amenityRatings = {};
      if (hotelDetail.amenities_ratings) {
        for (const amenity of hotelDetail.amenities_ratings) {
          hotelDetail.amenityRatings[amenity.name] = amenity.score;
        }
      }

      for (const hotelPrice of hotelPrices) {
        if (hotelPrice.id === hotelDetail.id) {
          hotelDetail.priceWithTax = hotelPrice.lowest_price;
          hotelDetail.price = this.setPriceWithTax(hotelPrice);
          hotelDetail.voucher_type_id = hotelPrice.voucher_type_id || undefined;
          hotelDetail.score = hotelPrice.points;
          hotelDetail.company_points = hotelPrice.company_points || 0;
          hotelDetail.bonus = hotelPrice.bonuses;
          hotelDetail.searchRank = hotelPrice.searchRank;
          hotelDetail.violates_price_policy = !!hotelPrice.violates_price_policy;
          hotelDetail.source = hotelPrice.source;
          hotelDetail.max_points_payment = hotelPrice.max_points_payment;
          hotelDetail.max_cash_payment = hotelPrice.max_cash_payment;
          hotelDetail.base_points_payment = hotelPrice.base_points_payment;
          hotelDetail.base_cash_payment = hotelPrice.base_cash_payment;
          hotelDetail.available = this.isHotelAvailable(hotelDetail);
          hotelDetail.max_earn_on_redeem_points = hotelPrice.max_earn_on_redeem_points;
          hotelDetail.cash_non_fixed_discounts_by_tier = hotelPrice.cash_non_fixed_discounts_by_tier;
          hotelDetail.points_non_fixed_discounts_by_tier = hotelPrice.points_non_fixed_discounts_by_tier;
          hotelDetail.max_cash_payment_by_tier = hotelPrice.max_cash_payment_by_tier;

          if (this.appSettings.displayBestDealBadge) {
            hotelDetail.bestDealMultiplier = this.checkGoodDealMultiplier(hotelPrice);
          }

          if (this.$rootScope.landingPage.hasProductType(ProductType.REDEEM)) {
            hotelDetail = this.payWithPointsCashService.calculatePointsCashToPay(hotelDetail);
          }

          // Get the right compare rate from market rates
          hotelDetail.marketRates = this.getMarketSingleRate(hotelPrice.market_rates);
          this.updatePricePerNight(hotelDetail);

          // If admin price is sent
          if (hotelPrice.rate) {
            hotelDetail.lowestNetRate = hotelPrice.rate / roomCount / numberOfNights;
            hotelDetail.adminPricePerNight = hotelPrice.lowest_price / roomCount / numberOfNights;
            hotelDetail.margin = hotelPrice.margin;
            hotelDetail.compRate = hotelPrice.compare_rate / roomCount / numberOfNights;
          }

          // If complimentary nights
          if  (this.$rootScope.landingPage.hasProductType(ProductType.COMPLIMENTARY_NIGHTS) ||
              hotelPrice.original_lowest_price) {
            hotelDetail.complimentary_nights = hotelPrice.complimentary_nights;
            hotelDetail.complimentary_discount = hotelPrice.complimentary_discount;
          }

          // Old 443 whitelabels
          if (hotelPrice.original_lowest_price) {
            hotelDetail.original_lowest_price_per_room_per_night = hotelPrice.original_lowest_price_per_room_per_night;
          }

          // New 443 productype whitelabel
          if (this.$rootScope.landingPage.hasProductType(ProductType.COMPLIMENTARY_NIGHTS)) {
            hotelDetail.original_lowest_price_per_room_per_night = hotelPrice.base_cash_payment;
            hotelDetail.original_lowest_points_per_room_per_night = hotelPrice.base_points_payment;
          }

          if (hotelPrice.discount) {
            hotelDetail.discount = hotelPrice.discount;
            hotelDetail.originalPricePerRoomPerNight = Math.ceil(hotelDetail.pricePerRoomPerNight/(1 - hotelPrice.discount/100));
          }

          matched = true;
          break;
        }
      }

      // We need to set unmatched rates to null because they might have been set to some value
      // in one of the previous iterations, to prevent out-of-date prices to be
      // shown in results
      if (!matched) {
        hotelDetail.price = null;
        hotelDetail.available = false;
        hotelDetail.voucher_type_id = null;
      }
    }

    this.applyResultListModifiers(hotelDetails);
    return hotelDetails;
  }

  private setPriceWithTax(hotelPrice: any): number {
    return this.$rootScope.globalState.taxInclusive ?
      hotelPrice.lowest_price : hotelPrice.lowest_price_without_tax;
  }

  // Elal best deal badge
  private checkGoodDealMultiplier(hotelPrice: any): number {
    if (!hotelPrice && hotelPrice.lowest_price === 0) {
      return;
    }

    const bestDealValueBase: number = Math.floor(hotelPrice.lowest_price / this.appSettings.basePointsRatio);
    const bestDealValueMultiplier: number = Math.floor((hotelPrice.points + hotelPrice.bonuses) / bestDealValueBase);
    return bestDealValueMultiplier;
  }

  private getLatOrLong(type: string): string {
    const lat: string = this.$rootScope.destinationLat;
    const lng: string = this.$rootScope.destinationLng;

    if (type === "lat") {
      return lat && lat !== "0" ? `&lat=${lat}` : "";
    } else if (type === "lng") {
      return lng && lng !== "0" ? `&lng=${lng}` : "";
    }
  }

  private getBookingKey(bookingKey: string): string {
    return bookingKey ? `&booking_key=${bookingKey}` : "";
  }

  private getGuestsValue(): string {
    return this.$rootScope.guests ? `&guests=${this.$rootScope.guests}` : "";
  }

  private getTrafficSource(): string {
    const source = this.appTraffic.source;
    return source ? `&traffic_source=${source}` : "";
  }

  private getSuppliers(): string {
    const suppliers = this.$rootScope.routeParams.suppliers;
    return suppliers ? `&suppliers=${suppliers}` : "";
  }

  private getCouponCode(code: string): string {
    return code ? `&coupon_code=${code}` : "";
  }

  private formatDate(usFormatDate: string) {
    return moment(usFormatDate, "MM/DD/YYYY").format("YYYY-MM-DD");
  }

  private getLandingPageUrl() {
    return this.$rootScope.landingPage.url ? this.$rootScope.landingPage.url :  "";
  }

  private useProductType(): string {
    if (this.$rootScope.useProductType()) {
      const productType: string = this.$routeParams.productType ||
        this.$rootScope.globalState.productType;
      return `&product_type=${this.$rootScope.productTypeAdapter(productType)}`;
    } else {
      return "";
    }
  }

  private setTaxInclusiveState(isTaxInclusive: boolean): void {
    this.$rootScope.globalState.taxInclusive = isTaxInclusive;
  }

  private applyResultListModifiers(hotels: HotelItem[]): void {
    for (const modifier of this.$rootScope.globalState.search_results.modifiers) {
      modifier.perform(hotels);
    }
  }

  private isHotelAvailable(hotel: HotelItem): boolean {
    return (hotel.price && hotel.price > 0) ||
      (hotel.max_points_payment && hotel.max_points_payment > 0) ||
      (hotel.max_cash_payment && hotel.max_cash_payment > 0) ||
      !!hotel.voucher_type_id;
  }

}

angular.module("BookingApp").service("HotelUtilService", HotelUtilService);
