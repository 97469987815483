import { Bindings } from "booking_app/types";
import { HotelResultSortMobileController } from "./hotel-result-sort-mobile.controller";

export class HotelResultSortMobileComponent {
  controller: any = HotelResultSortMobileController;
  template: string = `
    <div class="hotel-result-sort-mobile">
      <button class="hotel-result-sort-mobile-button" ng-click="$ctrl.openModal()">
        <span ng-if="sortIconSource">
          <img ng-src="{{ $ctrl.$rootScope.const.cdnImageUrl }}/{{ sortIconSource }}" alt="">
        </span>
        <div class="sort-label">{{ "Sort" | translate }}</div>
      </button>
      <form-popup-mobile ng-if="$ctrl.modalOpen" max-modal-width="$ctrl.maxModalWidth" close-modal="$ctrl.closeModal()">
        <title-content>
          <div class="sort-title">
            <span>{{ "Sort" | translate }}</span>
          </div>
          <button class="fa fa-close close-tick" ng-click="$ctrl.closeModal()" aria-label="Close"></button>
        </title-content>
        <body-content modal-accessibility-lock="form-popup-mobile">
          <div class="sort-container">
            <button class="sort-options {{ $ctrl.sortOptionClass(opt) }}"
              ng-class="{ 'selected': opt==$ctrl.sortingService.current }"
              ng-click="$ctrl.selectSortOptions(opt)"
              ng-repeat="opt in $ctrl.sortingService.permittedOptions">
              <span translate="{{$ctrl.sortingService.options[opt].label}}"></span>
              <div class="fa fa-check check-select"></div>
            </button>
          </div>
        </body-content>
      </form-popup-mobile>
    </div>
  `;
}

angular.module("BookingApp").component("hotelResultSortMobile", new HotelResultSortMobileComponent());
