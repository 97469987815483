import { AppSettings } from "booking_app/values/app-settings";

export class CookieService {

  static $inject = ["ipCookie", "AppSettings"];

  private cookieLifetime: number;

  constructor(
    private ipCookie: any,
    private appSettings: AppSettings,
  ) {
    this.cookieLifetime = this.appSettings.cookieLifeinDays;
  }

  getCurrency(): string {
    return this.fetchCookie("currency");
  }

  setCurrency(value: string): void {
    this.setCookie("currency", value);
  }

  getPartner(): number {
    return this.fetchCookie("partner");
  }

  setPartner(value: number): void {
    this.setCookie("partner", value);
  }

  getLocale(): string {
    return this.fetchCookie("lang");
  }

  setLocale(value: string): void {
    this.setCookie("lang", value);
  }

  getLandingPage(): number {
    return this.fetchCookie("landingPage");
  }

  setLandingPage(value: number): void {
    this.setCookie("landingPage", value);
  }

  getGeoLocale(): string {
    return this.fetchCookie("geolocale");
  }

  getGulfResident(): string {
    return this.fetchCookie("gulfResident");
  }

  setGulfResident(value: string): void {
    this.setCookie("gulfResident", value);
  }

  fetchCookie(name: string): any {
    return this.ipCookie(name);
  }

  setCookie(name: string, value: any): void {
    this.ipCookie(name, value, { expires: this.cookieLifetime, path: "/" });
  }
}

angular.module("BookingApp").service("CookieService", CookieService);
