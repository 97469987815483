export class MapDistanceController {

  static $inject = [
    "$filter",
  ];

  // bindings
  private distance: string;

  constructor(
    private $filter: any,
  ) {}

  supplierDistance(): string {
    return this.$filter("localeNumberFmt")(this.distance, 2);
  }
}
