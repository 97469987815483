import { Banner, BannersService, BannerType } from "booking_app/services/banners.service";
import { ViewportSizes } from "booking_app/types/viewport-sizes";

export class CarouselBannerController {

  static $inject = [
    "$element",
    "$rootScope",
    "$timeout",
    "BannersService",
  ];
  // bindings
  groupName: string;
  type: BannerType;

  carouselBanners: Banner[];
  slickConfiguration: any;
  slickInitialized: boolean = false;
  constructor(
    private $element: any,
    private $rootScope: any,
    private $timeout: any,
    private bannersService: BannersService,
  ) {}

  public $onInit(): void {
    this.carouselBanners = this.bannersService.findBanners(this.groupName);
    this.preloadCarouselBannerImages();
    this.$timeout(() => {
      this.slickConfiguration = this.buildSlickConfiguration();
    }, 500);
  }

  public buildSlickConfiguration(): any {
    return {
      lazyLoad: "ondemand",
      infinite: true,
      autoplay: false,
      draggable: false,
      dots: true,
      pauseOnHover: true,
      accessibility: true,
      mobileFirst: true,
      arrows: true,
      event: {
        init: (event: any, slick: any) => {
          const currentSlideHeight = slick.$slides[0].offsetHeight;
          this.updateElementPositioning(slick, currentSlideHeight);
          this.triggerResizeEvent();
          this.$timeout(() => {
            this.adjustContentHeight(slick);
          }, 2000);
        },
        setPosition: (slick: any) => {
          this.setPositionListener(slick);
        },
        beforeChange: (
          _event: any,
          slick: any,
          _currentSlide: number,
          nextSlide: number,
        ) => {
          const nextSlideHeight: number = slick.$slides[nextSlide].offsetHeight;
          this.updateElementPositioning(slick, nextSlideHeight);
        },
      },
    };
  }

  private setPositionListener(slick: any): void {
    const slickElement = slick.target.slick;
    if (slickElement &&
      slickElement.$slideTrack[0].checkVisibility() &&
      !this.slickInitialized
    ) {
      this.slickInitialized = true;
      const watchWidthEvent = this.$rootScope.$watch("globalState.browserWidth", () => {
        let slideHeight: number = slickElement.$slides[0].offsetHeight;
        this.updateElementPositioning(slickElement, slideHeight);

        if (this.$rootScope.globalState.browserWidth < ViewportSizes.XS_MAX) {
          slickElement.$slides[0].getElementsByTagName("img")[0].onload = (_event) => {
            slideHeight = slickElement.$slides[0].offsetHeight;
            this.updateElementPositioning(slickElement, slideHeight);
          };
        }

        watchWidthEvent();
      });
    } else if (slickElement.$slideTrack[0].checkVisibility()) {
      this.$timeout(() => {
        this.adjustContentHeight(slickElement);
      }, 1000);
    }
  }

  private adjustContentHeight(slickElement): void {
    const activeSlickElement: HTMLElement = slickElement.$slides.filter(".slick-active")[0];
    if (
      this.$rootScope.globalState.browserWidth < ViewportSizes.XS_MAX &&
      !!activeSlickElement &&
      activeSlickElement.getElementsByTagName("slick-active").length > 0
    ) {
      const contentHeight: number = activeSlickElement.offsetHeight;
      const carouselBanner = this.$element[0].getElementsByClassName("carousel-banner")[0];
      carouselBanner.style.height = `${contentHeight}px`;
    }
  }

  private updateElementPositioning(slick: any, slideHeight: number): void {
    this.$timeout(() => {
      slick.$slider.height(slideHeight);
      slick.$slideTrack.height(slideHeight);

      if (this.$rootScope.globalState.browserWidth < ViewportSizes.XS_MAX) {
        const isDeepLinkBanner: boolean = slideHeight > 200;
        const arrowPosition = isDeepLinkBanner ? "95px" : slick.$slider.height() / 2 + "px";
        slick.$nextArrow[0].style.top = arrowPosition;
        slick.$prevArrow[0].style.top = arrowPosition;
        const dotPosition = isDeepLinkBanner ? "162px" : slick.$slider.height() - 30 + "px";
        if (!!slick.$dots) {
          slick.$dots[0].style.top = dotPosition;
        }
      }
    }, 200);
  }

  private triggerResizeEvent(): void {
    // Need to retriger window resize event to fix issues on safari showing to slides on modal
    const resizeEvent = window.document.createEvent("UIEvents");
    resizeEvent.initEvent("resize", true, false);
    window.dispatchEvent(resizeEvent);
  }

  private preloadCarouselBannerImages(): void {
    this.carouselBanners.forEach((banner: Banner) => {
      const bannerImg = new Image();
      bannerImg.src = banner.image_url;
      bannerImg.onload = (_event) => { this.onLoadImageBehavior(); };
    });
  }

  private onLoadImageBehavior(): void {
    const slickElement = this.$element[0].getElementsByTagName("slick")[0];
    slickElement.slick.reinit();
  }
}
