import { DateFormObject } from "./date-form-object";

declare var moment: any;

export function isValidDate(dateFormObject: DateFormObject): boolean {
  const { day, month, year } = dateFormObject;

  // We only need to do validation if the data is present, else we can depend on AngularJS's form validation
  if (!month || !year) {
    return true;
  } else {
    return moment(`${year.text}-${month.text}-${day ? day.text : '01'}`, 'YYYY-MM-DD').isValid();
  }
}

export function isFutureDate(dateFormObject: DateFormObject): boolean {
  const { day, month, year } = dateFormObject;

  // We only need to do validation if the data is present, else we can depend on AngularJS's form validation
  if (!month || !year) {
    return true;
  }

  const date = moment(`${year.text}-${month.text}-${day ? day.text : '01'}`, 'YYYY-MM-DD');
  const currentDate = moment();

  return date.isValid() && date.isAfter(currentDate);
}
