import { DateRangeSingleFieldMobileController } from "./date-range-single-field-mobile.controller";
import { Bindings } from "booking_app/types";

export class DateRangeSingleFieldMobileComponent {
  bindings: any = {
    startDate: Bindings.ONE_WAY,
    endDate: Bindings.ONE_WAY,
    minDaysToBookInAdvance: Bindings.ONE_WAY,
    minBookingDays: Bindings.ONE_WAY,
    onUpdate: Bindings.EXPRESSION,
    locale: Bindings.ONE_WAY,
    dateFormat: Bindings.ONE_WAY,
    enableSelectionOfDates: Bindings.ONE_WAY,
  };
  transclude: any = {
    iconContent: "?iconContent",
  };
  controller: any = DateRangeSingleFieldMobileController;
  template: string = `
    <div class="date-range-single-field-mobile"
      ng-class="{'date-range-opened': $ctrl.modalOpen}"
      click-outside="$ctrl.closeModal()">
      <div class="date-range-single-field-mobile-input">
      <span ng-click="$ctrl.beginDateSelection()" ng-transclude="iconContent" id="span-datepicker"></span>
        <input
          aria-labelledby="span-datepicker"
          class="field-input"
          type="text"
          readonly
          ng-value="$ctrl.formattedDate()"
          ng-focus="$ctrl.beginDateSelection()"
        >
      </div>

      <form-popup-mobile ng-if="$ctrl.modalOpen">
        <title-content>
          <button
            type="button"
            class="fa fa-close close-tick"
            ng-click="$ctrl.closeModal()"
            aria-label="Close">
          </button>
          <div class="date-summary">
            <div ng-class="$ctrl.startInfoFieldClass()">
              <div class="info-field-label">{{ "Check In" | translate }}</div>
              <div class="info-field-box">{{ $ctrl.formattedStartDate() }}</div>
            </div>
            <div class="number-of-nights">
              <span>{{ $ctrl.numberOfNights() }}</span>
              <i class="fa fa-moon-o"></i>
            </div>
            <div ng-class="$ctrl.endInfoFieldClass()">
              <div class="info-field-label">{{ "Check Out" | translate }}</div>
              <div class="info-field-box">{{ $ctrl.formattedEndDate() }}</div>
            </div>
          </div>
        </title-content>

        <body-content modal-accessibility-lock="form-popup-mobile" watch-focus-out=true>
          <div class="datepicker-container">
            <date-picker
              min-date="$ctrl.minDatepickerDate()"
              start-date="$ctrl.startDate"
              end-date="$ctrl.endDate"
              on-select-start-date="$ctrl.updateStartDate(value)"
              on-select-end-date="$ctrl.updateEndDate(value)"
              selection-state="$ctrl.selectionState"
              locale="$ctrl.locale"
              ng-keydown="$ctrl.keyPressEvent($event)"
            ></date-picker>
            <button
              type="button"
              class="btn-action"
              ng-click="$ctrl.closeModal()">
              <span> {{ "button.done" | translate }} </span>
            </button>
          </div>
        </body-content>
      </form-popup-mobile>

    </div>
  `;
}

angular.module("BookingApp").component("dateRangeSingleFieldMobile", new DateRangeSingleFieldMobileComponent());
