import { Bindings } from "booking_app/types";

import { MapDirectionController } from "./map-direction.controller";

export class MapDirectionComponent {
  bindings: any = {
    originLat: Bindings.ONE_WAY,
    originLng: Bindings.ONE_WAY,
    destinationLat: Bindings.ONE_WAY,
    destinationLng: Bindings.ONE_WAY,
    distance: Bindings.ONE_WAY,
    originAddress: Bindings.ONE_WAY,
    destinationAddress: Bindings.ONE_WAY,
    isModal: Bindings.ONE_WAY,
  };
  controller: any = MapDirectionController;
  template: string = `
    <map-distance
      distance="$ctrl.distance"
    ></map-distance>
    <div class="mapbox-direction-container">
      <div ng-click="$ctrl.openMap($event, $event.currentTarget)" tabindex="0">
        <span class="fa fa-map-marker"></span>
        <span class="view-in-map-text" translate="txt.show_on_map"></span>
      </div>
    </div>
    <form-popup-mobile
      ng-if="$ctrl.mapboxDirectionService.popupMobileOpen"
      max-modal-width="$ctrl.mapboxDirectionService.minimumBrowserWidth"
      close-modal="$ctrl.closePopupMobile()">
      <body-content modal-accessibility-lock="form-popup-mobile">
        <div class="max-size" id="resultMapContainer">
          <div class="map-close-button" ng-click="$ctrl.closePopupMobile()">
            <button class="btn-close-action">
              <em class="fa fa-times"></em>
            </button>
          </div>
          <div id="mapbox-direction-map"></div>
        </div>
      </body-content>
    </form-popup-mobile>
  `;
}

angular.module("BookingApp").component("mapDirection", new MapDirectionComponent());
