import "../services/format-service";

angular.module("BookingApp")
  .filter("shortenNumber", [
    "FormatService",
    (FormatService) => (
      (num, locale) => FormatService.summarize(num, locale)
    ),
  ])
  .filter("localeShortenNumber", [
    "FormatService", "$rootScope",
    (FormatService, $rootScope) => (
      (num) => FormatService.summarize(num, $rootScope.selectedLocale)
    ),
  ])
  .filter("numberFmt", [
    "FormatService",
    (FormatService) => (
      (num, locale, decimalPlace) => FormatService.number(num, locale, decimalPlace)
    ),
  ])
  .filter("adjustDecimals", [
    "FormatService",
    (FormatService) => (
      (num, locale, decimalPlace) => FormatService.adjustDecimals(num, locale, decimalPlace)
    ),
  ])
  .filter("localeNumberFmt", [
    "FormatService", "$rootScope",
    (FormatService, $rootScope) => (
      (num, decimalPlace) => FormatService.number(num, $rootScope.selectedLocale, decimalPlace)
    ),
  ]);
