require('../factories/bookings-factory')
require('../directives/booking-item')


angular.module('BookingApp')

.controller 'AccManageBookingsCtrl', [

  "$scope", "$rootScope", "$timeout", "$translate", "$modal"
  "AppSettings", "BookingsFactory", "GlobalStateService", "ModalManager"
  "SimpleModalService"
  ($scope, $rootScope, $timeout, $translate, $modal
  AppSettings, BookingsFactory, GlobalStateService, ModalManager
  SimpleModalService) ->

    $scope.pageState =
      filter: "upcoming"
      bookingCancelled: null
      confirmEmailSent: null
      loading: false
      page: 0
      pageSize: 6
      maxPage: 0
      startIndex: 0

    $scope.pageState.loading = true

    GlobalStateService.currentPage = "manage-booking"

    $scope.hotelNoResultIconUrl = "/#{AppSettings.tenant.toLowerCase()}/icons/hotel-not-available.png"

    $scope.defaultHomePageUrl = AppSettings.defaultTravelTypeInHomePage

    setupBookingList = ->
      $scope.pageState.loading = false
      $scope.pageState.page = 0
      $scope.pageState.maxPage = Math.ceil($scope.bookings.length / $scope.pageState.pageSize)
      calculateStartIndex()

    $scope.$on "cancelBookingRequested", (e, isSuccess) ->
      openCancelBookingRequestedModal(isSuccess)

    openCancelBookingRequestedModal = (isSuccess) ->
      if isSuccess
        SimpleModalService.open("cancel-booking-request-success-modal")
        .then(() ->
          $window.location.reload()
        )
      else
        SimpleModalService.open("cancel-booking-request-failed-modall")
        .then(() ->
          $window.location.reload()
        )

    # Default to show upcoming booking rather than all booking
    BookingsFactory.getUpcoming().$promise.then( (bookings) ->
      # Set as ascending check in date for upcoming bookings
      $scope.bookings = bookings
      setupBookingList()
    )

    $scope.formatToShortDate = (date) ->
      moment(date, "YYYY-MM-DD").format("ll")

    $scope.allBookings = ->
      $scope.pageState.filter = "all"
      $scope.pageState.loading = true
      BookingsFactory.query().$promise.then( (bookings) ->
        $scope.bookings = bookings
        setupBookingList()
      )

    $scope.upcomingBookings = ->
      $scope.pageState.filter = "upcoming"
      $scope.pageState.loading = true
      BookingsFactory.getUpcoming().$promise.then( (bookings) ->
        # Set as ascending check in date for upcoming bookings
        $scope.bookings = bookings
        setupBookingList()
      )

    $scope.completedBookings = ->
      $scope.pageState.filter = "completed"
      $scope.pageState.loading = true
      BookingsFactory.getCompleted().$promise.then( (bookings) ->
        $scope.bookings = bookings
        setupBookingList()
      )

    $scope.cancelledBookings = ->
      $scope.pageState.filter = "cancelled"
      $scope.pageState.loading = true
      BookingsFactory.getCancelled().$promise.then( (bookings) ->
        $scope.bookings = bookings
        setupBookingList()
      )

    $scope.bookingTabOptions = [
      'all',
      'upcoming',
      'completed',
      'cancelled'
    ]

    $scope.formatBookingTabOption = (option) ->
      switch option
        when "all" then "accounts.bookings.label.all_bookings"
        when "upcoming" then "accounts.bookings.label.upcoming_bookings"
        when "completed" then "accounts.bookings.label.completed_bookings"
        when "cancelled" then "accounts.bookings.label.cancelled_bookings"

    $scope.switchBookingTab = (value) ->
      switch value
        when "all" then $scope.allBookings()
        when "upcoming" then $scope.upcomingBookings()
        when "completed" then $scope.completedBookings()
        when "cancelled" then $scope.cancelledBookings()

    calculateStartIndex = ->
      $scope.pageState.startIndex = $scope.pageState.page * $scope.pageState.pageSize

    $scope.nextPage = ->
      if $scope.pageState.page + 1 < $scope.pageState.maxPage
        $scope.pageState.page++
        calculateStartIndex()

    $scope.prevPage = ->
      if $scope.pageState.page > 0
        $scope.pageState.page--
        calculateStartIndex()

]
