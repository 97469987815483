import { ApiDataService } from 'booking_app/services/api-data.service';
import { StateSelectOption } from "./state-select-option";
import { CountrySelectOption } from "../country-select";
import { AngularUtils } from "booking_app/utils";

export class StateSelectController {

  static $inject = [
    'ApiDataService',
  ];

  // bindings
  country: CountrySelectOption;
  value: string;
  formName: string;
  onUpdate: (obj: { value: StateSelectOption }) => void;

  internalValue: StateSelectOption;
  options: StateSelectOption[];

  constructor(
    private apiDataService: ApiDataService,
  ) { }

  $onChanges(changesObj): void {
    if (AngularUtils.hasBindingChanged(changesObj.value)) {
      this.internalValue = angular.copy(this.value);
    }
    if (AngularUtils.hasBindingChanged(changesObj.country)) {
      this.fetchStatesList('');
    }
  }

  displayOptions(option: StateSelectOption): string {
    return option && option.name;
  }

  onUpdateInternalValue(value: StateSelectOption): void {
    this.internalValue = value;
    this.onUpdate({ value: this.internalValue });
  }

  fetchStatesList(searchText: string): void {
    this.apiDataService.get(`countries/${this.country.code}/states?name=${searchText}`)
      .then((res: StateSelectOption[]) => {
        this.options = res;
      });
  }
}
