FetchCancellationPolicyTask = (BookingPolicyRunner) ->

  (hotelId, checkInDate) ->

    EMPTY_POLICY = {remarks: ""}
    MAX_RUNNER = 2
    @hotelId = hotelId
    @stopFetching = false
    @checkin = checkInDate
    @availableRunners = []
    @rooms = []
    @policy = {}

    @availableRunners.push(new BookingPolicyRunner(hotelId, checkInDate)) for i in [0...MAX_RUNNER]
    @allRunners = (runner for runner in @availableRunners)

    @cancel = () ->
      @stopFetching = true
      @allRunners.forEach (runner) -> runner.cancel()

    @addRooms = (rooms) =>
      for room in rooms when !@policy[room.key] && room.visible
        if (runner = @availableRunners.shift())
          @fetchCancellationPolicy(runner, room)
        else
          @rooms.push(room)

    @addRunner = (runner) =>
      runner.bookingKey = ""
      if room = @rooms.shift()
        @fetchCancellationPolicy(runner, room)
      else
        @availableRunners.push(runner)

    checkFreeCancellation = (policy) ->
      roomRefundable = policy.refundable == "full"
      freeCancellationBefore = policy.free_cancellation_before
      policy.freeCancellationText = roomRefundable && freeCancellationBefore
      return

    @fetchCancellationPolicy = (runner, room) =>
      return if @stopFetching
      @policy[room.key] = true
      runner.fetch(room.key).then((policy) =>
        @policy[runner.bookingKey] = policy
        checkFreeCancellation(policy) if policy
        @addRunner(runner)
      , () =>
        @policy[runner.bookingKey] = EMPTY_POLICY
        @addRunner(runner)
      )

    return


FetchCancellationPolicyTask.$inject = ['BookingPolicyRunner']

angular.module("BookingApp")
.factory 'FetchCancellationPolicyTask', FetchCancellationPolicyTask
