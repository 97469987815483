import { Bindings } from 'booking_app/types';
import { SpinnerLoaderController } from ".";
export class SpinnerLoaderComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      loadingMessage: Bindings.ONE_WAY,
    };
    this.controller = SpinnerLoaderController;
    this.templateUrl = '/html/components/spinner_loader';
  }
}

angular.module("BookingApp").component("spinnerLoaderComponent", new SpinnerLoaderComponent());
