import { AppSettings } from "booking_app/values/app-settings";
import { ProductType } from "booking_app/types";

declare var angular: any;

export class LandingPageService {
  static $inject = ["$rootScope", "AppSettings"];

  private landingPage: any = {};

  constructor(
    private $rootScope: any,
    private appSettings: AppSettings,
  ) {}

  public init(rawLandingPage: any) {
    this.landingPage = angular.copy(rawLandingPage);

    this.landingPage.isEarnOnly = this.landingPage.can_earn_miles;
    this.landingPage.useVoucherOnly = this.landingPage.accepts_free_night_voucher;
    this.landingPage.isCashVoucher = this.landingPage.accepts_cash_voucher;
    this.landingPage.isPayWithPoints = this.landingPage.can_pay_with_points;
    this.landingPage.isComplimentaryNights = this.landingPage.has_complimentary_nights;

    /**
     * Following landingPage variables are function assignments.
     * We pass bind(this) to access lexical scope of landing page
     * service when these methods are called from outside
     */
    this.landingPage.hasProductType = this.hasProductType.bind(this);
    this.landingPage.allowsVoucher = this.isAllowsVoucher.bind(this);
    this.landingPage.setUrl = this.setUrl.bind(this);
    this.landingPage.earnMiles = this.earnMiles.bind(this);
    this.landingPage.changePartnerLabel = this.changePartnerLabel.bind(this);
    this.landingPage.infographicUrl = this.infographicUrl.bind(this);
    this.landingPage.backgroundImgStyle = this.backgroundImgStyle.bind(this);
    this.landingPage.redeemHotel = this.redeemHotel.bind(this);
    this.landingPage.complimentaryOrRedeem = this.complimentaryOrRedeem.bind(this);

    this.landingPage.quickSearchInNewTab = this.quickSearchInNewTab();
    this.landingPage.minBookingDays = this.getMinBookingDays();
    this.landingPage.welcomeBoxHeader = this.getWelcomeBoxHeader();
    this.landingPage.welcomeBoxSubHeader = this.getWelcomeBoxSubHeader();
    this.landingPage.showPartners = this.showPartners();
    this.landingPage.voucherTypes = this.createVoucherTypes();

    this.landingPage.showResultHeader = this.showResultHeader;
    this.landingPage.showCheckoutPaymentForm = this.showCheckoutPaymentForm;
    this.landingPage.showCheckoutSummaryInfo = this.showCheckoutSummaryInfo;
    this.landingPage.showCheckoutFooter = this.showCheckoutFooter;

    this.landingPage.urlLink = "/" + this.landingPage.url;
    this.landingPage.canChangePartner = this.landingPage.can_change_partner;
    this.landingPage.noAvailableHotelsText = ERROR_TEXTS.NO_MATCHING_HOTELS_TEXT;
    this.landingPage.noAvailableHotelsSubtext = ERROR_TEXTS.NO_MATCHING_HOTELS_SUB_TEXT;
    this.landingPage.invalidSearchDatesText = ERROR_TEXTS.INVALID_SEARCH_DATE_TEXT;
    this.landingPage.invalidSearchDatesSubtext = ERROR_TEXTS.INVALID_SEARCH_DATE_SUB_TEXT;

    return this.landingPage;
  }

  private hasProductType(type: ProductType): boolean {
    if (this.appSettings.useProductType) {
      return type === this.$rootScope.globalState.productType;
    } else {
      switch (type) {
        case ProductType.EARN:
          return this.landingPage.isEarnOnly;
        case ProductType.REDEEM:
          return this.landingPage.isPayWithPoints;
        case ProductType.VOUCHER:
          return this.landingPage.useVoucherOnly;
        case ProductType.CASHVOUCHER:
          return this.landingPage.isCashVoucher;
        case ProductType.COMPLIMENTARY_NIGHTS:
          return this.landingPage.isComplimentaryNights;
      }
    }
  }

  private redeemHotel(): boolean {
    return this.landingPage.hasProductType(ProductType.REDEEM)
      || this.landingPage.hasProductType(ProductType.VOUCHER);
  }

  private complimentaryOrRedeem(): boolean {
    return this.landingPage.hasProductType(ProductType.REDEEM)
      || this.landingPage.hasProductType(ProductType.COMPLIMENTARY_NIGHTS);
  }

  private quickSearchInNewTab(): boolean {
    return this.landingPage.url === "deals" || this.landingPage.url === "special-feature";
  }

  /**
   * We need this method so that there is backward compaitibility
   * for old productypes. Reason is that some implementations
   * rely on 'landingPage.url' as well. When all the whitelabels are converted to
   * new product type we can get rid of this method and its the places we use it
   */
  private setUrl(whiteLabel: string, type: string): void {
    switch (type) {
      case ProductType.VOUCHER:
        type = "freenight";
        break;
      case ProductType.CASHVOUCHER:
        type = "cashvoucherearn";
        break;
    }
    this.landingPage.url = `wl-${whiteLabel.toLowerCase()}-${type}`;
  }

  private isAllowsVoucher(): boolean {
    return (this.landingPage.hasProductType(ProductType.CASHVOUCHER) &&
    this.landingPage.points_partner_id === this.$rootScope.pointsPartner.id)
    || this.landingPage.hasProductType(ProductType.VOUCHER);
  }

  private getMinBookingDays(): number {
    if (this.landingPage.hasProductType(ProductType.CASHVOUCHER) &&
    this.landingPage.points_partner_id === this.$rootScope.pointsPartner.id) {
      /**
       * Cash voucher earn DLPs with min 1 day because
       * either voucher is partner funded, or voucher amount is small
       * Not sure we still need this below condition. Added just in case from old logic
       */
      if (this.landingPage.url === "krisflyeramex-promo" || this.landingPage.url === "uob-gift") {
        return 1;
      }
      return 2;
    } else {
      return 1;
    }
  }

  private getWelcomeBoxHeader(): string {
    let textToShow: string;
    if (this.landingPage.url === "refer") {
      textToShow = "Sign up now and get <span class='red'>" +
      "<strong>1,000</strong> bonus miles or points</span> on your first booking";
    } else {
      textToShow = "Get access to <span class='red'>exclusive bonus offers!</span>";
    }

    return textToShow;
  }

  private getWelcomeBoxSubHeader(): string {
    let textToShow: string;
    if (this.landingPage.url === "refer") {
      textToShow = "";
    } else {
      textToShow = "Sign up now for the best deals and promotions";
    }
    return textToShow;
  }

  private showPartners(): boolean {
    return !this.landingPage.hasProductType(ProductType.VOUCHER);
  }

  private earnMiles(): boolean {
    return !(this.landingPage.hasProductType(ProductType.VOUCHER)
      || this.complimentaryOrRedeem());
  }

  private showResultHeader(): boolean {
    return this.earnMiles();
  }

  private showCheckoutPaymentForm(): boolean {
    return this.earnMiles();
  }

  private showCheckoutSummaryInfo(): boolean {
    return this.earnMiles();
  }

  private showCheckoutFooter(): boolean {
    return this.earnMiles();
  }

  private infographicUrl(): string {
    if (!this.$rootScope.selectedLocale) {
      return this.landingPage.infographic_url;
    } else if (this.$rootScope.selectedLocale.lang_code === "en") {
      return this.landingPage.infographic_url;
    } else {
      const url = this.landingPage.infographic_url;
      const fileType = url.substr(url.length - 4);
      const urlWithoutFileType = url.substring(0, url.length - 4);
      return `${urlWithoutFileType}.${this.$rootScope.selectedLocale.lang_code}${fileType}`;
    }
  }

  private changePartnerLabel(): string {
    let label: string;
    if (this.landingPage.change_partner_label) {
      label = this.landingPage.change_partner_label;
    } else {
      if (this.landingPage.hasProductType(ProductType.VOUCHER)) {
        label = "Redeem Voucher";
      } else if (this.landingPage.hasProductType(ProductType.REDEEM)) {
        label = "Redeem Points";
      } else {
        label = "Earn miles or points with";
      }
    }

    return label;
  }

  private backgroundImgStyle(): any {
    const image = {
      "background-image" : `url(${this.landingPage.background_image_url})`,
    };

    return image;
  }

  private createVoucherTypes(): any {
    const voucherTypes = {};
    if (this.landingPage.voucher_types) {
      angular.forEach(this.landingPage.voucher_types, (voucher) => {
        voucherTypes[voucher.id] = voucher.name;
      });
    }
    return voucherTypes;
  }

}

export const ERROR_TEXTS = {
  NO_MATCHING_HOTELS_TEXT: "earn_no_matching_hotels",
  NO_MATCHING_HOTELS_SUB_TEXT: "Try searching with another set of dates, destination",
  INVALID_SEARCH_DATE_TEXT: "search.landing_page.invalid_search_dates_text",
  INVALID_SEARCH_DATE_SUB_TEXT: "search.landing_page.invalid_search_dates_subtext",
};

angular.module("BookingApp").service("LandingPageService", LandingPageService);
