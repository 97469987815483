angular.module('BookingApp')

.directive 'watchWidth', [
  '$window'
  '$rootScope'
  '$timeout'
  ($window, $rootScope, $timeout) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        w = angular.element($window)
        $rootScope.globalState.browserWidth = w.width()
        checkResize = () ->
          prevWidth = $rootScope.globalState.browserWidth
          newWidth = w.width()
          $rootScope.globalState.browserWidth = newWidth
          $rootScope.globalState.marketRatesCount = 2 if newWidth < 768
          if(
            (prevWidth > 480 && newWidth <= 480) ||
            (prevWidth <= 480 && newWidth > 480) ||
            (prevWidth > 768 && newWidth <= 768) ||
            (prevWidth <= 768 && newWidth > 768) ||
            (prevWidth > 991 && newWidth <= 991) ||
            (prevWidth <= 991 && newWidth > 991)
            )
            $rootScope.$broadcast("width-break-point")
            $rootScope.$apply()
          return
        w.bind 'resize', checkResize
        checkResize()
        return
    }
]
