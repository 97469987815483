angular.module('BookingApp')

.factory 'LocalesFactory', () ->

  Locale = (locales) ->
    model = {}
    model.all = -> locales
    model.findByCode = (code) ->
      for l in model.all()
        return l if l.lang_code == code
    return model

  buildLocale = (locale) ->
    {
      code: locale.code
      text: locale.name
      country_code: locale.country_code
      lang_code: locale.lang_code
      flag_code: locale.flag_code
      comma: if useCommaAsDecimal(locale.code) then "," else "."
      numSeparator: numSeparator(locale.code)
    }

  useCommaAsDecimal = (code) ->
    code in ['da', 'de', 'es', 'fi', 'fr', 'gr',
             'id', 'it', 'lv', 'nl', 'no', 'pt-BR',
             'ru', 'sv', 'tr', 'vn']

  numSeparator = (code) ->
    switch code
      when 'da', 'de', 'es', 'fi', 'gr', 'id', 'it', 'lv', 'nl', 'no', 'pt-BR', 'tr', 'vn'
        return '.'
      when 'fr', 'ru', 'sv'
        return ' '
      else
        return ','

  init = (data) ->
    enabledLocales = []
    angular.forEach data, (d) -> enabledLocales.push buildLocale(d)
    Locale(enabledLocales)

  return {
    init: init
  }
