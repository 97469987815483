require('../services/hotel-util.service')
require('../services/search-filters')
require('../services/mapbox')
require('../services/search-sorting.service')
require('../services/business-filters.service')
require('../services/hotel-image.service')
require('../services/pay-with-points-cash.service')
require('../services/format-service')
require('../services/tracker')
require('../services/bonuses')
require('../services/facebook-pixel')
require('../services/pay-with-points-cash-complimentary-nights')
require('../services/points-cash-share.service')
require('../services/scroll-service')
require('../filters/filter')

require('../directives/hotel-details')
require('../directives/directives')
require('../directives/hotel-details-popup')
require('../services/whitelabel-translate.service')
require('../values/app-settings')

angular.module('BookingApp')
.controller 'ResultsCtrl', [

  '$scope', '$rootScope', '$timeout', '$interval', '$window',
  '$location', '$q', '$sce', '$filter', '$translate',
  'HotelIndexPrefetch', 'HotelUtilService',
  'SearchFiltersService', 'SearchSortingService',
  'BusinessFiltersService', 'HotelImageService', 'PayWithPointsCashService',
  'FormatService', 'AppSettings', 'TrackerService', 'MapboxService',
  "GlobalStateService", "PayWithPointsCashCompNights",
  "PointsCashShareService", "ScrollService",
  "WhitelabelTranslateService"

  ($scope, $rootScope, $timeout, $interval, $window,
  $location, $q, $sce, $filter, $translate,
  HotelIndexPrefetch, HotelUtilService,
  SearchFiltersService, SearchSortingService,
  BusinessFiltersService, HotelImageService, PayWithPointsCashService,
  FormatService, AppSettings, TrackerService, MapboxService,
  GlobalStateService, PayWithPointsCashCompNights,
  PointsCashShareService, ScrollService,
  WhitelabelTranslateService) ->

    $window.scrollTo(0, 0)
    GlobalStateService.currentPage = "search-result"

    hotelNameTimeout = null
    $scope.searchFilter = SearchFiltersService
    $scope.businessFilter = BusinessFiltersService
    $scope.sortingService = SearchSortingService
    $scope.earnOnBurn = AppSettings.payWithPoints.earnOnBurn
    $scope.customLoadingSearch = AppSettings.customLoadingSearch
    $scope.showVatInfo = AppSettings.showVatInfo
    $scope.hotelNoResultIconUrl = "/#{AppSettings.tenant.toLowerCase()}/icons/hotel-not-available.png"
    $scope.globalStateService = GlobalStateService
    $scope.mapboxService = MapboxService
    $scope.pointsCashShareService = PointsCashShareService
    $scope.whitelabelTranslate = WhitelabelTranslateService

    $rootScope.globalState.sortingService = SearchSortingService
    $rootScope.globalState.isCancellationPolicyError = false
    $rootScope.globalState.displaySearchBottomSheet = true
    $rootScope.globalState.searchCompleted = false

    showUnavailableHotelsForLandingPage = !$rootScope.landingPage.allowsVoucher()

    hotelDetails              = HotelIndexPrefetch
    $scope.hotels             = []
    searchedVisibleHotels     = []

    $scope.loadingText = if AppSettings.customResultLoadingMessage
      AppSettings.customResultLoadingMessage
    else if $rootScope.landingPage.earnMiles()
      "txt.search_result.loading.subtext.incredible_rewards"
    else
      "txt.search_result.loading.subtext.best_deals"

    $rootScope.globalState.search_results.ui = {
      hotelRatingsList:
        '5':0
        '4':0
        '3':0
        '2':0
        '1':0
    }

    # setup the hotel data for the HotelImageService service
    HotelImageService.initHotelImageData(HotelIndexPrefetch)

    hotels = () ->
      @hotels     = []
      @startIndex = 0
      @endIndex   = AppSettings.hotelsPerPage
      return

    hotels.prototype.getHotel = () ->
      return @hotels

    hotels.prototype.setHotel = (hotelCount) ->
      @hotels = hotelCount
      return

    hotels.prototype.resetLimiter = () ->
      @startIndex = 0
      @endIndex   = AppSettings.hotelsPerPage
      return

    $scope.showOff            = false
    visibleHotels             = []

    if $rootScope.landingPage.hasProductType("voucher")
      filterHotels = $filter('filterVoucherHotels')
    else if $rootScope.landingPage.complimentaryOrRedeem()
      filterHotels = $filter('filterAvailableHotels')
    else
      filterHotels = $filter('filterVisibleHotels')

    displayCurrentHotelSet = new hotels()

    preFilterHotels = (hotels) ->
      hotels = SearchFiltersService.preFilterByStarRating(hotels)
      if AppSettings.preFilterUnavailableHotels
        hotels = SearchFiltersService.filterUnavailableHotels(hotels)

      hotels

    filterChains = () ->
      visibleHotels = searchedVisibleHotels

      displayCurrentHotelSet.resetLimiter()

      if SearchFiltersService.priceFilterApplied
        visibleHotels = SearchFiltersService.filterUnavailableHotels(visibleHotels)

      if SearchFiltersService.query? && SearchFiltersService.query.name?
        visibleHotels = $filter('filter')(visibleHotels, SearchFiltersService.queryFn)

      if SearchFiltersService.priceFilterApplied || (SearchFiltersService.query? && SearchFiltersService.query.name != '')
        SearchFiltersService.fireFilteringEvent()

      if !($scope.landingPage.hasProductType("voucher") || $scope.landingPage.hasProductType("redeem"))
        visibleHotels = SearchFiltersService.filterByPriceRange(visibleHotels)
      if SearchFiltersService.voucherType
        visibleHotels = SearchFiltersService.filterByHotelVoucherType(visibleHotels)

      if $rootScope.landingPage.complimentaryOrRedeem()
        visibleHotels =
          SearchFiltersService.redeem_points.sift(visibleHotels)

      visibleHotels = SearchFiltersService.reviews.sift(visibleHotels)

      visibleHotels = BusinessFiltersService.filterByPopularCategory(visibleHotels)

      updateRatingCounts(visibleHotels)

      visibleHotels = SearchFiltersService.filterByStarRatings(visibleHotels)

      sortOption = $scope.sortingService.options[$scope.sortingService.current]
      visibleHotels = $filter('orderBy')(visibleHotels, sortOption.sort)

      SearchFiltersService.hotelCounters.displayed = FormatService.number(visibleHotels.length, $rootScope.selectedLocale)

      $scope.hotels = []
      pushToVisible()

      MapboxService.updateAddressPoints(visibleHotels)

      $scope.$apply() if !$scope.$$phase

      if $scope.hotels.length > 0
        $timeout () ->
          $scope.showOff = true
        , 500
      return

    scrollToTopOnMobile = () ->
      if AppSettings.mobileFirst || $rootScope.globalState.browserWidth <= 991
        $window.scrollTo(0, 0)

    resetHotelRatingList = () ->
      $scope.globalState.search_results.ui.hotelRatingsList =
        '5':0
        '4':0
        '3':0
        '2':0
        '1':0

    updateRatingCounts = (filteredHotels) ->
      resetHotelRatingList()
      for hotel in filteredHotels
        if hotel.available && hotel.rating
          $scope.globalState.search_results.ui.hotelRatingsList[parseInt(hotel.rating)]++

    pushToVisible = () ->
      return if visibleHotels.length == 0
      if $rootScope.routeParams.hotelId
        displayCurrentHotelSet.endIndex = visibleHotels.length
      $scope.hotels = $filter('limitTo')(visibleHotels, displayCurrentHotelSet.endIndex)
      pushSelectedHotelToTop()
      $timeout(trackAddedHotels, 0)
      return

    pushSelectedHotelToTop = () ->
      return if !$rootScope.routeParams.selectedHotelId

      selectedHotel = hotelDetails.find (hotel) ->
        hotel.id == $rootScope.routeParams.selectedHotelId
      return unless selectedHotel

      # remove duplicate hotel if hotel available
      selectedHotelIdx = $scope.hotels.findIndex (hotel) ->
        hotel.id == $rootScope.routeParams.selectedHotelId
      if selectedHotelIdx != -1
        $scope.hotels.splice(selectedHotelIdx, 1)

      # add hotel to top
      $scope.hotels.unshift(selectedHotel)

    trackAddedHotels = ->
      return unless $rootScope.globalState.searchCompleted
      endIndex = displayCurrentHotelSet.endIndex
      TrackerService.hotelsImpression(
        $scope.hotels[(endIndex - AppSettings.hotelsPerPage)...endIndex],
        {
          guests: $rootScope.guests
          currencyCode: $rootScope.routeParams.currency
          checkInDate: $rootScope.checkInDate
          checkOutDate: $rootScope.checkOutDate
        }
      )

    $scope.applyFilter = () ->
      if SearchFiltersService.queryFilter && SearchFiltersService.queryFilter.name
        SearchFiltersService.queryFilter.name.dirty = false

      if !$rootScope.globalState.isNewSearch
        filterChains()
        scrollToTopOnMobile()
      return

    $scope.triggerKeyupBusinessFilter = (field, $event) ->
      if $event.keyCode == 13 || $event.keyCode == 32
        $event.preventDefault()
        BusinessFiltersService.categoryFilters[field] = !BusinessFiltersService.categoryFilters[field]
        SearchFiltersService.toggleQuickFilter()

    $scope.triggerKeyupStarRatingFilter = (rating, $event) ->
      if $event.keyCode == 13 || $event.keyCode == 32
        $event.preventDefault()
        SearchFiltersService.v2StarRatings["star#{rating}"] = !SearchFiltersService.v2StarRatings["star#{rating}"]
        SearchFiltersService.toggleRating(rating)

    _progressBarPromise = null
    runProgressBarCounter = (counterValue) ->
      $scope.progressBarCounter = counterValue || 50

      progress1 = 'progressbar.text1'
      progress2 = 'progressbar.text2'
      progress3 = 'Just a few more seconds now.'

      $('#progress-bar').fadeIn('fast')
      $scope.progressBarText = progress1

      _progressBarPromise = $interval ->
        return if $scope.progressBarCounter <= 0
        $scope.progressBarCounter -= 1
        $scope.progressBarText    = progress2 if $scope.progressBarCounter == 20
        $scope.progressBarText    = progress3 if $scope.progressBarCounter == 10
        return
      , 1000, $scope.progressBarCounter
      return

    runProgressBarCounter()

    _pollHotelPricesResultsFailureCount = 0
    _searchCount = 0
    _hotelPricePoll = null

    pollCounter = 0
    # poll every 1 second for the first 3 times, after that poll every 5 seconds
    getPollTime = () ->
      pollTimeoutTime = if pollCounter < 3 then 1000 else 5000
      pollCounter++
      return pollTimeoutTime

    pollHotelPricesResults = () ->
      if $rootScope.searchDatesValid
        $scope.pollHotelPricesIsLoading = $rootScope.hotelResultStillPolling = true
        HotelUtilService.getPrices().then(pollHotelPricesSuccess, pollHotelPricesError)
      else
        $scope.showOff = true
        $rootScope.globalState.searchCompleted = true # DEPRECATED
        $rootScope.globalState.completed = true
      return

    pollHotelPricesSuccess = (result) ->
      $scope.hotelPrices      = result.hotelPrices
      $scope.searchCompleted  = result.completed
      $scope.globalState.searchCompleted = result.completed
      hotelDetails     = HotelUtilService.collatePricesToDetails($scope.hotelPrices, hotelDetails)
      searchedVisibleHotels = filterHotels(
        hotelDetails,
        $scope.searchCompleted,
        showUnavailableHotelsForLandingPage
      )

      searchedVisibleHotels = preFilterHotels(
        searchedVisibleHotels
      )

      SearchFiltersService.countAvailableHotels(searchedVisibleHotels)

      if !result.completed
        if $scope.hotelPrices && $scope.hotelPrices.length > 0 #fix for Zumata changing null to []
          filterChains()
          scrollToTopOnMobile()
        _hotelPricePoll = $timeout(pollHotelPricesResults, getPollTime())
        _searchCount   += 2
      else
        $interval.cancel(_progressBarPromise) if _progressBarPromise

        $rootScope.globalState.isNewSearch = false

        $scope.pollHotelPricesIsLoading = $rootScope.hotelResultStillPolling = false

        allHotels = if AppSettings.includeUnavailableInTotalCount
          hotelDetails # all hotels available in our static data
        else
          searchedVisibleHotels # only hotels with prices returned by our supplier

        SearchFiltersService.hotelCounters.all = FormatService.number(allHotels.length, $rootScope.selectedLocale)

        # init the map markers with all hotel data
        $timeout ()->
          MapboxService.loadAddressPoints(allHotels)
        , 1

        $timeout( ->
          updateProductPayWithPoints()
          SearchFiltersService.reviews.update_slider()
          filterChains()
          updateProductPriceSlider()
          scrollToTopOnMobile()
          $scope.$broadcast('refreshSlider')
          hotelId = $rootScope.routeParams.hotelId
          $scope.showOff = true
          if hotelId &&
            (visibleHotels.filter (hotel) -> hotel.id is hotelId).length
              $scope.processingScroll = true
              ScrollService.processingScroll = true
              scrollToVisitedHotel()
        , 50)


    pollHotelPricesError = () ->
      _pollHotelPricesResultsFailureCount++
      if _pollHotelPricesResultsFailureCount <= 5
        _hotelPricePoll = $timeout(pollHotelPricesResults, getPollTime())
      else
        $scope.pollHotelPricesIsLoading = $rootScope.hotelResultStillPolling = false

    pollHotelPricesResults()

    $scope.$on '$locationChangeStart', ->
      $timeout.cancel(_hotelPricePoll) if _hotelPricePoll
      $(window).unbind('scroll')
      return

    $scope.deliberatelyTrustDangerousSnippet = (desc) ->
      return $sce.trustAsHtml(desc)

    $scope.getHotelPriceDetailsFromAPI = (id) ->
      result = $.grep($scope.hotelPrices, (e) -> e.hotelId is id)
      return result[0] if result != undefined

    $scope.hotelResultsIsLoaded = (hotel) ->
      return hotel.price != undefined || $scope.searchCompleted

    $scope.$watch 'sortingService.current', ((newValue, oldValue) ->
      if newValue != undefined && newValue != oldValue
        $scope.sortingService.fireSortingEvent()
        $scope.applyFilter()
    ), true

    $scope.$watch 'searchFilter.qratings', ((newValue, oldValue) ->
      $scope.applyFilter() if newValue != oldValue
    ), true

    $scope.$watch 'searchFilter.selectedRatings', ((newValue, oldValue) ->
      $scope.applyFilter() if newValue != oldValue
    ), true

    $scope.$watch 'searchFilter.reviews.slider', ((newValue, oldValue) ->
      if newValue != oldValue
        SearchFiltersService.reviews.update_slider_sentiment()
        filterChains()
        scrollToTopOnMobile()
    ), true

    $scope.$watch 'searchFilter.query.name', ((newValue, oldValue) ->
      return if newValue == oldValue
      $timeout.cancel(hotelNameTimeout) if hotelNameTimeout
      hotelNameTimeout = $timeout( ->
        $scope.applyFilter()
        hotelNameTimeout = null
      , 250)
    ), true

    $scope.$watch 'pointsPartner', ((newValue, oldValue) ->
      $scope.applyFilter() if newValue != oldValue

      if oldValue and newValue and parseInt(newValue.id) != parseInt(oldValue.id)
        runProgressBarCounter(5) if $scope.searchCompleted
        pollHotelPricesResults()

      if $rootScope.pointsPartner.shortCurrency
        updateSortingAndFilterLabels()
    ), true

    $scope.$watch "selectedLocale", (newValue, oldValue) ->
      if newValue != oldValue
        HotelUtilService.getDetails().then (result) ->
          hotelDetails = result
          pollHotelPricesResults()
      if $rootScope.pointsPartner.shortCurrency
        updateSortingAndFilterLabels()

    updateSortingAndFilterLabels = ->
      $scope.sortingService.options["point-hilo"].label =
        $scope.whitelabelTranslate.translate("price_high_to_low", {
          partnersShortCurrency: $translate.instant($rootScope.pointsPartner.shortCurrency)
        })
      $scope.sortingService.options["pay-points-hilo"].label =
        $scope.whitelabelTranslate.translate("price_high_to_low", {
          partnersShortCurrency: $translate.instant($rootScope.pointsPartner.shortCurrency)
        })
      $scope.sortingService.options["pay-points-lohi"].label =
        $scope.whitelabelTranslate.translate("price_low_to_high", {
          partnersShortCurrency: $translate.instant($rootScope.pointsPartner.shortCurrency)
        })
      $scope.sortingService.options["distance-lohi"].label = $filter('translate')("Distance" + if $rootScope.destinationType == "city" then " to city center" else "")
      SearchFiltersService.redeem_points.update_slider()

    $scope.$on "currencyChanged", () ->
      if $rootScope.landingPage.complimentaryOrRedeem()
        # dont need to get new prices from server when currency changed
        # price is generated by our own calculation instead of supplier
        # and we dont need the updated HC rates coz its using different view
        updateProductPayWithPoints()
      else
        $location.search(angular.merge(
          $location.search(),
          { currency: $rootScope.selectedCurrency.code }
        ))
      return

    $rootScope.globalState.selectSortOptions = (sort) ->
      $rootScope.lockBody = ""
      $rootScope.globalState.displaySortPopup = false
      $rootScope.globalState.sortingService.current = sort

    MapboxService.setViewMode('list')
    $rootScope.mapboxState.view = "result-page"

    $rootScope.$on "kaligoFilterSearchResult", ->
      filterChains()
      scrollToTopOnMobile()
      return

    $rootScope.$watch 'globalState.showResultFilter', ((newValue, oldValue) ->
      if newValue is true
        $timeout () ->
          $rootScope.$broadcast('rzSliderForceRender')
          $scope.$broadcast('refreshSlider') #fix known venturocket bug on slider render when display is re-rendered in the DOM
        , 20
    ), true

    $scope.$on 'pointsCashSliderUpdate', ((event, data) ->
      updateProductPayWithPoints(true)
      filterChains()
      SearchSortingService.updateSortingOptionsForPayWithPoints($scope.sortingService.permittedOptions)
    ), true

    updateProductPayWithPoints = (force) ->
      return if !$rootScope.landingPage.complimentaryOrRedeem()
      force = false if !force
      redeemPointsRange = { min: 0, max: 0 }
      isFirstHotel = true
      angular.forEach hotelDetails, (product) ->
        if $rootScope.landingPage.hasProductType("redeem")
          product = PayWithPointsCashService.calculatePointsCashToPay(product)
        else
          product = PayWithPointsCashCompNights.calculatePointsCashToPay(product)

        pointsPayment = if $rootScope.globalState.showTotalNights
          product.points_payment
        else
          product.points_payment_per_night
        if pointsPayment && $scope.globalState.searchCompleted
          redeemPointsRange.max = pointsPayment if (redeemPointsRange.max < pointsPayment || isFirstHotel)
          redeemPointsRange.min = pointsPayment if (redeemPointsRange.min > pointsPayment || isFirstHotel)
          isFirstHotel = false

      if $scope.globalState.searchCompleted
        SearchFiltersService.redeem_points.set_min_points(redeemPointsRange.min, force)
        SearchFiltersService.redeem_points.set_max_points(redeemPointsRange.max, force)
        SearchFiltersService.redeem_points.update_slider()
      return

    updateProductPriceSlider = () ->
      $rootScope.durationExcludingComplimentaryNights = $rootScope.duration

      if $rootScope.landingPage.complimentaryOrRedeem()
        return

      ceilingPrice = { hP: 0, lP: 1000000000 }
      complimentary_nights = 0
      angular.forEach visibleHotels, (hotel) ->
        if hotel.price && hotel.available
          if hotel.complimentary_nights > complimentary_nights
            complimentary_nights = hotel.complimentary_nights
          ceilingPrice.hP = hotel.price if ceilingPrice.hP < hotel.price
          ceilingPrice.lP = hotel.price if ceilingPrice.lP > hotel.price

      $rootScope.durationExcludingComplimentaryNights -= complimentary_nights

      SearchFiltersService.setSearchCeilingPrice(ceilingPrice.hP)
      SearchFiltersService.setSearchBottomPrice(ceilingPrice.lP)
      SearchFiltersService.setPriceRangeOptions()
      SearchFiltersService.updatePriceSlider()
      return


    scrollToVisitedHotel = () ->
      hotelId = $rootScope.routeParams.hotelId
      while(!($scope.hotels.filter (hotel) -> hotel.id is hotelId).length)
        displayCurrentHotelSet.endIndex += AppSettings.hotelsPerPage
        pushToVisible()
        $scope.$apply()
      ScrollService.visitedHotel(1200)
      $timeout( ->
        $scope.processingScroll = ScrollService.processingScroll
      , 1200)

    # create the illusion of infinite scroll
    $(window).scroll ->
      distanceToBottomBeginLoading = if $rootScope.isKeyboardUser then 500 else 300
      if $scope.mapboxState.viewMode is 'list' && $(window).scrollTop() + $(window).height() > $(document).height() - distanceToBottomBeginLoading
        displayCurrentHotelSet.endIndex += AppSettings.hotelsPerPage
        pushToVisible()
        $scope.$apply()
      return

    return
]
.controller 'kaligoResultsCtrl', [
  '$scope', '$rootScope', '$filter', 'BonusesService',
  'SearchFiltersService', 'FacebookPixelService'
  ($scope, $rootScope, $filter, BonusesService,
  SearchFiltersService, FacebookPixelService) ->

    $scope.bonuses = {}

    BonusesService.fetch($rootScope.landingPage.url).then (result) ->
      $scope.bonuses = result
      $scope.currentBonuses = $scope.verifyHasBonuses()

    $scope.$watch 'pointsPartner', (newPartner) ->
      $scope.currentBonuses = $scope.verifyHasBonuses()

    $scope.verifyHasBonuses = ->
      if $rootScope.pointsPartner && $scope.bonuses.active
        (bonus for bonus in $scope.bonuses.active() when bonus.applicableForPartner($rootScope.pointsPartner.id))
      else
        []

    $scope.isPoiSearch = ->
      $rootScope.destinationType && $rootScope.destinationLat && $rootScope.destinationLng && $rootScope.destinationType != 'city'

    $scope.searchCountType = ->
      return 'RESULTS_SHOWING_HOTEL_COUNT_CITY_NO_PARTNER' if $rootScope.landingPage.hasProductType("voucher")
      return 'RESULTS_SHOWING_HOTEL_COUNT_POI' if $scope.isPoiSearch()
      'RESULTS_SHOWING_HOTEL_COUNT_CITY'

    $scope.toggleAdminData = (e) ->
      $scope.adminDataOn = !$scope.adminDataOn
      if e
        e.stopPropagation()

    $scope.$watch 'selectedCurrency', (() ->
      SearchFiltersService.updatePriceSlider()
    ), true

    $scope.isSelectedHotel = (hotel) ->
      return hotel.id == $rootScope.routeParams.selectedHotelId
]
