import { ModalManager } from "booking_app/components/common/modal-manager";
import { CheckoutValidationService } from "booking_app/services/checkout-validation/checkout-validation.service";
import { PointsAdjustmentService } from "booking_app/services/points-adjustment.service";

export class InsufficientPointsModal {

  static $inject = [
    "ModalManager",
    "CheckoutValidationService",
    "PointsAdjustmentService",
    "$timeout",
    "$rootScope",
  ];

  constructor(
    private modalManager: ModalManager,
    private checkoutValidationService: CheckoutValidationService,
    private pointsAdjustmentService: PointsAdjustmentService,
    private $timeout: any,
    private $rootScope: any, // had to add this because of some weird service calls on typescript services
  ) {
    this.setupListener();
  }

  onCloseButtonClicked(): void {
    this.closeModal();
  }

  closeModal(): void {
    this.modalManager.close();
  }

  adjustMilesSelf(): void {
    this.modalManager.close();
    this.scrollIntoSlider();
  }

  adjustMilesCall(): void {
    this.pointsAdjustmentService.adjustMiles();
    this.modalManager.close();
    this.scrollIntoSlider();
  }

  hasEnoughtPointsToCoverMinimum(): boolean {
    return this.pointsAdjustmentService.hasEnoughPointsToCoverMinimum();
  }

  private scrollIntoSlider(): void {
    const element = $(".rzslider:visible")[0]; // had to use jquery to find the visible elements
    if (element) {
      this.scrollIntoView(element);
    }
  }

  private scrollIntoView(element: any): void {
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle = absoluteElementTop - (window.innerHeight / 2);
    window.scrollTo(0, middle);
  }

  private setupListener(): void {
    // make sure the modal will close when cancellation policy shows error
    const watchCancellationPolicy = this.$rootScope.$watch("globalState.isCancellationPolicyError", (newValue, _) => {
      if (newValue) {
        this.closeModal();
        watchCancellationPolicy();
      }
    });
  }
}

angular.module("BookingApp").controller("InsufficientPointsModal", InsufficientPointsModal);
