declare var angular: any;

angular.module("BookingApp")
  .directive("clickOutside", [
    "$document", "$timeout",
    ($document, $timeout) => {
      return {
        restrict: "A",
        link: (scope, elem, attr, ctrl) => {
          const onClick = (event: JQueryEventObject) => {
            const isChild = elem[0].contains(event.target);
            const isSelf = elem === event.target;
            const isInside = isChild || isSelf;
            if (!isInside) {
              scope.$apply(attr.clickOutside);
            }
          };

          let onFocus: any;

          // add a focus handler to trigger similar clickoutside event
          // when focusing on the elements
          if (attr.keyboardSupport) {
            onFocus = (event: JQueryEventObject) => {
              const isChild = elem[0].contains(document.activeElement);
              const isSelf = elem === event.target;
              const isInside = isChild || isSelf;

              if (!isInside) {
                scope.$apply(attr.clickOutside);
              }
            };
          }

          $timeout(() => {
            $document.bind("click", onClick);
            if (onFocus) {
              $document.bind("focus", onFocus);
            }
          }, 100);

          scope.$on("$destroy", () => {
            $document.unbind("click", onClick);
            if (onFocus) {
              $document.unbind("focus", onFocus);
            }
          });
        },
      };
    },
  ]);
