import { Bindings } from "booking_app/types";
import { MenuNavigationController } from "./menu-navigation.controller";

export class MenuNavigationComponent {
  controller: any = MenuNavigationController;
  bindings: any = {
    active: Bindings.ONE_WAY,
    useSequenceIndex: Bindings.ONE_WAY,
  };
  template: string = `
    <menu-links
      ng-repeat="option in $ctrl.getCurrentLinks()"
      link-option="option"
      use-sequence-index="$ctrl.useSequenceIndex"
      tab-index="$ctrl.getTabIndex()">
    </menu-links>
  `;
}

angular.module("BookingApp").component("menuNavigation", new MenuNavigationComponent());
