import { Bindings } from "booking_app/types";
import { HotelLocationSearchController } from "./hotel-location-search.controller";
import "booking_app/directives/ui-select-destination-search";

export class HotelLocationSearchComponent {
  bindings: any = {
    label: Bindings.ONE_WAY,
    value: Bindings.ONE_WAY,
    showError: Bindings.EXPRESSION,
  };
  controller: any = HotelLocationSearchController;
  template: string = `
    <ui-select-destination-search
      close-on-blur
      aria-hide-on-blur
      ng-model="$ctrl.internalValue"
      theme="bootstrap"
      ng-class="{'invalid': $ctrl.showError()}"
      on-select="$ctrl.onSelectCallback($item, $model)">
      <ui-select-match
        aria-hide-on-empty
        placeholder="{{ $ctrl.label | translate }}"
        aria-label="{{$ctrl.internalValue}}"
        role="text">{{$ctrl.internalValue}}</ui-select-match>
      <ui-select-choices group-by="'type'" repeat="location in $ctrl.locations | orderBy: 'type'"
            refresh="$ctrl.fetchHotelsLocations($select.search)"
            refresh-delay="500">
        <span>
          {{location.label}}
        </span>
      </ui-select-choices>
      <ul class="ui-select-no-choice dropdown-menu" ng-show="$ctrl.hasNoLocation($select.search)"
        aria-hidden="{{ $select.open ? 'false' : 'true' }}">
        <li>
          {{ $ctrl.searchStatusMsg }}
        </li>
      </ul>
    </ui-select-destination-search>
    <span class="input-icon" ng-click="$event.preventDefault();"></span>
    <div class="dirtyMsg tooltips" ng-show="$ctrl.showError()"  aria-live="assertive">
      <span role="alert">{{ "wl.please_fill_in_a_destination" | translate }}</span>
    </div>
  `;
}

angular.module("BookingApp").component("hotelLocationSearch", new HotelLocationSearchComponent());
