import { DatePickerController } from "./date-picker.controller";
import { Bindings } from "booking_app/types";

export class DatePickerComponent {
  bindings: any = {
    onSelectStartDate: Bindings.EXPRESSION,
    onSelectEndDate: Bindings.EXPRESSION,
    selectionState: Bindings.ONE_WAY,
    startDate: Bindings.ONE_WAY,
    endDate: Bindings.ONE_WAY,
    locale: Bindings.ONE_WAY,
    minDate: Bindings.ONE_WAY,
    numberOfMonths: Bindings.ONE_WAY,
  };
  controller: any = DatePickerController;
  template: string = `
    <div id="aria-message-container" aria-live="assertive">
      {{ $ctrl.ariaDateCheckInCheckOut() }}
    </div>
    <input type="text" readonly style="display: none;" aria-describedby="aria-message-container">
    <div class="datepicker-container"></div>
  `;
}

angular.module("BookingApp").component("datePicker", new DatePickerComponent());
