import { CurrentPage, HotelItem, ViewportSizes } from "booking_app/types";
import { AngularUtils } from "booking_app/utils";
import { TrustyouService } from "booking_app/services/trustyou.service";
import { PageDiscoveryService } from "booking_app/services/page-discovery.service";

export class TrustYouController {
  static $inject = [
    "$analytics",
    "$modal",
    "$scope",
    "$timeout",
    "TrustyouService",
    "PageDiscoveryService",
  ];

  // bindings
  hotel: HotelItem;
  viewFullReview: boolean = false;
  scrollToReview: () => void;

  // we need this object because this component is modifying the hotel object directly
  internalHotel: HotelItem;
  formPopupOpen: boolean = false;
  maxModalWidth: ViewportSizes;
  bodyElement: HTMLElement;
  isHotelResultsPage: boolean;

  constructor(
    private $analytics: any,
    private $modal: any,
    private $scope: any,
    private $timeout: any,
    private trustyouService: TrustyouService,
    private pageDiscoveryService: PageDiscoveryService,
  ) {
    this.bodyElement = document.getElementsByTagName("body")[0];
    this.isHotelResultsPage = this.pageDiscoveryService.isOnCurrentPage(CurrentPage.SEARCH_RESULT);
  }

  $onChanges(changesObj) {
    if (AngularUtils.hasBindingChanged(changesObj.hotel)) {
      this.internalHotel = Object.assign({}, this.hotel);
      if (this.internalHotel.trustyou.score) {
        this.internalHotel.trustyou.ui = this.trustyouService.getScoreUIDetails(
          this.internalHotel.trustyou.score.kaligo_overall);
      }
    }
  }

  reviewCount(): number {
    if (this.internalHotel.trustyou.reviews) {
      return this.internalHotel.trustyou.reviews.summary.count;
    }
  }

  openReviewModal($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.trackEvent();

    this.loadReview();
    this.openModalInstance();
  }

  openFormPopupMobile($event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.trackEvent();
    this.loadReview();
    this.$scope.hotelDetail = this.internalHotel;
    this.addModalOpenClass();

    this.$timeout(() => {
      this.formPopupOpen = true;
    }, 100);

    this.addFormPoupClass();
  }

  closeModal($event?: any): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.formPopupOpen = false;

    this.$timeout(() => {
      this.removeModalOpenClass();
    }, 100);
  }

  stopPropagation($event): void {
    $event.stopPropagation();
    $event.preventDefault();
  }

  private addFormPoupClass(): void {
    // This is to make sure trust you reviews for mobile is still different from
    // the one that is not inside a form popup modal
    this.$timeout(() => {
      const trustYouReviewContainer: any =
        document.getElementsByClassName("body-content")[0]
          .getElementsByClassName("trustyou-review");

      for (const item of trustYouReviewContainer) {
        item.classList.add("trustyou-form-popup");
      }
    }, 250);
  }

  private addModalOpenClass(): void {
     this.bodyElement.classList.add("modal-open");
  }

  private removeModalOpenClass(): void {
     this.bodyElement.classList.remove("modal-open");
  }

  private trackEvent(): void {
    this.$analytics.eventTrack("hotel-review", {
      category: "click",
      label: `${this.internalHotel.id}-${this.internalHotel.name}`,
    });
  }

  private loadReview() {
    this.internalHotel.isLoading = true;
    this.getTYReviews()
      .then(() => {
        this.internalHotel.isLoading = false;
      });
  }

  /*
  TODO:
  We should remove this pattern of decorating the hotel object with information such as isLoading,
  trustyou UI information. We should just pass the data needed to the modal separately. In order to
  preserve the caching behaviour, we could instead cache the results in that modal component instead.
  */
  private openModalInstance() {
    this.$modal.open({
      animation: true,
      templateUrl: "/html/modals/trustyou_review_modal",
      controller: "TrustyouReviewModal",
      size: "lg",
      windowClass: "trustyou-modal",
      resolve: {
        hotel: () => this.internalHotel,
      },
    });
  }

  private getTYReviews(): Promise<any> {
    return this.trustyouService.getHotelReview(this.internalHotel.id)
      .then((result) => {
        if (result.error) {
          this.internalHotel.trustyou.reviewsError = true;
        } else {
          this.internalHotel.trustyou.reviews = result;
        }

        return result;
      });
  }
}
