require('../services/api-data.service')

angular.module('BookingApp')
.controller 'PressCtrl', [

  '$scope', '$location', '$routeParams', '$anchorScroll', 'ApiDataService'
  ($scope, $location, $routeParams, $anchorScroll, ApiDataService) ->

    $scope.page = $routeParams
    url = $location.path()

    $scope.isActiveTab = url.replace('/press/', '')
    $anchorScroll()

    $scope.pressReleases = []

    kaligopressUrl = 'https://kaligopress.herokuapp.com/?json=get_recent_posts'
    ApiDataService.jsonP(kaligopressUrl, {jsonpCallbacKParam: 'callback'})
    .then((result) ->
      $scope.pressReleases = result['posts']
      console.log($scope.pressReleases)
    )

    return

]
