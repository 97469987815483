import { ApiDataService } from "booking_app/services/api-data.service";

export class CityCountryBuilderService {
  static $inject = ["$rootScope", "ApiDataService"];

  private countries: any[];
  private cities: any[];

  constructor(
    private $rootScope: any,
    private apiDataService: ApiDataService,
  ) { }

  public buildList(tenant: string): void {
    this.apiDataService.getJSON(`${this.$rootScope.const.cdnUrl}/assets/app/${tenant}_cities.json`, false)
      .then((countries: any[]) => {
        this.countries = countries;
        this.cities = countries.reduce((cities, country) => {
          return cities.concat(country.cities.map((city) => {
            return Object.assign({ country }, city);
          }));
        }, []);
      });
  }

  get countryList(): any[] {
    return this.countries;
  }

  get cityList(): any[] {
    return this.cities;
  }
}
angular.module("BookingApp").service("CityCountryBuilderService", CityCountryBuilderService);
