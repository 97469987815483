require('../services/search-filters')
require('../services/mapbox-static')
require('../services/search-sorting.service')
require('../services/cookie.service')
require('../values/app-settings')

angular.module('BookingApp')
.controller 'kaligoSelectCtrl', [

  '$rootScope', '$scope', '$filter', '$timeout',
  'CookieService', 'SearchFiltersService',
  'SearchSortingService', 'AppSettings', 'MapboxStaticService', 'BusinessFiltersService'

  ($rootScope, $scope, $filter, $timeout,
  CookieService, SearchFiltersService,
  SearchSortingService, AppSettings, MapboxStaticService, BusinessFiltersService) ->

    $scope.searchFilter = SearchFiltersService
    $scope.businessFilter = BusinessFiltersService

    $scope.filterOption = {
      voucherOptions: []
      displayVoucherCategory: $rootScope.landingPage.hasProductType("voucher")
      displayPointSlider:
        (
          $rootScope.landingPage.hasProductType("redeem") ||
          $rootScope.landingPage.hasProductType("complimentary-nights")
        ) && $rootScope.searchDatesValid && !AppSettings.hideRedemptionRangeSlider
      displayCashSlider: (
        !($rootScope.landingPage.hasProductType("redeem") ||
        $rootScope.landingPage.hasProductType("voucher") ||
        $rootScope.landingPage.hasProductType("complimentary-nights")) &&
        $rootScope.searchDatesValid &&
        AppSettings.showCashSlider
      )
    }

    $scope.ratingArray = AppSettings.ratingArray

    # define the list of voucher options for the dropdown view
    translateVoucherOptions = () ->
      $scope.filterOption.voucherOptions = [{ id: 0, label: $filter("translate")("Show All Voucher Categories") }]
      showOnlyTxt = $filter("translate")("Show Only") + ": "

      if $rootScope.landingPage.voucher_types?
        for voucherType in $rootScope.landingPage.voucher_types
          $scope.filterOption.voucherOptions.push({ id: parseInt(voucherType.id), label: showOnlyTxt + $filter("translate")(voucherType.name) }) if voucherType

      $timeout( () ->
        $scope.$apply()
      , 10)
      return

    translateVoucherOptions()

    $("#partner-select").on "select2-selecting", (selected) ->
      $rootScope.pointsPartner = $rootScope.pointsPartners.findById(parseInt(selected.val))
      CookieService.setPartner(parseInt(selected.val))

    $scope.$watch 'searchFilter.voucherType', ((newValue, oldValue) ->
      if newValue != oldValue
        $('#voucher-select .select2-chosen').html(newValue)
        $rootScope.$broadcast('kaligoFilterSearchResult')
      ), true

    $rootScope.isSelect2DropDownOpen = false
    $rootScope.select2Open = false

    $scope.doFilterSelectOpen = () ->
      $scope.isFilterSelectOpen = true
      $scope.falseClick = true
      $rootScope.isSelect2DropDownOpen = true
      $scope.$broadcast('refreshSlider')

    $scope.doFilterSelectClose = () ->
      $scope.isFilterSelectOpen = false
      $scope.falseClick = false
      $rootScope.isSelect2DropDownOpen = false
      $rootScope.$broadcast('kaligoFilterSearchResult')

    $scope.doResetFilter = () ->
      $scope.searchFilter.reset()
      $scope.searchFilter.resetPriceRange()
      $rootScope.$broadcast('kaligoFilterSearchResult')
      $timeout( ->
        $rootScope.$broadcast('rzSliderForceRender')
      , 50)
      return

    $scope.$on "kaligoRefreshPriceSlider", ->
      $scope.$broadcast('rzSliderForceRender')
      return

    $("#sortopts-select, #partner-select, #voucher-select")
    .on "select2-opening", ->
      $rootScope.select2Open = true
      $rootScope.isSelect2DropDownOpen = true
      $scope.$apply()
    .on "select2-close", ->
      $rootScope.select2Open = false
      $rootScope.isSelect2DropDownOpen = false
      $scope.$apply()

    $rootScope.$watch 'selectedLocale',((newValue, oldValue) ->
      $timeout (updateHeader), 200
    ), true

    $scope.$watch 'pointsPartner', ((newValue, oldValue) ->
      $timeout (updateHeader), 200
    ), true

    for starRating in SearchFiltersService.selectedRatings
      $scope.searchFilter.v2StarRatings['star' + starRating] = true

    updateHeader = () ->
      translateVoucherOptions()
      return

    minimapRequest = MapboxStaticService.minimapUrl()
    minimapRequest.then((url) -> $scope.minimapUrl = url) if minimapRequest

    return

]
