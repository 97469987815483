export enum Modal {
  RESEND_CONFIRMATION_SUCCESS         = "resend-confirmation-success-modal",
  RESEND_CONFIRMATION_FAILED          = "resend-confirmation-failed-modal",
  CANCEL_BOOKING                      = "cancel-booking-modal",
  CANCELLING_BOOKING                  = "cancelling-booking-modal",
  CANCEL_BOOKING_SUCCESS              = "cancel-booking-success-modal",
  CANCEL_BOOKING_FAILED               = "cancel-booking-failed-modal",
  CANCEL_BOOKING_REQUEST              = "cancel-booking-request-modal",
  CANCEL_BOOKING_REQUEST_SUCCESS      = "cancel-booking-request-success-modal",
  CANCEL_BOOKING_REQUEST_FAILED       = "cancel-booking-request-failed-modal",
  GULF_RESIDENCY                      = "gulf-residency-modal",
  HOTEL_ROOM_UNAVAILABLE              = "hotel-room-unavailable-modal",
  FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE = "flights-expiry-back-to-results-page-modal",
  FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE = "flights-expiry-back-to-landing-page-modal",
  SESSION_EXPIRY                      = "session-expiry-modal",
  USER_INELIGIBILITY_MODAL            = "user-ineligibility-modal",
  IMPERSONATED_CONFIRMATION           = "impersonated-confirmation-modal",
  IMPERSONATED_READ_ONLY              = "impersonated-read-only-modal",
}
