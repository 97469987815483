export class UrlUtils {
  static $inject = ["$rootScope"];

  constructor(
    private $rootScope: any,
  ) { }

  imageUrl(path: string): string {
    return `${this.$rootScope.const.cdnImageUrl}${path}`;
  }

  constructUrl(rawString: string): URL {
    let url: URL;

    try {
      url = new URL(rawString);
    } catch (_) {
      return;
    }

    return url;
  }

  isValidHttpUrl(rawString: string): boolean {
    const url = this.constructUrl(rawString);
    return url?.protocol === "http:" || url?.protocol === "https:";
  }

  convertToHttpsUrl(rawUrl: string): string {
    const url = this.constructUrl(rawUrl);

    if (url?.protocol === "http:") {
      return `https:${rawUrl.slice(5)}`;
    } else {
      return rawUrl;
    }
  }
}

angular.module("BookingApp").service("UrlUtils", UrlUtils);
