import { Bindings } from "booking_app/types";
import { ComplimentaryPriceBoxController } from "./complimentary-price-box.controller";
export class ComplimentaryPriceBoxComponent {
  bindings: any = {
    complimentaryNights: Bindings.ONE_WAY,
    singleRoomPerNightWithTax: Bindings.ONE_WAY,
    priceWithTax: Bindings.ONE_WAY,
    duration: Bindings.ONE_WAY,
    buttonText: Bindings.ONE_WAY,
    searchCompleted: Bindings.ONE_WAY,
    ourRateText: Bindings.ONE_WAY,
    priceBoxAction: Bindings.EXPRESSION,
  };

  controller: any = ComplimentaryPriceBoxController;
  template: string = `
  <div class='complimentary-price-box' ng-if="!$ctrl.searchCompleted">
    <div class="price loading"></div>
    <div class="points color-points loading"></div>
    <div class="btn-action uppercase loading"></div>
  </div>

  <div class='complimentary-price-box' ng-if="$ctrl.searchCompleted">
    <div class="our-rate-container">
      <div class="rate-text" translate="wl.visa.our_rate" ng-if="!!$ctrl.ourRateText"></div>
      <div class="diamond-container">
        <img class="diamond-icon"
          ng-if="$ctrl.complimentaryNights"
          cdn-path="{{ $ctrl.diamondLogo() }}">
      </div>
    </div>

    <div class="price">
      <div class="currency-container">
        <span class="value" ng-bind="$ctrl.$rootScope.selectedCurrency.code | codeSymbolFmt"/></span>
        <span class="value" ng-bind="$ctrl.convertedOriginalPricePerRoomPerNightWithTax()"></span>
        <span class="per-night visible-xs">
          <span>/</span>
          <span translate="night"></span>
        </span>
        <div class="per-night hidden-xs">
          <span translate="per night"></span>
        </div>
      </div>
    </div>
    <div class="points color-points">
      <span translate="wl.visa.total_earn_for"
        translate-values="{ currency_symbol: '{{ $ctrl.$rootScope.selectedCurrency.code | codeSymbolFmt }}',
          amount: '{{ $ctrl.convertedTotalDiscountedPriceWithTax() }}',
          duration: '{{ $ctrl.duration }}' }"></span>
    </div>

    <div class="btn-action uppercase" translate="{{ $ctrl.buttonText }}", ng-click=$ctrl.priceBoxAction()></div>
  </div>
  `;
}

angular.module("BookingApp").component("complimentaryPriceBox", new ComplimentaryPriceBoxComponent());
