declare var angular: any;

export enum MapboxStateViewMode {
  List = "list",
  Map = "map",
  Modal = "modal",
}

export enum MapboxStateView {
  ResultPage = "result-page",
  DetailPage = "detail-page",
}

export class MapboxState {
  public viewMode: string = MapboxStateViewMode.List;
  public savedViewMode: string = MapboxStateViewMode.List;
  public view: string = MapboxStateView.ResultPage;
  public loaded: boolean = false;
  public hoveredHotel: {[key: string]: any} = {};
  public selectedHotel: {[key: string]: any} = {};
}

export function isValidViewMode(viewMode: string) {
  const viewModes = Object.keys(MapboxStateViewMode).map(e => MapboxStateViewMode[e]);
  return viewModes.indexOf(viewMode) > -1;
}

angular.module("BookingApp").value("MapboxState", MapboxState);
