require('../services/trustyou.service')
require('../services/search-url')
require('../services/hotel-image.service')
require('../services/trustyou.service')
require('../services/currencies.service')
require('../services/global-state.service')
require('../services/points-cash-payment-handling.service')

angular.module("BookingApp")
.directive "hotelDetailPopup", () ->
  restrict:     "E"
  templateUrl:  "/html/hotels/hotel_details_popup"
  controller:   "MarkerPopupCtrl"
  scope:        { hotel: "=" }

.directive "hotelDetailTooltip", () ->
  restrict:     "E"
  templateUrl:  "/html/hotels/hotel_details_popup"
  controller:   "MarkerPopupCtrl"
  scope:        { hotel: "=" }

.controller "MarkerPopupCtrl", [

  "$scope", "$rootScope", "AppSettings", "$filter", "TrustyouService",
  "SearchUrlService", "HotelImageService", "FormatService",
  "CurrenciesService", "PointsCashPaymentHandlingService"

  ($scope, $rootScope, AppSettings, $filter, TrustyouService,
    SearchUrlService, HotelImageService, FormatService,
    CurrenciesService, PointsCashPaymentHandlingService) ->

    $scope.selectedCurrency = $rootScope.selectedCurrency
    $scope.pointsPartner = $rootScope.pointsPartner
    $scope.linkTarget = AppSettings.openInTargetTab.markerPopUp
    $scope.landingPage = $rootScope.landingPage

    $scope.appSettings = AppSettings

    $scope.pointsPayment = 0
    $scope.pointsPaymentPerNight = 0

    $scope.showTotal = $rootScope.globalState.showTotalNights
    $scope.showCashBeforePoints = AppSettings.showCashBeforePoints
    $scope.earnOnRedeem = AppSettings.hotelDetailsTemplateConfig.earnOnRedeem

    updatePricePerNight = (hotel) ->
      return if !hotel
      if !hotel.price
        hotel.pricePerNight = 0
        hotel.pricePerRoomPerNight = 0
        return
      pricePerNight = (hotel.price / hotel.nightOfStay)
      pricePerNight = CurrenciesService.convertFromUsd(pricePerNight)
      pricePerRoomPerNight = pricePerNight / $rootScope.roomCount
      if $rootScope.selectedCurrency.decimalPlace == 0
        pricePerNight = Math.ceil(pricePerNight)
        pricePerRoomPerNight = Math.ceil(pricePerRoomPerNight)
      hotel.pricePerNight = pricePerNight
      hotel.pricePerRoomPerNight = pricePerRoomPerNight

    updatePointsPayment = () ->
      return if !$scope.landingPage.hasProductType("redeem")
      locale = $rootScope.selectedLocale
      hotel = $scope.hotel
      $scope.pointsPayment = FormatService.number(hotel.points_payment, locale, 0)
      $scope.pointsPaymentPerNight = FormatService.number(hotel.points_payment_per_night, locale, 0)
      $scope.cashPaymentPerNight = FormatService.number(hotel.cash_payment_per_night, locale, 0)
      $scope.cashPayment = FormatService.number(hotel.cash_payment, locale, 0)

    updateComplimentaryNight = () ->
      return unless $scope.hotel.complimentary_nights?
      hotel = $scope.hotel
      pricePerRoomPerNight = hotel.original_lowest_price_per_room_per_night
      $scope.pricePerRoomPerNight = CurrenciesService.convertFromUsd(pricePerRoomPerNight)
      $scope.totalPriceWithTax = CurrenciesService.convertFromUsd(hotel.priceWithTax)

    $scope.zeroFullCashPayment = () ->
      return PointsCashPaymentHandlingService.zeroFullCashPayment(
        $scope.hotel.cash_payment,
        $scope.hotel.points_payment
      )

    $scope.$watchCollection('hotel', (newhotel, oldhotel)->
      return if !$rootScope.selectedCountrySite || !newhotel
      $scope.hotel = newhotel
      if $scope.hotel && $scope.hotel.trustyou && $scope.hotel.trustyou.score
        $scope.hotel.trustyou.ui = TrustyouService.getScoreUIDetails($scope.hotel.trustyou.score.kaligo_overall.toFixed(1))
        $scope.hotel.trustyouClass = if $scope.hotel.trustyou.ui.color == 'positive' then "pos" else "neu"
      $scope.hotel.locationURL = SearchUrlService.createHotelDetailsUrl($scope.hotel)
      $scope.hotel.nightOfStay = $filter('getNumberOfDays')($rootScope.checkInDate, $rootScope.checkOutDate)
      $scope.hotel.roomCount = $rootScope.roomCount
      HotelImageService.initHotelImageData([$scope.hotel])
      $scope.hotel.defaultBackgroundImageStyle ||= HotelImageService.getDefaultHotelBgImageStyle()
      $scope.hotel.backgroundImageStyle ||= HotelImageService.getDefaultThumbImageStyle($scope.hotel.id)

      updateComplimentaryNight()
      updatePointsPayment()
    )

    $rootScope.$watch 'pointsPartner', ((newValue, oldValue) ->
      return if !$rootScope.selectedCountrySite || !$scope.hotel
      $scope.hotel.locationURL = SearchUrlService.createHotelDetailsUrl($scope.hotel)
      $scope.pointsPartner = newValue
    ), true

    $rootScope.$watch 'selectedCurrency', ((newValue, oldValue) ->
      return if newValue == oldValue
      $scope.selectedCurrency = newValue
    ), true

    $rootScope.$watch 'selectedLocale', ((newValue, oldValue) ->
      return if !$scope.hotel
      updatePointsPayment()
    ), true

]
