require('../services/page.service')
require('../values/app-settings')
require('../services/prismic.service')

angular.module('BookingApp')
.controller 'PageCtrl', [

  '$scope', '$rootScope', '$routeParams', '$location', '$sce', "$timeout"
  '$anchorScroll', 'PageService', 'KaligoConfig',
  'GlobalStateService', 'AppSettings'
  ($scope, $rootScope, $routeParams, $location, $sce, $timeout,
  $anchorScroll, PageService, KaligoConfig,
  GlobalStateService, AppSettings) ->

    return if KaligoConfig.ignoreAngularRoute

    pageContents = ["company", "terms"]

    GlobalStateService.currentPage = "static-content"

    $scope.page = $routeParams

    url = $location.path()
    page = $rootScope.isActiveTab = url.replace(/\/(company|press|terms)\//, '')
    page = 'terms' if page == 'terms_condition'

    $scope.globalState.showNavMenu = false
    $anchorScroll()

    $scope.pageContent = ""

    loadPageContent = () ->
      PageService.getPage(page, $rootScope.selectedLocale.lang_code)
      .then((result) ->
        $scope.pageContent = result["page"]
        $scope.trustedPageContent = $sce.trustAsHtml($scope.pageContent.content)
        $scope.isPrismicImplementation = PageService.hasPrismicImplementation()
        $timeout(() -> $anchorScroll())
      )

    $rootScope.$watch 'selectedLocale',((newValue) ->
      if newValue
        loadPageContent() if $scope.pageContent
    )

    loadPageContent()

    return

]
