import { CountrySelectOption } from "./country-select-option";
import { AngularUtils } from "booking_app/utils";
import { CountryService } from "booking_app/services/country.service";
import { SortService } from "booking_app/services/sort.service";
import { Country } from "booking_app/constants/countries";

export class CountrySelectController {
  static $inject = [
    "CountryService",
    "SortService",
    "$rootScope",
  ];

  // bindings
  value: CountrySelectOption;
  formName: string;
  secondaryPassenger: boolean = false;
  onUpdate: (obj: { value: CountrySelectOption }) => void;

  internalValue: CountrySelectOption;
  options: CountrySelectOption[];

  constructor(
    private countryService: CountryService,
    private sortService: SortService,
    private $rootScope: any,
  ) {
    this.options = [];
  }

  $onInit() {
    this.options = this.initCountryOptions();
    if (this.$rootScope.isUserLoggedIn()) {
      const countryObject: any = this.countryService.getCountry(
        this.$rootScope.userDetails.user.country,
      );
      this.initInternalValue(countryObject);
    }
  }

  $onChanges(changesObj): void {
    if (AngularUtils.hasBindingChanged(changesObj.value)) {
      if (this.value.id === null) {
        this.internalValue = null;
      } else {
        this.internalValue = angular.copy(this.value);
      }
    }
  }

  displayOptions(option: CountrySelectOption) {
    return option && option.text;
  }

  onUpdateInternalValue(value: CountrySelectOption) {
    this.internalValue = angular.copy(value);
    this.onUpdate({ value: this.internalValue });
  }

  private initCountryOptions(): CountrySelectOption[] {
    const options = this.countryService.getCountries().map(item => ({
      id: item.code,
      code: item.code,
      text: item.name,
    }));

    return this.sortService.string(options, "text");
  }

  private initInternalValue(countryObject: Country): void {
    if (!this.secondaryPassenger && countryObject) {
      this.internalValue = {
        id: countryObject.code,
        code: countryObject.code,
        text: countryObject.name,
      };
    } else {
      this.internalValue = null;
    }
  }
}
