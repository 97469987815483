angular.module("BookingApp")

.directive "setToSelf", () ->
  ($scope, element, attrs) ->

    elements = angular.element.find('a')
    angular.forEach elements, (el) ->
      element = angular.element(el)
      if ( element.attr('target') != '_blank')
        element.attr('target', '_self')
