import { UrlUtils } from "booking_app/utils";

export class BonusSignUpWidgetController {

  static $inject = ["UrlUtils"];

  // bindings
  registerAction: (formData: any) => void;
  closeBox: () => void;
  enableWelcomeBox: () => void;
  registerBonusForm: any;
  hasLoggedUser: boolean;

  constructor(
    private urlUtils: UrlUtils,
  ) { }

  submitBonus(): void {
    this.registerBonusForm.submitted = true;
    this.enableWelcomeBox();
    this.registerBonusForm = this.registerAction({formData: this.registerBonusForm});
  }

  closeIcon(): string {
    return this.urlUtils.imageUrl(`/icons/white-multiply.png`);
  }
}
