import { isValidViewMode, MapboxState, MapboxStateView, MapboxStateViewMode } from "../values/mapbox-state";
import { ViewportSizes } from "booking_app/types/viewport-sizes";

interface Coordinates extends Array<number> {
  0: number;
  1: number;
}

declare var angular: any;
declare var mapboxgl: any;
declare var MapboxDirections: any;

export class MapboxDirectionService {
  static $inject = [
    "AppSettings",
    "MapboxState",
    "$timeout",
    "$compile",
    "$rootScope",
    "$modal",
  ];

  // bindings
  origin: Coordinates;
  originAddress: string;
  destination: Coordinates;
  destinationAddress: string;

  public popupMobileOpen: boolean;
  public minimumBrowserWidth: number;

  private map: any;
  private directionsControl: any;
  private callbackButtonElem: HTMLElement;

  constructor(
    private appSettings: any,
    private mapboxState: MapboxState,
    private $timeout: any,
    private $compile: any,
    private $rootScope: any,
    private $modal: any,
  ) {
    this.minimumBrowserWidth = ViewportSizes.XS_MAX;
  }

  setCallbackButtonElement(elem: HTMLElement) {
    this.callbackButtonElem = elem;
  }

  toggleViewMode(): void {
    if (!this.validCoordinates(this.origin, this.destination)) {
      return;
    }

    if (this.mapboxState.viewMode === MapboxStateViewMode.List) {
      this.setupMapView("mapbox-direction-map");
      this.setViewMode(MapboxStateViewMode.Map);
      this.resize();
    } else if (this.mapboxState.viewMode === MapboxStateViewMode.Map) {
      this.removeMap();
      document.getElementById("mapbox-direction-map").innerHTML = "";
      this.setViewMode(MapboxStateViewMode.List);

      // focus on the callback button if navigated using keyboard
      if (this.callbackButtonElem) {
        this.$timeout(() => {
          this.callbackButtonElem.focus();
        }, 0);
      }
    } else if (this.mapboxState.viewMode === MapboxStateViewMode.Modal) {
      if (this.$rootScope.globalState.browserWidth <= this.minimumBrowserWidth) {
        this.popupMobileOpen = true;
        this.$timeout(() => {
          this.setupMapView("mapbox-direction-map");
          this.resize();
        });
      } else {
        this.openModalInstance();
      }
    }
  }

  public setViewMode(viewMode: string) {
    if (!isValidViewMode(viewMode)) {
      return;
    }

    this.mapboxState.viewMode = viewMode;

    if (this.mapboxState.view === MapboxStateView.ResultPage) {
      this.mapboxState.savedViewMode = viewMode;
    }
  }

  public resize(): void {
    if (this.map) {
      // Asynchronously call resize on map object, to cater for map resizing bug when map is not loaded
      this.$timeout(() => this.map.resize(), 1);
    }
  }

  public setupMapView(containerId): void {
    this.createMapboxglInstance(containerId);
    this.configureMap();
  }

  public removeMap(): void {
    if (!this.map) { return; }

    this.directionsControl.removeRoutes();
    this.map.removeControl(this.directionsControl);
    this.map.on("remove", () => this.map = null);
    this.map.remove();
    this.popupMobileOpen = false;
  }

  private createMapboxglInstance(containerId): void {
    mapboxgl.accessToken = this.appSettings.mapboxAPIKey;
    this.map = new mapboxgl.Map({
      container: containerId,
      style: "mapbox://styles/mapbox/streets-v10",
      center: this.origin,
      zoom: 16,
    });
  }

  private configureMap(): void {
    // Disable map rotation
    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();
    this.map.on("load", () => {
      this.setupDirections();
      this.setupMapResources();
    });

    this.map.addControl(new mapboxgl.NavigationControl(), "top-left");
  }

  private setupDirections(): void {
    if (this.directionsControl) {
      return;
    }

    this.directionsControl = new MapboxDirections({
      accessToken: this.appSettings.mapboxAPIKey,
      unit: "metric",
      profile: "mapbox/walking",
      interactive: false,
      controls: {
        inputs: false,
        instructions: false,
        profileSwitcher: false,
      },
    });
    this.directionsControl.on("route", (result) => {
      let waypoints = [this.origin, this.destination];
      if (result.route.length > 0) {
        const steps = result.route[0].legs[0].steps;
        waypoints = steps.map((step) => step.maneuver.location);
      }
      this.fitBounds(waypoints);
    });
  }

  // Setup direction paths and markers
  private setupMapResources(): void {
    this.map.addControl(this.directionsControl);
    this.directionsControl.setOrigin(this.origin);
    this.directionsControl.setDestination(this.destination);

    this.setupMapMarkerStyling();
  }

  private setupMapMarkerPopUp(source): void {
    this.map.addSource(source, {
      type: "geojson",
      data: {},
      cluster: true,
      clusterMaxZoom: 13,
      clusterRadius: 80,
    });
  }

  private setupMapMarkerStyling(): void {
    this.addMapLayer({
      id: "directions-route-line",
      type: "line",
      source: "directions",
      visibility: "none",
    });
    this.addMapLayer({
      id: "directions-route-line-casing",
      type: "line",
      source: "directions",
      visibility: "none",
      paint: {
        "line-color": "#fff",
      },
    });
    this.addMapLayer({
      id: "directions-route-line",
      type: "line",
      source: "directions",
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": this.appSettings.mapboxHotelMarkerColor,
        "line-width": 3,
        "line-dasharray": [0, 2],
      },
    });
    this.addMapLayer({
      id: "directions-origin-point",
      type: "circle",
      source: "directions",
      paint: {
        "circle-radius": 10,
        "circle-color": this.appSettings.mapboxHotelMarkerColor,
      },
      filter: [
        "all",
        ["in", "$type", "Point"],
        ["in", "marker-symbol", "A"],
      ],
    });
    this.addMapLayer({
      id: "directions-destination-point",
      type: "circle",
      source: "directions",
      paint: {
        "circle-radius": 10,
        "circle-color": this.appSettings.mapboxHotelMarkerColor,
      },
      filter: [
        "all",
        ["in", "$type", "Point"],
        ["in", "marker-symbol", "B"],
      ],
    });
  }

  private addMapLayer(layer): void {
    if (this.map.getLayer(layer.id)) {
      this.map.removeLayer(layer.id);
    }
    this.map.addLayer(layer);
  }

  private fitBounds(waypoints): void {
    this.map.fitBounds(this.getLngLatBounds(waypoints), { padding: 80 });
    // Had to do this as some places doesnt have waypoints therefore will causing issues on the mapbox
    const wl = waypoints.length;
    const destination: Coordinates[] =
      (waypoints && wl > 0) ? waypoints[wl - 1] : this.destination;
    const origin: Coordinates[] = (waypoints && waypoints.length > 0) ? waypoints[0] : this.origin;
    this.openPopup(destination, "destination", this.destinationAddress);
    this.openPopup(origin, "pickup", this.originAddress);
  }

  private getLngLatBounds(waypoints): Coordinates[] {
    const lng = waypoints.map((point) => point[0]);
    const lat = waypoints.map((point) => point[1]);
    return [
      [Math.min(...lng), Math.min(...lat)],
      [Math.max(...lng), Math.max(...lat)],
    ];
  }

  // Pop up Template Generation
  private popupTemplate(pickUpState, address): any {
    return this.compilePopUpDetailsTemplate(
      angular.element(
        `<div class="marker-popup">
          <map-direction-popup address="'${address}'" pick-up-state="'${pickUpState}'"></map-direction-popup>
        </div>`,
      ),
    );
  }

  private compilePopUpDetailsTemplate(tplElement) {
    const scope = this.$rootScope.$new();
    const el = this.$compile(tplElement)(scope);
    return el[0];
  }

  private openPopup(coordinates, pickUpState, address): void {
    const selectPopup = new mapboxgl.Popup({ closeOnClick: false, closeButton: false });

    selectPopup.setLngLat(coordinates)
      .setDOMContent(this.popupTemplate(pickUpState, address))
      .addTo(this.map);
  }

  private openModalInstance(): void {
    this.$modal.open({
      animation: true,
      templateUrl: "/html/modals/mapbox_direction_modal",
      controller: "MapboxDirectionModal",
      size: "map-lg",
      windowClass: "map-center-modal",
      backdropClass: "gallery-backdrop",
    });
  }

  private validCoordinates(origin: Coordinates, destination: Coordinates): boolean {
    return (!!origin[0] && !!origin[1]) && (!!destination[0] && !!destination[1]);
  }
}

angular.module("BookingApp").service("MapboxDirectionService", MapboxDirectionService);
