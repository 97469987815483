angular.module("BookingApp")
.constant("ParseTrafficParams", [
  "$route", "UtilsService",
  ($route, utilsService) => {
    utilsService.parseTrafficParams($route.current.params);
  },
])
.constant("FetchHotelDetails", [
  "$route", "$rootScope", "$window", "UtilsService", "HotelUtilService",
  ($route, $rootScope, $window, utilsService, HotelUtilService) => {
    const invalidRouteVersion: boolean = utilsService.parseRouteParams($route.current.params);
    if (invalidRouteVersion) {
      $window.location.href = `/${$rootScope.landingPage.url}`;
    } else {
      return HotelUtilService
        .getDetails();
    }
  },
])
.constant("FetchSingleHotelDetail", [
  "$route", "$rootScope", "$window", "UtilsService", "HotelUtilService",
  ($route, $rootScope, $window, utilsService, HotelUtilService) => {
    const invalidRouteVersion: boolean = utilsService.parseRouteParams($route.current.params);
    if (invalidRouteVersion) {
      $window.location.href = `/${$rootScope.landingPage.url}`;
    } else {
      return HotelUtilService.getSingleDetail($rootScope.hotelId);
    }
  },
])
.constant("FetchDestinationInfo", [
  "$route", "$rootScope", "$window", "UtilsService", "DestinationsService",
  ($route, $rootScope, $window, utilsService, destinationsService) => {
    const invalidRouteVersion: boolean = utilsService.parseRouteParams($route.current.params);
    if (invalidRouteVersion) {
      $window.location.href = `/${$rootScope.landingPage.url}`;
    } else {
      return destinationsService.fetch($rootScope.destinationId).then((result) => {
        return destinationsService.checkGulfResidency(result);
      });
    }
  },
])
.constant("FetchMemberships", [
  "KaligoMembership",
  (kaligoMembership) => {
    return kaligoMembership().fetchAll();
  },
])
.constant("FetchReferrals", [
  "ReferralHistoryService",
  (referralHistoryService) => {
    return referralHistoryService();
  },
])
.constant("ParseCarsRouteParams", [
  "$route", "UtilsService",
  ($route, utilsService) => {
    utilsService.parseRouteCarsParams($route.current.params);
  },
])
.constant("ParseFlightsRouteParams", [
  "$route", "UtilsService",
  ($route, utilsService) => {
    utilsService.parseRouteFlightsParams($route.current.params);
  },
])
.constant("PayAnyoneRefresh", [
  "PaymentMethodService",
  (paymentMethodService) => {
    if (paymentMethodService.showPayAnyone) {
      window.location.reload();
    }
  },
])
.constant("RedirectSchemeUrl", [
  "AlternateRewardSchemeService",
  (AlternateRewardSchemeService) => {
    return AlternateRewardSchemeService.perform();
  },
]);
