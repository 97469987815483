require('../trustyou.service')

angular.module('BookingApp')
.service "ReviewsFilterService", [

  "$rootScope", "$timeout", "TrustyouService"
  ($rootScope, $timeout, TrustyouService) ->

    self = this

    default_review_scores = { min: 2.5, max: 5 }

    this.filter_applied = false
    this.review_scores = angular.copy(default_review_scores)

    slider_timeout = null
    this.adjust_slider = () ->
      if abs(self.review_scores.min,2.5) > 0.1 || abs(self.review_scores.max, 5) > 0.1
        self.filter_applied = true
      else
        self.filter_applied = false

    update_on_change = ->
      $timeout.cancel(slider_timeout) if slider_timeout
      self.review_scores.min = self.slider.min
      self.review_scores.max = self.slider.max

      self.filter_applied = true

      self.adjust_slider()
      slider_timeout = $timeout( ->
        $rootScope.$broadcast('kaligoFilterSearchResult')
        slider_timeout = null
      , 200)
      return

    this.slider = {
      min: self.review_scores.min
      max: self.review_scores.max
      min_sentiment: ''
      max_sentiment: ''
      options: {
        floor: 2.5
        ceil: 5
        step: 0.1
        precision: 2
        onChange: update_on_change
        translate: (value) ->
          return ""
      }
    }

    this.reset = () ->
      angular.copy(default_review_scores, self.review_scores)
      self.update_slider()
      self.filter_applied = false

    this.update_slider_sentiment = () ->
      self.min_sentiment = TrustyouService.getScoreUIDetails(self.slider.min)
      self.max_sentiment = TrustyouService.getScoreUIDetails(self.slider.max)

    abs = (a, b) ->
      Math.abs(a - b)

    this.update_slider = () ->
      self.slider = {
        min: self.review_scores.min
        max: self.review_scores.max
        options: {
          hidePointerLabels: true
          floor: 2.5
          ceil: 5
          step: 0.1
          precision: 2
          onChange: update_on_change
          translate: (value) ->
            return ""
        }
      }

      self.update_slider_sentiment()

      $timeout( ->
        $rootScope.$broadcast('rzSliderForceRender')
      , 50)
      return

    this.sift = (hotel_records) ->
      return hotel_records if !self.review_scores.min

      filtered_hotels = []
      hotel_records ||= []

      for hotel in hotel_records
        score = if hotel.trustyou then hotel.trustyou.score.kaligo_overall else 2.6
        score = 2.6 if score <= 2.6

        filtered_hotels.push hotel if (score >= self.slider.min and score <= self.slider.max)

      return filtered_hotels


    return this
]
