import { Booking } from "../models";

declare var angular: any;
declare var moment: any;

type BookingsCtrlUrlParams = {
  access_token: string,
}

export class BookingDetailUtils {

  private bookingsService: any;
  private bookingReference: string;
  private accessToken: string;
  private usersService: any;
  private booking: Booking;
  private pointsPartner: any;

  private bookingIsPolling: boolean;

  // inject variables
  private $rootScope: any;
  private $routeParams: any;
  private $location: any;
  private $q: any;
  private AppSettings: any;

  static $inject = [
    "$rootScope",
    "$routeParams",
    "$location",
    "$q",
    "BookingsService",
    "UsersService",
    "AppSettings",
  ]

  constructor(
    $rootScope,
    $routeParams,
    $location,
    $q,
    BookingsService,
    UsersService,
    AppSettings,
  ) {
    this.$rootScope = $rootScope;
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.$q = $q;
    this.AppSettings = AppSettings;
    this.bookingsService = new BookingsService();
    this.usersService = new UsersService(true);
  }

  public init(): void {
    this.booking = null;
    this.bookingReference = this.$routeParams.bookingReference;

    this.bookingIsPolling = false;

    const urlParams: BookingsCtrlUrlParams = this.$location.search();
    this.accessToken = urlParams.access_token;
  }

  public getBooking(): Booking {
    if (this.booking) {
      return this.booking;
    }
    if (this.bookingIsPolling) { return null; }
    this.fetchBooking()
      .then((res) => {
        this.booking = res;
        this.bookingIsPolling = false;
        this.$rootScope.$broadcast("trackAdobeAnalytics",
          this.booking,
        );
      })
      .catch((e) => { })
    return null;
  }

  public getPointsPartner(): any {
    if (this.pointsPartner) { return this.pointsPartner; }
    if (!this.getBooking()) { return null; }

    const pointsCredit = this.booking.points_credits[0];
    if (pointsCredit) {
      const pointsPartnerId = pointsCredit.points_partner.id;
      this.pointsPartner = this.$rootScope.pointsPartners.findById(pointsPartnerId);
    }
  }

  public getBookingReference(): string {
    return this.bookingReference;
  }

  public paidInCashOnly(): boolean {
    return this.booking && !this.booking.paid_in_points && this.booking.converted_amount > 0;
  }

  public paidAnyCash(): boolean {
    return this.booking && this.booking.converted_amount && this.booking.converted_amount > 0;
  }

  public paidAnyPoints(): boolean {
    return this.booking && this.booking.paid_in_points && this.booking.paid_in_points > 0;
  }

  public paidInPointsOnly(): boolean {
    return this.paidAnyPoints() && !this.paidAnyCash();
  }

  public activeBooking(): boolean {
    return this.booking && this.booking.status == 'active';
  }

  public verificationRequiredBooking(): boolean {
    return this.booking && this.booking.status === "verification_required";
  }

  public fetchBooking(): Promise<Booking> {
    this.bookingIsPolling = true;
    return this.$q((resolve, reject) => {
      this.bookingsService.getBooking(this.accessToken, this.bookingReference)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => { reject(e) })
    })
  }

  public getBookingGuestName(): string {
    if (this.booking) {
      return `${this.booking.first_name} ${this.booking.last_name}`;
    } else {
      return "";
    }
  }

  public pdfDownloadLink(): string {
    return `/purchases/${this.booking.id}?access_token=${this.$routeParams.access_token}&format=pdf`;
  }

  public verificationTimeLeft(): string {
    const createdAt = this.booking.created_at && moment(this.booking.created_at, "LL");
    const checkInDate = this.booking.checkInDate && moment(this.booking.checkInDate, "YYYY-MM-DD");
    const checkOutDate = this.booking.checkOutDate && moment(this.booking.checkOutDate, "YYYY-MM-DD");

    if (!createdAt || !checkInDate || !checkOutDate) {
      return "";
    }

    if (createdAt.clone().add(1, "day").diff(checkInDate, "days") >= 0) {
      return "60 minutes";
    } else if (createdAt.clone().add(3, "day").diff(checkInDate, "days") >= 0) {
      return "3 hours";
    }

    return "24 hours";
  }

  public getSelectedLocale(): { code: string } {
    return this.$rootScope.selectedLocale;
  }
}

angular.module("BookingApp").service('BookingDetailUtils', BookingDetailUtils)
