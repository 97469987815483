import { DatePickerSelectionState } from "../date-picker";
import { DateAdapterUtils } from "booking_app/utils";

declare var moment: any;

interface DateUpdateValue {
  startDate: string;
  endDate: string;
}

export class DateRangeFieldController {
  static $inject = ["$element", "$scope"];

  // bindings
  startDate: string;
  endDate: string;
  minDaysToBookInAdvance: number = 0;
  minBookingDays: number = 0;
  onUpdate: (obj: { value: DateUpdateValue }) => void;
  locale: string;
  dateFormat: string;
  numberOfMonths: number;
  // local state
  modalOpen: boolean = false;
  selectionState: DatePickerSelectionState;
  isSelectingDates: boolean = false;

  constructor(private $element: any, private $scope: any) {

  }

  updateStartDate(value: string) {
    // In order to prevent double entry animations, we need to detect when the user was in the middle of selecting dates
    this.isSelectingDates = true;
    this.onUpdate({
      value: {
        startDate: value,
        endDate: this.newEndDateValue(value),
      },
    });
    const dateFieldExists = this.focusEndDateField();
    if (!dateFieldExists) {
      this.blurStartDateField();
      this.closeModal();
      this.$scope.$apply();
    } else {
      this.isSelectingDates = false;
    }
  }

  updateEndDate(value: string) {
    this.isSelectingDates = false;
    this.onUpdate({
      value: {
        startDate: this.startDate,
        endDate: value,
      },
    });
    this.closeModal();
    this.blurEndDateField();
  }

  beginStartDateSelection() {
    this.selectionState = "START";
    this.openModal();
  }

  beginEndDateSelection() {
    this.selectionState = "END";
    this.openModal();
  }

  cancelSelection() {
    if (!this.isSelectingDates) {
      this.closeModal();
      this.isSelectingDates = false;
    }
  }

  focusEndDateField(): boolean {
    const endDateField = this.findEndDateField();
    if (endDateField) {
      endDateField.focus();
      return true;
    } else {
      return false;
    }
  }

  blurStartDateField() {
    const startDateField = this.findStartDateField();
    if (startDateField) {
      startDateField.blur();
    }
  }

  blurEndDateField() {
    const endDateField = this.findEndDateField();
    if (endDateField) {
      endDateField.blur();
    }
  }

  formattedStartDate(): string {
    return this.stringToMoment(this.startDate).format(this.dateFormat || "ll");
  }

  formattedEndDate(): string {
    return this.stringToMoment(this.endDate).format(this.dateFormat || "ll");
  }

  minDatepickerDate(): string {
    if (this.selectionState === "START") {
      return moment().add(this.minDaysToBookInAdvance, "d").format(DateAdapterUtils.V2_DATE_FORMAT);
    } else if (this.selectionState === "END") {
      return this.stringToMoment(this.startDate)
        .add(this.minBookingDays, "d")
        .format(DateAdapterUtils.V2_DATE_FORMAT);
    }
  }

  closeModal() {
    this.modalOpen = false;
  }

  private findStartDateField(): any {
    return this.$element.find(".date-range-field-start")[0];
  }

  private findEndDateField(): any {
    return this.$element.find(".date-range-field-end")[0];
  }

  private newEndDateValue(newStartValue: string): string {
    if (this.startDateGreaterThanEndDate(newStartValue)) {
      return this.momentToString(this.addMinBookingDays(newStartValue));
    } else {
      return this.endDate;
    }
  }

  private startDateGreaterThanEndDate(newStartDate: string): boolean {
    return this.stringToMoment(newStartDate) > this.stringToMoment(this.endDate);
  }

  private stringToMoment(date: string): any {
    return moment(date, DateAdapterUtils.V2_DATE_FORMAT);
  }

  private momentToString(date: string): string {
    return moment(date).format(DateAdapterUtils.V2_DATE_FORMAT);
  }

  private addMinBookingDays(date: string): string {
    return this.stringToMoment(date).add(this.minBookingDays, "d");
  }

  private openModal() {
    this.modalOpen = true;
  }
}
