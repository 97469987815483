import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { AppSettings } from "booking_app/values/app-settings";

export class HotelResultSortMobileController {
  static $inject = ["$rootScope", "$scope", "SearchSortingService", "AppSettings"];

  // local state
  modalOpen: boolean = false;
  maxModalWidth: ViewportSizes;

  constructor(
    private $rootScope: any,
    private $scope: any,
    private sortingService: any,
    private appSettings: AppSettings,
  ) {
    this.$scope.sortIconSource = this.appSettings.mobileResultPopupConfig.sortIconSource;
    this.maxModalWidth = ViewportSizes.SM_MAX;
  }

  openModal() {
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
  }

  selectSortOptions(sort) {
    this.sortingService.current = sort;
    this.closeModal();
  }

  sortOptionClass(option: string): string {
    return this.sortingService.sortOptionClass(option);
  }
}
