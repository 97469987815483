import { HotelsSearchFormController } from "./search-form.controller";
import { Bindings } from "booking_app/types";

/**
  This is a component that has exactly the same component code as search-form.component
  Why do we need this then?
  Because certain WLs render 2 versions of hotel search form,
  1. On the landing page (typically customized)
  2. On the edit search modal (typically the same as default)

  We covered the first case with search-form.component.
  The edit search form however is rarely customized and will always render default template.
*/
export class HotelsEditSearchFormComponent {
  bindings: any = {
    hidePartnerSelector: Bindings.ONE_WAY,
  };
  templateUrl: string = "/html/hotels/edit_search_form";
  controller: any = HotelsSearchFormController;
}

angular.module("BookingApp").component("hotelsEditSearchForm", new HotelsEditSearchFormComponent())
