import { Bindings } from "booking_app/types";
import { AmenitiesController } from "./amenities.controller";

export class AmenitiesComponent {
  bindings: any = {
    hotelDetail: Bindings.ONE_WAY,
  };
  controller: any = AmenitiesController;
  template: string = `
  <div class="amenities-list hidden-xs">
    <div class="guest-love-list" ng-if="$ctrl.hasGuestLove()">
      <h3 class="is-visually-hidden">Attractive attributes of the hotel</h3>
      <div class="title color-headline" translate="txt.guest_love"></div>
      <div class="item-container">
        <div class="amenity-item" ng-repeat="list in $ctrl.guestLoveList()">
          <em class="fa fa-check"></em>
          <span translate="{{list}}"></span>
        </div>
      </div>
    </div>
    <div class="facilities-list" ng-if="$ctrl.hasAmenities()">
      <h3 class="is-visually-hidden">Hotel Facilities</h3>
      <div class="title color-headline" translate="txt.facilities"></div>
      <div class="item-container">
        <div class="amenity-item" ng-repeat="(amenity, isPresent) in $ctrl.hotelDetail.amenities", "ng-if"="isPresent">
          <em class="fa fa-check"></em>
          <span translate="{{amenity}}"></span>
        </div>
      </div>
    </div>
  </div>

  `;
}

angular.module("BookingApp").component("amenities", new AmenitiesComponent());
