import { LinkTargetType } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";

declare var angular: any;

angular.module("BookingApp")
  .directive("linkChangeTarget", [
    "$timeout",
    "AppSettings",
    (
      $timeout: any,
      appSettings: AppSettings,
    ) => {
      return {
        restrict: "A",
        link: (scope: any, element: any) => {
          if (appSettings.openInTargetTab.allLinksTarget !== LinkTargetType.SELF) {
            $timeout(() => {
              if (element[0].tagName === "A") {
                element[0].setAttribute("target", appSettings.openInTargetTab.allLinksTarget);
              } else {
                const childNodes = element.children("a");
                childNodes.each(anchor => childNodes[anchor]
                    .setAttribute("target", appSettings.openInTargetTab.allLinksTarget));
              }
            }, 100);
          }
        },
      };
    },
]);
