EditSearchCtrl = ($rootScope, $scope, $controller, $modalInstance,
KaligoSearch, $location, GlobalStateService) ->
  # Preserve whatever page the user was in, and reset the page back to what its supposed to be
  previousPage = GlobalStateService.currentPage
  angular.extend(this, $controller('SearchCtrl', {$scope: $scope}))
  GlobalStateService.currentPage = previousPage

  close = () ->
    $modalInstance.dismiss('cancel')

  $scope.close = close
  KaligoSearch.close = close

  return

EditSearchCtrl
.$inject = [
  '$rootScope', '$scope', '$controller', '$modalInstance',
  'KaligoSearch', '$location', "GlobalStateService"
]

angular.module('BookingApp')
.controller 'EditSearchCtrl', EditSearchCtrl
