import { RoomGuestSelectMobileController } from "./room-guest-counter.controller";
import { Bindings } from "booking_app/types";

export class RoomGuestCounterComponent {
  bindings: any = {
    roomCount: Bindings.ONE_WAY,
    adultCount: Bindings.ONE_WAY,
    childCount: Bindings.ONE_WAY,
    childrenAges: Bindings.ONE_WAY,
    onUpdateRoomCount: Bindings.EXPRESSION,
    onUpdateAdultCount: Bindings.EXPRESSION,
    onUpdateChildCount: Bindings.EXPRESSION,
    onUpdateChildrenAges: Bindings.EXPRESSION,
    closeModal: Bindings.EXPRESSION,
  };
  controller: any = RoomGuestSelectMobileController;
  template: string = `
    <div class="room-guest-counter">
      <div class="room-guest-container">
        <input-counter
          value="$ctrl.roomCount"
          min-value="$ctrl.minRoomCount"
          max-value="$ctrl.maxRoomCount"
          on-update="$ctrl.updateRoomCount(value)"
          custom-aria-label="'rooms'"
          custom-label="$ctrl.roomLabel()"
          ng-if="$ctrl.shouldRenderRoomCounter()"
        ></input-counter>
        <input-counter
          value="$ctrl.adultCount"
          min-value="$ctrl.minAdultCount"
          max-value="$ctrl.maxAdultCount"
          on-update="$ctrl.updateAdultCount(value)"
          custom-aria-label="'adults'"
          custom-label="$ctrl.adultLabel()"
        ></input-counter>
        <input-counter
          value="$ctrl.childCount"
          min-value="$ctrl.minChildCount"
          max-value="$ctrl.maxChildCount"
          on-update="$ctrl.updateChildCount(value)"
          custom-aria-label="'children'"
          custom-label="$ctrl.childLabel()"
          ng-if="$ctrl.shouldRenderChildrenCounter()"
        ></input-counter>
        <div class="children-age-label" ng-if="$ctrl.shouldRenderChildrenCounter() && $ctrl.numOfChildren().length > 0">
          {{ "Age of children:" | translate }}
        </div>
        <div class="children-inclusion-label" ng-if="$ctrl.showMaxGuestsMessage()">
          {{ "txt.max_guests_including_children" | translate }}
        </div>
        <div class="age-selectors" ng-if="$ctrl.shouldRenderChildrenCounter() && $ctrl.numOfChildren().length > 0">
          <div class="age-selector" ng-repeat="num in $ctrl.numOfChildren()">
            <input-select
              class="ipt-select2 thin"
              value-formatter="$ctrl.formatChildValue"
              on-update="$ctrl.updateChildrenAges(value, $index)"
              selected-value="$ctrl.childrenAges[$index]"
              options="$ctrl.childrenAgeList"
              form-name="'children-ages'"
              search-enabled="false"
              choices-aria-label="'Age of Children'"
              choices-group-aria-label="'Dropdown'"
            ></input-select>
          </div>
        </div>
      </div>

      <div class="closer" ng-if="$ctrl.showCloseButton()">
        <a translate="button.done" ng-click="$ctrl.closeModal()" href="#" id="room-guest-counter-apply", role="button">
        </a>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("roomGuestCounter", new RoomGuestCounterComponent());
