import { Bindings } from "booking_app/types";
import { SiteLinksController } from "./site-links.controller";

export class SiteLinksComponent {
  controller: any = SiteLinksController;
  bindings: any = {
    linkOption: Bindings.ONE_WAY,
    clickBehavior: Bindings.ONE_WAY,
  };
  template: string = `
    <a class="travel-link"
      tabindex="{{ $ctrl.linkOption.tabIndex }}"
      ng-class="{'active': $ctrl.linkOption.active}"
      ng-mouseover="!$ctrl.clickBehavior && $ctrl.updateActiveLink()"
      ng-click="$ctrl.clickBehavior && $ctrl.updateActiveLink()"
      role="button"
      href="#">
      <span translate="{{ $ctrl.linkOption.title }}"></span>
    </a>
  `;

}

angular.module("BookingApp").component("siteLinks", new SiteLinksComponent());
