import { ExclusiveBenefits } from "booking_app/types/exclusive-benefits";
import { ApiDataService } from "booking_app/services/api-data.service";

interface ExclusiveBenefitResponse {
  offers: ExclusiveBenefits[];
}

export class ExclusiveBenefitsService {
  static $inject = [
    "ApiDataService",
  ];

  offers: any[];

  constructor(
    private apiDataService: ApiDataService,
  ) { }

  public getExclusiveBenefits(kaligoReference: string): ng.IPromise<ExclusiveBenefits[]> {
    return this.apiDataService.get(`offers/${ kaligoReference }`)
      .then((response: ExclusiveBenefitResponse) => {
        return response.offers;
      });
  }
}

angular.module("BookingApp").service("ExclusiveBenefitsService", ExclusiveBenefitsService);
