import { AppSettings } from "booking_app/values/app-settings";
import { GlobalStateService } from "./global-state.service";
import { PointsCashShare } from "booking_app/types/points-cash-share";
import { CashPointsRatio, CurrentPage } from "booking_app/types";

export class PointsCashShareService {
  static $inject = [
    "$rootScope",
    "$translate",
    "$timeout",
    "AppSettings",
    "GlobalStateService",
  ];

  public pointsCashShare: PointsCashShare;

  constructor(
    private $rootScope: any,
    private $translate: any,
    private $timeout: any,
    private appSettings: AppSettings,
    private globalStateService: GlobalStateService,
  ) {}

  public initializePointsCashShare(): void {
    const travelType: string = this.globalStateService.travelType;
    const ceilValue: number = this.$rootScope.pointsPartner.cashPaymentTiersBy(travelType).length - 1;
    let pointsCashShareParam = this.$rootScope.routeParams.pointsCashShare;
    pointsCashShareParam = pointsCashShareParam < 0 ? 0 : pointsCashShareParam;
    pointsCashShareParam = pointsCashShareParam > ceilValue ? ceilValue : pointsCashShareParam;

    this.pointsCashShare = {
      value: pointsCashShareParam || this.appSettings.pointsCashSliderSettings.pointSliderDefaultValue,
      sliderOptions: {
        floor: 0,
        ceil: ceilValue,
        step: 1,
        showSelectionBar: true,
        rightToLeft: this.isRtlDirection(),
        onChange: this.onChange.bind(this),
        translate: this.translate.bind(this),
      },
    };
  }

  public onChange(): void {
    this.$timeout(() => {
      this.$rootScope.$broadcast("pointsCashSliderUpdate");
    }, 300);
  }

  public translate(): string {
    const travelType: string = this.globalStateService.travelType;
    let pointsUsagePercentage: number;
    let pointsUsageText: string;

    if (this.appSettings.pointsCashSliderSettings.incrementPointsSliderCounter) {
      pointsUsagePercentage = Math.round(
        this.$rootScope.pointsPartner.pointsPaymentTiersBy(travelType)[this.pointsCashShare.value] * 100,
      );
    } else {
      pointsUsagePercentage = 100 - Math.round(
        this.$rootScope.pointsPartner.pointsPaymentTiersBy(travelType)[this.pointsCashShare.value] * 100,
      );
    }

    if (!this.appSettings.pointsCashSliderSettings.hasCustomSliderLabel) {
      pointsUsageText = `${pointsUsagePercentage}%
        ${this.$translate.instant(this.$rootScope.pointsPartner.shortCurrency)}`;
    } else {
      pointsUsageText = `${pointsUsagePercentage}%`;
    }

    if (this.globalStateService.currentPage === CurrentPage.CHECKOUT &&
      this.pointsCashShare.value === this.$rootScope.pointsPartner.pointsPaymentTiersBy(travelType).length - 1) {
        pointsUsageText = `${this.$translate.instant(this.appSettings.pointsCashSliderSettings.ceilingLabelPrefix)}${pointsUsageText}`;
    }

    return pointsUsageText;
  }

  public pointsCashRatio(): CashPointsRatio {
    const travelType: string = this.globalStateService.travelType;
    const pointsPaymentTierValue =
      this.$rootScope.pointsPartner.pointsPaymentTiersBy(travelType)[this.pointsCashShare.value];
    const cashPaymentTierValue =
      this.$rootScope.pointsPartner.cashPaymentTiersBy(travelType)[this.pointsCashShare.value];
    if (pointsPaymentTierValue === 0) {
      return CashPointsRatio.FULLCASH;
    } else if (cashPaymentTierValue === 0) {
      return CashPointsRatio.FULLPOINTS;
    } else {
      return CashPointsRatio.CASHANDPOINTS;
    }
  }

  public toggleSliderRTL(): void {
    if (this.pointsCashShare) {
      this.pointsCashShare.sliderOptions.rightToLeft = this.isRtlDirection();
    }
  }

  private isRtlDirection(): boolean {
    return this.$rootScope.globalState.displayAlignment === "rtl" ||
      this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider;
  }

}

angular.module("BookingApp").service("PointsCashShareService", PointsCashShareService);
