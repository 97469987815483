import { CouponService } from "../../../services/coupon.service";

export class CouponCodeController {

  static $inject = [
    "CouponService",
  ];

  // bindings
  private applyCouponCode: (code) => {};
  private resetCouponCode: () => {};

  constructor(
    private couponService: CouponService,
  ) {}

  validate() {
    const couponCode = {
      code: this.couponService.couponCodeDetails.code,
    };
    this.applyCouponCode(couponCode);
  }

  removeCouponCode() {
    this.resetCouponCode();
  }

  validStatusClass() {
    if (this.couponService.isValid()) {
      return "success";
    }
    if (!this.couponService.isValid() && this.couponService.hasErrorMessage()) {
      return "error";
    }
  }

}
