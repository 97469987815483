import { Bindings } from "booking_app/types";
import { ButtonLoaderController } from "./button-loader.controller";
export class ButtonLoaderComponent {
  bindings: any = {
    isLoading: Bindings.ONE_WAY,
  };
  controller: any = ButtonLoaderController;
  template: string = `
    <div class="spinner" ng-show="$ctrl.isLoading">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>`;
}

angular.module("BookingApp").component("buttonLoader", new ButtonLoaderComponent());
