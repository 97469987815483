declare var moment: any;

type FlightsSegmentType = 'origin' | 'return';

export interface FlightsSegment {
  from_airport: string;
  to_airport: string;
  departure_time: string;
  arrival_time: string;
  marketing_airline_flight_no: string;
  baggage_allowance: string;
  cabin: string;
  marketing_airline_code: string;
  marketing_airline_name: string;
  operating_airline_code: string;
  operating_airline_name: string;
  operating_airline_flight_no: string;
  duration: string;
  aircraft_type_name: string;
  fare_reference: string;
  fare_brand_name: string;
  segment_type: FlightsSegmentType;
  from_airport_name: string;
  to_airport_name: string;
}

export const flightsSegmentId = (segment: FlightsSegment): string => {
  return [
    segment.marketing_airline_code,
    segment.operating_airline_code,
    segment.operating_airline_flight_no,
    segment.cabin,
    segment.fare_brand_name,
  ].join("-");
};

export const departureTime = (segment: FlightsSegment): string => {
  return moment(segment.departure_time).format('HH:mm');
}
