import { Bindings } from "booking_app/types";
import { PaymentDetailFormController } from "./payment-detail-form.controller";

export class PaymentDetailFormComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      data: Bindings.ONE_WAY,
      onUpdate: Bindings.EXPRESSION,
      formName: Bindings.ONE_WAY,
      parentForm: Bindings.ONE_WAY,
      submitted: Bindings.ONE_WAY,
    };
    this.controller = PaymentDetailFormController;
    this.templateUrl = '/html/components/payment_detail_form';
  }
}

angular.module("BookingApp").component("paymentDetailFormComponent", new PaymentDetailFormComponent());
