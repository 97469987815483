import { Modal } from "booking_app/types";

export class SimpleModalController {

  static $inject = [
    "$modalInstance",
    "modalIconCDNPath",
    "modalHeader",
    "modalBody",
    "rejectButtonText",
    "resolveButtonText",
    "$rootScope",
    "modalId",
  ];

  public showImpersonatedModal: boolean;
  public impersonatedModalBody: string;

  constructor(
    private $modalInstance: any,
    public modalIconCDNPath: string,
    public modalHeader: string,
    public modalBody: string,
    public rejectButtonText: string,
    public resolveButtonText: string,
    private $rootScope: any,
    private modalId: string,
  ) {
    this.showImpersonatedModal = modalId === Modal.IMPERSONATED_CONFIRMATION;
    if (this.$rootScope.userDetails.user) {
      this.impersonatedModalBody = this.$rootScope.userDetails.user.first_name + " " + this.$rootScope.userDetails.user.last_name;
    }
  }

  resolveModal(reason?: string): void {
    this.$modalInstance.close(reason);
  }

  rejectModal(reason?: string): void {
    this.$modalInstance.dismiss(reason);
  }
}

angular.module("BookingApp").controller("SimpleModalController", SimpleModalController);
