import { TravelType } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";

declare var angular: any;

export class TravelTypeService {
  static $inject = [
    "$location",
    "AppSettings",
    "AppConfig",
  ];

  constructor(
    private $location: any,
    private appSettings: AppSettings,
    private appConfig: any,
  ) {}

  public changeTravelTypeUrl(type: TravelType): void {
    this.$location.url(type);
  }

  public initTravelType(): TravelType {
    let travelType: TravelType;
    switch (this.getTravelTypeFromUrl()) {
      case TravelType.HOTELS: {
        travelType = TravelType.HOTELS;
        break;
      }
      case TravelType.FLIGHTS: {
        travelType = TravelType.FLIGHTS;
        break;
      }
      case TravelType.CARS: {
        travelType = TravelType.CARS;
        break;
      }
      default: {
        travelType = TravelType.HOTELS;
        break;
      }
    }
    return travelType;
  }

  // Only accepts original path ea "/results/:destinationId"
  getTravelTypeFromPath(path: string): TravelType {
    if (path.includes("car")) {
      return TravelType.CARS;
    } else if (path.includes("flight")) {
      return TravelType.FLIGHTS;
    } else {
      return TravelType.HOTELS;
    }
  }

  private getTravelTypeFromUrl(): TravelType {
    const urlSegment: string = this.$location.path().split("/")[1];
    let travelTypeFromUrl: TravelType | string;

    if (urlSegment === "") {
      travelTypeFromUrl = this.appSettings.defaultTravelTypeInHomePage;
    } else if (urlSegment === "results") {
      travelTypeFromUrl = TravelType.HOTELS;
    } else {
      travelTypeFromUrl = this.appConfig.travel_types.includes(urlSegment) ?
        urlSegment : this.appSettings.defaultTravelTypeInHomePage;
    }

    return travelTypeFromUrl as TravelType;
  }

}

angular.module("BookingApp").service("TravelTypeService", TravelTypeService);
