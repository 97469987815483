import { TrustYouController } from "./trust-you.controller";
import { Bindings } from "booking_app/types";

export class TrustYouComponent {
  bindings: any = {
    hotel: Bindings.ONE_WAY,
    showReviewBlock: Bindings.ONE_WAY,
    scrollToReview: Bindings.EXPRESSION,
    viewFullReview: Bindings.ONE_WAY,
  };
  controller: any = TrustYouController;
  templateUrl: string = "/html/components/trust_you";
}

angular.module("BookingApp").component("trustYou", new TrustYouComponent());
