import { HotelListItemCompRatesController } from "./comp-rates.controller";
import { Bindings } from "booking_app/types";

export class HotelListItemCompRatesComponent {
  bindings: any = {
    hotel: Bindings.ONE_WAY,
  };
  controller: any = HotelListItemCompRatesController;
  template: string = `
    <div class="hotel-list-item-comp-rates" ng-if="$ctrl.showMarketRates()">

      <div class="outer-container">
        <label>
          <span translate="results.compare_rate"></span>
        </label>
        <div class="market-rates-area">
          <a
            class="market-rate-item"
            ng-click="$ctrl.redirectToExternalSupplier($event, marketRate)"
            ng-repeat="marketRate in $ctrl.hotel.marketRates | limitTo: $ctrl.$rootScope.globalState.marketRatesCount"
            ng-if="$ctrl.hasComparableRate(marketRate.singleRate)"
          >
            <div class="market-rate-supplier" ng-bind="marketRate.supplier"></div>
            <div class="market-rate-price">
              <span class="currency-symbol" ng-bind="$ctrl.$rootScope.selectedCurrency.code | codeSymbolFmt"></span>
              <span ng-bind="$ctrl.formatLocaleShortenNumber(marketRate.singleRate)"></span>
            </div>
          </a>
        </div>
      </div>

    </div>
  `;
}

angular.module("BookingApp").component("hotelListItemCompRates", new HotelListItemCompRatesComponent());
