export class NumberUtils {
  static formatCurrencyValue(value: number, locale: any): number {
    let decimalPlace = locale.decimalPlace

    if (decimalPlace > 0) {
      value = Math.ceil(value * 100) / 100
    } else {
      value = Math.ceil(value);
    }

    return value
  }

  static formatPointsValue(value: number, rounding: number = 100): number {
    return Math.ceil(value / rounding) * rounding
  }
}
