import { AngularUtils, DateAdapterUtils } from "booking_app/utils";
import { TimeListService } from "booking_app/services/time-list.service";

declare var moment: any;

interface TimeListOption {
  value: string;
  display: string;
}

export class DateTimeFieldMobileController {
  static $inject = [
    "$rootScope",
    "$scope",
    "TimeListService",
  ];

  // bindings
  date: string; // YYYY-MM-DD
  onUpdateDate: (obj: { date: string }) => void;
  minDate: string; // YYYY-MM-DD
  time: string; // e.g. 10.00, 01:30, 18:30
  onUpdateTime: (obj: { time: string }) => void;
  dateFormat: string;
  dateLabelKey: string;
  timeLabelKey: string;
  locale: string;
  showTimeOnly: boolean;

  // local constants
  timesList: TimeListOption[];

  // local state
  modalOpen: boolean = false;
  selectionState: "DATE" | "TIME";
  internalTime: string; // local copy of time binding, since select relies on ng-model to work

  constructor(
    private $rootScope: any,
    private $scope: any,
    private timeListService: TimeListService,
  ) {
  }

  $onInit(): void {
    // we must declare the default value here or the value will not be assigned.
    this.dateFormat = this.dateFormat || "ll | HH:mm";
    this.showTimeOnly = this.showTimeOnly || false;

    this.$rootScope.$watch("selectedLocale", () => {
      this.timesList = this.timeListService.timeList;
    });
  }

  $onChanges(changesObj: any) {
    if (AngularUtils.hasBindingChanged(changesObj.locale)) {
      this.timesList = this.timeListService.timeList;
    }
    if (AngularUtils.hasBindingChanged(changesObj.time)) {
      this.internalTime = angular.copy(this.time);
    }
  }

  dateTimeSummary(): string {
    const timeMoment = moment(this.time, "HH:mm");
    if (this.showTimeOnly) {
      return timeMoment.format(this.dateFormat);
    }

    const dateMoment = this.stringToMoment(this.date);
    return dateMoment.set({
      hour: timeMoment.get("hour"),
      minute: timeMoment.get("minute"),
    })
      .format(this.dateFormat || "ll");
  }

  formattedPopupDate(): string {
    return this.stringToMoment(this.date).format("ddd, D MMM");
  }

  beginSelection(): void {
    this.openModal();
    if (this.showTimeOnly) {
      this.selectionState = "TIME";
    } else {
      this.selectionState = "DATE";
    }
  }

  onTimeSelectionBegin(): void {
    this.selectionState = "TIME";
  }

  updateTime(): void {
    this.onUpdateTime({ time: this.internalTime });
    this.selectionState = "TIME";
  }

  dateFieldClass(): { [key: string]: boolean } {
    return {
      "info-field": true,
      "is-selecting": this.selectionState === "DATE",
    };
  }

  timeFieldClass(): { [key: string]: boolean } {
    return {
      "info-field": true,
      "is-selecting": this.selectionState === "TIME",
    };
  }

  updateDate(value: string): void {
    this.onUpdateDate({ date: value });
    this.selectionState = "DATE";
    this.$scope.$apply();
  }

  closeModal(): void {
    this.modalOpen = false;
  }

  private openModal(): void {
    this.modalOpen = true;
  }

  private stringToMoment(date: string): any {
    return moment(date, DateAdapterUtils.V2_DATE_FORMAT);
  }
}
