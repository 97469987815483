import { CreditCardFormController } from "./credit-card-form.controller";
import { Bindings } from "booking_app/types";

export class CreditCardFormComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      parentForm: Bindings.ONE_WAY,
      formName: Bindings.ONE_WAY,
      data: Bindings.ONE_WAY,
      onUpdate: Bindings.EXPRESSION,
      submitted: Bindings.ONE_WAY,
    };
    this.controller = CreditCardFormController;
    this.templateUrl = '/html/components/payment_detail_form_credit_card_form';
  }
}

angular.module("BookingApp").component("creditCardFormComponent", new CreditCardFormComponent());
