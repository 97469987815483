import { ComplimentaryBannerController } from "./complimentary-banner.controller";

export class ComplimentaryBanner {
  controller: any = ComplimentaryBannerController;
  template: string = `
    <div class="complimentary-container">
      <div class="complimentary-title">
        <img cdn-path="{{ $ctrl.diamondLogo() }}">
        <span translate="wl.visa.complimentary_night"></span>
      </div>
      <div class="description">
        <span translate="{{ $ctrl.complimentaryNightDescription() }}"></span>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("complimentaryBanner", new ComplimentaryBanner());
