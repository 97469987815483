import { AppSettings } from "booking_app/values/app-settings";
import { Tenant } from "booking_app/types/tenant";

export class PaymentTimerService {
  static $inject = [
    "$timeout",
    "AppSettings",
  ];

  private paymentInProgressMsg: string | undefined;

  constructor(
    private $timeout: any,
    private appSettings: AppSettings,
  ) {}

  public paymentInProgressTimer(): void {
    if (this.appSettings.tenant !== Tenant.OLDHAM) {
      return;
    }

    const paymentInProgressMsgArr: string[] = [
      "Payment in progress",
      "Hang on, we need a bit more time...",
      "You’re almost there!",
    ];
    let timeout: number = 3000;

    this.paymentInProgressMsg = paymentInProgressMsgArr[0];
    paymentInProgressMsgArr.shift();

    const updatePaymentMsg = () => {
      timeout = 6000;
      if (paymentInProgressMsgArr.length > 0) {
        this.paymentInProgressMsg = paymentInProgressMsgArr.shift();
      } else {
        clearTimeout(paymentInProgressInterval);
      }
      this.$timeout(updatePaymentMsg, timeout);
    };

    const paymentInProgressInterval = this.$timeout(updatePaymentMsg, timeout);
  }

}

angular.module("BookingApp").service("PaymentTimerService", PaymentTimerService);
