import { Bindings } from "booking_app/types";
import { StickyHeaderController } from "./sticky-header.controller";
export class StickyHeaderComponent {
  bindings: any = {
    stickyType: Bindings.ONE_WAY,
  };
  controller: any = StickyHeaderController;
  templateUrl: any = "/html/components/sticky_header";
}

angular.module("BookingApp").component("stickyHeader", new StickyHeaderComponent());
