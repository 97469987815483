angular.module("BookingApp")

.factory "LangLoader", [

  '$q', '$http', 'AppConfig'
  ($q, $http, AppConfig) ->

    (options) ->
      $http(
        method: 'GET'
        headers: { 'Cache-Control': undefined }
        url: '/translations/' + options.key.toLowerCase()
      )
      .then (response) ->
        response.data
      , (error) ->
        $q.reject(options.key)
]
