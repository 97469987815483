export class SignUpWidgetController {

  // bindings
  registerAction: (formData: any) => void;
  registerBonusForm: any;
  hasLoggedUser: boolean;

  submitBonus(): void {
    this.registerBonusForm.submitted = true;
    this.registerBonusForm = this.registerAction({formData: this.registerBonusForm});
  }
}
