import { AppSettings } from "booking_app/values/app-settings";

export class ComplimentaryBannerController {
  static $inject = ["$rootScope", "AppSettings"];

  private tenant: string;

  constructor(
    private $rootScope: any,
    private appSettings: AppSettings,
  ) {
    this.tenant = this.appSettings.tenant.toLowerCase();
  }

  diamondLogo(): string {
    return `/visa/icons/diamond.png`;
  }

  complimentaryNightDescription(): string {
    return `wl.${this.tenant}.cc_info_title`;
  }
}
