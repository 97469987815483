export class AmenitiesController {
  static $inject = ["$scope", "$rootScope"];

  private hotelDetail: any;

  constructor(
    private $scope: any,
    private $rootScope: any,
  ) {
  }

  hasGuestLove(): boolean {
    return this.guestLoveList().length > 0;
  }

  guestLoveList(): string[] {
    if (this.hasGoodToKnowList()) {
      return this.trustYouReviews().good_to_know_list.filter(comment => {
        return comment.sentiment === 'pos';
      }).map(comment => {
        return comment.title;
      });
    } else {
      return [];
    }
  }

  hasGoodToKnowList(): boolean {
    return this.trustYouReviews() && this.trustYouReviews().good_to_know_list;
  }

  trustYouReviews(): any {
    return this.hotelDetail.trustyou.reviews;
  }

  hasAmenities(): boolean {
    return Object.keys(this.hotelDetail.amenities).length > 0;
  }

}
