import { AppSettings } from "booking_app/values/app-settings";

export class RoomGuestFieldController {

  static $inject = [
    "$translate",
    "$element",
    "AppSettings",
  ];

  // bindings
  roomCount: number;
  adultCount: number;
  childCount: number;
  childrenAges: number[];
  isShowingTotalGuest: boolean;
  onUpdateRoomCount: (obj: { count: number }) => void;
  onUpdateAdultCount: (obj: { count: number }) => void;
  onUpdateChildCount: (obj: { count: number }) => void;
  onUpdateChildrenAges: (obj: { childrenAges: number[] }) => void;

  // local state
  modalOpen: boolean = false;
  isFocused: boolean = false;

  constructor(
    private $translate: any,
    private $element: any,
    private appSettings: AppSettings,
  ) { }

  summaryText(): string {
    let res = "";

    if (this.roomCount > 0) {
      res += `${ this.roomCaption() } ${ this.separator() } `;
    }

    if (this.isShowingTotalGuest) {
      res += `${this.totalGuestsCaption()}`;
    } else {
      res += `${this.adultCaption()}`;

      if (this.childCount > 0) {
        res += `, ${this.childCaption()}`;
      }
    }
    return res;
  }

  summaryTextAriaLabel(): string {
    return this.summaryText().replace(this.separator(), ",") + " selected dropdown menu";
  }

  toggleModal() {
    this.modalOpen = !this.modalOpen;
    this.isFocused = !this.isFocused;
  }

  closeModal() {
    this.modalOpen = false;
    this.isFocused = false;
  }

  updateRoomCount(count: number) {
    this.onUpdateRoomCount({ count });
  }

  updateAdultCount(count: number) {
    this.onUpdateAdultCount({ count });
  }

  updateChildCount(count: number) {
    this.onUpdateChildCount({ count });
  }

  updateChildrenAges(childrenAges: number[]) {
    this.onUpdateChildrenAges({
      childrenAges,
    });
  }

  shouldRenderChildrenCounter(): boolean {
    return Number.isInteger(this.childCount);
  }

  labelOnlySummaryText(): boolean {
    return this.appSettings.roomGuestParameters.labelOnlySummaryText;
  }

  private adultCaption(): string {
    if (this.shouldRenderChildrenCounter()) {
      return this.showLabelAsAdult();
    } else {
      return this.showLabelAsGuest();
    }
  }

  private showLabelAsAdult(): string {
    if (this.adultCount === 1) {
      return this.$translate.instant("count.adult_one");
    } else {
      return this.$translate.instant("count.adult_other", { count: this.adultCount });
    }
  }

  private showLabelAsGuest(): string {
    if (this.adultCount === 1) {
      return this.$translate.instant("wl.single_guest_count_per_room");
    } else {
      return this.$translate.instant("wl.multiple_guest_count_per_room", { count: this.adultCount });
    }
  }

  private childCaption(): string {
    if (this.childCount === 1) {
      return this.$translate.instant("count.children_one");
    } else {
      return this.$translate.instant("count.children_other", { count: this.childCount });
    }
  }

  private roomCaption(): string {
    if (this.roomCount === 1) {
      return this.$translate.instant("Single Room Count");
    } else {
      return this.$translate.instant("Multiple Room Count", { count: this.roomCount });
    }
  }

  private totalGuestsCaption(): string {
    const totalGuests = this.adultCount + this.childCount;
    return totalGuests === 1 ?
           this.$translate.instant("count.guest_per_room.one") :
           this.$translate.instant("count.guest_per_room.other", { count: totalGuests });
  }

  private separator(): string {
    if (this.labelOnlySummaryText()) {
      return `<span class="separator"> | </span>`;
    } else {
      return `|`;
    }
  }
}
