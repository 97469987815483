import { AppSettings } from "booking_app/values/app-settings";
import { ApiDataService } from "./api-data.service";
import { CookieService } from "./cookie.service";
import { Modal } from "booking_app/types";
import { SimpleModalService } from "./simple-modal.service";

export class DestinationsService {
  static $inject = [
    "$q", "$rootScope", "$modal", "$translate",
    "ApiDataService", "CookieService",
    "AppConfig", "DefaultLocale", "AppSettings",
    "SimpleModalService",
  ];

  constructor(
    private $q: any,
    private $rootScope: any,
    private $modal: any,
    private $translate: any,
    private apiDataService: ApiDataService,
    private cookieService: CookieService,
    private appConfig: any,
    private defaultLocale: any,
    private appSettings: AppSettings,
    private simpleModalService: SimpleModalService,
  ) { }

  fetch(destinationId: string): ng.IPromise<{}>  {
    return this.apiDataService.get(`destinations/${destinationId}`);
  }

  checkGulfResidency(data: any): ng.IPromise<{}> {
    const defer: ng.IDeferred<{}> = this.$q.defer();
    if (this.shouldDisplayGulfDestinationModal(data.gulf_destination)) {
      this.translateModalContent().then((translations: any) => {
        this.displayGulfResidentModal(translations, defer);
      });
    } else {
      defer.resolve();
    }
    return defer.promise;
  }

  private shouldDisplayGulfDestinationModal(isGulfDestination: boolean): boolean {
    return (
      !!isGulfDestination &&
      !this.isGulfCountry(this.appConfig.country_code) &&
      !this.cookieService.getGulfResident() &&
      this.appSettings.showGulfAlert
    );
  }

  private translateModalContent(): ng.IPromise<{}> {
    return this.defaultLocale.defer( () => {
      const translateDeferred: ng.IDeferred<{}> = this.$q.defer();
      this.$translate([
        "search.gulf_resident.header",
        "search.gulf_resident.body",
        "Yes",
        "No",
      ]).then((translations: any) => {
        translateDeferred.resolve(translations);
      });
      return translateDeferred.promise;
    });
  }

  private displayGulfResidentModal(translations: any, defer: ng.IDeferred<{}>): void {
    this.$rootScope.globalState.activeModal = true;

    this.simpleModalService.open(Modal.GULF_RESIDENCY).then(
      () => this.cookieService.setGulfResident(this.appSettings.userGulfResident),
      () => this.cookieService.setGulfResident(this.appConfig.country_code),
    ).finally(() => {
      this.$rootScope.globalState.activeModal = false;
      defer.resolve();
    });
  }

  private isGulfCountry(countryCode: string): boolean {
    return ["EG", "BH", "IR", "IQ", "IL", "JO", "KW", "LB",
            "OM", "QA", "SA", "SY", "TR", "AE", "YE", "TN",
            "MA", "MR", "DZ", "LY", "SD"].indexOf(countryCode) >= 0; // to support IE9
  }
}

angular.module("BookingApp").service("DestinationsService", DestinationsService);
