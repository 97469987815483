declare var angular: any
declare var CheckoutKit:any

interface CardDetail {
  number: string,
  firstName: string,
  cvv: string,
  expirationMonth: string,
  expirationYear: string
}

class CheckoutComKitService {

  //Declare inject variables
  $q: any
  $window: any
  $timeout: any

  static $inject = ["$q", "$window", "$timeout"]

  constructor($q, $window, $timeout) {
    this.$q = $q
    this.$window = $window
    this.$timeout = $timeout
  }

  public cardToken(cardDetail: CardDetail):any {
    let deferred = this.$q.defer()
    let CheckoutKit = this.$window.CheckoutKit
    if (CheckoutKit) {
      CheckoutKit.createCardToken({
        number: cardDetail.number,
        name: cardDetail.firstName,
        cvv: cardDetail.cvv,
        expiryMonth: cardDetail.expirationMonth,
        expiryYear: cardDetail.expirationYear
      }, { includeBinData: false }, (data: any) => {
        if (data.id) {
          deferred.resolve(data.id)
        } else {
          deferred.reject(data.errors)
        }
      })
    } else {
      this.$timeout(() => {
        deferred.reject("Missing CheckoutKit")
      })
    }
    return deferred.promise
  }
}

angular.module('BookingApp').service('CheckoutComKitService', CheckoutComKitService)
