import { DateFieldController } from "./date-field.controller";
import { Bindings } from "booking_app/types";

export class DateFieldComponent {
  bindings: any = {
    selectionState: Bindings.ONE_WAY,
  };
  controller: any = DateFieldController;
  require: any = {
    dateRangeField: "^dateRangeField",
  };
  transclude: any = {
    iconContent: "?iconContent",
  };
  template: string = `
    <div ng-class="{ 'date-field': true, 'is-focused': $ctrl.isFocused }">
      <span
        ng-click="$ctrl.focusInput()"
        ng-transclude="iconContent">
      </span>
      <input
        ng-class="$ctrl.inputClass()"
        type="text"
        readonly
        ng-value="$ctrl.formattedDate()"
        ng-focus="$ctrl.beginDateSelection()"
        ng-blur="$ctrl.cancelSelection()"
        ng-keydown="$ctrl.keypressEvent($event)"
      >
    </div>
  `;
}

angular.module("BookingApp").component("dateField", new DateFieldComponent());
