angular.module('BookingApp')
.controller 'VideoCtrl', [

  '$scope', '$analytics', 'KaligoConfig'
  ($scope, $analytics, KaligoConfig) ->

    $scope.videoVisible = false

    $scope.showVideo = () ->
      $scope.videoVisible = true
      videoPlayers = angular.element('#commbank-video')
      return if videoPlayers.length == 0
      commbankVideo = videoPlayers[0]
      $analytics.eventTrack('play_video', {  category: 'video', label: 'commbank' })
      commbankVideo.play()
      return

    $scope.showVideo() if KaligoConfig.autoplayVideo

]
