global.jQuery = require('jquery');
global.$ = global.jQuery;
global.angular = require('angular');
global.moment = require('moment');

require('jquery-ujs');
require('jquery.cookie');
require('jquery-ui');
require('./custom-event');

require('angular-route');
require('angular-cookie');
require('angular-dropdowns');
require('angular-messages');
require('angular-resource');
require('angular-recaptcha');
require('angular-sanitize');
require('angular-touch');
require('angular-translate');
require('angular-translate-loader-static-files');
require('angular-translate-storage-cookie');
require('angular-messages');

// select2
require('ui-select');

// google analytics
require('angulartics');
require('angulartics-ga');

// tooltip library taken from lumx framework
require('lumx_tooltip_directive');

// angular sliders
require('angularjs-slider/dist/rzslider');

// custom booststrap
require('ui-bootstrap-custom-tpls-0.13.4.min');
require('bootstrap-sass/assets/javascripts/bootstrap/dropdown');

// slick carousel, used in hotel detail as photo slider, and kaligo main page for partners slider
require('slick-carousel');
require('angular-slick-carousel');
