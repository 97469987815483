import './factories/lang-loader'

angular.module('BookingApp')

.config ([
  "$locationProvider", 'KaligoConfig',
  ($locationProvider, KaligoConfig) => {

    if (KaligoConfig.ignoreAngularRoute) return;

    // use the HTML5 History API
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false
    })

    // use default behaviour before angular 1.6
    $locationProvider.hashPrefix('')
  }
])

.config ([
  "$translateProvider",
  ($translateProvider) => {
    $translateProvider.useLoader('LangLoader', {})
    $translateProvider.storageKey('lang')
    $translateProvider.useSanitizeValueStrategy('sanitizeParameters')
    $translateProvider.fallbackLanguage('en')
  }
])

.config ([
  "$httpProvider",
  ($httpProvider) => {
    $httpProvider.interceptors.push("authHttpResponseInterceptor")
    $httpProvider.useApplyAsync(true)
    $httpProvider.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate'
    $httpProvider.defaults.headers.post['Content-Type'] = 'application/json'
    $httpProvider.defaults.headers.put['Content-Type'] = 'application/json'
  }
])

.config ([
  "$qProvider",
  ($qProvider) => {
    $qProvider.errorOnUnhandledRejections(false)
  }
])

.config ([
  '$sceDelegateProvider',
  ($sceDelegateProvider) => {
    $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      'https://kaligo.zendesk.com/**',
      'https://kaligopress.herokuapp.com/**',
      'https://pal-test.adyen.com/**'
    ]);
    return;
  }
])

.config ([
  '$compileProvider',
  ($compileProvider) => {
    // comment out this line to enable watcher at chrome developer tools
    $compileProvider.debugInfoEnabled(false)
  }
])

.config([
  "vcRecaptchaServiceProvider", "KaligoConfig",
  (vcRecaptchaServiceProvider, KaligoConfig) => {
    const siteKey = KaligoConfig.isProduction ?
    "6Lfa6wEaAAAAAGde53kVaXMCOhr7RGu4Wpy3T3a-" :
    "6Lf16gEaAAAAAB5q1TE6WolPwPUAEXRYF3nWjJYE";
    vcRecaptchaServiceProvider.setDefaults({
      key: siteKey,
      size: "invisible",
    });
  },
]);
