import { HotelsSearchFormController } from "../search-form/search-form.controller";

export class HotelsHorizontalSearchFormComponent {
  controller: any;
  templateUrl: string;

  constructor() {
    this.controller = HotelsSearchFormController;
    this.templateUrl = "/html/hotels/horizontal_search_form";
  }
}

angular.module("BookingApp").component("hotelsHorizontalSearchForm", new HotelsHorizontalSearchFormComponent())
