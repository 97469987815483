import { RoomInfoItem } from "booking_app/types";
import { UrlUtils } from "booking_app/utils";

export class RoomDetailsModalButtonController {
  static $inject = ["$modal", "$timeout", "UrlUtils"];

  // Bindings
  room: RoomInfoItem;
  buttonAriaLabel: string;

  formPopupOpen: boolean = false;
  images: any[];

  private bodyElement: HTMLElement;

  constructor(
    private $modal: any,
    private $timeout: any,
    private urlUtils: UrlUtils,
  ) {
    this.bodyElement = document.getElementsByTagName("body")[0];
  }

  openGalleryModal($event) {
    $event.stopPropagation();
    $event.preventDefault();

    this.openModalInstance();
  }

  roomImages(): string[] {
    if (this.room.images !== undefined && this.room.images.length > 0) {
      return this.room.images.map(image => image.high_resolution_url || image.url);
    } else {
      return [this.roomDefaultImage()];
    }
  }

  openModalInstance(): void {
    this.$modal.open({
      animation: true,
      templateUrl: "/html/modals/room_details_modal",
      controller: "RoomDetailsModal",
      size: this.getModalSize(),
      windowClass: this.getModalRoomStyleClass(),
      backdropClass: "gallery-backdrop",
      resolve: {
        images: () => this.roomImages(),
        roomTitle: () => this.room.roomDescriptionShort,
        description: () => this.room.long_description,
        defaultImage: () => this.roomDefaultImage(),
      },
    });
  }

  openFormPopup(): void {
    this.bodyElement.classList.add("modal-open");
    // Prevents Scrolling to top on form popup open

    this.images = this.roomImages();
    this.$timeout(() => {
      this.formPopupOpen = true;
    }, 100);
  }

  closeFormPopup($event?: any): void {
    if ($event) {
      $event.stopPropagation();
    }

    this.formPopupOpen = false;

    this.$timeout(() => {
      this.bodyElement.classList.remove("modal-open");
    }, 100);
  }

  private getModalSize(): string {
    if (this.room.long_description) {
      return "gallery-lg";
    } else {
      return "gallery-lg no-description";
    }
  }

  private getModalRoomStyleClass(): string {
    return `room-details-modal ${ this.room.long_description ? "with-description" : "no-description"}`;
  }

  private roomDefaultImage(): string {
    return this.urlUtils.imageUrl("/hotels_missing_images/hotel-room.jpg");
  }
}
