import { TrackerService } from "booking_app/services/tracker";
import { AppSettings } from "booking_app/values/app-settings";
import { CashPointsRatio, SortType } from "booking_app/types";
import { PointsCashShareService } from "./points-cash-share.service";

export class SearchSortingService {

  static $inject = ["$rootScope", "TrackerService", "AppSettings", "PointsCashShareService"];

  private current: string = SortType.DEAL;
  private selectedItem: { id: string } = { id: SortType.DEAL };
  private permittedOptions: SortType[] = [];
  private originalOptions: SortType[] = [];
  private options: {
    [sortType in SortType] : {
      label: string;
      sort: any;
    }
  };

  constructor(
    private $rootScope: any,
    private trackerService: TrackerService,
    private appSettings: AppSettings,
    private pointsCashShareService: PointsCashShareService,
  ) {
    this.initializeOptions();
  }

  public reset(): void {
    this.current = this.permittedOptions[0];
  }

  public setCurrent(newOption: SortType): void {
    this.current = newOption;
  }

  public fireSortingEvent(): void {
    this.trackerService.sortingEvent(
      { order: this.options[this.current].label },
    );
  }

  public toggleBestForBusiness(): void {
    if (this.current === SortType.BUSINESS) {
      this.current = this.permittedOptions[0];
    } else {
      this.current = SortType.BUSINESS;
    }
  }

  public initializePermittedSortingOptions(landingPageOptions): void {
    angular.copy(landingPageOptions, this.permittedOptions);
    angular.copy(landingPageOptions, this.originalOptions);
    this.reset();
  }

  public initializeCarsPermittedSortingOptions(sortingOptions: SortType[]): void {
    this.permittedOptions = [...sortingOptions];
  }

  public refreshSortOptions(): void {
    this.$rootScope.$broadcast("pointsCashSliderUpdate");
  }

  public updateSortingOptionsForPayWithPoints(): void {
    if (!this.appSettings.sortSettings.canUseFullCashOnRedemption &&
      !this.appSettings.sortSettings.canUseFullPointsOnRedemption) {
      return;
    }

    angular.copy(this.originalOptions, this.permittedOptions);

    const fullCashSortArr: SortType[] = [SortType.PAY_POINTS_CASH_LOHI, SortType.PAY_POINTS_CASH_HILO];
    const fullPointsSortArr: SortType[] = [SortType.PAY_POINTS_LOHI, SortType.PAY_POINTS_HILO];

    if (this.appSettings.sortSettings.canUseFullCashOnRedemption) {
      if (this.pointsCashShareService.pointsCashRatio() === CashPointsRatio.FULLCASH) {
        const subsetWithoutPoints: SortType[] = this.getUpdatedPermittedSortArr(fullPointsSortArr);

        this.current = this.isCurrentCashOrPoints(fullPointsSortArr) ?
          SortType.PAY_POINTS_CASH_LOHI : this.current;

        if (subsetWithoutPoints.length > 0) {
          this.permittedOptions.splice(0, this.permittedOptions.length, ...subsetWithoutPoints);
        }
      }
    }

    if (this.appSettings.sortSettings.canUseFullPointsOnRedemption) {
      if (this.pointsCashShareService.pointsCashRatio() === CashPointsRatio.FULLPOINTS &&
        this.appSettings.sortSettings.showPointsSortInFullPoints) {
        const subsetWithoutCash: SortType[] = this.getUpdatedPermittedSortArr(fullCashSortArr);

        this.current = this.isCurrentCashOrPoints(fullCashSortArr) ?
          SortType.PAY_POINTS_LOHI : this.current;

        if (subsetWithoutCash.length > 0) {
          this.permittedOptions.splice(0, this.permittedOptions.length, ...subsetWithoutCash);
        }
      }
    }
  }

  public sortOptionClass(option: string): string {
    return this.options[option].label.split(" ")[0];
  }

  private getUpdatedPermittedSortArr(sortLabelArrToBeRemoved: SortType[]): SortType[] {
    /** Return subset of permitted sorting options array
     * If full points = remove "pay-points-cash-lohi", "pay-points-cash-hilo"
     * If full cash =  remove "pay-points-lohi", "pay-points-hilo"
    */
    return this.permittedOptions.filter((element) => !sortLabelArrToBeRemoved.includes(element));
  }

  private isCurrentCashOrPoints(fullPointsOrCashArr: SortType[]): boolean {
    const currentElement: SortType = fullPointsOrCashArr.find(e => e === this.current);
    return currentElement && currentElement.length > 0;
  }

  private initializeOptions(): void {
    this.options  = {
      [SortType.DEAL]: {
        label: "Best Deal",
        sort: (item) => {
          if (item.available) {
            return item.searchRank;
          } else {
            return 1000000;
          }
        },
      },
      [SortType.PRICE_LOHI]: {
        label: "Price (Low to High)",
        sort: (item) => {
          if (item.available && item.price) {
            return item.price;
          } else {
            return 1000000;
          }
        },
      },
      [SortType.PRICE_HILO]: {
        label: "Price (High to Low)",
        sort: (item) => {
          if (item.available && item.price) {
            return -item.price;
          } else {
            return 1000000;
          }
        },
      },
      [SortType.CARS_PRICE_LOHI]: {
        label: "Price (Low to High)",
        sort: "cash_payment_per_night",
      },
      [SortType.CARS_PRICE_HILO]: {
        label: "Price (High to Low)",
        sort: "-cash_payment_per_night",
      },
      [SortType.RATING_LOHI]: {
        label: "Star Rating (Low to High)",
        sort: "rating",
      },
      [SortType.RATING_HILO]: {
        label: "Star Rating (High to Low)",
        sort: "-rating",
      },
      [SortType.POINTS_HILO]: {
        label: "Points (High to Low)",
        sort: (item) => {
          if (item.available && item.price) {
            return -(item.score + item.bonus);
          } else {
            return 1000000;
          }
        },
      },
      [SortType.CARS_POINT_HILO]: {
        label: "Points (High to Low)",
        sort: (item) => {
          if (item.price) {
            return -(item.points + item.bonus);
          } else {
            return 1000000;
          }
        },
      },
      [SortType.PAY_POINTS_LOHI]: {
        label: "Points (Low to High)",
        sort: "points_payment",
      },
      [SortType.PAY_POINTS_HILO]: {
        label: "Points (High to Low)",
        sort: "-points_payment",
      },
      [SortType.PAY_POINTS_CASH_LOHI]: {
        label: "Price (Low to High)",
        sort: "base_cash_payment",
      },
      [SortType.PAY_POINTS_CASH_HILO]: {
        label: "Price (High to Low)",
        sort: "-base_cash_payment",
      },
      [SortType.BEST_RATED]: {
        label: "Best Rated",
        sort: (item) => {
          if (item.trustyou && item.available) {
            return -(item.trustyou.score.kaligo_overall);
          } else {
            return 1000000;
          }
        },
      },
      [SortType.BUSINESS]: {
        label: "Best for Business Travellers",
        sort: (item) => {
          if (item.trustyou && item.available) {
            const score = item.trustyou.score;
            if (score.business) {
              return -(score.business);
            } else {
              return (-score.overall + 50);
            }
          } else {
            return 1000000;
          }
        },
      },
      [SortType.DISTANCE_LOHI]: {
        label: "Distance to city center",
        sort: (item) => {
          if (item.available && item.distance ) {
            return item.distance;
          } else {
            return 1000000;
          }
        },
      },
    };
  }

}

angular.module("BookingApp").service("SearchSortingService", SearchSortingService);
