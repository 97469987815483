import { AppSettings } from "booking_app/values/app-settings";
import { CCValidationService } from "booking_app/services/ccvalidation";

export interface CardVerificationFormData {
  creditCardBin: string;
}

export class CardVerificationController {
  static $inject = ["$http", "$modal", "$translate", "AppSettings", "CCValidationService"];

  formData: CardVerificationFormData;
  isLoading: boolean;
  tenant: string;

  constructor(
    private $http: any,
    private $modal: any,
    private $translate: any,
    private appSettings: AppSettings,
    private ccValidationService: CCValidationService,
  ) {
    this.isLoading = false;
    this.tenant = this.appSettings.tenant.toLowerCase();
  }

  verifyCreditCard(): void {
    this.isLoading = true;

    const regPattern = /^[0-9]{6}$/;

    if (!regPattern.test(this.formData.creditCardBin)) {
      this.openErrorModal(`wl.${this.tenant}.enter_nine_digits_number`);
      this.isLoading = false;
    } else {
      this.checkEligibility().then(
        (response) => this.successResponse(response.data),
        (errorResponse) => this.isLoading = false,
      );
    }
  }

  ccPlaceHolder(): string {
    return this.$translate.instant(`wl.${this.tenant}.cc.placeholder`);
  }

  verifyText(): string {
    return this.$translate.instant(`wl.${this.tenant}.cc.verify.message`);
  }

  private buildFormData(): any {
    return {
      ...this.ccValidationService.defaultParams(this.formData.creditCardBin),
    };
  }

  private checkEligibility(): Promise<any> {
    return this.$http({
      url: `/whitelabel/${this.tenant}/check_eligibility`,
      method: "POST",
      data: JSON.stringify(this.buildFormData()),
    });
  }

  private successResponse(data): void {
    this.isLoading = false;
    if (data.eligible) {
      this.updateCookie();
    } else {
      this.openErrorModal(`wl.${this.tenant}.use_valid_${this.tenant}_card`);
    }
  }

  private updateCookie(): void {
    const now = new Date();
    const exp = new Date(now.getFullYear(), now.getMonth() + 3, now.getDate());
    $.cookie("known", true, {
      expires: exp,
    });
  }

  private openErrorModal(errorMessage): void {
    const modalInstance = this.$modal.open({
     animation: true,
     templateUrl: "/html/modals/card_error_modal",
     controller: "CardErrorModal",
     windowClass: "card-error-modal",
     size: "md",
   });

    modalInstance.titleKey = `wl.visa.non-visa_card`;
    modalInstance.errorMessageKey = errorMessage;
  }
}
