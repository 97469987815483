import { Bindings } from "booking_app/types";

import { MapDistanceController } from "./map-distance.controller";

export class MapDistanceComponent {
  bindings: any = {
    distance: Bindings.ONE_WAY,
  };
  controller: any = MapDistanceController;
  template: string = `
    <div ng-if="$ctrl.distance">
      <div translate="txt.pick_up_distance_in_kilometers"
        translate-values="{ distance: '{{::$ctrl.supplierDistance()}}' }">
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("mapDistance", new MapDistanceComponent());
