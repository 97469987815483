import { ApiDataService } from './api-data.service';
import { Country, countries } from 'booking_app/constants/countries';
import { AppSettings } from "booking_app/values/app-settings";

const COUNTRIES_REQUIRING_STATE = [
  'AU',
  'BR',
  'CA',
  'US',
];

export class CountryService {

  static $inject = [
    "$rootScope",
    "ApiDataService",
    "AppSettings",
  ];

  constructor(
    private $rootScope: any,
    private apiDataService: ApiDataService,
    private appSettings: AppSettings,
  ) { }

  getCountries(): Country[] {
    return countries;
  }

  getCountry(countryCode: string): Country {
    return angular.copy(countries.find((country) => country.code === countryCode));
  }

  getDefaultCountry(): Country {
    const userCountryCode: string = this.$rootScope.userDetails.user.country;

    if (userCountryCode) {
      return this.getCountry(userCountryCode);
    } else if (this.appSettings.defaultCountryInCheckout.hasDefaultCountry) {
      return this.getCountry(this.appSettings.defaultCountryInCheckout.defaultCountryCode);
    } else {
      return;
    }
  }

  getDefaultCountryResidence(): Country {
    const userCountryResidenceCode: string = this.$rootScope.userDetails.user.country_residence;

    if (userCountryResidenceCode) {
      return this.getCountry(userCountryResidenceCode);
    } else if (this.appSettings.defaultCountryInCheckout.hasDefaultCountry) {
      return this.getCountry(this.appSettings.defaultCountryInCheckout.defaultCountryCode);
    } else {
      return;
    }
  }

  getState(countryCode: string, stateCode: string): ng.IPromise<{}> {
    return this.apiDataService.get(`countries/${countryCode}/states/${stateCode}`);
  }

  getStates(countryCode): any {
    return this.apiDataService.get(`countries/${countryCode}/states`);
  }

  isStateRequired(countryCode: string): boolean {
    return COUNTRIES_REQUIRING_STATE.includes(countryCode);
  }

}

angular.module('BookingApp').service('CountryService', CountryService);
