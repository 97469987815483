import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { AppSettings } from "booking_app/values/app-settings";

export class MapModal {
  static $inject = [
    "$scope",
    "$rootScope",
    "$modalInstance",
    "AppSettings",
  ];

  private lat: string;
  private lon: string;
  private mapViewApiBaseUrl: string;
  private mapApiQueryString: string;

  constructor(
    private $scope: any,
    private $rootScope: any,
    private $modalInstance: any,
    private appSettings: AppSettings,
  ) {
    this.$scope.close = () => {
      this.$modalInstance.dismiss("close");
    };

    this.$scope.yes = () => {
      this.$modalInstance.close("yes");
    };

    this.mapViewApiBaseUrl = "https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/";
    this.mapApiQueryString = `access_token=${this.appSettings.mapboxAPIKey}&logo=false&attribution=false`;

    this.$scope.modalMapUrl = this.getMapboxUrl();
    this.modalWidthListener();
  }

  public getMapboxUrl(): string {
    return `${this.mapViewApiBaseUrl}${this.lon},${this.lat},16/733x600?${this.mapApiQueryString}`;
  }

  private modalWidthListener() {
    this.$scope.mapModal = this.$rootScope.$watch("globalState.browserWidth", () => {
      if (this.$rootScope.globalState.browserWidth <= ViewportSizes.XS_MAX) {
        this.$scope.close();
        this.$scope.mapModal();
      }
    });
  }
}

angular.module("BookingApp").controller("MapModal", MapModal);
