import { ViewportSizes } from "booking_app/types/viewport-sizes";

export class FormPopupMobileController {

  static $inject = ["$rootScope", "$scope", "$element"];

  // Bindings
  maxModalWidth: ViewportSizes.SM_MAX;
  closeModal: () => void;

  constructor(
    private $rootScope: any,
    private $scope: any,
    private $element: any,
  ) { }

  $onInit(): void {
    this.$rootScope.lockBody = "lock-body";
    if (this.maxModalWidth) {
      this.setupModalWidthListener();
    }
    this.$element.attr("role", "dialog");
    this.$element.attr("aria-modal", "true");
  }

  $onDestroy() {
    this.$rootScope.lockBody = "";
  }

  private setupModalWidthListener() {
    // Added a plus 1 here because mobile view starts at ViewportSizes.SM_MAX and we only want to trigger
    // this event after mobile view
    const MAX_WIDTH = this.maxModalWidth + 1;

    // We should implement this to places that uses $watch
    // This is method is called deregistration.
    // triggering the watchWidthEvent here will remove the $watch for events
    // More notes can be found on PR 5095
    const watchWidthEvent = this.$rootScope.$watch("globalState.browserWidth", () => {
      if (this.$rootScope.globalState.browserWidth >= MAX_WIDTH) {
        this.closeModal();
        watchWidthEvent();
      }
    });
  }
}
