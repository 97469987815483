import { PhoneCountryCodeSelectController } from './phone-country-code-select.controller';
import { Bindings } from 'booking_app/types';

export class PhoneCountryCodeSelectComponent {
  bindings: any;
  controller: any;
  template: string;

  constructor() {
    this.bindings = {
      value: Bindings.ONE_WAY,
      formName: Bindings.ONE_WAY,
      onUpdate: Bindings.EXPRESSION,
    };
    this.controller = PhoneCountryCodeSelectController;
    this.template = `
    <input-select
      value-formatter="$ctrl.displayOptions"
      selected-value="$ctrl.internalValue"
      placeholder="'Select'"
      on-update="$ctrl.onUpdateInternalValue(value)"
      form-name="$ctrl.formName"
      options="$ctrl.options"
      search-enabled="true"
      trigger-update-on-init="true"
    ></input-select>
    `;
  }
}

angular.module("BookingApp").component("phoneCountryCodeSelectComponent", new PhoneCountryCodeSelectComponent());
