import { ApiDataService  } from "./api-data.service";
import { CouponCodeDetails, CouponCodeResponse  } from "../models/coupon-code-details";
import { AppSettings } from "../values/app-settings";

export const COUPON_CODE_ERROR_MAPPER = {
  "Invalid Coupon code": "txt.invalid_coupon_code",
  "Coupon has expired": "txt.expired_coupon_code",
  "Coupon fully redeemed": "txt.fully_redeemed_coupon_code",
  "Conditions not met": "txt.not_eligible_coupon_code",
};

export class CouponService {

  static $inject = [
    "$q",
    "ApiDataService",
    "AppSettings",
  ];

  public validatingCouponCode: boolean;
  public validatingPriceChanged: boolean;
  public couponCodeDetails: CouponCodeDetails;
  private couponErrorMapper = COUPON_CODE_ERROR_MAPPER;

  constructor(
    private $q,
    private apiDataService: ApiDataService,
    private appSettings: AppSettings,
  ) {
    this.validatingCouponCode = false;
    this.validatingPriceChanged = false;
    this.initializeCouponDetails();
  }

  public validateCouponCode(couponCode: string) {
    this.validatingCouponCode = true;
    this.couponCodeDetails.response.valid = false;
    this.couponCodeDetails.response.errorMsg = null;
    const url = "coupon_codes/validate";
    return this.apiDataService.post(url, {coupon_code: couponCode})
    .then(
      (data) => {
        this.couponCodeDetails.response.valid = true;
        return data;
      },
      (error) => {
        this.couponCodeDetails.response.valid = false;
        this.couponCodeDetails.response.errorMsg = error.error;
        return this.$q.reject(error);
      },
    ).finally(() => {
      this.validatingCouponCode = false;
    });
  }

  public initializeCouponDetails(): void {
    this.couponCodeDetails = {
      code: "",
      response: {
        valid: false,
        errorMsg: null,
      },
    };
  }

  public showCouponCodeUI(): boolean {
    return this.appSettings.hasCouponCode;
  }

  disableButton() {
    return this.couponCodeDetails.code === ""
      || this.validatingCouponCode
      || this.validatingPriceChanged
      || this.isValid();
  }

  resetCouponCode() {
    this.couponCodeDetails.code = "";
    this.validatingCouponCode = false;
    this.validatingPriceChanged = false;
    this.couponCodeDetails.response.valid = false;
    this.resetMessage();
  }

  public resetMessage() {
    this.couponCodeDetails.response.errorMsg = null;
  }

  public isValid() {
    return this.couponCodeDetails.response.valid && !this.validatingPriceChanged;
  }

  public hasErrorMessage() {
    return !!this.couponCodeDetails.response.errorMsg;
  }

  public couponErrorMessage(): string {
    return this.couponErrorMapper[this.couponCodeDetails.response.errorMsg];
  }

  public showCouponAppliedMessage(): boolean {
    return this.showCouponCodeUI && this.isValid();
  }

}

angular.module("BookingApp").service("CouponService", CouponService);
