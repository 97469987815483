import { convertJSONToQueryString } from 'booking_app/services/route-params-utils';
import { FlightsSearchFormState } from '.';
import { AppSettings } from "booking_app/values/app-settings";
import { PointsCashShareService } from "booking_app/services/points-cash-share.service";

declare var angular: any;

export class FlightsSearchFormService {
  static $inject = [
    "$rootScope",
    "FlightsSearchFormState",
    "AppSettings",
    "PointsCashShareService",
  ];

  constructor(
    private $rootScope: any,
    private formState: FlightsSearchFormState,
    private appSettings: AppSettings,
    private pointsCashShareService: PointsCashShareService,
  ) {}

  generateResultsUrl() {
    const urlParams: any = {
      fromAirport: this.formState.fromAirport.value,
      toAirport: this.formState.toAirport.value,
      departureDate: this.formState.departureDate,
      returnDate: this.formState.returnDate,
      cabin: this.formState.cabin,
      passengers: `${this.formState.adultCount}:${this.formState.childCount}:${this.formState.infantCount}`,
      flightType: this.formState.flightType,
      currency: this.$rootScope.selectedCurrency.code,
      landingPage: this.$rootScope.landingPage.url,
      partnerId: this.$rootScope.pointsPartner.id,
      pointsCashShare: this.pointsCashShareService.pointsCashShare?.value,
      pointsAccountId: this.$rootScope.pointsAccountId,
    };

    /* We need to have below logic in place until all WLs use new productType.
    /* When all whitelabels move to new productType , we can add this directly in the urlParams object */
    Object.assign(urlParams ,
      this.appSettings.useProductType ? { productType: this.$rootScope.globalState.productType } : null);

    const url = `/flights/results?${convertJSONToQueryString(urlParams)}`;

    return url;
  }
}

angular.module("BookingApp").service("FlightsSearchFormService", FlightsSearchFormService);
