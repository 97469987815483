export enum Browser {
  IOS_SAFARI = "ios-safari",
  IOS_FIREFOX = "ios-firefox",
  IOS_CHROME = "ios-chrome",
  ANDROID_CHROME = "android-chrome",
  ANDROID_FIREFOX = "android-firefox",
  ANDROID_OPERA = "android-opera",
  ANDROID_SAMSUNG_INTERNET = "android-samsung-internet",
  MOBILE_PUFFIN = "mobile-puffin",
  IE11 = "ie11",
}
