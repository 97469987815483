import { WhitelabelTranslateService } from "booking_app/services/whitelabel-translate.service";
declare var Rollbar: any;
// wl-translate: string; => "String to be translated"

// translate-values: "{ membership_no: 'some string here' }"
  // similar implementation as the original translate-values (interpolate params)

angular.module("BookingApp")
  .directive("wlTranslate", [
    "WhitelabelTranslateService",
    "$rootScope", (
      whitelabelTranslateService: WhitelabelTranslateService,
      $rootScope: any,
    ) => {
      return {
        restrict: "A",
        link: (scope: any, element: any, attr: any) => {
          scope.$watch(
            () =>  $rootScope.selectedLocale,
            () => {
              const interpolationParams = () => {
                let jsonStr: any;
                // Converts string to parseable json string
                if (!!attr.translateValues) {
                  return {};
                }
                try {
                  jsonStr = attr.translateValues.replace(/(\w+:)|(\w+ :)/g, (matchedStr) => {
                    return '"' + matchedStr.substring(0, matchedStr.length - 1) + '":';
                  }).replace(/'/g, '"');
                } catch (err) {
                  Rollbar.warning("Error on translate values: " + attr.translateValues);
                  jsonStr = "{}";
                }

                return JSON.parse(jsonStr);
              };

              element[0].textContent = whitelabelTranslateService.translate(attr.wlTranslate, interpolationParams);
            });

          return;
        },
      };
    },
  ]);
