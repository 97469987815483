angular.module("BookingApp")

.factory "LocalCallCacheService", [

  '$q'
  ($q) ->

    cacheContent = {}

    buildCache = (name, key, time) ->
      cache = {content: [], timestamp: Date.now(), key: key, ready: false, time: time}
      cacheContent[name] = cache
      cacheContent[name]


    isCached = (name, key) ->
      cache = cacheContent[name]

      return unless cache

      cache and
      cache.key == key and
      cache.ready and
      (cache.timestamp + cache.time*1000) > Date.now()

    typeIsArray = Array.isArray || ( value ) -> return {}.toString.call( value ) is '[object Array]'

    fillCache = (cache, newContent, isReady) ->
      if isReady(newContent)
        cache.ready = true
        cache.content = newContent
      cache


    # @cacheCall - fetches request result from cache or runs request and caches
    # output
    #
    # params:
    # * name - the name of the request
    # * key  - string that identifies the request
    # * time - cache time (in seconds)
    # * request - a function that runs requests, takes deferred as an
    # argument; needs to run resolve!
    # * isReady - function that decides if request is finished or not, takes
    # request result as a parameter; default one always returns true
    #
    # returns a promise that will either immediately return cached content
    # or will run the request and return request's result
    @cacheCall = (name, key, time, request, isReady) ->
      isReady ||= (_) -> true
      time ||= 300 # 5 minutes

      deferred = $q.defer()
      promise = deferred.promise

      if isCached(name, key)
        deferred.resolve(cacheContent[name].content)
      else
        cache = buildCache(name, key, time)
        request(deferred)
        promise.then (result) ->
          fillCache(cache, result, isReady)

      promise

    LocalCache = {cacheCall: @cacheCall}

    return LocalCache
]
