import { GlobalStateService } from "booking_app/services/global-state.service";
import { CurrentPage } from "booking_app/types";

export class NavigationBarController {
  static $inject = [
    "$rootScope",
    "$translate",
    "GlobalStateService",
  ];

  // Bindings
  navBarText: string;
  navAction: () => void;

  // Injectors
  constructor(
    private $rootScope: any,
    private $translate: any,
    private globalStateService: GlobalStateService,
  ) {}

  translatedNavText(): string {
    return this.$translate.instant(this.navBarText);
  }

  isHomePage(): boolean {
    const currentPage: CurrentPage = this.globalStateService.currentPage;
    return currentPage === CurrentPage.HOME;
  }

  userLoggedin(): boolean {
    return this.$rootScope.userDetails.loggedIn ;
  }

  displayPointsBalance(): boolean {
    return this.userLoggedin() && this.$rootScope.globalState.showNavMenu;
  }

  toggleSideNav(): void {
    this.$rootScope.globalState.showNavMenu = !this.$rootScope.globalState.showNavMenu;
  }
}
