import { CouponCodeController } from "./coupon-code.controller";
import { Bindings } from "booking_app/types";

export class CouponCodeComponent {
  bindings: any = {
    applyCouponCode: Bindings.EXPRESSION,
    resetCouponCode: Bindings.EXPRESSION,
  };
  controller: any = CouponCodeController;
  templateUrl: string = "/html/hotels/coupon_code";
}

angular.module("BookingApp").component("couponCode", new CouponCodeComponent());
