import { AppSettings } from "booking_app/values/app-settings";

export class HotelDescriptionController {
  static $inject = ["$rootScope", "$timeout", "AppSettings"];

  description: string;
  constructor(
    private $rootScope: any,
    private $timeout: any,
    private appSettings: AppSettings,
  ) {
    this.$rootScope.globalState.showFullDescription = false;
  }

  toggleDescription(): void {
    this.$rootScope.globalState.showFullDescription = !this.showFullDescription() ? true : false;
    // This scroll event is for the sticky headers computation
    // computation finish first before the hide/show therfore causing some issues on the display
    this.$timeout(() => this.triggerScrollEvent(), 100);
  }

  showFullDescription(): boolean {
    return this.$rootScope.globalState.showFullDescription;
  }

  isDescriptionLoading(): boolean {
    return !this.description;
  }

  private triggerScrollEvent(): void {
    const scrollEvent = window.document.createEvent("UIEvents");
    scrollEvent.initEvent("scroll", true, true);
    window.dispatchEvent(scrollEvent);
  }
}
