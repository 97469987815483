import { LandingPageTilesController } from "./landing-page-tiles.controller";
import { Bindings } from "booking_app/types";
import "booking_app/directives/translate-singular";

export class LandingPageTilesComponent {
  bindings: any = {
    travelType: Bindings.ONE_WAY,
    productType: Bindings.ONE_WAY,
    onHotelSearch: Bindings.EXPRESSION,
    onFlightSearch: Bindings.EXPRESSION,
    onCarSearch: Bindings.EXPRESSION,
  };
  controller: any = LandingPageTilesController;
  templateUrl: string = "/html/components/landing_page_tiles";
}

angular.module("BookingApp").component("landingPageTiles", new LandingPageTilesComponent());
