import { AngularUtils, DisplayUtils } from "booking_app/utils";
import { CityTile } from "booking_app/models";
import { CityTilesService } from "booking_app/services/city-tiles.service";
import { AppSettings } from "booking_app/values/app-settings";

import {
  LandingPageTilesType,
  ProductType,
  TravelType,
} from "booking_app/types";
import { GlobalStateService } from "booking_app/services/global-state.service";

export class LandingPageTilesController {
  static $inject = [
    "DisplayUtils",
    "GlobalStateService",
    "CityTilesService",
    "$translate",
    "$filter",
    "$rootScope",
    "AppSettings",
  ];

  // bindings
  travelType: TravelType;
  productType: ProductType;
  onHotelSearch: (obj: {
    id: string,
    name: string,
    nights: number,
    lat: number,
    lng: number,
  }) => void;

  onFlightSearch: (params?: {
    fromAirport: string,
    toAirport: string,
  }) => void;

  onCarSearch: (params?: {
    pickupLocationId: string,
    pickupLocationName: string,
    returnLocationId: string,
    returnLocationName: string,
  }) => void;

  // local state
  // TO DO: we wont need this product type once we moved to api call based
  // this is because we will be using the current landing page product type
  tilesData: {
    [travelType in TravelType]: {
      [productType in ProductType]: CityTile[];
    }
  };

  currentTilesData: CityTile[];

  constructor(
    private displayUtils: DisplayUtils,
    private globalStateService: GlobalStateService,
    private cityTilesService: CityTilesService,
    private $translate: any,
    private $filter: any,
    public $rootScope: any,
    public appSettings: AppSettings,
  ) { }

  $onInit() {
    this.initTilesData();
  }

  $onChanges(changesObj: { travelType?: any, productType?: any }) {
    if (
      AngularUtils.hasBindingChanged(changesObj.travelType) ||
      AngularUtils.hasBindingChanged(changesObj.productType)) {
      this.assignTilesData();
    }
  }

  assignTilesData(): void {
    if (this.tilesData) {
      this.currentTilesData = this.tilesData[this.travelType][this.productType];
    } else {
      this.currentTilesData = [];
    }
  }

  componentTitleKey(): string {
    return `wl.lp_tiles.component_title_${this.travelType}`;
  }

  milesLabelKey(): string {
    return `wl.lp_tiles.miles_label_${this.travelType}_${this.productType}`;
  }

  tileActionKey(): string {
    return `wl.lp_tiles.action_${this.travelType}`;
  }

  durationKey(): string {
    if (this.globalStateService.isTravelType(TravelType.HOTELS)) {
      return "txt.night";
    } else {
      return "txt.day_one";
    }
  }

  displayedAmount(tile: CityTile): string {
    if (this.$rootScope.landingPage.hasProductType(ProductType.REDEEM)) {
      return this.milesAmountDisplay(tile);
    } else if (this.$rootScope.landingPage.hasProductType(ProductType.EARN)) {
      const amount = this.$filter("localeNumberFmt")(this.tileAmount(tile) || tile.search_params.amount);
      return `${this.currencyLabel(tile)} ${amount}`;
    }
  }

  backgroundImgStyle(tile: CityTile): { [key: string]: string } {
    return {
      "background-image": `url(${tile.image_url})`,
    };
  }

  search(tile: CityTile): void {
    switch (this.travelType) {
      case "hotels":
        this.onHotelSearch({
          id: tile.search_params.id,
          name: this.$translate.instant(tile.search_params.name),
          nights: this.appSettings.destinationTileCheckoutDaysAfter,
          lat: tile.search_params.lat,
          lng: tile.search_params.lng,
        });
        break;
      case "cars":
        this.onCarSearch({
          pickupLocationId: tile.search_params.id,
          pickupLocationName: tile.search_params.value,
          returnLocationId: tile.search_params.id,
          returnLocationName: tile.search_params.value,
        });
        break;
      case "flights":
        const locationIds = tile.search_params.id.toString().split("|");
        this.onFlightSearch({
          fromAirport: locationIds[0],
          toAirport: locationIds[1],
        });
        break;
    }
  }

  milesAmountDisplay(tile: CityTile): string {
    const amount = this.$filter("localeNumberFmt")(this.tileAmount(tile));
    const points = this.$filter("localeNumberFmt")(this.tilePoints(tile));
    let amountDisplay = `${amount} ${this.$translate.instant(
        this.displayUtils.partnerShortCurrency(),
      )}`;

    if (points) {
      amountDisplay = `${this.currencyLabel(tile)} ${amount} <span>or</span> ${points} ${this.pointsLabel(tile)}`;
    }

    return amountDisplay;
  }

  private tileAmount(tile): number {
    if (typeof tile.search_params.amount === "number") {
      return tile.search_params.amount;
    } else {
      return tile.search_params.amount[this.displayUtils.partnerShortCurrency()];
    }
  }

  private tilePoints(tile): number {
    return tile.search_params.points;
  }

  private currencyLabel(tile): string {
    return tile.search_params.currency || "USD";
  }

  private pointsLabel(tile): string {
    return tile.search_params.currency_label || "points";
  }

  private initTilesData(): void {
    this.cityTilesService.fetchCityTile().then((data) => {
      this.rebuildCityTilesData(data);
      this.assignTilesData();
    });
  }

  private rebuildCityTilesData(cityTiles): any {
    this.tilesData = cityTiles.reduce((currentTiles, cityTile) => {
      // we set this as current productType because we based the api call on the landing page which has the product type
      if (!currentTiles[cityTile.travel_type]) {
        // TO DO: remove condition once we migrate everything to landing page city tiles api
        currentTiles[cityTile.travel_type] = { [this.productType]: undefined };
      }
      currentTiles[cityTile.travel_type][this.productType] =
        [...currentTiles[cityTile.travel_type][this.productType] || [], cityTile];
      return currentTiles;
    }, {});
  }
}
