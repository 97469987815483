import { Bindings } from "booking_app/types";
import { GoogleAnalyticsController } from "./google-analytics.controller";

export class GoogleAnalyticsComponent {
  controller: any;
  template: string;

  constructor() {
    this.controller = GoogleAnalyticsController;
    this.template = "";
  }
}

angular.module("BookingApp").component("googleAnalytics", new GoogleAnalyticsComponent());
