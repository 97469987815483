import { RoomInfoItem } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";

export class RoomImageController {
  static $inject = ["AppSettings"];

  // Bindings
  private room: RoomInfoItem;

  constructor(private appSettings: AppSettings) {}

  hasRoomImages(): boolean {
    return this.room.images && this.room.images.length > 0;
  }

  displayRoomImage(): string {
    if (this.appSettings.useHighResolutionUrl) {
      return this.room.images[0].high_resolution_url || this.room.images[0].url;
    } else {
      return this.room.images[0].url;
    }
  }
}
