export class TrustyouReviewModal {

  static $inject = ["$scope", "$modalInstance", "hotel"];

  constructor(
    private $scope: any,
    private $modalInstance: any,
    public hotel: any,
  ) {
    this.$scope.close = () => this.$modalInstance.dismiss("close");

    this.$scope.hotelDetail = hotel;
    this.$scope.hotelName = hotel.name;
  }
}

angular.module("BookingApp").controller("TrustyouReviewModal", TrustyouReviewModal);
