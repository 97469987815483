import { MobileRoomPricesButtonController } from "./mobile-room-prices-button.controller";
import { Bindings } from "booking_app/types";

// A component that only shows for when hotel details page is @screen-size-sm.
// its basically a component that holds the price-box for the mobile view of JAL

export class MobileRoomPricesButtonComponent {
  bindings: any = {
    productType: Bindings.ONE_WAY,
    searchCompleted: Bindings.ONE_WAY,
    rooms: Bindings.ONE_WAY,
    scrollTo: Bindings.EXPRESSION,
    loyaltyPointLabel: Bindings.ONE_WAY,
  };
  controller: any = MobileRoomPricesButtonController;
  templateUrl: string = "/html/components/mobile_room_prices_button";
}

angular.module("BookingApp").component("mobileRoomPricesButton", new MobileRoomPricesButtonComponent());
