import { AppSettings } from "booking_app/values/app-settings";
import { RoomInfoItem } from "booking_app/types";
import { UrlUtils } from "booking_app/utils";
import { ProductType } from "booking_app/types/product-type";
import { CheckoutValidationService } from "booking_app/services/checkout-validation";
import { HotelDetailsService } from "booking_app/services/hotels/hotel-details.service";

export class GroupedRoomController {
  static $inject = [
    "$scope",
    "$rootScope",
    "AppSettings",
    "UrlUtils",
    "CheckoutValidationService",
    "HotelDetailsService",
  ];
  // bindings
  public policies: any;
  public pointsPartner: any;
  public showAllRooms: boolean;
  public currentLimit: number;

  private redirectToHotelCheckoutPage: ({bookingRoom}) => void;
  private isPointChecking: boolean;

  constructor(
    private $scope: any,
    private $rootScope: any,
    private appSettings: AppSettings,
    private urlUtils: UrlUtils,
    private checkoutValidationService: CheckoutValidationService,
    private hotelDetailsService: HotelDetailsService,
  ) {
  }

  $onInit() {
    this.currentLimit = 1;
    this.isPointChecking = this.isPointChecking || false;
  }

  freeCancellation(): boolean {
    if (this.$rootScope.landingPage.hasProductType(ProductType.REDEEM)) {
      return this.appSettings.showCancelPolicyOnRedeem;
    } else if (this.$rootScope.landingPage.useVoucherOnly) {
      return false;
    } else {
      return this.appSettings.showCancelPolicyOnEarn;
    }
  }

  roomListing(): any {
    return this.hotelDetailsService.roomListing;
  }

  goToHotelCheckoutPage(room): void {
    const promiseValidToCheckout = this.checkoutValidationService.checkIfValidToCheckout(
      room,
      this.isPointChecking,
    );
    promiseValidToCheckout.then(() => {
      this.redirectToHotelCheckoutPage({ bookingRoom: room });
    },
    () => {
      return; // error
    });
  }

  roomDefaultImage(): string {
    return this.urlUtils.imageUrl(`/hotels_missing_images/hotel-room.jpg`);
  }
}
