import { ApiDataService } from 'booking_app/services/api-data.service';

export class BonusesRegistrationService {

  static $inject = [
    '$q',
    'ApiDataService',
  ]

  constructor(
    private $q,
    private apiDataService: ApiDataService
  ) { }

  public registerBonus(landingPageUrl, name, email, sendMarketing, referralCode) {
    return this.apiDataService.post(
      "bonuses",
      { first_name: name,
        email: email,
        referral_code: referralCode,
        send_marketing: sendMarketing,
        url: landingPageUrl
      },
    ).then(
      (data) => data || [],
      (error) => this.$q.reject(error || [])
    )
  }
}

angular.module('BookingApp').service('BonusesRegistrationService', BonusesRegistrationService)
