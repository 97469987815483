require('../constants/constants')
require('../constants/route-resolve')

require('../directives/parse-style')
require('../directives/destination-select2')
require('../directives/set-to-self')
require('../directives/watch-width')
require('../directives/convert-to-number')
require('../directives/click-outside')
require('../directives/image-src-check')
require('../directives/select-scroll-detector')
require('../directives/cdn-path')
require('../directives/whitelabel-translate')
require('../directives/humanize-translation')
require('../directives/link-change-target')
require('../directives/skip-to')
require('../directives/validate-membership-no')


require('../factories/lang-loader')
require('../factories/points-partners-collection')
require('../factories/user-factory')

require('../filters/filter')
require('../filters/format-currency')
require('../filters/points-cash-arialabel')

require('../services/landing-page.service')
require('../services/auth-access')
require('../services/currencies.service')
require('../services/default-locale')
require('../services/event-triggers')
require('../services/floodlight')
require('../services/facebook-pixel')
require('../services/mapbox')
require('../services/mapbox-direction')
require('../services/pay-with-points-cash.service')
require('../services/search-sorting.service')
require('../services/cookie.service')
require('../services/utils')
require('../services/api-polling.service')
require('../services/city-country-builder.service')
require('../services/session-timer.service')
require('../services/global-state.service')
require('../services/travel-type.service')
require('../services/points-cash-share.service')
require('../services/whitelabel-translate.service')
require('../services/city-tiles.service')
require('../values/app-state')
require('../services/checkout-options')

require('../main-config')
require('../main-routing')

angular.module("BookingApp")

.controller "MainCtrl", [

  "$scope", "$rootScope", "$location", "$window", "$timeout",
  "CookieService", "AppConfig", "AppSettings", "LandingPageService", "EventTriggers",
  "PointsPartnersCollection", "CurrenciesService",
  "FloodlightService", 'FacebookPixelService',
  "ReservedUrlKeywords", "KaligoConfig", "AppUser", "SearchSortingService",
  "KaligoConstants", "DefaultLocale", "UtilsService",
  "KaligoUser", "AuthAccess", "PayWithPointsCashService", "UrlUtils",
  'AppState', 'MapboxService', 'MapboxState', 'SessionTimerService',
  "MapboxDirectionService", "ModalManager", "GlobalStateService",
  "PointsCashShareService", "$modalStack", "CheckoutOptions"

  ($scope, $rootScope, $location, $window, $timeout,
  CookieService, AppConfig, AppSettings, LandingPageService, EventTriggers,
  PointsPartnersCollection, CurrenciesService,
  FloodlightService, FacebookPixelService,
  ReservedUrlKeywords, KaligoConfig, AppUser, SearchSortingService,
  KaligoConstants, DefaultLocale, UtilsService,
  KaligoUser, AuthAccess, PayWithPointsCashService, UrlUtils,
  AppState, MapboxService, MapboxState, SessionTimerService,
  MapboxDirectionService, ModalManager, GlobalStateService,
  PointsCashShareService, $modalStack, CheckoutOptions) ->

    AppState.session = {
      start: Date.now(),
      expired: false
    }

    urlParams = $location.search()
    urlPointsPartnerId = parseInt(urlParams.partnerId)

    $rootScope.userDetails = {loggedIn: false}
    $rootScope.selectedCountrySite = {text: 'International', default_partner: 6}
    $rootScope.selectedCurrency = {text: CookieService.getCurrency()}
    $rootScope.pointsPartners = undefined
    $rootScope.pointsPartner  = undefined
    $rootScope.pageContent = undefined
    $rootScope.bookingKeysBlackList = []
    $rootScope.showWelcomeBox = true
    $rootScope.activePanel = null
    $rootScope.paymentMode = "credit_card" # NEED TO SET THIS DEFAULT VALUE
    $rootScope.mapboxState = MapboxState
    $rootScope.mapboxDirectionService = MapboxDirectionService
    $rootScope.rdUrl = AppConfig.rd_url if AppConfig.rd_url
    $rootScope.nnUrl = AppConfig.nn_url if AppConfig.nn_url
    $rootScope.ghUrl = AppConfig.gh_url if AppConfig.gh_url
    $rootScope.isKeyboardUser = false

    $rootScope.abTest = {}

    $timeout( ->
      $rootScope.globalState.loaded = true
    , 100)

    $rootScope.const = {
      cdnUrl: KaligoConfig.cdnUrl
      cdnImageUrl: KaligoConfig.cdnImageUrl
      cdnHighResImageUrl: KaligoConfig.highResImagesCdnUrl
      noImgUrl: KaligoConfig.cdnUrl + "/assets/images/img-no-images.png"
      config: KaligoConfig
      appConfig: AppConfig
      emailRegex: KaligoConstants.emailRegex
    }

    $rootScope.checkInDate = ""
    $rootScope.checkOutDate = ""

    $rootScope.globalState = {
      showTotalNights: false
      showRegisterForm: false
      showForgetPasswordForm: false
      showResultFilter: false
      showInterstitial: false
      processing: false
      hasTriggeredViewLanding: false
      showNavMenu: false
      displaySearchBottomSheet: false
      displaySortPopup: false
      activeModal: false
      browserWidth: angular.element($window).width()
      searchCompleted: false
      isNewSearch: false
      isSelectingPartner: false
      isCancellationPolicyError: false
      requireLoginOnCheckout: false
      pointsPartnerIcon: ""
      useSimpleLoader: false
      checkoutHideUserDetailName: false
      serviceEmail: "service@kaligo.com"
      search_results: {
        modifiers: [],
        ui: {
          extra_disclaimer: null
        }
      }
      displayMembershipAtCheckout: true
      loginEndpoint: '/sign_in'
      signupEndpoint: '/users'
      forgetPassEndpoint: '/users/password'
      useMembershipNoAsEmail: false
      hideUseAddressFromProfile: false
      taxInclusive: false
      alwaysAskForEmail: false
      bookingKeyExpired: false
      displayAlignment: "ltr"
      requestVoucher: {
        url: "",
        linkText: ""
      }
      specialRequestText: "Please note that requests are passed to the hotel and not guaranteed"
      productType: KaligoConfig['PRODUCT_TYPE']
      carsRedemptionLabel: "search.filter.pwp_redemption_range"
      marketRatesCount: 4
    }

    # Common Login Modal Config
    $rootScope.globalState.openLogin = (isStatic = false, redirectUrl = '') ->
      $rootScope.isLoading = true
      $rootScope.globalState.showNavMenu = false
      ModalManager.open({
        animation: true
        templateUrl: '/html/modals/login_modal'
        windowClass: AppSettings.loginModalConfig.loginModalWindowClass
        size: AppSettings.loginModalConfig.loginModalSize
        controller: 'LoginPopupCtrl'
        controllerAs: 'popup'
        backdrop: if isStatic then 'static' else true
        backdropClass: if redirectUrl then 'validation-backdrop' else ''
        resolve: {
          redirectUrl: () -> redirectUrl,
        }
      })
      return

    $rootScope.routeParams = {}
    $rootScope.searchState = {}

    # This represents a set of preferential
    # currencies for specified landing pages
    landingPageCurrencies = {
      "amex-sme-uk": "GBP"
    }

    if !CookieService.fetchCookie('showInterstitial')
      $rootScope.globalState.showInterstitial = true
      CookieService.setCookie('showInterstitial', true)

    formatUserDetails = (data) ->
      if !data || data is "null"
        return { loggedIn: false, user: null }
      if data.birthday
        [data.year, data.month, data.day] = data.birthday.split('-').map (value) -> parseInt(value)
      return { loggedIn: true, user: data }

    $rootScope.userDetails = KaligoUser().format(AppUser)

    $rootScope.banners = AppConfig.banners
    $rootScope.company = AppConfig.company

    if $rootScope.company
      $rootScope.globalState.companyMembershipCurrency = $rootScope.company.membership_currency
      $rootScope.company.isOneMan = true if $rootScope.company.name == $rootScope.userDetails.user.email

    $rootScope.selectedCountrySite = {
      code: AppConfig.country_code
    }

    #Locales
    DefaultLocale.init(AppConfig.languages)

    # !! IMPORTANT !!
    # The order for setting the config need to maintain this format
    # 1. Points Partners
    # 2. Landing Pages
    # 3. Currencies

    #PointsPartners
    $rootScope.pointsPartners = PointsPartnersCollection(AppConfig.partners.partners, AppConfig.partners.default)
    $rootScope.pointsPartners.responsive = [
      {
        breakpoint: 10240,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]

    $rootScope.$on "$locationChangeStart", () ->
      $modalStack.dismissAll("cancel")
      document.getElementsByTagName("body")[0].classList.remove("modal-open")

    $rootScope.isUserLoggedIn = () ->
      return $rootScope.userDetails &&
        $rootScope.userDetails.loggedIn

    $rootScope.pointsPartner  = $rootScope.pointsPartners.determinePartner(
      urlPointsPartnerId,
      AppConfig.landing_page.points_partner_id,
      CookieService.getPartner(),
      AppConfig.default_points_partner_id
    )

    $rootScope.useProductType = () ->
      AppSettings.useProductType

    # In front-end we store the productType in a different way compare to backend
    # Below method will convert the productType according to the backend
    # And will be used in the places where we call prices , booking confirmation API calls
    $rootScope.productTypeAdapter = (productType) ->
      switch productType
        when 'redeem' then 'pay_with_points'
        when 'cash-voucher' then 'cash_voucher_earn'
        when 'voucher' then 'free_night'
        when 'complimentary-nights' then 'complimentary_nights'
        else productType

    #Set landingPage if either landingPage url param is present or DLP is used
    emptyLandingPage = {points_partner_id: null, id: null}

    $rootScope.landingPage = LandingPageService.init(AppConfig.landing_page || emptyLandingPage)

    GlobalStateService.initState()

    $scope.globalStateService = GlobalStateService

    # urlParams.prooductType will be returned if it is not landingPage
    getCurrentProductType = (productType) ->
      urlParams.productType || productType

    $scope.globalStateService = GlobalStateService

    # When this ctrl getting initialized for the first time, Appsettings.useProductType
    # Is false , since we override it in WL controller and it is not initialized yet.
    # We send the kaligoconfig initial value from the respective WL controller.
    # And reinitialize it in the landing page.
    $rootScope.initializeProductType = (productType) ->
      if productType
        getCurrentProductType(productType)
      else
        if $rootScope.landingPage.hasProductType("voucher")
          return "voucher"
        else if $rootScope.landingPage.hasProductType("cash-voucher")
          return "cash-voucher"
        else if $rootScope.landingPage.hasProductType("redeem")
          return "redeem"
        else if $rootScope.landingPage.hasProductType("earn")
          return "earn"
        else if $rootScope.landingPage.hasProductType("complimentary-nights")
          return "complimentary-nights"

    # In the past we used to depend on landingPage solely to determine which template to render (hotel list item)
    # Then WL introduced productType variable; now we just have to make sure that we use productType
    # even in non WLs.
    # This block of code is for K.com and DLPs that don't have script that explicitly sets KaligoConfig['PRODUCT_TYPE']
    $rootScope.globalState.productType = $rootScope.initializeProductType()

    $window.DLPQueen = {}
    $window.DLPQueen.template = $rootScope.landingPage.template

    #Currencies
    CurrenciesService.init(AppConfig.currencies)
    $rootScope.currenciesService = CurrenciesService
    $rootScope.allCurrencies = CurrenciesService.all()
    $rootScope.selectedCurrency = angular.copy(
      CurrenciesService.findByCode(landingPageCurrencies[$rootScope.landingPage.url]) ||
      CurrenciesService.findByCode(urlParams.currency) ||
      CurrenciesService.findByCode(CookieService.getCurrency()) ||
      CurrenciesService.findByCode(AppConfig.default_currency) ||
      CurrenciesService.default()
    )
    $rootScope.convert_rate = $rootScope.selectedCurrency.rate

    # for commbank-video page, bring user to 'commbank' dlp page when kaligo logo at header is clicked
    $rootScope.landingPage.urlLink = "/commbank" if location.pathname.split("/")[1] == 'commbank-video'

    # load the floodlight service
    FloodlightService.init()

    # Sorting options and order | Business is currently hard-coded till we have a better way to have the backend return this
    SearchSortingService.initializePermittedSortingOptions($rootScope.landingPage.sorting_options)

    $rootScope.$watch 'selectedCurrency', ((newValue, oldValue) ->
      $rootScope.convert_rate = newValue.rate if newValue
      return if newValue == oldValue
      $rootScope.$broadcast("currencyChanged")
      $window.DLPQueen.currency = newValue.code
      EventTriggers.updateCurrency(newValue.code)
    ), true

    checkDisplayAlignment = () ->
      return 'ltr' if !$rootScope.selectedLocale
      if $rootScope.selectedLocale.lang_code in ['ar', 'he'] then "rtl" else "ltr"

    $rootScope.$watch 'selectedLocale',((newValue) ->
      if newValue
        selectedAdultCountText = $('.selectedAdultCount span').not('.ng-hide').html()
        $('#adultCount .select2-chosen').html(selectedAdultCountText)
        selectedRoomCountText = $('.selectedRoomCount span').not('.ng-hide').html()
        $('#roomCount .select2-chosen').html(selectedRoomCountText)
        moment.locale(newValue.lang_code)
        $window.DLPQueen.locale = newValue.code
        EventTriggers.updateLocale(newValue.code)
        $rootScope.globalState.displayAlignment = checkDisplayAlignment()
        PointsCashShareService.toggleSliderRTL()
        $timeout(() ->
          $rootScope.$broadcast('rzSliderForceRender')
        , 100) if $rootScope.landingPage.hasProductType("redeem")
    ), true

    isFunctionalPage = (path) ->
      for page in ReservedUrlKeywords
        return true if path.indexOf("/#{page}/") > -1
      return false

    cdnBrandMapping = {
      red_en: UrlUtils.imageUrl("/scb/kaligo-logo-red-black.png")
      red_zh: UrlUtils.imageUrl("/Kaligo-Logo-Stack-CN.png")
      red_hk: UrlUtils.imageUrl("/Kaligo-Logo-Stack-CN.png")
      red_tw: UrlUtils.imageUrl("/Kaligo-Logo-Stack-TW.png")
      white_en: UrlUtils.imageUrl("/logo-kaligo.png")
      white_zh: UrlUtils.imageUrl("/Kaligo-Logo-Stack-CN-white.png")
      white_hk: UrlUtils.imageUrl("/Kaligo-Logo-Stack-CN-white.png")
      white_tw: UrlUtils.imageUrl("/Kaligo-Logo-Stack-TW-white.png")
    }

    localeBrand = (brandType) ->
      if $rootScope.landingPage.url == 'emirates-spend'
        return cdnBrandMapping.red_plus
      if $rootScope.selectedLocale && $rootScope.selectedLocale.code in ['zh','hk','tw']
        return cdnBrandMapping[brandType + "_" + $rootScope.selectedLocale.code]
      cdnBrandMapping[brandType + "_en"]

    isRedKaligoLogo = () ->
      ($rootScope.landingPage.dlp_logo_type == 'red' && $rootScope.navBarClass) ||
      $rootScope.navBarClass == 'landing' ||
      !$rootScope.navBarClass

    $rootScope.chineseUser = () ->
      $rootScope.showForLocales(['zh-CN', 'zh-HK', 'zh-TW', 'tw'])

    $rootScope.traditionalChineseUser = () ->
      $rootScope.showForLocales(['zh-HK', 'zh-TW', 'tw'])

    $rootScope.simplifiedChineseUser = () ->
      $rootScope.showForLocales(['zh-CN'])

    $rootScope.showForLocales = (locales) ->
      return false if !$rootScope.selectedLocale
      $rootScope.selectedLocale.lang_code in locales

    $rootScope.customCheckoutCreditingTerms = () ->
      $rootScope.pointsPartner.partner == "CommBank" || $rootScope.pointsPartner.partner == "DBS Bank Singapore"

    $rootScope.hideRedemptionSliderOnFullCash = () ->
      AppSettings.sortSettings.canUseFullCashOnRedemption &&
      PointsCashShareService.pointsCashShare.value == PointsCashShareService.pointsCashShare.sliderOptions.ceil

    $rootScope.currentPointsBalance = () ->
      if AppSettings.multiplePointsPartner
        return $rootScope.userDetails.selectedPointsBalance || 0
      else
        return $rootScope.userDetails.user.redemption_points_balance || 0

    $rootScope.currentPointsAccountName = () ->
      if AppSettings.multiplePointsPartner
        return $rootScope.userDetails.selectedPointsAccountName || ""
      else
        return ""

    $scope.blackHeaderFont = () ->
      isRedKaligoLogo()

    $scope.headerBrand = () ->
      if isRedKaligoLogo()
        return localeBrand("red")
      else
        return localeBrand("white")

    $scope.setKeyboardUser = (event) ->
      htmlElement = document.getElementsByTagName("html")[0]
      if !htmlElement.classList.contains("is-keyboard-user") && (event.code == "Tab" || event.keyCode == 9)
        htmlElement.classList.add('is-keyboard-user')
        $rootScope.isKeyboardUser = true if !$rootScope.isKeyboardUser

    $scope.setMouseUser = (event) ->
      htmlElement = document.getElementsByTagName("html")[0]
      if htmlElement.classList.contains("is-keyboard-user") && !(event.type == "click" && event.target.type == "checkbox")
        htmlElement.classList.remove('is-keyboard-user')
        $rootScope.isKeyboardUser = false if $rootScope.isKeyboardUser

    if CookieService.fetchCookie('impersonated') && $rootScope.isUserLoggedIn()
      $rootScope.showE2EImpersonatedBanner = true
      mainElement = document.getElementById("main")
      mainElement.classList.add('impersonated')

    $scope.updateDisplayNavStatus = (navState) ->
      $rootScope.globalState.showNavMenu = navState
      $timeout( ->
        $rootScope.globalState.displayNav = navState
      , 500)

    if urlParams.refer
      $rootScope.globalState.referrer = urlParams.refer

    $rootScope.$on "$routeChangeStart", (e, next, current) ->
      # need to handle errors once done
      if (current && next) &&
        current.$$route.controller == "HotelsDetailCtrl" &&
        next.$$route.controller == "ResultsCtrl"
          next.params.hotelId = current.params.hotelId
      return if KaligoConfig.ignoreAngularRoute
      $rootScope.isLoading = true
      MapboxService.resetAll()
      $rootScope.globalState.displaySearchBottomSheet = false
      $(window).unbind('scroll')
      AuthAccess.check(next)
      #handeling session counter
      if AppSettings.sessionCounter.isEnabled &&
        $rootScope.userDetails.loggedIn #only start the timer if loggedIn
          SessionTimerService.startTimer(AppSettings.sessionCounter)

    $rootScope.$on "$routeChangeSuccess", (e, current, previous) ->
      return if KaligoConfig.ignoreAngularRoute
      $timeout( ->
        $rootScope.isLoading = false
      , 10)

      # early terminate on 404 route
      return if !current.$$route

      # if pay with points landing page, initialize the PayWithPointsCashService
      if $rootScope.landingPage.hasProductType("redeem") ||
        AppSettings.pointsCashSliderSettings.alwaysShowPointsSlider
          PayWithPointsCashService.initializePayWithPointsCash()

      # check if need to trigger the fb pixel view landing page event
      # only need to trigger 1 time
      if $rootScope.globalState.hasTriggeredViewLanding &&
        current.$$route &&
        current.loadedTemplateUrl &&
        (current.$$route.originalPath == '/' || current.$$route.originalPath == '/:partner') # main page
          $rootScope.globalState.hasTriggeredViewLanding = true
          landingPage = if originPath == '/' then 'default' else current.pathParams.partner
          FacebookPixelService.eventTrack('ViewLanding', {
            landing: landingPage
          })

      path = $location.path()
      if path == '/' # main page
        $rootScope.navBarClass = 'landing'
        if CookieService.fetchCookie('index')
          $rootScope.isReturnVisitor = true
          $rootScope.showWelcomeBox = false unless UtilsService.getReferralCode($location)
        else
          $rootScope.isReturnVisitor = false
          CookieService.setCookie('index', true)
      else if isFunctionalPage(path)
        if $rootScope.landingPage.page_header_style == 'landing_only'
          $rootScope.navBarClass = null
        else
          $rootScope.navBarClass = $rootScope.landingPage.url
      else # DLP
        $rootScope.navBarClass = $rootScope.landingPage.url

        # Allow for PremierMiles Gift DLP to have different title
        if $rootScope.landingPage.url == "100gift"
          document.title = "Thousands of Hotels. Incredible Rewards."
          $rootScope.navBarClass = "pmgift" #We cannot have a CSS class start w numbers

        # Check for cookie unique to the landing page
        if CookieService.fetchCookie($rootScope.landingPage.url)
          $rootScope.isReturnVisitor = true
          $rootScope.showWelcomeBox = false unless ($location.path() == '/refer' || UtilsService.getReferralCode($location))
        else
          $rootScope.isReturnVisitor = false
          CookieService.setCookie($rootScope.landingPage.url, true)

        CookieService.setLandingPage($rootScope.landingPage.id) if CookieService.getLandingPage()

      $rootScope.isAtLandingPage = () ->
        path = $location.path()
        return path  == "/" || path  == "/#{$rootScope.landingPage.url}"

      $scope.sliderLeftLabel =
        AppSettings.pointsCashSliderSettings.sliderLeftLabel
      $scope.sliderRightLabel =
        AppSettings.pointsCashSliderSettings.sliderRightLabel
      $scope.sliderLabel =
        AppSettings.pointsCashSliderSettings.sliderLabel
      $scope.alwaysShowPointsSlider =
        AppSettings.pointsCashSliderSettings.alwaysShowPointsSlider

    $scope.tenantName = () ->
      AppSettings.tenant.toLowerCase();

    $rootScope.countriesList = CheckoutOptions.countryOptions()

    ArrayRange = (start, stop, step) ->
      Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) -> start + index * step
      )

    $rootScope.carAgeList = ArrayRange(21, 98, 1)
]

.factory "authHttpResponseInterceptor", [
  "$q", "$location", "KaligoConfig"
  ($q, $location, KaligoConfig) ->
    responseError = (rejection) ->
      if rejection.status is 401
        window.location.assign("/")
      $q.reject rejection

    request = (config) ->
      config.headers['APP-VERSION'] = KaligoConfig.APP_VERSION
      config

    return (
      responseError: responseError
      request: request
    )
]

.constant "CheckLandingPageExists", [
  "$location", "$rootScope"
  ($location, $rootScope) ->
    # if not landing page found, redirect to kaligo.com
    if !$rootScope.landingPage || !$rootScope.landingPage.url
      $location.url('/')
]

.constant "CatchAll404Route", [
  "$location", "$rootScope", "KaligoConfig"
  ($location, $rootScope, KaligoConfig) ->
    if KaligoConfig.APPLICATION_TYPE == "whitelabel"
      $location.url("/")
    else
      $location.url($rootScope.landingPage.url || "/")
]
