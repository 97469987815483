require('./currencies.service')
require('./search-utils.service')
require('./cookie.service')
require('../values/app-traffic')
require('../values/cars-state')

angular.module('BookingApp')

.service 'UtilsService', [

  '$rootScope', 'KaligoConfig', 'CurrenciesService', 'SearchUtilsService',
  'AppConfig', 'CookieService', 'AppTraffic', 'CarsState',
  ($rootScope, KaligoConfig, CurrenciesService, SearchUtilsService,
  AppConfig, CookieService, AppTraffic, CarsState) ->

    determinePaymentType = (paymentType) ->
      return 'credit_card'

    invalidHotelSearch = (destinationId) ->
      KaligoConfig.APP_VERSION == '2.0.0' && destinationId.length > 4

    # TODO: refactor this code, especially the `CurrenciesService`.
    checkFallbackCurrency = () ->
      CurrenciesService.init(AppConfig.currencies)
      $rootScope.allCurrencies = CurrenciesService.all()
      fallbackCurrency = angular.copy(
        CurrenciesService.findByCode(CookieService.getCurrency()) ||
        CurrenciesService.findByCode(AppConfig.default_currency) ||
        CurrenciesService.default()
      )
      return fallbackCurrency.code

    parseParamsValue = (val) ->
      valInt = parseInt(val)
      return 1 if !valInt
      return Math.min(4, valInt) if valInt > 4
      return Math.max(1, valInt) if valInt < 1
      return valInt

    @checkRoomsAdultsCompatibility = (routeParams) ->
      if routeParams.guests? && routeParams.guests
        roomsInfo = @parseGuestsParams(routeParams.guests)
        $rootScope.routeParams.adultCount = $rootScope.adultCount = roomsInfo.adultCount
        $rootScope.routeParams.roomCount = $rootScope.roomCount = roomsInfo.roomCount
      else
        $rootScope.routeParams.adultCount = $rootScope.adultCount = parseParamsValue(routeParams.adultCount)
        $rootScope.routeParams.roomCount = $rootScope.roomCount = parseParamsValue(routeParams.roomCount)
        $rootScope.routeParams.guests = $rootScope.guests = @formatToGuests($rootScope.roomCount, $rootScope.adultCount)
      return

    @parseGuestsParams = (guestsParam) ->
      return {} if !guestsParam || !guestsParam?
      roomsInfo = guestsParam.split("|")
      firstRoomAdultCount = roomsInfo[0].split(":")[0]

      childrenInfo = roomsInfo[0].split(":")[1]
      childrenAges = if childrenInfo then childrenInfo.split(",").map(Number) else []
      childrenCount = childrenAges.length

      return {
        roomCount: roomsInfo.length,
        adultCount: parseInt(firstRoomAdultCount, 10),
        childrenCount: childrenCount,
        childrenAges: childrenAges,
      }

    # e.g. childrenAges: [6, 10]
    @formatToGuests = (roomCount, adultCount, childrenAges = []) ->
      return "" if (!roomCount || !adultCount)
      guestsInfo = for index in [1..roomCount]
        if childrenAges.length > 0 then [adultCount, childrenAges.join(',')].join(':') else adultCount
      return guestsInfo.join('|')

    @getChildrenAges = (numOfChildren, childrenAges) ->
      return childrenAges.slice(0, numOfChildren)

    @getchildrenCount = (guest) ->
      @getChildrenAgesFromGuests(guest).length

    @getChildrenAgesFromGuests = (guest) ->
      [_adults, children] = guest.split(":")
      return [] unless children
      return children.split(',')

    @parseTrafficParams = (routeParams) ->
      AppTraffic.source = routeParams.trafficSource
      AppTraffic.clickId = routeParams.trafficClickId

    @formatSelectedDates = (scope, start, end) ->
      startDate = moment(start, 'MM/DD/YYYY')
      scope.displayCheckInDateNum = startDate.toDate()
      scope.displayCheckInDate = startDate.format("LL")
      scope.displayCheckInDateShort = startDate.format("[customDayMonth]")
      scope.displayCheckInDateWithDay = startDate.format("ddd MMM, D")
      scope.displayCheckInDateOnly = startDate.format("MMM Do")
      endDate = moment(end, 'MM/DD/YYYY')
      scope.displayCheckOutDateNum = endDate.toDate()
      scope.displayCheckOutDate = endDate.format("LL")
      scope.displayCheckOutDateShort = endDate.format("[customDayMonth]")
      scope.displayCheckOutDateWithDay = endDate.format("ddd MMM, D")
      scope.displayCheckOutDateOnly = endDate.format("MMM Do")
      return

    @parseRouteCarsParams = (routeParams) ->
      routeParams.currency = checkFallbackCurrency() if !routeParams.currency
      CarsState.init(routeParams, $rootScope.searchState)
      $rootScope.searchDatesValid = SearchUtilsService.isSelectedTimeValid(
        CarsState.pickupDateTime, CarsState.returnDateTime
      )
      # support loading pay with points selected data
      $rootScope.routeParams.pointsCashShare = routeParams.pointsCashShare
      return

    @parseRouteFlightsParams = (routeParams) ->
      $rootScope.routeParams.pointsCashShare = routeParams.pointsCashShare
      return

    @parseRouteParams = (routeParams) ->
      routeParams.currency = checkFallbackCurrency() if !routeParams.currency
      $rootScope.routeParams.destination = $rootScope.destination = routeParams.destination
      $rootScope.routeParams.destinationId = $rootScope.destinationId = routeParams.destinationId
      $rootScope.routeParams.checkInDate = $rootScope.checkInDate = routeParams.checkInDate
      $rootScope.routeParams.checkOutDate = $rootScope.checkOutDate = routeParams.checkOutDate
      $rootScope.routeParams.guests = $rootScope.guests = routeParams.guests
      $rootScope.routeParams.hotelId = $rootScope.hotelId = routeParams.hotelId
      $rootScope.routeParams.partnerId = $rootScope.partnerId = routeParams.partnerId
      $rootScope.routeParams.bookingKey = $rootScope.bookingKey = routeParams.bookingKey
      $rootScope.routeParams.bookingTransactionId = $rootScope.bookingTransactionId = routeParams.transactionId
      $rootScope.routeParams.destinationType = $rootScope.destinationType = routeParams.destination_type
      $rootScope.routeParams.destinationLat = $rootScope.destinationLat = routeParams.lat
      $rootScope.routeParams.destinationLng = $rootScope.destinationLng = routeParams.lng
      if routeParams.payment_channel
        $rootScope.routeParams.paymentMode = $rootScope.paymentMode = determinePaymentType(routeParams.payment_channel)
      $rootScope.routeParams.currency = routeParams.currency
      $rootScope.routeParams.pointsCashShare = routeParams.pointsCashShare
      $rootScope.routeParams.suppliers = routeParams.suppliers
      $rootScope.routeParams.selectedHotelId = routeParams.selectedHotelId

      @parseTrafficParams(routeParams)
      @checkRoomsAdultsCompatibility(routeParams)

      $rootScope.searchDatesValid = SearchUtilsService.searchDatesValid($rootScope.checkInDate, $rootScope.checkOutDate)
      $rootScope.duration = @numOfDays($rootScope.checkInDate, $rootScope.checkOutDate)
      return invalidHotelSearch(routeParams.destinationId)

    @getReferralCode = (loc) ->
      loc.search()["referral"] || loc.search()["refer"]

    # retain the order of the filter locales
    @filterArrayAttr = (main, shortlist, attr) ->
      shortlisted = []
      for shortlistItem in shortlist
        for item in main
          if shortlistItem == item[attr]
            shortlisted.push(item)
            break
      return shortlisted

    @numOfDays = (before, after) ->
      return 0 if !before || !after
      dateFormat = if /\-/.test(before) then "YYYY-MM-DD" else "MM/DD/YYYY"
      beforeTime = moment.utc(before, dateFormat)
      afterTime = moment.utc(after, dateFormat)
      return afterTime.diff(beforeTime, 'days')

    @getQueryParams = () ->
      if (window.location.hash.indexOf("#/") >= 0)
        return window.location.hash.split("?")[1]
      else
        return window.location.search.split("?")[1]

    return
]
