export class RoomDetailsModal {
  static $inject = [
    "$scope",
    "$modalInstance",
    "images",
    "roomTitle",
    "description",
    "defaultImage",
  ];

  constructor(
    private $scope: any,
    private $modalInstance: any,
    private images: string[],
    private roomTitle: string,
    private description: string,
    private defaultImage: string,
  ) {
    this.$scope.close = () => {
      this.$modalInstance.dismiss("close");
    };

    this.$scope.yes = () => {
      this.$modalInstance.close("yes");
    };

    $scope.images = images;
    $scope.title = roomTitle;
    $scope.description = description;
    $scope.defaultImage = defaultImage;
  }
}

angular.module("BookingApp").controller("RoomDetailsModal", RoomDetailsModal);
