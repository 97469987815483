angular.module("BookingApp")

.directive 'cancellationPolicy', [
  '$rootScope', 'CancellationPolicyService', 'CookieService', 'FormatService'
  ($rootScope, CancellationPolicyService, CookieService, FormatService) ->
    restrict: "E"
    scope:
      profile: '='
      amount: '='
      currency: '='
      percentage: '='
      static: '='
      responsive: '='
      purchase: '='
      start: '='
      end: '='
      checkin: '='
      displayPercentage: '='
    templateUrl: "/html/hotels/cancellation_policy_desc"
    link: (scope) ->
      moment.locale(CookieService.getLocale()) #in purchase success, it defaults to zh-tw for some reason w/o this code

      if scope.displayPercentage
        scope.clause =
          from: scope.start
          to: scope.end
          amount: (scope.percentage.toFixed(2)) + "%"

      else if scope.purchase
        if scope.profile
          tmp = scope.amount * scope.percentage / 100
          num = FormatService.number(tmp, $rootScope.selectedLocale, 2)
          amount = "#{scope.currency} #{num}"
        else
          amount = scope.amount

        scope.clause =
          from: scope.start
          to: scope.end
          amount: amount
      else
        scope.clause = scope.$parent.clause #scope belongs to parent element, the div with detail, use that since custom scope is now assigned to current element
        scope.leftToPay = scope.$parent.leftToPay
        scope.selectedCurrency = scope.$parent.selectedCurrency
      CancellationPolicyService.transformClause(scope.clause)
]
