require('../services/utils')
require('../services/manage-booking')

CancelBookingCtrl = ($scope, ManageBooking, UtilsService) ->

  getAccessToken = () ->
    value = ''
    queryParams = UtilsService.getQueryParams()
    if (queryParams)
      params = queryParams.split("&")
      params.forEach (param) ->
        item = param.split("=")
        value = item[1] if(item[0] == 'access_token')
    return value

  token = getAccessToken()
  $scope.cancelStatus = ManageBooking.defaultStatus

  $scope.isActive = () ->
    $scope.cancelStatus != 'cancelled' && $scope.cancelStatus != 'error'

  $scope.cancelBooking = (booking) ->
    if token? && token
      ManageBooking.showConfirmCancelBookingDialog(booking, token)
      .then((status) ->
        return if !status || status == 'abort'
        $scope.cancelStatus = status
      , (status) ->
        $scope.cancelStatus = status
      , (status) ->
        $scope.cancelStatus = status
      )
    else
      $scope.cancelStatus = 'error'

  return # End of ctrl

CancelBookingCtrl
  .$inject = [
    '$scope', 'ManageBooking', 'UtilsService'
  ]

angular.module('BookingApp')
  .controller 'CancelBookingCtrl', CancelBookingCtrl
