angular.module("BookingApp")

.factory "BookingsFactory", [

  "$resource",
  ($resource) ->
    $resource("/api/bookings/:id", { id: '@id' }, {
      "cancel": { method: 'POST', url: "/api/bookings/:id/cancel" }
      "cancelRequest": { method: 'POST', url: "/api/bookings/:id/cancel_request" }
      "getCancelled": { method: "GET", url: "api/bookings/cancelled", isArray: true }
      "getUpcoming": { method: "GET", url: "api/bookings/upcoming", isArray: true }
      "getCompleted": { method: "GET", url: "api/bookings/completed", isArray: true }
      "sendConfirmationEmail": { method: "POST", url: "api/bookings/:id/send_confirmation_email" }
    })
]
