// extra controller is required to inject the modalInstance for modal popup
// injecting modalInstance to non popup may result in error

declare var angular : any;

export class SignUpPopupCtrl {

  static $inject = ["$scope", "$controller", "$modalInstance"]

  private $controller : any;
  private $modalInstance : any;

  constructor($scope, $controller, $modalInstance) {
    this.$controller = $controller
    this.$modalInstance = $modalInstance
    angular.extend(this, this.$controller('SignUpCtrl', {$scope: $scope}))
  }

  public cancel() {
    this.$modalInstance.dismiss('cancel')
  }

}

angular.module('BookingApp').controller('SignUpPopupCtrl', SignUpPopupCtrl)
