// TrackerService is a service used to track conversion events
// It is an entry point for each custome tracker we want to use.
// It uses a generic tracker as well as any tracker that any
// white label requires
// Available methods are:
// hotelsSearch(searchForm) - hotel search
// cityTileImpression(cityTile, index) - display city tile
// cityTileClick(cityTile) - click on city tile
// hotelsImpression(hotels, params) - hotels displayed on list
// hotelClick(hotel, params) - click on single hotel
// hotelDetailsImpression(hotel, params) - displayed room selection
// hotelDetailsClick(hotel, params) - click on single room
// hotelCheckoutInitiation(room, hotel, params) - checkout form displayed
// hotelBookingConfirmation(room, hotel, params) - booking confirmed
// filteringEvent(data) - filtering applied
// sortingEvent(data) - sorting changed

declare var angular : any

import './common-tracker'

export class TrackerService {

  static $inject = ["CommonTrackerService"]

  private trackers : any

  constructor(CommonTrackerService) {
    this.trackers = [CommonTrackerService]
  }

  public registerTracker(tracker) { return this.trackers.push(tracker) }

  public updateCommonData(data) { this.fireEvent("updateCommonData", data) }
  public hotelsSearch(searchForm) { this.fireEvent("hotelsSearch", searchForm) }
  public cityTileImpression(cityTile, index) { this.fireEvent("cityTileImpression", cityTile, index) }
  public cityTileClick(cityTile, index) { this.fireEvent("cityTileClick", cityTile, index) }
  public hotelsImpression(hotels, params) { this.fireEvent("hotelsImpression", hotels, params) }
  public hotelClick(hotel, params) { this.fireEvent("hotelClick", hotel, params) }
  public hotelDetailsImpression(room, hotel, params) { this.fireEvent("hotelDetailsImpression", room, hotel, params) }
  public hotelDetailsClick(hotel, params) { this.fireEvent("hotelDetailsClick", hotel, params) }
  public hotelCheckoutInitiation(room, hotel, params) { this.fireEvent("hotelCheckoutInitiation", room, hotel, params) }
  public hotelBookingConfirmation(room, hotel, params) { this.fireEvent("hotelBookingConfirmation", room, hotel, params) }
  public filteringEvent(data) { this.fireEvent("filteringEvent", data) }
  public sortingEvent(data) { this.fireEvent("sortingEvent", data) }

  private fireEvent(name, ...args) {
    this.trackers.forEach((tracker) => {
      if (typeof tracker[name] === 'function') { tracker[name](...args) }
    });
  }
}

angular.module("BookingApp").service("TrackerService", TrackerService)
