angular.module("BookingApp")

.service "WhitelabelPartner", [

  "KaligoConfig", "$rootScope"
  (KaligoConfig, $rootScope) ->

    _data = {
      appType: KaligoConfig['APPLICATION_TYPE'] || "kaligo"
      partner: KaligoConfig['WHITELABEL_PARTNER'] || ""
      dlp: KaligoConfig['DLP'] || ""
      displayCompareRate: KaligoConfig['DISPLAY_COMPARE_RATE']
    }

    @isWhiteLabel = _data.appType == "whitelabel"
    @partner = _data.partner
    @hideCompareRatesUI = _data.partner.length > 1 && !_data.displayCompareRate

    return this
]
