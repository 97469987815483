require('../services/points-partner-mapper.service')

angular.module('BookingApp')
.factory 'PointsPartner', [

  "PointsPartnerMapperService"
  (PointsPartnerMapperService) ->

    (partner) ->
      id: parseInt(partner.id)
      visible: partner.visible
      partner: partner.name
      programName: partner.program_name
      label: PointsPartnerMapperService.pointsPartnerList[partner.currency_label] || partner.currency_label
      shortCurrency: PointsPartnerMapperService.pointsPartnerList[partner.currency_short] || partner.currency_short
      longCurrency: partner.currency_long
      iconUrl: partner.logo_url
      category: partner.category
      requireMemberNoOnly: partner.require_member_no_only
      pointsPaymentTiers: partner.hotels_points_payment_tiers
      cashPaymentTiers: partner.hotels_cash_payment_tiers
      hotelsPointsPaymentTiers: partner.hotels_points_payment_tiers
      hotelsCashPaymentTiers: partner.hotels_cash_payment_tiers
      carsPointsPaymentTiers: partner.cars_points_payment_tiers
      carsCashPaymentTiers: partner.cars_cash_payment_tiers
      flightsPointsPaymentTiers: partner.flights_points_payment_tiers
      flightsCashPaymentTiers: partner.flights_cash_payment_tiers
      minPoints: partner.min_points
      minPointsCashValueInUsd: partner.min_points_cash_value_in_usd
      settings:
        colors:
          backgroundColor: if (partner.settings) then partner.settings.background_color else ""
          buttonColor: if (partner.settings) then partner.settings.button_color else ""
          fontColor: if (partner.settings) then partner.settings.font_color else ""
        crediting:
          message: if (partner.settings) then partner.settings.crediting_message else ""
          min_time: if (partner.settings) then partner.settings.crediting_min_time else ""
          max_time: if (partner.settings) then partner.settings.crediting_max_time else ""
          timeframe: if (partner.settings) then partner.settings.crediting_timeframe else ""

      showMiniSelector: -> !(partner.name in ['Singapore Airlines KrisFlyer Amex', 'Avianca Cobrand', 'Royal Jordanian Cobrand', 'Saudia Cobrand', "Garuda Indonesia Cobrand"])
      pointsPaymentTiersBy: (travelType) ->
        switch travelType
          when "hotels" then partner.hotels_points_payment_tiers
          when "cars" then partner.cars_points_payment_tiers
          when "flights" then partner.flights_points_payment_tiers
      cashPaymentTiersBy: (travelType) ->
        switch travelType
          when "hotels" then partner.hotels_cash_payment_tiers
          when "cars" then partner.cars_cash_payment_tiers
          when "flights" then partner.flights_cash_payment_tiers
]
