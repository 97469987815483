require('../services/api-data.service')
require('../services/cancellation-policy.service')

BookingPolicyRunner = ($q, $timeout, ApiDataService, CancellationPolicyService) ->

  (hotelId, checkInDate) ->

    MAX_RETRY = 10
    INTERVAL = 3000

    @retry = 0
    @bookingKey = ""
    @hotelId = hotelId
    @checkin = moment.utc(checkInDate, "MM/DD/YYYY").format("YYYY-MM-DD")
    @defer = $q.defer()
    @isLoading = false

    DEFER_PRISTINE = 0
    DEFER_RESOLVED = 1
    DEFER_REJECTED = 2
    INVALID_POLICY = { invalid: true }

    @cancel = () =>
      @defer.resolve() if !@isDeferResolved
      @isLoading = false
      @retry = 0

    @isDeferResolved = () =>
      return @defer.promise.$$state.status != DEFER_PRISTINE

    @fetch = (bookingKey) =>
      return if @isLoading
      @bookingKey = bookingKey
      @retry = 0
      @isLoading = true
      return @run()

    @run = () =>
      @defer = $q.defer() if @isDeferResolved()
      url = "hotels/#{@hotelId}/cancellation_policy/#{@bookingKey}?checkin=#{@checkin}"
      ApiDataService.get(url).then (response) =>
        if response.completed
          @isLoading = false
          if response.policy
            @defer.resolve(CancellationPolicyService.transformPolicy(response.policy))
          else
            @defer.reject(INVALID_POLICY)
        else
          @retryFetch()
      , () =>
        @isLoading = false
        @defer.reject(INVALID_POLICY)
      return @defer.promise

    @retryFetch = () =>
      if @retry > MAX_RETRY
        @isLoading = false
        @defer.reject()
      else
        @retry++
        $timeout () =>
          @run() if @isLoading
        , INTERVAL

    return

BookingPolicyRunner.$inject = ['$q', '$timeout', 'ApiDataService', 'CancellationPolicyService']

angular.module("BookingApp")
.factory 'BookingPolicyRunner', BookingPolicyRunner
