require('./api-data.service')
require('../values/app-settings')

ManageBooking = (
  $rootScope, $q, $timeout, $modal, $translate, $window,
  ApiDataService, AppSettings, ModalManager, SimpleModalService
) ->

  _isProcessing = false
  @defaultStatus = 'cancel'
  self = this

  @showConfirmCancelBookingDialog = (booking, token = '') ->
    deferred = $q.defer()
    SimpleModalService.open("cancel-booking-modal").then(
      () ->
        SimpleModalService.open("cancelling-booking-modal")
        self.processCancelBooking(booking.id, token, deferred)
      , () ->
        deferred.resolve("abort")
    )

    return deferred.promise

  @showConfirmCancelBookingRequestDialog = (booking) ->
    deferred = $q.defer()
    SimpleModalService.open("cancel-booking-request-modal").then(
      () ->
        processCancelBookingRequest(booking.id, deferred)
      , () ->
        deferred.resolve("abort")
    )

    return deferred.promise

  @processCancelBooking = (id, token, deferred, modalInstance) ->
    return if _isProcessing

    _isProcessing = true
    deferred.notify("cancelling")

    url = "bookings/#{id}/cancel"
    url += "?access_token=#{token}" if token

    ApiDataService.post(url)
    .then((response) ->
      modalInstance.close() if modalInstance
      return if response.status != "success"
      deferred.resolve("cancelled")
      openSuccessCancellationModal()
    , (response) ->
      modalInstance.close() if modalInstance
      deferred.resolve('error')
      openFailedCancellationModal()
    ).finally(() ->
      _isProcessing = false
    )
    return

  processCancelBookingRequest = (id, deferred) ->
    return if _isProcessing

    _isProcessing = true
    deferred.notify("requesting")

    url = "bookings/#{id}/cancel_request"

    ApiDataService.post(url)
    .then((response) ->
      return if response.status != "success"
      deferred.resolve("requested")
      $rootScope.$broadcast('cancelBookingRequested', true)
    , (response) ->
      deferred.reject('error')
      $rootScope.$broadcast('cancelBookingRequested', false)
    ).finally(() ->
      _isProcessing = false
    )
    return

  openSuccessCancellationModal = () ->
    SimpleModalService.open("cancel-booking-success-modal").then(() ->
      $window.location.reload()
    )

  openFailedCancellationModal = () ->
    SimpleModalService.open("cancel-booking-failed-modal").then(() ->
      $window.location.reload()
    )

  return # END

ManageBooking
  .$inject = [
    '$rootScope', '$q', '$timeout', '$modal', '$translate', "$window"
    'ApiDataService', 'AppSettings', 'ModalManager', "SimpleModalService"
  ]

angular.module('BookingApp')
  .service 'ManageBooking', ManageBooking
