import { Bindings } from "booking_app/types";

export class RoomDetailsModalContentComponent {
  bindings: any = {
    images: Bindings.ONE_WAY,
    longRoomDescription: Bindings.ONE_WAY,
    shortRoomDescription: Bindings.ONE_WAY,
    defaultImage: Bindings.ONE_WAY,
  };
  template: string = `
    <div class="image-container">
      <gallery images="$ctrl.images"
        autoplay="false"
        default-background-image="$ctrl.defaultImage"
        arrow-aria-label="'room'">
      </gallery>
    </div>
    <div class="room-description-container" ng-if="$ctrl.longRoomDescription">
      <div class="detail-content">
        <div class="room-title" ng-bind-html="$ctrl.shortRoomDescription">
        </div>
        <div class="room-description" ng-bind-html="$ctrl.longRoomDescription">
        </div>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("roomDetailsModalContent", new RoomDetailsModalContentComponent());
