// This is a polyfill for the CustomEvent constructor
// function, which is not present in IE 11.
//
// Reference: https://caniuse.com/#search=customevent
//
if ( typeof window.CustomEvent !== "function" ) {
  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
}
