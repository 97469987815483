require('../services/hotel-util.service')
require('../services/mapbox')
require('../services/hotel-image.service')
require('../services/bonuses')
require('../services/search-url')
require('../services/trustyou.service')
require('../factories/booking-policy-runner')
require('../values/app-settings')
require('../factories/booking-policy-runner')
require('../factories/fetch-cancellation-policy-task')
require('../services/pay-with-points-cash.service')
require('../services/api-data.service')
require('../services/tracker')
require('../services/currencies.service')
require('../services/pay-with-points-cash-complimentary-nights')
require('../services/points-cash-share.service')
require('../services/points-cash-payment-handling.service')
require('../utils/user-agent-checker')
require('../services/hotels/hotel-details.service')

angular.module('BookingApp')
.controller 'HotelsDetailCtrl', [

  '$scope', '$rootScope', '$window', '$location', '$timeout',
  'HotelsDetailPrefetch', 'HotelUtilService', 'HotelImageService',
  'BonusesService', 'SearchUrlService',
  'TrustyouService', 'AppSettings',
  'FetchCancellationPolicyTask', 'PayWithPointsCashService', 'ApiDataService', 'TrackerService',
  'MapboxService', 'CurrenciesService', 'UserAgentChecker',
  "GlobalStateService", 'PayWithPointsCashCompNights', "PointsCashShareService",
  "HotelDetailsService", "PointsCashPaymentHandlingService"

  ($scope, $rootScope, $window, $location, $timeout,
  HotelsDetailPrefetch, HotelUtilService, HotelImageService,
  BonusesService, SearchUrlService,
  TrustyouService, AppSettings,
  FetchCancellationPolicyTask, PayWithPointsCashService, ApiDataService, TrackerService,
  MapboxService, CurrenciesService, UserAgentChecker,
  GlobalStateService, PayWithPointsCashCompNights, PointsCashShareService,
  HotelDetailsService, PointsCashPaymentHandlingService) ->

    $scope.hotelDetail = HotelsDetailPrefetch
    $scope.globalStateService = GlobalStateService
    $scope.pointsCashShareService = PointsCashShareService
    $scope.bookingOptions = {
      displayAll: false
    }
    $scope.showTotalNights = $rootScope.globalState.showTotalNights

    $scope.customLoadingSearch = AppSettings.customLoadingSearch
    $scope.showPerNightText = AppSettings.showPerNightText
    $scope.showVatInfo = AppSettings.showVatInfo
    $scope.policyNoteOnDetailsPage = AppSettings.policyNoteOnDetailsPage
    $scope.customBookingOptionTabs = AppSettings.customBookingOptionTabs
    $scope.showMiniMap = AppSettings.showMiniMap
    $scope.showBgPageColor = AppSettings.hotelDetailsTemplateConfig.showBgPageColor
    $scope.settingBasedColor = AppSettings.settingBasedColor
    $scope.earnRewardLabel = AppSettings.hotelDetailsTemplateConfig.earnRewardLabel
    $scope.doubleMobileRoomPricesButton = AppSettings.hotelDetailsTemplateConfig.doubleMobileRoomPricesButton
    $scope.showPointCashSliderRoomList = AppSettings.hotelDetailsTemplateConfig.showPointCashSliderRoomList
    $scope.showExtraHeadingsRoomList = AppSettings.hotelDetailsTemplateConfig.showExtraHeadingsRoomList
    # setup the hotel data for the HotelImageService service
    HotelImageService.initHotelImageData([HotelsDetailPrefetch])
    $scope.galleryImage = HotelImageService.getHotelMediumSizeImagesSet($scope.hotelDetail.id)
    $rootScope.globalState.isCancellationPolicyError = false

    $scope.defaultImg = HotelImageService.getDefaultThumbImageStyle($scope.hotelDetail.id)
    $scope.defaultBackgroundImage = HotelImageService.getDefaultHotelBgImage()
    $scope.defaultBackgroundImageStyle = HotelImageService.getDefaultHotelBgImageStyle()

    $scope.isShowMore = true
    $window.scrollTo(0, 0)

    $scope.bonuses = {}
    $scope.currentBonuses = []


    BonusesService.fetch($rootScope.landingPage.url)
    .then (result) ->
      $scope.bonuses = result
      $scope.currentBonuses = $scope.verifyHasBonuses()

    $scope.verifyHasBonuses = ->
      if $rootScope.pointsPartner && $scope.bonuses.active
        bonusList = $scope.bonuses.active()
        selectedPartner = $rootScope.pointsPartner.id
        (bonus for bonus in bonusList when bonus.applicableForPartner(selectedPartner))
      else
        []

    booking = null

    hotelPricePoll = null
    _pollErrorCount = 0
    pollHotelSinglePriceResults = () ->
      $scope.pollHotelPricesIsLoading = true
      hotelPricePoll = HotelUtilService.getSinglePrice($rootScope.hotelId)
        .then(pollHotelSinglePriceSuccess, pollHotelSinglePriceError)
      return

    firstRoom = null
    $scope.availRooms = []
    fetchPolicyTask = new FetchCancellationPolicyTask($rootScope.hotelId, $rootScope.checkInDate)
    $scope.policies = fetchPolicyTask.policy

    pollHotelSinglePriceSuccess = (result) ->
      if result && !result.errors
        _pollErrorCount = 0
        $scope.hotelPrice = result
        $scope.hotelDetail = angular.extend($scope.hotelDetail, result.hotelPrice)
        # Remove rooms with duplicated booking key
        $scope.hotelPrice.rooms = removeDuplicates($scope.hotelPrice.rooms)
        getRoomDescription($scope.hotelPrice.rooms)
        $scope.searchCompleted = result.completed

      if $scope.hotelPrice?.rooms
        $scope.availRooms = updateAvailableRooms()
        fetchPolicyTask.addRooms($scope.availRooms)
        formatHotelRoomData()

      if $scope.searchCompleted
        $scope.pollHotelPricesIsLoading = false
        hotelPricePoll = null
        if $scope.hotelPrice
          firstRoom = $scope.hotelPrice.rooms[0]
          return if !firstRoom
          $scope.hotelDetail.room = firstRoom
          if firstRoom && firstRoom.voucher_type_id
            voucherTypes = $rootScope.landingPage.voucherTypes
            $scope.roomBookingVoucherType = voucherTypes[firstRoom.voucher_type_id]

          TrackerService.hotelDetailsImpression(
            $scope.hotelPrice.rooms[0]
            $scope.hotelDetail,
            {
              guests: $rootScope.guests
              currencyCode: $rootScope.routeParams.currency
              checkInDate: $rootScope.checkInDate
              checkOutDate: $rootScope.checkOutDate
            }
          )

      else
        $scope.pollHotelPricesIsLoading = true
        hotelPricePoll = $timeout(pollHotelSinglePriceResults, 5000)

      return

    pollHotelSinglePriceError = (reason) ->
      console.log("pollHotelSinglePriceError", _pollErrorCount)
      console.log(reason)
      _pollErrorCount++
      if _pollErrorCount < 3
        hotelPricePoll = $timeout(pollHotelSinglePriceResults, 5000)
      else
        $timeout.cancel(hotelPricePoll) if hotelPricePoll
      return

    updateAvailableRooms = () ->
      HotelDetailsService.initRooms($scope.hotelPrice.rooms)
      return HotelDetailsService.rooms

    $scope.showAllRooms = ->
      $scope.bookingOptions.displayAll = true
      $scope.availRooms = updateAvailableRooms()
      fetchPolicyTask.addRooms($scope.availRooms)
      return

    $scope.hasRooms = -> $scope.availRooms.length > 0
    $scope.moreRoomsToShow = -> $scope.availRooms.length >= 5

    getRoomDescription = (rooms) ->
      angular.forEach rooms, (room, i) ->
        room.roomDescriptionShort = room.roomNormalizedDescription
        room.roomDescriptionRaw = room.roomDescription
        room.roomDescriptionDetail = room.roomAdditionalInfo.breakfastInfo
        room.roomRawDetails = false

        if room.discount
          pricePerNight = (room.price / nightOfStay)
          room.originalPricePerRoomPerNight = Math.ceil(pricePerNight/(1 - room.discount/100))

    removeDuplicates = (items) ->
      uniqueKey = {}
      uniqueList = []
      if items
        items.forEach((item) ->
          uniqueList.push(item) if !uniqueKey[item.key]
          uniqueKey[item.key] = true
        )
      return uniqueList

    $scope.toggleHotelRawDetails = (rawDetailsShown) ->
      rawDetailsShown = !rawDetailsShown

    $scope.shouldDisplayAmenities = ->
      goodToKnowList = $scope.hotelDetail.trustyou.reviews?.good_to_know_list
      (
        goodToKnowList &&
        goodToKnowList.some (comment) ->
          comment.sentiment == 'pos'
      ) || Object.keys($scope.hotelDetail.amenities).length > 0

    $scope.$on "$locationChangeStart", ->
      fetchPolicyTask.cancel()
      $timeout.cancel(hotelPricePoll) if hotelPricePoll
      return

    $rootScope.$watch 'selectedLocale', (newvalue, oldvalue) ->
      return if newvalue == oldvalue
      reloadHotelDetailContent()
      $timeout.cancel(hotelPricePoll) if hotelPricePoll
      $scope.searchCompleted = false
      $scope.hotelPrice.rooms = [] if $scope.hotelPrice
      pollHotelSinglePriceResults()
      $scope.hotelDetail.trustyou.reviews = undefined
      getTYReviews($rootScope.hotelId)
      return

    reloadHotelDetailContent = () ->
      $scope.hotelDetail.description = null
      hotelDetailPromise = HotelUtilService.getSingleDetail($rootScope.hotelId)
      hotelDetailPromise.then (hotelDetails) ->
        $scope.hotelDetail.description = hotelDetails.description
        $scope.hotelDetail.name = hotelDetails.name

    requireLogin = () ->
      return $rootScope.globalState.requireLoginOnCheckout && !$rootScope.userDetails.loggedIn

    goToCheckout = (bookingRoom) ->
      checkoutURL = SearchUrlService.createCheckoutUrl(bookingRoom.key)
      if AppSettings.openInTargetTab.checkoutInNewTab
        $window.open(checkoutURL)
      else
        $location.url(checkoutURL)

    $scope.redirectToHotelCheckoutPage = (bookingRoom) ->
      return if bookingRoom.violates_price_policy
      return $scope.globalState.openLogin() if requireLogin()
      if AppSettings.checkUserStillLoggedin
        ApiDataService.get('user')
        .then (user) ->
          goToCheckout(bookingRoom) if user
          $scope.globalState.openLogin() if !user
        , (errorResponse) ->
          $scope.globalState.openLogin()
      else
        goToCheckout(bookingRoom)

    $scope.backToSearchResultsPage = () ->
      $timeout.cancel($scope.hotelPricePoll)
      HotelUtilService.cancelGetSinglePrice('redirecting')
      $location.url(SearchUrlService.createBackToSearchUrl $scope )

    $scope.reviewsCallCheck = (hotelId) ->
      MapboxService.setViewMode('list')
      if !$scope.hotelDetail.trustyou.reviews
        getTYReviews(hotelId)

    getTYReviews = (hotelId) ->
      TrustyouService.getHotelReview(hotelId).then (result) ->
        if result["error"]
          $scope.hotelDetail.trustyou["reviewsError"] = true
        else
          $scope.hotelDetail.trustyou["reviews"] = result

    $scope.viewMap = () ->
      $rootScope.mapboxState.selectedHotel = $scope.hotelDetail
      MapboxService.setViewMode('map')
      $rootScope.globalState.displaySearchBottomSheet = true

    nightOfStay = $scope.nightOfStay = $rootScope.duration
    roomCount = $rootScope.roomCount

    formatHotelRoomData = () ->
      angular.forEach $scope.hotelPrice.rooms, (pkg) ->
        pkg.price = if $rootScope.globalState.taxInclusive
                      pkg.chargeableRate
                    else
                      pkg.chargeableRateWithoutTax
        pkg.score = pkg.points
        pkg.company_points = pkg.company_points || 0
        pkg.bonus = pkg.bonuses

        pricePerNight = (pkg.price / nightOfStay)
        pricePerNight = CurrenciesService.convertFromUsd(pricePerNight)
        pricePerRoomPerNight = pricePerNight / roomCount
        if $rootScope.selectedCurrency.decimalPlace == 0
          pricePerNight = Math.ceil(pricePerNight)
          pricePerRoomPerNight = Math.ceil(pricePerRoomPerNight)
        pkg.pricePerNight = pricePerNight
        pkg.pricePerRoomPerNight = pricePerRoomPerNight

        if pkg.rate # if has admin data
          pkg.adminPricePerNight = pkg.chargeableRate / nightOfStay / roomCount

        if $rootScope.landingPage.hasProductType("redeem")
          pkg = PayWithPointsCashService.calculatePointsCashToPay(pkg)
        if $rootScope.landingPage.hasProductType("complimentary-nights")
          pkg = PayWithPointsCashCompNights.calculatePointsCashToPay(pkg)

      if $scope.hotelPrice && $scope.hotelPrice.rooms && $scope.hotelPrice.rooms.length > 0
        $scope.hotelFirstRoom = $scope.hotelPrice.rooms[0]

    $rootScope.$watch 'selectedCurrency', ((newValue, oldValue) ->
      return if newValue == oldValue
      formatHotelRoomData()
    ), true

    $scope.$on 'pointsCashSliderUpdate', ((event, data) ->
      formatHotelRoomData()
    ), true

    $scope.toggleAdminData = (e) ->
      $scope.adminDataOn = !$scope.adminDataOn
      e.stopPropagation()

    # Start polling for single hotel room prices
    pollHotelSinglePriceResults()

    $rootScope.mapboxState.view = "detail-page"
    GlobalStateService.currentPage = "hotel-detail"

    $scope.reviewsCallCheck($scope.hotelDetail.id)

    # Delay getStickyHeaderHeight() so sticky header classes are in place before calculating the height
    $timeout( ->
      $scope.getStickyHeaderHeight =  AppSettings.getStickyHeaderHeight()
    )

    $scope.scrollToElementId = (elementId) ->
      el = document.getElementById(elementId)
      stickyHeaderHeight = AppSettings.getStickyHeaderHeight()
      if stickyHeaderHeight > 0
        coordinates = el.getBoundingClientRect()
        scrollCoordinate =  coordinates.top - stickyHeaderHeight + $window.pageYOffset + 5
        if UserAgentChecker.isIE()
          $window.scrollTo(0, scrollCoordinate)
        else
          $window.scroll(top: scrollCoordinate, behavior: "smooth")
      else
        el.scrollIntoView
          behavior: 'smooth'
          block: 'start'

    $scope.triggerScrollEvent = () ->
      scrollEvent = window.document.createEvent('UIEvents')
      scrollEvent.initEvent('scroll', true, true)
      window.dispatchEvent(scrollEvent)

    stickyElement = document.getElementById('sticky-button')
    if stickyElement && !$rootScope.landingPage.hasProductType("voucher")
      stickyElementHeight = stickyElement.scrollHeight
      visibleElement = document.getElementById('holder-button')
      $scope.stickyScroll = angular.element($window).bind 'scroll', () ->
        if (visibleElement.getBoundingClientRect().top + stickyElementHeight) - this.innerHeight <= 0
          stickyElement.className = "hidden"
        else
          stickyElement.className = "fixed"
        return

    $scope.noRoomsMessage = false

    $scope.roomPaymentLoaded = () ->

      if $scope.searchCompleted && ($scope.hotelPrice?.rooms?[0]?.pricePerNight? ||
      $scope.hotelPrice?.rooms?[0]?.points_payment_per_night?)
        return true
      else
        if $scope.searchCompleted
          # we dont need to have searchCompleted to  be true flag
          # before this part as we already have the first room data
          # which is only needed to be shown on top
          showNoRoomsMessage()
        return false

    $scope.firstRoomSetLoaded = () ->
      $scope.hotelPrice?.rooms?[0]?.pricePerNight? || $scope.hotelPrice?.rooms?[0]?.points_payment_per_night?

    $scope.zeroFullCashPaymentFirstRoom = () ->
      return PointsCashPaymentHandlingService.zeroFullCashPayment(
        $scope.hotelPrice?.rooms[0]?.cash_payment,
        $scope.hotelPrice?.rooms[0]?.points_payment
      )

    $scope.zeroFullCashPaymentPerNight = () ->
      return PointsCashPaymentHandlingService.zeroFullCashPayment(
        $scope.hotelPrice?.rooms[0]?.cash_payment_per_night,
        $scope.hotelPrice?.rooms[0]?.points_payment_per_night
      )

    showNoRoomsMessage = () ->
      $scope.noRoomsMessage = true

    @$afterInit = ->
      $scope.triggerScrollEvent()
      return

    triggerResizeEvent = () ->
      resizeEvent = window.document.createEvent('UIEvents')
      resizeEvent.initUIEvent 'resize', true, false, window, 0
      window.dispatchEvent resizeEvent
]
