import { GlobalStateService } from "booking_app/services/global-state.service";
import { CurrentPage } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";

export class FooterController {

  static $inject = [
    "$rootScope",
    "$scope",
    "GlobalStateService",
    "AppSettings",
  ];

  private isFooterHidden: boolean = true;
  constructor(
    private $rootScope: any,
    private $scope: any,
    private globalStateService: GlobalStateService,
    private appSettings: AppSettings,
  ) {
    this.checkPageIfFooterIsHidden();
  }

  public tenant(): string {
    return this.appSettings.tenant.toLowerCase();
  }

  private checkPageIfFooterIsHidden() {
    const hideFooterPages = [
      CurrentPage.SEARCH_RESULT,
      CurrentPage.HOTEL_DETAIL,
      CurrentPage.CAR_DETAIL,
      CurrentPage.CHECKOUT,
      CurrentPage.CONFIRMATION,
    ];
    this.$scope.$watch(() => this.globalStateService.currentPage,
      (currentPage: CurrentPage) => {
        if (hideFooterPages.includes(currentPage)) {
          this.isFooterHidden = true;
        } else {
          this.isFooterHidden = false;
        }
      });
  }
}
