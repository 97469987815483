import { Bindings } from "booking_app/types";
import { LandingPageInfoItemController } from "./landing-page-info-item.controller";

export class LandingPageInfoItemComponent {
  bindings: any = {
    header: Bindings.ONE_WAY,
    description: Bindings.ONE_WAY,
    icon: Bindings.ONE_WAY,
    folderName: Bindings.ONE_WAY,
    iconFormat: Bindings.ONE_WAY,
  };

  controller: any = LandingPageInfoItemController;
  template: string = `
    <div class="img" ng-style="{'background-image': '{{ $ctrl.infoImageUrl() }}'}">
    </div>
    <div>
      <span class="header" translate="{{ $ctrl.header }}"></span>
      <span class="description" translate="{{ $ctrl.description }}"></span>
    </div>
  `;
}

angular.module("BookingApp").component("landingPageInfoItem", new LandingPageInfoItemComponent());
