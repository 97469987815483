import { Bindings } from "booking_app/types";
import { TravelVoucherController } from "./travel-voucher.controller";

export class TravelVoucherComponent {
  bindings: any = {
  };
  controller: any = TravelVoucherController;
  template: string = `
    <div class="travel-voucher-container" ng-if="$ctrl.isTravelVoucherAvailable()">
      <img cdn-path="/fab/icons/icn-cashdisc.svg">
      <span class="travel-voucher-text">
        {{ $ctrl.travelVoucherStatement() }}
      </span>
    </div>
  `;
}

angular.module("BookingApp").component("travelVoucher", new TravelVoucherComponent());
