import { CancellationPolicy } from "../models/cancellation-policy";
import { CancellationPolicyClause } from "../models/cancellation-policy-clause";

declare var moment: any;

export class CancellationPolicyService {
  public transformPolicy(policy: CancellationPolicy): CancellationPolicy {
    if (policy.refundable === "full") {
      policy.free_cancellation_before = moment.utc(policy.free_cancellation_before).format("LL");
    }

    if (policy.penalties) {
      policy.penalties.forEach((clause: CancellationPolicyClause) => {
        this.transformClause(clause);
      });
    }

    if (policy.non_refundable_from) {
      policy.non_refundable_from = moment.utc(policy.non_refundable_from);
    }

    return policy;
  }

  public transformClause(clause: CancellationPolicyClause): void {
    clause.from = moment.utc(clause.from);
    clause.to = moment.utc(clause.to);
    clause.text = "booking_detail.cancellation_policy.clause.in_between";
  }
}

angular.module("BookingApp").service("CancellationPolicyService", CancellationPolicyService);
