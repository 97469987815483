import { UrlUtils } from "booking_app/utils";

export class LandingPageInfoItemController {
  static $inject = [
    "UrlUtils",
  ];

  // Bindings
  header: string;
  description: string;
  icon: string;
  folderName: string;
  iconFormat: string;

  constructor(
    private urlUtils: UrlUtils,
  ) {}

  public infoImageUrl(): string {
    return `url(${ this.iconUrl() }.${ this.iconFormat || "png" })`;
  }

  private iconUrl(): string {
    return this.urlUtils.imageUrl(`/${this.folderName}/icons/${this.icon}`);
  }
}
