import { SignUpWidgetController } from "./sign-up-widget.controller";
import { Bindings } from "booking_app/types";

export class SignUpWidgetComponent {
  controller: any = SignUpWidgetController;
  templateUrl: string = "/html/components/sign_up_widget";
  bindings: any = {
    registerAction: Bindings.EXPRESSION,
    userDetails: Bindings.ONE_WAY,
    successMessageForNotLoggedUser: Bindings.ONE_WAY,
    headerTitle: Bindings.ONE_WAY,
    hasLoggedUser: Bindings.ONE_WAY,
  };
}

angular.module("BookingApp").component("signUpWidget", new SignUpWidgetComponent());
