import { HeaderUrlList } from "booking_app/services/header-url-list";
import { HeaderUrlLink } from "booking_app/constants/header-url-link";
import { PageDiscoveryService } from "booking_app/services/page-discovery.service";
import { AppSettings } from "booking_app/values/app-settings";

export class SiteNavigationMobileController {
  static $inject = [
    "$rootScope",
    "$window",
    "HeaderUrlList",
    "PageDiscoveryService",
    "AppSettings",
  ];

  headerList: HeaderUrlLink[];
  tenant: string;
  private currentExpandMenu: HeaderUrlLink;
  private mobileMenuListener: any;

  constructor(
    private $rootScope: any,
    private $window: any,
    private headerUrlList: HeaderUrlList,
    private pageDiscoveryService: PageDiscoveryService,
    private appSettings: AppSettings,
  ) {
    this.headerUrlList.setupUrlLink();
    this.tenant = this.appSettings.tenant.toLowerCase();
  }

  $onInit(): void {
    this.headerList = this.headerUrlList.headerList;
    this.setupOnOpenListener();
  }

  isExpand(menuItem: HeaderUrlLink): boolean {
    return this.currentExpandMenu && menuItem.title === this.currentExpandMenu.title;
  }

  toggleMenu(menuItem: HeaderUrlLink): void {
    this.currentExpandMenu = this.currentExpandMenu === menuItem ? null : menuItem ;
  }

  toggleMobileNavigation(): void {
    if (this.$rootScope.isLoading) {
      return;
    }
    this.$rootScope.globalState.showNavMenu = !this.$rootScope.globalState.showNavMenu;
  }

  mobileClass(className: string): string {
    return className.replace(/\s|\./g, "-").toLowerCase();
  }

  login(): void {
    if (this.appSettings.loginEndpointOpenLoginModal) {
      this.$rootScope.globalState.openLogin();
    } else {
      this.$window.location.href = `${this.$window.location.origin}/${this.$rootScope.globalState.loginEndpoint}`;
    }
  }

  logout(): void {
    this.$window.location.href = `${this.$window.location.origin}/${this.appSettings.logoutUrl}`;
  }

  isUserAccountBlocked(): boolean {
    return this.$rootScope.userDetails.user.account_status === 'blocked';
  }

  private setupOnOpenListener(): void {
    this.mobileMenuListener = this.$rootScope.$watch("globalState.showNavMenu", () => {
      const activeItem: HeaderUrlLink = this.headerUrlList.getActiveHeader();
      if (activeItem) {
        this.currentExpandMenu = activeItem;
      }
    });
  }
}
