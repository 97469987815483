import { PaymentMethod } from "booking_app/types/payment-method";
import { AppSettings } from "booking_app/values/app-settings";
import { SavedCard, CreditCardDetails } from "booking_app/models";
import { PaymentChannel } from "booking_app/types";

export class PaymentMethodService {
  static $inject = [
    "$rootScope",
    "AppSettings",
  ];

  private ShowPayAnyone: boolean = false;
  private ActivePaymentTab: PaymentMethod;
  private SelectedCreditCard: SavedCard;
  private CheckedSaveCreditCard: boolean = false;
  private SavedCards: SavedCard[] = [];
  private AdyenSavedCards: SavedCard[] = [];

  constructor(
    private $rootScope: any,
    private appSettings: AppSettings,
  ) {
    this.ActivePaymentTab = this.appSettings.hasMultiplePaymentMethods ?
      this.appSettings.defaultPaymentMethod : undefined;
  }

  /**
   * TODO: Need to move other payment method related
   * variables (introdued in ocbc) to this service,
   * So that we can decouple those from ctrls (hotel, cars and flight checkout)
   */
  get selectedCreditCard(): SavedCard {
    return this.SelectedCreditCard;
  }

  set selectedCreditCard(card: SavedCard) {
    this.SelectedCreditCard = card;
  }

  get showPayAnyone(): boolean {
    return this.ShowPayAnyone;
  }

  set showPayAnyone(value: boolean) {
    this.ShowPayAnyone = value;
  }

  get activePaymentTab(): PaymentMethod {
    return this.ActivePaymentTab;
  }

  set activePaymentTab(value: PaymentMethod) {
    this.ActivePaymentTab = value;
  }

  get checkedSaveCreditCard(): boolean {
    return this.CheckedSaveCreditCard;
  }

  set checkedSaveCreditCard(value: boolean) {
    this.CheckedSaveCreditCard = value;
  }

  get savedCards(): SavedCard[] {
    return this.SavedCards;
  }

  set savedCards(savedCards: SavedCard[]) {
    this.populateExtraProperties(savedCards);
    this.SavedCards = savedCards;
  }

  get adyenSavedCards(): SavedCard[] {
    return this.AdyenSavedCards;
  }

  set adyenSavedCards(adyenSavedCards: SavedCard[]) {
    this.AdyenSavedCards = adyenSavedCards;
  }

  public allowSaveCreditCard(): boolean {
    return  this.appSettings.storeCreditCard &&
            this.$rootScope.userDetails.loggedIn &&
            this.$rootScope.selectedCurrency.preferredGateway !== "checkout_com";
  }

  public hasSavedCards(): boolean {
    return this.savedCards.length > 0;
  }

  public filterPaymentData(paymentData: CreditCardDetails): CreditCardDetails {
    paymentData.card_number = "";
    paymentData.cvv = "";
    paymentData.expiration_month = "";
    paymentData.expiration_year = "";
    return paymentData;
  }

  public setActiveTab(paymentMethod: PaymentMethod): void {
    this.activePaymentTab = paymentMethod;

    // If we are not on the saved cards tab, we don't want to
    // use a saved card to make a booking
    if (paymentMethod !== PaymentMethod.SAVED_CARDS) {
      this.selectedCreditCard = null;
    }
  }

  public isUsingSavedAdyenCard() {
    return this.activePaymentTab === "saved-cards" &&
      this.selectedCreditCard?.payment_channel === "adyen";
  }

  public getPaymentChannelFromAdyenSavedCard(): string {
    switch (this.selectedCreditCard.card_type) {
      case "alipay":
        return PaymentChannel.ADYEN_ALIPAY;
      case "diners":
        return PaymentChannel.ADYEN_DINERS;
      case "jcb":
        return PaymentChannel.ADYEN_JCB;
      case "cup":
        return PaymentChannel.ADYEN_UNIONPAY;
      default:
        return PaymentChannel.ADYEN;
    }
  }

  private populateExtraProperties(savedCards: SavedCard[]) {
    /**
     * Adding 2 extra properties "removeInProgress" , "id".
     * id: To have a unique ID for each card
     * removeInProgress: Remove card confirmation in front end temporarily
     */
    savedCards.forEach((card, index) => {
      card.removeInProgress = false;
      card.id = `${card.card_type}_${index}`;
      card.card_type = card.card_type === "American Express" ? "amex" : card.card_type;
    });
  }
}

angular.module("BookingApp").service("PaymentMethodService", PaymentMethodService);
