export enum UserActionType {
  DESTINATION = "destination",
}

export interface UserAction {
  action_type: UserActionType;
  custom_data: any;
}

export interface UserActionInput {
  action_type: UserActionType;
  custom_data_id: string;
}
