angular.module('BookingApp')

.service "RedeemPointsFilterService", [

  "$rootScope", "$timeout", "$translate", "FormatService", "AppSettings"
  ($rootScope, $timeout, $translate, FormatService, AppSettings) ->

    self = this

    default_points_range = { min: 0, max: 10000000 }

    this.points_range = angular.copy(default_points_range)

    this.max_points = 10000000
    this.min_points = 0
    this.filter_applied = false
    this.slider_timeout = null

    WAIT_FOR_RERENDER = 1000 # in ms

    this.set_min_points = (min_points, force) ->
      self.min_points = min_points
      if !self.filter_applied || force
        self.points_range.min = min_points
        default_points_range.min = min_points

    this.set_max_points = (max_points, force) ->
      self.max_points = max_points
      if !self.filter_applied || force
        self.points_range.max = max_points
        default_points_range.max = max_points

    this.reset = () ->
      angular.copy(default_points_range, self.points_range)
      self.update_slider()
      self.filter_applied = false

    abs = (a, b) ->
      Math.abs(a - b)

    this.format = (miles) ->
      FormatService.number(miles, $rootScope.selectedLocale, 0)

    slider_timeout = null
    this.adjust_slider = () -> #slider deals with whole numbers, search ceiling & bottom stored as float
      if abs(self.points_range.min, self.min_points) > 1 || abs(self.points_range.max, self.max_points) > 1
        self.filter_applied = true
      else
        self.filter_applied = false

    update_on_change = ->
      $timeout.cancel(slider_timeout) if slider_timeout
      self.points_range.min = self.slider.min
      self.points_range.max = self.slider.max

      self.filter_applied = true

      self.adjust_slider()
      $timeout.cancel(self.slider_timeout) if self.slider_timeout
      self.slider_timeout = $timeout( ->
        $rootScope.$broadcast('kaligoFilterSearchResult')
        self.slider_timeout = null
      , 200)
      return

    this.update_slider = () ->
      self.slider = {
        min: self.points_range.min
        max: self.points_range.max
        options: {
          floor: self.min_points
          ceil: self.max_points
          step: 50
          rightToLeft: isRtlDirection()
          onChange: update_on_change
          translate: (points) ->
            formatted = self.format(points)

            if AppSettings.showCurrencyInFilterSliders == true
              "#{formatted} #{$translate.instant($rootScope.pointsPartner.shortCurrency)}"
            else
              formatted
        }
      }

      $timeout( ->
        $rootScope.$broadcast('rzSliderForceRender')
      , WAIT_FOR_RERENDER)
      return

    isRtlDirection = () ->
      $rootScope.globalState.displayAlignment == "rtl"

    this.sift = (records) ->
      return records if !self.points_range.min

      filtered = []
      min = self.points_range.min - 1
      max = self.points_range.max + 1

      records = [] if !records? || !records
      showsTotal = $rootScope.globalState.showTotalNights

      for record in records
        points = if showsTotal then record.points_payment else record.points_payment_per_night
        points = if points then parseFloat(points) else 0
        filtered.push record if (points >= min and points <= max) or !points

      return filtered

    return this
]
