import { isValidViewMode, MapboxState, MapboxStateView, MapboxStateViewMode } from "../values/mapbox-state";
import { AppSettings } from "booking_app/values/app-settings";
import { CurrentPage, HotelItem } from "booking_app/types";
import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { ProductType } from "booking_app/types/product-type";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { ScrollService } from "booking_app/services/scroll-service";
interface Coordinates extends Array<number> {
  0: number;
  1: number;
}

declare var angular: any;
declare var mapboxgl: any;
declare var isMapboxglSupported: boolean;

const OPEN_POPUP_EVENT = "openPopup";
const FOCUS_ZOOM_LEVEL = 18;
const MARKER_CLICK_ANIMATION_DURATION = 200;
const CLUSTER_CLICK_ANIMATION_DURATION = 500;
const DEFAULT_POPUP_ANIMATION_DURATION = 1000;

export class MapboxService {

  static $inject = [
    "$compile",
    "$rootScope",
    "$timeout",
    "$q",
    "$translate",
    "AppSettings",
    "MapboxState",
    "GlobalStateService",
    "ScrollService",
    "$filter",
  ];

  userHasTouch: boolean = false;

  private addressPoints: any;
  private map: any;
  private currencyListener: any;
  private localeListener: any;
  private hotelData: HotelItem[];
  private currentLocale: any;
  private currentCurrencyCode: string;
  private callbackButtonElem: HTMLElement;

  constructor(
    private $compile: any,
    private $rootScope: any,
    private $timeout: any,
    private $q: any,
    private $translate: any,
    private appSettings: AppSettings,
    private mapboxState: MapboxState,
    private globalStateService: GlobalStateService,
    private scrollService: ScrollService,
    private $filter: any,
  ) {
    this.addressPoints = {
      type: "FeatureCollection",
      features: [],
    };
  }

  public setViewMode(viewMode: string) {
    // Reject if viewMode is not valid to prevent unwanted side effects
    if (!isValidViewMode(viewMode)) {
      return;
    }

    if (viewMode === MapboxStateViewMode.Map) {
      this.resize();
    }

    this.mapboxState.viewMode = viewMode;

    if (this.mapboxState.view === MapboxStateView.ResultPage) {
      this.mapboxState.savedViewMode = viewMode;
    }
  }

  public toggleViewMode(event?): void {
    event?.preventDefault();

    if (this.mapboxState.viewMode === MapboxStateViewMode.List) {
      if (this.isHotelResultStillPolling()) { return; }

      this.setupMapView("mapbox-search-map");
      this.setViewMode(MapboxStateViewMode.Map);
      this.setupViewportListener();

    } else if (this.mapboxState.viewMode === MapboxStateViewMode.Map) {
      // Remove elements inside mapbox-serach-map because we are redrawing
      // for the next toggle
      document.getElementById("mapbox-search-map").innerHTML = "";

      this.setViewMode(MapboxStateViewMode.List);
      this.scrollService.visitedHotel(1200);
      // focus on the callback button if navigated using keyboard
      if (this.callbackButtonElem) {
        this.$timeout(() => {
          this.callbackButtonElem.focus();
        }, 0);
      }
    }
  }

  public toggleMobileViewMode(): void {
    this.setupMapView("mapbox-search-map-mobile");
  }

  // had to move this to a public method so we can access it outside
  public openPopup(coordinates: Coordinates, hotelId: string,
                   zoomLevel?: number, polygonID?: string): void {

    this.flyMapTo(coordinates, zoomLevel || this.map.getZoom());

    const selectPopup = new mapboxgl.Popup();
    selectPopup.remove();

    this.mapboxState.selectedHotel = this.getHotelFromId(hotelId);

    selectPopup.setLngLat(coordinates)
      .setDOMContent(this.popupTemplate())
      .addTo(this.map);

    selectPopup.on("close", (e) => {
      this.mapboxState.selectedHotel = {};
    });

    if (typeof polygonID !== "undefined" && this.isEarn()) {
      this.updateFeatureStates(polygonID, true);

      selectPopup.on("close", (e) => {
        this.updateFeatureStates(polygonID, false);
      });
    } else if (typeof polygonID === "undefined" && this.isEarn()) {
      this.map.once("load", () => {
        this.$timeout(() => this.queryFeatureAndHideState(selectPopup), 1500);
      });
    }
  }

  public setupSingleHotelMap(coordinates: Coordinates): void {
    this.map.on("load", () => {
      this.flyMapTo(coordinates, FOCUS_ZOOM_LEVEL);
      this.setupMapMarker(coordinates);
    });
  }

  public setupMapMarker(coordinates: Coordinates): void {
    // create a HTML element for each feature
    const el = document.createElement("div");
    el.className = "hotel-detail-marker";
    const marker = new mapboxgl.Marker(el)
      .setLngLat(coordinates)
      .addTo(this.map);
  }

  public setupMapView(containerId): void {
    this.createMapboxglInstance(containerId);
    this.configureMap();
    this.map.on("load", () => {
      this.setupMapResources();
    });
  }

  // Get hotels data from ResultsCtrl
  public loadAddressPoints(addressPoints: HotelItem[]): void {
    this.hotelData = addressPoints;
    this.setupAddressPointsFeatures(addressPoints);
  }

  // Update hotel data without removing sources / layers
  public updateAddressPoints(addressPoints: HotelItem[]): void {
    this.hotelData = addressPoints;
    this.removeAddressPointsFeatures();
    this.setupAddressPointsFeatures(addressPoints);
  }

  public resize(): void {
    if (this.map) {
      // Asynchronously call resize on map object, to cater for map resizing bug when map is not loaded
      this.$timeout(() => this.map.resize(), 1);
    }
  }

  public focusOnHotel(hotel: HotelItem): void {
    if (this.isHotelResultStillPolling()) {
      return;
    }

    this.toggleViewMode();
    const coordinates: Coordinates = [hotel.longitude, hotel.latitude];
    this.openPopup(coordinates, hotel.id, 18);
  }

  public reset(removeMapResources = true): void {
    this.setViewMode(MapboxStateViewMode.List);

    if (removeMapResources) {
      this.removeMapResources();
    }

    this.removeAddressPointsFeatures();
  }

  public resetAll(): void {
    // We can skip removing the resources, because Map#remove() will do that for us
    this.reset(false);
    this.removeMap();
  }

  public setCallbackButtonElement(elem: HTMLElement) {
    this.callbackButtonElem = elem;
  }

  private queryFeatureAndHideState(selectPopup: any): void {
    const features = this.map.queryRenderedFeatures(null, { layers: ["hotelsLayer"]});
    for (const feature of features) {
      this.map.setFeatureState(feature, {
        click: true,
      });
    }

    selectPopup.on("close", (e) => {
      if (!this.map) { return; }
      for (const feature of features) {
        try {
          this.map.setFeatureState(feature, {
            click: false,
          });
        } catch (_) {
          break;
        }
      }
    });
  }

  private flyMapTo(coordinates: Coordinates, zoomLevel?: number, duration?: number) {
    this.map.flyTo({
      center: coordinates,
      around: coordinates,
      zoom: zoomLevel || FOCUS_ZOOM_LEVEL,
      duration: duration || 0,
    });
  }

  private isHotelResultStillPolling(): boolean {
    return this.$rootScope.hotelResultStillPolling;
  }

  private compileHotelDetailsTemplate(tplElement) {
    const scope = this.$rootScope.$new();
    const el = this.$compile(tplElement)(scope);
    return el[0];
  }

  private tooltipTemplate() {
    return this.compileHotelDetailsTemplate(
      angular.element(
        `<div class="marker-tooltip">
          <hotel-detail-tooltip hotel="mapboxState.hoveredHotel"></hotel-detail-tooltip>
        </div>`,
      ),
    );
  }

  private popupTemplate() {
    return this.compileHotelDetailsTemplate(
      angular.element(
        `<div class="marker-popup">
          <hotel-detail-popup hotel="mapboxState.selectedHotel"></hotel-detail-popup>
        </div>`,
      ),
    );
  }

  private createMapboxglInstance(containerId): void {
    mapboxgl.accessToken = this.appSettings.mapboxAPIKey;
    this.map = new mapboxgl.Map({
      container: containerId,
      style: "mapbox://styles/mapbox/streets-v10",
      center: [this.$rootScope.destinationLng, this.$rootScope.destinationLat],
      zoom: 10,
    });
  }

  private configureMap(): void {
    // Disable map rotation
    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();

    // Map controls UI
    this.map.addControl(new mapboxgl.NavigationControl(), "top-left");
  }

  private mapExists(): boolean {
    return this.map && this.mapboxState.loaded;
  }

  // Setup resources required to render markers and popups on the map object
  private setupMapResources() {
    this.setupSources();
    this.setupLayers();
    this.setupEventListeners();
    this.setupLayerRefresherListener();
  }

  private setupSources() {
    if (!this.setupSourcesData()) {
      const maxZoom = this.isEarn() ? 22 : 13;
      this.map.addSource("hotels", {
        type: "geojson",
        data: this.addressPoints,
        cluster: true,
        clusterMaxZoom: maxZoom,
        clusterRadius: 80,
        generateId: true,
      });
    }
  }

  private hasCurrencyLocaleListener(): boolean {
    return this.currencyListener && this.localeListener;
  }

  // Set data to "hotels" map source if available, useful for updating data without reloading sources/layers
  private setupSourcesData(): boolean {
    const hotelsSource = this.map.getSource("hotels");
    if (hotelsSource) {
      hotelsSource.setData(this.addressPoints);
      return true;
    } else {
      return false;
    }
  }

  private setupEarnLayers(): void {
    this.map.loadImage(
      `${this.$rootScope.const.cdnUrl}/assets/images/callout-shadow.png`, (error, image) => {
        if (error) {
          throw error;
        }

        this.map.addImage("callout", image, {
          stretchX: [
            [50, 95],
            [130, 175],
          ],
          stretchY: [[45, 80]],
          content: [50, 45, 170, 80],
          pixelRatio: 2,
        });

        this.map.addLayer({
          id: "hotelsLayer",
          type: "symbol",
          source: "hotels",
          layout: {
            "text-field": ["get", "description"],
            "text-font": [
              "Arial Unicode MS Regular",
              "Arial Unicode MS Bold",
            ],
            "icon-text-fit": "both",
            "text-size": 12,
            "icon-image": ["get", "image-name"],
            "icon-allow-overlap": true,
            "text-allow-overlap": false,
            "text-optional": true,
            "icon-optional": true,
            "symbol-z-order": "viewport-y",
          },
          paint: {
            "text-color": this.appSettings.mapboxHotelMarkerColor,
            "icon-opacity": [
              "case",
              ["boolean", ["feature-state", "click"], false],
              0,
              1,
            ],
            "text-opacity": [
              "case",
              ["boolean", ["feature-state", "click"], false],
              0,
              1,
            ],
          },
        });
      },
    );
  }

  private setupLayerRefresherListener(): void {
    // Had to add this new variable because of some weird bug on JPY
    // where it is always JPY as new and oldvalue
    if (this.isEarn() && this.hasCurrencyLocaleListener()) { return; }
    this.currentCurrencyCode = this.$rootScope.selectedCurrency.code;
    this.currentLocale = this.$rootScope.selectedLocale;

    this.currencyListener =
      this.$rootScope.$watch("selectedCurrency.code", (newValue) => {
        if (newValue !== this.currentCurrencyCode) {
          this.currentCurrencyCode = newValue;
          this.resetLayers();
        }
      });

    this.localeListener =
      this.$rootScope.$watch("selectedLocale", (newValue) => {
        if (newValue !== this.currentLocale) {
          this.currentLocale = newValue;
          this.resetLayers();
        }
      });
  }

  private resetLayers(): void {
    this.addressPoints = {
      type: "FeatureCollection",
      features: [],
    };
    this.resetAll();

    if (this.mapboxState.viewMode === MapboxStateViewMode.Map) {
      this.toggleViewMode();
    }

    this.setupLayerRefresherListener();
    this.loadAddressPoints(this.hotelData);
  }

  private setupLayers() {
    // Individual hotel markers
    if (this.globalStateService.currentPage !== CurrentPage.SEARCH_RESULT) { return; }

    if (this.isEarn()) {
      this.setupEarnLayers();
    } else {
      this.map.addLayer({
        id: "hotelsLayer",
        source: "hotels",
        type: "circle",
        filter: ["!has", "point_count"],
        paint: {
          "circle-radius": 8,
          "circle-color": this.appSettings.mapboxHotelMarkerColor,
        },
      });
    }

    // Hotel cluster markers
    this.map.addLayer({
      id: "hotelsCluster",
      source: "hotels",
      type: "circle",
      filter: ["has", "point_count"],
      paint: {
        "circle-radius": 15,
        "circle-color": this.appSettings.mapboxHotelClusterColor,
      },
    });

    // Hotel cluster count
    this.map.addLayer({
      id: "hotelsClusterCount",
      source: "hotels",
      type: "symbol",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-size": 14,
      },
      paint: {
        "text-color": this.appSettings.mapboxHotelClusterCounterTextColor,
      },
    });
  }

  private setupEventListeners() {
    // Tooltips
    const hoverTooltip = new mapboxgl.Popup({
      closeButton: false,
    });
    // Check if device has touch capabilities
    this.map.on("touchstart", (e) => {
      this.userHasTouch = true;
    });
    this.map.on("mouseenter", "hotelsLayer", () => {
      // Do not attach mousemove event listener if device has touch
      if (!this.userHasTouch) {
        // Using mousemove instead of mouseenter so that its easier to select hotels in close proximity of each other
        this.map.on("mousemove", "hotelsLayer", (e) => {
          this.mapboxState.hoveredHotel = this.getHotelFromId(e.features[0].properties.id);

          if (hoverTooltip.isOpen()) {
            return;
          }

          if (this.mapboxState.hoveredHotel &&
            this.mapboxState.hoveredHotel.id === e.features[0].properties.id &&
            this.mapboxState.hoveredHotel === this.mapboxState.selectedHotel
            ) {
            return;
          }
          this.map.getCanvas().style.cursor = "pointer";
          hoverTooltip.setLngLat(e.features[0].geometry.coordinates)
            .setDOMContent(this.tooltipTemplate())
            .addTo(this.map);
        });
      }
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseleave", "hotelsLayer", () => {
      this.map.getCanvas().style.cursor = "";
      hoverTooltip.remove();
    });

    // Popups
    const selectPopup = new mapboxgl.Popup({anchor: "bottom"});

    this.map.on("click", "hotelsLayer", (e) => {
      const coordinates = e.features[0].geometry.coordinates;
      const hotelId = e.features[0].properties.id;
      let polygonID: string;

      if (e.features.length > 0) {
        if (polygonID) {
          this.map.removeFeatureState({
            source: "hotels",
            id: polygonID,
          });
        }

        polygonID = e.features[0].id;

        this.$timeout(() => this.openPopup(coordinates, hotelId, null, polygonID));
      }
    });

    this.map.on("moveend", "hotelsLayer", (e) => {
      // For smoother animation, open popup only after the map has centered on the marker
      const { eventType, coordinates } = e;
      if (eventType === OPEN_POPUP_EVENT) {
        selectPopup.remove();
        selectPopup.setLngLat(coordinates)
          .setDOMContent(this.popupTemplate())
          .addTo(this.map);
      }
    });

    // Clusters
    this.map.on("click", "hotelsCluster", (e) => {
      const currentZoom = this.map.getZoom();
      this.flyMapTo(e.features[0].geometry.coordinates,
        currentZoom + 1,
        CLUSTER_CLICK_ANIMATION_DURATION,
      );
    });

    this.map.on("mouseenter", "hotelsCluster", () => {
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseleave", "hotelsCluster", () => {
      this.map.getCanvas().style.cursor = "";
    });
  }

  private updateFeatureStates(polygonID: string, state: boolean): void {
    if (!state) {
      this.map.removeFeatureState({
        source: "hotels",
        id: polygonID,
      });
    }

    this.map.setFeatureState({
      source: "hotels",
      id: polygonID,
    }, {
      click: state,
    });
  }

  private getHotelFromId(id: string): any {
    const feature = this.addressPoints.features.find(feature => feature.properties.id === id);
    if (feature) {
      return feature.properties.hotel;
    } else {
      return null;
    }
  }

  private formatPointPrice(price): number {
    let priceDisplay: number;
    priceDisplay = price * this.$rootScope.selectedCurrency.rate;
    if (!this.$rootScope.showTotalNights) {
      priceDisplay = priceDisplay / this.$rootScope.duration;
    }

    if (this.$rootScope.selectedCurrency.decimalPlace === 0) {
      priceDisplay = Math.ceil(priceDisplay);
    }

    return this.$filter("numberFmt")(priceDisplay, this.$rootScope.selectedLocale, 0);
  }

  private setupAddressPointsFeatures(points) {
    points
    .filter((point) => point.available)
    .forEach((point) => {
      const priceDisplay: number = this.formatPointPrice(point.price);
      const displayCode: string = this.$rootScope.selectedCurrency[this.appSettings.defaultCurrencyDisplay];
      const descriptionText: string = this.buildDescription(displayCode, priceDisplay);
      this.addressPoints.features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [point.longitude, point.latitude],
        },
        properties: {
          "hotel": point,
          "id": point.id,
          "description": descriptionText,
          "image-name": "callout",
        },
      });
    });
  }

  private buildDescription(displayCode: string, priceDisplay: number): string {
    if (this.appSettings.defaultCurrencyDisplay === "symbol") {
      return `${displayCode}${priceDisplay}`;
    } else {
      return `${displayCode} ${priceDisplay}`;
    }
  }
  private removeAddressPointsFeatures() {
    this.addressPoints.features = [];
  }

  private onResize(callback: () => void): void {
    if (this.map) {
      this.map.on("resize", () => {
        callback();
        this.map.off("resize");
      });
    }
  }

  private removeMap() {
    if (!this.map) { return false; }

    this.map.on("remove", () => this.map = null);
    this.mapboxState.loaded = false;
    this.map.remove();
  }

  private removeMapResources() {
    const layers = ["hotelsLayer", "hotelsCluster", "hotelsClusterCount"];

    layers.forEach((layer) => {
      if (this.map && this.map.getLayer(layer)) {
        this.map.removeLayer(layer);
      }
    });

    const sources = ["hotels"];

    sources.forEach((source) => {
      if (this.map && this.map.getSource(source)) {
        this.map.removeSource(source);
      }
    });
  }

  private setupViewportListener(): void {
    const maxWidth = ViewportSizes.SM_MAX;
    const watchWidthEvent = this.$rootScope.$watch("globalState.browserWidth", () => {
      if (this.$rootScope.globalState.browserWidth <= maxWidth) {
        this.toggleViewMode();
        watchWidthEvent();
      }
    });
  }

  private isEarn(): boolean {
    return this.$rootScope.globalState.productType === ProductType.EARN;
  }
}

angular.module("BookingApp").service("MapboxService", MapboxService);
