// extra controller is required to inject the modalInstance for modal popup
// injecting modalInstance to non popup may result in error

declare var angular : any;

export class ForgetPasswordPopupCtrl {

  static $inject = ["$scope", "$controller", "$modalInstance"]

  private $scope : any;
  private $controller : any;
  private $modalInstance : any;

  constructor($scope, $controller, $modalInstance) {
    this.$scope = $scope;
    this.$controller = $controller;
    this.$modalInstance = $modalInstance;

    angular.extend(this, this.$controller('ForgetPasswordCtrl', {$scope: this.$scope}))
  }

  public cancel() {
    this.$modalInstance.dismiss('cancel');
  }

}

angular.module('BookingApp').controller('ForgetPasswordPopupCtrl', ForgetPasswordPopupCtrl)
