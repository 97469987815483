import { FacebookPixelService } from "booking_app/services/facebook-pixel";
import { BonusesRegistrationService } from "booking_app/services/bonuses-registration";

interface BonusParameters {
  firstName: string;
  email: string;
  sendMarketing: boolean;
}

export class DlpSignUpController {
  static $inject = [
    "$rootScope",
    "BonusesRegistrationService",
    "UtilsService",
    "$location",
    "FacebookPixelService",
    "UsersService",
    "$timeout",
    "$translate",
    "BonusesService",
  ];

  registerBonusForm: any;
  hasLoggedUser: boolean;
  private referralCode: any;

  constructor(
    private $rootScope: any,
    private bonusesRegistrationService: BonusesRegistrationService,
    private utilsService: any,
    private $location: any,
    private facebookPixelService: FacebookPixelService,
    private usersService: any,
    private $timeout: any,
    private $translate: any,
    private bonusesService: any,
  ) {
  }

  $onInit(): void {
    this.referralCode = this.utilsService.getReferralCode(this.$location);
    if (this.$rootScope.isUserLoggedIn()) {
      this.hasLoggedUser = true;
      this.registerBonusForm = {};
    } else {
      this.hasLoggedUser = false;
    }
  }

  closeBox(): void {
    this.$rootScope.showWelcomeBox = false;
  }

  headerTitle(): string {
    if (this.isDlpPage()) {
      return this.$translate.instant("dlp.sign_up_now_to_enjoy_this_special_offer");
    } else {
      return this.$translate.instant("dlp.sign_up_for_exclusive_kaligo_offers_and_travel_tips");
    }
  }

  successMessageForNotLoggedUser(): string {
    if (this.isDlpPage()) {
      return this.$translate.instant("dlp.search_hotels_and_enjoy_this_special_offer_now");
    } else {
      return this.$translate.instant("dlp.make_your_first_booking_and_start_earning");
    }
  }
  // Submits bonus action for no logged and logged user
  // (only checks form validity if there is a valid logged user)
  submitBonus(formData: any): any {
    this.registerBonusForm = formData;

    if (!this.hasLoggedUser && this.checkFormValidity()) {
      return this.registerBonusForm;
    }

    this.$rootScope.isLoading = true;
    this.registerBonusCall(this.bonusCallParameter()).
      then((result: any) => this.processBonusCall(result)).
      catch((_) => this.bonusCallError());

    return this.registerBonusForm;
  }

  dlpSignUpState(): "bonus-sign-up" | "sign-up" {
    if (this.noLoggedUser() || this.hasLoggedBonusSignUp()) {
      return "bonus-sign-up";
    } else if (!this.isDlpPage()) {
      return "sign-up";
    }
  }

  enableWelcomeBox(): void {
    this.$rootScope.showWelcomeBox = true;
  }

  private checkFormValidity(): boolean {
    return !this.registerBonusForm.$valid ||
      this.registerBonusForm.validSubmission ||
      this.$rootScope.isLoading;
  }

  private noLoggedUser(): boolean {
    return !this.hasLoggedUser && this.$rootScope.showWelcomeBox;
  }

  private hasLoggedBonusSignUp(): boolean {
    return (this.hasLoggedUser &&
      this.isDlpPage() &&
      !this.dlpUserHasBonus()) ||
      (this.registerBonusForm && this.registerBonusForm.success && this.$rootScope.showWelcomeBox);
  }

  private dlpUserHasBonus(): boolean {
    // Change this to be an API call to check if there is an exisiting bonus for that user
    return $.cookie("dlp-user-has-bonus");
  }

  private isDlpPage(): boolean {
    return (this.$rootScope.landingPage.url !== "" &&
      this.$rootScope.landingPage.url !== "/"
    );
  }

  private processBonusCall(result): void {
    this.registerBonusForm.registeredBonuses = result;
    this.$rootScope.isLoading = false;
    $.cookie("dlp-user-has-bonus", "true");
    this.registerBonusForm.success = true;
    this.triggerUserFacebookPixelService();

    if (!this.hasLoggedUser) {
      this.loginUser();
    }
  }

  private triggerUserFacebookPixelService(): void {
    this.facebookPixelService.eventTrack("Lead", {
      content_category: "LandingPage",
      content_name: this.$rootScope.landingPage.url,
    });
  }

  private registerBonusCall(bonus: BonusParameters): any {
    return this.bonusesRegistrationService.registerBonus(
      this.$rootScope.landingPage.url,
      bonus.firstName,
      bonus.email,
      bonus.sendMarketing,
      this.referralCode,
    );
  }

  private loginUser(): void {
    this.usersService(true).then((result: any) => {
      this.$rootScope.userDetails = result;
      this.hasLoggedUser = true;
    });
  }

  private bonusCallError(): void {
    this.$rootScope.isLoading = false;
    this.registerBonusForm.success = false;
  }

  private bonusCallParameter(): BonusParameters {
    const userParams = this.$rootScope.userDetails.user || this.registerBonusForm;
    return {
      firstName: userParams.firstName,
      email: userParams.email,
      sendMarketing: true, // defaults to true according to Colin and JY
    };
  }
}
