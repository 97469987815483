export enum HeaderUrl {
  // FAB
  TRAVEL_BOOKING = "wl.travel_booking",
  TRAVEL_BENEFITS = "wl.travel_benefits",
  MY_ACCOUNT = "My Account",

  // BOUNTY
  BUY_IN_APP = "Buy in-app with discount",
  DISCOVER_EXCLUSIVE_BENEFITS = "Discover exclusive benefits",
  MY_OFFERS_ACCOUNT = "My Offers account",
}
