import { HeaderUrlList } from "booking_app/services/header-url-list";
import { UrlLink } from "booking_app/constants/header-url-link";

export class MenuNavigationController {
  static $inject = [
    "HeaderUrlList",
  ];

  private useSequenceIndex: boolean;

  constructor(
    private headerUrlList: HeaderUrlList,
  ) { }

  getCurrentLinks(): UrlLink[] {
    return this.headerUrlList.getCurrentLinks();
  }

  getTabIndex(): number {
    return this.headerUrlList.getActiveHeader().tabIndex;
  }
}
