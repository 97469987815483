import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { MapboxDirectionService } from "booking_app/services/mapbox-direction";

export class MapboxDirectionModal {
  static $inject = [
    "$scope",
    "$rootScope",
    "$timeout",
    "$modalInstance",
    "MapboxDirectionService",
  ];

  constructor(
    private $scope: any,
    private $rootScope: any,
    private $timeout: any,
    private $modalInstance: any,
    private mapboxDirectionService: MapboxDirectionService,
  ) {

    this.$scope.close = () => {
      this.mapboxDirectionService.removeMap();
      this.$modalInstance.dismiss("close");
    };

    this.$scope.yes = () => {
      this.mapboxDirectionService.removeMap();
      this.$modalInstance.close("yes");
    };

    this.$timeout(() => {
      this.mapboxDirectionService.setupMapView("mapbox-direction-map");
      this.mapboxDirectionService.resize();
    });
    this.modalWidthListener();
  }

  private modalWidthListener() {
    this.$scope.mapModal = this.$rootScope.$watch("globalState.browserWidth", () => {
      if (this.$rootScope.globalState.browserWidth <= ViewportSizes.XS_MAX) {
        this.$scope.close();
        this.$scope.mapModal();
      }
    });
  }
}

angular.module("BookingApp").controller("MapboxDirectionModal", MapboxDirectionModal);
