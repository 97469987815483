angular.module("BookingApp").directive("parseStyle", [
  "$interpolate", "$rootScope",
  ($interpolate: any, $rootScope: any) => {
    return {
      scope: {
        style: "=parseStyle",
      },
      link: (scope: any, element: any) => {
        if (!scope.style) {
          return;
        }
        const exp = $interpolate(scope.style);
        element.html(exp($rootScope));
      },
    };
  },
]);
