import { Bindings } from "booking_app/types";
import { ExclusiveBenefitsController } from "./exclusive-benefits.controller";

export class ExclusiveBenefits {
  bindings: any = {
    kaligoReference: Bindings.ONE_WAY,
  };
  controller: any = ExclusiveBenefitsController;
  template: string = `
    <div class="exclusive-benefits" ng-if="$ctrl.exclusiveBenefits.length > 0">
      <div class="title-container">
        <div class="header" translate='wl.visa.visa_exclusive'></div>
        <div class="sub-header" translate='wl.visa.check_out_latest_deals_near_your_hotel'></div>
      </div>
      <div class="card-container">
        <div class="wrapper">
          <exclusive-card ng-repeat="benefits in $ctrl.exclusiveBenefits"
            image="$ctrl.processImage(benefits.offer.image_list[0])"
            title="benefits.merchant.name"
            short-description="benefits.offer.short_description"
            go-to-exclusive-benefits="$ctrl.goToExclusiveBenefits(benefits.merchant.id)">
          </exclusive-card>
        </div>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("exclusiveBenefits", new ExclusiveBenefits());
