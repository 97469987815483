import { MapOpenerService } from "booking_app/services/hotels/map-opener.service";
import { ViewportSizes } from "booking_app/types/viewport-sizes";
import { AppSettings } from "booking_app/values/app-settings";

export class HotelResultMapMobileController {
  static $inject = [
    "$timeout",
    "$rootScope",
    "$scope",
    "AppSettings",
    "MapOpenerService",
  ];

  // local state
  maxModalWidth: ViewportSizes;

  constructor(
    private $timeout: any,
    private $rootScope: any,
    private $scope: any,
    private appSettings: AppSettings,
    private mapOpenerService: MapOpenerService,
  ) {
    this.$scope.mapIconSource = this.appSettings.mobileResultPopupConfig.mapIconSource;
    this.maxModalWidth = ViewportSizes.SM_MAX;
  }

  openModal() {
    this.mapOpenerService.openMap();
  }

  closeModal() {
    this.mapOpenerService.closeMap();
  }
}
