import { ExclusiveBenefitsService } from "booking_app/services/exclusive-benefits-service";
import { ExclusiveBenefits } from "booking_app/types/exclusive-benefits";
import { CloudfrontImageService } from "booking_app/services/cloudfront-image.service";

export class ExclusiveBenefitsController {
  static $inject = [
    "ExclusiveBenefitsService",
    "CloudfrontImageService",
    "$window",
  ];

  // bindings
  kaligoReference: string;

  exclusiveBenefits: ExclusiveBenefits[];
  loading: boolean;

  constructor(
    private exclusiveBenefitsService: ExclusiveBenefitsService,
    private imageProcessor: CloudfrontImageService,
    private $window: any,
  ) {
  }

  $onChanges(): void {
    this.getExclusiveBenefits();
  }

  public getExclusiveBenefits(): void {
    if (this.kaligoReference) {
      this.exclusiveBenefitsService.getExclusiveBenefits(this.kaligoReference).then((response: any) => {
        this.loading = false;
        this.exclusiveBenefits = response;
      });
    }
  }

  public processImage(imageUrl: string): string {
    return this.imageProcessor.processVisaImage(imageUrl, 640);
  }

  public goToExclusiveBenefits(merchantId: number): void {
    this.$window.location.href = this.exclusiveBenefitUrl(merchantId);
  }

  private exclusiveBenefitUrl(merchantId: number): string {
    return `/exclusive_benefits?merchant_id=${merchantId}`;
  }
}
